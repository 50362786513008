import * as Apollo from "@apollo/client";
import { gql } from "@apollo/client";
import {
  FieldPolicy,
  FieldReadFunction,
  TypePolicies,
  TypePolicy,
} from "@apollo/client/cache";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  UUID: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AddRackInput = {
  worklist?: Maybe<Array<Maybe<TubeLabellerDestinationWorklistInput>>>;
  currentRackNumber: Scalars["Int"];
  plateBarcode?: Maybe<Scalars["String"]>;
  salivaSamples?: Maybe<Array<Maybe<Scalars["String"]>>>;
  process?: Maybe<Scalars["String"]>;
  method?: Maybe<Scalars["String"]>;
  numberOfDuplicates?: Maybe<Scalars["Int"]>;
  passageNumberIncrement: Scalars["Int"];
  resuspensionVol?: Maybe<Scalars["Int"]>;
  cellNumber?: Maybe<Scalars["Int"]>;
  colWise: Scalars["Boolean"];
  fillRack: Scalars["Boolean"];
  includeInactiveWells: Scalars["Boolean"];
  date: Scalars["DateTime"];
  lotNumberType?: Maybe<Scalars["String"]>;
  runTaskId?: Maybe<Scalars["Int"]>;
  isAutomated?: Maybe<Scalars["Boolean"]>;
};

export type AppSuiteGroup = {
  __typename?: "AppSuiteGroup";
  groupId: Scalars["Int"];
  groupName?: Maybe<Scalars["String"]>;
  slackGroupId?: Maybe<Scalars["String"]>;
  slackGroupName?: Maybe<Scalars["String"]>;
  activeForTaskAssignment?: Maybe<Scalars["Boolean"]>;
  appSuiteUsers?: Maybe<Array<Maybe<AppSuiteUser>>>;
  collectionTasks?: Maybe<Array<Maybe<CollectionTask>>>;
  feedGroups?: Maybe<Array<Maybe<FeedGroup>>>;
  folderStructures?: Maybe<Array<Maybe<FolderStructure>>>;
  lookupAssetTypes?: Maybe<Array<Maybe<LookupAssetType>>>;
  lookupTicketTypes?: Maybe<Array<Maybe<LookupTicketType>>>;
  personalTaskTemplateTasks?: Maybe<Array<Maybe<PersonalTaskTemplateTask>>>;
  collectionTasksNavigation?: Maybe<Array<Maybe<CollectionTask>>>;
  personalTaskTemplateTasksNavigation?: Maybe<
    Array<Maybe<PersonalTaskTemplateTask>>
  >;
  personalTasks?: Maybe<Array<Maybe<PersonalTask>>>;
  runTasks?: Maybe<Array<Maybe<RunTask>>>;
  runTemplateTasks?: Maybe<Array<Maybe<RunTemplateTask>>>;
  runTemplates?: Maybe<Array<Maybe<RunTemplate>>>;
  users?: Maybe<Array<Maybe<AppSuiteUser>>>;
};

export type AppSuiteGroupFilterInput = {
  and?: Maybe<Array<AppSuiteGroupFilterInput>>;
  or?: Maybe<Array<AppSuiteGroupFilterInput>>;
  groupId?: Maybe<ComparableInt32OperationFilterInput>;
  groupName?: Maybe<StringOperationFilterInput>;
  slackGroupId?: Maybe<StringOperationFilterInput>;
  slackGroupName?: Maybe<StringOperationFilterInput>;
  activeForTaskAssignment?: Maybe<BooleanOperationFilterInput>;
  appSuiteGroupUsers?: Maybe<ListFilterInputTypeOfAppSuiteGroupUserFilterInput>;
  appSuiteUsers?: Maybe<ListFilterInputTypeOfAppSuiteUserFilterInput>;
  collectionTasks?: Maybe<ListFilterInputTypeOfCollectionTaskFilterInput>;
  feedGroups?: Maybe<ListFilterInputTypeOfFeedGroupFilterInput>;
  folderStructures?: Maybe<ListFilterInputTypeOfFolderStructureFilterInput>;
  lookupAssetTypes?: Maybe<ListFilterInputTypeOfLookupAssetTypeFilterInput>;
  lookupTicketTypes?: Maybe<ListFilterInputTypeOfLookupTicketTypeFilterInput>;
  personalTaskTemplateTasks?: Maybe<ListFilterInputTypeOfPersonalTaskTemplateTaskFilterInput>;
  collectionTasksNavigation?: Maybe<ListFilterInputTypeOfCollectionTaskFilterInput>;
  personalTaskTemplateTasksNavigation?: Maybe<ListFilterInputTypeOfPersonalTaskTemplateTaskFilterInput>;
  personalTasks?: Maybe<ListFilterInputTypeOfPersonalTaskFilterInput>;
  runTasks?: Maybe<ListFilterInputTypeOfRunTaskFilterInput>;
  runTemplateTasks?: Maybe<ListFilterInputTypeOfRunTemplateTaskFilterInput>;
  runTemplates?: Maybe<ListFilterInputTypeOfRunTemplateFilterInput>;
};

export type AppSuiteGroupSortInput = {
  groupId?: Maybe<SortEnumType>;
  groupName?: Maybe<SortEnumType>;
  slackGroupId?: Maybe<SortEnumType>;
  slackGroupName?: Maybe<SortEnumType>;
  activeForTaskAssignment?: Maybe<SortEnumType>;
};

export type AppSuiteGroupUser = {
  __typename?: "AppSuiteGroupUser";
  groupUserId: Scalars["Int"];
  userId: Scalars["Int"];
  groupId: Scalars["Int"];
  isGroupLeader?: Maybe<Scalars["Boolean"]>;
  isDefaultGroup?: Maybe<Scalars["Boolean"]>;
  group?: Maybe<AppSuiteGroup>;
  user?: Maybe<AppSuiteUser>;
};

export type AppSuiteGroupUserFilterInput = {
  and?: Maybe<Array<AppSuiteGroupUserFilterInput>>;
  or?: Maybe<Array<AppSuiteGroupUserFilterInput>>;
  groupUserId?: Maybe<ComparableInt32OperationFilterInput>;
  userId?: Maybe<ComparableInt32OperationFilterInput>;
  groupId?: Maybe<ComparableInt32OperationFilterInput>;
  isGroupLeader?: Maybe<BooleanOperationFilterInput>;
  isDefaultGroup?: Maybe<BooleanOperationFilterInput>;
  group?: Maybe<AppSuiteGroupFilterInput>;
  user?: Maybe<AppSuiteUserFilterInput>;
};

export type AppSuiteUser = {
  __typename?: "AppSuiteUser";
  userId: Scalars["Int"];
  userName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  initials?: Maybe<Scalars["String"]>;
  isActive: Scalars["Boolean"];
  isAdmin?: Maybe<Scalars["Boolean"]>;
  userIcon?: Maybe<Array<Scalars["Byte"]>>;
  slackUserId?: Maybe<Scalars["String"]>;
  groupAccountId?: Maybe<Scalars["Int"]>;
  githubId?: Maybe<Scalars["String"]>;
  groupAccount?: Maybe<AppSuiteGroup>;
  userSetting?: Maybe<UserSetting>;
  cellLineRegisteredByUsers?: Maybe<Array<Maybe<CellLine>>>;
  cellLineUpdatedByUsers?: Maybe<Array<Maybe<CellLine>>>;
  coatedPlateBatches?: Maybe<Array<Maybe<CoatedPlateBatch>>>;
  collectionTasks?: Maybe<Array<Maybe<CollectionTask>>>;
  collections?: Maybe<Array<Maybe<Collection>>>;
  containerComments?: Maybe<Array<Maybe<ContainerComment>>>;
  containerMarkings?: Maybe<Array<Maybe<ContainerMarking>>>;
  containerNotes?: Maybe<Array<Maybe<ContainerNote>>>;
  donorComments?: Maybe<Array<Maybe<DonorComment>>>;
  folderStructures?: Maybe<Array<Maybe<FolderStructure>>>;
  methodReservationCompletedByUsers?: Maybe<Array<Maybe<MethodReservation>>>;
  methodReservationUsers?: Maybe<Array<Maybe<MethodReservation>>>;
  normalizedPassageTemplates?: Maybe<Array<Maybe<NormalizedPassageTemplate>>>;
  personalTaskCompletedByNavigations?: Maybe<Array<Maybe<PersonalTask>>>;
  personalTaskTaskOwners?: Maybe<Array<Maybe<PersonalTask>>>;
  personalTaskTemplateTasks?: Maybe<Array<Maybe<PersonalTaskTemplateTask>>>;
  personalTaskTemplates?: Maybe<Array<Maybe<PersonalTaskTemplate>>>;
  plateNotes?: Maybe<Array<Maybe<PlateNote>>>;
  platesCreateds?: Maybe<Array<Maybe<PlatesCreated>>>;
  productionRuns?: Maybe<Array<Maybe<ProductionRun>>>;
  runTasks?: Maybe<Array<Maybe<RunTask>>>;
  runTemplateCreatedByNavigations?: Maybe<Array<Maybe<RunTemplate>>>;
  runTemplateDeletedBies?: Maybe<Array<Maybe<RunTemplate>>>;
  runTemplateLastUpdatedByNavigations?: Maybe<Array<Maybe<RunTemplate>>>;
  runs?: Maybe<Array<Maybe<Run>>>;
  ticketAssignees?: Maybe<Array<Maybe<TicketAssignee>>>;
  ticketFollowers?: Maybe<Array<Maybe<TicketFollower>>>;
  ticketUpdates?: Maybe<Array<Maybe<TicketUpdate>>>;
  userToRoles?: Maybe<Array<Maybe<UserToRole>>>;
  worklistToolTemplates?: Maybe<Array<Maybe<WorklistToolTemplate>>>;
  collectionTasksNavigation?: Maybe<Array<Maybe<CollectionTask>>>;
  personalTaskTemplateTasksNavigation?: Maybe<
    Array<Maybe<PersonalTaskTemplateTask>>
  >;
  personalTasks?: Maybe<Array<Maybe<PersonalTask>>>;
  runTasksNavigation?: Maybe<Array<Maybe<RunTask>>>;
  runTemplateTasks?: Maybe<Array<Maybe<RunTemplateTask>>>;
  groups?: Maybe<Array<Maybe<AppSuiteGroup>>>;
  roles?: Maybe<Array<Role>>;
};

export type AppSuiteUserFilterInput = {
  and?: Maybe<Array<AppSuiteUserFilterInput>>;
  or?: Maybe<Array<AppSuiteUserFilterInput>>;
  userId?: Maybe<ComparableInt32OperationFilterInput>;
  userName?: Maybe<StringOperationFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  icon?: Maybe<StringOperationFilterInput>;
  initials?: Maybe<StringOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  password?: Maybe<ListComparableByteOperationFilterInput>;
  saltString?: Maybe<StringOperationFilterInput>;
  temporaryPassword?: Maybe<ListComparableByteOperationFilterInput>;
  isAdmin?: Maybe<BooleanOperationFilterInput>;
  userIcon?: Maybe<ListComparableByteOperationFilterInput>;
  slackUserId?: Maybe<StringOperationFilterInput>;
  personalComputerMachineName?: Maybe<StringOperationFilterInput>;
  groupAccountId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  githubId?: Maybe<StringOperationFilterInput>;
  groupAccount?: Maybe<AppSuiteGroupFilterInput>;
  userSetting?: Maybe<UserSettingFilterInput>;
  appSuiteGroupUsers?: Maybe<ListFilterInputTypeOfAppSuiteGroupUserFilterInput>;
  cellLineRegisteredByUsers?: Maybe<ListFilterInputTypeOfCellLineFilterInput>;
  cellLineUpdatedByUsers?: Maybe<ListFilterInputTypeOfCellLineFilterInput>;
  coatedPlateBatches?: Maybe<ListFilterInputTypeOfCoatedPlateBatchFilterInput>;
  collectionTasks?: Maybe<ListFilterInputTypeOfCollectionTaskFilterInput>;
  collections?: Maybe<ListFilterInputTypeOfCollectionFilterInput>;
  containerComments?: Maybe<ListFilterInputTypeOfContainerCommentFilterInput>;
  containerMarkings?: Maybe<ListFilterInputTypeOfContainerMarkingFilterInput>;
  containerNotes?: Maybe<ListFilterInputTypeOfContainerNoteFilterInput>;
  donorComments?: Maybe<ListFilterInputTypeOfDonorCommentFilterInput>;
  folderStructures?: Maybe<ListFilterInputTypeOfFolderStructureFilterInput>;
  methodReservationCompletedByUsers?: Maybe<ListFilterInputTypeOfMethodReservationFilterInput>;
  methodReservationUsers?: Maybe<ListFilterInputTypeOfMethodReservationFilterInput>;
  normalizedPassageTemplates?: Maybe<ListFilterInputTypeOfNormalizedPassageTemplateFilterInput>;
  personalTaskCompletedByNavigations?: Maybe<ListFilterInputTypeOfPersonalTaskFilterInput>;
  personalTaskTaskOwners?: Maybe<ListFilterInputTypeOfPersonalTaskFilterInput>;
  personalTaskTemplateTasks?: Maybe<ListFilterInputTypeOfPersonalTaskTemplateTaskFilterInput>;
  personalTaskTemplates?: Maybe<ListFilterInputTypeOfPersonalTaskTemplateFilterInput>;
  plateNotes?: Maybe<ListFilterInputTypeOfPlateNoteFilterInput>;
  platesCreateds?: Maybe<ListFilterInputTypeOfPlatesCreatedFilterInput>;
  productionRuns?: Maybe<ListFilterInputTypeOfProductionRunFilterInput>;
  runTasks?: Maybe<ListFilterInputTypeOfRunTaskFilterInput>;
  runTemplateCreatedByNavigations?: Maybe<ListFilterInputTypeOfRunTemplateFilterInput>;
  runTemplateDeletedBies?: Maybe<ListFilterInputTypeOfRunTemplateFilterInput>;
  runTemplateLastUpdatedByNavigations?: Maybe<ListFilterInputTypeOfRunTemplateFilterInput>;
  runs?: Maybe<ListFilterInputTypeOfRunFilterInput>;
  ticketAssignees?: Maybe<ListFilterInputTypeOfTicketAssigneeFilterInput>;
  ticketFollowers?: Maybe<ListFilterInputTypeOfTicketFollowerFilterInput>;
  ticketUpdates?: Maybe<ListFilterInputTypeOfTicketUpdateFilterInput>;
  userToRoles?: Maybe<ListFilterInputTypeOfUserToRoleFilterInput>;
  worklistToolTemplates?: Maybe<ListFilterInputTypeOfWorklistToolTemplateFilterInput>;
  collectionTasksNavigation?: Maybe<ListFilterInputTypeOfCollectionTaskFilterInput>;
  personalTaskTemplateTasksNavigation?: Maybe<ListFilterInputTypeOfPersonalTaskTemplateTaskFilterInput>;
  personalTasks?: Maybe<ListFilterInputTypeOfPersonalTaskFilterInput>;
  runTasksNavigation?: Maybe<ListFilterInputTypeOfRunTaskFilterInput>;
  runTemplateTasks?: Maybe<ListFilterInputTypeOfRunTemplateTaskFilterInput>;
};

export type AppSuiteUserSortInput = {
  userId?: Maybe<SortEnumType>;
  userName?: Maybe<SortEnumType>;
  email?: Maybe<SortEnumType>;
  icon?: Maybe<SortEnumType>;
  initials?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  saltString?: Maybe<SortEnumType>;
  isAdmin?: Maybe<SortEnumType>;
  slackUserId?: Maybe<SortEnumType>;
  personalComputerMachineName?: Maybe<SortEnumType>;
  groupAccountId?: Maybe<SortEnumType>;
  githubId?: Maybe<SortEnumType>;
  groupAccount?: Maybe<AppSuiteGroupSortInput>;
  userSetting?: Maybe<UserSettingSortInput>;
};

export enum ApplyPolicy {
  BeforeResolver = "BEFORE_RESOLVER",
  AfterResolver = "AFTER_RESOLVER",
}

export type ArrayMethodLabwareType = {
  __typename?: "ArrayMethodLabwareType";
  arrayMethodLabwareTypeId: Scalars["Int"];
  arrayMethodId: Scalars["Int"];
  labwareTypeId: Scalars["Int"];
  methodPlateRoleId: Scalars["Int"];
  isDefault?: Maybe<Scalars["Boolean"]>;
  arrayMethod?: Maybe<LookupArrayMethod>;
  labwareType?: Maybe<LookupLabwareType>;
  methodPlateRole?: Maybe<LookupArrayMethodPlateRole>;
};

export type ArrayMethodLabwareTypeFilterInput = {
  and?: Maybe<Array<ArrayMethodLabwareTypeFilterInput>>;
  or?: Maybe<Array<ArrayMethodLabwareTypeFilterInput>>;
  arrayMethodLabwareTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  arrayMethodId?: Maybe<ComparableInt32OperationFilterInput>;
  labwareTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  methodPlateRoleId?: Maybe<ComparableInt32OperationFilterInput>;
  isDefault?: Maybe<BooleanOperationFilterInput>;
  arrayMethod?: Maybe<LookupArrayMethodFilterInput>;
  labwareType?: Maybe<LookupLabwareTypeFilterInput>;
  methodPlateRole?: Maybe<LookupArrayMethodPlateRoleFilterInput>;
};

export type ArrayMethodPlate = {
  __typename?: "ArrayMethodPlate";
  arrayMethodPlateId: Scalars["Int"];
  arrayMethodId: Scalars["Int"];
  arrayMethodVariationId?: Maybe<Scalars["Int"]>;
  arrayMethodPlateRoleId: Scalars["Int"];
  minNumberInvolved: Scalars["Int"];
  maxNumberInvolved: Scalars["Int"];
  srcArrayMethodPlateRoleId?: Maybe<Scalars["Int"]>;
  printPlate?: Maybe<Scalars["Boolean"]>;
  arrayPlateCodeId?: Maybe<Scalars["Int"]>;
  arrayPlateCodeOptions?: Maybe<Scalars["String"]>;
  worklistTask?: Maybe<Scalars["String"]>;
  worklistColumnId?: Maybe<Scalars["Int"]>;
  minPlateAspirateVol?: Maybe<Scalars["Int"]>;
  maxPlateAspirateVol?: Maybe<Scalars["Int"]>;
  defaultPlateAspirateVol?: Maybe<Scalars["Int"]>;
  minPlateDispenseVol?: Maybe<Scalars["Int"]>;
  maxPlateDispenseVol?: Maybe<Scalars["Int"]>;
  defaultPlateDispenseVol?: Maybe<Scalars["Int"]>;
  minPlateTransferVol?: Maybe<Scalars["Int"]>;
  maxPlateTransferVol?: Maybe<Scalars["Int"]>;
  defaultPlateTransferVol?: Maybe<Scalars["Int"]>;
  arrayMethod?: Maybe<LookupArrayMethod>;
  arrayMethodPlateRole?: Maybe<LookupArrayMethodPlateRole>;
  arrayPlateCode?: Maybe<LookupArrayPlateCode>;
  srcArrayMethodPlateRole?: Maybe<LookupArrayMethodPlateRole>;
  worklistColumn?: Maybe<WorklistColumn>;
};

export type ArrayMethodPlateFilterInput = {
  and?: Maybe<Array<ArrayMethodPlateFilterInput>>;
  or?: Maybe<Array<ArrayMethodPlateFilterInput>>;
  arrayMethodPlateId?: Maybe<ComparableInt32OperationFilterInput>;
  arrayMethodId?: Maybe<ComparableInt32OperationFilterInput>;
  arrayMethodVariationId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  arrayMethodPlateRoleId?: Maybe<ComparableInt32OperationFilterInput>;
  minNumberInvolved?: Maybe<ComparableInt32OperationFilterInput>;
  maxNumberInvolved?: Maybe<ComparableInt32OperationFilterInput>;
  srcArrayMethodPlateRoleId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  printPlate?: Maybe<BooleanOperationFilterInput>;
  arrayPlateCodeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  arrayPlateCodeOptions?: Maybe<StringOperationFilterInput>;
  worklistTask?: Maybe<StringOperationFilterInput>;
  worklistColumnId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  minPlateAspirateVol?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  maxPlateAspirateVol?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  defaultPlateAspirateVol?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  minPlateDispenseVol?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  maxPlateDispenseVol?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  defaultPlateDispenseVol?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  minPlateTransferVol?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  maxPlateTransferVol?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  defaultPlateTransferVol?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  arrayMethod?: Maybe<LookupArrayMethodFilterInput>;
  arrayMethodPlateRole?: Maybe<LookupArrayMethodPlateRoleFilterInput>;
  arrayPlateCode?: Maybe<LookupArrayPlateCodeFilterInput>;
  srcArrayMethodPlateRole?: Maybe<LookupArrayMethodPlateRoleFilterInput>;
  worklistColumn?: Maybe<WorklistColumnFilterInput>;
};

export type ArraySystemAutomationMethod = {
  __typename?: "ArraySystemAutomationMethod";
  arraySystemAutomationMethodId: Scalars["Int"];
  automationMethodCode?: Maybe<Scalars["String"]>;
  arraySystemId: Scalars["Int"];
  automationMethodId: Scalars["Int"];
  isActive?: Maybe<Scalars["Boolean"]>;
  averageRunTimeSeconds?: Maybe<Scalars["Int"]>;
  arraySystem?: Maybe<LookupArraySystem>;
  automationMethod?: Maybe<LookupAutomationMethod>;
  arraySystemAutomationMethodLabwareTypes?: Maybe<
    Array<Maybe<ArraySystemAutomationMethodLabwareType>>
  >;
  arraySystemAutomationMethodProcesses?: Maybe<
    Array<Maybe<ArraySystemAutomationMethodProcess>>
  >;
  feedGroups?: Maybe<Array<Maybe<FeedGroup>>>;
  lookupAssets?: Maybe<Array<Maybe<LookupAsset>>>;
};

export type ArraySystemAutomationMethodFilterInput = {
  and?: Maybe<Array<ArraySystemAutomationMethodFilterInput>>;
  or?: Maybe<Array<ArraySystemAutomationMethodFilterInput>>;
  arraySystemAutomationMethodId?: Maybe<ComparableInt32OperationFilterInput>;
  automationMethodCode?: Maybe<StringOperationFilterInput>;
  arraySystemId?: Maybe<ComparableInt32OperationFilterInput>;
  automationMethodId?: Maybe<ComparableInt32OperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  averageRunTimeSeconds?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  arraySystem?: Maybe<LookupArraySystemFilterInput>;
  automationMethod?: Maybe<LookupAutomationMethodFilterInput>;
  arraySystemAutomationMethodLabwareTypes?: Maybe<ListFilterInputTypeOfArraySystemAutomationMethodLabwareTypeFilterInput>;
  arraySystemAutomationMethodProcesses?: Maybe<ListFilterInputTypeOfArraySystemAutomationMethodProcessFilterInput>;
  feedGroups?: Maybe<ListFilterInputTypeOfFeedGroupFilterInput>;
  lookupAssets?: Maybe<ListFilterInputTypeOfLookupAssetFilterInput>;
};

export type ArraySystemAutomationMethodLabwareType = {
  __typename?: "ArraySystemAutomationMethodLabwareType";
  arraySystemAutomationMethodLabwareTypeId: Scalars["Int"];
  arraySystemAutomationMethodId: Scalars["Int"];
  labwareTypeId: Scalars["Int"];
  arrayMethodPlateRoleId: Scalars["Int"];
  isDefault: Scalars["Boolean"];
  arrayMethodVariationId?: Maybe<Scalars["Int"]>;
  arrayMethodPlateRole?: Maybe<LookupArrayMethodPlateRole>;
  arraySystemAutomationMethod?: Maybe<ArraySystemAutomationMethod>;
  labwareType?: Maybe<LookupLabwareType>;
};

export type ArraySystemAutomationMethodLabwareTypeFilterInput = {
  and?: Maybe<Array<ArraySystemAutomationMethodLabwareTypeFilterInput>>;
  or?: Maybe<Array<ArraySystemAutomationMethodLabwareTypeFilterInput>>;
  arraySystemAutomationMethodLabwareTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  arraySystemAutomationMethodId?: Maybe<ComparableInt32OperationFilterInput>;
  labwareTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  arrayMethodPlateRoleId?: Maybe<ComparableInt32OperationFilterInput>;
  isDefault?: Maybe<BooleanOperationFilterInput>;
  arrayMethodVariationId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  arrayMethodPlateRole?: Maybe<LookupArrayMethodPlateRoleFilterInput>;
  arraySystemAutomationMethod?: Maybe<ArraySystemAutomationMethodFilterInput>;
  labwareType?: Maybe<LookupLabwareTypeFilterInput>;
};

export type ArraySystemAutomationMethodProcess = {
  __typename?: "ArraySystemAutomationMethodProcess";
  arraySystemAutomationMethodProcessId: Scalars["Int"];
  arraySystemAutomationMethodId: Scalars["Int"];
  automationMethodProcessRunTypeId: Scalars["Int"];
  usesCytomat: Scalars["Boolean"];
  usesVspin: Scalars["Boolean"];
  usesDecapper: Scalars["Boolean"];
  usesEasyCode: Scalars["Boolean"];
  expectedRunTime?: Maybe<Scalars["Int"]>;
  arraySystemAutomationMethod?: Maybe<ArraySystemAutomationMethod>;
  automationMethodProcessRunType?: Maybe<LookupAutomationMethodProcessRunType>;
  arraySystemAutomationMethodProcessRuns?: Maybe<
    Array<Maybe<ArraySystemAutomationMethodProcessRun>>
  >;
  arraySystemAutomationMethodProcessSteps?: Maybe<
    Array<Maybe<ArraySystemAutomationMethodProcessStep>>
  >;
};

export type ArraySystemAutomationMethodProcessFilterInput = {
  and?: Maybe<Array<ArraySystemAutomationMethodProcessFilterInput>>;
  or?: Maybe<Array<ArraySystemAutomationMethodProcessFilterInput>>;
  arraySystemAutomationMethodProcessId?: Maybe<ComparableInt32OperationFilterInput>;
  arraySystemAutomationMethodId?: Maybe<ComparableInt32OperationFilterInput>;
  automationMethodProcessRunTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  usesCytomat?: Maybe<BooleanOperationFilterInput>;
  usesVspin?: Maybe<BooleanOperationFilterInput>;
  usesDecapper?: Maybe<BooleanOperationFilterInput>;
  usesEasyCode?: Maybe<BooleanOperationFilterInput>;
  expectedRunTime?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  arraySystemAutomationMethod?: Maybe<ArraySystemAutomationMethodFilterInput>;
  automationMethodProcessRunType?: Maybe<LookupAutomationMethodProcessRunTypeFilterInput>;
  arraySystemAutomationMethodProcessRuns?: Maybe<ListFilterInputTypeOfArraySystemAutomationMethodProcessRunFilterInput>;
  arraySystemAutomationMethodProcessSteps?: Maybe<ListFilterInputTypeOfArraySystemAutomationMethodProcessStepFilterInput>;
};

export type ArraySystemAutomationMethodProcessRun = {
  __typename?: "ArraySystemAutomationMethodProcessRun";
  arraySystemAutomationMethodProcessRunId: Scalars["Int"];
  arraySystemAutomationMethodProcessId?: Maybe<Scalars["Int"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  lastUpdatedAt?: Maybe<Scalars["DateTime"]>;
  automationMethodProcessRunStatusId?: Maybe<Scalars["Int"]>;
  lastArraySystemAutomationMethodProcessStepId?: Maybe<Scalars["Int"]>;
  lastPlateBarcode?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  isSimulated?: Maybe<Scalars["Boolean"]>;
  countNtrtips?: Maybe<Scalars["Int"]>;
  count1000uLtips?: Maybe<Scalars["Int"]>;
  count300uLtips?: Maybe<Scalars["Int"]>;
  capacityNtrtips?: Maybe<Scalars["Int"]>;
  capacity1000uLtips?: Maybe<Scalars["Int"]>;
  capacity300uLtips?: Maybe<Scalars["Int"]>;
  incubationTime?: Maybe<Scalars["Int"]>;
  vspinTime?: Maybe<Scalars["Int"]>;
  runTime?: Maybe<Scalars["Int"]>;
  worklistId?: Maybe<Scalars["Int"]>;
  parsedRunInstanceId?: Maybe<Scalars["Int"]>;
  parsedSystem?: Maybe<Scalars["String"]>;
  parsedAutomationMethod?: Maybe<Scalars["String"]>;
  parsedAutomationMethodCode?: Maybe<Scalars["String"]>;
  parsedLastStep?: Maybe<Scalars["String"]>;
  parsedUsesCytomat?: Maybe<Scalars["Boolean"]>;
  parsedUsesVspin?: Maybe<Scalars["Boolean"]>;
  parsedUsesDecapper?: Maybe<Scalars["Boolean"]>;
  parsedUsesEasyCode?: Maybe<Scalars["Boolean"]>;
  parsedRunTime?: Maybe<Scalars["Int"]>;
  parsedWorklistContents?: Maybe<Scalars["String"]>;
  arraySystemAutomationMethodProcess?: Maybe<ArraySystemAutomationMethodProcess>;
  automationMethodProcessRunStatus?: Maybe<LookupAutomationMethodProcessRunStatus>;
  arraySystemAutomationMethodProcessRunSteps?: Maybe<
    Array<Maybe<ArraySystemAutomationMethodProcessRunStep>>
  >;
};

export type ArraySystemAutomationMethodProcessRunFilterInput = {
  and?: Maybe<Array<ArraySystemAutomationMethodProcessRunFilterInput>>;
  or?: Maybe<Array<ArraySystemAutomationMethodProcessRunFilterInput>>;
  arraySystemAutomationMethodProcessRunId?: Maybe<ComparableInt32OperationFilterInput>;
  arraySystemAutomationMethodProcessId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  startedAt?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  lastUpdatedAt?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  automationMethodProcessRunStatusId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  lastArraySystemAutomationMethodProcessStepId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  lastPlateBarcode?: Maybe<StringOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  isSimulated?: Maybe<BooleanOperationFilterInput>;
  countNtrtips?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  count1000uLtips?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  count300uLtips?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  capacityNtrtips?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  capacity1000uLtips?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  capacity300uLtips?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  incubationTime?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  vspinTime?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  runTime?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  worklistId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  parsedRunInstanceId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  parsedSystem?: Maybe<StringOperationFilterInput>;
  parsedAutomationMethod?: Maybe<StringOperationFilterInput>;
  parsedAutomationMethodCode?: Maybe<StringOperationFilterInput>;
  parsedLastStep?: Maybe<StringOperationFilterInput>;
  parsedUsesCytomat?: Maybe<BooleanOperationFilterInput>;
  parsedUsesVspin?: Maybe<BooleanOperationFilterInput>;
  parsedUsesDecapper?: Maybe<BooleanOperationFilterInput>;
  parsedUsesEasyCode?: Maybe<BooleanOperationFilterInput>;
  parsedRunTime?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  parsedWorklistContents?: Maybe<StringOperationFilterInput>;
  arraySystemAutomationMethodProcess?: Maybe<ArraySystemAutomationMethodProcessFilterInput>;
  automationMethodProcessRunStatus?: Maybe<LookupAutomationMethodProcessRunStatusFilterInput>;
  arraySystemAutomationMethodProcessRunSteps?: Maybe<ListFilterInputTypeOfArraySystemAutomationMethodProcessRunStepFilterInput>;
};

export type ArraySystemAutomationMethodProcessRunStep = {
  __typename?: "ArraySystemAutomationMethodProcessRunStep";
  arraySystemAutomationMethodProcessRunStepId: Scalars["Int"];
  arraySystemAutomationMethodProcessRunId: Scalars["Int"];
  arraySystemAutomationMethodProcessStepId?: Maybe<Scalars["Int"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  sourcePlateBarcode?: Maybe<Scalars["String"]>;
  destinationPlateBarcode?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  isComplete?: Maybe<Scalars["Boolean"]>;
  requiredNtrtips?: Maybe<Scalars["Int"]>;
  required1000uLtips?: Maybe<Scalars["Int"]>;
  required300uLtips?: Maybe<Scalars["Int"]>;
  consumptionNtrtips?: Maybe<Scalars["Int"]>;
  consumption1000uLtips?: Maybe<Scalars["Int"]>;
  consumption300uLtips?: Maybe<Scalars["Int"]>;
  runTime?: Maybe<Scalars["Decimal"]>;
  parsedRunProcessId?: Maybe<Scalars["Int"]>;
  parsedStep?: Maybe<Scalars["String"]>;
  arraySystemAutomationMethodProcessRun?: Maybe<ArraySystemAutomationMethodProcessRun>;
  arraySystemAutomationMethodProcessStep?: Maybe<ArraySystemAutomationMethodProcessStep>;
};

export type ArraySystemAutomationMethodProcessRunStepFilterInput = {
  and?: Maybe<Array<ArraySystemAutomationMethodProcessRunStepFilterInput>>;
  or?: Maybe<Array<ArraySystemAutomationMethodProcessRunStepFilterInput>>;
  arraySystemAutomationMethodProcessRunStepId?: Maybe<ComparableInt32OperationFilterInput>;
  arraySystemAutomationMethodProcessRunId?: Maybe<ComparableInt32OperationFilterInput>;
  arraySystemAutomationMethodProcessStepId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  startedAt?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  sourcePlateBarcode?: Maybe<StringOperationFilterInput>;
  destinationPlateBarcode?: Maybe<StringOperationFilterInput>;
  details?: Maybe<StringOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  isComplete?: Maybe<BooleanOperationFilterInput>;
  requiredNtrtips?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  required1000uLtips?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  required300uLtips?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  consumptionNtrtips?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  consumption1000uLtips?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  consumption300uLtips?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  runTime?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  parsedRunProcessId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  parsedStep?: Maybe<StringOperationFilterInput>;
  arraySystemAutomationMethodProcessRun?: Maybe<ArraySystemAutomationMethodProcessRunFilterInput>;
  arraySystemAutomationMethodProcessStep?: Maybe<ArraySystemAutomationMethodProcessStepFilterInput>;
};

export type ArraySystemAutomationMethodProcessStep = {
  __typename?: "ArraySystemAutomationMethodProcessStep";
  arraySystemAutomationMethodProcessStepId: Scalars["Int"];
  arraySystemAutomationMethodProcessId: Scalars["Int"];
  automationMethodProcessStepTypeId: Scalars["Int"];
  worklistDetailFieldId: Scalars["Int"];
  processStepPosition: Scalars["Int"];
  processStepInnerPosition: Scalars["Int"];
  automationMethodProcessStepControlStringId?: Maybe<Scalars["Int"]>;
  isTracked: Scalars["Boolean"];
  usageNtrtips: Scalars["Int"];
  usage1000uLtips: Scalars["Int"];
  usage300uLtips: Scalars["Int"];
  expectedRuntime?: Maybe<Scalars["Int"]>;
  batchSize?: Maybe<Scalars["Int"]>;
  arraySystemAutomationMethodProcess?: Maybe<ArraySystemAutomationMethodProcess>;
  arraySystemAutomationMethodProcessRunSteps?: Maybe<
    Array<Maybe<ArraySystemAutomationMethodProcessRunStep>>
  >;
};

export type ArraySystemAutomationMethodProcessStepFilterInput = {
  and?: Maybe<Array<ArraySystemAutomationMethodProcessStepFilterInput>>;
  or?: Maybe<Array<ArraySystemAutomationMethodProcessStepFilterInput>>;
  arraySystemAutomationMethodProcessStepId?: Maybe<ComparableInt32OperationFilterInput>;
  arraySystemAutomationMethodProcessId?: Maybe<ComparableInt32OperationFilterInput>;
  automationMethodProcessStepTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  worklistDetailFieldId?: Maybe<ComparableInt32OperationFilterInput>;
  processStepPosition?: Maybe<ComparableInt32OperationFilterInput>;
  processStepInnerPosition?: Maybe<ComparableInt32OperationFilterInput>;
  automationMethodProcessStepControlStringId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  isTracked?: Maybe<BooleanOperationFilterInput>;
  usageNtrtips?: Maybe<ComparableInt32OperationFilterInput>;
  usage1000uLtips?: Maybe<ComparableInt32OperationFilterInput>;
  usage300uLtips?: Maybe<ComparableInt32OperationFilterInput>;
  expectedRuntime?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  batchSize?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  arraySystemAutomationMethodProcess?: Maybe<ArraySystemAutomationMethodProcessFilterInput>;
  arraySystemAutomationMethodProcessRunSteps?: Maybe<ListFilterInputTypeOfArraySystemAutomationMethodProcessRunStepFilterInput>;
};

export type ArraySystemAutomationMethodSortInput = {
  arraySystemAutomationMethodId?: Maybe<SortEnumType>;
  automationMethodCode?: Maybe<SortEnumType>;
  arraySystemId?: Maybe<SortEnumType>;
  automationMethodId?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  averageRunTimeSeconds?: Maybe<SortEnumType>;
  arraySystem?: Maybe<LookupArraySystemSortInput>;
  automationMethod?: Maybe<LookupAutomationMethodSortInput>;
};

export type AssetInput = {
  asset?: Maybe<Scalars["String"]>;
  assetTypeId: Scalars["Int"];
  serialNumber?: Maybe<Scalars["String"]>;
  manufacturer?: Maybe<Scalars["String"]>;
  vendor?: Maybe<Scalars["String"]>;
  contractId?: Maybe<Scalars["Int"]>;
  designName?: Maybe<Scalars["String"]>;
  documentationUrl?: Maybe<Scalars["String"]>;
};

export type AssignTaskInput = {
  selectedTasks: Array<SelectedTaskInput>;
  assignedUsers?: Maybe<Array<Scalars["Int"]>>;
  assignedGroups?: Maybe<Array<Scalars["Int"]>>;
};

export type BooleanOperationFilterInput = {
  eq?: Maybe<Scalars["Boolean"]>;
  neq?: Maybe<Scalars["Boolean"]>;
};

export type CalendarNode = {
  __typename?: "CalendarNode";
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  taskType: TaskTypeEnum;
  runId?: Maybe<Scalars["Int"]>;
  startTimeScheduled: Scalars["DateTime"];
  endTimeScheduled: Scalars["DateTime"];
  startTimeActual?: Maybe<Scalars["DateTime"]>;
  endTimeActual?: Maybe<Scalars["DateTime"]>;
  startTime: Scalars["DateTime"];
  endTime: Scalars["DateTime"];
  status: TaskStatusEnum;
  priority: TaskPriorityEnum;
  systemAssignedTo?: Maybe<Scalars["Int"]>;
  groups?: Maybe<Array<Maybe<AppSuiteGroup>>>;
  users?: Maybe<Array<Maybe<AppSuiteUser>>>;
  methodReservations?: Maybe<Array<Maybe<MethodReservation>>>;
  systemAssignedToNavigation?: Maybe<LookupArraySystem>;
  method?: Maybe<LookupArrayMethod>;
  manualTaskType?: Maybe<ManualTaskType>;
  runName?: Maybe<Scalars["String"]>;
  lastTimeUpdated?: Maybe<Scalars["DateTime"]>;
  updatedBy?: Maybe<Scalars["Int"]>;
  updatedByName?: Maybe<Scalars["String"]>;
  inProgress: Scalars["Boolean"];
};

export type CalendarNodeFilterInput = {
  and?: Maybe<Array<CalendarNodeFilterInput>>;
  or?: Maybe<Array<CalendarNodeFilterInput>>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  taskType?: Maybe<TaskTypeEnumOperationFilterInput>;
  runId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  startTimeScheduled?: Maybe<ComparableDateTimeOperationFilterInput>;
  endTimeScheduled?: Maybe<ComparableDateTimeOperationFilterInput>;
  startTimeActual?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  endTimeActual?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  startTime?: Maybe<ComparableDateTimeOperationFilterInput>;
  endTime?: Maybe<ComparableDateTimeOperationFilterInput>;
  status?: Maybe<TaskStatusEnumOperationFilterInput>;
  priority?: Maybe<TaskPriorityEnumOperationFilterInput>;
  systemAssignedTo?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  groups?: Maybe<ListFilterInputTypeOfAppSuiteGroupFilterInput>;
  users?: Maybe<ListFilterInputTypeOfAppSuiteUserFilterInput>;
  methodReservations?: Maybe<ListFilterInputTypeOfMethodReservationFilterInput>;
  systemAssignedToNavigation?: Maybe<LookupArraySystemFilterInput>;
  method?: Maybe<LookupArrayMethodFilterInput>;
  manualTaskType?: Maybe<ManualTaskTypeFilterInput>;
  runName?: Maybe<StringOperationFilterInput>;
  lastTimeUpdated?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updatedBy?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  updatedByName?: Maybe<StringOperationFilterInput>;
  inProgress?: Maybe<BooleanOperationFilterInput>;
};

export type CellLine = {
  __typename?: "CellLine";
  cellLineId: Scalars["Int"];
  sourceCellLine?: Maybe<Scalars["String"]>;
  donorId: Scalars["Int"];
  collectionNumber: Scalars["Int"];
  sourceSampleTypeId: Scalars["Int"];
  ageAtCollection?: Maybe<Scalars["String"]>;
  yearCollected?: Maybe<Scalars["Int"]>;
  passageNumberAtThaw?: Maybe<Scalars["Int"]>;
  workflowPriority?: Maybe<Scalars["Int"]>;
  iPsclinesRequired?: Maybe<Scalars["Int"]>;
  requiresMonoclonalization: Scalars["Boolean"];
  requiresMediaProcess: Scalars["Boolean"];
  distributable?: Maybe<Scalars["String"]>;
  providedAsIs: Scalars["Boolean"];
  vendorId?: Maybe<Scalars["Int"]>;
  comments?: Maybe<Scalars["String"]>;
  dateRegistered?: Maybe<Scalars["DateTime"]>;
  registeredByUserId?: Maybe<Scalars["Int"]>;
  dateLastUpdated?: Maybe<Scalars["DateTime"]>;
  updatedByUserId?: Maybe<Scalars["Int"]>;
  donor?: Maybe<Donor>;
  registeredByUser?: Maybe<AppSuiteUser>;
  sourceSampleType?: Maybe<LookupSampleType>;
  updatedByUser?: Maybe<AppSuiteUser>;
  samples?: Maybe<Array<Maybe<Sample>>>;
};

export type CellLineFilterInput = {
  and?: Maybe<Array<CellLineFilterInput>>;
  or?: Maybe<Array<CellLineFilterInput>>;
  cellLineId?: Maybe<ComparableInt32OperationFilterInput>;
  sourceCellLine?: Maybe<StringOperationFilterInput>;
  donorId?: Maybe<ComparableInt32OperationFilterInput>;
  collectionNumber?: Maybe<ComparableInt32OperationFilterInput>;
  sourceSampleTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  ageAtCollection?: Maybe<StringOperationFilterInput>;
  yearCollected?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  passageNumberAtThaw?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  workflowPriority?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  iPsclinesRequired?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  requiresMonoclonalization?: Maybe<BooleanOperationFilterInput>;
  requiresMediaProcess?: Maybe<BooleanOperationFilterInput>;
  distributable?: Maybe<StringOperationFilterInput>;
  providedAsIs?: Maybe<BooleanOperationFilterInput>;
  vendorId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  comments?: Maybe<StringOperationFilterInput>;
  dateRegistered?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  registeredByUserId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  dateLastUpdated?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updatedByUserId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  donor?: Maybe<DonorFilterInput>;
  registeredByUser?: Maybe<AppSuiteUserFilterInput>;
  sourceSampleType?: Maybe<LookupSampleTypeFilterInput>;
  updatedByUser?: Maybe<AppSuiteUserFilterInput>;
  samples?: Maybe<ListFilterInputTypeOfSampleFilterInput>;
};

export type CellLineSortInput = {
  cellLineId?: Maybe<SortEnumType>;
  sourceCellLine?: Maybe<SortEnumType>;
  donorId?: Maybe<SortEnumType>;
  collectionNumber?: Maybe<SortEnumType>;
  sourceSampleTypeId?: Maybe<SortEnumType>;
  ageAtCollection?: Maybe<SortEnumType>;
  yearCollected?: Maybe<SortEnumType>;
  passageNumberAtThaw?: Maybe<SortEnumType>;
  workflowPriority?: Maybe<SortEnumType>;
  iPsclinesRequired?: Maybe<SortEnumType>;
  requiresMonoclonalization?: Maybe<SortEnumType>;
  requiresMediaProcess?: Maybe<SortEnumType>;
  distributable?: Maybe<SortEnumType>;
  providedAsIs?: Maybe<SortEnumType>;
  vendorId?: Maybe<SortEnumType>;
  comments?: Maybe<SortEnumType>;
  dateRegistered?: Maybe<SortEnumType>;
  registeredByUserId?: Maybe<SortEnumType>;
  dateLastUpdated?: Maybe<SortEnumType>;
  updatedByUserId?: Maybe<SortEnumType>;
  donor?: Maybe<DonorSortInput>;
  registeredByUser?: Maybe<AppSuiteUserSortInput>;
  sourceSampleType?: Maybe<LookupSampleTypeSortInput>;
  updatedByUser?: Maybe<AppSuiteUserSortInput>;
};

export type Cluster = {
  __typename?: "Cluster";
  clusterId: Scalars["Int"];
  cluster1?: Maybe<Scalars["String"]>;
  lookupArraySystems?: Maybe<Array<Maybe<LookupArraySystem>>>;
};

export type ClusterFilterInput = {
  and?: Maybe<Array<ClusterFilterInput>>;
  or?: Maybe<Array<ClusterFilterInput>>;
  clusterId?: Maybe<ComparableInt32OperationFilterInput>;
  cluster1?: Maybe<StringOperationFilterInput>;
  lookupArraySystems?: Maybe<ListFilterInputTypeOfLookupArraySystemFilterInput>;
};

export type ClusterSortInput = {
  clusterId?: Maybe<SortEnumType>;
  cluster1?: Maybe<SortEnumType>;
};

export type CoatedPlateBatch = {
  __typename?: "CoatedPlateBatch";
  batchId: Scalars["Int"];
  batchNumber: Scalars["Int"];
  batchTypeId: Scalars["Int"];
  createdByUserId: Scalars["Int"];
  createdDate: Scalars["DateTime"];
  coatingLotNumber?: Maybe<Scalars["String"]>;
  mediaLotNumber?: Maybe<Scalars["String"]>;
  fromVendorAliquot: Scalars["Boolean"];
  comments?: Maybe<Scalars["String"]>;
  mediaTypeId: Scalars["Int"];
  artifactsPresent: Scalars["Boolean"];
  numPe96w: Scalars["Int"];
  numC96w: Scalars["Int"];
  numC6w: Scalars["Int"];
  numC12w: Scalars["Int"];
  numC24w: Scalars["Int"];
  createdByUser?: Maybe<AppSuiteUser>;
  platesCreateds?: Maybe<Array<Maybe<PlatesCreated>>>;
};

export type CoatedPlateBatchFilterInput = {
  and?: Maybe<Array<CoatedPlateBatchFilterInput>>;
  or?: Maybe<Array<CoatedPlateBatchFilterInput>>;
  batchId?: Maybe<ComparableInt32OperationFilterInput>;
  batchNumber?: Maybe<ComparableInt32OperationFilterInput>;
  batchTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  createdByUserId?: Maybe<ComparableInt32OperationFilterInput>;
  createdDate?: Maybe<ComparableDateTimeOperationFilterInput>;
  coatingLotNumber?: Maybe<StringOperationFilterInput>;
  mediaLotNumber?: Maybe<StringOperationFilterInput>;
  fromVendorAliquot?: Maybe<BooleanOperationFilterInput>;
  comments?: Maybe<StringOperationFilterInput>;
  mediaTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  artifactsPresent?: Maybe<BooleanOperationFilterInput>;
  numPe96w?: Maybe<ComparableInt32OperationFilterInput>;
  numC96w?: Maybe<ComparableInt32OperationFilterInput>;
  numC6w?: Maybe<ComparableInt32OperationFilterInput>;
  numC12w?: Maybe<ComparableInt32OperationFilterInput>;
  numC24w?: Maybe<ComparableInt32OperationFilterInput>;
  createdByUser?: Maybe<AppSuiteUserFilterInput>;
  platesCreateds?: Maybe<ListFilterInputTypeOfPlatesCreatedFilterInput>;
};

export type CoatedPlateBatchSortInput = {
  batchId?: Maybe<SortEnumType>;
  batchNumber?: Maybe<SortEnumType>;
  batchTypeId?: Maybe<SortEnumType>;
  createdByUserId?: Maybe<SortEnumType>;
  createdDate?: Maybe<SortEnumType>;
  coatingLotNumber?: Maybe<SortEnumType>;
  mediaLotNumber?: Maybe<SortEnumType>;
  fromVendorAliquot?: Maybe<SortEnumType>;
  comments?: Maybe<SortEnumType>;
  mediaTypeId?: Maybe<SortEnumType>;
  artifactsPresent?: Maybe<SortEnumType>;
  numPe96w?: Maybe<SortEnumType>;
  numC96w?: Maybe<SortEnumType>;
  numC6w?: Maybe<SortEnumType>;
  numC12w?: Maybe<SortEnumType>;
  numC24w?: Maybe<SortEnumType>;
  createdByUser?: Maybe<AppSuiteUserSortInput>;
};

export type Collection = {
  __typename?: "Collection";
  collectionId: Scalars["Int"];
  projectId: Scalars["Int"];
  dateTimeOfArrival?: Maybe<Scalars["DateTime"]>;
  dateTimeOfCollection?: Maybe<Scalars["DateTime"]>;
  createdBy?: Maybe<Scalars["Int"]>;
  createdByNavigation?: Maybe<AppSuiteUser>;
  project?: Maybe<Project>;
  collectionSampleTypes?: Maybe<Array<Maybe<CollectionSampleType>>>;
};

export type CollectionFilterInput = {
  and?: Maybe<Array<CollectionFilterInput>>;
  or?: Maybe<Array<CollectionFilterInput>>;
  collectionId?: Maybe<ComparableInt32OperationFilterInput>;
  projectId?: Maybe<ComparableInt32OperationFilterInput>;
  dateTimeOfArrival?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  dateTimeOfCollection?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  createdBy?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  createdByNavigation?: Maybe<AppSuiteUserFilterInput>;
  project?: Maybe<ProjectFilterInput>;
  collectionSampleTypes?: Maybe<ListFilterInputTypeOfCollectionSampleTypeFilterInput>;
};

export type CollectionSampleType = {
  __typename?: "CollectionSampleType";
  incomingSampleTypeId: Scalars["Int"];
  collectionId?: Maybe<Scalars["Int"]>;
  dateToProcess?: Maybe<Scalars["DateTime"]>;
  notes?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  sampleTypeId: Scalars["Int"];
  collection?: Maybe<Collection>;
  collectionTasks?: Maybe<Array<Maybe<CollectionTask>>>;
  sampleTypeText?: Maybe<Scalars["String"]>;
  sampleType?: Maybe<LookupSampleType>;
};

export type CollectionSampleTypeFilterInput = {
  and?: Maybe<Array<CollectionSampleTypeFilterInput>>;
  or?: Maybe<Array<CollectionSampleTypeFilterInput>>;
  incomingSampleTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  collectionId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  sampleType?: Maybe<StringOperationFilterInput>;
  dateToProcess?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  notes?: Maybe<StringOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  sampleTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  collection?: Maybe<CollectionFilterInput>;
  sampleTypeNavigation?: Maybe<LookupSampleTypeFilterInput>;
  collectionTasks?: Maybe<ListFilterInputTypeOfCollectionTaskFilterInput>;
};

export type CollectionSampleTypeSortInput = {
  incomingSampleTypeId?: Maybe<SortEnumType>;
  collectionId?: Maybe<SortEnumType>;
  sampleType?: Maybe<SortEnumType>;
  dateToProcess?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  sampleTypeId?: Maybe<SortEnumType>;
  collection?: Maybe<CollectionSortInput>;
  sampleTypeNavigation?: Maybe<LookupSampleTypeSortInput>;
};

export type CollectionSortInput = {
  collectionId?: Maybe<SortEnumType>;
  projectId?: Maybe<SortEnumType>;
  dateTimeOfArrival?: Maybe<SortEnumType>;
  dateTimeOfCollection?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  createdByNavigation?: Maybe<AppSuiteUserSortInput>;
  project?: Maybe<ProjectSortInput>;
};

export type CollectionTask = {
  __typename?: "CollectionTask";
  status: TaskStatusEnum;
  priority: TaskPriorityEnum;
  incomingSampleTaskId: Scalars["Int"];
  incomingSampleTypeId: Scalars["Int"];
  incomingSampleParentTaskId?: Maybe<Scalars["Int"]>;
  task?: Maybe<Scalars["String"]>;
  startTimeScheduled: Scalars["DateTime"];
  endTimeScheduled: Scalars["DateTime"];
  startTimeActual?: Maybe<Scalars["DateTime"]>;
  endTimeActual?: Maybe<Scalars["DateTime"]>;
  userAssignedTo?: Maybe<Scalars["Int"]>;
  groupAssignedTo?: Maybe<Scalars["Int"]>;
  systemAssignedTo?: Maybe<Scalars["Int"]>;
  methodId?: Maybe<Scalars["Int"]>;
  folderParentId?: Maybe<Scalars["Int"]>;
  displayInsideIncomingSamplesFolder?: Maybe<Scalars["Boolean"]>;
  updatedBy?: Maybe<Scalars["Int"]>;
  updatedByTime?: Maybe<Scalars["DateTime"]>;
  completedBy?: Maybe<Scalars["Int"]>;
  completedByTime?: Maybe<Scalars["DateTime"]>;
  folderParent?: Maybe<FolderStructure>;
  groupAssignedToNavigation?: Maybe<AppSuiteGroup>;
  incomingSampleType?: Maybe<CollectionSampleType>;
  method?: Maybe<LookupArrayMethod>;
  systemAssignedToNavigation?: Maybe<LookupArraySystem>;
  userAssignedToNavigation?: Maybe<AppSuiteUser>;
  groups?: Maybe<Array<Maybe<AppSuiteGroup>>>;
  users?: Maybe<Array<Maybe<AppSuiteUser>>>;
};

export type CollectionTaskFilterInput = {
  and?: Maybe<Array<CollectionTaskFilterInput>>;
  or?: Maybe<Array<CollectionTaskFilterInput>>;
  status?: Maybe<TaskStatusEnumOperationFilterInput>;
  priority?: Maybe<TaskPriorityEnumOperationFilterInput>;
  incomingSampleTaskId?: Maybe<ComparableInt32OperationFilterInput>;
  incomingSampleTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  incomingSampleParentTaskId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  task?: Maybe<StringOperationFilterInput>;
  startTimeScheduled?: Maybe<ComparableDateTimeOperationFilterInput>;
  endTimeScheduled?: Maybe<ComparableDateTimeOperationFilterInput>;
  startTimeActual?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  endTimeActual?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  userAssignedTo?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  groupAssignedTo?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  systemAssignedTo?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  methodId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  folderParentId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  displayInsideIncomingSamplesFolder?: Maybe<BooleanOperationFilterInput>;
  updatedBy?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  updatedByTime?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  completedBy?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  completedByTime?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  folderParent?: Maybe<FolderStructureFilterInput>;
  groupAssignedToNavigation?: Maybe<AppSuiteGroupFilterInput>;
  incomingSampleType?: Maybe<CollectionSampleTypeFilterInput>;
  method?: Maybe<LookupArrayMethodFilterInput>;
  systemAssignedToNavigation?: Maybe<LookupArraySystemFilterInput>;
  userAssignedToNavigation?: Maybe<AppSuiteUserFilterInput>;
  groups?: Maybe<ListFilterInputTypeOfAppSuiteGroupFilterInput>;
  users?: Maybe<ListFilterInputTypeOfAppSuiteUserFilterInput>;
};

export type CollectionTaskInput = {
  incomingSampleTaskId?: Maybe<Scalars["Int"]>;
  incomingSampleTypeId?: Maybe<Scalars["Int"]>;
  incomingSampleParentTaskId?: Maybe<Scalars["Int"]>;
  task?: Maybe<Scalars["String"]>;
  startTimeScheduled?: Maybe<Scalars["DateTime"]>;
  userAssignedTo?: Maybe<Scalars["Int"]>;
  groupAssignedTo?: Maybe<Scalars["Int"]>;
  systemAssignedTo?: Maybe<Scalars["Int"]>;
  priority?: Maybe<TaskPriorityEnum>;
  status?: Maybe<TaskStatusEnum>;
  endTimeActual?: Maybe<Scalars["DateTime"]>;
  endTimeScheduled?: Maybe<Scalars["DateTime"]>;
  startTimeActual?: Maybe<Scalars["DateTime"]>;
  displayInsideIncomingSamplesFolder?: Maybe<Scalars["Boolean"]>;
  folderParentId?: Maybe<Scalars["Int"]>;
};

export type CollectionTaskSortInput = {
  status?: Maybe<SortEnumType>;
  priority?: Maybe<SortEnumType>;
  incomingSampleTaskId?: Maybe<SortEnumType>;
  incomingSampleTypeId?: Maybe<SortEnumType>;
  incomingSampleParentTaskId?: Maybe<SortEnumType>;
  task?: Maybe<SortEnumType>;
  startTimeScheduled?: Maybe<SortEnumType>;
  endTimeScheduled?: Maybe<SortEnumType>;
  startTimeActual?: Maybe<SortEnumType>;
  endTimeActual?: Maybe<SortEnumType>;
  userAssignedTo?: Maybe<SortEnumType>;
  groupAssignedTo?: Maybe<SortEnumType>;
  systemAssignedTo?: Maybe<SortEnumType>;
  methodId?: Maybe<SortEnumType>;
  folderParentId?: Maybe<SortEnumType>;
  displayInsideIncomingSamplesFolder?: Maybe<SortEnumType>;
  updatedBy?: Maybe<SortEnumType>;
  updatedByTime?: Maybe<SortEnumType>;
  completedBy?: Maybe<SortEnumType>;
  completedByTime?: Maybe<SortEnumType>;
  folderParent?: Maybe<FolderStructureSortInput>;
  groupAssignedToNavigation?: Maybe<AppSuiteGroupSortInput>;
  incomingSampleType?: Maybe<CollectionSampleTypeSortInput>;
  method?: Maybe<LookupArrayMethodSortInput>;
  systemAssignedToNavigation?: Maybe<LookupArraySystemSortInput>;
  userAssignedToNavigation?: Maybe<AppSuiteUserSortInput>;
};

export type ComparableByteOperationFilterInput = {
  eq?: Maybe<Scalars["Byte"]>;
  neq?: Maybe<Scalars["Byte"]>;
  in?: Maybe<Array<Scalars["Byte"]>>;
  nin?: Maybe<Array<Scalars["Byte"]>>;
  gt?: Maybe<Scalars["Byte"]>;
  ngt?: Maybe<Scalars["Byte"]>;
  gte?: Maybe<Scalars["Byte"]>;
  ngte?: Maybe<Scalars["Byte"]>;
  lt?: Maybe<Scalars["Byte"]>;
  nlt?: Maybe<Scalars["Byte"]>;
  lte?: Maybe<Scalars["Byte"]>;
  nlte?: Maybe<Scalars["Byte"]>;
};

export type ComparableDateTimeOperationFilterInput = {
  eq?: Maybe<Scalars["DateTime"]>;
  neq?: Maybe<Scalars["DateTime"]>;
  in?: Maybe<Array<Scalars["DateTime"]>>;
  nin?: Maybe<Array<Scalars["DateTime"]>>;
  gt?: Maybe<Scalars["DateTime"]>;
  ngt?: Maybe<Scalars["DateTime"]>;
  gte?: Maybe<Scalars["DateTime"]>;
  ngte?: Maybe<Scalars["DateTime"]>;
  lt?: Maybe<Scalars["DateTime"]>;
  nlt?: Maybe<Scalars["DateTime"]>;
  lte?: Maybe<Scalars["DateTime"]>;
  nlte?: Maybe<Scalars["DateTime"]>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: Maybe<Scalars["Int"]>;
  neq?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Scalars["Int"]>>;
  nin?: Maybe<Array<Scalars["Int"]>>;
  gt?: Maybe<Scalars["Int"]>;
  ngt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  ngte?: Maybe<Scalars["Int"]>;
  lt?: Maybe<Scalars["Int"]>;
  nlt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  nlte?: Maybe<Scalars["Int"]>;
};

export type ComparableNullableOfByteOperationFilterInput = {
  eq?: Maybe<Scalars["Byte"]>;
  neq?: Maybe<Scalars["Byte"]>;
  in?: Maybe<Array<Maybe<Scalars["Byte"]>>>;
  nin?: Maybe<Array<Maybe<Scalars["Byte"]>>>;
  gt?: Maybe<Scalars["Byte"]>;
  ngt?: Maybe<Scalars["Byte"]>;
  gte?: Maybe<Scalars["Byte"]>;
  ngte?: Maybe<Scalars["Byte"]>;
  lt?: Maybe<Scalars["Byte"]>;
  nlt?: Maybe<Scalars["Byte"]>;
  lte?: Maybe<Scalars["Byte"]>;
  nlte?: Maybe<Scalars["Byte"]>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: Maybe<Scalars["DateTime"]>;
  neq?: Maybe<Scalars["DateTime"]>;
  in?: Maybe<Array<Maybe<Scalars["DateTime"]>>>;
  nin?: Maybe<Array<Maybe<Scalars["DateTime"]>>>;
  gt?: Maybe<Scalars["DateTime"]>;
  ngt?: Maybe<Scalars["DateTime"]>;
  gte?: Maybe<Scalars["DateTime"]>;
  ngte?: Maybe<Scalars["DateTime"]>;
  lt?: Maybe<Scalars["DateTime"]>;
  nlt?: Maybe<Scalars["DateTime"]>;
  lte?: Maybe<Scalars["DateTime"]>;
  nlte?: Maybe<Scalars["DateTime"]>;
};

export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: Maybe<Scalars["Decimal"]>;
  neq?: Maybe<Scalars["Decimal"]>;
  in?: Maybe<Array<Maybe<Scalars["Decimal"]>>>;
  nin?: Maybe<Array<Maybe<Scalars["Decimal"]>>>;
  gt?: Maybe<Scalars["Decimal"]>;
  ngt?: Maybe<Scalars["Decimal"]>;
  gte?: Maybe<Scalars["Decimal"]>;
  ngte?: Maybe<Scalars["Decimal"]>;
  lt?: Maybe<Scalars["Decimal"]>;
  nlt?: Maybe<Scalars["Decimal"]>;
  lte?: Maybe<Scalars["Decimal"]>;
  nlte?: Maybe<Scalars["Decimal"]>;
};

export type ComparableNullableOfGuidOperationFilterInput = {
  eq?: Maybe<Scalars["UUID"]>;
  neq?: Maybe<Scalars["UUID"]>;
  in?: Maybe<Array<Maybe<Scalars["UUID"]>>>;
  nin?: Maybe<Array<Maybe<Scalars["UUID"]>>>;
  gt?: Maybe<Scalars["UUID"]>;
  ngt?: Maybe<Scalars["UUID"]>;
  gte?: Maybe<Scalars["UUID"]>;
  ngte?: Maybe<Scalars["UUID"]>;
  lt?: Maybe<Scalars["UUID"]>;
  nlt?: Maybe<Scalars["UUID"]>;
  lte?: Maybe<Scalars["UUID"]>;
  nlte?: Maybe<Scalars["UUID"]>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: Maybe<Scalars["Int"]>;
  neq?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  nin?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  gt?: Maybe<Scalars["Int"]>;
  ngt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  ngte?: Maybe<Scalars["Int"]>;
  lt?: Maybe<Scalars["Int"]>;
  nlt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  nlte?: Maybe<Scalars["Int"]>;
};

export type Container = {
  __typename?: "Container";
  containerId: Scalars["Int"];
  sampleId: Scalars["Int"];
  plateId: Scalars["Int"];
  rowPos: Scalars["Int"];
  colPos: Scalars["Int"];
  barcode?: Maybe<Scalars["String"]>;
  dateCreated?: Maybe<Scalars["DateTime"]>;
  comments?: Maybe<Scalars["String"]>;
  isActive: Scalars["Boolean"];
  inactivationReasonId?: Maybe<Scalars["Int"]>;
  reasonInactivated?: Maybe<Scalars["String"]>;
  inactivatedBy?: Maybe<Scalars["String"]>;
  dateInactivated?: Maybe<Scalars["DateTime"]>;
  lotNumberId?: Maybe<Scalars["Int"]>;
  passageNumber?: Maybe<Scalars["Int"]>;
  explicitlyDeclaredPassageNumber?: Maybe<Scalars["Boolean"]>;
  startingCellNumber?: Maybe<Scalars["Int"]>;
  explicitlyDeclaredStartingCellNumber?: Maybe<Scalars["Boolean"]>;
  dateUploaded?: Maybe<Scalars["DateTime"]>;
  lastUpdatedByUserId?: Maybe<Scalars["Int"]>;
  dateLastUpdated?: Maybe<Scalars["DateTime"]>;
  temporaryCreation?: Maybe<Scalars["Boolean"]>;
  isPicked?: Maybe<Scalars["Boolean"]>;
  lotNumberIdold?: Maybe<Scalars["Int"]>;
  startingPassageNumber?: Maybe<Scalars["Int"]>;
  rowCol?: Maybe<Scalars["String"]>;
  colRow?: Maybe<Scalars["String"]>;
  platePosition?: Maybe<Scalars["String"]>;
  markingSummary?: Maybe<Scalars["String"]>;
  contaminatedMarking?: Maybe<Scalars["String"]>;
  differentiatedMarking?: Maybe<Scalars["String"]>;
  noCellsMarking?: Maybe<Scalars["String"]>;
  qcpassFailMarking?: Maybe<Scalars["String"]>;
  colonyMarking?: Maybe<Scalars["String"]>;
  clonalityMarking?: Maybe<Scalars["String"]>;
  conReviewMarking?: Maybe<Scalars["String"]>;
  sangerSeqMarking?: Maybe<Scalars["String"]>;
  lastDataPointSummary?: Maybe<Scalars["String"]>;
  lastDataPointValue?: Maybe<Scalars["String"]>;
  createdByMappingFileId?: Maybe<Scalars["Int"]>;
  lastLineageCorrectionId?: Maybe<Scalars["Int"]>;
  inactivationReason?: Maybe<LookupInactivationReason>;
  lotNumber?: Maybe<LotNumber>;
  plate?: Maybe<PlatesCreated>;
  sample?: Maybe<Sample>;
  containerComments?: Maybe<Array<Maybe<ContainerComment>>>;
  containerMarkings?: Maybe<Array<Maybe<ContainerMarking>>>;
  containerNotes?: Maybe<Array<Maybe<ContainerNote>>>;
  matricesTubeDestContainer?: Maybe<Array<Maybe<MatrixTube>>>;
  matricesTubeSrcContainer?: Maybe<Array<Maybe<MatrixTube>>>;
  containerMetadata?: Maybe<ContainerMetaData>;
};

export type ContainerComment = {
  __typename?: "ContainerComment";
  containerCommentId: Scalars["Int"];
  containerId: Scalars["Int"];
  comment?: Maybe<Scalars["String"]>;
  dateTime: Scalars["DateTime"];
  userId: Scalars["Int"];
  container?: Maybe<Container>;
  user?: Maybe<AppSuiteUser>;
};

export type ContainerCommentFilterInput = {
  and?: Maybe<Array<ContainerCommentFilterInput>>;
  or?: Maybe<Array<ContainerCommentFilterInput>>;
  containerCommentId?: Maybe<ComparableInt32OperationFilterInput>;
  containerId?: Maybe<ComparableInt32OperationFilterInput>;
  comment?: Maybe<StringOperationFilterInput>;
  dateTime?: Maybe<ComparableDateTimeOperationFilterInput>;
  userId?: Maybe<ComparableInt32OperationFilterInput>;
  container?: Maybe<ContainerFilterInput>;
  user?: Maybe<AppSuiteUserFilterInput>;
};

export type ContainerFilterInput = {
  and?: Maybe<Array<ContainerFilterInput>>;
  or?: Maybe<Array<ContainerFilterInput>>;
  containerId?: Maybe<ComparableInt32OperationFilterInput>;
  sampleId?: Maybe<ComparableInt32OperationFilterInput>;
  plateId?: Maybe<ComparableInt32OperationFilterInput>;
  rowPos?: Maybe<ComparableInt32OperationFilterInput>;
  colPos?: Maybe<ComparableInt32OperationFilterInput>;
  barcode?: Maybe<StringOperationFilterInput>;
  dateCreated?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  comments?: Maybe<StringOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  inactivationReasonId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  reasonInactivated?: Maybe<StringOperationFilterInput>;
  inactivatedBy?: Maybe<StringOperationFilterInput>;
  dateInactivated?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  lotNumberId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  passageNumber?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  explicitlyDeclaredPassageNumber?: Maybe<BooleanOperationFilterInput>;
  startingCellNumber?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  explicitlyDeclaredStartingCellNumber?: Maybe<BooleanOperationFilterInput>;
  dateUploaded?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  lastUpdatedByUserId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  dateLastUpdated?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  temporaryCreation?: Maybe<BooleanOperationFilterInput>;
  isPicked?: Maybe<BooleanOperationFilterInput>;
  lotNumberIdold?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  startingPassageNumber?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  rowCol?: Maybe<StringOperationFilterInput>;
  colRow?: Maybe<StringOperationFilterInput>;
  platePosition?: Maybe<StringOperationFilterInput>;
  markingSummary?: Maybe<StringOperationFilterInput>;
  contaminatedMarking?: Maybe<StringOperationFilterInput>;
  differentiatedMarking?: Maybe<StringOperationFilterInput>;
  noCellsMarking?: Maybe<StringOperationFilterInput>;
  qcpassFailMarking?: Maybe<StringOperationFilterInput>;
  colonyMarking?: Maybe<StringOperationFilterInput>;
  clonalityMarking?: Maybe<StringOperationFilterInput>;
  conReviewMarking?: Maybe<StringOperationFilterInput>;
  sangerSeqMarking?: Maybe<StringOperationFilterInput>;
  lastDataPointSummary?: Maybe<StringOperationFilterInput>;
  lastDataPointValue?: Maybe<StringOperationFilterInput>;
  createdByMappingFileId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  lastLineageCorrectionId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  inactivationReason?: Maybe<LookupInactivationReasonFilterInput>;
  lotNumber?: Maybe<LotNumberFilterInput>;
  plate?: Maybe<PlatesCreatedFilterInput>;
  sample?: Maybe<SampleFilterInput>;
  containerComments?: Maybe<ListFilterInputTypeOfContainerCommentFilterInput>;
  containerMarkings?: Maybe<ListFilterInputTypeOfContainerMarkingFilterInput>;
  containerNotes?: Maybe<ListFilterInputTypeOfContainerNoteFilterInput>;
  matricesTubeDestContainer?: Maybe<ListFilterInputTypeOfMatrixTubeFilterInput>;
  matricesTubeSrcContainer?: Maybe<ListFilterInputTypeOfMatrixTubeFilterInput>;
};

export type ContainerLineage = {
  __typename?: "ContainerLineage";
  containerId: Scalars["Int"];
  hierarchy: Scalars["Int"];
  plateBarcode?: Maybe<Scalars["String"]>;
  rowPos: Scalars["Int"];
  colPos: Scalars["Int"];
  sample?: Maybe<Scalars["String"]>;
  tubeBarcode?: Maybe<Scalars["String"]>;
  dateCreated: Scalars["DateTime"];
  passageNumber: Scalars["Int"];
  lotNumber?: Maybe<Scalars["String"]>;
  isActive: Scalars["Boolean"];
  finalConfluence?: Maybe<Scalars["String"]>;
  lN2SAMActivity?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
};

export type ContainerMarking = {
  __typename?: "ContainerMarking";
  containerMarkingId: Scalars["Int"];
  containerId: Scalars["Int"];
  dateMarked: Scalars["DateTime"];
  markMethod?: Maybe<Scalars["String"]>;
  containerMarkingTypeId?: Maybe<Scalars["Int"]>;
  markingAmount?: Maybe<Scalars["String"]>;
  markedByUserId?: Maybe<Scalars["Int"]>;
  container?: Maybe<Container>;
  containerMarkingType?: Maybe<LookupContainerMarkingType>;
  markedByUser?: Maybe<AppSuiteUser>;
};

export type ContainerMarkingFilterInput = {
  and?: Maybe<Array<ContainerMarkingFilterInput>>;
  or?: Maybe<Array<ContainerMarkingFilterInput>>;
  containerMarkingId?: Maybe<ComparableInt32OperationFilterInput>;
  containerId?: Maybe<ComparableInt32OperationFilterInput>;
  dateMarked?: Maybe<ComparableDateTimeOperationFilterInput>;
  markMethod?: Maybe<StringOperationFilterInput>;
  containerMarkingTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  markingAmount?: Maybe<StringOperationFilterInput>;
  markedByUserId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  container?: Maybe<ContainerFilterInput>;
  containerMarkingType?: Maybe<LookupContainerMarkingTypeFilterInput>;
  markedByUser?: Maybe<AppSuiteUserFilterInput>;
};

export type ContainerMetaData = {
  __typename?: "ContainerMetaData";
  plateCreatedId: Scalars["Int"];
  containerId: Scalars["Int"];
  rowCol?: Maybe<Scalars["String"]>;
  rowPos: Scalars["Int"];
  colPos: Scalars["Int"];
  sampleBarcode?: Maybe<Scalars["String"]>;
  barcode?: Maybe<Scalars["String"]>;
  isActive: Scalars["Boolean"];
  lotNumber?: Maybe<Scalars["String"]>;
  passageNumber?: Maybe<Scalars["Int"]>;
  lastScanDate?: Maybe<Scalars["DateTime"]>;
  lastDataPointSummary?: Maybe<Scalars["String"]>;
  markingSummary?: Maybe<Scalars["String"]>;
  nyscfid?: Maybe<Scalars["String"]>;
  collaboratorId?: Maybe<Scalars["String"]>;
  sourceCellLine?: Maybe<Scalars["String"]>;
  runName?: Maybe<Scalars["String"]>;
  plateBarcodeAlias?: Maybe<Scalars["String"]>;
  sampleBarcodeAliases?: Maybe<Scalars["String"]>;
  lastDataPointValue?: Maybe<Scalars["Decimal"]>;
  externalLotNumber?: Maybe<Scalars["String"]>;
  spitLotNumber?: Maybe<Scalars["String"]>;
  preFibroblastLotNumber?: Maybe<Scalars["String"]>;
  fibroblastLotNumber?: Maybe<Scalars["String"]>;
  bloodProcessingLotNumber?: Maybe<Scalars["String"]>;
  reprogrammingLotNumber?: Maybe<Scalars["String"]>;
  consolidationLotNumber?: Maybe<Scalars["String"]>;
  monoclonalizationLotNumber?: Maybe<Scalars["String"]>;
  expansionLotNumber?: Maybe<Scalars["String"]>;
  geneEditingLotNumber?: Maybe<Scalars["String"]>;
  dnaextractionLotNumber?: Maybe<Scalars["String"]>;
  rpelotNumber?: Maybe<Scalars["String"]>;
  referenceLotNumber?: Maybe<Scalars["String"]>;
  otherLotNumber?: Maybe<Scalars["String"]>;
  contaminatedMarking?: Maybe<Scalars["String"]>;
  differentiatedMarking?: Maybe<Scalars["String"]>;
  noCellsMarking?: Maybe<Scalars["String"]>;
  clonalityMarking?: Maybe<Scalars["String"]>;
  qcpassFailMarking?: Maybe<Scalars["String"]>;
  colonyMarking?: Maybe<Scalars["String"]>;
  sangerSeqMarking?: Maybe<Scalars["String"]>;
  conReviewMarking?: Maybe<Scalars["String"]>;
};

export type ContainerNote = {
  __typename?: "ContainerNote";
  containerNoteId: Scalars["Int"];
  containerId: Scalars["Int"];
  note?: Maybe<Scalars["String"]>;
  dateCommented: Scalars["DateTime"];
  userId: Scalars["Int"];
  container?: Maybe<Container>;
  user?: Maybe<AppSuiteUser>;
};

export type ContainerNoteFilterInput = {
  and?: Maybe<Array<ContainerNoteFilterInput>>;
  or?: Maybe<Array<ContainerNoteFilterInput>>;
  containerNoteId?: Maybe<ComparableInt32OperationFilterInput>;
  containerId?: Maybe<ComparableInt32OperationFilterInput>;
  note?: Maybe<StringOperationFilterInput>;
  dateCommented?: Maybe<ComparableDateTimeOperationFilterInput>;
  userId?: Maybe<ComparableInt32OperationFilterInput>;
  container?: Maybe<ContainerFilterInput>;
  user?: Maybe<AppSuiteUserFilterInput>;
};

export type ContainerSortInput = {
  containerId?: Maybe<SortEnumType>;
  sampleId?: Maybe<SortEnumType>;
  plateId?: Maybe<SortEnumType>;
  rowPos?: Maybe<SortEnumType>;
  colPos?: Maybe<SortEnumType>;
  barcode?: Maybe<SortEnumType>;
  dateCreated?: Maybe<SortEnumType>;
  comments?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  inactivationReasonId?: Maybe<SortEnumType>;
  reasonInactivated?: Maybe<SortEnumType>;
  inactivatedBy?: Maybe<SortEnumType>;
  dateInactivated?: Maybe<SortEnumType>;
  lotNumberId?: Maybe<SortEnumType>;
  passageNumber?: Maybe<SortEnumType>;
  explicitlyDeclaredPassageNumber?: Maybe<SortEnumType>;
  startingCellNumber?: Maybe<SortEnumType>;
  explicitlyDeclaredStartingCellNumber?: Maybe<SortEnumType>;
  dateUploaded?: Maybe<SortEnumType>;
  lastUpdatedByUserId?: Maybe<SortEnumType>;
  dateLastUpdated?: Maybe<SortEnumType>;
  temporaryCreation?: Maybe<SortEnumType>;
  isPicked?: Maybe<SortEnumType>;
  lotNumberIdold?: Maybe<SortEnumType>;
  startingPassageNumber?: Maybe<SortEnumType>;
  rowCol?: Maybe<SortEnumType>;
  colRow?: Maybe<SortEnumType>;
  platePosition?: Maybe<SortEnumType>;
  markingSummary?: Maybe<SortEnumType>;
  contaminatedMarking?: Maybe<SortEnumType>;
  differentiatedMarking?: Maybe<SortEnumType>;
  noCellsMarking?: Maybe<SortEnumType>;
  qcpassFailMarking?: Maybe<SortEnumType>;
  colonyMarking?: Maybe<SortEnumType>;
  clonalityMarking?: Maybe<SortEnumType>;
  conReviewMarking?: Maybe<SortEnumType>;
  sangerSeqMarking?: Maybe<SortEnumType>;
  lastDataPointSummary?: Maybe<SortEnumType>;
  lastDataPointValue?: Maybe<SortEnumType>;
  createdByMappingFileId?: Maybe<SortEnumType>;
  lastLineageCorrectionId?: Maybe<SortEnumType>;
  inactivationReason?: Maybe<LookupInactivationReasonSortInput>;
  lotNumber?: Maybe<LotNumberSortInput>;
  plate?: Maybe<PlatesCreatedSortInput>;
  sample?: Maybe<SampleSortInput>;
};

/** A connection to a list of items. */
export type ContainersConnection = {
  __typename?: "ContainersConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ContainersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Container>>>;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type ContainersEdge = {
  __typename?: "ContainersEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<Container>;
};

export type CreateIncomingSampleCollectionInput = {
  collectionId: Scalars["Int"];
  projectId: Scalars["Int"];
  dateTimeOfArrival?: Maybe<Scalars["DateTime"]>;
  dateTimeOfCollection?: Maybe<Scalars["DateTime"]>;
  incomingSamplesCollectionSampleTypes: Array<CreateIncomingSampleTypeInput>;
};

export type CreateIncomingSampleTypeInput = {
  collectionId: Scalars["Int"];
  dateToProcess?: Maybe<Scalars["DateTime"]>;
  group?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  sampleTypeId: Scalars["Int"];
};

export type CreateRunDefaults = {
  __typename?: "CreateRunDefaults";
  runName?: Maybe<Scalars["String"]>;
  runFolder: Scalars["Int"];
};

export type CreateRunFolderInput = {
  folderParentId?: Maybe<Scalars["Int"]>;
  folderName: Scalars["String"];
  groupId?: Maybe<Scalars["Int"]>;
  isInsertable?: Maybe<Scalars["Boolean"]>;
};

export type CreateRunFolderPayload = {
  __typename?: "CreateRunFolderPayload";
  directoryFolder?: Maybe<DirectoryFolder>;
  folderStructure?: Maybe<FolderStructure>;
};

export type CreateRunInput = {
  runId: Scalars["Int"];
  folderParentId?: Maybe<Scalars["Int"]>;
  runTemplateId?: Maybe<Scalars["Int"]>;
  runDateScheduled?: Maybe<Scalars["DateTime"]>;
  runTypeId?: Maybe<Scalars["Int"]>;
  runName?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  runProgress?: Maybe<Scalars["Int"]>;
  runOwner?: Maybe<Scalars["Int"]>;
  assignedUsers?: Maybe<Array<Scalars["Int"]>>;
  status: TaskStatusEnum;
};

export type CreateRunTemplateInput = {
  runTemplateId: Scalars["Int"];
  runTemplateName: Scalars["String"];
  groupIds: Array<Scalars["Int"]>;
  createdBy?: Maybe<Scalars["Int"]>;
  folderLocationId: Scalars["Int"];
  productionRunTypeId?: Maybe<Scalars["Int"]>;
  createdByV?: Maybe<Scalars["String"]>;
  lastUpdatedBy?: Maybe<Scalars["Int"]>;
  cloneTemplate: Scalars["Boolean"];
};

export type CreateRunTemplateStageInput = {
  runTemplateStageId: Scalars["Int"];
  runTemplateId: Scalars["Int"];
  stageSeqNo?: Maybe<Scalars["Int"]>;
  templateStageName: Scalars["String"];
};

export type CreateRunTemplateTreeNodeInput = {
  parentId: Scalars["Int"];
  childId: Scalars["Int"];
  startDay: Scalars["Int"];
  stageId: Scalars["Int"];
};

export type CreateTicketAttachmentInput = {
  name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["Upload"]>;
};

export type CreateTicketInput = {
  description: Scalars["String"];
  assetId: Scalars["Int"];
  assetTypeId?: Maybe<Scalars["Int"]>;
  priority: TicketPriority;
  dateEncountered: Scalars["DateTime"];
  requestedCompletionDate?: Maybe<Scalars["DateTime"]>;
  isActive: Scalars["Boolean"];
  ticketTypeId: Scalars["Int"];
  ticketAssigneeIds?: Maybe<Array<Scalars["Int"]>>;
  ticketFollowerIds?: Maybe<Array<Scalars["Int"]>>;
  ticketAttachments?: Maybe<Array<CreateTicketAttachmentInput>>;
};

export type CreateTicketUpdateInput = {
  ticketId: Scalars["Int"];
  summary?: Maybe<Scalars["String"]>;
};

export type CurrentComputerUserInput = {
  computerId: Scalars["Int"];
  userId?: Maybe<Scalars["Int"]>;
};

export type Cytomat = {
  __typename?: "Cytomat";
  cytomatId: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  labComputerId?: Maybe<Scalars["Int"]>;
  capacity?: Maybe<Scalars["Int"]>;
  labComputer?: Maybe<LabComputer>;
  cytomatWarnings?: Maybe<Array<Maybe<CytomatWarning>>>;
};

export type CytomatPosition = {
  __typename?: "CytomatPosition";
  cytomatPositionId: Scalars["Int"];
  cytomatId: Scalars["Int"];
  position?: Maybe<Scalars["Int"]>;
  barcode?: Maybe<Scalars["String"]>;
  isOccupied: Scalars["Boolean"];
  modifiedOn?: Maybe<Scalars["DateTime"]>;
  importedOn?: Maybe<Scalars["DateTime"]>;
  plateId?: Maybe<Scalars["Int"]>;
  plate?: Maybe<PlatesCreated>;
};

export type CytomatPositionFilterInput = {
  and?: Maybe<Array<CytomatPositionFilterInput>>;
  or?: Maybe<Array<CytomatPositionFilterInput>>;
  cytomatPositionId?: Maybe<ComparableInt32OperationFilterInput>;
  cytomatId?: Maybe<ComparableInt32OperationFilterInput>;
  position?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  barcode?: Maybe<StringOperationFilterInput>;
  isOccupied?: Maybe<BooleanOperationFilterInput>;
  modifiedOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  importedOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  plateId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  plate?: Maybe<PlatesCreatedFilterInput>;
};

export type CytomatWarning = {
  __typename?: "CytomatWarning";
  cytomatWarningId: Scalars["Int"];
  cytomatId: Scalars["Int"];
  entity?: Maybe<Scalars["String"]>;
  dateWarned: Scalars["DateTime"];
  cytomat?: Maybe<Cytomat>;
};

export type DashboardTask = {
  __typename?: "DashboardTask";
  taskOwnerId: Scalars["Int"];
  taskOwnerName?: Maybe<Scalars["String"]>;
  taskId: Scalars["Int"];
  taskName?: Maybe<Scalars["String"]>;
  taskType: TaskTypeEnum;
  startTime: Scalars["DateTime"];
  endTime: Scalars["DateTime"];
  priority: TaskPriorityEnum;
  status: TaskStatusEnum;
  assignedUsers?: Maybe<Array<Scalars["Int"]>>;
  assignedGroups?: Maybe<Array<Scalars["Int"]>>;
  systemId: Scalars["Int"];
  notes?: Maybe<Scalars["String"]>;
  methodId?: Maybe<Scalars["Int"]>;
  folderId?: Maybe<Scalars["Int"]>;
  startTimeScheduled: Scalars["DateTime"];
  endTimeScheduled: Scalars["DateTime"];
  startTimeActual?: Maybe<Scalars["DateTime"]>;
  endTimeActual?: Maybe<Scalars["DateTime"]>;
  taskAttachment?: Maybe<Array<TaskAttachment>>;
  methodReservationPlateMetadata?: Maybe<Array<Maybe<PlateMetadata>>>;
  project?: Maybe<Scalars["String"]>;
  sampleType?: Maybe<Scalars["String"]>;
  taskSettings?: Maybe<RunTaskDetail>;
  startDate: Scalars["DateTime"];
};

export type DashboardTaskFilterInput = {
  and?: Maybe<Array<DashboardTaskFilterInput>>;
  or?: Maybe<Array<DashboardTaskFilterInput>>;
  taskOwnerId?: Maybe<ComparableInt32OperationFilterInput>;
  taskOwnerName?: Maybe<StringOperationFilterInput>;
  taskId?: Maybe<ComparableInt32OperationFilterInput>;
  taskName?: Maybe<StringOperationFilterInput>;
  taskType?: Maybe<TaskTypeEnumOperationFilterInput>;
  startTime?: Maybe<ComparableDateTimeOperationFilterInput>;
  endTime?: Maybe<ComparableDateTimeOperationFilterInput>;
  priority?: Maybe<TaskPriorityEnumOperationFilterInput>;
  status?: Maybe<TaskStatusEnumOperationFilterInput>;
  assignedUsers?: Maybe<ListComparableInt32OperationFilterInput>;
  assignedGroups?: Maybe<ListComparableInt32OperationFilterInput>;
  systemId?: Maybe<ComparableInt32OperationFilterInput>;
  notes?: Maybe<StringOperationFilterInput>;
  methodId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  folderId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  startTimeScheduled?: Maybe<ComparableDateTimeOperationFilterInput>;
  endTimeScheduled?: Maybe<ComparableDateTimeOperationFilterInput>;
  startTimeActual?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  endTimeActual?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  taskAttachment?: Maybe<ListFilterInputTypeOfTaskAttachmentFilterInput>;
  methodReservationPlateMetadata?: Maybe<ListFilterInputTypeOfPlateMetadataFilterInput>;
  project?: Maybe<StringOperationFilterInput>;
  sampleType?: Maybe<StringOperationFilterInput>;
  taskSettings?: Maybe<RunTaskDetailFilterInput>;
  startDate?: Maybe<ComparableDateTimeOperationFilterInput>;
};

export type DashboardTaskInput = {
  taskOwnerId: Scalars["Int"];
  taskOwnerName?: Maybe<Scalars["String"]>;
  taskId: Scalars["Int"];
  taskName?: Maybe<Scalars["String"]>;
  taskType: TaskTypeEnum;
  startTime: Scalars["DateTime"];
  endTime: Scalars["DateTime"];
  startTimeScheduled?: Maybe<Scalars["DateTime"]>;
  endTimeScheduled?: Maybe<Scalars["DateTime"]>;
  priority: TaskPriorityEnum;
  status: TaskStatusEnum;
  assignedUsers?: Maybe<Array<Scalars["Int"]>>;
  assignedGroups?: Maybe<Array<Scalars["Int"]>>;
  systemId: Scalars["Int"];
  notes?: Maybe<Scalars["String"]>;
  methodId?: Maybe<Scalars["Int"]>;
  methodReservationPlateMetadata?: Maybe<Array<Maybe<PlateMetadataInput>>>;
  taskSettings?: Maybe<RunTaskDetailInput>;
};

export type DashboardTaskSortInput = {
  taskOwnerId?: Maybe<SortEnumType>;
  taskOwnerName?: Maybe<SortEnumType>;
  taskId?: Maybe<SortEnumType>;
  taskName?: Maybe<SortEnumType>;
  taskType?: Maybe<SortEnumType>;
  startTime?: Maybe<SortEnumType>;
  endTime?: Maybe<SortEnumType>;
  priority?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  systemId?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  methodId?: Maybe<SortEnumType>;
  folderId?: Maybe<SortEnumType>;
  startTimeScheduled?: Maybe<SortEnumType>;
  endTimeScheduled?: Maybe<SortEnumType>;
  startTimeActual?: Maybe<SortEnumType>;
  endTimeActual?: Maybe<SortEnumType>;
  project?: Maybe<SortEnumType>;
  sampleType?: Maybe<SortEnumType>;
  taskSettings?: Maybe<RunTaskDetailSortInput>;
  startDate?: Maybe<SortEnumType>;
};

export enum DayOfWeek {
  Sunday = "SUNDAY",
  Monday = "MONDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
  Thursday = "THURSDAY",
  Friday = "FRIDAY",
  Saturday = "SATURDAY",
}

export type DeleteInput = {
  primaryKey: Scalars["Int"];
};

export type DestPlateInfoInput = {
  plateBarcode?: Maybe<Scalars["String"]>;
  plateType?: Maybe<Scalars["String"]>;
};

export enum DiagnosticSeverity {
  Hidden = "HIDDEN",
  Info = "INFO",
  Warning = "WARNING",
  Error = "ERROR",
}

export type DirectoryFolder = {
  __typename?: "DirectoryFolder";
  name: Scalars["String"];
  parentType: NodeType;
  parentId?: Maybe<Scalars["Int"]>;
  subFolders?: Maybe<Array<DirectoryFolder>>;
  id: Scalars["Int"];
  type: NodeType;
};

export type DirectoryFolderFilterInput = {
  and?: Maybe<Array<DirectoryFolderFilterInput>>;
  or?: Maybe<Array<DirectoryFolderFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  parentType?: Maybe<NodeTypeOperationFilterInput>;
  parentId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  subFolders?: Maybe<ListFilterInputTypeOfDirectoryFolderFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  type?: Maybe<NodeTypeOperationFilterInput>;
};

export type DirectoryFolderKeyInput = {
  id: Scalars["Int"];
  type: NodeType;
};

export type DirectoryFolderSortInput = {
  name?: Maybe<SortEnumType>;
  parentType?: Maybe<SortEnumType>;
  parentId?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  type?: Maybe<SortEnumType>;
};

export type Donor = {
  __typename?: "Donor";
  donorId: Scalars["Int"];
  nyscfid?: Maybe<Scalars["String"]>;
  collaboratorId?: Maybe<Scalars["String"]>;
  nyscfidalias?: Maybe<Scalars["String"]>;
  collaboratorIdalias?: Maybe<Scalars["String"]>;
  nyscfidalias2?: Maybe<Scalars["String"]>;
  sex?: Maybe<Scalars["String"]>;
  yearOfBirth?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  studySampleType?: Maybe<Scalars["String"]>;
  redcapNyscfidalias?: Maybe<Scalars["String"]>;
  recontact?: Maybe<Scalars["String"]>;
  noRecontactDetails?: Maybe<Scalars["String"]>;
  flagged?: Maybe<Scalars["String"]>;
  flaggedDetails?: Maybe<Scalars["String"]>;
  subjectOverviewComplete?: Maybe<Scalars["String"]>;
  education?: Maybe<Scalars["String"]>;
  educationOther?: Maybe<Scalars["String"]>;
  handedness?: Maybe<Scalars["String"]>;
  adopted?: Maybe<Scalars["String"]>;
  twinOrMultiple?: Maybe<Scalars["String"]>;
  twinType?: Maybe<Scalars["String"]>;
  multipleDetails?: Maybe<Scalars["String"]>;
  raceAmericanIndianOrAlaskaNative?: Maybe<Scalars["Boolean"]>;
  raceAsianOrPacificIslander?: Maybe<Scalars["Boolean"]>;
  raceBlack?: Maybe<Scalars["Boolean"]>;
  raceHispanic?: Maybe<Scalars["Boolean"]>;
  raceWhite?: Maybe<Scalars["Boolean"]>;
  raceOther?: Maybe<Scalars["Boolean"]>;
  otherRace?: Maybe<Scalars["String"]>;
  raceUnknown?: Maybe<Scalars["Boolean"]>;
  ancestryAdmixedAmerican?: Maybe<Scalars["Decimal"]>;
  ancestryAfrican?: Maybe<Scalars["Decimal"]>;
  ancestryEastAsian?: Maybe<Scalars["Decimal"]>;
  ancestryEuropean?: Maybe<Scalars["Decimal"]>;
  ancestrySouthAsian?: Maybe<Scalars["Decimal"]>;
  dateAncestryEstimated?: Maybe<Scalars["DateTime"]>;
  ashkenazi?: Maybe<Scalars["String"]>;
  ashkenaziGrandparents?: Maybe<Scalars["String"]>;
  demographicsComments?: Maybe<Scalars["String"]>;
  demographicsComplete?: Maybe<Scalars["String"]>;
  studyHistoryNyuPdr?: Maybe<Scalars["Boolean"]>;
  studyHistoryNyuControl?: Maybe<Scalars["Boolean"]>;
  studyHistory10001?: Maybe<Scalars["Boolean"]>;
  studyHistoryEmbryo?: Maybe<Scalars["Boolean"]>;
  studyHistoryPgp?: Maybe<Scalars["Boolean"]>;
  studyHistoryOther?: Maybe<Scalars["Boolean"]>;
  otherStudy?: Maybe<Scalars["String"]>;
  statusId?: Maybe<Scalars["Int"]>;
  mostRecentPlateCreated?: Maybe<Scalars["Int"]>;
  mostRecentSampleTypeId?: Maybe<Scalars["Int"]>;
  mostRecentSampleBarcode?: Maybe<Scalars["String"]>;
  affectedMedicalConditionId?: Maybe<Scalars["Int"]>;
  clonesPassedQc?: Maybe<Scalars["Int"]>;
  dateAdded?: Maybe<Scalars["DateTime"]>;
  yearRegistered?: Maybe<Scalars["String"]>;
  registeredBy?: Maybe<Scalars["String"]>;
  dateLastUpdated?: Maybe<Scalars["DateTime"]>;
  updatedBy?: Maybe<Scalars["String"]>;
  studyInformationComplete?: Maybe<Scalars["String"]>;
  paternalGrandFather?: Maybe<Scalars["String"]>;
  paternalGrandFatherOther?: Maybe<Scalars["String"]>;
  paternalGrandMother?: Maybe<Scalars["String"]>;
  paternalGrandMotherOther?: Maybe<Scalars["String"]>;
  maternalGrandFather?: Maybe<Scalars["String"]>;
  maternalGrandFatherOther?: Maybe<Scalars["String"]>;
  maternalGrandMother?: Maybe<Scalars["String"]>;
  maternalGrandMotherOther?: Maybe<Scalars["String"]>;
  reportedEthnicity?: Maybe<Scalars["String"]>;
  isTwin?: Maybe<Scalars["Boolean"]>;
  isAdopted?: Maybe<Scalars["Boolean"]>;
  ageAtDeath?: Maybe<Scalars["Int"]>;
  apoemutationTypes?: Maybe<Scalars["String"]>;
  amyloidBuildUp?: Maybe<Scalars["Decimal"]>;
  neuriticPlaqueAverageScaledNeocortex?: Maybe<Scalars["Decimal"]>;
  braakscore?: Maybe<Scalars["Int"]>;
  cellLines?: Maybe<Array<Maybe<CellLine>>>;
  donorComments?: Maybe<Array<Maybe<DonorComment>>>;
  donorMedicalConditions?: Maybe<Array<Maybe<DonorMedicalCondition>>>;
  projectDonors?: Maybe<Array<Maybe<ProjectDonor>>>;
};

export type DonorComment = {
  __typename?: "DonorComment";
  donorCommentId: Scalars["Int"];
  donorId: Scalars["Int"];
  comment?: Maybe<Scalars["String"]>;
  dateTime: Scalars["DateTime"];
  userId: Scalars["Int"];
  donor?: Maybe<Donor>;
  user?: Maybe<AppSuiteUser>;
};

export type DonorCommentFilterInput = {
  and?: Maybe<Array<DonorCommentFilterInput>>;
  or?: Maybe<Array<DonorCommentFilterInput>>;
  donorCommentId?: Maybe<ComparableInt32OperationFilterInput>;
  donorId?: Maybe<ComparableInt32OperationFilterInput>;
  comment?: Maybe<StringOperationFilterInput>;
  dateTime?: Maybe<ComparableDateTimeOperationFilterInput>;
  userId?: Maybe<ComparableInt32OperationFilterInput>;
  donor?: Maybe<DonorFilterInput>;
  user?: Maybe<AppSuiteUserFilterInput>;
};

export type DonorFilterInput = {
  and?: Maybe<Array<DonorFilterInput>>;
  or?: Maybe<Array<DonorFilterInput>>;
  donorId?: Maybe<ComparableInt32OperationFilterInput>;
  nyscfid?: Maybe<StringOperationFilterInput>;
  collaboratorId?: Maybe<StringOperationFilterInput>;
  nyscfidalias?: Maybe<StringOperationFilterInput>;
  collaboratorIdalias?: Maybe<StringOperationFilterInput>;
  nyscfidalias2?: Maybe<StringOperationFilterInput>;
  sex?: Maybe<StringOperationFilterInput>;
  yearOfBirth?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  studySampleType?: Maybe<StringOperationFilterInput>;
  redcapNyscfidalias?: Maybe<StringOperationFilterInput>;
  recontact?: Maybe<StringOperationFilterInput>;
  noRecontactDetails?: Maybe<StringOperationFilterInput>;
  flagged?: Maybe<StringOperationFilterInput>;
  flaggedDetails?: Maybe<StringOperationFilterInput>;
  subjectOverviewComplete?: Maybe<StringOperationFilterInput>;
  education?: Maybe<StringOperationFilterInput>;
  educationOther?: Maybe<StringOperationFilterInput>;
  handedness?: Maybe<StringOperationFilterInput>;
  adopted?: Maybe<StringOperationFilterInput>;
  twinOrMultiple?: Maybe<StringOperationFilterInput>;
  twinType?: Maybe<StringOperationFilterInput>;
  multipleDetails?: Maybe<StringOperationFilterInput>;
  raceAmericanIndianOrAlaskaNative?: Maybe<BooleanOperationFilterInput>;
  raceAsianOrPacificIslander?: Maybe<BooleanOperationFilterInput>;
  raceBlack?: Maybe<BooleanOperationFilterInput>;
  raceHispanic?: Maybe<BooleanOperationFilterInput>;
  raceWhite?: Maybe<BooleanOperationFilterInput>;
  raceOther?: Maybe<BooleanOperationFilterInput>;
  otherRace?: Maybe<StringOperationFilterInput>;
  raceUnknown?: Maybe<BooleanOperationFilterInput>;
  ancestryAdmixedAmerican?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  ancestryAfrican?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  ancestryEastAsian?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  ancestryEuropean?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  ancestrySouthAsian?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  dateAncestryEstimated?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  ashkenazi?: Maybe<StringOperationFilterInput>;
  ashkenaziGrandparents?: Maybe<StringOperationFilterInput>;
  demographicsComments?: Maybe<StringOperationFilterInput>;
  demographicsComplete?: Maybe<StringOperationFilterInput>;
  studyHistoryNyuPdr?: Maybe<BooleanOperationFilterInput>;
  studyHistoryNyuControl?: Maybe<BooleanOperationFilterInput>;
  studyHistory10001?: Maybe<BooleanOperationFilterInput>;
  studyHistoryEmbryo?: Maybe<BooleanOperationFilterInput>;
  studyHistoryPgp?: Maybe<BooleanOperationFilterInput>;
  studyHistoryOther?: Maybe<BooleanOperationFilterInput>;
  otherStudy?: Maybe<StringOperationFilterInput>;
  statusId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  mostRecentPlateCreated?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  mostRecentSampleTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  mostRecentSampleBarcode?: Maybe<StringOperationFilterInput>;
  affectedMedicalConditionId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  clonesPassedQc?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  dateAdded?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  yearRegistered?: Maybe<StringOperationFilterInput>;
  registeredBy?: Maybe<StringOperationFilterInput>;
  dateLastUpdated?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updatedBy?: Maybe<StringOperationFilterInput>;
  studyInformationComplete?: Maybe<StringOperationFilterInput>;
  paternalGrandFather?: Maybe<StringOperationFilterInput>;
  paternalGrandFatherOther?: Maybe<StringOperationFilterInput>;
  paternalGrandMother?: Maybe<StringOperationFilterInput>;
  paternalGrandMotherOther?: Maybe<StringOperationFilterInput>;
  maternalGrandFather?: Maybe<StringOperationFilterInput>;
  maternalGrandFatherOther?: Maybe<StringOperationFilterInput>;
  maternalGrandMother?: Maybe<StringOperationFilterInput>;
  maternalGrandMotherOther?: Maybe<StringOperationFilterInput>;
  reportedEthnicity?: Maybe<StringOperationFilterInput>;
  isTwin?: Maybe<BooleanOperationFilterInput>;
  isAdopted?: Maybe<BooleanOperationFilterInput>;
  ageAtDeath?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  apoemutationTypes?: Maybe<StringOperationFilterInput>;
  amyloidBuildUp?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  neuriticPlaqueAverageScaledNeocortex?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  braakscore?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  cellLines?: Maybe<ListFilterInputTypeOfCellLineFilterInput>;
  donorComments?: Maybe<ListFilterInputTypeOfDonorCommentFilterInput>;
  donorMedicalConditions?: Maybe<ListFilterInputTypeOfDonorMedicalConditionFilterInput>;
  projectDonors?: Maybe<ListFilterInputTypeOfProjectDonorFilterInput>;
};

export type DonorMedicalCondition = {
  __typename?: "DonorMedicalCondition";
  donorMedicalConditionId: Scalars["Int"];
  donorId: Scalars["Int"];
  medicalConditionId: Scalars["Int"];
  ageOfOnset?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  importedFromRedcap?: Maybe<Scalars["Boolean"]>;
  donor?: Maybe<Donor>;
  medicalCondition?: Maybe<LookupMedicalCondition>;
};

export type DonorMedicalConditionFilterInput = {
  and?: Maybe<Array<DonorMedicalConditionFilterInput>>;
  or?: Maybe<Array<DonorMedicalConditionFilterInput>>;
  donorMedicalConditionId?: Maybe<ComparableInt32OperationFilterInput>;
  donorId?: Maybe<ComparableInt32OperationFilterInput>;
  medicalConditionId?: Maybe<ComparableInt32OperationFilterInput>;
  ageOfOnset?: Maybe<StringOperationFilterInput>;
  comments?: Maybe<StringOperationFilterInput>;
  importedFromRedcap?: Maybe<BooleanOperationFilterInput>;
  donor?: Maybe<DonorFilterInput>;
  medicalCondition?: Maybe<LookupMedicalConditionFilterInput>;
};

export type DonorSortInput = {
  donorId?: Maybe<SortEnumType>;
  nyscfid?: Maybe<SortEnumType>;
  collaboratorId?: Maybe<SortEnumType>;
  nyscfidalias?: Maybe<SortEnumType>;
  collaboratorIdalias?: Maybe<SortEnumType>;
  nyscfidalias2?: Maybe<SortEnumType>;
  sex?: Maybe<SortEnumType>;
  yearOfBirth?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  studySampleType?: Maybe<SortEnumType>;
  redcapNyscfidalias?: Maybe<SortEnumType>;
  recontact?: Maybe<SortEnumType>;
  noRecontactDetails?: Maybe<SortEnumType>;
  flagged?: Maybe<SortEnumType>;
  flaggedDetails?: Maybe<SortEnumType>;
  subjectOverviewComplete?: Maybe<SortEnumType>;
  education?: Maybe<SortEnumType>;
  educationOther?: Maybe<SortEnumType>;
  handedness?: Maybe<SortEnumType>;
  adopted?: Maybe<SortEnumType>;
  twinOrMultiple?: Maybe<SortEnumType>;
  twinType?: Maybe<SortEnumType>;
  multipleDetails?: Maybe<SortEnumType>;
  raceAmericanIndianOrAlaskaNative?: Maybe<SortEnumType>;
  raceAsianOrPacificIslander?: Maybe<SortEnumType>;
  raceBlack?: Maybe<SortEnumType>;
  raceHispanic?: Maybe<SortEnumType>;
  raceWhite?: Maybe<SortEnumType>;
  raceOther?: Maybe<SortEnumType>;
  otherRace?: Maybe<SortEnumType>;
  raceUnknown?: Maybe<SortEnumType>;
  ancestryAdmixedAmerican?: Maybe<SortEnumType>;
  ancestryAfrican?: Maybe<SortEnumType>;
  ancestryEastAsian?: Maybe<SortEnumType>;
  ancestryEuropean?: Maybe<SortEnumType>;
  ancestrySouthAsian?: Maybe<SortEnumType>;
  dateAncestryEstimated?: Maybe<SortEnumType>;
  ashkenazi?: Maybe<SortEnumType>;
  ashkenaziGrandparents?: Maybe<SortEnumType>;
  demographicsComments?: Maybe<SortEnumType>;
  demographicsComplete?: Maybe<SortEnumType>;
  studyHistoryNyuPdr?: Maybe<SortEnumType>;
  studyHistoryNyuControl?: Maybe<SortEnumType>;
  studyHistory10001?: Maybe<SortEnumType>;
  studyHistoryEmbryo?: Maybe<SortEnumType>;
  studyHistoryPgp?: Maybe<SortEnumType>;
  studyHistoryOther?: Maybe<SortEnumType>;
  otherStudy?: Maybe<SortEnumType>;
  statusId?: Maybe<SortEnumType>;
  mostRecentPlateCreated?: Maybe<SortEnumType>;
  mostRecentSampleTypeId?: Maybe<SortEnumType>;
  mostRecentSampleBarcode?: Maybe<SortEnumType>;
  affectedMedicalConditionId?: Maybe<SortEnumType>;
  clonesPassedQc?: Maybe<SortEnumType>;
  dateAdded?: Maybe<SortEnumType>;
  yearRegistered?: Maybe<SortEnumType>;
  registeredBy?: Maybe<SortEnumType>;
  dateLastUpdated?: Maybe<SortEnumType>;
  updatedBy?: Maybe<SortEnumType>;
  studyInformationComplete?: Maybe<SortEnumType>;
  paternalGrandFather?: Maybe<SortEnumType>;
  paternalGrandFatherOther?: Maybe<SortEnumType>;
  paternalGrandMother?: Maybe<SortEnumType>;
  paternalGrandMotherOther?: Maybe<SortEnumType>;
  maternalGrandFather?: Maybe<SortEnumType>;
  maternalGrandFatherOther?: Maybe<SortEnumType>;
  maternalGrandMother?: Maybe<SortEnumType>;
  maternalGrandMotherOther?: Maybe<SortEnumType>;
  reportedEthnicity?: Maybe<SortEnumType>;
  isTwin?: Maybe<SortEnumType>;
  isAdopted?: Maybe<SortEnumType>;
  ageAtDeath?: Maybe<SortEnumType>;
  apoemutationTypes?: Maybe<SortEnumType>;
  amyloidBuildUp?: Maybe<SortEnumType>;
  neuriticPlaqueAverageScaledNeocortex?: Maybe<SortEnumType>;
  braakscore?: Maybe<SortEnumType>;
};

export type FeedGroup = {
  __typename?: "FeedGroup";
  feedGroupId: Scalars["Int"];
  groupId: Scalars["Int"];
  arraySystemAutomationMethodId: Scalars["Int"];
  feedGroupName?: Maybe<Scalars["String"]>;
  isActive: Scalars["Boolean"];
  numberOfPlates: Scalars["Int"];
  startTimeScheduled?: Maybe<Scalars["DateTime"]>;
  arraySystemAutomationMethod?: Maybe<ArraySystemAutomationMethod>;
  group?: Maybe<AppSuiteGroup>;
  personalTasks?: Maybe<Array<Maybe<PersonalTask>>>;
};

export type FeedGroupFilterInput = {
  and?: Maybe<Array<FeedGroupFilterInput>>;
  or?: Maybe<Array<FeedGroupFilterInput>>;
  feedGroupId?: Maybe<ComparableInt32OperationFilterInput>;
  groupId?: Maybe<ComparableInt32OperationFilterInput>;
  arraySystemAutomationMethodId?: Maybe<ComparableInt32OperationFilterInput>;
  feedGroupName?: Maybe<StringOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  numberOfPlates?: Maybe<ComparableInt32OperationFilterInput>;
  startTimeScheduled?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  arraySystemAutomationMethod?: Maybe<ArraySystemAutomationMethodFilterInput>;
  group?: Maybe<AppSuiteGroupFilterInput>;
  personalTasks?: Maybe<ListFilterInputTypeOfPersonalTaskFilterInput>;
};

export type FeedGroupInput = {
  feedGroupId?: Maybe<Scalars["Int"]>;
  groupId?: Maybe<Scalars["Int"]>;
  arraySystemAutomationMethodId?: Maybe<Scalars["Int"]>;
  feedGroupName?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  startTimeScheduled: Scalars["DateTime"];
  numberOfPlates?: Maybe<Scalars["Int"]>;
  methodId?: Maybe<Scalars["Int"]>;
  arraySystemId?: Maybe<Scalars["Int"]>;
  automationMethodId?: Maybe<Scalars["Int"]>;
};

export type FeedGroupSortInput = {
  feedGroupId?: Maybe<SortEnumType>;
  groupId?: Maybe<SortEnumType>;
  arraySystemAutomationMethodId?: Maybe<SortEnumType>;
  feedGroupName?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  numberOfPlates?: Maybe<SortEnumType>;
  startTimeScheduled?: Maybe<SortEnumType>;
  arraySystemAutomationMethod?: Maybe<ArraySystemAutomationMethodSortInput>;
  group?: Maybe<AppSuiteGroupSortInput>;
};

export type FolderStructure = {
  __typename?: "FolderStructure";
  folderId: Scalars["Int"];
  folderParentId?: Maybe<Scalars["Int"]>;
  folderName?: Maybe<Scalars["String"]>;
  groupId?: Maybe<Scalars["Int"]>;
  isInsertable?: Maybe<Scalars["Boolean"]>;
  folderParent?: Maybe<FolderStructure>;
  group?: Maybe<AppSuiteGroup>;
  lookupTeamGanttId?: Maybe<LookupTeamGanttId>;
  collectionTasks?: Maybe<Array<Maybe<CollectionTask>>>;
  inverseFolderParent?: Maybe<Array<Maybe<FolderStructure>>>;
  runTemplates?: Maybe<Array<Maybe<RunTemplate>>>;
  runs?: Maybe<Array<Maybe<Run>>>;
};

export type FolderStructureFilterInput = {
  and?: Maybe<Array<FolderStructureFilterInput>>;
  or?: Maybe<Array<FolderStructureFilterInput>>;
  folderId?: Maybe<ComparableInt32OperationFilterInput>;
  folderParentId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  folderName?: Maybe<StringOperationFilterInput>;
  groupId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  isInsertable?: Maybe<BooleanOperationFilterInput>;
  folderParent?: Maybe<FolderStructureFilterInput>;
  group?: Maybe<AppSuiteGroupFilterInput>;
  lookupTeamGanttId?: Maybe<LookupTeamGanttIdFilterInput>;
  collectionTasks?: Maybe<ListFilterInputTypeOfCollectionTaskFilterInput>;
  inverseFolderParent?: Maybe<ListFilterInputTypeOfFolderStructureFilterInput>;
  runTemplates?: Maybe<ListFilterInputTypeOfRunTemplateFilterInput>;
  runs?: Maybe<ListFilterInputTypeOfRunFilterInput>;
};

export type FolderStructureSortInput = {
  folderId?: Maybe<SortEnumType>;
  folderParentId?: Maybe<SortEnumType>;
  folderName?: Maybe<SortEnumType>;
  groupId?: Maybe<SortEnumType>;
  isInsertable?: Maybe<SortEnumType>;
  folderParent?: Maybe<FolderStructureSortInput>;
  group?: Maybe<AppSuiteGroupSortInput>;
  lookupTeamGanttId?: Maybe<LookupTeamGanttIdSortInput>;
};

export type GithubProject = {
  __typename?: "GithubProject";
  id: Scalars["Int"];
  githubId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  repositoryId?: Maybe<Scalars["String"]>;
  lookupAssetTypes?: Maybe<Array<Maybe<LookupAssetType>>>;
};

export type GithubProjectFilterInput = {
  and?: Maybe<Array<GithubProjectFilterInput>>;
  or?: Maybe<Array<GithubProjectFilterInput>>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  githubId?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  repositoryId?: Maybe<StringOperationFilterInput>;
  lookupAssetTypes?: Maybe<ListFilterInputTypeOfLookupAssetTypeFilterInput>;
};

export type GithubProjectSortInput = {
  id?: Maybe<SortEnumType>;
  githubId?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  repositoryId?: Maybe<SortEnumType>;
};

export type GroupLoginState = {
  __typename?: "GroupLoginState";
  id: Scalars["Int"];
  labComputer: LabComputer;
  user?: Maybe<AppSuiteUser>;
};

export type GroupedPlateMetadata = {
  __typename?: "GroupedPlateMetadata";
  prefix?: Maybe<Scalars["String"]>;
  plates?: Maybe<Array<Maybe<PlateToMetadata>>>;
};

export enum ImageExportFormat {
  Jpg = "JPG",
  Tiff = "TIFF",
  Bmp = "BMP",
}

/** A connection to a list of items. */
export type IncomingSampleCollectionsConnection = {
  __typename?: "IncomingSampleCollectionsConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<IncomingSampleCollectionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Collection>>>;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type IncomingSampleCollectionsEdge = {
  __typename?: "IncomingSampleCollectionsEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<Collection>;
};

/** A connection to a list of items. */
export type IncomingSampleTasksConnection = {
  __typename?: "IncomingSampleTasksConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<IncomingSampleTasksEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<CollectionTask>>>;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type IncomingSampleTasksEdge = {
  __typename?: "IncomingSampleTasksEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<CollectionTask>;
};

export type LabComputer = {
  __typename?: "LabComputer";
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  slackChannelId?: Maybe<Scalars["Int"]>;
  cytomats?: Maybe<Array<Maybe<Cytomat>>>;
};

export type ListComparableByteOperationFilterInput = {
  all?: Maybe<ComparableByteOperationFilterInput>;
  none?: Maybe<ComparableByteOperationFilterInput>;
  some?: Maybe<ComparableByteOperationFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListComparableInt32OperationFilterInput = {
  all?: Maybe<ComparableInt32OperationFilterInput>;
  none?: Maybe<ComparableInt32OperationFilterInput>;
  some?: Maybe<ComparableInt32OperationFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfAppSuiteGroupFilterInput = {
  all?: Maybe<AppSuiteGroupFilterInput>;
  none?: Maybe<AppSuiteGroupFilterInput>;
  some?: Maybe<AppSuiteGroupFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfAppSuiteGroupUserFilterInput = {
  all?: Maybe<AppSuiteGroupUserFilterInput>;
  none?: Maybe<AppSuiteGroupUserFilterInput>;
  some?: Maybe<AppSuiteGroupUserFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfAppSuiteUserFilterInput = {
  all?: Maybe<AppSuiteUserFilterInput>;
  none?: Maybe<AppSuiteUserFilterInput>;
  some?: Maybe<AppSuiteUserFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfArrayMethodLabwareTypeFilterInput = {
  all?: Maybe<ArrayMethodLabwareTypeFilterInput>;
  none?: Maybe<ArrayMethodLabwareTypeFilterInput>;
  some?: Maybe<ArrayMethodLabwareTypeFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfArrayMethodPlateFilterInput = {
  all?: Maybe<ArrayMethodPlateFilterInput>;
  none?: Maybe<ArrayMethodPlateFilterInput>;
  some?: Maybe<ArrayMethodPlateFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfArraySystemAutomationMethodFilterInput = {
  all?: Maybe<ArraySystemAutomationMethodFilterInput>;
  none?: Maybe<ArraySystemAutomationMethodFilterInput>;
  some?: Maybe<ArraySystemAutomationMethodFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfArraySystemAutomationMethodLabwareTypeFilterInput =
  {
    all?: Maybe<ArraySystemAutomationMethodLabwareTypeFilterInput>;
    none?: Maybe<ArraySystemAutomationMethodLabwareTypeFilterInput>;
    some?: Maybe<ArraySystemAutomationMethodLabwareTypeFilterInput>;
    any?: Maybe<Scalars["Boolean"]>;
  };

export type ListFilterInputTypeOfArraySystemAutomationMethodProcessFilterInput =
  {
    all?: Maybe<ArraySystemAutomationMethodProcessFilterInput>;
    none?: Maybe<ArraySystemAutomationMethodProcessFilterInput>;
    some?: Maybe<ArraySystemAutomationMethodProcessFilterInput>;
    any?: Maybe<Scalars["Boolean"]>;
  };

export type ListFilterInputTypeOfArraySystemAutomationMethodProcessRunFilterInput =
  {
    all?: Maybe<ArraySystemAutomationMethodProcessRunFilterInput>;
    none?: Maybe<ArraySystemAutomationMethodProcessRunFilterInput>;
    some?: Maybe<ArraySystemAutomationMethodProcessRunFilterInput>;
    any?: Maybe<Scalars["Boolean"]>;
  };

export type ListFilterInputTypeOfArraySystemAutomationMethodProcessRunStepFilterInput =
  {
    all?: Maybe<ArraySystemAutomationMethodProcessRunStepFilterInput>;
    none?: Maybe<ArraySystemAutomationMethodProcessRunStepFilterInput>;
    some?: Maybe<ArraySystemAutomationMethodProcessRunStepFilterInput>;
    any?: Maybe<Scalars["Boolean"]>;
  };

export type ListFilterInputTypeOfArraySystemAutomationMethodProcessStepFilterInput =
  {
    all?: Maybe<ArraySystemAutomationMethodProcessStepFilterInput>;
    none?: Maybe<ArraySystemAutomationMethodProcessStepFilterInput>;
    some?: Maybe<ArraySystemAutomationMethodProcessStepFilterInput>;
    any?: Maybe<Scalars["Boolean"]>;
  };

export type ListFilterInputTypeOfCellLineFilterInput = {
  all?: Maybe<CellLineFilterInput>;
  none?: Maybe<CellLineFilterInput>;
  some?: Maybe<CellLineFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfCoatedPlateBatchFilterInput = {
  all?: Maybe<CoatedPlateBatchFilterInput>;
  none?: Maybe<CoatedPlateBatchFilterInput>;
  some?: Maybe<CoatedPlateBatchFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfCollectionFilterInput = {
  all?: Maybe<CollectionFilterInput>;
  none?: Maybe<CollectionFilterInput>;
  some?: Maybe<CollectionFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfCollectionSampleTypeFilterInput = {
  all?: Maybe<CollectionSampleTypeFilterInput>;
  none?: Maybe<CollectionSampleTypeFilterInput>;
  some?: Maybe<CollectionSampleTypeFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfCollectionTaskFilterInput = {
  all?: Maybe<CollectionTaskFilterInput>;
  none?: Maybe<CollectionTaskFilterInput>;
  some?: Maybe<CollectionTaskFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfContainerCommentFilterInput = {
  all?: Maybe<ContainerCommentFilterInput>;
  none?: Maybe<ContainerCommentFilterInput>;
  some?: Maybe<ContainerCommentFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfContainerFilterInput = {
  all?: Maybe<ContainerFilterInput>;
  none?: Maybe<ContainerFilterInput>;
  some?: Maybe<ContainerFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfContainerMarkingFilterInput = {
  all?: Maybe<ContainerMarkingFilterInput>;
  none?: Maybe<ContainerMarkingFilterInput>;
  some?: Maybe<ContainerMarkingFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfContainerNoteFilterInput = {
  all?: Maybe<ContainerNoteFilterInput>;
  none?: Maybe<ContainerNoteFilterInput>;
  some?: Maybe<ContainerNoteFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfCytomatPositionFilterInput = {
  all?: Maybe<CytomatPositionFilterInput>;
  none?: Maybe<CytomatPositionFilterInput>;
  some?: Maybe<CytomatPositionFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfDirectoryFolderFilterInput = {
  all?: Maybe<DirectoryFolderFilterInput>;
  none?: Maybe<DirectoryFolderFilterInput>;
  some?: Maybe<DirectoryFolderFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfDonorCommentFilterInput = {
  all?: Maybe<DonorCommentFilterInput>;
  none?: Maybe<DonorCommentFilterInput>;
  some?: Maybe<DonorCommentFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfDonorMedicalConditionFilterInput = {
  all?: Maybe<DonorMedicalConditionFilterInput>;
  none?: Maybe<DonorMedicalConditionFilterInput>;
  some?: Maybe<DonorMedicalConditionFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfFeedGroupFilterInput = {
  all?: Maybe<FeedGroupFilterInput>;
  none?: Maybe<FeedGroupFilterInput>;
  some?: Maybe<FeedGroupFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfFolderStructureFilterInput = {
  all?: Maybe<FolderStructureFilterInput>;
  none?: Maybe<FolderStructureFilterInput>;
  some?: Maybe<FolderStructureFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfLookupArrayMethodFilterInput = {
  all?: Maybe<LookupArrayMethodFilterInput>;
  none?: Maybe<LookupArrayMethodFilterInput>;
  some?: Maybe<LookupArrayMethodFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfLookupArrayMethodGroupFilterInput = {
  all?: Maybe<LookupArrayMethodGroupFilterInput>;
  none?: Maybe<LookupArrayMethodGroupFilterInput>;
  some?: Maybe<LookupArrayMethodGroupFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfLookupArrayMethodPlateRoleFilterInput = {
  all?: Maybe<LookupArrayMethodPlateRoleFilterInput>;
  none?: Maybe<LookupArrayMethodPlateRoleFilterInput>;
  some?: Maybe<LookupArrayMethodPlateRoleFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfLookupArrayPlateTypeStringFilterInput = {
  all?: Maybe<LookupArrayPlateTypeStringFilterInput>;
  none?: Maybe<LookupArrayPlateTypeStringFilterInput>;
  some?: Maybe<LookupArrayPlateTypeStringFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfLookupArraySystemFilterInput = {
  all?: Maybe<LookupArraySystemFilterInput>;
  none?: Maybe<LookupArraySystemFilterInput>;
  some?: Maybe<LookupArraySystemFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfLookupAssetFilterInput = {
  all?: Maybe<LookupAssetFilterInput>;
  none?: Maybe<LookupAssetFilterInput>;
  some?: Maybe<LookupAssetFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfLookupAssetTypeFilterInput = {
  all?: Maybe<LookupAssetTypeFilterInput>;
  none?: Maybe<LookupAssetTypeFilterInput>;
  some?: Maybe<LookupAssetTypeFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfLookupContainerMarkingTypeFilterInput = {
  all?: Maybe<LookupContainerMarkingTypeFilterInput>;
  none?: Maybe<LookupContainerMarkingTypeFilterInput>;
  some?: Maybe<LookupContainerMarkingTypeFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfLookupExperimentSettingFilterInput = {
  all?: Maybe<LookupExperimentSettingFilterInput>;
  none?: Maybe<LookupExperimentSettingFilterInput>;
  some?: Maybe<LookupExperimentSettingFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfLookupLabwareTypeFilterInput = {
  all?: Maybe<LookupLabwareTypeFilterInput>;
  none?: Maybe<LookupLabwareTypeFilterInput>;
  some?: Maybe<LookupLabwareTypeFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfLookupSampleCollectionTypeFilterInput = {
  all?: Maybe<LookupSampleCollectionTypeFilterInput>;
  none?: Maybe<LookupSampleCollectionTypeFilterInput>;
  some?: Maybe<LookupSampleCollectionTypeFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfLookupSampleTypeFilterInput = {
  all?: Maybe<LookupSampleTypeFilterInput>;
  none?: Maybe<LookupSampleTypeFilterInput>;
  some?: Maybe<LookupSampleTypeFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfLookupTemplateTaskFilterInput = {
  all?: Maybe<LookupTemplateTaskFilterInput>;
  none?: Maybe<LookupTemplateTaskFilterInput>;
  some?: Maybe<LookupTemplateTaskFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfLookupTicketTypeFilterInput = {
  all?: Maybe<LookupTicketTypeFilterInput>;
  none?: Maybe<LookupTicketTypeFilterInput>;
  some?: Maybe<LookupTicketTypeFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfLotNumberFilterInput = {
  all?: Maybe<LotNumberFilterInput>;
  none?: Maybe<LotNumberFilterInput>;
  some?: Maybe<LotNumberFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfManualTaskTypeFilterInput = {
  all?: Maybe<ManualTaskTypeFilterInput>;
  none?: Maybe<ManualTaskTypeFilterInput>;
  some?: Maybe<ManualTaskTypeFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfMatrixTubeFilterInput = {
  all?: Maybe<MatrixTubeFilterInput>;
  none?: Maybe<MatrixTubeFilterInput>;
  some?: Maybe<MatrixTubeFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfMethodReservationFilterInput = {
  all?: Maybe<MethodReservationFilterInput>;
  none?: Maybe<MethodReservationFilterInput>;
  some?: Maybe<MethodReservationFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfMethodReservationsPlateBarcodeFilterInput = {
  all?: Maybe<MethodReservationsPlateBarcodeFilterInput>;
  none?: Maybe<MethodReservationsPlateBarcodeFilterInput>;
  some?: Maybe<MethodReservationsPlateBarcodeFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfMethodSourcePlateFilterFilterInput = {
  all?: Maybe<MethodSourcePlateFilterFilterInput>;
  none?: Maybe<MethodSourcePlateFilterFilterInput>;
  some?: Maybe<MethodSourcePlateFilterFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfNormalizedPassageTemplateDetailFilterInput = {
  all?: Maybe<NormalizedPassageTemplateDetailFilterInput>;
  none?: Maybe<NormalizedPassageTemplateDetailFilterInput>;
  some?: Maybe<NormalizedPassageTemplateDetailFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfNormalizedPassageTemplateFilterInput = {
  all?: Maybe<NormalizedPassageTemplateFilterInput>;
  none?: Maybe<NormalizedPassageTemplateFilterInput>;
  some?: Maybe<NormalizedPassageTemplateFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfNormalizedPassageTemplateMethodSettingFilterInput =
  {
    all?: Maybe<NormalizedPassageTemplateMethodSettingFilterInput>;
    none?: Maybe<NormalizedPassageTemplateMethodSettingFilterInput>;
    some?: Maybe<NormalizedPassageTemplateMethodSettingFilterInput>;
    any?: Maybe<Scalars["Boolean"]>;
  };

export type ListFilterInputTypeOfNormalizedPassageTemplateStampDetailFilterInput =
  {
    all?: Maybe<NormalizedPassageTemplateStampDetailFilterInput>;
    none?: Maybe<NormalizedPassageTemplateStampDetailFilterInput>;
    some?: Maybe<NormalizedPassageTemplateStampDetailFilterInput>;
    any?: Maybe<Scalars["Boolean"]>;
  };

export type ListFilterInputTypeOfNormalizedPassageTemplatesStampTopLeftFilterInput =
  {
    all?: Maybe<NormalizedPassageTemplatesStampTopLeftFilterInput>;
    none?: Maybe<NormalizedPassageTemplatesStampTopLeftFilterInput>;
    some?: Maybe<NormalizedPassageTemplatesStampTopLeftFilterInput>;
    any?: Maybe<Scalars["Boolean"]>;
  };

export type ListFilterInputTypeOfPersonalTaskAttachmentFilterInput = {
  all?: Maybe<PersonalTaskAttachmentFilterInput>;
  none?: Maybe<PersonalTaskAttachmentFilterInput>;
  some?: Maybe<PersonalTaskAttachmentFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfPersonalTaskFilterInput = {
  all?: Maybe<PersonalTaskFilterInput>;
  none?: Maybe<PersonalTaskFilterInput>;
  some?: Maybe<PersonalTaskFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfPersonalTaskTemplateFilterInput = {
  all?: Maybe<PersonalTaskTemplateFilterInput>;
  none?: Maybe<PersonalTaskTemplateFilterInput>;
  some?: Maybe<PersonalTaskTemplateFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfPersonalTaskTemplateTaskFilterInput = {
  all?: Maybe<PersonalTaskTemplateTaskFilterInput>;
  none?: Maybe<PersonalTaskTemplateTaskFilterInput>;
  some?: Maybe<PersonalTaskTemplateTaskFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfPlateMetadataFilterInput = {
  all?: Maybe<PlateMetadataFilterInput>;
  none?: Maybe<PlateMetadataFilterInput>;
  some?: Maybe<PlateMetadataFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfPlateNoteFilterInput = {
  all?: Maybe<PlateNoteFilterInput>;
  none?: Maybe<PlateNoteFilterInput>;
  some?: Maybe<PlateNoteFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfPlateTransferFilterInput = {
  all?: Maybe<PlateTransferFilterInput>;
  none?: Maybe<PlateTransferFilterInput>;
  some?: Maybe<PlateTransferFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfPlatesCreatedFilterInput = {
  all?: Maybe<PlatesCreatedFilterInput>;
  none?: Maybe<PlatesCreatedFilterInput>;
  some?: Maybe<PlatesCreatedFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfProductionRunFilterInput = {
  all?: Maybe<ProductionRunFilterInput>;
  none?: Maybe<ProductionRunFilterInput>;
  some?: Maybe<ProductionRunFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfProductionRunTypeFilterInput = {
  all?: Maybe<ProductionRunTypeFilterInput>;
  none?: Maybe<ProductionRunTypeFilterInput>;
  some?: Maybe<ProductionRunTypeFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfProjectDonorFilterInput = {
  all?: Maybe<ProjectDonorFilterInput>;
  none?: Maybe<ProjectDonorFilterInput>;
  some?: Maybe<ProjectDonorFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfRunFilterInput = {
  all?: Maybe<RunFilterInput>;
  none?: Maybe<RunFilterInput>;
  some?: Maybe<RunFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfRunStageFilterInput = {
  all?: Maybe<RunStageFilterInput>;
  none?: Maybe<RunStageFilterInput>;
  some?: Maybe<RunStageFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfRunTaskAttachmentFilterInput = {
  all?: Maybe<RunTaskAttachmentFilterInput>;
  none?: Maybe<RunTaskAttachmentFilterInput>;
  some?: Maybe<RunTaskAttachmentFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfRunTaskDetailFilterInput = {
  all?: Maybe<RunTaskDetailFilterInput>;
  none?: Maybe<RunTaskDetailFilterInput>;
  some?: Maybe<RunTaskDetailFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfRunTaskFilterInput = {
  all?: Maybe<RunTaskFilterInput>;
  none?: Maybe<RunTaskFilterInput>;
  some?: Maybe<RunTaskFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfRunTemplateFilterInput = {
  all?: Maybe<RunTemplateFilterInput>;
  none?: Maybe<RunTemplateFilterInput>;
  some?: Maybe<RunTemplateFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfRunTemplateStageFilterInput = {
  all?: Maybe<RunTemplateStageFilterInput>;
  none?: Maybe<RunTemplateStageFilterInput>;
  some?: Maybe<RunTemplateStageFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfRunTemplateTaskDetailFilterInput = {
  all?: Maybe<RunTemplateTaskDetailFilterInput>;
  none?: Maybe<RunTemplateTaskDetailFilterInput>;
  some?: Maybe<RunTemplateTaskDetailFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfRunTemplateTaskFilterInput = {
  all?: Maybe<RunTemplateTaskFilterInput>;
  none?: Maybe<RunTemplateTaskFilterInput>;
  some?: Maybe<RunTemplateTaskFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfRunTemplateTreeNodeFilterInput = {
  all?: Maybe<RunTemplateTreeNodeFilterInput>;
  none?: Maybe<RunTemplateTreeNodeFilterInput>;
  some?: Maybe<RunTemplateTreeNodeFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfRunTemplatesRepeatDaysOfWeekFilterInput = {
  all?: Maybe<RunTemplatesRepeatDaysOfWeekFilterInput>;
  none?: Maybe<RunTemplatesRepeatDaysOfWeekFilterInput>;
  some?: Maybe<RunTemplatesRepeatDaysOfWeekFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfSampleFilterInput = {
  all?: Maybe<SampleFilterInput>;
  none?: Maybe<SampleFilterInput>;
  some?: Maybe<SampleFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfSampleTypeCollectionTaskFilterInput = {
  all?: Maybe<SampleTypeCollectionTaskFilterInput>;
  none?: Maybe<SampleTypeCollectionTaskFilterInput>;
  some?: Maybe<SampleTypeCollectionTaskFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfTaskAttachmentFilterInput = {
  all?: Maybe<TaskAttachmentFilterInput>;
  none?: Maybe<TaskAttachmentFilterInput>;
  some?: Maybe<TaskAttachmentFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfTicketAssigneeFilterInput = {
  all?: Maybe<TicketAssigneeFilterInput>;
  none?: Maybe<TicketAssigneeFilterInput>;
  some?: Maybe<TicketAssigneeFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfTicketAttachmentFilterInput = {
  all?: Maybe<TicketAttachmentFilterInput>;
  none?: Maybe<TicketAttachmentFilterInput>;
  some?: Maybe<TicketAttachmentFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfTicketFilterInput = {
  all?: Maybe<TicketFilterInput>;
  none?: Maybe<TicketFilterInput>;
  some?: Maybe<TicketFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfTicketFollowerFilterInput = {
  all?: Maybe<TicketFollowerFilterInput>;
  none?: Maybe<TicketFollowerFilterInput>;
  some?: Maybe<TicketFollowerFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfTicketUpdateFilterInput = {
  all?: Maybe<TicketUpdateFilterInput>;
  none?: Maybe<TicketUpdateFilterInput>;
  some?: Maybe<TicketUpdateFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfTubeBarcodeFileFilterInput = {
  all?: Maybe<TubeBarcodeFileFilterInput>;
  none?: Maybe<TubeBarcodeFileFilterInput>;
  some?: Maybe<TubeBarcodeFileFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfUserToRoleFilterInput = {
  all?: Maybe<UserToRoleFilterInput>;
  none?: Maybe<UserToRoleFilterInput>;
  some?: Maybe<UserToRoleFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfWebSuiteTaskLogFilterInput = {
  all?: Maybe<WebSuiteTaskLogFilterInput>;
  none?: Maybe<WebSuiteTaskLogFilterInput>;
  some?: Maybe<WebSuiteTaskLogFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfWorklistContentFilterInput = {
  all?: Maybe<WorklistContentFilterInput>;
  none?: Maybe<WorklistContentFilterInput>;
  some?: Maybe<WorklistContentFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfWorklistContentImagingFilterInput = {
  all?: Maybe<WorklistContentImagingFilterInput>;
  none?: Maybe<WorklistContentImagingFilterInput>;
  some?: Maybe<WorklistContentImagingFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfWorklistFilterInput = {
  all?: Maybe<WorklistFilterInput>;
  none?: Maybe<WorklistFilterInput>;
  some?: Maybe<WorklistFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfWorklistToolTemplateContentFilterInput = {
  all?: Maybe<WorklistToolTemplateContentFilterInput>;
  none?: Maybe<WorklistToolTemplateContentFilterInput>;
  some?: Maybe<WorklistToolTemplateContentFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfWorklistToolTemplateFilterInput = {
  all?: Maybe<WorklistToolTemplateFilterInput>;
  none?: Maybe<WorklistToolTemplateFilterInput>;
  some?: Maybe<WorklistToolTemplateFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type ListFilterInputTypeOfWorklistToolTemplatePlateFilterInput = {
  all?: Maybe<WorklistToolTemplatePlateFilterInput>;
  none?: Maybe<WorklistToolTemplatePlateFilterInput>;
  some?: Maybe<WorklistToolTemplatePlateFilterInput>;
  any?: Maybe<Scalars["Boolean"]>;
};

export type LogInput = {
  severity: DiagnosticSeverity;
  message: Scalars["String"];
};

export type LogResponse = {
  __typename?: "LogResponse";
  success: Scalars["Boolean"];
};

export type LoggedInGroupMember = {
  __typename?: "LoggedInGroupMember";
  id: Scalars["Int"];
  labComputer: LabComputer;
  user?: Maybe<User>;
};

export type LookupArrayMethod = {
  __typename?: "LookupArrayMethod";
  methodId: Scalars["Int"];
  methodName?: Maybe<Scalars["String"]>;
  methodTypeId: Scalars["Int"];
  arrayMethodGroupId?: Maybe<Scalars["Int"]>;
  automationMethodId?: Maybe<Scalars["Int"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  isManual: Scalars["Boolean"];
  worklistCherryPickModeId?: Maybe<Scalars["Int"]>;
  platesRequired?: Maybe<Scalars["Int"]>;
  platesCreated?: Maybe<Scalars["Int"]>;
  incrementHundred?: Maybe<Scalars["Boolean"]>;
  useNewPrefix?: Maybe<Scalars["Boolean"]>;
  intPlatesCreated?: Maybe<Scalars["Int"]>;
  deadPlatesCreated?: Maybe<Scalars["Int"]>;
  maxSourcePlatesAllowed?: Maybe<Scalars["Int"]>;
  destArrayPlateCodeId?: Maybe<Scalars["Int"]>;
  destinationPlateType?: Maybe<Scalars["Int"]>;
  newPrefix?: Maybe<Scalars["String"]>;
  createsMystPlate?: Maybe<Scalars["Boolean"]>;
  auxilliaryPlatesCreated?: Maybe<Scalars["Int"]>;
  auxilliaryPlateType?: Maybe<Scalars["Int"]>;
  auxArrayPlateCodeId?: Maybe<Scalars["Int"]>;
  batchGenerate?: Maybe<Scalars["Boolean"]>;
  picklistDriven?: Maybe<Scalars["Boolean"]>;
  customDispenseVolumes?: Maybe<Scalars["Boolean"]>;
  cherryPick?: Maybe<Scalars["Boolean"]>;
  methodShorthand?: Maybe<Scalars["String"]>;
  optionToSkipSamples?: Maybe<Scalars["Boolean"]>;
  maps1to1ForEachSrcPlate?: Maybe<Scalars["Boolean"]>;
  promptForPartialMethod?: Maybe<Scalars["Boolean"]>;
  destPlateSampleOverflow?: Maybe<Scalars["Boolean"]>;
  productionProcessOrder?: Maybe<Scalars["Int"]>;
  pairedMethodId?: Maybe<Scalars["Int"]>;
  printSrcPlate?: Maybe<Scalars["Boolean"]>;
  mappingGroupId?: Maybe<Scalars["Int"]>;
  passageTemplateId?: Maybe<Scalars["Int"]>;
  worklistSourcePlateTask?: Maybe<Scalars["String"]>;
  worklistCustomTask?: Maybe<Scalars["String"]>;
  worklistCustomTaskDetails?: Maybe<Scalars["String"]>;
  worklistCustomTaskPosition?: Maybe<Scalars["String"]>;
  worklistDestPlateTask?: Maybe<Scalars["String"]>;
  worklistAuxilliaryPlateTask?: Maybe<Scalars["String"]>;
  worklistFirstIntPlateTask?: Maybe<Scalars["String"]>;
  worklistSecondIntPlateTask?: Maybe<Scalars["String"]>;
  worklistCherryPickTask?: Maybe<Scalars["String"]>;
  worklistCherryPickColumn?: Maybe<Scalars["String"]>;
  worklistIncludesRowNumber?: Maybe<Scalars["Boolean"]>;
  worklistRowNumberKeyword?: Maybe<Scalars["String"]>;
  durationInMinutes?: Maybe<Scalars["Int"]>;
  documentationUrl?: Maybe<Scalars["String"]>;
  arrayMethodGroup?: Maybe<LookupArrayMethodGroup>;
  automationMethod?: Maybe<LookupAutomationMethod>;
  auxArrayPlateCode?: Maybe<LookupArrayPlateCode>;
  destArrayPlateCode?: Maybe<LookupArrayPlateCode>;
  destinationPlateTypeNavigation?: Maybe<LookupArrayPlateTypeString>;
  methodType?: Maybe<LookupArrayMethodType>;
  pairedMethod?: Maybe<LookupArrayMethod>;
  worklistCherryPickMode?: Maybe<WorklistCherryPickMode>;
  arrayMethodLabwareTypes?: Maybe<Array<Maybe<ArrayMethodLabwareType>>>;
  arrayMethodPlates?: Maybe<Array<Maybe<ArrayMethodPlate>>>;
  collectionTasks?: Maybe<Array<Maybe<CollectionTask>>>;
  inversePairedMethod?: Maybe<Array<Maybe<LookupArrayMethod>>>;
  lookupAssets?: Maybe<Array<Maybe<LookupAsset>>>;
  methodReservations?: Maybe<Array<Maybe<MethodReservation>>>;
  methodSourcePlateFilters?: Maybe<Array<Maybe<MethodSourcePlateFilter>>>;
  personalTasks?: Maybe<Array<Maybe<PersonalTask>>>;
  runTasks?: Maybe<Array<Maybe<RunTask>>>;
  runTemplateTasks?: Maybe<Array<Maybe<RunTemplateTask>>>;
  worklistToolTemplates?: Maybe<Array<Maybe<WorklistToolTemplate>>>;
  worklists?: Maybe<Array<Maybe<Worklist>>>;
};

export type LookupArrayMethodFilterInput = {
  and?: Maybe<Array<LookupArrayMethodFilterInput>>;
  or?: Maybe<Array<LookupArrayMethodFilterInput>>;
  methodId?: Maybe<ComparableInt32OperationFilterInput>;
  methodName?: Maybe<StringOperationFilterInput>;
  methodTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  arrayMethodGroupId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  automationMethodId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  isManual?: Maybe<BooleanOperationFilterInput>;
  worklistCherryPickModeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  platesRequired?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  platesCreated?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  incrementHundred?: Maybe<BooleanOperationFilterInput>;
  useNewPrefix?: Maybe<BooleanOperationFilterInput>;
  intPlatesCreated?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  deadPlatesCreated?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  maxSourcePlatesAllowed?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  destArrayPlateCodeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  destinationPlateType?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  newPrefix?: Maybe<StringOperationFilterInput>;
  createsMystPlate?: Maybe<BooleanOperationFilterInput>;
  auxilliaryPlatesCreated?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  auxilliaryPlateType?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  auxArrayPlateCodeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  batchGenerate?: Maybe<BooleanOperationFilterInput>;
  picklistDriven?: Maybe<BooleanOperationFilterInput>;
  customDispenseVolumes?: Maybe<BooleanOperationFilterInput>;
  cherryPick?: Maybe<BooleanOperationFilterInput>;
  methodShorthand?: Maybe<StringOperationFilterInput>;
  optionToSkipSamples?: Maybe<BooleanOperationFilterInput>;
  maps1to1ForEachSrcPlate?: Maybe<BooleanOperationFilterInput>;
  promptForPartialMethod?: Maybe<BooleanOperationFilterInput>;
  destPlateSampleOverflow?: Maybe<BooleanOperationFilterInput>;
  productionProcessOrder?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  pairedMethodId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  printSrcPlate?: Maybe<BooleanOperationFilterInput>;
  mappingGroupId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  passageTemplateId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  worklistSourcePlateTask?: Maybe<StringOperationFilterInput>;
  worklistCustomTask?: Maybe<StringOperationFilterInput>;
  worklistCustomTaskDetails?: Maybe<StringOperationFilterInput>;
  worklistCustomTaskPosition?: Maybe<StringOperationFilterInput>;
  worklistDestPlateTask?: Maybe<StringOperationFilterInput>;
  worklistAuxilliaryPlateTask?: Maybe<StringOperationFilterInput>;
  worklistFirstIntPlateTask?: Maybe<StringOperationFilterInput>;
  worklistSecondIntPlateTask?: Maybe<StringOperationFilterInput>;
  worklistCherryPickTask?: Maybe<StringOperationFilterInput>;
  worklistCherryPickColumn?: Maybe<StringOperationFilterInput>;
  worklistIncludesRowNumber?: Maybe<BooleanOperationFilterInput>;
  worklistRowNumberKeyword?: Maybe<StringOperationFilterInput>;
  durationInMinutes?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  documentationUrl?: Maybe<StringOperationFilterInput>;
  arrayMethodGroup?: Maybe<LookupArrayMethodGroupFilterInput>;
  automationMethod?: Maybe<LookupAutomationMethodFilterInput>;
  auxArrayPlateCode?: Maybe<LookupArrayPlateCodeFilterInput>;
  destArrayPlateCode?: Maybe<LookupArrayPlateCodeFilterInput>;
  destinationPlateTypeNavigation?: Maybe<LookupArrayPlateTypeStringFilterInput>;
  methodType?: Maybe<LookupArrayMethodTypeFilterInput>;
  pairedMethod?: Maybe<LookupArrayMethodFilterInput>;
  worklistCherryPickMode?: Maybe<WorklistCherryPickModeFilterInput>;
  arrayMethodLabwareTypes?: Maybe<ListFilterInputTypeOfArrayMethodLabwareTypeFilterInput>;
  arrayMethodPlates?: Maybe<ListFilterInputTypeOfArrayMethodPlateFilterInput>;
  collectionTasks?: Maybe<ListFilterInputTypeOfCollectionTaskFilterInput>;
  inversePairedMethod?: Maybe<ListFilterInputTypeOfLookupArrayMethodFilterInput>;
  lookupAssets?: Maybe<ListFilterInputTypeOfLookupAssetFilterInput>;
  methodReservations?: Maybe<ListFilterInputTypeOfMethodReservationFilterInput>;
  methodSourcePlateFilters?: Maybe<ListFilterInputTypeOfMethodSourcePlateFilterFilterInput>;
  personalTasks?: Maybe<ListFilterInputTypeOfPersonalTaskFilterInput>;
  runTasks?: Maybe<ListFilterInputTypeOfRunTaskFilterInput>;
  runTemplateTasks?: Maybe<ListFilterInputTypeOfRunTemplateTaskFilterInput>;
  worklistToolTemplates?: Maybe<ListFilterInputTypeOfWorklistToolTemplateFilterInput>;
  worklists?: Maybe<ListFilterInputTypeOfWorklistFilterInput>;
};

export type LookupArrayMethodGroup = {
  __typename?: "LookupArrayMethodGroup";
  arrayMethodGroupId: Scalars["Int"];
  arrayMethodGroup?: Maybe<Scalars["String"]>;
  arrayMethodTypeId: Scalars["Int"];
  arrayMethodType?: Maybe<LookupArrayMethodType>;
  lookupArrayMethods?: Maybe<Array<Maybe<LookupArrayMethod>>>;
};

export type LookupArrayMethodGroupFilterInput = {
  and?: Maybe<Array<LookupArrayMethodGroupFilterInput>>;
  or?: Maybe<Array<LookupArrayMethodGroupFilterInput>>;
  arrayMethodGroupId?: Maybe<ComparableInt32OperationFilterInput>;
  arrayMethodGroup?: Maybe<StringOperationFilterInput>;
  arrayMethodTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  arrayMethodType?: Maybe<LookupArrayMethodTypeFilterInput>;
  lookupArrayMethods?: Maybe<ListFilterInputTypeOfLookupArrayMethodFilterInput>;
};

export type LookupArrayMethodGroupSortInput = {
  arrayMethodGroupId?: Maybe<SortEnumType>;
  arrayMethodGroup?: Maybe<SortEnumType>;
  arrayMethodTypeId?: Maybe<SortEnumType>;
  arrayMethodType?: Maybe<LookupArrayMethodTypeSortInput>;
};

export type LookupArrayMethodPlateRole = {
  __typename?: "LookupArrayMethodPlateRole";
  arrayMethodPlateRoleId: Scalars["Int"];
  arrayMethodPlateRole?: Maybe<Scalars["String"]>;
  methodOrder: Scalars["Int"];
  defaultArrayPlateCodeId?: Maybe<Scalars["Int"]>;
  defaultWorklistTask?: Maybe<Scalars["String"]>;
  validForIntSourcePlateCode: Scalars["Boolean"];
  validForIntDestPlateCode: Scalars["Boolean"];
  defaultWorklistColumnId: Scalars["Int"];
  isDestination: Scalars["Boolean"];
  defaultArrayPlateCode?: Maybe<LookupArrayPlateCode>;
  defaultWorklistColumn?: Maybe<WorklistColumn>;
  arrayMethodLabwareTypes?: Maybe<Array<Maybe<ArrayMethodLabwareType>>>;
  arrayMethodPlateArrayMethodPlateRoles?: Maybe<Array<Maybe<ArrayMethodPlate>>>;
  arrayMethodPlateSrcArrayMethodPlateRoles?: Maybe<
    Array<Maybe<ArrayMethodPlate>>
  >;
  arraySystemAutomationMethodLabwareTypes?: Maybe<
    Array<Maybe<ArraySystemAutomationMethodLabwareType>>
  >;
  methodReservationsPlateBarcodes?: Maybe<
    Array<Maybe<MethodReservationsPlateBarcode>>
  >;
};

export type LookupArrayMethodPlateRoleFilterInput = {
  and?: Maybe<Array<LookupArrayMethodPlateRoleFilterInput>>;
  or?: Maybe<Array<LookupArrayMethodPlateRoleFilterInput>>;
  arrayMethodPlateRoleId?: Maybe<ComparableInt32OperationFilterInput>;
  arrayMethodPlateRole?: Maybe<StringOperationFilterInput>;
  methodOrder?: Maybe<ComparableInt32OperationFilterInput>;
  defaultArrayPlateCodeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  defaultWorklistTask?: Maybe<StringOperationFilterInput>;
  validForIntSourcePlateCode?: Maybe<BooleanOperationFilterInput>;
  validForIntDestPlateCode?: Maybe<BooleanOperationFilterInput>;
  defaultWorklistColumnId?: Maybe<ComparableInt32OperationFilterInput>;
  isDestination?: Maybe<BooleanOperationFilterInput>;
  defaultArrayPlateCode?: Maybe<LookupArrayPlateCodeFilterInput>;
  defaultWorklistColumn?: Maybe<WorklistColumnFilterInput>;
  arrayMethodLabwareTypes?: Maybe<ListFilterInputTypeOfArrayMethodLabwareTypeFilterInput>;
  arrayMethodPlateArrayMethodPlateRoles?: Maybe<ListFilterInputTypeOfArrayMethodPlateFilterInput>;
  arrayMethodPlateSrcArrayMethodPlateRoles?: Maybe<ListFilterInputTypeOfArrayMethodPlateFilterInput>;
  arraySystemAutomationMethodLabwareTypes?: Maybe<ListFilterInputTypeOfArraySystemAutomationMethodLabwareTypeFilterInput>;
  methodReservationsPlateBarcodes?: Maybe<ListFilterInputTypeOfMethodReservationsPlateBarcodeFilterInput>;
};

export type LookupArrayMethodSortInput = {
  methodId?: Maybe<SortEnumType>;
  methodName?: Maybe<SortEnumType>;
  methodTypeId?: Maybe<SortEnumType>;
  arrayMethodGroupId?: Maybe<SortEnumType>;
  automationMethodId?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  isManual?: Maybe<SortEnumType>;
  worklistCherryPickModeId?: Maybe<SortEnumType>;
  platesRequired?: Maybe<SortEnumType>;
  platesCreated?: Maybe<SortEnumType>;
  incrementHundred?: Maybe<SortEnumType>;
  useNewPrefix?: Maybe<SortEnumType>;
  intPlatesCreated?: Maybe<SortEnumType>;
  deadPlatesCreated?: Maybe<SortEnumType>;
  maxSourcePlatesAllowed?: Maybe<SortEnumType>;
  destArrayPlateCodeId?: Maybe<SortEnumType>;
  destinationPlateType?: Maybe<SortEnumType>;
  newPrefix?: Maybe<SortEnumType>;
  createsMystPlate?: Maybe<SortEnumType>;
  auxilliaryPlatesCreated?: Maybe<SortEnumType>;
  auxilliaryPlateType?: Maybe<SortEnumType>;
  auxArrayPlateCodeId?: Maybe<SortEnumType>;
  batchGenerate?: Maybe<SortEnumType>;
  picklistDriven?: Maybe<SortEnumType>;
  customDispenseVolumes?: Maybe<SortEnumType>;
  cherryPick?: Maybe<SortEnumType>;
  methodShorthand?: Maybe<SortEnumType>;
  optionToSkipSamples?: Maybe<SortEnumType>;
  maps1to1ForEachSrcPlate?: Maybe<SortEnumType>;
  promptForPartialMethod?: Maybe<SortEnumType>;
  destPlateSampleOverflow?: Maybe<SortEnumType>;
  productionProcessOrder?: Maybe<SortEnumType>;
  pairedMethodId?: Maybe<SortEnumType>;
  printSrcPlate?: Maybe<SortEnumType>;
  mappingGroupId?: Maybe<SortEnumType>;
  passageTemplateId?: Maybe<SortEnumType>;
  worklistSourcePlateTask?: Maybe<SortEnumType>;
  worklistCustomTask?: Maybe<SortEnumType>;
  worklistCustomTaskDetails?: Maybe<SortEnumType>;
  worklistCustomTaskPosition?: Maybe<SortEnumType>;
  worklistDestPlateTask?: Maybe<SortEnumType>;
  worklistAuxilliaryPlateTask?: Maybe<SortEnumType>;
  worklistFirstIntPlateTask?: Maybe<SortEnumType>;
  worklistSecondIntPlateTask?: Maybe<SortEnumType>;
  worklistCherryPickTask?: Maybe<SortEnumType>;
  worklistCherryPickColumn?: Maybe<SortEnumType>;
  worklistIncludesRowNumber?: Maybe<SortEnumType>;
  worklistRowNumberKeyword?: Maybe<SortEnumType>;
  durationInMinutes?: Maybe<SortEnumType>;
  documentationUrl?: Maybe<SortEnumType>;
  arrayMethodGroup?: Maybe<LookupArrayMethodGroupSortInput>;
  automationMethod?: Maybe<LookupAutomationMethodSortInput>;
  auxArrayPlateCode?: Maybe<LookupArrayPlateCodeSortInput>;
  destArrayPlateCode?: Maybe<LookupArrayPlateCodeSortInput>;
  destinationPlateTypeNavigation?: Maybe<LookupArrayPlateTypeStringSortInput>;
  methodType?: Maybe<LookupArrayMethodTypeSortInput>;
  pairedMethod?: Maybe<LookupArrayMethodSortInput>;
  worklistCherryPickMode?: Maybe<WorklistCherryPickModeSortInput>;
};

export type LookupArrayMethodType = {
  __typename?: "LookupArrayMethodType";
  methodTypeId: Scalars["Int"];
  methodType?: Maybe<Scalars["String"]>;
  runSpecific: Scalars["Boolean"];
  inactivatesSourcePlates: Scalars["Boolean"];
  excludeSourcePlatesReservedForInactivation?: Maybe<Scalars["Boolean"]>;
  lookupArrayMethodGroups?: Maybe<Array<Maybe<LookupArrayMethodGroup>>>;
  lookupArrayMethods?: Maybe<Array<Maybe<LookupArrayMethod>>>;
};

export type LookupArrayMethodTypeFilterInput = {
  and?: Maybe<Array<LookupArrayMethodTypeFilterInput>>;
  or?: Maybe<Array<LookupArrayMethodTypeFilterInput>>;
  methodTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  methodType?: Maybe<StringOperationFilterInput>;
  runSpecific?: Maybe<BooleanOperationFilterInput>;
  inactivatesSourcePlates?: Maybe<BooleanOperationFilterInput>;
  excludeSourcePlatesReservedForInactivation?: Maybe<BooleanOperationFilterInput>;
  lookupArrayMethodGroups?: Maybe<ListFilterInputTypeOfLookupArrayMethodGroupFilterInput>;
  lookupArrayMethods?: Maybe<ListFilterInputTypeOfLookupArrayMethodFilterInput>;
};

export type LookupArrayMethodTypeSortInput = {
  methodTypeId?: Maybe<SortEnumType>;
  methodType?: Maybe<SortEnumType>;
  runSpecific?: Maybe<SortEnumType>;
  inactivatesSourcePlates?: Maybe<SortEnumType>;
  excludeSourcePlatesReservedForInactivation?: Maybe<SortEnumType>;
};

export type LookupArrayPlateCode = {
  __typename?: "LookupArrayPlateCode";
  arrayPlateCodeId: Scalars["Int"];
  arrayPlateCode?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  arrayMethodPlates?: Maybe<Array<Maybe<ArrayMethodPlate>>>;
  lookupArrayMethodAuxArrayPlateCodes?: Maybe<Array<Maybe<LookupArrayMethod>>>;
  lookupArrayMethodDestArrayPlateCodes?: Maybe<Array<Maybe<LookupArrayMethod>>>;
  lookupArrayMethodPlateRoles?: Maybe<Array<Maybe<LookupArrayMethodPlateRole>>>;
  lookupArrayPlateTypeStrings?: Maybe<Array<Maybe<LookupArrayPlateTypeString>>>;
  lookupLabwareTypes?: Maybe<Array<Maybe<LookupLabwareType>>>;
};

export type LookupArrayPlateCodeFilterInput = {
  and?: Maybe<Array<LookupArrayPlateCodeFilterInput>>;
  or?: Maybe<Array<LookupArrayPlateCodeFilterInput>>;
  arrayPlateCodeId?: Maybe<ComparableInt32OperationFilterInput>;
  arrayPlateCode?: Maybe<StringOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  arrayMethodPlates?: Maybe<ListFilterInputTypeOfArrayMethodPlateFilterInput>;
  lookupArrayMethodAuxArrayPlateCodes?: Maybe<ListFilterInputTypeOfLookupArrayMethodFilterInput>;
  lookupArrayMethodDestArrayPlateCodes?: Maybe<ListFilterInputTypeOfLookupArrayMethodFilterInput>;
  lookupArrayMethodPlateRoles?: Maybe<ListFilterInputTypeOfLookupArrayMethodPlateRoleFilterInput>;
  lookupArrayPlateTypeStrings?: Maybe<ListFilterInputTypeOfLookupArrayPlateTypeStringFilterInput>;
  lookupLabwareTypes?: Maybe<ListFilterInputTypeOfLookupLabwareTypeFilterInput>;
};

export type LookupArrayPlateCodeSortInput = {
  arrayPlateCodeId?: Maybe<SortEnumType>;
  arrayPlateCode?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
};

export type LookupArrayPlateTypeString = {
  __typename?: "LookupArrayPlateTypeString";
  plateTypeId: Scalars["Int"];
  arrayPlateType?: Maybe<Scalars["String"]>;
  productionRunTypeId?: Maybe<Scalars["Int"]>;
  arrayPlateCodeId: Scalars["Int"];
  defaultLabwareTypeId?: Maybe<Scalars["Int"]>;
  defaultCeligoExperimentSettingsId?: Maybe<Scalars["Int"]>;
  plateLayoutTypeId?: Maybe<Scalars["Int"]>;
  arrayPlateRoleId: Scalars["Int"];
  newSampleTypeId?: Maybe<Scalars["Int"]>;
  lotNumberTypeId?: Maybe<Scalars["Int"]>;
  lotNumberFormatId?: Maybe<Scalars["Int"]>;
  plateDescription?: Maybe<Scalars["String"]>;
  passageByDayLimit?: Maybe<Scalars["Int"]>;
  dataAbsenceAutoInactivationLimit?: Maybe<Scalars["Int"]>;
  isMatrixRack?: Maybe<Scalars["Boolean"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  runTemplateId?: Maybe<Scalars["Int"]>;
  arrayPlateCode?: Maybe<LookupArrayPlateCode>;
  defaultCeligoExperimentSettings?: Maybe<LookupExperimentSetting>;
  defaultLabwareType?: Maybe<LookupLabwareType>;
  lotNumberFormat?: Maybe<LotNumberFormat>;
  lotNumberType?: Maybe<LookupLotNumberType>;
  newSampleType?: Maybe<LookupSampleType>;
  plateLayoutType?: Maybe<PlateType>;
  productionRunType?: Maybe<ProductionRunType>;
  runTemplate?: Maybe<RunTemplate>;
  lookupArrayMethods?: Maybe<Array<Maybe<LookupArrayMethod>>>;
  platesCreateds?: Maybe<Array<Maybe<PlatesCreated>>>;
  productionRunTypeFinalPlateTypes?: Maybe<Array<Maybe<ProductionRunType>>>;
  productionRunTypeSourcePlateTypes?: Maybe<Array<Maybe<ProductionRunType>>>;
};

export type LookupArrayPlateTypeStringFilterInput = {
  and?: Maybe<Array<LookupArrayPlateTypeStringFilterInput>>;
  or?: Maybe<Array<LookupArrayPlateTypeStringFilterInput>>;
  plateTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  arrayPlateType?: Maybe<StringOperationFilterInput>;
  productionRunTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  arrayPlateCodeId?: Maybe<ComparableInt32OperationFilterInput>;
  defaultLabwareTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  defaultCeligoExperimentSettingsId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  plateLayoutTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  arrayPlateRoleId?: Maybe<ComparableInt32OperationFilterInput>;
  newSampleTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  lotNumberTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  lotNumberFormatId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  plateDescription?: Maybe<StringOperationFilterInput>;
  passageByDayLimit?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  dataAbsenceAutoInactivationLimit?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  isMatrixRack?: Maybe<BooleanOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  runTemplateId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  arrayPlateCode?: Maybe<LookupArrayPlateCodeFilterInput>;
  defaultCeligoExperimentSettings?: Maybe<LookupExperimentSettingFilterInput>;
  defaultLabwareType?: Maybe<LookupLabwareTypeFilterInput>;
  lotNumberFormat?: Maybe<LotNumberFormatFilterInput>;
  lotNumberType?: Maybe<LookupLotNumberTypeFilterInput>;
  newSampleType?: Maybe<LookupSampleTypeFilterInput>;
  plateLayoutType?: Maybe<PlateTypeFilterInput>;
  productionRunType?: Maybe<ProductionRunTypeFilterInput>;
  runTemplate?: Maybe<RunTemplateFilterInput>;
  lookupArrayMethods?: Maybe<ListFilterInputTypeOfLookupArrayMethodFilterInput>;
  platesCreateds?: Maybe<ListFilterInputTypeOfPlatesCreatedFilterInput>;
  productionRunTypeFinalPlateTypes?: Maybe<ListFilterInputTypeOfProductionRunTypeFilterInput>;
  productionRunTypeSourcePlateTypes?: Maybe<ListFilterInputTypeOfProductionRunTypeFilterInput>;
};

export type LookupArrayPlateTypeStringSortInput = {
  plateTypeId?: Maybe<SortEnumType>;
  arrayPlateType?: Maybe<SortEnumType>;
  productionRunTypeId?: Maybe<SortEnumType>;
  arrayPlateCodeId?: Maybe<SortEnumType>;
  defaultLabwareTypeId?: Maybe<SortEnumType>;
  defaultCeligoExperimentSettingsId?: Maybe<SortEnumType>;
  plateLayoutTypeId?: Maybe<SortEnumType>;
  arrayPlateRoleId?: Maybe<SortEnumType>;
  newSampleTypeId?: Maybe<SortEnumType>;
  lotNumberTypeId?: Maybe<SortEnumType>;
  lotNumberFormatId?: Maybe<SortEnumType>;
  plateDescription?: Maybe<SortEnumType>;
  passageByDayLimit?: Maybe<SortEnumType>;
  dataAbsenceAutoInactivationLimit?: Maybe<SortEnumType>;
  isMatrixRack?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  runTemplateId?: Maybe<SortEnumType>;
  arrayPlateCode?: Maybe<LookupArrayPlateCodeSortInput>;
  defaultCeligoExperimentSettings?: Maybe<LookupExperimentSettingSortInput>;
  defaultLabwareType?: Maybe<LookupLabwareTypeSortInput>;
  lotNumberFormat?: Maybe<LotNumberFormatSortInput>;
  lotNumberType?: Maybe<LookupLotNumberTypeSortInput>;
  newSampleType?: Maybe<LookupSampleTypeSortInput>;
  plateLayoutType?: Maybe<PlateTypeSortInput>;
  productionRunType?: Maybe<ProductionRunTypeSortInput>;
  runTemplate?: Maybe<RunTemplateSortInput>;
};

export type LookupArraySystem = {
  __typename?: "LookupArraySystem";
  systemId: Scalars["Int"];
  systemName?: Maybe<Scalars["String"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  systemNumber?: Maybe<Scalars["Int"]>;
  slackChannelId?: Maybe<Scalars["Int"]>;
  systemShorthand?: Maybe<Scalars["String"]>;
  clusterId?: Maybe<Scalars["Int"]>;
  systemTypeId?: Maybe<Scalars["Int"]>;
  cluster?: Maybe<Cluster>;
  systemType?: Maybe<SystemType>;
  arraySystemAutomationMethods?: Maybe<
    Array<Maybe<ArraySystemAutomationMethod>>
  >;
  collectionTasks?: Maybe<Array<Maybe<CollectionTask>>>;
  methodReservations?: Maybe<Array<Maybe<MethodReservation>>>;
  personalTasks?: Maybe<Array<Maybe<PersonalTask>>>;
  runTasks?: Maybe<Array<Maybe<RunTask>>>;
  runTemplateTasks?: Maybe<Array<Maybe<RunTemplateTask>>>;
  worklists?: Maybe<Array<Maybe<Worklist>>>;
};

export type LookupArraySystemFilterInput = {
  and?: Maybe<Array<LookupArraySystemFilterInput>>;
  or?: Maybe<Array<LookupArraySystemFilterInput>>;
  systemId?: Maybe<ComparableInt32OperationFilterInput>;
  systemName?: Maybe<StringOperationFilterInput>;
  serialNumber?: Maybe<StringOperationFilterInput>;
  systemNumber?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  slackChannelId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  systemShorthand?: Maybe<StringOperationFilterInput>;
  clusterId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  systemTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  cluster?: Maybe<ClusterFilterInput>;
  systemType?: Maybe<SystemTypeFilterInput>;
  arraySystemAutomationMethods?: Maybe<ListFilterInputTypeOfArraySystemAutomationMethodFilterInput>;
  collectionTasks?: Maybe<ListFilterInputTypeOfCollectionTaskFilterInput>;
  methodReservations?: Maybe<ListFilterInputTypeOfMethodReservationFilterInput>;
  personalTasks?: Maybe<ListFilterInputTypeOfPersonalTaskFilterInput>;
  runTasks?: Maybe<ListFilterInputTypeOfRunTaskFilterInput>;
  runTemplateTasks?: Maybe<ListFilterInputTypeOfRunTemplateTaskFilterInput>;
  worklists?: Maybe<ListFilterInputTypeOfWorklistFilterInput>;
};

export type LookupArraySystemSortInput = {
  systemId?: Maybe<SortEnumType>;
  systemName?: Maybe<SortEnumType>;
  serialNumber?: Maybe<SortEnumType>;
  systemNumber?: Maybe<SortEnumType>;
  slackChannelId?: Maybe<SortEnumType>;
  systemShorthand?: Maybe<SortEnumType>;
  clusterId?: Maybe<SortEnumType>;
  systemTypeId?: Maybe<SortEnumType>;
  cluster?: Maybe<ClusterSortInput>;
  systemType?: Maybe<SystemTypeSortInput>;
};

export type LookupAsset = {
  __typename?: "LookupAsset";
  assetId: Scalars["Int"];
  asset?: Maybe<Scalars["String"]>;
  assetTypeId: Scalars["Int"];
  serialNumber?: Maybe<Scalars["String"]>;
  manufacturer?: Maybe<Scalars["String"]>;
  vendor?: Maybe<Scalars["String"]>;
  contractId?: Maybe<Scalars["Int"]>;
  designName?: Maybe<Scalars["String"]>;
  documentationUrl?: Maybe<Scalars["String"]>;
  arrayMethodId?: Maybe<Scalars["Int"]>;
  arraySystemAutomationMethodId?: Maybe<Scalars["Int"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  arrayMethod?: Maybe<LookupArrayMethod>;
  arraySystemAutomationMethod?: Maybe<ArraySystemAutomationMethod>;
  assetType?: Maybe<LookupAssetType>;
  tickets?: Maybe<Array<Maybe<Ticket>>>;
};

export type LookupAssetFilterInput = {
  and?: Maybe<Array<LookupAssetFilterInput>>;
  or?: Maybe<Array<LookupAssetFilterInput>>;
  assetId?: Maybe<ComparableInt32OperationFilterInput>;
  asset?: Maybe<StringOperationFilterInput>;
  assetTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  serialNumber?: Maybe<StringOperationFilterInput>;
  manufacturer?: Maybe<StringOperationFilterInput>;
  vendor?: Maybe<StringOperationFilterInput>;
  contractId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  designName?: Maybe<StringOperationFilterInput>;
  documentationUrl?: Maybe<StringOperationFilterInput>;
  arrayMethodId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  arraySystemAutomationMethodId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  arrayMethod?: Maybe<LookupArrayMethodFilterInput>;
  arraySystemAutomationMethod?: Maybe<ArraySystemAutomationMethodFilterInput>;
  assetType?: Maybe<LookupAssetTypeFilterInput>;
  tickets?: Maybe<ListFilterInputTypeOfTicketFilterInput>;
};

export type LookupAssetSortInput = {
  assetId?: Maybe<SortEnumType>;
  asset?: Maybe<SortEnumType>;
  assetTypeId?: Maybe<SortEnumType>;
  serialNumber?: Maybe<SortEnumType>;
  manufacturer?: Maybe<SortEnumType>;
  vendor?: Maybe<SortEnumType>;
  contractId?: Maybe<SortEnumType>;
  designName?: Maybe<SortEnumType>;
  documentationUrl?: Maybe<SortEnumType>;
  arrayMethodId?: Maybe<SortEnumType>;
  arraySystemAutomationMethodId?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  arrayMethod?: Maybe<LookupArrayMethodSortInput>;
  arraySystemAutomationMethod?: Maybe<ArraySystemAutomationMethodSortInput>;
  assetType?: Maybe<LookupAssetTypeSortInput>;
};

export type LookupAssetType = {
  __typename?: "LookupAssetType";
  assetTypeId: Scalars["Int"];
  assetType?: Maybe<Scalars["String"]>;
  userGroupId: Scalars["Int"];
  githubProjectId?: Maybe<Scalars["Int"]>;
  githubProject?: Maybe<GithubProject>;
  userGroup?: Maybe<AppSuiteGroup>;
  lookupAssets?: Maybe<Array<Maybe<LookupAsset>>>;
};

export type LookupAssetTypeFilterInput = {
  and?: Maybe<Array<LookupAssetTypeFilterInput>>;
  or?: Maybe<Array<LookupAssetTypeFilterInput>>;
  assetTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  assetType?: Maybe<StringOperationFilterInput>;
  userGroupId?: Maybe<ComparableInt32OperationFilterInput>;
  githubProjectId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  githubProject?: Maybe<GithubProjectFilterInput>;
  userGroup?: Maybe<AppSuiteGroupFilterInput>;
  lookupAssets?: Maybe<ListFilterInputTypeOfLookupAssetFilterInput>;
};

export type LookupAssetTypeSortInput = {
  assetTypeId?: Maybe<SortEnumType>;
  assetType?: Maybe<SortEnumType>;
  userGroupId?: Maybe<SortEnumType>;
  githubProjectId?: Maybe<SortEnumType>;
  githubProject?: Maybe<GithubProjectSortInput>;
  userGroup?: Maybe<AppSuiteGroupSortInput>;
};

export type LookupAutomationMethod = {
  __typename?: "LookupAutomationMethod";
  automationMethodId: Scalars["Int"];
  automationMethod?: Maybe<Scalars["String"]>;
  arraySystemAutomationMethods?: Maybe<
    Array<Maybe<ArraySystemAutomationMethod>>
  >;
  lookupArrayMethods?: Maybe<Array<Maybe<LookupArrayMethod>>>;
  manualTaskTypes?: Maybe<Array<Maybe<ManualTaskType>>>;
};

export type LookupAutomationMethodFilterInput = {
  and?: Maybe<Array<LookupAutomationMethodFilterInput>>;
  or?: Maybe<Array<LookupAutomationMethodFilterInput>>;
  automationMethodId?: Maybe<ComparableInt32OperationFilterInput>;
  automationMethod?: Maybe<StringOperationFilterInput>;
  arraySystemAutomationMethods?: Maybe<ListFilterInputTypeOfArraySystemAutomationMethodFilterInput>;
  lookupArrayMethods?: Maybe<ListFilterInputTypeOfLookupArrayMethodFilterInput>;
  manualTaskTypes?: Maybe<ListFilterInputTypeOfManualTaskTypeFilterInput>;
};

export type LookupAutomationMethodProcessRunStatus = {
  __typename?: "LookupAutomationMethodProcessRunStatus";
  automationMethodProcessRunStatusId: Scalars["Int"];
  processRunStatus?: Maybe<Scalars["String"]>;
  arraySystemAutomationMethodProcessRuns?: Maybe<
    Array<Maybe<ArraySystemAutomationMethodProcessRun>>
  >;
};

export type LookupAutomationMethodProcessRunStatusFilterInput = {
  and?: Maybe<Array<LookupAutomationMethodProcessRunStatusFilterInput>>;
  or?: Maybe<Array<LookupAutomationMethodProcessRunStatusFilterInput>>;
  automationMethodProcessRunStatusId?: Maybe<ComparableInt32OperationFilterInput>;
  processRunStatus?: Maybe<StringOperationFilterInput>;
  arraySystemAutomationMethodProcessRuns?: Maybe<ListFilterInputTypeOfArraySystemAutomationMethodProcessRunFilterInput>;
};

export type LookupAutomationMethodProcessRunType = {
  __typename?: "LookupAutomationMethodProcessRunType";
  automationMethodProcessRunTypeId: Scalars["Int"];
  processRunType?: Maybe<Scalars["String"]>;
  arraySystemAutomationMethodProcesses?: Maybe<
    Array<Maybe<ArraySystemAutomationMethodProcess>>
  >;
};

export type LookupAutomationMethodProcessRunTypeFilterInput = {
  and?: Maybe<Array<LookupAutomationMethodProcessRunTypeFilterInput>>;
  or?: Maybe<Array<LookupAutomationMethodProcessRunTypeFilterInput>>;
  automationMethodProcessRunTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  processRunType?: Maybe<StringOperationFilterInput>;
  arraySystemAutomationMethodProcesses?: Maybe<ListFilterInputTypeOfArraySystemAutomationMethodProcessFilterInput>;
};

export type LookupAutomationMethodSortInput = {
  automationMethodId?: Maybe<SortEnumType>;
  automationMethod?: Maybe<SortEnumType>;
};

export type LookupContainerMarkingType = {
  __typename?: "LookupContainerMarkingType";
  containerMarkingTypeId: Scalars["Int"];
  conditionGroupId: Scalars["Int"];
  conditionType?: Maybe<Scalars["String"]>;
  conditionAbbreviation?: Maybe<Scalars["String"]>;
  allowedPlatePatterns?: Maybe<Scalars["String"]>;
  plateMapBackColor?: Maybe<Scalars["String"]>;
  backColorPriority?: Maybe<Scalars["Int"]>;
  autoMarkQcfail?: Maybe<Scalars["Boolean"]>;
  inactivationReasonId?: Maybe<Scalars["Int"]>;
  limitPropagationToCurrentRun?: Maybe<Scalars["Boolean"]>;
  conditionOld?: Maybe<Scalars["String"]>;
  qcpassFailFlagId?: Maybe<Scalars["Int"]>;
  inactivationReason?: Maybe<LookupInactivationReason>;
  containerMarkings?: Maybe<Array<Maybe<ContainerMarking>>>;
};

export type LookupContainerMarkingTypeFilterInput = {
  and?: Maybe<Array<LookupContainerMarkingTypeFilterInput>>;
  or?: Maybe<Array<LookupContainerMarkingTypeFilterInput>>;
  containerMarkingTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  conditionGroupId?: Maybe<ComparableInt32OperationFilterInput>;
  conditionType?: Maybe<StringOperationFilterInput>;
  conditionAbbreviation?: Maybe<StringOperationFilterInput>;
  allowedPlatePatterns?: Maybe<StringOperationFilterInput>;
  plateMapBackColor?: Maybe<StringOperationFilterInput>;
  backColorPriority?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  autoMarkQcfail?: Maybe<BooleanOperationFilterInput>;
  inactivationReasonId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  limitPropagationToCurrentRun?: Maybe<BooleanOperationFilterInput>;
  conditionOld?: Maybe<StringOperationFilterInput>;
  qcpassFailFlagId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  inactivationReason?: Maybe<LookupInactivationReasonFilterInput>;
  containerMarkings?: Maybe<ListFilterInputTypeOfContainerMarkingFilterInput>;
};

export type LookupExperimentSetting = {
  __typename?: "LookupExperimentSetting";
  experimentSettingId: Scalars["Int"];
  experimentSetting?: Maybe<Scalars["String"]>;
  experimentSettingScanTypeId: Scalars["Int"];
  plateLayoutTypeId: Scalars["Int"];
  focusWellRowPos?: Maybe<Scalars["Int"]>;
  focusWellColPos?: Maybe<Scalars["Int"]>;
  plateLayoutType?: Maybe<PlateType>;
  lookupArrayPlateTypeStrings?: Maybe<Array<Maybe<LookupArrayPlateTypeString>>>;
  lookupLabwareTypes?: Maybe<Array<Maybe<LookupLabwareType>>>;
  platesCreateds?: Maybe<Array<Maybe<PlatesCreated>>>;
};

export type LookupExperimentSettingFilterInput = {
  and?: Maybe<Array<LookupExperimentSettingFilterInput>>;
  or?: Maybe<Array<LookupExperimentSettingFilterInput>>;
  experimentSettingId?: Maybe<ComparableInt32OperationFilterInput>;
  experimentSetting?: Maybe<StringOperationFilterInput>;
  experimentSettingScanTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  plateLayoutTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  focusWellRowPos?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  focusWellColPos?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  plateLayoutType?: Maybe<PlateTypeFilterInput>;
  lookupArrayPlateTypeStrings?: Maybe<ListFilterInputTypeOfLookupArrayPlateTypeStringFilterInput>;
  lookupLabwareTypes?: Maybe<ListFilterInputTypeOfLookupLabwareTypeFilterInput>;
  platesCreateds?: Maybe<ListFilterInputTypeOfPlatesCreatedFilterInput>;
};

export type LookupExperimentSettingSortInput = {
  experimentSettingId?: Maybe<SortEnumType>;
  experimentSetting?: Maybe<SortEnumType>;
  experimentSettingScanTypeId?: Maybe<SortEnumType>;
  plateLayoutTypeId?: Maybe<SortEnumType>;
  focusWellRowPos?: Maybe<SortEnumType>;
  focusWellColPos?: Maybe<SortEnumType>;
  plateLayoutType?: Maybe<PlateTypeSortInput>;
};

export type LookupInactivationReason = {
  __typename?: "LookupInactivationReason";
  inactivationReasonId: Scalars["Int"];
  reason?: Maybe<Scalars["String"]>;
  specificReason?: Maybe<Scalars["String"]>;
  activeForRun?: Maybe<Scalars["Boolean"]>;
  activeForPlate?: Maybe<Scalars["Boolean"]>;
  activeForContainer?: Maybe<Scalars["Boolean"]>;
  activeForBloodProcessing?: Maybe<Scalars["Boolean"]>;
  containerMarkingTypeId?: Maybe<Scalars["Int"]>;
  containers?: Maybe<Array<Maybe<Container>>>;
  lookupContainerMarkingTypes?: Maybe<Array<Maybe<LookupContainerMarkingType>>>;
};

export type LookupInactivationReasonFilterInput = {
  and?: Maybe<Array<LookupInactivationReasonFilterInput>>;
  or?: Maybe<Array<LookupInactivationReasonFilterInput>>;
  inactivationReasonId?: Maybe<ComparableInt32OperationFilterInput>;
  reason?: Maybe<StringOperationFilterInput>;
  specificReason?: Maybe<StringOperationFilterInput>;
  activeForRun?: Maybe<BooleanOperationFilterInput>;
  activeForPlate?: Maybe<BooleanOperationFilterInput>;
  activeForContainer?: Maybe<BooleanOperationFilterInput>;
  activeForBloodProcessing?: Maybe<BooleanOperationFilterInput>;
  containerMarkingTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  containers?: Maybe<ListFilterInputTypeOfContainerFilterInput>;
  lookupContainerMarkingTypes?: Maybe<ListFilterInputTypeOfLookupContainerMarkingTypeFilterInput>;
};

export type LookupInactivationReasonSortInput = {
  inactivationReasonId?: Maybe<SortEnumType>;
  reason?: Maybe<SortEnumType>;
  specificReason?: Maybe<SortEnumType>;
  activeForRun?: Maybe<SortEnumType>;
  activeForPlate?: Maybe<SortEnumType>;
  activeForContainer?: Maybe<SortEnumType>;
  activeForBloodProcessing?: Maybe<SortEnumType>;
  containerMarkingTypeId?: Maybe<SortEnumType>;
};

export type LookupLabwareType = {
  __typename?: "LookupLabwareType";
  labwareTypeId: Scalars["Int"];
  labwareTypeName?: Maybe<Scalars["String"]>;
  manufacturer?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  plateLayoutTypeId: Scalars["Int"];
  isActive: Scalars["Boolean"];
  labwareTypeCode?: Maybe<Scalars["String"]>;
  celigoScanName?: Maybe<Scalars["String"]>;
  defaultCeligoExperimentSettingId?: Maybe<Scalars["Int"]>;
  footprint?: Maybe<Scalars["String"]>;
  platePitch?: Maybe<Scalars["String"]>;
  defaultLabwareVolume?: Maybe<Scalars["Int"]>;
  minLabwareVolume?: Maybe<Scalars["Int"]>;
  maxLabwareVolume?: Maybe<Scalars["Int"]>;
  defaultAspirateVolume?: Maybe<Scalars["Int"]>;
  minAspirateVolume?: Maybe<Scalars["Int"]>;
  maxAspirateVolume?: Maybe<Scalars["Int"]>;
  defaultDispenseVolume?: Maybe<Scalars["Int"]>;
  minDispenseVolume?: Maybe<Scalars["Int"]>;
  maxDispenseVolume?: Maybe<Scalars["Int"]>;
  maxStampResuspensionVolume?: Maybe<Scalars["Int"]>;
  defaultArrayPlateCode?: Maybe<Scalars["String"]>;
  defaultArrayPlateCodeId?: Maybe<Scalars["Int"]>;
  labwareTypeSummary?: Maybe<Scalars["String"]>;
  defaultArrayPlateCodeNavigation?: Maybe<LookupArrayPlateCode>;
  defaultCeligoExperimentSetting?: Maybe<LookupExperimentSetting>;
  plateLayoutType?: Maybe<PlateType>;
  arrayMethodLabwareTypes?: Maybe<Array<Maybe<ArrayMethodLabwareType>>>;
  arraySystemAutomationMethodLabwareTypes?: Maybe<
    Array<Maybe<ArraySystemAutomationMethodLabwareType>>
  >;
  lookupArrayPlateTypeStrings?: Maybe<Array<Maybe<LookupArrayPlateTypeString>>>;
  methodReservationsPlateBarcodes?: Maybe<
    Array<Maybe<MethodReservationsPlateBarcode>>
  >;
  platesCreateds?: Maybe<Array<Maybe<PlatesCreated>>>;
  runTaskDetails?: Maybe<Array<Maybe<RunTaskDetail>>>;
  runTemplateTaskDetails?: Maybe<Array<Maybe<RunTemplateTaskDetail>>>;
};

export type LookupLabwareTypeFilterInput = {
  and?: Maybe<Array<LookupLabwareTypeFilterInput>>;
  or?: Maybe<Array<LookupLabwareTypeFilterInput>>;
  labwareTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  labwareTypeName?: Maybe<StringOperationFilterInput>;
  manufacturer?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  plateLayoutTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  labwareTypeCode?: Maybe<StringOperationFilterInput>;
  celigoScanName?: Maybe<StringOperationFilterInput>;
  defaultCeligoExperimentSettingId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  footprint?: Maybe<StringOperationFilterInput>;
  platePitch?: Maybe<StringOperationFilterInput>;
  defaultLabwareVolume?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  minLabwareVolume?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  maxLabwareVolume?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  defaultAspirateVolume?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  minAspirateVolume?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  maxAspirateVolume?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  defaultDispenseVolume?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  minDispenseVolume?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  maxDispenseVolume?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  maxStampResuspensionVolume?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  defaultArrayPlateCode?: Maybe<StringOperationFilterInput>;
  defaultArrayPlateCodeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  labwareTypeSummary?: Maybe<StringOperationFilterInput>;
  defaultArrayPlateCodeNavigation?: Maybe<LookupArrayPlateCodeFilterInput>;
  defaultCeligoExperimentSetting?: Maybe<LookupExperimentSettingFilterInput>;
  plateLayoutType?: Maybe<PlateTypeFilterInput>;
  arrayMethodLabwareTypes?: Maybe<ListFilterInputTypeOfArrayMethodLabwareTypeFilterInput>;
  arraySystemAutomationMethodLabwareTypes?: Maybe<ListFilterInputTypeOfArraySystemAutomationMethodLabwareTypeFilterInput>;
  lookupArrayPlateTypeStrings?: Maybe<ListFilterInputTypeOfLookupArrayPlateTypeStringFilterInput>;
  methodReservationsPlateBarcodes?: Maybe<ListFilterInputTypeOfMethodReservationsPlateBarcodeFilterInput>;
  platesCreateds?: Maybe<ListFilterInputTypeOfPlatesCreatedFilterInput>;
  runTaskDetails?: Maybe<ListFilterInputTypeOfRunTaskDetailFilterInput>;
  runTemplateTaskDetails?: Maybe<ListFilterInputTypeOfRunTemplateTaskDetailFilterInput>;
};

export type LookupLabwareTypeSortInput = {
  labwareTypeId?: Maybe<SortEnumType>;
  labwareTypeName?: Maybe<SortEnumType>;
  manufacturer?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  plateLayoutTypeId?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  labwareTypeCode?: Maybe<SortEnumType>;
  celigoScanName?: Maybe<SortEnumType>;
  defaultCeligoExperimentSettingId?: Maybe<SortEnumType>;
  footprint?: Maybe<SortEnumType>;
  platePitch?: Maybe<SortEnumType>;
  defaultLabwareVolume?: Maybe<SortEnumType>;
  minLabwareVolume?: Maybe<SortEnumType>;
  maxLabwareVolume?: Maybe<SortEnumType>;
  defaultAspirateVolume?: Maybe<SortEnumType>;
  minAspirateVolume?: Maybe<SortEnumType>;
  maxAspirateVolume?: Maybe<SortEnumType>;
  defaultDispenseVolume?: Maybe<SortEnumType>;
  minDispenseVolume?: Maybe<SortEnumType>;
  maxDispenseVolume?: Maybe<SortEnumType>;
  maxStampResuspensionVolume?: Maybe<SortEnumType>;
  defaultArrayPlateCode?: Maybe<SortEnumType>;
  defaultArrayPlateCodeId?: Maybe<SortEnumType>;
  labwareTypeSummary?: Maybe<SortEnumType>;
  defaultArrayPlateCodeNavigation?: Maybe<LookupArrayPlateCodeSortInput>;
  defaultCeligoExperimentSetting?: Maybe<LookupExperimentSettingSortInput>;
  plateLayoutType?: Maybe<PlateTypeSortInput>;
};

export type LookupLotNumberType = {
  __typename?: "LookupLotNumberType";
  lotNumberTypeId: Scalars["Int"];
  lotNumberTypeName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  lotNumberFormat?: Maybe<Scalars["String"]>;
  lookupArrayPlateTypeStrings?: Maybe<Array<Maybe<LookupArrayPlateTypeString>>>;
  lotNumbers?: Maybe<Array<Maybe<LotNumber>>>;
  productionRunTypes?: Maybe<Array<Maybe<ProductionRunType>>>;
};

export type LookupLotNumberTypeFilterInput = {
  and?: Maybe<Array<LookupLotNumberTypeFilterInput>>;
  or?: Maybe<Array<LookupLotNumberTypeFilterInput>>;
  lotNumberTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  lotNumberTypeName?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  lotNumberFormat?: Maybe<StringOperationFilterInput>;
  lookupArrayPlateTypeStrings?: Maybe<ListFilterInputTypeOfLookupArrayPlateTypeStringFilterInput>;
  lotNumbers?: Maybe<ListFilterInputTypeOfLotNumberFilterInput>;
  productionRunTypes?: Maybe<ListFilterInputTypeOfProductionRunTypeFilterInput>;
};

export type LookupLotNumberTypeSortInput = {
  lotNumberTypeId?: Maybe<SortEnumType>;
  lotNumberTypeName?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  lotNumberFormat?: Maybe<SortEnumType>;
};

export type LookupMedicalCondition = {
  __typename?: "LookupMedicalCondition";
  medicalConditionId: Scalars["Int"];
  medicalCondition?: Maybe<Scalars["String"]>;
  definition?: Maybe<Scalars["String"]>;
  medicalConditionTypeId?: Maybe<Scalars["Int"]>;
  healthyControlTypeId?: Maybe<Scalars["Byte"]>;
  doid?: Maybe<Scalars["String"]>;
  importedFromRedcap?: Maybe<Scalars["Boolean"]>;
  donorMedicalConditions?: Maybe<Array<Maybe<DonorMedicalCondition>>>;
};

export type LookupMedicalConditionFilterInput = {
  and?: Maybe<Array<LookupMedicalConditionFilterInput>>;
  or?: Maybe<Array<LookupMedicalConditionFilterInput>>;
  medicalConditionId?: Maybe<ComparableInt32OperationFilterInput>;
  medicalCondition?: Maybe<StringOperationFilterInput>;
  definition?: Maybe<StringOperationFilterInput>;
  medicalConditionTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  healthyControlTypeId?: Maybe<ComparableNullableOfByteOperationFilterInput>;
  doid?: Maybe<StringOperationFilterInput>;
  importedFromRedcap?: Maybe<BooleanOperationFilterInput>;
  donorMedicalConditions?: Maybe<ListFilterInputTypeOfDonorMedicalConditionFilterInput>;
};

export type LookupRunActivityType = {
  __typename?: "LookupRunActivityType";
  runActivityTypeId: Scalars["Int"];
  runActivityType?: Maybe<Scalars["String"]>;
  lookupTemplateTasks?: Maybe<Array<Maybe<LookupTemplateTask>>>;
  runTasks?: Maybe<Array<Maybe<RunTask>>>;
};

export type LookupRunActivityTypeFilterInput = {
  and?: Maybe<Array<LookupRunActivityTypeFilterInput>>;
  or?: Maybe<Array<LookupRunActivityTypeFilterInput>>;
  runActivityTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  runActivityType?: Maybe<StringOperationFilterInput>;
  lookupTemplateTasks?: Maybe<ListFilterInputTypeOfLookupTemplateTaskFilterInput>;
  runTasks?: Maybe<ListFilterInputTypeOfRunTaskFilterInput>;
};

export type LookupRunActivityTypeSortInput = {
  runActivityTypeId?: Maybe<SortEnumType>;
  runActivityType?: Maybe<SortEnumType>;
};

export type LookupSampleCollectionType = {
  __typename?: "LookupSampleCollectionType";
  sampleCollectionTypeId: Scalars["Byte"];
  sampleCollectionType?: Maybe<Scalars["String"]>;
  redcapCollectionTypeCheckedVariable?: Maybe<Scalars["String"]>;
  sampleTypeId?: Maybe<Scalars["Int"]>;
  sampleType?: Maybe<LookupSampleType>;
};

export type LookupSampleCollectionTypeFilterInput = {
  and?: Maybe<Array<LookupSampleCollectionTypeFilterInput>>;
  or?: Maybe<Array<LookupSampleCollectionTypeFilterInput>>;
  sampleCollectionTypeId?: Maybe<ComparableByteOperationFilterInput>;
  sampleCollectionType?: Maybe<StringOperationFilterInput>;
  redcapCollectionTypeCheckedVariable?: Maybe<StringOperationFilterInput>;
  sampleTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  sampleType?: Maybe<LookupSampleTypeFilterInput>;
};

export type LookupSampleType = {
  __typename?: "LookupSampleType";
  sampleTypeId: Scalars["Int"];
  sampleTypeString?: Maybe<Scalars["String"]>;
  sampleTypeAbbreviation?: Maybe<Scalars["String"]>;
  isSourceSampleType: Scalars["Boolean"];
  isDerivedSampleType: Scalars["Boolean"];
  isStemCellSampleType: Scalars["Boolean"];
  isCrisprsampleType: Scalars["Boolean"];
  isMonoclonalSampleType: Scalars["Boolean"];
  reprogrammingTier?: Maybe<Scalars["Byte"]>;
  lowStockAlertLimit?: Maybe<Scalars["Int"]>;
  monoclonalSampleTypeId?: Maybe<Scalars["Int"]>;
  polyclonalSampleTypeId?: Maybe<Scalars["Int"]>;
  appendSampleTypeCode?: Maybe<Scalars["Boolean"]>;
  appendRun?: Maybe<Scalars["Boolean"]>;
  monoclonalSampleType?: Maybe<LookupSampleType>;
  polyclonalSampleType?: Maybe<LookupSampleType>;
  cellLines?: Maybe<Array<Maybe<CellLine>>>;
  collectionSampleTypes?: Maybe<Array<Maybe<CollectionSampleType>>>;
  inverseMonoclonalSampleType?: Maybe<Array<Maybe<LookupSampleType>>>;
  inversePolyclonalSampleType?: Maybe<Array<Maybe<LookupSampleType>>>;
  lookupArrayPlateTypeStrings?: Maybe<Array<Maybe<LookupArrayPlateTypeString>>>;
  lookupSampleCollectionTypes?: Maybe<Array<Maybe<LookupSampleCollectionType>>>;
  productionRunTypes?: Maybe<Array<Maybe<ProductionRunType>>>;
  sampleTypeCollectionTasks?: Maybe<Array<Maybe<SampleTypeCollectionTask>>>;
  samples?: Maybe<Array<Maybe<Sample>>>;
};

export type LookupSampleTypeFilterInput = {
  and?: Maybe<Array<LookupSampleTypeFilterInput>>;
  or?: Maybe<Array<LookupSampleTypeFilterInput>>;
  sampleTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  sampleTypeString?: Maybe<StringOperationFilterInput>;
  sampleTypeAbbreviation?: Maybe<StringOperationFilterInput>;
  isSourceSampleType?: Maybe<BooleanOperationFilterInput>;
  isDerivedSampleType?: Maybe<BooleanOperationFilterInput>;
  isStemCellSampleType?: Maybe<BooleanOperationFilterInput>;
  isCrisprsampleType?: Maybe<BooleanOperationFilterInput>;
  isMonoclonalSampleType?: Maybe<BooleanOperationFilterInput>;
  reprogrammingTier?: Maybe<ComparableNullableOfByteOperationFilterInput>;
  lowStockAlertLimit?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  monoclonalSampleTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  polyclonalSampleTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  appendSampleTypeCode?: Maybe<BooleanOperationFilterInput>;
  appendRun?: Maybe<BooleanOperationFilterInput>;
  monoclonalSampleType?: Maybe<LookupSampleTypeFilterInput>;
  polyclonalSampleType?: Maybe<LookupSampleTypeFilterInput>;
  cellLines?: Maybe<ListFilterInputTypeOfCellLineFilterInput>;
  collectionSampleTypes?: Maybe<ListFilterInputTypeOfCollectionSampleTypeFilterInput>;
  inverseMonoclonalSampleType?: Maybe<ListFilterInputTypeOfLookupSampleTypeFilterInput>;
  inversePolyclonalSampleType?: Maybe<ListFilterInputTypeOfLookupSampleTypeFilterInput>;
  lookupArrayPlateTypeStrings?: Maybe<ListFilterInputTypeOfLookupArrayPlateTypeStringFilterInput>;
  lookupSampleCollectionTypes?: Maybe<ListFilterInputTypeOfLookupSampleCollectionTypeFilterInput>;
  productionRunTypes?: Maybe<ListFilterInputTypeOfProductionRunTypeFilterInput>;
  sampleTypeCollectionTasks?: Maybe<ListFilterInputTypeOfSampleTypeCollectionTaskFilterInput>;
  samples?: Maybe<ListFilterInputTypeOfSampleFilterInput>;
};

export type LookupSampleTypeSortInput = {
  sampleTypeId?: Maybe<SortEnumType>;
  sampleTypeString?: Maybe<SortEnumType>;
  sampleTypeAbbreviation?: Maybe<SortEnumType>;
  isSourceSampleType?: Maybe<SortEnumType>;
  isDerivedSampleType?: Maybe<SortEnumType>;
  isStemCellSampleType?: Maybe<SortEnumType>;
  isCrisprsampleType?: Maybe<SortEnumType>;
  isMonoclonalSampleType?: Maybe<SortEnumType>;
  reprogrammingTier?: Maybe<SortEnumType>;
  lowStockAlertLimit?: Maybe<SortEnumType>;
  monoclonalSampleTypeId?: Maybe<SortEnumType>;
  polyclonalSampleTypeId?: Maybe<SortEnumType>;
  appendSampleTypeCode?: Maybe<SortEnumType>;
  appendRun?: Maybe<SortEnumType>;
  monoclonalSampleType?: Maybe<LookupSampleTypeSortInput>;
  polyclonalSampleType?: Maybe<LookupSampleTypeSortInput>;
};

export type LookupTeamGanttId = {
  __typename?: "LookupTeamGanttId";
  nodeType: TeamGanttType;
  id: Scalars["Int"];
  folderId?: Maybe<Scalars["Int"]>;
  taskId?: Maybe<Scalars["Int"]>;
  teamGanttId?: Maybe<Scalars["String"]>;
  dateCreated: Scalars["DateTime"];
  runId?: Maybe<Scalars["Int"]>;
  folder?: Maybe<FolderStructure>;
  run?: Maybe<Run>;
  task?: Maybe<RunTask>;
};

export type LookupTeamGanttIdFilterInput = {
  and?: Maybe<Array<LookupTeamGanttIdFilterInput>>;
  or?: Maybe<Array<LookupTeamGanttIdFilterInput>>;
  nodeType?: Maybe<TeamGanttTypeOperationFilterInput>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  folderId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  taskId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  teamGanttId?: Maybe<StringOperationFilterInput>;
  dateCreated?: Maybe<ComparableDateTimeOperationFilterInput>;
  runId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  folder?: Maybe<FolderStructureFilterInput>;
  run?: Maybe<RunFilterInput>;
  task?: Maybe<RunTaskFilterInput>;
};

export type LookupTeamGanttIdSortInput = {
  nodeType?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  folderId?: Maybe<SortEnumType>;
  taskId?: Maybe<SortEnumType>;
  teamGanttId?: Maybe<SortEnumType>;
  dateCreated?: Maybe<SortEnumType>;
  runId?: Maybe<SortEnumType>;
  folder?: Maybe<FolderStructureSortInput>;
  run?: Maybe<RunSortInput>;
  task?: Maybe<RunTaskSortInput>;
};

export type LookupTemplateTask = {
  __typename?: "LookupTemplateTask";
  templateTaskId: Scalars["Int"];
  methodId?: Maybe<Scalars["Int"]>;
  methodName?: Maybe<Scalars["String"]>;
  sourcePlateFilter?: Maybe<Scalars["String"]>;
  runActivityTypeId?: Maybe<Scalars["Int"]>;
  averageRunTime?: Maybe<Scalars["Int"]>;
  runActivityType?: Maybe<LookupRunActivityType>;
};

export type LookupTemplateTaskFilterInput = {
  and?: Maybe<Array<LookupTemplateTaskFilterInput>>;
  or?: Maybe<Array<LookupTemplateTaskFilterInput>>;
  templateTaskId?: Maybe<ComparableInt32OperationFilterInput>;
  methodId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  methodName?: Maybe<StringOperationFilterInput>;
  sourcePlateFilter?: Maybe<StringOperationFilterInput>;
  runActivityTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  averageRunTime?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  runActivityType?: Maybe<LookupRunActivityTypeFilterInput>;
};

export type LookupTicketType = {
  __typename?: "LookupTicketType";
  ticketTypeId: Scalars["Int"];
  ticketType?: Maybe<Scalars["String"]>;
  userGroupId: Scalars["Int"];
  userGroup?: Maybe<AppSuiteGroup>;
  tickets?: Maybe<Array<Maybe<Ticket>>>;
};

export type LookupTicketTypeFilterInput = {
  and?: Maybe<Array<LookupTicketTypeFilterInput>>;
  or?: Maybe<Array<LookupTicketTypeFilterInput>>;
  ticketTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  ticketType?: Maybe<StringOperationFilterInput>;
  userGroupId?: Maybe<ComparableInt32OperationFilterInput>;
  userGroup?: Maybe<AppSuiteGroupFilterInput>;
  tickets?: Maybe<ListFilterInputTypeOfTicketFilterInput>;
};

export type LookupTicketTypeSortInput = {
  ticketTypeId?: Maybe<SortEnumType>;
  ticketType?: Maybe<SortEnumType>;
  userGroupId?: Maybe<SortEnumType>;
  userGroup?: Maybe<AppSuiteGroupSortInput>;
};

export type LotNumber = {
  __typename?: "LotNumber";
  lotNumberId: Scalars["Int"];
  lotNumber1?: Maybe<Scalars["String"]>;
  lotNumberTypeId: Scalars["Int"];
  fibroblastLotNumber?: Maybe<Scalars["String"]>;
  spitLotNumber?: Maybe<Scalars["String"]>;
  bloodProcessingLotNumber?: Maybe<Scalars["String"]>;
  reprogrammingLotNumber?: Maybe<Scalars["String"]>;
  consolidationLotNumber?: Maybe<Scalars["String"]>;
  monoclonalizationLotNumber?: Maybe<Scalars["String"]>;
  expansionLotNumber?: Maybe<Scalars["String"]>;
  geneEditingLotNumber?: Maybe<Scalars["String"]>;
  dnaextractionLotNumber?: Maybe<Scalars["String"]>;
  externalLotNumber?: Maybe<Scalars["String"]>;
  otherLotNumber?: Maybe<Scalars["String"]>;
  preFibroblastLotNumber?: Maybe<Scalars["String"]>;
  referenceLotNumber?: Maybe<Scalars["String"]>;
  rpelotNumber?: Maybe<Scalars["String"]>;
  lotNumberType?: Maybe<LookupLotNumberType>;
  containers?: Maybe<Array<Maybe<Container>>>;
  matricesTube?: Maybe<Array<Maybe<MatrixTube>>>;
};

export type LotNumberFilterInput = {
  and?: Maybe<Array<LotNumberFilterInput>>;
  or?: Maybe<Array<LotNumberFilterInput>>;
  lotNumberId?: Maybe<ComparableInt32OperationFilterInput>;
  lotNumber1?: Maybe<StringOperationFilterInput>;
  lotNumberTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  fibroblastLotNumber?: Maybe<StringOperationFilterInput>;
  spitLotNumber?: Maybe<StringOperationFilterInput>;
  bloodProcessingLotNumber?: Maybe<StringOperationFilterInput>;
  reprogrammingLotNumber?: Maybe<StringOperationFilterInput>;
  consolidationLotNumber?: Maybe<StringOperationFilterInput>;
  monoclonalizationLotNumber?: Maybe<StringOperationFilterInput>;
  expansionLotNumber?: Maybe<StringOperationFilterInput>;
  geneEditingLotNumber?: Maybe<StringOperationFilterInput>;
  dnaextractionLotNumber?: Maybe<StringOperationFilterInput>;
  externalLotNumber?: Maybe<StringOperationFilterInput>;
  otherLotNumber?: Maybe<StringOperationFilterInput>;
  preFibroblastLotNumber?: Maybe<StringOperationFilterInput>;
  referenceLotNumber?: Maybe<StringOperationFilterInput>;
  rpelotNumber?: Maybe<StringOperationFilterInput>;
  lotNumberType?: Maybe<LookupLotNumberTypeFilterInput>;
  containers?: Maybe<ListFilterInputTypeOfContainerFilterInput>;
  matricesTube?: Maybe<ListFilterInputTypeOfMatrixTubeFilterInput>;
};

export type LotNumberFormat = {
  __typename?: "LotNumberFormat";
  lotNumberFormatId: Scalars["Int"];
  lotNumberFormat1?: Maybe<Scalars["String"]>;
  includeRow: Scalars["Boolean"];
  includeColumn: Scalars["Boolean"];
  includePlateSeries: Scalars["Boolean"];
  alphabeticalRow: Scalars["Boolean"];
  example?: Maybe<Scalars["String"]>;
  lookupArrayPlateTypeStrings?: Maybe<Array<Maybe<LookupArrayPlateTypeString>>>;
  productionRunTypes?: Maybe<Array<Maybe<ProductionRunType>>>;
};

export type LotNumberFormatFilterInput = {
  and?: Maybe<Array<LotNumberFormatFilterInput>>;
  or?: Maybe<Array<LotNumberFormatFilterInput>>;
  lotNumberFormatId?: Maybe<ComparableInt32OperationFilterInput>;
  lotNumberFormat1?: Maybe<StringOperationFilterInput>;
  includeRow?: Maybe<BooleanOperationFilterInput>;
  includeColumn?: Maybe<BooleanOperationFilterInput>;
  includePlateSeries?: Maybe<BooleanOperationFilterInput>;
  alphabeticalRow?: Maybe<BooleanOperationFilterInput>;
  example?: Maybe<StringOperationFilterInput>;
  lookupArrayPlateTypeStrings?: Maybe<ListFilterInputTypeOfLookupArrayPlateTypeStringFilterInput>;
  productionRunTypes?: Maybe<ListFilterInputTypeOfProductionRunTypeFilterInput>;
};

export type LotNumberFormatSortInput = {
  lotNumberFormatId?: Maybe<SortEnumType>;
  lotNumberFormat1?: Maybe<SortEnumType>;
  includeRow?: Maybe<SortEnumType>;
  includeColumn?: Maybe<SortEnumType>;
  includePlateSeries?: Maybe<SortEnumType>;
  alphabeticalRow?: Maybe<SortEnumType>;
  example?: Maybe<SortEnumType>;
};

export type LotNumberSortInput = {
  lotNumberId?: Maybe<SortEnumType>;
  lotNumber1?: Maybe<SortEnumType>;
  lotNumberTypeId?: Maybe<SortEnumType>;
  fibroblastLotNumber?: Maybe<SortEnumType>;
  spitLotNumber?: Maybe<SortEnumType>;
  bloodProcessingLotNumber?: Maybe<SortEnumType>;
  reprogrammingLotNumber?: Maybe<SortEnumType>;
  consolidationLotNumber?: Maybe<SortEnumType>;
  monoclonalizationLotNumber?: Maybe<SortEnumType>;
  expansionLotNumber?: Maybe<SortEnumType>;
  geneEditingLotNumber?: Maybe<SortEnumType>;
  dnaextractionLotNumber?: Maybe<SortEnumType>;
  externalLotNumber?: Maybe<SortEnumType>;
  otherLotNumber?: Maybe<SortEnumType>;
  preFibroblastLotNumber?: Maybe<SortEnumType>;
  referenceLotNumber?: Maybe<SortEnumType>;
  rpelotNumber?: Maybe<SortEnumType>;
  lotNumberType?: Maybe<LookupLotNumberTypeSortInput>;
};

/** A connection to a list of items. */
export type LotNumbersConnection = {
  __typename?: "LotNumbersConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<LotNumbersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<LotNumber>>>;
};

/** An edge in a connection. */
export type LotNumbersEdge = {
  __typename?: "LotNumbersEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<LotNumber>;
};

export type ManagePersonalTask = {
  __typename?: "ManagePersonalTask";
  taskId: Scalars["Int"];
  taskName?: Maybe<Scalars["String"]>;
  assignedUsers?: Maybe<Array<Scalars["Int"]>>;
  assignedGroups?: Maybe<Array<Scalars["Int"]>>;
  systemAssignedTo?: Maybe<Scalars["Int"]>;
  startTimeScheduled: Scalars["DateTime"];
  endTimeScheduled?: Maybe<Scalars["DateTime"]>;
  priority: TaskPriorityEnum;
  status: TaskStatusEnum;
  notes?: Maybe<Scalars["String"]>;
  taskOwnerId: Scalars["Int"];
};

export type ManagePersonalTaskFilterInput = {
  and?: Maybe<Array<ManagePersonalTaskFilterInput>>;
  or?: Maybe<Array<ManagePersonalTaskFilterInput>>;
  taskId?: Maybe<ComparableInt32OperationFilterInput>;
  taskName?: Maybe<StringOperationFilterInput>;
  assignedUsers?: Maybe<ListComparableInt32OperationFilterInput>;
  assignedGroups?: Maybe<ListComparableInt32OperationFilterInput>;
  systemAssignedTo?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  startTimeScheduled?: Maybe<ComparableDateTimeOperationFilterInput>;
  endTimeScheduled?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  priority?: Maybe<TaskPriorityEnumOperationFilterInput>;
  status?: Maybe<TaskStatusEnumOperationFilterInput>;
  notes?: Maybe<StringOperationFilterInput>;
  taskOwnerId?: Maybe<ComparableInt32OperationFilterInput>;
};

export type ManagePersonalTaskSortInput = {
  taskId?: Maybe<SortEnumType>;
  taskName?: Maybe<SortEnumType>;
  systemAssignedTo?: Maybe<SortEnumType>;
  startTimeScheduled?: Maybe<SortEnumType>;
  endTimeScheduled?: Maybe<SortEnumType>;
  priority?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  taskOwnerId?: Maybe<SortEnumType>;
};

export type ManualTaskType = {
  __typename?: "ManualTaskType";
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  documentationUrl?: Maybe<Scalars["String"]>;
  durationInMinutes?: Maybe<Scalars["Int"]>;
  sourcePlateFilter?: Maybe<Scalars["String"]>;
  automationMethodId?: Maybe<Scalars["Int"]>;
  automationMethod?: Maybe<LookupAutomationMethod>;
  personalTasks?: Maybe<Array<Maybe<PersonalTask>>>;
  runTasks?: Maybe<Array<Maybe<RunTask>>>;
  runTemplateTasks?: Maybe<Array<Maybe<RunTemplateTask>>>;
};

export type ManualTaskTypeFilterInput = {
  and?: Maybe<Array<ManualTaskTypeFilterInput>>;
  or?: Maybe<Array<ManualTaskTypeFilterInput>>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  documentationUrl?: Maybe<StringOperationFilterInput>;
  durationInMinutes?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  sourcePlateFilter?: Maybe<StringOperationFilterInput>;
  automationMethodId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  automationMethod?: Maybe<LookupAutomationMethodFilterInput>;
  personalTasks?: Maybe<ListFilterInputTypeOfPersonalTaskFilterInput>;
  runTasks?: Maybe<ListFilterInputTypeOfRunTaskFilterInput>;
  runTemplateTasks?: Maybe<ListFilterInputTypeOfRunTemplateTaskFilterInput>;
};

export type ManualTaskTypeSortInput = {
  id?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  documentationUrl?: Maybe<SortEnumType>;
  durationInMinutes?: Maybe<SortEnumType>;
  sourcePlateFilter?: Maybe<SortEnumType>;
  automationMethodId?: Maybe<SortEnumType>;
  automationMethod?: Maybe<LookupAutomationMethodSortInput>;
};

export type MatrixTube = {
  __typename?: "MatrixTube";
  matrixTubeId: Scalars["Int"];
  sampleId?: Maybe<Scalars["Int"]>;
  tubeBarcode?: Maybe<Scalars["String"]>;
  stockTypeId?: Maybe<Scalars["Int"]>;
  groupingId?: Maybe<Scalars["Int"]>;
  comment?: Maybe<Scalars["String"]>;
  isActive: Scalars["Boolean"];
  dateCreated?: Maybe<Scalars["DateTime"]>;
  createdBy?: Maybe<Scalars["String"]>;
  dateIdentified?: Maybe<Scalars["DateTime"]>;
  identifiedBy?: Maybe<Scalars["String"]>;
  offSite: Scalars["Boolean"];
  shipped: Scalars["Boolean"];
  reserved: Scalars["Boolean"];
  lotNumberId?: Maybe<Scalars["Int"]>;
  preFibroblastLotNumber?: Maybe<Scalars["String"]>;
  fibroblastLotNumber?: Maybe<Scalars["String"]>;
  spitLotNumber?: Maybe<Scalars["String"]>;
  bloodProcessingLotNumber?: Maybe<Scalars["String"]>;
  reprogrammingLotNumber?: Maybe<Scalars["String"]>;
  consolidationLotNumber?: Maybe<Scalars["String"]>;
  monoclonalizationLotNumber?: Maybe<Scalars["String"]>;
  geneEditingLotNumber?: Maybe<Scalars["String"]>;
  expansionLotNumber?: Maybe<Scalars["String"]>;
  dnaextractionLotNumber?: Maybe<Scalars["String"]>;
  externalLotNumber?: Maybe<Scalars["String"]>;
  otherLotNumber?: Maybe<Scalars["String"]>;
  srcContainerId?: Maybe<Scalars["Int"]>;
  destContainerId?: Maybe<Scalars["Int"]>;
  latestTransactionDate: Scalars["DateTime"];
  cellCount: Scalars["Int"];
  passageNumber: Scalars["Int"];
  lotNumberIdold?: Maybe<Scalars["Int"]>;
  labeledAsAlias?: Maybe<Scalars["Boolean"]>;
  passageNumberOld?: Maybe<Scalars["Int"]>;
  destContainer?: Maybe<Container>;
  lotNumber?: Maybe<LotNumber>;
  sample?: Maybe<Sample>;
  srcContainer?: Maybe<Container>;
};

export type MatrixTubeFilterInput = {
  and?: Maybe<Array<MatrixTubeFilterInput>>;
  or?: Maybe<Array<MatrixTubeFilterInput>>;
  matrixTubeId?: Maybe<ComparableInt32OperationFilterInput>;
  sampleId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  tubeBarcode?: Maybe<StringOperationFilterInput>;
  stockTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  groupingId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  comment?: Maybe<StringOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  dateCreated?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  dateIdentified?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  identifiedBy?: Maybe<StringOperationFilterInput>;
  offSite?: Maybe<BooleanOperationFilterInput>;
  shipped?: Maybe<BooleanOperationFilterInput>;
  reserved?: Maybe<BooleanOperationFilterInput>;
  lotNumberId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  preFibroblastLotNumber?: Maybe<StringOperationFilterInput>;
  fibroblastLotNumber?: Maybe<StringOperationFilterInput>;
  spitLotNumber?: Maybe<StringOperationFilterInput>;
  bloodProcessingLotNumber?: Maybe<StringOperationFilterInput>;
  reprogrammingLotNumber?: Maybe<StringOperationFilterInput>;
  consolidationLotNumber?: Maybe<StringOperationFilterInput>;
  monoclonalizationLotNumber?: Maybe<StringOperationFilterInput>;
  geneEditingLotNumber?: Maybe<StringOperationFilterInput>;
  expansionLotNumber?: Maybe<StringOperationFilterInput>;
  dnaextractionLotNumber?: Maybe<StringOperationFilterInput>;
  externalLotNumber?: Maybe<StringOperationFilterInput>;
  otherLotNumber?: Maybe<StringOperationFilterInput>;
  srcContainerId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  destContainerId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  latestTransactionDate?: Maybe<ComparableDateTimeOperationFilterInput>;
  cellCount?: Maybe<ComparableInt32OperationFilterInput>;
  passageNumber?: Maybe<ComparableInt32OperationFilterInput>;
  lotNumberIdold?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  labeledAsAlias?: Maybe<BooleanOperationFilterInput>;
  passageNumberOld?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  destContainer?: Maybe<ContainerFilterInput>;
  lotNumber?: Maybe<LotNumberFilterInput>;
  sample?: Maybe<SampleFilterInput>;
  srcContainer?: Maybe<ContainerFilterInput>;
};

export type MethodReservation = {
  __typename?: "MethodReservation";
  methodReservationId: Scalars["Int"];
  arraySystemAutomationMethodId?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["Int"]>;
  methodId?: Maybe<Scalars["Int"]>;
  systemId?: Maybe<Scalars["Int"]>;
  sysNum?: Maybe<Scalars["Int"]>;
  date: Scalars["DateTime"];
  picklistId?: Maybe<Scalars["Int"]>;
  isCompleted: Scalars["Boolean"];
  methodVariationId?: Maybe<Scalars["Int"]>;
  dateCreated?: Maybe<Scalars["DateTime"]>;
  generatedFromAppSuite?: Maybe<Scalars["Boolean"]>;
  runActivityId?: Maybe<Scalars["Int"]>;
  dateCompleted?: Maybe<Scalars["DateTime"]>;
  completedByUserId?: Maybe<Scalars["Int"]>;
  personalTaskId?: Maybe<Scalars["Int"]>;
  cherryPickIncludeInactiveWells?: Maybe<Scalars["Boolean"]>;
  completedByUser?: Maybe<AppSuiteUser>;
  method?: Maybe<LookupArrayMethod>;
  personalTask?: Maybe<PersonalTask>;
  runActivity?: Maybe<RunTask>;
  system?: Maybe<LookupArraySystem>;
  user?: Maybe<AppSuiteUser>;
  worklist?: Maybe<Worklist>;
  methodReservationsPlateBarcodes?: Maybe<
    Array<Maybe<MethodReservationsPlateBarcode>>
  >;
};

export type MethodReservationFilterInput = {
  and?: Maybe<Array<MethodReservationFilterInput>>;
  or?: Maybe<Array<MethodReservationFilterInput>>;
  methodReservationId?: Maybe<ComparableInt32OperationFilterInput>;
  arraySystemAutomationMethodId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  userId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  methodId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  systemId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  sysNum?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  date?: Maybe<ComparableDateTimeOperationFilterInput>;
  picklistId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  isCompleted?: Maybe<BooleanOperationFilterInput>;
  methodVariationId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  dateCreated?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  generatedFromAppSuite?: Maybe<BooleanOperationFilterInput>;
  runActivityId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  dateCompleted?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  completedByUserId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  personalTaskId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  cherryPickIncludeInactiveWells?: Maybe<BooleanOperationFilterInput>;
  completedByUser?: Maybe<AppSuiteUserFilterInput>;
  method?: Maybe<LookupArrayMethodFilterInput>;
  personalTask?: Maybe<PersonalTaskFilterInput>;
  runActivity?: Maybe<RunTaskFilterInput>;
  system?: Maybe<LookupArraySystemFilterInput>;
  user?: Maybe<AppSuiteUserFilterInput>;
  worklist?: Maybe<WorklistFilterInput>;
  methodReservationsPlateBarcodes?: Maybe<ListFilterInputTypeOfMethodReservationsPlateBarcodeFilterInput>;
};

export type MethodReservationPlateBarcodePreview = {
  __typename?: "MethodReservationPlateBarcodePreview";
  intermediatePlateBarcode1?: Maybe<Scalars["String"]>;
  intermediatePlateBarcode2?: Maybe<Scalars["String"]>;
  deadPlateBarcode?: Maybe<Scalars["String"]>;
  destinationPlateBarcode?: Maybe<Scalars["String"]>;
  iD?: Maybe<Scalars["Int"]>;
  plateBarcode?: Maybe<Scalars["String"]>;
  plateType?: Maybe<Scalars["String"]>;
};

export type MethodReservationSortInput = {
  methodReservationId?: Maybe<SortEnumType>;
  arraySystemAutomationMethodId?: Maybe<SortEnumType>;
  userId?: Maybe<SortEnumType>;
  methodId?: Maybe<SortEnumType>;
  systemId?: Maybe<SortEnumType>;
  sysNum?: Maybe<SortEnumType>;
  date?: Maybe<SortEnumType>;
  picklistId?: Maybe<SortEnumType>;
  isCompleted?: Maybe<SortEnumType>;
  methodVariationId?: Maybe<SortEnumType>;
  dateCreated?: Maybe<SortEnumType>;
  generatedFromAppSuite?: Maybe<SortEnumType>;
  runActivityId?: Maybe<SortEnumType>;
  dateCompleted?: Maybe<SortEnumType>;
  completedByUserId?: Maybe<SortEnumType>;
  personalTaskId?: Maybe<SortEnumType>;
  cherryPickIncludeInactiveWells?: Maybe<SortEnumType>;
  completedByUser?: Maybe<AppSuiteUserSortInput>;
  method?: Maybe<LookupArrayMethodSortInput>;
  personalTask?: Maybe<PersonalTaskSortInput>;
  runActivity?: Maybe<RunTaskSortInput>;
  system?: Maybe<LookupArraySystemSortInput>;
  user?: Maybe<AppSuiteUserSortInput>;
  worklist?: Maybe<WorklistSortInput>;
};

export type MethodReservationsPlateBarcode = {
  __typename?: "MethodReservationsPlateBarcode";
  plateReservationId: Scalars["Int"];
  methodReservationId: Scalars["Int"];
  plateId?: Maybe<Scalars["Int"]>;
  plateBarcode?: Maybe<Scalars["String"]>;
  plateBarcodeAlias?: Maybe<Scalars["String"]>;
  arrayMethodPlateRoleId?: Maybe<Scalars["Int"]>;
  labwareTypeId?: Maybe<Scalars["Int"]>;
  aspirateVol?: Maybe<Scalars["Int"]>;
  dispenseVol?: Maybe<Scalars["Int"]>;
  labwareType?: Maybe<Scalars["String"]>;
  plateType?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["String"]>;
  isCompleted?: Maybe<Scalars["Boolean"]>;
  arrayMethodPlateRole?: Maybe<LookupArrayMethodPlateRole>;
  labwareTypeNavigation?: Maybe<LookupLabwareType>;
  methodReservation?: Maybe<MethodReservation>;
};

export type MethodReservationsPlateBarcodeFilterInput = {
  and?: Maybe<Array<MethodReservationsPlateBarcodeFilterInput>>;
  or?: Maybe<Array<MethodReservationsPlateBarcodeFilterInput>>;
  plateReservationId?: Maybe<ComparableInt32OperationFilterInput>;
  methodReservationId?: Maybe<ComparableInt32OperationFilterInput>;
  plateId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  plateBarcode?: Maybe<StringOperationFilterInput>;
  plateBarcodeAlias?: Maybe<StringOperationFilterInput>;
  arrayMethodPlateRoleId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  labwareTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  aspirateVol?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  dispenseVol?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  labwareType?: Maybe<StringOperationFilterInput>;
  plateType?: Maybe<StringOperationFilterInput>;
  details?: Maybe<StringOperationFilterInput>;
  isCompleted?: Maybe<BooleanOperationFilterInput>;
  arrayMethodPlateRole?: Maybe<LookupArrayMethodPlateRoleFilterInput>;
  labwareTypeNavigation?: Maybe<LookupLabwareTypeFilterInput>;
  methodReservation?: Maybe<MethodReservationFilterInput>;
};

export type MethodSourcePlateFilter = {
  __typename?: "MethodSourcePlateFilter";
  methodSourcePlateFilterId: Scalars["Int"];
  methodId: Scalars["Int"];
  arrayMethodVariationId?: Maybe<Scalars["Int"]>;
  sourcePlateFilter?: Maybe<Scalars["String"]>;
  maxDaysSincePlated?: Maybe<Scalars["Int"]>;
  maxDaysSinceScanned?: Maybe<Scalars["Int"]>;
  activePlatesOnly: Scalars["Boolean"];
  method?: Maybe<LookupArrayMethod>;
};

export type MethodSourcePlateFilterFilterInput = {
  and?: Maybe<Array<MethodSourcePlateFilterFilterInput>>;
  or?: Maybe<Array<MethodSourcePlateFilterFilterInput>>;
  methodSourcePlateFilterId?: Maybe<ComparableInt32OperationFilterInput>;
  methodId?: Maybe<ComparableInt32OperationFilterInput>;
  arrayMethodVariationId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  sourcePlateFilter?: Maybe<StringOperationFilterInput>;
  maxDaysSincePlated?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  maxDaysSinceScanned?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  activePlatesOnly?: Maybe<BooleanOperationFilterInput>;
  method?: Maybe<LookupArrayMethodFilterInput>;
};

export type Mutation = {
  __typename?: "Mutation";
  reserveFeedWorklist?: Maybe<SystemOneFeedWorklist>;
  deleteTemplateTask: Scalars["Boolean"];
  assignTask: Scalars["Int"];
  createTaskAttachments: Scalars["Int"];
  deleteTaskAttachment: Scalars["Boolean"];
  deleteTasks: Scalars["Boolean"];
  rescheduleTasks: Scalars["Boolean"];
  addPersonalTask?: Maybe<PersonalTask>;
  addRecurringPersonalTask?: Maybe<PersonalTasksRecurringTask>;
  startPersonalTaskTemplate: Scalars["Int"];
  createPersonalTaskTemplate?: Maybe<PersonalTaskTemplate>;
  addPersonalTaskTemplateTask?: Maybe<PersonalTaskTemplate>;
  addRunTemplateTasks?: Maybe<RunTemplateStage>;
  updatePersonalTaskTemplateTask?: Maybe<PersonalTaskTemplateTask>;
  updateRunTemplateTasks?: Maybe<Array<Maybe<RunTemplateTask>>>;
  overwriteRecurringTasks: Scalars["Int"];
  updatePersonalTasks?: Maybe<Array<Maybe<PersonalTask>>>;
  deleteRecurringPersonalTask?: Maybe<PersonalTasksRecurringTask>;
  updateRecurringTask?: Maybe<PersonalTasksRecurringTask>;
  cancelRemainingReccuringTasks?: Maybe<PersonalTasksRecurringTask>;
  updateDashboardTasks: Scalars["Int"];
  updateCollectionTasks?: Maybe<Array<Maybe<CollectionTask>>>;
  createCollectionTask?: Maybe<CollectionTask>;
  addRunTasks?: Maybe<Array<Maybe<RunTask>>>;
  updateRunTasks?: Maybe<Array<Maybe<RunTask>>>;
  saveFeedGroups: Scalars["Boolean"];
  addPersonalFeedTasks: Scalars["Boolean"];
  createTicket?: Maybe<Ticket>;
  mergeTicket?: Maybe<Array<Maybe<Ticket>>>;
  updateTickets?: Maybe<Array<Maybe<Ticket>>>;
  acceptTicket?: Maybe<Ticket>;
  addTicketUpdate?: Maybe<Ticket>;
  addTicketAttachment?: Maybe<Ticket>;
  deleteTicketAttachment?: Maybe<Ticket>;
  createRunTemplate?: Maybe<RunTemplate>;
  addChildRunTemplate?: Maybe<RunTemplate>;
  addRunTemplateStages?: Maybe<RunTemplate>;
  updateRunTemplate?: Maybe<RunTemplate>;
  updateRunTemplateStages?: Maybe<Array<Maybe<RunTemplate>>>;
  updateRunTemplateTaskSchedule?: Maybe<RunTemplateTask>;
  deleteTemplate?: Maybe<Array<Maybe<RunTemplate>>>;
  removeTemplateStage?: Maybe<Array<Maybe<RunTemplate>>>;
  deleteRunTemplateTreeNode?: Maybe<Array<Maybe<RunTemplateStage>>>;
  intermediateDeadAndDestPlateBarcodes?: Maybe<MethodReservationPlateBarcodePreview>;
  setLabwareType?: Maybe<PlatesCreated>;
  plateBarcodePreview?: Maybe<
    Array<Maybe<MethodReservationPlateBarcodePreview>>
  >;
  reserveMethod?: Maybe<MethodReservation>;
  deleteExistingMethodReservation?: Maybe<MethodReservation>;
  saveWorklistToolTemplate?: Maybe<WorklistToolTemplate>;
  overwriteExistingWorklistTemplate?: Maybe<WorklistToolTemplate>;
  deleteWorklistToolTemplate?: Maybe<WorklistToolTemplate>;
  updateUsers?: Maybe<Array<Maybe<AppSuiteUser>>>;
  groupMemberLogin?: Maybe<GroupLoginState>;
  saveSettings?: Maybe<UserClaims>;
  deleteRunActivityMethodReservation?: Maybe<Array<Maybe<RunTask>>>;
  deleteTaskMethodReservation: Scalars["Boolean"];
  reserveCeligoRunTaskMethod?: Maybe<Array<Maybe<RunTask>>>;
  reserveCeligoPersonalTaskMethod?: Maybe<DashboardTask>;
  log?: Maybe<LogResponse>;
  updateIncomingSampleCollections?: Maybe<Collection>;
  createIncomingSampleCollection?: Maybe<Collection>;
  addIdfUpload?: Maybe<UploadIdfFormResponse>;
  validateIdfUpload?: Maybe<Array<Maybe<Scalars["String"]>>>;
  deleteIncomingSampleCollection: Scalars["Boolean"];
  updateIncomingSampleType?: Maybe<Collection>;
  addIncomingSampleType?: Maybe<Collection>;
  deleteCollectionSampleType?: Maybe<Collection>;
  addAsset?: Maybe<LookupAsset>;
  updateMethods?: Maybe<Array<Maybe<LookupArrayMethod>>>;
  updateManualTasks?: Maybe<Array<Maybe<ManualTaskType>>>;
  updateRunTaskTypeDocumentation?: Maybe<RunTaskType>;
  createRunFolder?: Maybe<CreateRunFolderPayload>;
  deleteRunFolder: Scalars["Boolean"];
  createRun?: Maybe<Run>;
  updateRun?: Maybe<Array<Maybe<FolderStructure>>>;
  deleteRun?: Maybe<FolderStructure>;
  createRunGantt?: Maybe<Run>;
};

export type MutationReserveFeedWorklistArgs = {
  plates?: Maybe<SystemOneFeedReservationInfoInput>;
};

export type MutationDeleteTemplateTaskArgs = {
  templateId: Scalars["Int"];
  taskType: TaskTypeEnum;
};

export type MutationAssignTaskArgs = {
  taskInput?: Maybe<AssignTaskInput>;
};

export type MutationCreateTaskAttachmentsArgs = {
  inputs?: Maybe<Array<Maybe<TaskAttachmentInput>>>;
  taskId: Scalars["Int"];
  taskType: TaskTypeEnum;
};

export type MutationDeleteTaskAttachmentArgs = {
  taskAttachmentId: Scalars["Int"];
  taskType: TaskTypeEnum;
};

export type MutationDeleteTasksArgs = {
  taskIDs?: Maybe<Array<Scalars["Int"]>>;
  taskType: TaskTypeEnum;
};

export type MutationRescheduleTasksArgs = {
  models?: Maybe<Array<Maybe<RescheduleTaskInput>>>;
};

export type MutationAddPersonalTaskArgs = {
  input?: Maybe<PersonalTaskInput>;
};

export type MutationAddRecurringPersonalTaskArgs = {
  input?: Maybe<PersonalTaskInput>;
};

export type MutationStartPersonalTaskTemplateArgs = {
  input?: Maybe<StartPersonalTaskTemplateInput>;
};

export type MutationCreatePersonalTaskTemplateArgs = {
  templateName?: Maybe<Scalars["String"]>;
};

export type MutationAddPersonalTaskTemplateTaskArgs = {
  input?: Maybe<PersonalTaskTemplateTaskInput>;
};

export type MutationAddRunTemplateTasksArgs = {
  tasks?: Maybe<Array<Maybe<RunTemplateTaskInput>>>;
};

export type MutationUpdatePersonalTaskTemplateTaskArgs = {
  input?: Maybe<PersonalTaskTemplateTaskInput>;
};

export type MutationUpdateRunTemplateTasksArgs = {
  inputs?: Maybe<Array<Maybe<RunTemplateTaskInput>>>;
  cascadeChanges?: Scalars["Boolean"];
};

export type MutationOverwriteRecurringTasksArgs = {
  input?: Maybe<OverwriteRecurringTaskInput>;
};

export type MutationUpdatePersonalTasksArgs = {
  inputs?: Maybe<Array<Maybe<PersonalTaskInput>>>;
};

export type MutationDeleteRecurringPersonalTaskArgs = {
  recurringTaskId: Scalars["Int"];
};

export type MutationUpdateRecurringTaskArgs = {
  recurringTask?: Maybe<PersonalTasksRecurringTaskInput>;
};

export type MutationCancelRemainingReccuringTasksArgs = {
  recurringTaskId: Scalars["Int"];
};

export type MutationUpdateDashboardTasksArgs = {
  dashboardTasks?: Maybe<Array<Maybe<UpdateDashboardTaskInput>>>;
};

export type MutationUpdateCollectionTasksArgs = {
  inputs?: Maybe<Array<Maybe<CollectionTaskInput>>>;
};

export type MutationCreateCollectionTaskArgs = {
  collectionTaskInput?: Maybe<CollectionTaskInput>;
};

export type MutationAddRunTasksArgs = {
  tasks?: Maybe<Array<Maybe<RunTaskInput>>>;
};

export type MutationUpdateRunTasksArgs = {
  updateInputs?: Maybe<Array<Maybe<RunTaskInput>>>;
};

export type MutationSaveFeedGroupsArgs = {
  inputs?: Maybe<Array<Maybe<FeedGroupInput>>>;
};

export type MutationAddPersonalFeedTasksArgs = {
  inputs?: Maybe<Array<Maybe<FeedGroupInput>>>;
};

export type MutationCreateTicketArgs = {
  input?: Maybe<CreateTicketInput>;
};

export type MutationMergeTicketArgs = {
  mergeInto: Scalars["Int"];
  ticketToMerge: Scalars["Int"];
};

export type MutationUpdateTicketsArgs = {
  inputs?: Maybe<Array<Maybe<UpdateInputOfUpdateTicketInputAndTicketInput>>>;
};

export type MutationAcceptTicketArgs = {
  ticketId: Scalars["Int"];
};

export type MutationAddTicketUpdateArgs = {
  input?: Maybe<CreateTicketUpdateInput>;
};

export type MutationAddTicketAttachmentArgs = {
  inputs?: Maybe<Array<Maybe<CreateTicketAttachmentInput>>>;
  ticketId: Scalars["Int"];
};

export type MutationDeleteTicketAttachmentArgs = {
  input?: Maybe<DeleteInput>;
};

export type MutationCreateRunTemplateArgs = {
  createRunTemplate?: Maybe<CreateRunTemplateInput>;
};

export type MutationAddChildRunTemplateArgs = {
  input?: Maybe<CreateRunTemplateTreeNodeInput>;
};

export type MutationAddRunTemplateStagesArgs = {
  stages?: Maybe<Array<Maybe<CreateRunTemplateStageInput>>>;
};

export type MutationUpdateRunTemplateArgs = {
  updateInput?: Maybe<UpdateInputOfUpdateRunTemplateInputAndRunTemplateInput>;
};

export type MutationUpdateRunTemplateStagesArgs = {
  updateInputs?: Maybe<
    Array<
      Maybe<UpdateInputOfUpdateRunTemplateStageInputAndRunTemplateStageInput>
    >
  >;
};

export type MutationUpdateRunTemplateTaskScheduleArgs = {
  runTemplateTaskId: Scalars["Int"];
  schedule?: Maybe<Array<Maybe<RunTemplatesRepeatDaysOfWeekInput>>>;
};

export type MutationDeleteTemplateArgs = {
  deleteInput?: Maybe<DeleteInput>;
};

export type MutationRemoveTemplateStageArgs = {
  deleteInput?: Maybe<DeleteInput>;
};

export type MutationDeleteRunTemplateTreeNodeArgs = {
  deleteInput?: Maybe<DeleteInput>;
};

export type MutationIntermediateDeadAndDestPlateBarcodesArgs = {
  sourcePlateBarcode?: Maybe<Scalars["String"]>;
  destPlateCode?: Maybe<Scalars["String"]>;
  hasMultipleSourcePlateTypes: Scalars["Boolean"];
  hasMultipleDestPlateTypes: Scalars["Boolean"];
  has384DeadPlate: Scalars["Boolean"];
};

export type MutationSetLabwareTypeArgs = {
  plateId: Scalars["Int"];
  labwareTypeId: Scalars["Int"];
};

export type MutationPlateBarcodePreviewArgs = {
  methodId: Scalars["Int"];
  sourcePlateBarcodes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  destPlateCode?: Maybe<Scalars["String"]>;
};

export type MutationReserveMethodArgs = {
  input?: Maybe<WorklistToolMethodReservationInput>;
};

export type MutationDeleteExistingMethodReservationArgs = {
  methodReservationId: Scalars["Int"];
};

export type MutationSaveWorklistToolTemplateArgs = {
  input?: Maybe<SaveWorklistTemplateInput>;
};

export type MutationOverwriteExistingWorklistTemplateArgs = {
  input?: Maybe<SaveWorklistTemplateInput>;
};

export type MutationDeleteWorklistToolTemplateArgs = {
  worklistToolTemplateId: Scalars["Int"];
};

export type MutationUpdateUsersArgs = {
  inputs?: Maybe<
    Array<Maybe<UpdateInputOfUpdateAppSuiteUserInputAndAppSuiteUserInput>>
  >;
};

export type MutationGroupMemberLoginArgs = {
  currentComputerUser?: Maybe<CurrentComputerUserInput>;
};

export type MutationSaveSettingsArgs = {
  settingsJson?: Maybe<Scalars["String"]>;
};

export type MutationDeleteRunActivityMethodReservationArgs = {
  deleteInput?: Maybe<DeleteInput>;
};

export type MutationDeleteTaskMethodReservationArgs = {
  deleteInput?: Maybe<DeleteInput>;
};

export type MutationReserveCeligoRunTaskMethodArgs = {
  input?: Maybe<RunTaskInput>;
};

export type MutationReserveCeligoPersonalTaskMethodArgs = {
  task?: Maybe<DashboardTaskInput>;
};

export type MutationLogArgs = {
  log?: Maybe<LogInput>;
};

export type MutationUpdateIncomingSampleCollectionsArgs = {
  inputs?: Maybe<
    Array<
      Maybe<UpdateInputOfUpdateIncomingSampleCollectionInputAndCollectionInput>
    >
  >;
};

export type MutationCreateIncomingSampleCollectionArgs = {
  input?: Maybe<CreateIncomingSampleCollectionInput>;
};

export type MutationAddIdfUploadArgs = {
  uploadIdfFormInput?: Maybe<UploadIdfFormInput>;
};

export type MutationValidateIdfUploadArgs = {
  uploadIdfFormInput?: Maybe<UploadIdfFormInput>;
};

export type MutationDeleteIncomingSampleCollectionArgs = {
  input?: Maybe<DeleteInput>;
};

export type MutationUpdateIncomingSampleTypeArgs = {
  input?: Maybe<UpdateInputOfUpdateIncomingSampleTypeInputAndCollectionSampleTypeInput>;
};

export type MutationAddIncomingSampleTypeArgs = {
  input?: Maybe<CreateIncomingSampleTypeInput>;
};

export type MutationDeleteCollectionSampleTypeArgs = {
  input?: Maybe<DeleteInput>;
};

export type MutationAddAssetArgs = {
  input?: Maybe<AssetInput>;
};

export type MutationUpdateMethodsArgs = {
  inputs?: Maybe<
    Array<Maybe<UpdateInputOfUpdateMethodInputAndLookupArrayMethodInput>>
  >;
};

export type MutationUpdateManualTasksArgs = {
  inputs?: Maybe<
    Array<Maybe<UpdateInputOfUpdateManualTaskInputAndManualTaskTypeInput>>
  >;
};

export type MutationUpdateRunTaskTypeDocumentationArgs = {
  input?: Maybe<UpdateMethodDocumentationInput>;
};

export type MutationCreateRunFolderArgs = {
  input?: Maybe<CreateRunFolderInput>;
};

export type MutationDeleteRunFolderArgs = {
  input?: Maybe<DeleteInput>;
};

export type MutationCreateRunArgs = {
  runInput?: Maybe<CreateRunInput>;
};

export type MutationUpdateRunArgs = {
  input?: Maybe<UpdateInputOfUpdateRunInputAndRunInput>;
};

export type MutationDeleteRunArgs = {
  input?: Maybe<DeleteInput>;
};

export type MutationCreateRunGanttArgs = {
  runId: Scalars["Int"];
};

/** A connection to a list of items. */
export type MyPersonalTasksConnection = {
  __typename?: "MyPersonalTasksConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<MyPersonalTasksEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<ManagePersonalTask>>>;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type MyPersonalTasksEdge = {
  __typename?: "MyPersonalTasksEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<ManagePersonalTask>;
};

export enum NodeType {
  Folder = "FOLDER",
  Run = "RUN",
  Stage = "STAGE",
}

export type NodeTypeOperationFilterInput = {
  eq?: Maybe<NodeType>;
  neq?: Maybe<NodeType>;
  in?: Maybe<Array<NodeType>>;
  nin?: Maybe<Array<NodeType>>;
};

export type NormalizedPassageTemplate = {
  __typename?: "NormalizedPassageTemplate";
  normalizedPassageTemplateId: Scalars["Int"];
  templateName?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Scalars["Int"]>;
  updatedBy?: Maybe<Scalars["Int"]>;
  updatedByTime?: Maybe<Scalars["DateTime"]>;
  createdByNavigation?: Maybe<AppSuiteUser>;
  normalizedPassageTemplateDetails?: Maybe<
    Array<Maybe<NormalizedPassageTemplateDetail>>
  >;
  normalizedPassageTemplateMethodSettings?: Maybe<
    Array<Maybe<NormalizedPassageTemplateMethodSetting>>
  >;
  normalizedPassageTemplateStampDetails?: Maybe<
    Array<Maybe<NormalizedPassageTemplateStampDetail>>
  >;
  normalizedPassageTemplatesStampTopLefts?: Maybe<
    Array<Maybe<NormalizedPassageTemplatesStampTopLeft>>
  >;
};

export type NormalizedPassageTemplateDetail = {
  __typename?: "NormalizedPassageTemplateDetail";
  normalizedPassageTemplateDetailsId: Scalars["Int"];
  normalizedPassageTemplateId: Scalars["Int"];
  task?: Maybe<Scalars["String"]>;
  sourcePlateId: Scalars["Int"];
  sourcePlateWellPosition?: Maybe<Scalars["String"]>;
  destinationPlateId: Scalars["Int"];
  destinationPlateWellPosition?: Maybe<Scalars["String"]>;
  cellNumber?: Maybe<Scalars["Int"]>;
  sourcePlateType?: Maybe<Scalars["String"]>;
  destinationPlateType?: Maybe<Scalars["String"]>;
  normalizedPassageTemplate?: Maybe<NormalizedPassageTemplate>;
};

export type NormalizedPassageTemplateDetailFilterInput = {
  and?: Maybe<Array<NormalizedPassageTemplateDetailFilterInput>>;
  or?: Maybe<Array<NormalizedPassageTemplateDetailFilterInput>>;
  normalizedPassageTemplateDetailsId?: Maybe<ComparableInt32OperationFilterInput>;
  normalizedPassageTemplateId?: Maybe<ComparableInt32OperationFilterInput>;
  task?: Maybe<StringOperationFilterInput>;
  sourcePlateId?: Maybe<ComparableInt32OperationFilterInput>;
  sourcePlateWellPosition?: Maybe<StringOperationFilterInput>;
  destinationPlateId?: Maybe<ComparableInt32OperationFilterInput>;
  destinationPlateWellPosition?: Maybe<StringOperationFilterInput>;
  cellNumber?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  sourcePlateType?: Maybe<StringOperationFilterInput>;
  destinationPlateType?: Maybe<StringOperationFilterInput>;
  normalizedPassageTemplate?: Maybe<NormalizedPassageTemplateFilterInput>;
};

export type NormalizedPassageTemplateFilterInput = {
  and?: Maybe<Array<NormalizedPassageTemplateFilterInput>>;
  or?: Maybe<Array<NormalizedPassageTemplateFilterInput>>;
  normalizedPassageTemplateId?: Maybe<ComparableInt32OperationFilterInput>;
  templateName?: Maybe<StringOperationFilterInput>;
  createdBy?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  updatedBy?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  updatedByTime?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  createdByNavigation?: Maybe<AppSuiteUserFilterInput>;
  normalizedPassageTemplateDetails?: Maybe<ListFilterInputTypeOfNormalizedPassageTemplateDetailFilterInput>;
  normalizedPassageTemplateMethodSettings?: Maybe<ListFilterInputTypeOfNormalizedPassageTemplateMethodSettingFilterInput>;
  normalizedPassageTemplateStampDetails?: Maybe<ListFilterInputTypeOfNormalizedPassageTemplateStampDetailFilterInput>;
  normalizedPassageTemplatesStampTopLefts?: Maybe<ListFilterInputTypeOfNormalizedPassageTemplatesStampTopLeftFilterInput>;
};

export type NormalizedPassageTemplateMethodSetting = {
  __typename?: "NormalizedPassageTemplateMethodSetting";
  normalizedPassageTemplateMethodSettingsId: Scalars["Int"];
  normalizedPassageTemplateId: Scalars["Int"];
  selectedSystem?: Maybe<Scalars["Int"]>;
  isStamp96?: Maybe<Scalars["Boolean"]>;
  cellType?: Maybe<Scalars["String"]>;
  dissociationTime?: Maybe<Scalars["Int"]>;
  spinParameters?: Maybe<Scalars["Int"]>;
  spinTime?: Maybe<Scalars["Int"]>;
  spinAcceleration?: Maybe<Scalars["Int"]>;
  spinDeceleration?: Maybe<Scalars["Int"]>;
  pelletResuspensionVolume?: Maybe<Scalars["Int"]>;
  dissociationWashReagent?: Maybe<Scalars["String"]>;
  reFeedWells?: Maybe<Scalars["Boolean"]>;
  reFeedWellsReagent?: Maybe<Scalars["String"]>;
  dissociation?: Maybe<Scalars["Boolean"]>;
  washBeforeDissociation?: Maybe<Scalars["Boolean"]>;
  washAfterDissociation?: Maybe<Scalars["Boolean"]>;
  preprocessPlate?: Maybe<Scalars["Boolean"]>;
  stampColumns?: Maybe<Scalars["Int"]>;
  stampRows?: Maybe<Scalars["Int"]>;
  stampVolume?: Maybe<Scalars["Int"]>;
  normalizedPassageTemplate?: Maybe<NormalizedPassageTemplate>;
};

export type NormalizedPassageTemplateMethodSettingFilterInput = {
  and?: Maybe<Array<NormalizedPassageTemplateMethodSettingFilterInput>>;
  or?: Maybe<Array<NormalizedPassageTemplateMethodSettingFilterInput>>;
  normalizedPassageTemplateMethodSettingsId?: Maybe<ComparableInt32OperationFilterInput>;
  normalizedPassageTemplateId?: Maybe<ComparableInt32OperationFilterInput>;
  selectedSystem?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  isStamp96?: Maybe<BooleanOperationFilterInput>;
  cellType?: Maybe<StringOperationFilterInput>;
  dissociationTime?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  spinParameters?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  spinTime?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  spinAcceleration?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  spinDeceleration?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  pelletResuspensionVolume?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  dissociationWashReagent?: Maybe<StringOperationFilterInput>;
  reFeedWells?: Maybe<BooleanOperationFilterInput>;
  reFeedWellsReagent?: Maybe<StringOperationFilterInput>;
  dissociation?: Maybe<BooleanOperationFilterInput>;
  washBeforeDissociation?: Maybe<BooleanOperationFilterInput>;
  washAfterDissociation?: Maybe<BooleanOperationFilterInput>;
  preprocessPlate?: Maybe<BooleanOperationFilterInput>;
  stampColumns?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  stampRows?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  stampVolume?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  normalizedPassageTemplate?: Maybe<NormalizedPassageTemplateFilterInput>;
};

export type NormalizedPassageTemplateStampDetail = {
  __typename?: "NormalizedPassageTemplateStampDetail";
  normalizedPassageTemplateStampId: Scalars["Int"];
  normalizedPassageTemplateId: Scalars["Int"];
  sourcePlateLocation: Scalars["Int"];
  sourceWellId?: Maybe<Scalars["String"]>;
  normalizedPassageTemplate?: Maybe<NormalizedPassageTemplate>;
};

export type NormalizedPassageTemplateStampDetailFilterInput = {
  and?: Maybe<Array<NormalizedPassageTemplateStampDetailFilterInput>>;
  or?: Maybe<Array<NormalizedPassageTemplateStampDetailFilterInput>>;
  normalizedPassageTemplateStampId?: Maybe<ComparableInt32OperationFilterInput>;
  normalizedPassageTemplateId?: Maybe<ComparableInt32OperationFilterInput>;
  sourcePlateLocation?: Maybe<ComparableInt32OperationFilterInput>;
  sourceWellId?: Maybe<StringOperationFilterInput>;
  normalizedPassageTemplate?: Maybe<NormalizedPassageTemplateFilterInput>;
};

export type NormalizedPassageTemplatesStampTopLeft = {
  __typename?: "NormalizedPassageTemplatesStampTopLeft";
  stampTopLeftId: Scalars["Int"];
  normalizedPassageTemplateId: Scalars["Int"];
  stampTopLeftPlateLocation: Scalars["Int"];
  stampTopLeftWellLocation?: Maybe<Scalars["String"]>;
  normalizedPassageTemplate?: Maybe<NormalizedPassageTemplate>;
};

export type NormalizedPassageTemplatesStampTopLeftFilterInput = {
  and?: Maybe<Array<NormalizedPassageTemplatesStampTopLeftFilterInput>>;
  or?: Maybe<Array<NormalizedPassageTemplatesStampTopLeftFilterInput>>;
  stampTopLeftId?: Maybe<ComparableInt32OperationFilterInput>;
  normalizedPassageTemplateId?: Maybe<ComparableInt32OperationFilterInput>;
  stampTopLeftPlateLocation?: Maybe<ComparableInt32OperationFilterInput>;
  stampTopLeftWellLocation?: Maybe<StringOperationFilterInput>;
  normalizedPassageTemplate?: Maybe<NormalizedPassageTemplateFilterInput>;
};

export type NullableOfDayOfWeekOperationFilterInput = {
  eq?: Maybe<DayOfWeek>;
  neq?: Maybe<DayOfWeek>;
  in?: Maybe<Array<Maybe<DayOfWeek>>>;
  nin?: Maybe<Array<Maybe<DayOfWeek>>>;
};

export type NullableOfImageExportFormatOperationFilterInput = {
  eq?: Maybe<ImageExportFormat>;
  neq?: Maybe<ImageExportFormat>;
  in?: Maybe<Array<Maybe<ImageExportFormat>>>;
  nin?: Maybe<Array<Maybe<ImageExportFormat>>>;
};

export type OverwriteRecurringTaskInput = {
  recurringTaskId: Scalars["Int"];
  selectedUsers?: Maybe<Array<Scalars["Int"]>>;
  selectedGroups?: Maybe<Array<Scalars["Int"]>>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars["Boolean"];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
};

export type PersonalTask = {
  __typename?: "PersonalTask";
  taskType: TaskTypeEnum;
  taskId: Scalars["Int"];
  taskName?: Maybe<Scalars["String"]>;
  methodId?: Maybe<Scalars["Int"]>;
  taskOwnerId?: Maybe<Scalars["Int"]>;
  systemAssignedTo?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<Scalars["Int"]>;
  updateActionDetails?: Maybe<Scalars["String"]>;
  lastTimeUpdated?: Maybe<Scalars["DateTime"]>;
  completedBy?: Maybe<Scalars["Int"]>;
  completedByTime?: Maybe<Scalars["DateTime"]>;
  recurringTaskId?: Maybe<Scalars["Int"]>;
  manualTaskTypeId?: Maybe<Scalars["Int"]>;
  startTimeScheduled: Scalars["DateTime"];
  endTimeScheduled: Scalars["DateTime"];
  startTimeActual?: Maybe<Scalars["DateTime"]>;
  endTimeActual?: Maybe<Scalars["DateTime"]>;
  feedGroupId?: Maybe<Scalars["Int"]>;
  completedByNavigation?: Maybe<AppSuiteUser>;
  feedGroup?: Maybe<FeedGroup>;
  manualTaskType?: Maybe<ManualTaskType>;
  method?: Maybe<LookupArrayMethod>;
  recurringTask?: Maybe<PersonalTasksRecurringTask>;
  systemAssignedToNavigation?: Maybe<LookupArraySystem>;
  taskOwner?: Maybe<AppSuiteUser>;
  methodReservations?: Maybe<Array<Maybe<MethodReservation>>>;
  personalTaskAttachments?: Maybe<Array<Maybe<PersonalTaskAttachment>>>;
  groups?: Maybe<Array<Maybe<AppSuiteGroup>>>;
  users?: Maybe<Array<Maybe<AppSuiteUser>>>;
  priority?: Maybe<TaskPriorityEnum>;
  status?: Maybe<TaskStatusEnum>;
};

export type PersonalTaskAttachment = {
  __typename?: "PersonalTaskAttachment";
  personalTaskAttachmentId: Scalars["Int"];
  personalTaskId: Scalars["Int"];
  attachmentName?: Maybe<Scalars["String"]>;
  dateUploaded: Scalars["DateTime"];
  uploadedBy: Scalars["Int"];
  comments?: Maybe<Scalars["String"]>;
  personalTask?: Maybe<PersonalTask>;
  attachmentUrl?: Maybe<Scalars["String"]>;
};

export type PersonalTaskAttachmentFilterInput = {
  and?: Maybe<Array<PersonalTaskAttachmentFilterInput>>;
  or?: Maybe<Array<PersonalTaskAttachmentFilterInput>>;
  personalTaskAttachmentId?: Maybe<ComparableInt32OperationFilterInput>;
  personalTaskId?: Maybe<ComparableInt32OperationFilterInput>;
  attachmentName?: Maybe<StringOperationFilterInput>;
  attachmentFile?: Maybe<ListComparableByteOperationFilterInput>;
  dateUploaded?: Maybe<ComparableDateTimeOperationFilterInput>;
  uploadedBy?: Maybe<ComparableInt32OperationFilterInput>;
  comments?: Maybe<StringOperationFilterInput>;
  personalTask?: Maybe<PersonalTaskFilterInput>;
};

export type PersonalTaskFilterInput = {
  and?: Maybe<Array<PersonalTaskFilterInput>>;
  or?: Maybe<Array<PersonalTaskFilterInput>>;
  taskType?: Maybe<TaskTypeEnumOperationFilterInput>;
  status?: Maybe<TaskStatusEnumOperationFilterInput>;
  priority?: Maybe<TaskPriorityEnumOperationFilterInput>;
  taskId?: Maybe<ComparableInt32OperationFilterInput>;
  taskName?: Maybe<StringOperationFilterInput>;
  methodId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  taskOwnerId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  systemAssignedTo?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  notes?: Maybe<StringOperationFilterInput>;
  updatedBy?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  updateActionDetails?: Maybe<StringOperationFilterInput>;
  lastTimeUpdated?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  completedBy?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  completedByTime?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  recurringTaskId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  manualTaskTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  startTimeScheduled?: Maybe<ComparableDateTimeOperationFilterInput>;
  endTimeScheduled?: Maybe<ComparableDateTimeOperationFilterInput>;
  startTimeActual?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  endTimeActual?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  feedGroupId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  completedByNavigation?: Maybe<AppSuiteUserFilterInput>;
  feedGroup?: Maybe<FeedGroupFilterInput>;
  manualTaskType?: Maybe<ManualTaskTypeFilterInput>;
  method?: Maybe<LookupArrayMethodFilterInput>;
  recurringTask?: Maybe<PersonalTasksRecurringTaskFilterInput>;
  systemAssignedToNavigation?: Maybe<LookupArraySystemFilterInput>;
  taskOwner?: Maybe<AppSuiteUserFilterInput>;
  methodReservations?: Maybe<ListFilterInputTypeOfMethodReservationFilterInput>;
  personalTaskAttachments?: Maybe<ListFilterInputTypeOfPersonalTaskAttachmentFilterInput>;
  groups?: Maybe<ListFilterInputTypeOfAppSuiteGroupFilterInput>;
  users?: Maybe<ListFilterInputTypeOfAppSuiteUserFilterInput>;
};

export type PersonalTaskInput = {
  taskTypeSelection: Scalars["Int"];
  methodTaskName?: Maybe<Scalars["String"]>;
  methodId?: Maybe<Scalars["Int"]>;
  manualTaskTypeId?: Maybe<Scalars["Int"]>;
  taskId?: Maybe<Scalars["Int"]>;
  taskName?: Maybe<Scalars["String"]>;
  assignedUsers?: Maybe<Array<Scalars["Int"]>>;
  assignedGroups?: Maybe<Array<Scalars["Int"]>>;
  systemAssignedTo?: Maybe<Scalars["Int"]>;
  startTimeScheduled: Scalars["DateTime"];
  endTimeScheduled?: Maybe<Scalars["DateTime"]>;
  dateSelection: Scalars["Int"];
  repeatDaysOfWeek?: Maybe<Array<DayOfWeek>>;
  endRepeatDate: Scalars["DateTime"];
  selectedDates?: Maybe<Array<Scalars["DateTime"]>>;
  priority: TaskPriorityEnum;
  status: TaskStatusEnum;
  notes?: Maybe<Scalars["String"]>;
  taskOwnerId?: Maybe<Scalars["Int"]>;
  personalTaskAttachments?: Maybe<Array<TaskAttachmentInput>>;
  completedByTime?: Maybe<Scalars["DateTime"]>;
  lastTimeUpdated?: Maybe<Scalars["DateTime"]>;
  completedBy?: Maybe<Scalars["Int"]>;
  groupAssignedTo?: Maybe<Scalars["Int"]>;
  recurringTaskId?: Maybe<Scalars["Int"]>;
  updatedBy?: Maybe<Scalars["Int"]>;
  userAssignedTo?: Maybe<Scalars["Int"]>;
  updateActionDetails?: Maybe<Scalars["String"]>;
};

export type PersonalTaskSortInput = {
  taskType?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  priority?: Maybe<SortEnumType>;
  taskId?: Maybe<SortEnumType>;
  taskName?: Maybe<SortEnumType>;
  methodId?: Maybe<SortEnumType>;
  taskOwnerId?: Maybe<SortEnumType>;
  systemAssignedTo?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  updatedBy?: Maybe<SortEnumType>;
  updateActionDetails?: Maybe<SortEnumType>;
  lastTimeUpdated?: Maybe<SortEnumType>;
  completedBy?: Maybe<SortEnumType>;
  completedByTime?: Maybe<SortEnumType>;
  recurringTaskId?: Maybe<SortEnumType>;
  manualTaskTypeId?: Maybe<SortEnumType>;
  startTimeScheduled?: Maybe<SortEnumType>;
  endTimeScheduled?: Maybe<SortEnumType>;
  startTimeActual?: Maybe<SortEnumType>;
  endTimeActual?: Maybe<SortEnumType>;
  feedGroupId?: Maybe<SortEnumType>;
  completedByNavigation?: Maybe<AppSuiteUserSortInput>;
  feedGroup?: Maybe<FeedGroupSortInput>;
  manualTaskType?: Maybe<ManualTaskTypeSortInput>;
  method?: Maybe<LookupArrayMethodSortInput>;
  recurringTask?: Maybe<PersonalTasksRecurringTaskSortInput>;
  systemAssignedToNavigation?: Maybe<LookupArraySystemSortInput>;
  taskOwner?: Maybe<AppSuiteUserSortInput>;
};

export type PersonalTaskTemplate = {
  __typename?: "PersonalTaskTemplate";
  personalTaskTemplateId: Scalars["Int"];
  personalTaskTemplateName?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Scalars["Int"]>;
  updatedBy?: Maybe<Scalars["Int"]>;
  updatedByTime?: Maybe<Scalars["DateTime"]>;
  createdByNavigation?: Maybe<AppSuiteUser>;
  personalTaskTemplateTasks?: Maybe<Array<Maybe<PersonalTaskTemplateTask>>>;
};

export type PersonalTaskTemplateFilterInput = {
  and?: Maybe<Array<PersonalTaskTemplateFilterInput>>;
  or?: Maybe<Array<PersonalTaskTemplateFilterInput>>;
  personalTaskTemplateId?: Maybe<ComparableInt32OperationFilterInput>;
  personalTaskTemplateName?: Maybe<StringOperationFilterInput>;
  createdBy?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  updatedBy?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  updatedByTime?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  createdByNavigation?: Maybe<AppSuiteUserFilterInput>;
  personalTaskTemplateTasks?: Maybe<ListFilterInputTypeOfPersonalTaskTemplateTaskFilterInput>;
};

export type PersonalTaskTemplateSortInput = {
  personalTaskTemplateId?: Maybe<SortEnumType>;
  personalTaskTemplateName?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  updatedBy?: Maybe<SortEnumType>;
  updatedByTime?: Maybe<SortEnumType>;
  createdByNavigation?: Maybe<AppSuiteUserSortInput>;
};

export type PersonalTaskTemplateTask = {
  __typename?: "PersonalTaskTemplateTask";
  priority: TaskPriorityEnum;
  personalTaskTemplateTaskId: Scalars["Int"];
  personalTaskTemplateId?: Maybe<Scalars["Int"]>;
  personalTaskName?: Maybe<Scalars["String"]>;
  dayScheduled?: Maybe<Scalars["Int"]>;
  userAssignedTo?: Maybe<Scalars["Int"]>;
  groupAssignedTo?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  assignedSystem?: Maybe<Scalars["Int"]>;
  groupAssignedToNavigation?: Maybe<AppSuiteGroup>;
  personalTaskTemplate?: Maybe<PersonalTaskTemplate>;
  userAssignedToNavigation?: Maybe<AppSuiteUser>;
  groups?: Maybe<Array<Maybe<AppSuiteGroup>>>;
  users?: Maybe<Array<Maybe<AppSuiteUser>>>;
};

export type PersonalTaskTemplateTaskFilterInput = {
  and?: Maybe<Array<PersonalTaskTemplateTaskFilterInput>>;
  or?: Maybe<Array<PersonalTaskTemplateTaskFilterInput>>;
  priority?: Maybe<TaskPriorityEnumOperationFilterInput>;
  personalTaskTemplateTaskId?: Maybe<ComparableInt32OperationFilterInput>;
  personalTaskTemplateId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  personalTaskName?: Maybe<StringOperationFilterInput>;
  dayScheduled?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  userAssignedTo?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  groupAssignedTo?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  notes?: Maybe<StringOperationFilterInput>;
  assignedSystem?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  groupAssignedToNavigation?: Maybe<AppSuiteGroupFilterInput>;
  personalTaskTemplate?: Maybe<PersonalTaskTemplateFilterInput>;
  userAssignedToNavigation?: Maybe<AppSuiteUserFilterInput>;
  groups?: Maybe<ListFilterInputTypeOfAppSuiteGroupFilterInput>;
  users?: Maybe<ListFilterInputTypeOfAppSuiteUserFilterInput>;
};

export type PersonalTaskTemplateTaskInput = {
  personalTaskTemplateId?: Maybe<Scalars["Int"]>;
  personalTaskTemplateTaskId?: Maybe<Scalars["Int"]>;
  personalTaskName?: Maybe<Scalars["String"]>;
  dayScheduled: Scalars["Int"];
  assignedUsers?: Maybe<Array<Scalars["Int"]>>;
  assignedGroups?: Maybe<Array<Scalars["Int"]>>;
  assignedSystem: Scalars["Int"];
  priority: TaskPriorityEnum;
  notes?: Maybe<Scalars["String"]>;
};

/** A connection to a list of items. */
export type PersonalTaskTemplatesConnection = {
  __typename?: "PersonalTaskTemplatesConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PersonalTaskTemplatesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<PersonalTaskTemplate>>>;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type PersonalTaskTemplatesEdge = {
  __typename?: "PersonalTaskTemplatesEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<PersonalTaskTemplate>;
};

export type PersonalTasksRecurringTask = {
  __typename?: "PersonalTasksRecurringTask";
  recurringTaskId: Scalars["Int"];
  taskName?: Maybe<Scalars["String"]>;
  repeatingDaysOfWeek?: Maybe<Scalars["String"]>;
  endRepeatDate?: Maybe<Scalars["DateTime"]>;
  personalTasks?: Maybe<Array<Maybe<PersonalTask>>>;
};

export type PersonalTasksRecurringTaskFilterInput = {
  and?: Maybe<Array<PersonalTasksRecurringTaskFilterInput>>;
  or?: Maybe<Array<PersonalTasksRecurringTaskFilterInput>>;
  recurringTaskId?: Maybe<ComparableInt32OperationFilterInput>;
  taskName?: Maybe<StringOperationFilterInput>;
  repeatingDaysOfWeek?: Maybe<StringOperationFilterInput>;
  endRepeatDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  personalTasks?: Maybe<ListFilterInputTypeOfPersonalTaskFilterInput>;
};

export type PersonalTasksRecurringTaskInput = {
  recurringTaskId: Scalars["Int"];
  repeatingDaysOfWeek?: Maybe<Array<Maybe<Scalars["String"]>>>;
  endRepeatDate?: Maybe<Scalars["DateTime"]>;
};

export type PersonalTasksRecurringTaskSortInput = {
  recurringTaskId?: Maybe<SortEnumType>;
  taskName?: Maybe<SortEnumType>;
  repeatingDaysOfWeek?: Maybe<SortEnumType>;
  endRepeatDate?: Maybe<SortEnumType>;
};

export type PlateInfoInput = {
  plateType?: Maybe<Scalars["String"]>;
  plateIndex: Scalars["Int"];
  labwareTypeCode?: Maybe<Scalars["String"]>;
  numberOfRows: Scalars["Int"];
  numberOfColumns: Scalars["Int"];
  operatingVol?: Maybe<Scalars["Int"]>;
  resuspensionVol?: Maybe<Scalars["Int"]>;
  startingVol?: Maybe<Scalars["Int"]>;
  preprocess?: Maybe<Scalars["Boolean"]>;
  topup?: Maybe<Scalars["Boolean"]>;
};

export type PlateMetadata = {
  __typename?: "PlateMetadata";
  sourcePlateBarcode: Scalars["String"];
  experimentSetting?: Maybe<Scalars["String"]>;
  analysisSetting?: Maybe<Scalars["String"]>;
  platePitch?: Maybe<Scalars["String"]>;
  imageExportSetting?: Maybe<Scalars["Int"]>;
  imageExportFormat?: Maybe<ImageExportFormat>;
};

export type PlateMetadataFilterInput = {
  and?: Maybe<Array<PlateMetadataFilterInput>>;
  or?: Maybe<Array<PlateMetadataFilterInput>>;
  sourcePlateBarcode?: Maybe<StringOperationFilterInput>;
  experimentSetting?: Maybe<StringOperationFilterInput>;
  analysisSetting?: Maybe<StringOperationFilterInput>;
  platePitch?: Maybe<StringOperationFilterInput>;
  imageExportSetting?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  imageExportFormat?: Maybe<NullableOfImageExportFormatOperationFilterInput>;
};

export type PlateMetadataInput = {
  sourcePlateBarcode: Scalars["String"];
  experimentSetting?: Maybe<Scalars["String"]>;
  analysisSetting?: Maybe<Scalars["String"]>;
  platePitch?: Maybe<Scalars["String"]>;
  imageExportSetting?: Maybe<Scalars["Int"]>;
  imageExportFormat?: Maybe<ImageExportFormat>;
};

export type PlateNote = {
  __typename?: "PlateNote";
  plateNoteId: Scalars["Int"];
  plateId: Scalars["Int"];
  note?: Maybe<Scalars["String"]>;
  dateCommented: Scalars["DateTime"];
  userId: Scalars["Int"];
  plate?: Maybe<PlatesCreated>;
  user?: Maybe<AppSuiteUser>;
};

export type PlateNoteFilterInput = {
  and?: Maybe<Array<PlateNoteFilterInput>>;
  or?: Maybe<Array<PlateNoteFilterInput>>;
  plateNoteId?: Maybe<ComparableInt32OperationFilterInput>;
  plateId?: Maybe<ComparableInt32OperationFilterInput>;
  note?: Maybe<StringOperationFilterInput>;
  dateCommented?: Maybe<ComparableDateTimeOperationFilterInput>;
  userId?: Maybe<ComparableInt32OperationFilterInput>;
  plate?: Maybe<PlatesCreatedFilterInput>;
  user?: Maybe<AppSuiteUserFilterInput>;
};

export type PlateToMetadata = {
  __typename?: "PlateToMetadata";
  platesCreated?: Maybe<PlatesCreated>;
  metadata?: Maybe<PlateMetadata>;
};

export type PlateTransfer = {
  __typename?: "PlateTransfer";
  plateTransferId: Scalars["Int"];
  srcPlateId?: Maybe<Scalars["Int"]>;
  destPlateId?: Maybe<Scalars["Int"]>;
  dateTime?: Maybe<Scalars["DateTime"]>;
  isVisible?: Maybe<Scalars["Boolean"]>;
  destPlate?: Maybe<PlatesCreated>;
  srcPlate?: Maybe<PlatesCreated>;
};

export type PlateTransferFilterInput = {
  and?: Maybe<Array<PlateTransferFilterInput>>;
  or?: Maybe<Array<PlateTransferFilterInput>>;
  plateTransferId?: Maybe<ComparableInt32OperationFilterInput>;
  srcPlateId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  destPlateId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  dateTime?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isVisible?: Maybe<BooleanOperationFilterInput>;
  destPlate?: Maybe<PlatesCreatedFilterInput>;
  srcPlate?: Maybe<PlatesCreatedFilterInput>;
};

export type PlateType = {
  __typename?: "PlateType";
  plateTypeId: Scalars["Int"];
  plateName?: Maybe<Scalars["String"]>;
  plateDescription?: Maybe<Scalars["String"]>;
  manufacturer?: Maybe<Scalars["String"]>;
  catalogNumber?: Maybe<Scalars["String"]>;
  plateRows?: Maybe<Scalars["Int"]>;
  plateCols?: Maybe<Scalars["Int"]>;
  lookupArrayPlateTypeStrings?: Maybe<Array<Maybe<LookupArrayPlateTypeString>>>;
  lookupExperimentSettings?: Maybe<Array<Maybe<LookupExperimentSetting>>>;
  lookupLabwareTypes?: Maybe<Array<Maybe<LookupLabwareType>>>;
};

export type PlateTypeFilterInput = {
  and?: Maybe<Array<PlateTypeFilterInput>>;
  or?: Maybe<Array<PlateTypeFilterInput>>;
  plateTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  plateName?: Maybe<StringOperationFilterInput>;
  plateDescription?: Maybe<StringOperationFilterInput>;
  manufacturer?: Maybe<StringOperationFilterInput>;
  catalogNumber?: Maybe<StringOperationFilterInput>;
  plateRows?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  plateCols?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  lookupArrayPlateTypeStrings?: Maybe<ListFilterInputTypeOfLookupArrayPlateTypeStringFilterInput>;
  lookupExperimentSettings?: Maybe<ListFilterInputTypeOfLookupExperimentSettingFilterInput>;
  lookupLabwareTypes?: Maybe<ListFilterInputTypeOfLookupLabwareTypeFilterInput>;
};

export type PlateTypeSortInput = {
  plateTypeId?: Maybe<SortEnumType>;
  plateName?: Maybe<SortEnumType>;
  plateDescription?: Maybe<SortEnumType>;
  manufacturer?: Maybe<SortEnumType>;
  catalogNumber?: Maybe<SortEnumType>;
  plateRows?: Maybe<SortEnumType>;
  plateCols?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type PlatesConnection = {
  __typename?: "PlatesConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PlatesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<PlatesCreated>>>;
};

export type PlatesCreated = {
  __typename?: "PlatesCreated";
  method?: Maybe<LookupArrayMethod>;
  plateCreatedId: Scalars["Int"];
  plateBarcode?: Maybe<Scalars["String"]>;
  dateCreated?: Maybe<Scalars["DateTime"]>;
  createdByUserId?: Maybe<Scalars["Int"]>;
  dateUploaded?: Maybe<Scalars["DateTime"]>;
  systemSerialNumber?: Maybe<Scalars["String"]>;
  systemMethodName?: Maybe<Scalars["String"]>;
  plateBarcodeAlias?: Maybe<Scalars["String"]>;
  isActive: Scalars["Boolean"];
  dateInactivate?: Maybe<Scalars["DateTime"]>;
  inactivatedBy?: Maybe<Scalars["String"]>;
  inactivationReasonId?: Maybe<Scalars["Int"]>;
  reasonInactivated?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  productionRunId?: Maybe<Scalars["Int"]>;
  arrayPlateTypeId: Scalars["Int"];
  reasonNoData?: Maybe<Scalars["String"]>;
  reasonNoTubeBarcodes?: Maybe<Scalars["String"]>;
  reasonNoPassage?: Maybe<Scalars["String"]>;
  isIntermediate: Scalars["Boolean"];
  isDead: Scalars["Boolean"];
  labwareTypeId?: Maybe<Scalars["Int"]>;
  temporaryCreation?: Maybe<Scalars["Boolean"]>;
  coatedPlateBatchId?: Maybe<Scalars["Int"]>;
  celigoExperimentSettingsId?: Maybe<Scalars["Int"]>;
  lastScanDate?: Maybe<Scalars["DateTime"]>;
  isReserved?: Maybe<Scalars["Boolean"]>;
  arrayPlateType?: Maybe<LookupArrayPlateTypeString>;
  celigoExperimentSettings?: Maybe<LookupExperimentSetting>;
  coatedPlateBatch?: Maybe<CoatedPlateBatch>;
  createdByUser?: Maybe<AppSuiteUser>;
  labwareType?: Maybe<LookupLabwareType>;
  productionRun?: Maybe<ProductionRun>;
  containers?: Maybe<Array<Maybe<Container>>>;
  cytomatPositions?: Maybe<Array<Maybe<CytomatPosition>>>;
  plateNotes?: Maybe<Array<Maybe<PlateNote>>>;
  plateTransferDestPlates?: Maybe<Array<Maybe<PlateTransfer>>>;
  plateTransferSrcPlates?: Maybe<Array<Maybe<PlateTransfer>>>;
  runs?: Maybe<Array<Maybe<Run>>>;
  tubeBarcodeFiles?: Maybe<Array<Maybe<TubeBarcodeFile>>>;
};

export type PlatesCreatedFilterInput = {
  and?: Maybe<Array<PlatesCreatedFilterInput>>;
  or?: Maybe<Array<PlatesCreatedFilterInput>>;
  method?: Maybe<LookupArrayMethodFilterInput>;
  plateCreatedId?: Maybe<ComparableInt32OperationFilterInput>;
  plateBarcode?: Maybe<StringOperationFilterInput>;
  dateCreated?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  createdByUserId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  dateUploaded?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  systemSerialNumber?: Maybe<StringOperationFilterInput>;
  systemMethodName?: Maybe<StringOperationFilterInput>;
  plateBarcodeAlias?: Maybe<StringOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  dateInactivate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  inactivatedBy?: Maybe<StringOperationFilterInput>;
  inactivationReasonId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  reasonInactivated?: Maybe<StringOperationFilterInput>;
  comments?: Maybe<StringOperationFilterInput>;
  productionRunId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  arrayPlateTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  reasonNoData?: Maybe<StringOperationFilterInput>;
  reasonNoTubeBarcodes?: Maybe<StringOperationFilterInput>;
  reasonNoPassage?: Maybe<StringOperationFilterInput>;
  isIntermediate?: Maybe<BooleanOperationFilterInput>;
  isDead?: Maybe<BooleanOperationFilterInput>;
  labwareTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  temporaryCreation?: Maybe<BooleanOperationFilterInput>;
  coatedPlateBatchId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  celigoExperimentSettingsId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  lastScanDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isReserved?: Maybe<BooleanOperationFilterInput>;
  arrayPlateType?: Maybe<LookupArrayPlateTypeStringFilterInput>;
  celigoExperimentSettings?: Maybe<LookupExperimentSettingFilterInput>;
  coatedPlateBatch?: Maybe<CoatedPlateBatchFilterInput>;
  createdByUser?: Maybe<AppSuiteUserFilterInput>;
  labwareType?: Maybe<LookupLabwareTypeFilterInput>;
  productionRun?: Maybe<ProductionRunFilterInput>;
  containers?: Maybe<ListFilterInputTypeOfContainerFilterInput>;
  cytomatPositions?: Maybe<ListFilterInputTypeOfCytomatPositionFilterInput>;
  plateNotes?: Maybe<ListFilterInputTypeOfPlateNoteFilterInput>;
  plateTransferDestPlates?: Maybe<ListFilterInputTypeOfPlateTransferFilterInput>;
  plateTransferSrcPlates?: Maybe<ListFilterInputTypeOfPlateTransferFilterInput>;
  runs?: Maybe<ListFilterInputTypeOfRunFilterInput>;
  tubeBarcodeFiles?: Maybe<ListFilterInputTypeOfTubeBarcodeFileFilterInput>;
};

export type PlatesCreatedSortInput = {
  method?: Maybe<LookupArrayMethodSortInput>;
  plateCreatedId?: Maybe<SortEnumType>;
  plateBarcode?: Maybe<SortEnumType>;
  dateCreated?: Maybe<SortEnumType>;
  createdByUserId?: Maybe<SortEnumType>;
  dateUploaded?: Maybe<SortEnumType>;
  systemSerialNumber?: Maybe<SortEnumType>;
  systemMethodName?: Maybe<SortEnumType>;
  plateBarcodeAlias?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  dateInactivate?: Maybe<SortEnumType>;
  inactivatedBy?: Maybe<SortEnumType>;
  inactivationReasonId?: Maybe<SortEnumType>;
  reasonInactivated?: Maybe<SortEnumType>;
  comments?: Maybe<SortEnumType>;
  productionRunId?: Maybe<SortEnumType>;
  arrayPlateTypeId?: Maybe<SortEnumType>;
  reasonNoData?: Maybe<SortEnumType>;
  reasonNoTubeBarcodes?: Maybe<SortEnumType>;
  reasonNoPassage?: Maybe<SortEnumType>;
  isIntermediate?: Maybe<SortEnumType>;
  isDead?: Maybe<SortEnumType>;
  labwareTypeId?: Maybe<SortEnumType>;
  temporaryCreation?: Maybe<SortEnumType>;
  coatedPlateBatchId?: Maybe<SortEnumType>;
  celigoExperimentSettingsId?: Maybe<SortEnumType>;
  lastScanDate?: Maybe<SortEnumType>;
  isReserved?: Maybe<SortEnumType>;
  arrayPlateType?: Maybe<LookupArrayPlateTypeStringSortInput>;
  celigoExperimentSettings?: Maybe<LookupExperimentSettingSortInput>;
  coatedPlateBatch?: Maybe<CoatedPlateBatchSortInput>;
  createdByUser?: Maybe<AppSuiteUserSortInput>;
  labwareType?: Maybe<LookupLabwareTypeSortInput>;
  productionRun?: Maybe<ProductionRunSortInput>;
};

/** An edge in a connection. */
export type PlatesEdge = {
  __typename?: "PlatesEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<PlatesCreated>;
};

export type ProductionRun = {
  __typename?: "ProductionRun";
  runId: Scalars["Int"];
  runName?: Maybe<Scalars["String"]>;
  runNameAlias?: Maybe<Scalars["String"]>;
  aliasComment?: Maybe<Scalars["String"]>;
  runTypeId?: Maybe<Scalars["Int"]>;
  runTemplateId?: Maybe<Scalars["Int"]>;
  startDate?: Maybe<Scalars["DateTime"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  runStatusId?: Maybe<Scalars["Byte"]>;
  isActive: Scalars["Boolean"];
  inactivationReasonId?: Maybe<Scalars["Int"]>;
  inactivationComments?: Maybe<Scalars["String"]>;
  dateInactivated?: Maybe<Scalars["DateTime"]>;
  inactivatedBy?: Maybe<Scalars["String"]>;
  isQcactive?: Maybe<Scalars["Boolean"]>;
  isExperimental?: Maybe<Scalars["Boolean"]>;
  isReference?: Maybe<Scalars["Boolean"]>;
  endDateFbreproTube?: Maybe<Scalars["DateTime"]>;
  createdByUserId?: Maybe<Scalars["Int"]>;
  createdByUser?: Maybe<AppSuiteUser>;
  runType?: Maybe<ProductionRunType>;
  platesCreateds?: Maybe<Array<Maybe<PlatesCreated>>>;
  runs?: Maybe<Array<Maybe<Run>>>;
};

export type ProductionRunFilterInput = {
  and?: Maybe<Array<ProductionRunFilterInput>>;
  or?: Maybe<Array<ProductionRunFilterInput>>;
  runId?: Maybe<ComparableInt32OperationFilterInput>;
  runName?: Maybe<StringOperationFilterInput>;
  runNameAlias?: Maybe<StringOperationFilterInput>;
  aliasComment?: Maybe<StringOperationFilterInput>;
  runTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  runTemplateId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  startDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  endDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  runStatusId?: Maybe<ComparableNullableOfByteOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  inactivationReasonId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  inactivationComments?: Maybe<StringOperationFilterInput>;
  dateInactivated?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  inactivatedBy?: Maybe<StringOperationFilterInput>;
  isQcactive?: Maybe<BooleanOperationFilterInput>;
  isExperimental?: Maybe<BooleanOperationFilterInput>;
  isReference?: Maybe<BooleanOperationFilterInput>;
  endDateFbreproTube?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  createdByUserId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  createdByUser?: Maybe<AppSuiteUserFilterInput>;
  runType?: Maybe<ProductionRunTypeFilterInput>;
  platesCreateds?: Maybe<ListFilterInputTypeOfPlatesCreatedFilterInput>;
  runs?: Maybe<ListFilterInputTypeOfRunFilterInput>;
};

export type ProductionRunSortInput = {
  runId?: Maybe<SortEnumType>;
  runName?: Maybe<SortEnumType>;
  runNameAlias?: Maybe<SortEnumType>;
  aliasComment?: Maybe<SortEnumType>;
  runTypeId?: Maybe<SortEnumType>;
  runTemplateId?: Maybe<SortEnumType>;
  startDate?: Maybe<SortEnumType>;
  endDate?: Maybe<SortEnumType>;
  runStatusId?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  inactivationReasonId?: Maybe<SortEnumType>;
  inactivationComments?: Maybe<SortEnumType>;
  dateInactivated?: Maybe<SortEnumType>;
  inactivatedBy?: Maybe<SortEnumType>;
  isQcactive?: Maybe<SortEnumType>;
  isExperimental?: Maybe<SortEnumType>;
  isReference?: Maybe<SortEnumType>;
  endDateFbreproTube?: Maybe<SortEnumType>;
  createdByUserId?: Maybe<SortEnumType>;
  createdByUser?: Maybe<AppSuiteUserSortInput>;
  runType?: Maybe<ProductionRunTypeSortInput>;
};

export type ProductionRunType = {
  __typename?: "ProductionRunType";
  productionRunTypeId: Scalars["Int"];
  typeName?: Maybe<Scalars["String"]>;
  runCode?: Maybe<Scalars["String"]>;
  runNumberDigits: Scalars["Int"];
  isActive?: Maybe<Scalars["Boolean"]>;
  lotNumberTypeId?: Maybe<Scalars["Int"]>;
  lotNumberFormatId?: Maybe<Scalars["Int"]>;
  sourcePlateTypeId?: Maybe<Scalars["Int"]>;
  finalPlateTypeId?: Maybe<Scalars["Int"]>;
  actionId?: Maybe<Scalars["Int"]>;
  isRandD?: Maybe<Scalars["Boolean"]>;
  isGeneEditing?: Maybe<Scalars["Boolean"]>;
  isReprogramming?: Maybe<Scalars["Boolean"]>;
  isConsolidation?: Maybe<Scalars["Boolean"]>;
  slackChannelId?: Maybe<Scalars["Int"]>;
  isSourceRun?: Maybe<Scalars["Boolean"]>;
  pivotPosition?: Maybe<Scalars["Int"]>;
  expectedCompletionTime?: Maybe<Scalars["Int"]>;
  newSampleTypeId?: Maybe<Scalars["Int"]>;
  sampleTypeChangeTriggersOnAnyPassageWithinRun?: Maybe<Scalars["Boolean"]>;
  sampleTypeChangeTriggersOnRunTypeChange?: Maybe<Scalars["Boolean"]>;
  sampleTypeChangeUsesSingleCloneMode?: Maybe<Scalars["Boolean"]>;
  runTemplateId?: Maybe<Scalars["Int"]>;
  finalPlateType?: Maybe<LookupArrayPlateTypeString>;
  lotNumberFormat?: Maybe<LotNumberFormat>;
  lotNumberType?: Maybe<LookupLotNumberType>;
  newSampleType?: Maybe<LookupSampleType>;
  runTemplate?: Maybe<RunTemplate>;
  sourcePlateType?: Maybe<LookupArrayPlateTypeString>;
  lookupArrayPlateTypeStrings?: Maybe<Array<Maybe<LookupArrayPlateTypeString>>>;
  productionRuns?: Maybe<Array<Maybe<ProductionRun>>>;
  runTemplates?: Maybe<Array<Maybe<RunTemplate>>>;
};

export type ProductionRunTypeFilterInput = {
  and?: Maybe<Array<ProductionRunTypeFilterInput>>;
  or?: Maybe<Array<ProductionRunTypeFilterInput>>;
  productionRunTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  typeName?: Maybe<StringOperationFilterInput>;
  runCode?: Maybe<StringOperationFilterInput>;
  runNumberDigits?: Maybe<ComparableInt32OperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  lotNumberTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  lotNumberFormatId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  sourcePlateTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  finalPlateTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  actionId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  isRandD?: Maybe<BooleanOperationFilterInput>;
  isGeneEditing?: Maybe<BooleanOperationFilterInput>;
  isReprogramming?: Maybe<BooleanOperationFilterInput>;
  isConsolidation?: Maybe<BooleanOperationFilterInput>;
  slackChannelId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  isSourceRun?: Maybe<BooleanOperationFilterInput>;
  pivotPosition?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  expectedCompletionTime?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  newSampleTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  sampleTypeChangeTriggersOnAnyPassageWithinRun?: Maybe<BooleanOperationFilterInput>;
  sampleTypeChangeTriggersOnRunTypeChange?: Maybe<BooleanOperationFilterInput>;
  sampleTypeChangeUsesSingleCloneMode?: Maybe<BooleanOperationFilterInput>;
  runTemplateId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  finalPlateType?: Maybe<LookupArrayPlateTypeStringFilterInput>;
  lotNumberFormat?: Maybe<LotNumberFormatFilterInput>;
  lotNumberType?: Maybe<LookupLotNumberTypeFilterInput>;
  newSampleType?: Maybe<LookupSampleTypeFilterInput>;
  runTemplate?: Maybe<RunTemplateFilterInput>;
  sourcePlateType?: Maybe<LookupArrayPlateTypeStringFilterInput>;
  lookupArrayPlateTypeStrings?: Maybe<ListFilterInputTypeOfLookupArrayPlateTypeStringFilterInput>;
  productionRuns?: Maybe<ListFilterInputTypeOfProductionRunFilterInput>;
  runTemplates?: Maybe<ListFilterInputTypeOfRunTemplateFilterInput>;
};

export type ProductionRunTypeSortInput = {
  productionRunTypeId?: Maybe<SortEnumType>;
  typeName?: Maybe<SortEnumType>;
  runCode?: Maybe<SortEnumType>;
  runNumberDigits?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  lotNumberTypeId?: Maybe<SortEnumType>;
  lotNumberFormatId?: Maybe<SortEnumType>;
  sourcePlateTypeId?: Maybe<SortEnumType>;
  finalPlateTypeId?: Maybe<SortEnumType>;
  actionId?: Maybe<SortEnumType>;
  isRandD?: Maybe<SortEnumType>;
  isGeneEditing?: Maybe<SortEnumType>;
  isReprogramming?: Maybe<SortEnumType>;
  isConsolidation?: Maybe<SortEnumType>;
  slackChannelId?: Maybe<SortEnumType>;
  isSourceRun?: Maybe<SortEnumType>;
  pivotPosition?: Maybe<SortEnumType>;
  expectedCompletionTime?: Maybe<SortEnumType>;
  newSampleTypeId?: Maybe<SortEnumType>;
  sampleTypeChangeTriggersOnAnyPassageWithinRun?: Maybe<SortEnumType>;
  sampleTypeChangeTriggersOnRunTypeChange?: Maybe<SortEnumType>;
  sampleTypeChangeUsesSingleCloneMode?: Maybe<SortEnumType>;
  runTemplateId?: Maybe<SortEnumType>;
  finalPlateType?: Maybe<LookupArrayPlateTypeStringSortInput>;
  lotNumberFormat?: Maybe<LotNumberFormatSortInput>;
  lotNumberType?: Maybe<LookupLotNumberTypeSortInput>;
  newSampleType?: Maybe<LookupSampleTypeSortInput>;
  runTemplate?: Maybe<RunTemplateSortInput>;
  sourcePlateType?: Maybe<LookupArrayPlateTypeStringSortInput>;
};

export type Project = {
  __typename?: "Project";
  projectId: Scalars["Int"];
  projectName?: Maybe<Scalars["String"]>;
  nyscfprefix?: Maybe<Scalars["String"]>;
  dateCreated: Scalars["DateTime"];
  createdBy?: Maybe<Scalars["String"]>;
  administrator?: Maybe<Scalars["String"]>;
  isActive: Scalars["Boolean"];
  providedAsIsCoAtext?: Maybe<Scalars["String"]>;
  collections?: Maybe<Array<Maybe<Collection>>>;
  projectDonors?: Maybe<Array<Maybe<ProjectDonor>>>;
};

export type ProjectDonor = {
  __typename?: "ProjectDonor";
  projectDonorId: Scalars["Int"];
  projectId: Scalars["Int"];
  donorId: Scalars["Int"];
  associatedBy?: Maybe<Scalars["String"]>;
  donor?: Maybe<Donor>;
  project?: Maybe<Project>;
};

export type ProjectDonorFilterInput = {
  and?: Maybe<Array<ProjectDonorFilterInput>>;
  or?: Maybe<Array<ProjectDonorFilterInput>>;
  projectDonorId?: Maybe<ComparableInt32OperationFilterInput>;
  projectId?: Maybe<ComparableInt32OperationFilterInput>;
  donorId?: Maybe<ComparableInt32OperationFilterInput>;
  associatedBy?: Maybe<StringOperationFilterInput>;
  donor?: Maybe<DonorFilterInput>;
  project?: Maybe<ProjectFilterInput>;
};

export type ProjectFilterInput = {
  and?: Maybe<Array<ProjectFilterInput>>;
  or?: Maybe<Array<ProjectFilterInput>>;
  projectId?: Maybe<ComparableInt32OperationFilterInput>;
  projectName?: Maybe<StringOperationFilterInput>;
  nyscfprefix?: Maybe<StringOperationFilterInput>;
  dateCreated?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  administrator?: Maybe<StringOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  providedAsIsCoAtext?: Maybe<StringOperationFilterInput>;
  collections?: Maybe<ListFilterInputTypeOfCollectionFilterInput>;
  projectDonors?: Maybe<ListFilterInputTypeOfProjectDonorFilterInput>;
};

export type ProjectSortInput = {
  projectId?: Maybe<SortEnumType>;
  projectName?: Maybe<SortEnumType>;
  nyscfprefix?: Maybe<SortEnumType>;
  dateCreated?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  administrator?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  providedAsIsCoAtext?: Maybe<SortEnumType>;
};

export type Query = {
  __typename?: "Query";
  activeASAMs?: Maybe<Array<Maybe<ArraySystemAutomationMethod>>>;
  activePooling1224TCPlates?: Maybe<Array<Maybe<Scalars["String"]>>>;
  activeReservations?: Maybe<Array<Maybe<MethodReservation>>>;
  activeTickets?: Maybe<Array<Maybe<Ticket>>>;
  allUserAndGroupTasks?: Maybe<Array<Maybe<DashboardTask>>>;
  appSuiteGroups?: Maybe<Array<Maybe<AppSuiteGroup>>>;
  appSuiteUsers?: Maybe<Array<Maybe<AppSuiteUser>>>;
  arrayMethodLabwareTypes?: Maybe<
    Array<Maybe<ArraySystemAutomationMethodLabwareType>>
  >;
  arraySystemAutomationMethods?: Maybe<
    Array<Maybe<ArraySystemAutomationMethod>>
  >;
  bloodProcessingRackInfo?: Maybe<
    Array<Maybe<TubeLabellerDestinationWorklist>>
  >;
  calendarNodes?: Maybe<Array<Maybe<CalendarNode>>>;
  celigoScanPlateMetadata?: Maybe<Array<Maybe<GroupedPlateMetadata>>>;
  cellNumberFromNormalizedPassagePlate: Scalars["Int"];
  consolidationRackInfo?: Maybe<Array<Maybe<TubeLabellerDestinationWorklist>>>;
  container?: Maybe<Container>;
  containerLineage?: Maybe<Array<Maybe<ContainerLineage>>>;
  containers?: Maybe<ContainersConnection>;
  createRunDefaults?: Maybe<CreateRunDefaults>;
  dNARNARackInfo?: Maybe<Array<Maybe<TubeLabellerDestinationWorklist>>>;
  dashboardTask?: Maybe<DashboardTask>;
  directory?: Maybe<Array<Maybe<DirectoryFolder>>>;
  eBCollectionRackInfo?: Maybe<Array<Maybe<TubeLabellerDestinationWorklist>>>;
  excludedFeedPlates?: Maybe<Array<Maybe<SystemOneFeedRuns>>>;
  existingDeadPlate?: Maybe<PlatesCreated>;
  existingMethodReservation?: Maybe<Array<Maybe<MethodReservation>>>;
  experimentSettings?: Maybe<Array<Maybe<LookupExperimentSetting>>>;
  feedGroups?: Maybe<Array<Maybe<FeedGroup>>>;
  fibroblastRackInfo?: Maybe<Array<Maybe<TubeLabellerDestinationWorklist>>>;
  folder?: Maybe<FolderStructure>;
  folders?: Maybe<Array<Maybe<FolderStructure>>>;
  ganttIFrameUrl?: Maybe<Scalars["String"]>;
  group?: Maybe<AppSuiteGroup>;
  groupPersonalTasks?: Maybe<Array<Maybe<PersonalTask>>>;
  groupsFromCache?: Maybe<Array<Maybe<AppSuiteGroup>>>;
  iPSCExpansionRackInfo?: Maybe<Array<Maybe<TubeLabellerDestinationWorklist>>>;
  includedFeedPlates?: Maybe<Array<Maybe<SystemOneFeedRuns>>>;
  incomingSampleCollections?: Maybe<IncomingSampleCollectionsConnection>;
  incomingSampleTasks?: Maybe<IncomingSampleTasksConnection>;
  incomingSamplesCollection?: Maybe<Collection>;
  incomingSamplesTask?: Maybe<CollectionTask>;
  incomingSamplesTasksFromCache: Array<CollectionTask>;
  insertableFolders?: Maybe<Array<Maybe<FolderStructure>>>;
  labComputers?: Maybe<Array<Maybe<LabComputer>>>;
  labwareTypes?: Maybe<Array<Maybe<LookupLabwareType>>>;
  lotNumbers?: Maybe<LotNumbersConnection>;
  manualPlateRackInfo?: Maybe<Array<Maybe<TubeLabellerDestinationWorklist>>>;
  manualTaskType?: Maybe<ManualTaskType>;
  manualTaskTypes?: Maybe<Array<Maybe<ManualTaskType>>>;
  me?: Maybe<UserClaims>;
  method?: Maybe<LookupArrayMethod>;
  methodReservation?: Maybe<Array<Maybe<MethodReservation>>>;
  methods?: Maybe<Array<Maybe<LookupArrayMethod>>>;
  monoclonalizationRackInfo?: Maybe<
    Array<Maybe<TubeLabellerDestinationWorklist>>
  >;
  myPersonalTasks?: Maybe<MyPersonalTasksConnection>;
  personalTaskAttachments?: Maybe<Array<Maybe<PersonalTaskAttachment>>>;
  personalTaskTemplates?: Maybe<PersonalTaskTemplatesConnection>;
  plate?: Maybe<PlatesCreated>;
  plateCodes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  plateHasTubeBarcodeScan: Scalars["Boolean"];
  plateInfo?: Maybe<PlatesCreated>;
  plateMap?: Maybe<Array<Maybe<PlatesCreated>>>;
  plates?: Maybe<PlatesConnection>;
  platesForManualSelector?: Maybe<Array<Maybe<PlatesCreated>>>;
  platesInfo?: Maybe<PlatesCreated>;
  pooling1224TCRackInfo?: Maybe<Array<Maybe<TubeLabellerDestinationWorklist>>>;
  productionRunType?: Maybe<ProductionRunType>;
  productionRuns?: Maybe<Array<Maybe<ProductionRun>>>;
  projects?: Maybe<Array<Maybe<Project>>>;
  qCRackInfo?: Maybe<Array<Maybe<TubeLabellerDestinationWorklist>>>;
  repeatingTasksSummary?: Maybe<RepeatingTasksSummaryConnection>;
  reservedMethods?: Maybe<Array<Maybe<MethodReservation>>>;
  resuspensionVolFromPooling1224TCPlate: Scalars["Int"];
  run?: Maybe<Run>;
  runStages?: Maybe<RunStagesConnection>;
  runTask?: Maybe<RunTask>;
  runTaskAttachments?: Maybe<Array<Maybe<RunTaskAttachment>>>;
  runTaskMetadata?: Maybe<Array<Maybe<RunTask>>>;
  runTaskTypes?: Maybe<Array<Maybe<RunTaskType>>>;
  runTasks?: Maybe<RunTasksConnection>;
  runTasksForTubeLabels?: Maybe<Array<Maybe<RunTask>>>;
  runTasksFromCache: Array<RunTask>;
  runTemplate?: Maybe<RunTemplate>;
  runTemplateStage?: Maybe<RunTemplateStage>;
  runTemplateTask?: Maybe<RunTemplateTask>;
  runTemplateTasks?: Maybe<Array<Maybe<RunTemplateTask>>>;
  runTemplates?: Maybe<RunTemplatesConnection>;
  runTemplatesForCombobox?: Maybe<Array<Maybe<RunTemplateOption>>>;
  runTypes?: Maybe<Array<Maybe<ProductionRunType>>>;
  runs?: Maybe<Array<Maybe<Run>>>;
  salivaRackInfo?: Maybe<Array<Maybe<TubeLabellerDestinationWorklist>>>;
  sample?: Maybe<Sample>;
  sampleTypes?: Maybe<Array<Maybe<LookupSampleType>>>;
  searchDirectory: Array<DirectoryFolder>;
  serverGroupState?: Maybe<GroupLoginState>;
  sourcePlates?: Maybe<Array<Maybe<PlatesCreated>>>;
  system?: Maybe<LookupArraySystem>;
  systems?: Maybe<Array<Maybe<LookupArraySystem>>>;
  taskDetailsForWorklistTool?: Maybe<RunTaskDetail>;
  taskHistory?: Maybe<Array<Maybe<WebSuiteTaskLog>>>;
  ticket?: Maybe<Ticket>;
  ticketAssetTypes?: Maybe<Array<Maybe<LookupAssetType>>>;
  ticketAssets?: Maybe<Array<Maybe<LookupAsset>>>;
  ticketInfo?: Maybe<Ticket>;
  ticketTypes?: Maybe<Array<Maybe<LookupTicketType>>>;
  tickets?: Maybe<TicketsConnection>;
  tubeLabellerRuns?: Maybe<Array<Maybe<Scalars["String"]>>>;
  tubeLabellerRunsByProcess?: Maybe<Array<Maybe<ProductionRun>>>;
  tubeLabellerSalivaSamples?: Maybe<Array<Maybe<Sample>>>;
  unavailablePlates?: Maybe<Array<Maybe<Scalars["String"]>>>;
  user?: Maybe<AppSuiteUser>;
  usersFromCache: Array<Maybe<AppSuiteUser>>;
  worklist?: Maybe<Array<Maybe<Worklist>>>;
  worklistToolTemplate?: Maybe<Array<Maybe<WorklistToolTemplate>>>;
  worklistToolTemplateContent?: Maybe<
    Array<Maybe<WorklistToolTemplateContent>>
  >;
};

export type QueryActiveAsaMsArgs = {
  where?: Maybe<ArraySystemAutomationMethodFilterInput>;
};

export type QueryActiveReservationsArgs = {
  where?: Maybe<MethodReservationFilterInput>;
};

export type QueryActiveTicketsArgs = {
  where?: Maybe<TicketFilterInput>;
  order?: Maybe<Array<TicketSortInput>>;
};

export type QueryAllUserAndGroupTasksArgs = {
  where?: Maybe<DashboardTaskFilterInput>;
  order?: Maybe<Array<DashboardTaskSortInput>>;
};

export type QueryAppSuiteGroupsArgs = {
  order?: Maybe<Array<AppSuiteGroupSortInput>>;
  where?: Maybe<AppSuiteGroupFilterInput>;
};

export type QueryAppSuiteUsersArgs = {
  order?: Maybe<Array<AppSuiteUserSortInput>>;
  where?: Maybe<AppSuiteUserFilterInput>;
};

export type QueryArrayMethodLabwareTypesArgs = {
  where?: Maybe<ArraySystemAutomationMethodLabwareTypeFilterInput>;
};

export type QueryBloodProcessingRackInfoArgs = {
  input?: Maybe<AddRackInput>;
};

export type QueryCalendarNodesArgs = {
  startDate: Scalars["DateTime"];
  endDate: Scalars["DateTime"];
  where?: Maybe<CalendarNodeFilterInput>;
};

export type QueryCellNumberFromNormalizedPassagePlateArgs = {
  plateBarcode?: Maybe<Scalars["String"]>;
};

export type QueryConsolidationRackInfoArgs = {
  input?: Maybe<AddRackInput>;
};

export type QueryContainerArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryContainerLineageArgs = {
  containerId: Scalars["Int"];
};

export type QueryContainersArgs = {
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  where?: Maybe<ContainerFilterInput>;
  order?: Maybe<Array<ContainerSortInput>>;
};

export type QueryCreateRunDefaultsArgs = {
  runTemplateId: Scalars["Int"];
};

export type QueryDNarnaRackInfoArgs = {
  input?: Maybe<AddRackInput>;
};

export type QueryDashboardTaskArgs = {
  taskID: Scalars["Int"];
  taskType: TaskTypeEnum;
};

export type QueryDirectoryArgs = {
  where?: Maybe<DirectoryFolderFilterInput>;
  order?: Maybe<Array<DirectoryFolderSortInput>>;
};

export type QueryEbCollectionRackInfoArgs = {
  input?: Maybe<AddRackInput>;
};

export type QueryExcludedFeedPlatesArgs = {
  dateScheduled: Scalars["DateTime"];
};

export type QueryExistingDeadPlateArgs = {
  srcPlateId: Scalars["Int"];
};

export type QueryExistingMethodReservationArgs = {
  where?: Maybe<MethodReservationFilterInput>;
};

export type QueryExperimentSettingsArgs = {
  where?: Maybe<LookupExperimentSettingFilterInput>;
  order?: Maybe<Array<LookupExperimentSettingSortInput>>;
};

export type QueryFeedGroupsArgs = {
  groupId: Scalars["Int"];
};

export type QueryFibroblastRackInfoArgs = {
  input?: Maybe<AddRackInput>;
};

export type QueryFolderArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryFoldersArgs = {
  where?: Maybe<FolderStructureFilterInput>;
  order?: Maybe<Array<FolderStructureSortInput>>;
};

export type QueryGanttIFrameUrlArgs = {
  runId: Scalars["Int"];
};

export type QueryGroupArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryGroupPersonalTasksArgs = {
  groupId: Scalars["Int"];
  today: Scalars["DateTime"];
};

export type QueryGroupsFromCacheArgs = {
  ids?: Maybe<Array<Scalars["Int"]>>;
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryIPscExpansionRackInfoArgs = {
  input?: Maybe<AddRackInput>;
};

export type QueryIncludedFeedPlatesArgs = {
  dateScheduled: Scalars["DateTime"];
};

export type QueryIncomingSampleCollectionsArgs = {
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  where?: Maybe<CollectionFilterInput>;
  order?: Maybe<Array<CollectionSortInput>>;
};

export type QueryIncomingSampleTasksArgs = {
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  where?: Maybe<CollectionTaskFilterInput>;
  order?: Maybe<Array<CollectionTaskSortInput>>;
};

export type QueryIncomingSamplesCollectionArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryIncomingSamplesTaskArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryIncomingSamplesTasksFromCacheArgs = {
  ids: Array<Scalars["Int"]>;
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryLabwareTypesArgs = {
  where?: Maybe<LookupLabwareTypeFilterInput>;
};

export type QueryLotNumbersArgs = {
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  where?: Maybe<LotNumberFilterInput>;
  order?: Maybe<Array<LotNumberSortInput>>;
};

export type QueryManualPlateRackInfoArgs = {
  input?: Maybe<AddRackInput>;
};

export type QueryManualTaskTypeArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryManualTaskTypesArgs = {
  where?: Maybe<ManualTaskTypeFilterInput>;
  order?: Maybe<Array<ManualTaskTypeSortInput>>;
};

export type QueryMethodArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryMethodReservationArgs = {
  where?: Maybe<MethodReservationFilterInput>;
};

export type QueryMethodsArgs = {
  where?: Maybe<LookupArrayMethodFilterInput>;
  order?: Maybe<Array<LookupArrayMethodSortInput>>;
};

export type QueryMonoclonalizationRackInfoArgs = {
  input?: Maybe<AddRackInput>;
};

export type QueryMyPersonalTasksArgs = {
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  where?: Maybe<ManagePersonalTaskFilterInput>;
  order?: Maybe<Array<ManagePersonalTaskSortInput>>;
};

export type QueryPersonalTaskAttachmentsArgs = {
  taskId: Scalars["Int"];
};

export type QueryPersonalTaskTemplatesArgs = {
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  where?: Maybe<PersonalTaskTemplateFilterInput>;
  order?: Maybe<Array<PersonalTaskTemplateSortInput>>;
};

export type QueryPlateArgs = {
  id: Scalars["String"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryPlateHasTubeBarcodeScanArgs = {
  plateId: Scalars["Int"];
};

export type QueryPlateInfoArgs = {
  plateBarcode?: Maybe<Scalars["String"]>;
};

export type QueryPlateMapArgs = {
  plateID: Scalars["Int"];
};

export type QueryPlatesArgs = {
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  where?: Maybe<PlatesCreatedFilterInput>;
  order?: Maybe<Array<PlatesCreatedSortInput>>;
};

export type QueryPlatesInfoArgs = {
  where?: Maybe<PlatesCreatedFilterInput>;
};

export type QueryPooling1224TcRackInfoArgs = {
  input?: Maybe<AddRackInput>;
};

export type QueryProductionRunTypeArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryProductionRunsArgs = {
  folderId: Scalars["Int"];
  where?: Maybe<ProductionRunFilterInput>;
};

export type QueryProjectsArgs = {
  where?: Maybe<ProjectFilterInput>;
};

export type QueryQcRackInfoArgs = {
  input?: Maybe<AddRackInput>;
};

export type QueryRepeatingTasksSummaryArgs = {
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  where?: Maybe<PersonalTasksRecurringTaskFilterInput>;
  order?: Maybe<Array<PersonalTasksRecurringTaskSortInput>>;
};

export type QueryReservedMethodsArgs = {
  runId: Scalars["Int"];
  where?: Maybe<MethodReservationFilterInput>;
  order?: Maybe<Array<MethodReservationSortInput>>;
};

export type QueryResuspensionVolFromPooling1224TcPlateArgs = {
  plateBarcode?: Maybe<Scalars["String"]>;
};

export type QueryRunArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryRunStagesArgs = {
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  where?: Maybe<RunStageFilterInput>;
  order?: Maybe<Array<RunStageSortInput>>;
};

export type QueryRunTaskArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryRunTaskAttachmentsArgs = {
  taskId: Scalars["Int"];
};

export type QueryRunTaskMetadataArgs = {
  runTaskIds?: Maybe<Array<Scalars["Int"]>>;
};

export type QueryRunTaskTypesArgs = {
  order?: Maybe<Array<RunTaskTypeSortInput>>;
};

export type QueryRunTasksArgs = {
  key?: Maybe<DirectoryFolderKeyInput>;
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  where?: Maybe<RunTaskFilterInput>;
  order?: Maybe<Array<RunTaskSortInput>>;
};

export type QueryRunTasksForTubeLabelsArgs = {
  plateBarcode?: Maybe<Scalars["String"]>;
  showCompleted: Scalars["Boolean"];
  order?: Maybe<Array<RunTaskSortInput>>;
};

export type QueryRunTasksFromCacheArgs = {
  ids: Array<Scalars["Int"]>;
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryRunTemplateArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryRunTemplateStageArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryRunTemplateTaskArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryRunTemplateTasksArgs = {
  where?: Maybe<RunTemplateTaskFilterInput>;
  order?: Maybe<Array<RunTemplateTaskSortInput>>;
};

export type QueryRunTemplatesArgs = {
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  where?: Maybe<RunTemplateFilterInput>;
  order?: Maybe<Array<RunTemplateSortInput>>;
};

export type QueryRunTemplatesForComboboxArgs = {
  where?: Maybe<RunTemplateOptionFilterInput>;
  order?: Maybe<Array<RunTemplateOptionSortInput>>;
};

export type QueryRunTypesArgs = {
  where?: Maybe<ProductionRunTypeFilterInput>;
  order?: Maybe<Array<ProductionRunTypeSortInput>>;
};

export type QueryRunsArgs = {
  where?: Maybe<RunFilterInput>;
  order?: Maybe<Array<RunSortInput>>;
};

export type QuerySalivaRackInfoArgs = {
  input?: Maybe<AddRackInput>;
};

export type QuerySampleArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QuerySampleTypesArgs = {
  where?: Maybe<LookupSampleTypeFilterInput>;
};

export type QuerySearchDirectoryArgs = {
  search: Scalars["String"];
};

export type QueryServerGroupStateArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QuerySourcePlatesArgs = {
  where?: Maybe<PlatesCreatedFilterInput>;
  order?: Maybe<Array<PlatesCreatedSortInput>>;
};

export type QuerySystemArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QuerySystemsArgs = {
  where?: Maybe<LookupArraySystemFilterInput>;
  order?: Maybe<Array<LookupArraySystemSortInput>>;
};

export type QueryTaskDetailsForWorklistToolArgs = {
  runTaskId: Scalars["Int"];
};

export type QueryTaskHistoryArgs = {
  where?: Maybe<WebSuiteTaskLogFilterInput>;
  order?: Maybe<Array<WebSuiteTaskLogSortInput>>;
};

export type QueryTicketArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryTicketAssetsArgs = {
  order?: Maybe<Array<LookupAssetSortInput>>;
};

export type QueryTicketInfoArgs = {
  id: Scalars["Int"];
};

export type QueryTicketsArgs = {
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  where?: Maybe<TicketFilterInput>;
  order?: Maybe<Array<TicketSortInput>>;
};

export type QueryTubeLabellerRunsArgs = {
  method?: Maybe<Scalars["String"]>;
};

export type QueryTubeLabellerRunsByProcessArgs = {
  process?: Maybe<Scalars["String"]>;
  where?: Maybe<ProductionRunFilterInput>;
  order?: Maybe<Array<ProductionRunSortInput>>;
};

export type QueryUnavailablePlatesArgs = {
  methodId: Scalars["Int"];
};

export type QueryUserArgs = {
  id: Scalars["Int"];
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryUsersFromCacheArgs = {
  ids: Array<Scalars["Int"]>;
  keyField: Scalars["String"];
  typeName: Scalars["String"];
};

export type QueryWorklistArgs = {
  where?: Maybe<WorklistFilterInput>;
};

export type QueryWorklistToolTemplateArgs = {
  where?: Maybe<WorklistToolTemplateFilterInput>;
};

export type QueryWorklistToolTemplateContentArgs = {
  where?: Maybe<WorklistToolTemplateContentFilterInput>;
};

/** A connection to a list of items. */
export type RepeatingTasksSummaryConnection = {
  __typename?: "RepeatingTasksSummaryConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<RepeatingTasksSummaryEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<PersonalTasksRecurringTask>>>;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type RepeatingTasksSummaryEdge = {
  __typename?: "RepeatingTasksSummaryEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<PersonalTasksRecurringTask>;
};

export type RescheduleTaskInput = {
  taskId: Scalars["Int"];
  taskType: TaskTypeEnum;
  startTimeScheduled: Scalars["DateTime"];
  endTimeScheduled: Scalars["DateTime"];
  systemAssignedTo?: Maybe<Scalars["Int"]>;
};

export enum Role {
  Admin = "ADMIN",
  Engineer = "ENGINEER",
  Manager = "MANAGER",
  User = "USER",
}

export type Run = {
  __typename?: "Run";
  status: TaskStatusEnum;
  runId: Scalars["Int"];
  folderParentId: Scalars["Int"];
  runTemplateId?: Maybe<Scalars["Int"]>;
  runDateScheduled: Scalars["DateTime"];
  runTypeId?: Maybe<Scalars["Int"]>;
  runName?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  runProgress?: Maybe<Scalars["Int"]>;
  runOwner?: Maybe<Scalars["Int"]>;
  productionRunId?: Maybe<Scalars["Int"]>;
  plateCreatedId?: Maybe<Scalars["Int"]>;
  folderParent?: Maybe<FolderStructure>;
  plateCreated?: Maybe<PlatesCreated>;
  runOwnerNavigation?: Maybe<AppSuiteUser>;
  lookupTeamGanttId?: Maybe<LookupTeamGanttId>;
  runStages?: Maybe<Array<Maybe<RunStage>>>;
  runTasks?: Maybe<Array<Maybe<RunTask>>>;
  productionRun?: Maybe<ProductionRun>;
};

export type RunRunTasksArgs = {
  where?: Maybe<RunTaskFilterInput>;
  order?: Maybe<Array<RunTaskSortInput>>;
};

export type RunFilterInput = {
  and?: Maybe<Array<RunFilterInput>>;
  or?: Maybe<Array<RunFilterInput>>;
  status?: Maybe<TaskStatusEnumOperationFilterInput>;
  runId?: Maybe<ComparableInt32OperationFilterInput>;
  folderParentId?: Maybe<ComparableInt32OperationFilterInput>;
  runTemplateId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  runDateScheduled?: Maybe<ComparableDateTimeOperationFilterInput>;
  runTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  runName?: Maybe<StringOperationFilterInput>;
  comments?: Maybe<StringOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  runProgress?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  runOwner?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  productionRunId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  plateCreatedId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  folderParent?: Maybe<FolderStructureFilterInput>;
  plateCreated?: Maybe<PlatesCreatedFilterInput>;
  productionRun?: Maybe<ProductionRunFilterInput>;
  runOwnerNavigation?: Maybe<AppSuiteUserFilterInput>;
  lookupTeamGanttId?: Maybe<LookupTeamGanttIdFilterInput>;
  runStages?: Maybe<ListFilterInputTypeOfRunStageFilterInput>;
  runTasks?: Maybe<ListFilterInputTypeOfRunTaskFilterInput>;
};

export type RunSortInput = {
  status?: Maybe<SortEnumType>;
  runId?: Maybe<SortEnumType>;
  folderParentId?: Maybe<SortEnumType>;
  runTemplateId?: Maybe<SortEnumType>;
  runDateScheduled?: Maybe<SortEnumType>;
  runTypeId?: Maybe<SortEnumType>;
  runName?: Maybe<SortEnumType>;
  comments?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  runProgress?: Maybe<SortEnumType>;
  runOwner?: Maybe<SortEnumType>;
  productionRunId?: Maybe<SortEnumType>;
  plateCreatedId?: Maybe<SortEnumType>;
  folderParent?: Maybe<FolderStructureSortInput>;
  plateCreated?: Maybe<PlatesCreatedSortInput>;
  productionRun?: Maybe<ProductionRunSortInput>;
  runOwnerNavigation?: Maybe<AppSuiteUserSortInput>;
  lookupTeamGanttId?: Maybe<LookupTeamGanttIdSortInput>;
};

export type RunStage = {
  __typename?: "RunStage";
  runStageId: Scalars["Int"];
  runId: Scalars["Int"];
  runStageSeqNo?: Maybe<Scalars["Int"]>;
  runStageName?: Maybe<Scalars["String"]>;
  run?: Maybe<Run>;
  runTasks?: Maybe<Array<Maybe<RunTask>>>;
};

export type RunStageFilterInput = {
  and?: Maybe<Array<RunStageFilterInput>>;
  or?: Maybe<Array<RunStageFilterInput>>;
  runStageId?: Maybe<ComparableInt32OperationFilterInput>;
  runId?: Maybe<ComparableInt32OperationFilterInput>;
  runStageSeqNo?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  runStageName?: Maybe<StringOperationFilterInput>;
  run?: Maybe<RunFilterInput>;
  runTasks?: Maybe<ListFilterInputTypeOfRunTaskFilterInput>;
};

export type RunStageSortInput = {
  runStageId?: Maybe<SortEnumType>;
  runId?: Maybe<SortEnumType>;
  runStageSeqNo?: Maybe<SortEnumType>;
  runStageName?: Maybe<SortEnumType>;
  run?: Maybe<RunSortInput>;
};

/** A connection to a list of items. */
export type RunStagesConnection = {
  __typename?: "RunStagesConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<RunStagesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<RunStage>>>;
};

/** An edge in a connection. */
export type RunStagesEdge = {
  __typename?: "RunStagesEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<RunStage>;
};

export type RunTask = {
  __typename?: "RunTask";
  taskType: TaskTypeEnum;
  status: TaskStatusEnum;
  priority: TaskPriorityEnum;
  methodReservationPlateMetadata?: Maybe<Array<Maybe<PlateMetadata>>>;
  folderId?: Maybe<Scalars["Int"]>;
  runTaskId: Scalars["Int"];
  runId?: Maybe<Scalars["Int"]>;
  runStageId?: Maybe<Scalars["Int"]>;
  parentSubTaskId?: Maybe<Scalars["Int"]>;
  methodId?: Maybe<Scalars["Int"]>;
  runTemplateTaskId?: Maybe<Scalars["Int"]>;
  seqNo?: Maybe<Scalars["Int"]>;
  activityName?: Maybe<Scalars["String"]>;
  startTimeScheduled: Scalars["DateTime"];
  endTimeScheduled: Scalars["DateTime"];
  startTimeActual?: Maybe<Scalars["DateTime"]>;
  endTimeActual?: Maybe<Scalars["DateTime"]>;
  systemAssignedTo?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  runActivityTypeId?: Maybe<Scalars["Int"]>;
  sourcePlateFilter?: Maybe<Scalars["String"]>;
  updateActionDetails?: Maybe<Scalars["String"]>;
  lastTimeUpdated?: Maybe<Scalars["DateTime"]>;
  updatedBy?: Maybe<Scalars["Int"]>;
  completedBy?: Maybe<Scalars["Int"]>;
  completedByTime?: Maybe<Scalars["DateTime"]>;
  manualTaskTypeId?: Maybe<Scalars["Int"]>;
  completedByNavigation?: Maybe<AppSuiteUser>;
  manualTaskType?: Maybe<ManualTaskType>;
  method?: Maybe<LookupArrayMethod>;
  run?: Maybe<Run>;
  runActivityType?: Maybe<LookupRunActivityType>;
  runNavigation?: Maybe<RunStage>;
  runTemplateTask?: Maybe<RunTemplateTask>;
  systemAssignedToNavigation?: Maybe<LookupArraySystem>;
  lookupTeamGanttId?: Maybe<LookupTeamGanttId>;
  runTaskDetail?: Maybe<RunTaskDetail>;
  methodReservations?: Maybe<Array<Maybe<MethodReservation>>>;
  runTaskAttachments?: Maybe<Array<Maybe<RunTaskAttachment>>>;
  groups?: Maybe<Array<Maybe<AppSuiteGroup>>>;
  users?: Maybe<Array<Maybe<AppSuiteUser>>>;
  daysFromRunStart: Scalars["Int"];
};

export type RunTaskAttachment = {
  __typename?: "RunTaskAttachment";
  runTaskAttachmentId: Scalars["Int"];
  runTaskId: Scalars["Int"];
  attachmentName?: Maybe<Scalars["String"]>;
  dateUploaded: Scalars["DateTime"];
  uploadedBy: Scalars["Int"];
  comments?: Maybe<Scalars["String"]>;
  runTask?: Maybe<RunTask>;
  attachmentUrl?: Maybe<Scalars["String"]>;
};

export type RunTaskAttachmentFilterInput = {
  and?: Maybe<Array<RunTaskAttachmentFilterInput>>;
  or?: Maybe<Array<RunTaskAttachmentFilterInput>>;
  runTaskAttachmentId?: Maybe<ComparableInt32OperationFilterInput>;
  runTaskId?: Maybe<ComparableInt32OperationFilterInput>;
  attachmentName?: Maybe<StringOperationFilterInput>;
  attachmentFile?: Maybe<ListComparableByteOperationFilterInput>;
  dateUploaded?: Maybe<ComparableDateTimeOperationFilterInput>;
  uploadedBy?: Maybe<ComparableInt32OperationFilterInput>;
  comments?: Maybe<StringOperationFilterInput>;
  runTask?: Maybe<RunTaskFilterInput>;
};

export type RunTaskDetail = {
  __typename?: "RunTaskDetail";
  imageExportFormat?: Maybe<ImageExportFormat>;
  runTaskDetailsId: Scalars["Int"];
  runTaskId: Scalars["Int"];
  daughterWellNumber?: Maybe<Scalars["Int"]>;
  seedCellCount?: Maybe<Scalars["Int"]>;
  freezeCellCount?: Maybe<Scalars["Int"]>;
  freezeDownRackType?: Maybe<Scalars["String"]>;
  createMystplate?: Maybe<Scalars["Boolean"]>;
  destPlateCode?: Maybe<Scalars["String"]>;
  maxCryovial?: Maybe<Scalars["Int"]>;
  minCryovial?: Maybe<Scalars["Int"]>;
  overflowVials?: Maybe<Scalars["String"]>;
  imageExportSetting?: Maybe<Scalars["Int"]>;
  worklistTemplateId?: Maybe<Scalars["Int"]>;
  intermediateLabwareTypeId?: Maybe<Scalars["Int"]>;
  intermediateLabwareType?: Maybe<LookupLabwareType>;
  runTask?: Maybe<RunTask>;
};

export type RunTaskDetailFilterInput = {
  and?: Maybe<Array<RunTaskDetailFilterInput>>;
  or?: Maybe<Array<RunTaskDetailFilterInput>>;
  imageExportFormat?: Maybe<NullableOfImageExportFormatOperationFilterInput>;
  runTaskDetailsId?: Maybe<ComparableInt32OperationFilterInput>;
  runTaskId?: Maybe<ComparableInt32OperationFilterInput>;
  daughterWellNumber?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  seedCellCount?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  freezeCellCount?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  freezeDownRackType?: Maybe<StringOperationFilterInput>;
  createMystplate?: Maybe<BooleanOperationFilterInput>;
  destPlateCode?: Maybe<StringOperationFilterInput>;
  maxCryovial?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  minCryovial?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  overflowVials?: Maybe<StringOperationFilterInput>;
  imageExportSetting?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  worklistTemplateId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  intermediateLabwareTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  intermediateLabwareType?: Maybe<LookupLabwareTypeFilterInput>;
  runTask?: Maybe<RunTaskFilterInput>;
};

export type RunTaskDetailInput = {
  runTaskDetailId?: Maybe<Scalars["Int"]>;
  parentId?: Maybe<Scalars["Int"]>;
  daughterWellNumber?: Maybe<Scalars["Int"]>;
  seedCellCount?: Maybe<Scalars["Int"]>;
  freezeCellCount?: Maybe<Scalars["Int"]>;
  freezeDownRackType?: Maybe<Scalars["String"]>;
  createMystplate?: Maybe<Scalars["Boolean"]>;
  destPlateCode?: Maybe<Scalars["String"]>;
  maxCryovial?: Maybe<Scalars["Int"]>;
  minCryovial?: Maybe<Scalars["Int"]>;
  overflowVials?: Maybe<Scalars["String"]>;
  imageExportSetting?: Maybe<Scalars["Int"]>;
  imageExportFormat?: Maybe<ImageExportFormat>;
  worklistTemplateId?: Maybe<Scalars["Int"]>;
  intermediateLabwareTypeId?: Maybe<Scalars["Int"]>;
};

export type RunTaskDetailSortInput = {
  imageExportFormat?: Maybe<SortEnumType>;
  runTaskDetailsId?: Maybe<SortEnumType>;
  runTaskId?: Maybe<SortEnumType>;
  daughterWellNumber?: Maybe<SortEnumType>;
  seedCellCount?: Maybe<SortEnumType>;
  freezeCellCount?: Maybe<SortEnumType>;
  freezeDownRackType?: Maybe<SortEnumType>;
  createMystplate?: Maybe<SortEnumType>;
  destPlateCode?: Maybe<SortEnumType>;
  maxCryovial?: Maybe<SortEnumType>;
  minCryovial?: Maybe<SortEnumType>;
  overflowVials?: Maybe<SortEnumType>;
  imageExportSetting?: Maybe<SortEnumType>;
  worklistTemplateId?: Maybe<SortEnumType>;
  intermediateLabwareTypeId?: Maybe<SortEnumType>;
  intermediateLabwareType?: Maybe<LookupLabwareTypeSortInput>;
  runTask?: Maybe<RunTaskSortInput>;
};

export type RunTaskFilterInput = {
  and?: Maybe<Array<RunTaskFilterInput>>;
  or?: Maybe<Array<RunTaskFilterInput>>;
  taskType?: Maybe<TaskTypeEnumOperationFilterInput>;
  status?: Maybe<TaskStatusEnumOperationFilterInput>;
  priority?: Maybe<TaskPriorityEnumOperationFilterInput>;
  methodReservationPlateMetadata?: Maybe<ListFilterInputTypeOfPlateMetadataFilterInput>;
  folderId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  runTaskId?: Maybe<ComparableInt32OperationFilterInput>;
  runId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  runStageId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  parentSubTaskId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  methodId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  runTemplateTaskId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  seqNo?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  activityName?: Maybe<StringOperationFilterInput>;
  startTimeScheduled?: Maybe<ComparableDateTimeOperationFilterInput>;
  endTimeScheduled?: Maybe<ComparableDateTimeOperationFilterInput>;
  startTimeActual?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  endTimeActual?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  systemAssignedTo?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  notes?: Maybe<StringOperationFilterInput>;
  runActivityTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  sourcePlateFilter?: Maybe<StringOperationFilterInput>;
  updateActionDetails?: Maybe<StringOperationFilterInput>;
  lastTimeUpdated?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updatedBy?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  completedBy?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  completedByTime?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  manualTaskTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  completedByNavigation?: Maybe<AppSuiteUserFilterInput>;
  manualTaskType?: Maybe<ManualTaskTypeFilterInput>;
  method?: Maybe<LookupArrayMethodFilterInput>;
  run?: Maybe<RunFilterInput>;
  runActivityType?: Maybe<LookupRunActivityTypeFilterInput>;
  runNavigation?: Maybe<RunStageFilterInput>;
  runTemplateTask?: Maybe<RunTemplateTaskFilterInput>;
  systemAssignedToNavigation?: Maybe<LookupArraySystemFilterInput>;
  lookupTeamGanttId?: Maybe<LookupTeamGanttIdFilterInput>;
  runTaskDetail?: Maybe<RunTaskDetailFilterInput>;
  methodReservations?: Maybe<ListFilterInputTypeOfMethodReservationFilterInput>;
  runTaskAttachments?: Maybe<ListFilterInputTypeOfRunTaskAttachmentFilterInput>;
  groups?: Maybe<ListFilterInputTypeOfAppSuiteGroupFilterInput>;
  users?: Maybe<ListFilterInputTypeOfAppSuiteUserFilterInput>;
};

export type RunTaskInput = {
  runActivityId?: Maybe<Scalars["Int"]>;
  runId?: Maybe<Scalars["Int"]>;
  runStageId?: Maybe<Scalars["Int"]>;
  parentSubTaskId?: Maybe<Scalars["Int"]>;
  taskTypeDetails?: Maybe<TaskTypeDetailInput>;
  runTemplateTaskId?: Maybe<Scalars["Int"]>;
  seqNo?: Maybe<Scalars["Int"]>;
  activityName?: Maybe<Scalars["String"]>;
  startTimeScheduled?: Maybe<Scalars["DateTime"]>;
  endTimeScheduled?: Maybe<Scalars["DateTime"]>;
  startTimeActual?: Maybe<Scalars["DateTime"]>;
  endTimeActual?: Maybe<Scalars["DateTime"]>;
  runTaskAssignedGroups?: Maybe<Array<Scalars["Int"]>>;
  runTaskAssignedUsers?: Maybe<Array<Scalars["Int"]>>;
  systemAssignedTo?: Maybe<Scalars["Int"]>;
  priority?: Maybe<TaskPriorityEnum>;
  status?: Maybe<TaskStatusEnum>;
  notes?: Maybe<Scalars["String"]>;
  runActivityTypeId?: Maybe<Scalars["Int"]>;
  sourcePlateFilter?: Maybe<Scalars["String"]>;
  updateActionDetails?: Maybe<Scalars["String"]>;
  lastTimeUpdated?: Maybe<Scalars["DateTime"]>;
  updatedBy?: Maybe<Scalars["Int"]>;
  completedBy?: Maybe<Scalars["Int"]>;
  completedByTime?: Maybe<Scalars["DateTime"]>;
  details?: Maybe<RunTaskDetailInput>;
  methodReservationPlateMetadata?: Maybe<Array<PlateMetadataInput>>;
};

export type RunTaskSortInput = {
  taskType?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  priority?: Maybe<SortEnumType>;
  folderId?: Maybe<SortEnumType>;
  runTaskId?: Maybe<SortEnumType>;
  runId?: Maybe<SortEnumType>;
  runStageId?: Maybe<SortEnumType>;
  parentSubTaskId?: Maybe<SortEnumType>;
  methodId?: Maybe<SortEnumType>;
  runTemplateTaskId?: Maybe<SortEnumType>;
  seqNo?: Maybe<SortEnumType>;
  activityName?: Maybe<SortEnumType>;
  startTimeScheduled?: Maybe<SortEnumType>;
  endTimeScheduled?: Maybe<SortEnumType>;
  startTimeActual?: Maybe<SortEnumType>;
  endTimeActual?: Maybe<SortEnumType>;
  systemAssignedTo?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  runActivityTypeId?: Maybe<SortEnumType>;
  sourcePlateFilter?: Maybe<SortEnumType>;
  updateActionDetails?: Maybe<SortEnumType>;
  lastTimeUpdated?: Maybe<SortEnumType>;
  updatedBy?: Maybe<SortEnumType>;
  completedBy?: Maybe<SortEnumType>;
  completedByTime?: Maybe<SortEnumType>;
  manualTaskTypeId?: Maybe<SortEnumType>;
  completedByNavigation?: Maybe<AppSuiteUserSortInput>;
  manualTaskType?: Maybe<ManualTaskTypeSortInput>;
  method?: Maybe<LookupArrayMethodSortInput>;
  run?: Maybe<RunSortInput>;
  runActivityType?: Maybe<LookupRunActivityTypeSortInput>;
  runNavigation?: Maybe<RunStageSortInput>;
  runTemplateTask?: Maybe<RunTemplateTaskSortInput>;
  systemAssignedToNavigation?: Maybe<LookupArraySystemSortInput>;
  lookupTeamGanttId?: Maybe<LookupTeamGanttIdSortInput>;
  runTaskDetail?: Maybe<RunTaskDetailSortInput>;
};

export type RunTaskType = {
  __typename?: "RunTaskType";
  type: TaskSelectionTypeEnum;
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  documentationUrl?: Maybe<Scalars["String"]>;
  automationMethodId?: Maybe<Scalars["Int"]>;
  compositeKey?: Maybe<Scalars["String"]>;
  manualTaskType?: Maybe<ManualTaskType>;
  method?: Maybe<Array<Maybe<LookupArrayMethod>>>;
};

export type RunTaskTypeSortInput = {
  type?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  documentationUrl?: Maybe<SortEnumType>;
  automationMethodId?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type RunTasksConnection = {
  __typename?: "RunTasksConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<RunTasksEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<RunTask>>>;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type RunTasksEdge = {
  __typename?: "RunTasksEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<RunTask>;
};

export type RunTemplate = {
  __typename?: "RunTemplate";
  runTemplateId: Scalars["Int"];
  runTemplateName?: Maybe<Scalars["String"]>;
  productionRunType?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Scalars["Int"]>;
  folderLocationId?: Maybe<Scalars["Int"]>;
  productionRunTypeId?: Maybe<Scalars["Int"]>;
  createdByV?: Maybe<Scalars["String"]>;
  lastUpdatedBy?: Maybe<Scalars["Int"]>;
  lastTimeUpdated?: Maybe<Scalars["DateTime"]>;
  createdByNavigation?: Maybe<AppSuiteUser>;
  folderLocation?: Maybe<FolderStructure>;
  lastUpdatedByNavigation?: Maybe<AppSuiteUser>;
  productionRunTypeNavigation?: Maybe<ProductionRunType>;
  lookupArrayPlateTypeStrings?: Maybe<Array<Maybe<LookupArrayPlateTypeString>>>;
  productionRunTypes?: Maybe<Array<Maybe<ProductionRunType>>>;
  runTemplateStages?: Maybe<Array<Maybe<RunTemplateStage>>>;
  runTemplateTasks?: Maybe<Array<Maybe<RunTemplateTask>>>;
  runTemplateTreeNodeChildren?: Maybe<Array<Maybe<RunTemplateTreeNode>>>;
  runTemplateTreeNodeParents?: Maybe<Array<Maybe<RunTemplateTreeNode>>>;
  groups?: Maybe<Array<Maybe<AppSuiteGroup>>>;
  numberOfSubTemplates: Scalars["Int"];
  numberOfTasks: Scalars["Int"];
};

export type RunTemplateFilterInput = {
  and?: Maybe<Array<RunTemplateFilterInput>>;
  or?: Maybe<Array<RunTemplateFilterInput>>;
  runTemplateId?: Maybe<ComparableInt32OperationFilterInput>;
  runTemplateName?: Maybe<StringOperationFilterInput>;
  productionRunType?: Maybe<StringOperationFilterInput>;
  createdBy?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  folderLocationId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  productionRunTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  createdByV?: Maybe<StringOperationFilterInput>;
  lastUpdatedBy?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  lastTimeUpdated?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  createdByNavigation?: Maybe<AppSuiteUserFilterInput>;
  folderLocation?: Maybe<FolderStructureFilterInput>;
  lastUpdatedByNavigation?: Maybe<AppSuiteUserFilterInput>;
  productionRunTypeNavigation?: Maybe<ProductionRunTypeFilterInput>;
  lookupArrayPlateTypeStrings?: Maybe<ListFilterInputTypeOfLookupArrayPlateTypeStringFilterInput>;
  productionRunTypes?: Maybe<ListFilterInputTypeOfProductionRunTypeFilterInput>;
  runTemplateStages?: Maybe<ListFilterInputTypeOfRunTemplateStageFilterInput>;
  runTemplateTasks?: Maybe<ListFilterInputTypeOfRunTemplateTaskFilterInput>;
  runTemplateTreeNodeChildren?: Maybe<ListFilterInputTypeOfRunTemplateTreeNodeFilterInput>;
  runTemplateTreeNodeParents?: Maybe<ListFilterInputTypeOfRunTemplateTreeNodeFilterInput>;
  groups?: Maybe<ListFilterInputTypeOfAppSuiteGroupFilterInput>;
};

export type RunTemplateOption = {
  __typename?: "RunTemplateOption";
  runTemplateId: Scalars["Int"];
  runTemplateName?: Maybe<Scalars["String"]>;
};

export type RunTemplateOptionFilterInput = {
  and?: Maybe<Array<RunTemplateOptionFilterInput>>;
  or?: Maybe<Array<RunTemplateOptionFilterInput>>;
  runTemplateId?: Maybe<ComparableInt32OperationFilterInput>;
  runTemplateName?: Maybe<StringOperationFilterInput>;
};

export type RunTemplateOptionSortInput = {
  runTemplateId?: Maybe<SortEnumType>;
  runTemplateName?: Maybe<SortEnumType>;
};

export type RunTemplateSortInput = {
  runTemplateId?: Maybe<SortEnumType>;
  runTemplateName?: Maybe<SortEnumType>;
  productionRunType?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  folderLocationId?: Maybe<SortEnumType>;
  productionRunTypeId?: Maybe<SortEnumType>;
  createdByV?: Maybe<SortEnumType>;
  lastUpdatedBy?: Maybe<SortEnumType>;
  lastTimeUpdated?: Maybe<SortEnumType>;
  createdByNavigation?: Maybe<AppSuiteUserSortInput>;
  folderLocation?: Maybe<FolderStructureSortInput>;
  lastUpdatedByNavigation?: Maybe<AppSuiteUserSortInput>;
  productionRunTypeNavigation?: Maybe<ProductionRunTypeSortInput>;
};

export type RunTemplateStage = {
  __typename?: "RunTemplateStage";
  runTemplateStageId: Scalars["Int"];
  runTemplateId: Scalars["Int"];
  stageSeqNo?: Maybe<Scalars["Int"]>;
  templateStageName?: Maybe<Scalars["String"]>;
  runTemplate?: Maybe<RunTemplate>;
  runTemplateTasks?: Maybe<Array<Maybe<RunTemplateTask>>>;
  runTemplateTreeNodes?: Maybe<Array<Maybe<RunTemplateTreeNode>>>;
};

export type RunTemplateStageFilterInput = {
  and?: Maybe<Array<RunTemplateStageFilterInput>>;
  or?: Maybe<Array<RunTemplateStageFilterInput>>;
  runTemplateStageId?: Maybe<ComparableInt32OperationFilterInput>;
  runTemplateId?: Maybe<ComparableInt32OperationFilterInput>;
  stageSeqNo?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  templateStageName?: Maybe<StringOperationFilterInput>;
  runTemplate?: Maybe<RunTemplateFilterInput>;
  runTemplateTasks?: Maybe<ListFilterInputTypeOfRunTemplateTaskFilterInput>;
  runTemplateTreeNodes?: Maybe<ListFilterInputTypeOfRunTemplateTreeNodeFilterInput>;
};

export type RunTemplateStageSortInput = {
  runTemplateStageId?: Maybe<SortEnumType>;
  runTemplateId?: Maybe<SortEnumType>;
  stageSeqNo?: Maybe<SortEnumType>;
  templateStageName?: Maybe<SortEnumType>;
  runTemplate?: Maybe<RunTemplateSortInput>;
};

export type RunTemplateTask = {
  __typename?: "RunTemplateTask";
  runTemplateTaskId: Scalars["Int"];
  runTemplateParentTaskId?: Maybe<Scalars["Int"]>;
  templateTaskSeqNo?: Maybe<Scalars["Int"]>;
  runTemplateStageId?: Maybe<Scalars["Int"]>;
  runTemplateId?: Maybe<Scalars["Int"]>;
  customTaskName?: Maybe<Scalars["String"]>;
  dayScheduled: Scalars["Int"];
  systemAssignedTo?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  methodId?: Maybe<Scalars["Int"]>;
  manualTaskTypeId?: Maybe<Scalars["Int"]>;
  manualTaskType?: Maybe<ManualTaskType>;
  method?: Maybe<LookupArrayMethod>;
  runTemplate?: Maybe<RunTemplate>;
  runTemplateStage?: Maybe<RunTemplateStage>;
  systemAssignedToNavigation?: Maybe<LookupArraySystem>;
  runTemplateTaskDetail?: Maybe<RunTemplateTaskDetail>;
  runTasks?: Maybe<Array<Maybe<RunTask>>>;
  runTemplatesRepeatDaysOfWeeks?: Maybe<
    Array<Maybe<RunTemplatesRepeatDaysOfWeek>>
  >;
  groups?: Maybe<Array<Maybe<AppSuiteGroup>>>;
  users?: Maybe<Array<Maybe<AppSuiteUser>>>;
};

export type RunTemplateTaskRunTasksArgs = {
  where?: Maybe<RunTaskFilterInput>;
};

export type RunTemplateTaskDetail = {
  __typename?: "RunTemplateTaskDetail";
  imageExportFormat?: Maybe<ImageExportFormat>;
  runTemplateTaskDetailsId: Scalars["Int"];
  runTemplateTaskId: Scalars["Int"];
  daughterWellNumber?: Maybe<Scalars["Int"]>;
  seedCellCount?: Maybe<Scalars["Int"]>;
  freezeCellCount?: Maybe<Scalars["Int"]>;
  freezeDownRackType?: Maybe<Scalars["String"]>;
  createMystplate?: Maybe<Scalars["Boolean"]>;
  destPlateCode?: Maybe<Scalars["String"]>;
  maxCryovial?: Maybe<Scalars["Int"]>;
  minCryovial?: Maybe<Scalars["Int"]>;
  overflowVials?: Maybe<Scalars["String"]>;
  imageExportSetting?: Maybe<Scalars["Int"]>;
  worklistTemplateId?: Maybe<Scalars["Int"]>;
  intermediateLabwareTypeId?: Maybe<Scalars["Int"]>;
  intermediateLabwareType?: Maybe<LookupLabwareType>;
  runTemplateTask?: Maybe<RunTemplateTask>;
};

export type RunTemplateTaskDetailFilterInput = {
  and?: Maybe<Array<RunTemplateTaskDetailFilterInput>>;
  or?: Maybe<Array<RunTemplateTaskDetailFilterInput>>;
  imageExportFormat?: Maybe<NullableOfImageExportFormatOperationFilterInput>;
  runTemplateTaskDetailsId?: Maybe<ComparableInt32OperationFilterInput>;
  runTemplateTaskId?: Maybe<ComparableInt32OperationFilterInput>;
  daughterWellNumber?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  seedCellCount?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  freezeCellCount?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  freezeDownRackType?: Maybe<StringOperationFilterInput>;
  createMystplate?: Maybe<BooleanOperationFilterInput>;
  destPlateCode?: Maybe<StringOperationFilterInput>;
  maxCryovial?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  minCryovial?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  overflowVials?: Maybe<StringOperationFilterInput>;
  imageExportSetting?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  worklistTemplateId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  intermediateLabwareTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  intermediateLabwareType?: Maybe<LookupLabwareTypeFilterInput>;
  runTemplateTask?: Maybe<RunTemplateTaskFilterInput>;
};

export type RunTemplateTaskDetailSortInput = {
  imageExportFormat?: Maybe<SortEnumType>;
  runTemplateTaskDetailsId?: Maybe<SortEnumType>;
  runTemplateTaskId?: Maybe<SortEnumType>;
  daughterWellNumber?: Maybe<SortEnumType>;
  seedCellCount?: Maybe<SortEnumType>;
  freezeCellCount?: Maybe<SortEnumType>;
  freezeDownRackType?: Maybe<SortEnumType>;
  createMystplate?: Maybe<SortEnumType>;
  destPlateCode?: Maybe<SortEnumType>;
  maxCryovial?: Maybe<SortEnumType>;
  minCryovial?: Maybe<SortEnumType>;
  overflowVials?: Maybe<SortEnumType>;
  imageExportSetting?: Maybe<SortEnumType>;
  worklistTemplateId?: Maybe<SortEnumType>;
  intermediateLabwareTypeId?: Maybe<SortEnumType>;
  intermediateLabwareType?: Maybe<LookupLabwareTypeSortInput>;
  runTemplateTask?: Maybe<RunTemplateTaskSortInput>;
};

export type RunTemplateTaskFilterInput = {
  and?: Maybe<Array<RunTemplateTaskFilterInput>>;
  or?: Maybe<Array<RunTemplateTaskFilterInput>>;
  runTemplateTaskId?: Maybe<ComparableInt32OperationFilterInput>;
  runTemplateParentTaskId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  templateTaskSeqNo?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  runTemplateStageId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  runTemplateId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  customTaskName?: Maybe<StringOperationFilterInput>;
  dayScheduled?: Maybe<ComparableInt32OperationFilterInput>;
  systemAssignedTo?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  notes?: Maybe<StringOperationFilterInput>;
  methodId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  manualTaskTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  manualTaskType?: Maybe<ManualTaskTypeFilterInput>;
  method?: Maybe<LookupArrayMethodFilterInput>;
  runTemplate?: Maybe<RunTemplateFilterInput>;
  runTemplateStage?: Maybe<RunTemplateStageFilterInput>;
  systemAssignedToNavigation?: Maybe<LookupArraySystemFilterInput>;
  runTemplateTaskDetail?: Maybe<RunTemplateTaskDetailFilterInput>;
  runTasks?: Maybe<ListFilterInputTypeOfRunTaskFilterInput>;
  runTemplatesRepeatDaysOfWeeks?: Maybe<ListFilterInputTypeOfRunTemplatesRepeatDaysOfWeekFilterInput>;
  groups?: Maybe<ListFilterInputTypeOfAppSuiteGroupFilterInput>;
  users?: Maybe<ListFilterInputTypeOfAppSuiteUserFilterInput>;
};

export type RunTemplateTaskInput = {
  runTemplateTaskId?: Maybe<Scalars["Int"]>;
  runTemplateParentTaskId?: Maybe<Scalars["Int"]>;
  templateTaskSeqNo?: Maybe<Scalars["Int"]>;
  runTemplateStageId?: Maybe<Scalars["Int"]>;
  runTemplateId?: Maybe<Scalars["Int"]>;
  taskTypeDetails?: Maybe<TaskTypeDetailInput>;
  customTaskName?: Maybe<Scalars["String"]>;
  dayScheduled: Scalars["Int"];
  userIds?: Maybe<Array<Scalars["Int"]>>;
  groupIds?: Maybe<Array<Scalars["Int"]>>;
  systemAssignedTo?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  intermediateLabwareTypeId?: Maybe<Scalars["Int"]>;
  details?: Maybe<RunTaskDetailInput>;
  runTemplatesRepeatDaysOfWeeks?: Maybe<
    Array<RunTemplatesRepeatDaysOfWeekInput>
  >;
};

export type RunTemplateTaskSortInput = {
  runTemplateTaskId?: Maybe<SortEnumType>;
  runTemplateParentTaskId?: Maybe<SortEnumType>;
  templateTaskSeqNo?: Maybe<SortEnumType>;
  runTemplateStageId?: Maybe<SortEnumType>;
  runTemplateId?: Maybe<SortEnumType>;
  customTaskName?: Maybe<SortEnumType>;
  dayScheduled?: Maybe<SortEnumType>;
  systemAssignedTo?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  methodId?: Maybe<SortEnumType>;
  manualTaskTypeId?: Maybe<SortEnumType>;
  manualTaskType?: Maybe<ManualTaskTypeSortInput>;
  method?: Maybe<LookupArrayMethodSortInput>;
  runTemplate?: Maybe<RunTemplateSortInput>;
  runTemplateStage?: Maybe<RunTemplateStageSortInput>;
  systemAssignedToNavigation?: Maybe<LookupArraySystemSortInput>;
  runTemplateTaskDetail?: Maybe<RunTemplateTaskDetailSortInput>;
};

export type RunTemplateTreeNode = {
  __typename?: "RunTemplateTreeNode";
  nodeId: Scalars["Int"];
  parentId: Scalars["Int"];
  childId: Scalars["Int"];
  child?: Maybe<RunTemplate>;
  parent?: Maybe<RunTemplate>;
  stage?: Maybe<RunTemplateStage>;
  stageId: Scalars["Int"];
  dayScheduled: Scalars["Int"];
};

export type RunTemplateTreeNodeFilterInput = {
  and?: Maybe<Array<RunTemplateTreeNodeFilterInput>>;
  or?: Maybe<Array<RunTemplateTreeNodeFilterInput>>;
  nodeId?: Maybe<ComparableInt32OperationFilterInput>;
  stageId?: Maybe<ComparableInt32OperationFilterInput>;
  parentId?: Maybe<ComparableInt32OperationFilterInput>;
  childId?: Maybe<ComparableInt32OperationFilterInput>;
  startDay?: Maybe<ComparableInt32OperationFilterInput>;
  child?: Maybe<RunTemplateFilterInput>;
  parent?: Maybe<RunTemplateFilterInput>;
  stage?: Maybe<RunTemplateStageFilterInput>;
};

/** A connection to a list of items. */
export type RunTemplatesConnection = {
  __typename?: "RunTemplatesConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<RunTemplatesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<RunTemplate>>>;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type RunTemplatesEdge = {
  __typename?: "RunTemplatesEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<RunTemplate>;
};

export type RunTemplatesRepeatDaysOfWeek = {
  __typename?: "RunTemplatesRepeatDaysOfWeek";
  dayOfWeek?: Maybe<DayOfWeek>;
  runTemplateRepeatDayOfWeekId: Scalars["Int"];
  runTemplateTaskId?: Maybe<Scalars["Int"]>;
  numberOfWeeks: Scalars["Int"];
  runTemplateTask?: Maybe<RunTemplateTask>;
};

export type RunTemplatesRepeatDaysOfWeekFilterInput = {
  and?: Maybe<Array<RunTemplatesRepeatDaysOfWeekFilterInput>>;
  or?: Maybe<Array<RunTemplatesRepeatDaysOfWeekFilterInput>>;
  dayOfWeek?: Maybe<NullableOfDayOfWeekOperationFilterInput>;
  runTemplateRepeatDayOfWeekId?: Maybe<ComparableInt32OperationFilterInput>;
  runTemplateTaskId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  numberOfWeeks?: Maybe<ComparableInt32OperationFilterInput>;
  runTemplateTask?: Maybe<RunTemplateTaskFilterInput>;
};

export type RunTemplatesRepeatDaysOfWeekInput = {
  runTemplateRepeatDayOfWeekId?: Maybe<Scalars["Int"]>;
  runTemplateTaskId?: Maybe<Scalars["Int"]>;
  dayOfWeek?: Maybe<DayOfWeek>;
  numberOfWeeks?: Maybe<Scalars["Int"]>;
};

export type Sample = {
  __typename?: "Sample";
  sampleId: Scalars["Int"];
  sampleBarcode?: Maybe<Scalars["String"]>;
  sampleBarcodeAliasOld?: Maybe<Scalars["String"]>;
  sampleBarcodeAliases?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  cellLineId: Scalars["Int"];
  sampleTypeId: Scalars["Int"];
  dateAdded: Scalars["DateTime"];
  registeredBy?: Maybe<Scalars["String"]>;
  registeredByUserId?: Maybe<Scalars["Int"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  isVisible?: Maybe<Scalars["Boolean"]>;
  passageNumberAtThaw?: Maybe<Scalars["Int"]>;
  collectionTubeBarcode?: Maybe<Scalars["String"]>;
  collectionContainerType?: Maybe<Scalars["String"]>;
  collectionCells?: Maybe<Scalars["Int"]>;
  mLcollectionVolume?: Maybe<Scalars["Decimal"]>;
  mLplasmaVolume?: Maybe<Scalars["Decimal"]>;
  mLprocessingVolume?: Maybe<Scalars["Decimal"]>;
  receivedAt?: Maybe<Scalars["DateTime"]>;
  processingStartedAt?: Maybe<Scalars["DateTime"]>;
  processingEndedAt?: Maybe<Scalars["DateTime"]>;
  isGenotyped?: Maybe<Scalars["Boolean"]>;
  isKaryotyped?: Maybe<Scalars["Boolean"]>;
  mycoplasmaTestDate?: Maybe<Scalars["DateTime"]>;
  mycoplasmaResults?: Maybe<Scalars["String"]>;
  parentSampleId?: Maybe<Scalars["Int"]>;
  secondParentSampleId?: Maybe<Scalars["Int"]>;
  dateLastUpdated?: Maybe<Scalars["DateTime"]>;
  updatedBy?: Maybe<Scalars["String"]>;
  temporaryCreation?: Maybe<Scalars["Boolean"]>;
  createdByMappingFileId?: Maybe<Scalars["Int"]>;
  collaboratorSampleId?: Maybe<Scalars["String"]>;
  idftraceId?: Maybe<Scalars["UUID"]>;
  cellLine?: Maybe<CellLine>;
  sampleType?: Maybe<LookupSampleType>;
  containers?: Maybe<Array<Maybe<Container>>>;
  matricesTube?: Maybe<Array<Maybe<MatrixTube>>>;
  tubeBarcodes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  plateBarcodes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  lotNumbers?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type SampleContainersArgs = {
  containerFilterInput?: Maybe<SampleContainerFilterInput>;
  take?: Scalars["Int"];
  where?: Maybe<ContainerFilterInput>;
};

export type SampleTubeBarcodesArgs = {
  barcode?: Maybe<Scalars["String"]>;
  take?: Scalars["Int"];
};

export type SamplePlateBarcodesArgs = {
  barcode?: Maybe<Scalars["String"]>;
  take?: Scalars["Int"];
};

export type SampleLotNumbersArgs = {
  lotNumber?: Maybe<Scalars["String"]>;
  take?: Scalars["Int"];
};

export type SampleContainerFilterInput = {
  plateBarcode?: Maybe<Scalars["String"]>;
  lotNumber?: Maybe<Scalars["String"]>;
  tubeBarcode?: Maybe<Scalars["String"]>;
};

export type SampleFilterInput = {
  and?: Maybe<Array<SampleFilterInput>>;
  or?: Maybe<Array<SampleFilterInput>>;
  sampleId?: Maybe<ComparableInt32OperationFilterInput>;
  sampleBarcode?: Maybe<StringOperationFilterInput>;
  sampleBarcodeAliasOld?: Maybe<StringOperationFilterInput>;
  sampleBarcodeAliases?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  cellLineId?: Maybe<ComparableInt32OperationFilterInput>;
  sampleTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  dateAdded?: Maybe<ComparableDateTimeOperationFilterInput>;
  registeredBy?: Maybe<StringOperationFilterInput>;
  registeredByUserId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  isVisible?: Maybe<BooleanOperationFilterInput>;
  passageNumberAtThaw?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  collectionTubeBarcode?: Maybe<StringOperationFilterInput>;
  collectionContainerType?: Maybe<StringOperationFilterInput>;
  collectionCells?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  mLcollectionVolume?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  mLplasmaVolume?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  mLprocessingVolume?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  receivedAt?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  processingStartedAt?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  processingEndedAt?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isGenotyped?: Maybe<BooleanOperationFilterInput>;
  isKaryotyped?: Maybe<BooleanOperationFilterInput>;
  mycoplasmaTestDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  mycoplasmaResults?: Maybe<StringOperationFilterInput>;
  parentSampleId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  secondParentSampleId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  dateLastUpdated?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  updatedBy?: Maybe<StringOperationFilterInput>;
  temporaryCreation?: Maybe<BooleanOperationFilterInput>;
  createdByMappingFileId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  collaboratorSampleId?: Maybe<StringOperationFilterInput>;
  idftraceId?: Maybe<ComparableNullableOfGuidOperationFilterInput>;
  cellLine?: Maybe<CellLineFilterInput>;
  sampleType?: Maybe<LookupSampleTypeFilterInput>;
  containers?: Maybe<ListFilterInputTypeOfContainerFilterInput>;
  matricesTube?: Maybe<ListFilterInputTypeOfMatrixTubeFilterInput>;
};

export type SampleSortInput = {
  sampleId?: Maybe<SortEnumType>;
  sampleBarcode?: Maybe<SortEnumType>;
  sampleBarcodeAliasOld?: Maybe<SortEnumType>;
  sampleBarcodeAliases?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  cellLineId?: Maybe<SortEnumType>;
  sampleTypeId?: Maybe<SortEnumType>;
  dateAdded?: Maybe<SortEnumType>;
  registeredBy?: Maybe<SortEnumType>;
  registeredByUserId?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  isVisible?: Maybe<SortEnumType>;
  passageNumberAtThaw?: Maybe<SortEnumType>;
  collectionTubeBarcode?: Maybe<SortEnumType>;
  collectionContainerType?: Maybe<SortEnumType>;
  collectionCells?: Maybe<SortEnumType>;
  mLcollectionVolume?: Maybe<SortEnumType>;
  mLplasmaVolume?: Maybe<SortEnumType>;
  mLprocessingVolume?: Maybe<SortEnumType>;
  receivedAt?: Maybe<SortEnumType>;
  processingStartedAt?: Maybe<SortEnumType>;
  processingEndedAt?: Maybe<SortEnumType>;
  isGenotyped?: Maybe<SortEnumType>;
  isKaryotyped?: Maybe<SortEnumType>;
  mycoplasmaTestDate?: Maybe<SortEnumType>;
  mycoplasmaResults?: Maybe<SortEnumType>;
  parentSampleId?: Maybe<SortEnumType>;
  secondParentSampleId?: Maybe<SortEnumType>;
  dateLastUpdated?: Maybe<SortEnumType>;
  updatedBy?: Maybe<SortEnumType>;
  temporaryCreation?: Maybe<SortEnumType>;
  createdByMappingFileId?: Maybe<SortEnumType>;
  collaboratorSampleId?: Maybe<SortEnumType>;
  idftraceId?: Maybe<SortEnumType>;
  cellLine?: Maybe<CellLineSortInput>;
  sampleType?: Maybe<LookupSampleTypeSortInput>;
};

export type SampleTypeCollectionTask = {
  __typename?: "SampleTypeCollectionTask";
  id: Scalars["Int"];
  sampleTypeId: Scalars["Int"];
  taskName?: Maybe<Scalars["String"]>;
  seqNo: Scalars["Int"];
  day: Scalars["Int"];
  sampleType?: Maybe<LookupSampleType>;
};

export type SampleTypeCollectionTaskFilterInput = {
  and?: Maybe<Array<SampleTypeCollectionTaskFilterInput>>;
  or?: Maybe<Array<SampleTypeCollectionTaskFilterInput>>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  sampleTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  taskName?: Maybe<StringOperationFilterInput>;
  seqNo?: Maybe<ComparableInt32OperationFilterInput>;
  day?: Maybe<ComparableInt32OperationFilterInput>;
  sampleType?: Maybe<LookupSampleTypeFilterInput>;
};

export type SaveWorklistTemplateInput = {
  worklistToolTemplateId?: Maybe<Scalars["Int"]>;
  templateName?: Maybe<Scalars["String"]>;
  methodId: Scalars["Int"];
  sourcePlateInfo?: Maybe<Array<Maybe<PlateInfoInput>>>;
  intPlateInfo?: Maybe<Array<Maybe<PlateInfoInput>>>;
  destPlateInfo?: Maybe<Array<Maybe<PlateInfoInput>>>;
  deadPlateInfo?: Maybe<Array<Maybe<PlateInfoInput>>>;
  worklistToolTemplateContentsInput?: Maybe<
    Array<Maybe<WorklistToolTemplateContentsInput>>
  >;
};

export type SelectedTaskInput = {
  taskId: Scalars["Int"];
  taskType: TaskTypeEnum;
};

export enum SortEnumType {
  Asc = "ASC",
  Desc = "DESC",
}

export type SourcePlateInfoInput = {
  plateBarcode?: Maybe<Scalars["String"]>;
  plateType?: Maybe<Scalars["String"]>;
};

export type StartPersonalTaskTemplateInput = {
  personalTaskTemplateId: Scalars["Int"];
  startDate: Scalars["DateTime"];
};

export type StringOperationFilterInput = {
  and?: Maybe<Array<StringOperationFilterInput>>;
  or?: Maybe<Array<StringOperationFilterInput>>;
  eq?: Maybe<Scalars["String"]>;
  neq?: Maybe<Scalars["String"]>;
  contains?: Maybe<Scalars["String"]>;
  ncontains?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Maybe<Scalars["String"]>>>;
  nin?: Maybe<Array<Maybe<Scalars["String"]>>>;
  startsWith?: Maybe<Scalars["String"]>;
  nstartsWith?: Maybe<Scalars["String"]>;
  endsWith?: Maybe<Scalars["String"]>;
  nendsWith?: Maybe<Scalars["String"]>;
};

export type SystemOneFeedPlates = {
  __typename?: "SystemOneFeedPlates";
  plateID: Scalars["Int"];
  plateBarcode?: Maybe<Scalars["String"]>;
  activeWellCount: Scalars["Int"];
};

export type SystemOneFeedPlatesInput = {
  plateID: Scalars["Int"];
  plateBarcode?: Maybe<Scalars["String"]>;
  activeWellCount: Scalars["Int"];
};

export type SystemOneFeedReservationInfoInput = {
  includedPlates?: Maybe<Array<Maybe<SystemOneFeedWorklistPlatesInput>>>;
  excludedPlates?: Maybe<Array<Maybe<SystemOneFeedPlatesInput>>>;
  personalTaskId: Scalars["Int"];
};

export type SystemOneFeedRuns = {
  __typename?: "SystemOneFeedRuns";
  runName?: Maybe<Scalars["String"]>;
  plates?: Maybe<Array<Maybe<SystemOneFeedPlates>>>;
  reason?: Maybe<Scalars["String"]>;
};

export type SystemOneFeedWorklist = {
  __typename?: "SystemOneFeedWorklist";
  methodReservationID: Scalars["Int"];
  worklistID: Scalars["Int"];
};

export type SystemOneFeedWorklistContainersInput = {
  containerID: Scalars["Int"];
  rowPos: Scalars["Int"];
  colPos: Scalars["Int"];
  isActive: Scalars["Boolean"];
};

export type SystemOneFeedWorklistPlatesInput = {
  plateID: Scalars["Int"];
  plateBarcode?: Maybe<Scalars["String"]>;
  activeWellCount: Scalars["Int"];
  aspirateLC?: Maybe<Scalars["String"]>;
  aspirateVol: Scalars["Int"];
  dispenseLC?: Maybe<Scalars["String"]>;
  dispenseVol: Scalars["Int"];
  selectedWells?: Maybe<Array<Maybe<SystemOneFeedWorklistContainersInput>>>;
};

export type SystemType = {
  __typename?: "SystemType";
  systemTypeId: Scalars["Int"];
  systemType1?: Maybe<Scalars["String"]>;
  lookupArraySystems?: Maybe<Array<Maybe<LookupArraySystem>>>;
};

export type SystemTypeFilterInput = {
  and?: Maybe<Array<SystemTypeFilterInput>>;
  or?: Maybe<Array<SystemTypeFilterInput>>;
  systemTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  systemType1?: Maybe<StringOperationFilterInput>;
  lookupArraySystems?: Maybe<ListFilterInputTypeOfLookupArraySystemFilterInput>;
};

export type SystemTypeSortInput = {
  systemTypeId?: Maybe<SortEnumType>;
  systemType1?: Maybe<SortEnumType>;
};

export type TaskAttachment = {
  __typename?: "TaskAttachment";
  taskAttachmentId: Scalars["Int"];
  taskId: Scalars["Int"];
  attachmentName?: Maybe<Scalars["String"]>;
  attachmentFile?: Maybe<Array<Scalars["Byte"]>>;
  dateUploaded: Scalars["DateTime"];
  uploadedBy: Scalars["Int"];
  comments?: Maybe<Scalars["String"]>;
  task?: Maybe<DashboardTask>;
};

export type TaskAttachmentFilterInput = {
  and?: Maybe<Array<TaskAttachmentFilterInput>>;
  or?: Maybe<Array<TaskAttachmentFilterInput>>;
  taskAttachmentId?: Maybe<ComparableInt32OperationFilterInput>;
  taskId?: Maybe<ComparableInt32OperationFilterInput>;
  attachmentName?: Maybe<StringOperationFilterInput>;
  attachmentFile?: Maybe<ListComparableByteOperationFilterInput>;
  dateUploaded?: Maybe<ComparableDateTimeOperationFilterInput>;
  uploadedBy?: Maybe<ComparableInt32OperationFilterInput>;
  comments?: Maybe<StringOperationFilterInput>;
  task?: Maybe<DashboardTaskFilterInput>;
};

export type TaskAttachmentInput = {
  name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["Upload"]>;
};

export enum TaskPriorityEnum {
  Immediate = "IMMEDIATE",
  High = "HIGH",
  Medium = "MEDIUM",
  Low = "LOW",
}

export type TaskPriorityEnumOperationFilterInput = {
  eq?: Maybe<TaskPriorityEnum>;
  neq?: Maybe<TaskPriorityEnum>;
  in?: Maybe<Array<TaskPriorityEnum>>;
  nin?: Maybe<Array<TaskPriorityEnum>>;
};

export enum TaskSelectionTypeEnum {
  Manual = "MANUAL",
  Method = "METHOD",
}

export enum TaskStatusEnum {
  Overdue = "OVERDUE",
  Scheduled = "SCHEDULED",
  InProgress = "IN_PROGRESS",
  Completed = "COMPLETED",
  Cancelled = "CANCELLED",
}

export type TaskStatusEnumOperationFilterInput = {
  eq?: Maybe<TaskStatusEnum>;
  neq?: Maybe<TaskStatusEnum>;
  in?: Maybe<Array<TaskStatusEnum>>;
  nin?: Maybe<Array<TaskStatusEnum>>;
};

export type TaskType = {
  __typename?: "TaskType";
  taskTypeId: Scalars["Int"];
  taskType1?: Maybe<Scalars["String"]>;
  webSuiteTaskLogs?: Maybe<Array<Maybe<WebSuiteTaskLog>>>;
};

export type TaskTypeDetailInput = {
  taskType?: Maybe<TaskSelectionTypeEnum>;
  taskTypeId?: Maybe<Scalars["Int"]>;
  documentationUrl?: Maybe<Scalars["String"]>;
};

export enum TaskTypeEnum {
  IncomingSample = "INCOMING_SAMPLE",
  RunTask = "RUN_TASK",
  Personal = "PERSONAL",
}

export type TaskTypeEnumOperationFilterInput = {
  eq?: Maybe<TaskTypeEnum>;
  neq?: Maybe<TaskTypeEnum>;
  in?: Maybe<Array<TaskTypeEnum>>;
  nin?: Maybe<Array<TaskTypeEnum>>;
};

export type TaskTypeFilterInput = {
  and?: Maybe<Array<TaskTypeFilterInput>>;
  or?: Maybe<Array<TaskTypeFilterInput>>;
  taskTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  taskType1?: Maybe<StringOperationFilterInput>;
  webSuiteTaskLogs?: Maybe<ListFilterInputTypeOfWebSuiteTaskLogFilterInput>;
};

export type TaskTypeSortInput = {
  taskTypeId?: Maybe<SortEnumType>;
  taskType1?: Maybe<SortEnumType>;
};

export enum TeamGanttType {
  Folder = "FOLDER",
  Project = "PROJECT",
  Group = "GROUP",
  Task = "TASK",
}

export type TeamGanttTypeOperationFilterInput = {
  eq?: Maybe<TeamGanttType>;
  neq?: Maybe<TeamGanttType>;
  in?: Maybe<Array<TeamGanttType>>;
  nin?: Maybe<Array<TeamGanttType>>;
};

export type Ticket = {
  __typename?: "Ticket";
  status: TicketStatus;
  lastUpdated: Scalars["DateTime"];
  ticketId: Scalars["Int"];
  ticketHeader?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  dateCreated: Scalars["DateTime"];
  createdByUserId: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  assetId: Scalars["Int"];
  dateEncountered?: Maybe<Scalars["DateTime"]>;
  percentComplete: Scalars["Int"];
  requestedCompletionDate?: Maybe<Scalars["DateTime"]>;
  estimatedCompletionDays?: Maybe<Scalars["Decimal"]>;
  actualCompletionDays?: Maybe<Scalars["Decimal"]>;
  estimatedCompletionDate?: Maybe<Scalars["DateTime"]>;
  isActive: Scalars["Boolean"];
  ticketTypeId: Scalars["Int"];
  mergedIntoTicketId?: Maybe<Scalars["Int"]>;
  exceptionId?: Maybe<Scalars["Int"]>;
  computerName?: Maybe<Scalars["String"]>;
  slackMessageTimestamp?: Maybe<Scalars["String"]>;
  githubId?: Maybe<Scalars["String"]>;
  asset?: Maybe<LookupAsset>;
  ticketType?: Maybe<LookupTicketType>;
  ticketAssignees?: Maybe<Array<Maybe<TicketAssignee>>>;
  ticketAttachments?: Maybe<Array<Maybe<TicketAttachment>>>;
  ticketFollowers?: Maybe<Array<Maybe<TicketFollower>>>;
  ticketUpdates?: Maybe<Array<Maybe<TicketUpdate>>>;
  priority: TicketPriority;
};

export type TicketAssignee = {
  __typename?: "TicketAssignee";
  ticketAssigneeId: Scalars["Int"];
  ticketId: Scalars["Int"];
  userId: Scalars["Int"];
  dateAssigned?: Maybe<Scalars["DateTime"]>;
  ticketAccepted: Scalars["Boolean"];
  dateAccepted?: Maybe<Scalars["DateTime"]>;
  ticket?: Maybe<Ticket>;
  user?: Maybe<AppSuiteUser>;
};

export type TicketAssigneeFilterInput = {
  and?: Maybe<Array<TicketAssigneeFilterInput>>;
  or?: Maybe<Array<TicketAssigneeFilterInput>>;
  ticketAssigneeId?: Maybe<ComparableInt32OperationFilterInput>;
  ticketId?: Maybe<ComparableInt32OperationFilterInput>;
  userId?: Maybe<ComparableInt32OperationFilterInput>;
  dateAssigned?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  ticketAccepted?: Maybe<BooleanOperationFilterInput>;
  dateAccepted?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  ticket?: Maybe<TicketFilterInput>;
  user?: Maybe<AppSuiteUserFilterInput>;
};

export type TicketAttachment = {
  __typename?: "TicketAttachment";
  ticketAttachmentId: Scalars["Int"];
  ticketId: Scalars["Int"];
  attachmentName?: Maybe<Scalars["String"]>;
  dateUploaded: Scalars["DateTime"];
  uploadedBy?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  slackAttachmentUrl?: Maybe<Scalars["String"]>;
  slackPrivateUrl?: Maybe<Scalars["String"]>;
  ticket?: Maybe<Ticket>;
  attachmentUrl?: Maybe<Scalars["String"]>;
};

export type TicketAttachmentFilterInput = {
  and?: Maybe<Array<TicketAttachmentFilterInput>>;
  or?: Maybe<Array<TicketAttachmentFilterInput>>;
  ticketAttachmentId?: Maybe<ComparableInt32OperationFilterInput>;
  ticketId?: Maybe<ComparableInt32OperationFilterInput>;
  attachmentName?: Maybe<StringOperationFilterInput>;
  attachmentFile?: Maybe<ListComparableByteOperationFilterInput>;
  dateUploaded?: Maybe<ComparableDateTimeOperationFilterInput>;
  uploadedBy?: Maybe<StringOperationFilterInput>;
  comments?: Maybe<StringOperationFilterInput>;
  slackAttachmentUrl?: Maybe<StringOperationFilterInput>;
  slackPrivateUrl?: Maybe<StringOperationFilterInput>;
  ticket?: Maybe<TicketFilterInput>;
};

export type TicketFilterInput = {
  and?: Maybe<Array<TicketFilterInput>>;
  or?: Maybe<Array<TicketFilterInput>>;
  status?: Maybe<TicketStatusOperationFilterInput>;
  priority?: Maybe<StringOperationFilterInput>;
  lastUpdated?: Maybe<ComparableDateTimeOperationFilterInput>;
  ticketId?: Maybe<ComparableInt32OperationFilterInput>;
  ticketHeader?: Maybe<StringOperationFilterInput>;
  title?: Maybe<StringOperationFilterInput>;
  dateCreated?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdByUserId?: Maybe<ComparableInt32OperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  assetId?: Maybe<ComparableInt32OperationFilterInput>;
  dateEncountered?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  percentComplete?: Maybe<ComparableInt32OperationFilterInput>;
  requestedCompletionDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  estimatedCompletionDays?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  actualCompletionDays?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
  estimatedCompletionDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  ticketTypeId?: Maybe<ComparableInt32OperationFilterInput>;
  mergedIntoTicketId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  exceptionId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  computerName?: Maybe<StringOperationFilterInput>;
  slackMessageTimestamp?: Maybe<StringOperationFilterInput>;
  githubId?: Maybe<StringOperationFilterInput>;
  asset?: Maybe<LookupAssetFilterInput>;
  ticketType?: Maybe<LookupTicketTypeFilterInput>;
  ticketAssignees?: Maybe<ListFilterInputTypeOfTicketAssigneeFilterInput>;
  ticketAttachments?: Maybe<ListFilterInputTypeOfTicketAttachmentFilterInput>;
  ticketFollowers?: Maybe<ListFilterInputTypeOfTicketFollowerFilterInput>;
  ticketUpdates?: Maybe<ListFilterInputTypeOfTicketUpdateFilterInput>;
};

export type TicketFollower = {
  __typename?: "TicketFollower";
  ticketFollowerId: Scalars["Int"];
  ticketId: Scalars["Int"];
  userId: Scalars["Int"];
  ticket?: Maybe<Ticket>;
  user?: Maybe<AppSuiteUser>;
};

export type TicketFollowerFilterInput = {
  and?: Maybe<Array<TicketFollowerFilterInput>>;
  or?: Maybe<Array<TicketFollowerFilterInput>>;
  ticketFollowerId?: Maybe<ComparableInt32OperationFilterInput>;
  ticketId?: Maybe<ComparableInt32OperationFilterInput>;
  userId?: Maybe<ComparableInt32OperationFilterInput>;
  ticket?: Maybe<TicketFilterInput>;
  user?: Maybe<AppSuiteUserFilterInput>;
};

export enum TicketPriority {
  Low = "LOW",
  Medium = "MEDIUM",
  High = "HIGH",
  Immediate = "IMMEDIATE",
}

export type TicketSortInput = {
  status?: Maybe<SortEnumType>;
  priority?: Maybe<SortEnumType>;
  lastUpdated?: Maybe<SortEnumType>;
  ticketId?: Maybe<SortEnumType>;
  ticketHeader?: Maybe<SortEnumType>;
  title?: Maybe<SortEnumType>;
  dateCreated?: Maybe<SortEnumType>;
  createdByUserId?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  assetId?: Maybe<SortEnumType>;
  dateEncountered?: Maybe<SortEnumType>;
  percentComplete?: Maybe<SortEnumType>;
  requestedCompletionDate?: Maybe<SortEnumType>;
  estimatedCompletionDays?: Maybe<SortEnumType>;
  actualCompletionDays?: Maybe<SortEnumType>;
  estimatedCompletionDate?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  ticketTypeId?: Maybe<SortEnumType>;
  mergedIntoTicketId?: Maybe<SortEnumType>;
  exceptionId?: Maybe<SortEnumType>;
  computerName?: Maybe<SortEnumType>;
  slackMessageTimestamp?: Maybe<SortEnumType>;
  githubId?: Maybe<SortEnumType>;
  asset?: Maybe<LookupAssetSortInput>;
  ticketType?: Maybe<LookupTicketTypeSortInput>;
};

export enum TicketStatus {
  Open = "OPEN",
  Closed = "CLOSED",
  InProgress = "IN_PROGRESS",
  Resolved = "RESOLVED",
  Pending = "PENDING",
  Escalated = "ESCALATED",
  OnHold = "ON_HOLD",
  Merged = "MERGED",
}

export type TicketStatusOperationFilterInput = {
  eq?: Maybe<TicketStatus>;
  neq?: Maybe<TicketStatus>;
  in?: Maybe<Array<TicketStatus>>;
  nin?: Maybe<Array<TicketStatus>>;
};

export type TicketUpdate = {
  __typename?: "TicketUpdate";
  status: TicketStatus;
  ticketUpdateId: Scalars["Int"];
  ticketId: Scalars["Int"];
  summary?: Maybe<Scalars["String"]>;
  dateUpdated: Scalars["DateTime"];
  updatedByUserId: Scalars["Int"];
  percentComplete?: Maybe<Scalars["Byte"]>;
  ticket?: Maybe<Ticket>;
  updatedByUser?: Maybe<AppSuiteUser>;
};

export type TicketUpdateFilterInput = {
  and?: Maybe<Array<TicketUpdateFilterInput>>;
  or?: Maybe<Array<TicketUpdateFilterInput>>;
  status?: Maybe<TicketStatusOperationFilterInput>;
  ticketUpdateId?: Maybe<ComparableInt32OperationFilterInput>;
  ticketId?: Maybe<ComparableInt32OperationFilterInput>;
  summary?: Maybe<StringOperationFilterInput>;
  dateUpdated?: Maybe<ComparableDateTimeOperationFilterInput>;
  updatedByUserId?: Maybe<ComparableInt32OperationFilterInput>;
  percentComplete?: Maybe<ComparableNullableOfByteOperationFilterInput>;
  ticket?: Maybe<TicketFilterInput>;
  updatedByUser?: Maybe<AppSuiteUserFilterInput>;
};

/** A connection to a list of items. */
export type TicketsConnection = {
  __typename?: "TicketsConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<TicketsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Ticket>>>;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type TicketsEdge = {
  __typename?: "TicketsEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<Ticket>;
};

export type TubeBarcodeFile = {
  __typename?: "TubeBarcodeFile";
  tubeBarcodeFileId: Scalars["Int"];
  fileName?: Maybe<Scalars["String"]>;
  plateId: Scalars["Int"];
  dateCreated: Scalars["DateTime"];
  dateUploaded: Scalars["DateTime"];
  computerName?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["String"]>;
  fileUploaded?: Maybe<Array<Scalars["Byte"]>>;
  plate?: Maybe<PlatesCreated>;
};

export type TubeBarcodeFileFilterInput = {
  and?: Maybe<Array<TubeBarcodeFileFilterInput>>;
  or?: Maybe<Array<TubeBarcodeFileFilterInput>>;
  tubeBarcodeFileId?: Maybe<ComparableInt32OperationFilterInput>;
  fileName?: Maybe<StringOperationFilterInput>;
  plateId?: Maybe<ComparableInt32OperationFilterInput>;
  dateCreated?: Maybe<ComparableDateTimeOperationFilterInput>;
  dateUploaded?: Maybe<ComparableDateTimeOperationFilterInput>;
  computerName?: Maybe<StringOperationFilterInput>;
  isActive?: Maybe<StringOperationFilterInput>;
  fileUploaded?: Maybe<ListComparableByteOperationFilterInput>;
  plate?: Maybe<PlatesCreatedFilterInput>;
};

export type TubeLabellerDestinationWorklist = {
  __typename?: "TubeLabellerDestinationWorklist";
  dSTRack: Scalars["Int"];
  dSTPosition?: Maybe<Scalars["String"]>;
  sRCRack: Scalars["Int"];
  sRCPosition?: Maybe<Scalars["String"]>;
  dSTRackBarcode?: Maybe<Scalars["String"]>;
  dSTLinePosition?: Maybe<Scalars["String"]>;
  factoryBarcode?: Maybe<Scalars["String"]>;
  field1?: Maybe<Scalars["String"]>;
  field2?: Maybe<Scalars["String"]>;
  field3?: Maybe<Scalars["String"]>;
  field4?: Maybe<Scalars["String"]>;
  field5?: Maybe<Scalars["String"]>;
  field6?: Maybe<Scalars["String"]>;
  field7?: Maybe<Scalars["String"]>;
};

export type TubeLabellerDestinationWorklistInput = {
  dSTRack: Scalars["Int"];
  dSTPosition?: Maybe<Scalars["String"]>;
  sRCRack: Scalars["Int"];
  sRCPosition?: Maybe<Scalars["String"]>;
  dSTRackBarcode?: Maybe<Scalars["String"]>;
  dSTLinePosition?: Maybe<Scalars["String"]>;
  factoryBarcode?: Maybe<Scalars["String"]>;
  field1?: Maybe<Scalars["String"]>;
  field2?: Maybe<Scalars["String"]>;
  field3?: Maybe<Scalars["String"]>;
  field4?: Maybe<Scalars["String"]>;
  field5?: Maybe<Scalars["String"]>;
  field6?: Maybe<Scalars["String"]>;
  field7?: Maybe<Scalars["String"]>;
};

export type UpdateAppSuiteUserInput = {
  userName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  groups?: Maybe<Array<Scalars["Int"]>>;
  roles?: Maybe<Array<Role>>;
};

export type UpdateDashboardTaskInput = {
  taskOwnerId?: Maybe<Scalars["Int"]>;
  taskOwnerName?: Maybe<Scalars["String"]>;
  taskId: Scalars["Int"];
  taskName?: Maybe<Scalars["String"]>;
  taskType: TaskTypeEnum;
  startTime?: Maybe<Scalars["DateTime"]>;
  endTime?: Maybe<Scalars["DateTime"]>;
  priority?: Maybe<TaskPriorityEnum>;
  status?: Maybe<TaskStatusEnum>;
  notes?: Maybe<Scalars["String"]>;
  usersAssignedTo?: Maybe<Array<Scalars["Int"]>>;
  groupsAssignedTo?: Maybe<Array<Scalars["Int"]>>;
  systemId?: Maybe<Scalars["Int"]>;
  methodId?: Maybe<Scalars["Int"]>;
  folderId?: Maybe<Scalars["Int"]>;
  startTimeScheduled: Scalars["DateTime"];
  endTimeScheduled: Scalars["DateTime"];
  startTimeActual?: Maybe<Scalars["DateTime"]>;
  endTimeActual?: Maybe<Scalars["DateTime"]>;
};

export type UpdateIncomingSampleCollectionInput = {
  projectId: Scalars["Int"];
  dateTimeOfArrival?: Maybe<Scalars["DateTime"]>;
  dateTimeOfCollection?: Maybe<Scalars["DateTime"]>;
};

export type UpdateIncomingSampleTypeInput = {
  dateToProcess?: Maybe<Scalars["DateTime"]>;
  notes?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  sampleTypeId?: Maybe<Scalars["Int"]>;
};

export type UpdateInputOfUpdateAppSuiteUserInputAndAppSuiteUserInput = {
  primaryKey: Scalars["Int"];
  set: UpdateAppSuiteUserInput;
};

export type UpdateInputOfUpdateIncomingSampleCollectionInputAndCollectionInput =
  {
    primaryKey: Scalars["Int"];
    set: UpdateIncomingSampleCollectionInput;
  };

export type UpdateInputOfUpdateIncomingSampleTypeInputAndCollectionSampleTypeInput =
  {
    primaryKey: Scalars["Int"];
    set: UpdateIncomingSampleTypeInput;
  };

export type UpdateInputOfUpdateManualTaskInputAndManualTaskTypeInput = {
  primaryKey: Scalars["Int"];
  set: UpdateManualTaskInput;
};

export type UpdateInputOfUpdateMethodInputAndLookupArrayMethodInput = {
  primaryKey: Scalars["Int"];
  set: UpdateMethodInput;
};

export type UpdateInputOfUpdateRunInputAndRunInput = {
  primaryKey: Scalars["Int"];
  set: UpdateRunInput;
};

export type UpdateInputOfUpdateRunTemplateInputAndRunTemplateInput = {
  primaryKey: Scalars["Int"];
  set: UpdateRunTemplateInput;
};

export type UpdateInputOfUpdateRunTemplateStageInputAndRunTemplateStageInput = {
  primaryKey: Scalars["Int"];
  set: UpdateRunTemplateStageInput;
};

export type UpdateInputOfUpdateTicketInputAndTicketInput = {
  primaryKey: Scalars["Int"];
  set: UpdateTicketInput;
};

export type UpdateManualTaskInput = {
  name?: Maybe<Scalars["String"]>;
  documentationUrl?: Maybe<Scalars["String"]>;
};

export type UpdateMethodDocumentationInput = {
  type: TaskSelectionTypeEnum;
  id: Scalars["Int"];
  documentationUrl: Scalars["String"];
};

export type UpdateMethodInput = {
  methodName?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  documentationUrl?: Maybe<Scalars["String"]>;
};

export type UpdateRunInput = {
  runName?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  runOwner?: Maybe<Scalars["Int"]>;
  runDateScheduled?: Maybe<Scalars["DateTime"]>;
  parentFolderId?: Maybe<Scalars["Int"]>;
  status?: Maybe<TaskStatusEnum>;
};

export type UpdateRunTemplateInput = {
  runTemplateId?: Maybe<Scalars["Int"]>;
  runTemplateName?: Maybe<Scalars["String"]>;
  groupIds?: Maybe<Array<Scalars["Int"]>>;
  createdBy?: Maybe<Scalars["Int"]>;
  folderLocationId: Scalars["Int"];
  productionRunTypeId?: Maybe<Scalars["Int"]>;
  createdByV?: Maybe<Scalars["String"]>;
  lastUpdatedBy?: Maybe<Scalars["Int"]>;
  assignAllTasks?: Maybe<Scalars["Int"]>;
};

export type UpdateRunTemplateStageInput = {
  runTemplateStageId?: Maybe<Scalars["Int"]>;
  runTemplateId?: Maybe<Scalars["Int"]>;
  stageSeqNo?: Maybe<Scalars["Int"]>;
  templateStageName?: Maybe<Scalars["String"]>;
};

export type UpdateTicketInput = {
  title?: Maybe<Scalars["String"]>;
  dateCreated?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  assetId?: Maybe<Scalars["Int"]>;
  assetTypeId?: Maybe<Scalars["Int"]>;
  priority?: Maybe<TicketPriority>;
  dateEncountered?: Maybe<Scalars["DateTime"]>;
  percentComplete?: Maybe<Scalars["Int"]>;
  requestedCompletionDate?: Maybe<Scalars["DateTime"]>;
  status: TicketStatus;
  ticketTypeId?: Maybe<Scalars["Int"]>;
  ticketAssigneeIds?: Maybe<Array<Scalars["Int"]>>;
  ticketFollowerIds?: Maybe<Array<Scalars["Int"]>>;
};

export type UploadIdfFormInput = {
  name?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["Upload"]>;
  projectCode?: Maybe<Scalars["String"]>;
  registeredBy?: Maybe<Scalars["String"]>;
  registeredById: Scalars["Int"];
};

export type UploadIdfFormResponse = {
  __typename?: "UploadIdfFormResponse";
  success: Scalars["Boolean"];
  traceID?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type User = {
  __typename?: "User";
  userName: Scalars["String"];
  userId: Scalars["Int"];
  email: Scalars["String"];
  isAdmin: Scalars["Boolean"];
};

export type UserClaims = {
  __typename?: "UserClaims";
  isGroupAccount: Scalars["Boolean"];
  loggedInGroupMember?: Maybe<LoggedInGroupMember>;
  groupAccount?: Maybe<AppSuiteGroup>;
  setting?: Maybe<UserSetting>;
  userName: Scalars["String"];
  userId: Scalars["Int"];
  email: Scalars["String"];
  isAdmin: Scalars["Boolean"];
};

export type UserSetting = {
  __typename?: "UserSetting";
  id: Scalars["Int"];
  userId: Scalars["Int"];
  settingsJson?: Maybe<Scalars["String"]>;
  user?: Maybe<AppSuiteUser>;
};

export type UserSettingFilterInput = {
  and?: Maybe<Array<UserSettingFilterInput>>;
  or?: Maybe<Array<UserSettingFilterInput>>;
  id?: Maybe<ComparableInt32OperationFilterInput>;
  userId?: Maybe<ComparableInt32OperationFilterInput>;
  settingsJson?: Maybe<StringOperationFilterInput>;
  user?: Maybe<AppSuiteUserFilterInput>;
};

export type UserSettingSortInput = {
  id?: Maybe<SortEnumType>;
  userId?: Maybe<SortEnumType>;
  settingsJson?: Maybe<SortEnumType>;
  user?: Maybe<AppSuiteUserSortInput>;
};

export type UserToRole = {
  __typename?: "UserToRole";
  userId: Scalars["Int"];
  roleId: Scalars["Int"];
  user?: Maybe<AppSuiteUser>;
};

export type UserToRoleFilterInput = {
  and?: Maybe<Array<UserToRoleFilterInput>>;
  or?: Maybe<Array<UserToRoleFilterInput>>;
  userId?: Maybe<ComparableInt32OperationFilterInput>;
  roleId?: Maybe<ComparableInt32OperationFilterInput>;
  user?: Maybe<AppSuiteUserFilterInput>;
};

export type WebSuiteTaskLog = {
  __typename?: "WebSuiteTaskLog";
  taskLogId: Scalars["Int"];
  taskParentId?: Maybe<Scalars["Int"]>;
  taskId?: Maybe<Scalars["Int"]>;
  taskType?: Maybe<Scalars["String"]>;
  actionPerformed?: Maybe<Scalars["String"]>;
  actionPerformedTime?: Maybe<Scalars["DateTime"]>;
  actionPerformedBy?: Maybe<Scalars["Int"]>;
  taskTypeId?: Maybe<Scalars["Int"]>;
  taskTypeNavigation?: Maybe<TaskType>;
};

export type WebSuiteTaskLogFilterInput = {
  and?: Maybe<Array<WebSuiteTaskLogFilterInput>>;
  or?: Maybe<Array<WebSuiteTaskLogFilterInput>>;
  taskLogId?: Maybe<ComparableInt32OperationFilterInput>;
  taskParentId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  taskId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  taskType?: Maybe<StringOperationFilterInput>;
  actionPerformed?: Maybe<StringOperationFilterInput>;
  actionPerformedTime?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  actionPerformedBy?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  taskTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  taskTypeNavigation?: Maybe<TaskTypeFilterInput>;
};

export type WebSuiteTaskLogSortInput = {
  taskLogId?: Maybe<SortEnumType>;
  taskParentId?: Maybe<SortEnumType>;
  taskId?: Maybe<SortEnumType>;
  taskType?: Maybe<SortEnumType>;
  actionPerformed?: Maybe<SortEnumType>;
  actionPerformedTime?: Maybe<SortEnumType>;
  actionPerformedBy?: Maybe<SortEnumType>;
  taskTypeId?: Maybe<SortEnumType>;
  taskTypeNavigation?: Maybe<TaskTypeSortInput>;
};

export type Worklist = {
  __typename?: "Worklist";
  worklistId: Scalars["Int"];
  systemId?: Maybe<Scalars["Int"]>;
  methodId?: Maybe<Scalars["Int"]>;
  passageTemplateId?: Maybe<Scalars["Int"]>;
  dateCreated?: Maybe<Scalars["DateTime"]>;
  createdBy?: Maybe<Scalars["String"]>;
  methodReservationId: Scalars["Int"];
  fileName?: Maybe<Scalars["String"]>;
  method?: Maybe<LookupArrayMethod>;
  methodReservation?: Maybe<MethodReservation>;
  system?: Maybe<LookupArraySystem>;
  worklistContentImagings?: Maybe<Array<Maybe<WorklistContentImaging>>>;
  worklistContents?: Maybe<Array<Maybe<WorklistContent>>>;
};

export type WorklistCherryPickMode = {
  __typename?: "WorklistCherryPickMode";
  worklistCherryPickModeId: Scalars["Int"];
  taskKeyword?: Maybe<Scalars["String"]>;
  detailExpression?: Maybe<Scalars["String"]>;
  populateSourcePlate: Scalars["Boolean"];
  populateSourceWell: Scalars["Boolean"];
  populateDestinationPlate: Scalars["Boolean"];
  populateDestinationWell: Scalars["Boolean"];
  sortWellsColumnWise: Scalars["Boolean"];
  lookupArrayMethods?: Maybe<Array<Maybe<LookupArrayMethod>>>;
};

export type WorklistCherryPickModeFilterInput = {
  and?: Maybe<Array<WorklistCherryPickModeFilterInput>>;
  or?: Maybe<Array<WorklistCherryPickModeFilterInput>>;
  worklistCherryPickModeId?: Maybe<ComparableInt32OperationFilterInput>;
  taskKeyword?: Maybe<StringOperationFilterInput>;
  detailExpression?: Maybe<StringOperationFilterInput>;
  populateSourcePlate?: Maybe<BooleanOperationFilterInput>;
  populateSourceWell?: Maybe<BooleanOperationFilterInput>;
  populateDestinationPlate?: Maybe<BooleanOperationFilterInput>;
  populateDestinationWell?: Maybe<BooleanOperationFilterInput>;
  sortWellsColumnWise?: Maybe<BooleanOperationFilterInput>;
  lookupArrayMethods?: Maybe<ListFilterInputTypeOfLookupArrayMethodFilterInput>;
};

export type WorklistCherryPickModeSortInput = {
  worklistCherryPickModeId?: Maybe<SortEnumType>;
  taskKeyword?: Maybe<SortEnumType>;
  detailExpression?: Maybe<SortEnumType>;
  populateSourcePlate?: Maybe<SortEnumType>;
  populateSourceWell?: Maybe<SortEnumType>;
  populateDestinationPlate?: Maybe<SortEnumType>;
  populateDestinationWell?: Maybe<SortEnumType>;
  sortWellsColumnWise?: Maybe<SortEnumType>;
};

export type WorklistColumn = {
  __typename?: "WorklistColumn";
  worklistColumnId: Scalars["Int"];
  worklistColumn1?: Maybe<Scalars["String"]>;
  arrayMethodPlates?: Maybe<Array<Maybe<ArrayMethodPlate>>>;
  lookupArrayMethodPlateRoles?: Maybe<Array<Maybe<LookupArrayMethodPlateRole>>>;
};

export type WorklistColumnFilterInput = {
  and?: Maybe<Array<WorklistColumnFilterInput>>;
  or?: Maybe<Array<WorklistColumnFilterInput>>;
  worklistColumnId?: Maybe<ComparableInt32OperationFilterInput>;
  worklistColumn1?: Maybe<StringOperationFilterInput>;
  arrayMethodPlates?: Maybe<ListFilterInputTypeOfArrayMethodPlateFilterInput>;
  lookupArrayMethodPlateRoles?: Maybe<ListFilterInputTypeOfLookupArrayMethodPlateRoleFilterInput>;
};

export type WorklistContent = {
  __typename?: "WorklistContent";
  worklistContentId: Scalars["Int"];
  worklistId?: Maybe<Scalars["Int"]>;
  task?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["String"]>;
  aspirateVol?: Maybe<Scalars["String"]>;
  dispenseVol?: Maybe<Scalars["String"]>;
  transferVol?: Maybe<Scalars["String"]>;
  sourcePlateType?: Maybe<Scalars["String"]>;
  sourcePlateBarcode?: Maybe<Scalars["String"]>;
  sourceWellId?: Maybe<Scalars["String"]>;
  destinationPlateType?: Maybe<Scalars["String"]>;
  destinationPlateBarcode?: Maybe<Scalars["String"]>;
  destinationWellId?: Maybe<Scalars["String"]>;
  rowNumber?: Maybe<Scalars["Int"]>;
  worklist?: Maybe<Worklist>;
};

export type WorklistContentFilterInput = {
  and?: Maybe<Array<WorklistContentFilterInput>>;
  or?: Maybe<Array<WorklistContentFilterInput>>;
  worklistContentId?: Maybe<ComparableInt32OperationFilterInput>;
  worklistId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  task?: Maybe<StringOperationFilterInput>;
  details?: Maybe<StringOperationFilterInput>;
  aspirateVol?: Maybe<StringOperationFilterInput>;
  dispenseVol?: Maybe<StringOperationFilterInput>;
  transferVol?: Maybe<StringOperationFilterInput>;
  sourcePlateType?: Maybe<StringOperationFilterInput>;
  sourcePlateBarcode?: Maybe<StringOperationFilterInput>;
  sourceWellId?: Maybe<StringOperationFilterInput>;
  destinationPlateType?: Maybe<StringOperationFilterInput>;
  destinationPlateBarcode?: Maybe<StringOperationFilterInput>;
  destinationWellId?: Maybe<StringOperationFilterInput>;
  rowNumber?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  worklist?: Maybe<WorklistFilterInput>;
};

export type WorklistContentImaging = {
  __typename?: "WorklistContentImaging";
  imageExportFormat?: Maybe<ImageExportFormat>;
  worklistContentImagingId: Scalars["Int"];
  worklistId?: Maybe<Scalars["Int"]>;
  barcode?: Maybe<Scalars["String"]>;
  plateType?: Maybe<Scalars["String"]>;
  activeWells?: Maybe<Scalars["Int"]>;
  platePitch?: Maybe<Scalars["String"]>;
  experimentSetting?: Maybe<Scalars["String"]>;
  analysisSetting?: Maybe<Scalars["String"]>;
  imageExportSetting?: Maybe<Scalars["Int"]>;
  worklist?: Maybe<Worklist>;
};

export type WorklistContentImagingFilterInput = {
  and?: Maybe<Array<WorklistContentImagingFilterInput>>;
  or?: Maybe<Array<WorklistContentImagingFilterInput>>;
  imageExportFormat?: Maybe<NullableOfImageExportFormatOperationFilterInput>;
  worklistContentImagingId?: Maybe<ComparableInt32OperationFilterInput>;
  worklistId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  barcode?: Maybe<StringOperationFilterInput>;
  plateType?: Maybe<StringOperationFilterInput>;
  activeWells?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  platePitch?: Maybe<StringOperationFilterInput>;
  experimentSetting?: Maybe<StringOperationFilterInput>;
  analysisSetting?: Maybe<StringOperationFilterInput>;
  imageExportSetting?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  worklist?: Maybe<WorklistFilterInput>;
};

export type WorklistFilterInput = {
  and?: Maybe<Array<WorklistFilterInput>>;
  or?: Maybe<Array<WorklistFilterInput>>;
  worklistId?: Maybe<ComparableInt32OperationFilterInput>;
  systemId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  methodId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  passageTemplateId?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  dateCreated?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  methodReservationId?: Maybe<ComparableInt32OperationFilterInput>;
  fileName?: Maybe<StringOperationFilterInput>;
  method?: Maybe<LookupArrayMethodFilterInput>;
  methodReservation?: Maybe<MethodReservationFilterInput>;
  system?: Maybe<LookupArraySystemFilterInput>;
  worklistContentImagings?: Maybe<ListFilterInputTypeOfWorklistContentImagingFilterInput>;
  worklistContents?: Maybe<ListFilterInputTypeOfWorklistContentFilterInput>;
};

export type WorklistInfoInput = {
  worklistId?: Maybe<Scalars["Int"]>;
  task?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["String"]>;
  aspirateVol?: Maybe<Scalars["String"]>;
  dispenseVol?: Maybe<Scalars["String"]>;
  transferVol?: Maybe<Scalars["String"]>;
  sourcePlateType?: Maybe<Scalars["String"]>;
  sourcePlateBarcode?: Maybe<Scalars["String"]>;
  sourceWellID?: Maybe<Scalars["String"]>;
  destinationPlateType?: Maybe<Scalars["String"]>;
  destinationPlateBarcode?: Maybe<Scalars["String"]>;
  destinationWellID?: Maybe<Scalars["String"]>;
};

export type WorklistSortInput = {
  worklistId?: Maybe<SortEnumType>;
  systemId?: Maybe<SortEnumType>;
  methodId?: Maybe<SortEnumType>;
  passageTemplateId?: Maybe<SortEnumType>;
  dateCreated?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  methodReservationId?: Maybe<SortEnumType>;
  fileName?: Maybe<SortEnumType>;
  method?: Maybe<LookupArrayMethodSortInput>;
  methodReservation?: Maybe<MethodReservationSortInput>;
  system?: Maybe<LookupArraySystemSortInput>;
};

export type WorklistToolMethodReservationInput = {
  selectedSystem: Scalars["Int"];
  methodId: Scalars["Int"];
  runTaskId?: Maybe<Scalars["Int"]>;
  sourcePlateInfo?: Maybe<Array<Maybe<SourcePlateInfoInput>>>;
  destPlateInfo?: Maybe<Array<Maybe<DestPlateInfoInput>>>;
  intPlateBarcode?: Maybe<Scalars["String"]>;
  intPlateBarcode2?: Maybe<Scalars["String"]>;
  intPlateBarcode3?: Maybe<Scalars["String"]>;
  deadPlateBarcode?: Maybe<Scalars["String"]>;
  worklist?: Maybe<Array<Maybe<WorklistInfoInput>>>;
  reservationTime?: Maybe<Scalars["DateTime"]>;
};

export type WorklistToolTemplate = {
  __typename?: "WorklistToolTemplate";
  worklistToolTemplateId: Scalars["Int"];
  methodId: Scalars["Int"];
  templateName?: Maybe<Scalars["String"]>;
  createdBy: Scalars["Int"];
  lastUpdatedBy?: Maybe<Scalars["Int"]>;
  lastUpdateByTime?: Maybe<Scalars["DateTime"]>;
  createdByNavigation?: Maybe<AppSuiteUser>;
  method?: Maybe<LookupArrayMethod>;
  worklistToolTemplateContents?: Maybe<
    Array<Maybe<WorklistToolTemplateContent>>
  >;
  worklistToolTemplatePlates?: Maybe<Array<Maybe<WorklistToolTemplatePlate>>>;
};

export type WorklistToolTemplateContent = {
  __typename?: "WorklistToolTemplateContent";
  worklistToolTemplateContentsId: Scalars["Int"];
  worklistToolTemplateId: Scalars["Int"];
  task?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["String"]>;
  aspirateVol?: Maybe<Scalars["String"]>;
  dispenseVol?: Maybe<Scalars["String"]>;
  transferVol?: Maybe<Scalars["String"]>;
  sourcePlateType?: Maybe<Scalars["String"]>;
  sourcePlatePosition?: Maybe<Scalars["Int"]>;
  sourceWellId?: Maybe<Scalars["String"]>;
  destinationPlateType?: Maybe<Scalars["String"]>;
  destinationPlatePosition?: Maybe<Scalars["Int"]>;
  destinationWellId?: Maybe<Scalars["String"]>;
  worklistToolTemplate?: Maybe<WorklistToolTemplate>;
};

export type WorklistToolTemplateContentFilterInput = {
  and?: Maybe<Array<WorklistToolTemplateContentFilterInput>>;
  or?: Maybe<Array<WorklistToolTemplateContentFilterInput>>;
  worklistToolTemplateContentsId?: Maybe<ComparableInt32OperationFilterInput>;
  worklistToolTemplateId?: Maybe<ComparableInt32OperationFilterInput>;
  task?: Maybe<StringOperationFilterInput>;
  details?: Maybe<StringOperationFilterInput>;
  aspirateVol?: Maybe<StringOperationFilterInput>;
  dispenseVol?: Maybe<StringOperationFilterInput>;
  transferVol?: Maybe<StringOperationFilterInput>;
  sourcePlateType?: Maybe<StringOperationFilterInput>;
  sourcePlatePosition?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  sourceWellId?: Maybe<StringOperationFilterInput>;
  destinationPlateType?: Maybe<StringOperationFilterInput>;
  destinationPlatePosition?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  destinationWellId?: Maybe<StringOperationFilterInput>;
  worklistToolTemplate?: Maybe<WorklistToolTemplateFilterInput>;
};

export type WorklistToolTemplateContentsInput = {
  worklistToolTemplateId?: Maybe<Scalars["Int"]>;
  task?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["String"]>;
  aspirateVol?: Maybe<Scalars["String"]>;
  dispenseVol?: Maybe<Scalars["String"]>;
  transferVol?: Maybe<Scalars["String"]>;
  sourcePlateType?: Maybe<Scalars["String"]>;
  sourcePlatePosition?: Maybe<Scalars["Int"]>;
  sourceWellID?: Maybe<Scalars["String"]>;
  destinationPlateType?: Maybe<Scalars["String"]>;
  destinationPlatePosition?: Maybe<Scalars["Int"]>;
  destinationWellID?: Maybe<Scalars["String"]>;
};

export type WorklistToolTemplateFilterInput = {
  and?: Maybe<Array<WorklistToolTemplateFilterInput>>;
  or?: Maybe<Array<WorklistToolTemplateFilterInput>>;
  worklistToolTemplateId?: Maybe<ComparableInt32OperationFilterInput>;
  methodId?: Maybe<ComparableInt32OperationFilterInput>;
  templateName?: Maybe<StringOperationFilterInput>;
  createdBy?: Maybe<ComparableInt32OperationFilterInput>;
  lastUpdatedBy?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  lastUpdateByTime?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  createdByNavigation?: Maybe<AppSuiteUserFilterInput>;
  method?: Maybe<LookupArrayMethodFilterInput>;
  worklistToolTemplateContents?: Maybe<ListFilterInputTypeOfWorklistToolTemplateContentFilterInput>;
  worklistToolTemplatePlates?: Maybe<ListFilterInputTypeOfWorklistToolTemplatePlateFilterInput>;
};

export type WorklistToolTemplatePlate = {
  __typename?: "WorklistToolTemplatePlate";
  worklistToolTemplatePlateId: Scalars["Int"];
  worklistToolTemplateId: Scalars["Int"];
  plateType?: Maybe<Scalars["String"]>;
  plateIndex: Scalars["Int"];
  labwareTypeCode?: Maybe<Scalars["String"]>;
  numberOfRows: Scalars["Int"];
  numberOfColumns: Scalars["Int"];
  operatingVol?: Maybe<Scalars["Int"]>;
  resuspensionVol?: Maybe<Scalars["Int"]>;
  startingVol?: Maybe<Scalars["Int"]>;
  preprocess?: Maybe<Scalars["Boolean"]>;
  topup?: Maybe<Scalars["Boolean"]>;
  worklistToolTemplate?: Maybe<WorklistToolTemplate>;
};

export type WorklistToolTemplatePlateFilterInput = {
  and?: Maybe<Array<WorklistToolTemplatePlateFilterInput>>;
  or?: Maybe<Array<WorklistToolTemplatePlateFilterInput>>;
  worklistToolTemplatePlateId?: Maybe<ComparableInt32OperationFilterInput>;
  worklistToolTemplateId?: Maybe<ComparableInt32OperationFilterInput>;
  plateType?: Maybe<StringOperationFilterInput>;
  plateIndex?: Maybe<ComparableInt32OperationFilterInput>;
  labwareTypeCode?: Maybe<StringOperationFilterInput>;
  numberOfRows?: Maybe<ComparableInt32OperationFilterInput>;
  numberOfColumns?: Maybe<ComparableInt32OperationFilterInput>;
  operatingVol?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  resuspensionVol?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  startingVol?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  preprocess?: Maybe<BooleanOperationFilterInput>;
  topup?: Maybe<BooleanOperationFilterInput>;
  worklistToolTemplate?: Maybe<WorklistToolTemplateFilterInput>;
};

export type CalendarNodeFragment = { __typename?: "CalendarNode" } & Pick<
  CalendarNode,
  | "id"
  | "name"
  | "taskType"
  | "startTimeScheduled"
  | "endTimeScheduled"
  | "startTime"
  | "endTime"
  | "status"
  | "priority"
  | "systemAssignedTo"
  | "runId"
  | "runName"
  | "lastTimeUpdated"
  | "updatedByName"
> & {
    method?: Maybe<
      { __typename?: "LookupArrayMethod" } & Pick<
        LookupArrayMethod,
        "methodId" | "methodName"
      >
    >;
    manualTaskType?: Maybe<
      { __typename?: "ManualTaskType" } & Pick<ManualTaskType, "id" | "name">
    >;
    systemAssignedToNavigation?: Maybe<
      { __typename?: "LookupArraySystem" } & Pick<
        LookupArraySystem,
        "systemId" | "systemName" | "clusterId"
      >
    >;
    users?: Maybe<
      Array<
        Maybe<
          { __typename?: "AppSuiteUser" } & Pick<
            AppSuiteUser,
            "userId" | "userName"
          >
        >
      >
    >;
    methodReservations?: Maybe<
      Array<
        Maybe<
          { __typename?: "MethodReservation" } & {
            methodReservationsPlateBarcodes?: Maybe<
              Array<
                Maybe<
                  { __typename?: "MethodReservationsPlateBarcode" } & Pick<
                    MethodReservationsPlateBarcode,
                    "plateBarcode"
                  >
                >
              >
            >;
          }
        >
      >
    >;
    groups?: Maybe<
      Array<
        Maybe<
          { __typename?: "AppSuiteGroup" } & Pick<
            AppSuiteGroup,
            "groupId" | "groupName"
          >
        >
      >
    >;
  };

export type DashboardTaskFragment = { __typename?: "DashboardTask" } & Pick<
  DashboardTask,
  | "taskOwnerId"
  | "taskOwnerName"
  | "taskId"
  | "taskName"
  | "taskType"
  | "startDate"
  | "startTime"
  | "endTime"
  | "priority"
  | "status"
  | "assignedUsers"
  | "assignedGroups"
  | "systemId"
  | "folderId"
  | "notes"
  | "methodId"
  | "startTimeScheduled"
  | "endTimeScheduled"
  | "startTimeActual"
  | "endTimeActual"
  | "project"
  | "sampleType"
>;

export type ManagePersonalTaskFragment = {
  __typename?: "ManagePersonalTask";
} & Pick<
  ManagePersonalTask,
  | "taskOwnerId"
  | "taskId"
  | "taskName"
  | "startTimeScheduled"
  | "endTimeScheduled"
  | "priority"
  | "status"
  | "assignedUsers"
  | "assignedGroups"
  | "systemAssignedTo"
  | "notes"
>;

export type FolderStructureFragment = { __typename?: "FolderStructure" } & Pick<
  FolderStructure,
  "folderId" | "folderName" | "folderParentId" | "isInsertable"
> & {
    folderParent?: Maybe<
      { __typename?: "FolderStructure" } & Pick<
        FolderStructure,
        "folderId" | "folderName"
      >
    >;
    lookupTeamGanttId?: Maybe<
      { __typename?: "LookupTeamGanttId" } & Pick<
        LookupTeamGanttId,
        "folderId" | "nodeType" | "teamGanttId"
      >
    >;
    runs?: Maybe<
      Array<
        Maybe<
          { __typename?: "Run" } & Pick<Run, "runId"> & {
              runStages?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "RunStage" } & Pick<
                      RunStage,
                      "runStageId" | "runStageName"
                    >
                  >
                >
              >;
            } & RunFragment
        >
      >
    >;
  };

export type DirectoryFolderFragment = { __typename?: "DirectoryFolder" } & Pick<
  DirectoryFolder,
  "id" | "parentId" | "parentType" | "name" | "type"
>;

export type DirectoryFragment = { __typename?: "DirectoryFolder" } & {
  subFolders?: Maybe<
    Array<
      { __typename?: "DirectoryFolder" } & {
        subFolders?: Maybe<
          Array<
            { __typename?: "DirectoryFolder" } & {
              subFolders?: Maybe<
                Array<
                  { __typename?: "DirectoryFolder" } & {
                    subFolders?: Maybe<
                      Array<
                        {
                          __typename?: "DirectoryFolder";
                        } & DirectoryFolderFragment
                      >
                    >;
                  } & DirectoryFolderFragment
                >
              >;
            } & DirectoryFolderFragment
          >
        >;
      } & DirectoryFolderFragment
    >
  >;
} & DirectoryFolderFragment;

export type IncomingSampleTypeFragment = {
  __typename?: "CollectionSampleType";
} & Pick<
  CollectionSampleType,
  | "incomingSampleTypeId"
  | "sampleTypeId"
  | "dateToProcess"
  | "isActive"
  | "notes"
> & {
    sampleType?: Maybe<
      { __typename?: "LookupSampleType" } & Pick<
        LookupSampleType,
        "sampleTypeId" | "sampleTypeString"
      >
    >;
  };

export type IncomingSampleTaskTableEntryFragment = {
  __typename?: "CollectionTask";
} & Pick<
  CollectionTask,
  | "incomingSampleTaskId"
  | "task"
  | "startTimeScheduled"
  | "status"
  | "groupAssignedTo"
  | "userAssignedTo"
  | "systemAssignedTo"
> & {
    incomingSampleType?: Maybe<
      { __typename?: "CollectionSampleType" } & {
        sampleType?: Maybe<
          { __typename?: "LookupSampleType" } & Pick<
            LookupSampleType,
            "sampleTypeId" | "sampleTypeString"
          >
        >;
        collection?: Maybe<
          { __typename?: "Collection" } & Pick<Collection, "collectionId"> & {
              project?: Maybe<
                { __typename?: "Project" } & Pick<
                  Project,
                  "projectId" | "projectName"
                >
              >;
            }
        >;
      }
    >;
  };

export type IncomingSampleTaskFragment = {
  __typename?: "CollectionTask";
} & Pick<
  CollectionTask,
  | "completedBy"
  | "completedByTime"
  | "endTimeActual"
  | "endTimeScheduled"
  | "startTimeActual"
> &
  IncomingSampleTaskTableEntryFragment;

export type IncomingSampleCollectionFragment = {
  __typename?: "Collection";
} & Pick<
  Collection,
  | "collectionId"
  | "createdBy"
  | "projectId"
  | "dateTimeOfArrival"
  | "dateTimeOfCollection"
> & {
    collectionSampleTypes?: Maybe<
      Array<
        Maybe<
          { __typename?: "CollectionSampleType" } & IncomingSampleTypeFragment
        >
      >
    >;
    project?: Maybe<
      { __typename?: "Project" } & Pick<
        Project,
        "projectId" | "projectName" | "nyscfprefix"
      >
    >;
  };

export type UploadIdfFormResponseFragment = {
  __typename?: "UploadIdfFormResponse";
} & Pick<UploadIdfFormResponse, "success" | "traceID" | "message" | "errors">;

export type ManualTaskTypeFragment = { __typename?: "ManualTaskType" } & Pick<
  ManualTaskType,
  "id" | "name" | "documentationUrl"
>;

export type MethodFragment = { __typename?: "LookupArrayMethod" } & Pick<
  LookupArrayMethod,
  "methodId" | "methodName" | "documentationUrl" | "isActive"
> & {
    methodType?: Maybe<
      { __typename?: "LookupArrayMethodType" } & Pick<
        LookupArrayMethodType,
        | "methodType"
        | "methodTypeId"
        | "runSpecific"
        | "inactivatesSourcePlates"
        | "excludeSourcePlatesReservedForInactivation"
      >
    >;
  };

export type ImagingWorklistFragment = {
  __typename?: "WorklistContentImaging";
} & Pick<
  WorklistContentImaging,
  | "barcode"
  | "platePitch"
  | "experimentSetting"
  | "analysisSetting"
  | "imageExportFormat"
  | "imageExportSetting"
>;

export type PersonalTaskAttachmentFragment = {
  __typename?: "PersonalTaskAttachment";
} & Pick<
  PersonalTaskAttachment,
  | "personalTaskAttachmentId"
  | "personalTaskId"
  | "attachmentName"
  | "attachmentUrl"
  | "dateUploaded"
  | "uploadedBy"
  | "comments"
>;

export type PlateCreatedFragment = { __typename?: "PlatesCreated" } & Pick<
  PlatesCreated,
  "plateCreatedId" | "plateBarcode" | "isActive"
> & {
    labwareType?: Maybe<
      { __typename?: "LookupLabwareType" } & Pick<
        LookupLabwareType,
        "plateLayoutTypeId" | "platePitch"
      >
    >;
    arrayPlateType?: Maybe<
      { __typename?: "LookupArrayPlateTypeString" } & {
        defaultLabwareType?: Maybe<
          { __typename?: "LookupLabwareType" } & Pick<
            LookupLabwareType,
            "plateLayoutTypeId" | "platePitch"
          >
        >;
      }
    >;
    containers?: Maybe<
      Array<
        Maybe<
          { __typename?: "Container" } & Pick<Container, "containerId"> & {
              sample?: Maybe<
                { __typename?: "Sample" } & Pick<
                  Sample,
                  "sampleId" | "sampleBarcode"
                >
              >;
            }
        >
      >
    >;
  };

export type PlateFragment = { __typename?: "PlatesCreated" } & Pick<
  PlatesCreated,
  | "plateCreatedId"
  | "plateBarcode"
  | "plateBarcodeAlias"
  | "comments"
  | "dateCreated"
  | "createdByUserId"
  | "isDead"
  | "isIntermediate"
  | "isActive"
  | "lastScanDate"
> & {
    plateNotes?: Maybe<
      Array<
        Maybe<
          { __typename: "PlateNote" } & Pick<
            PlateNote,
            "plateNoteId" | "note" | "dateCommented"
          > & {
              user?: Maybe<
                { __typename?: "AppSuiteUser" } & Pick<
                  AppSuiteUser,
                  "userId" | "userName"
                >
              >;
            }
        >
      >
    >;
    productionRun?: Maybe<
      { __typename?: "ProductionRun" } & Pick<
        ProductionRun,
        "runName" | "runId"
      >
    >;
    arrayPlateType?: Maybe<
      { __typename?: "LookupArrayPlateTypeString" } & Pick<
        LookupArrayPlateTypeString,
        "plateLayoutTypeId"
      > & {
          plateLayoutType?: Maybe<
            { __typename?: "PlateType" } & Pick<PlateType, "plateTypeId">
          >;
          defaultLabwareType?: Maybe<
            { __typename?: "LookupLabwareType" } & Pick<
              LookupLabwareType,
              "plateLayoutTypeId"
            >
          >;
        }
    >;
  };

export type ContainerMetadataFragment = {
  __typename?: "ContainerMetaData";
} & Pick<
  ContainerMetaData,
  | "rowCol"
  | "rowPos"
  | "colPos"
  | "barcode"
  | "isActive"
  | "passageNumber"
  | "lastDataPointSummary"
  | "lastDataPointValue"
  | "markingSummary"
  | "contaminatedMarking"
  | "differentiatedMarking"
  | "noCellsMarking"
  | "clonalityMarking"
  | "qcpassFailMarking"
  | "colonyMarking"
  | "sangerSeqMarking"
  | "conReviewMarking"
  | "fibroblastLotNumber"
  | "bloodProcessingLotNumber"
  | "consolidationLotNumber"
  | "reprogrammingLotNumber"
  | "spitLotNumber"
  | "monoclonalizationLotNumber"
  | "expansionLotNumber"
  | "geneEditingLotNumber"
  | "dnaextractionLotNumber"
  | "externalLotNumber"
  | "otherLotNumber"
  | "collaboratorId"
  | "nyscfid"
  | "sourceCellLine"
  | "lotNumber"
  | "sampleBarcode"
  | "sampleBarcodeAliases"
>;

export type PlateInfoFragment = { __typename?: "PlatesCreated" } & {
  containers?: Maybe<
    Array<
      Maybe<
        { __typename?: "Container" } & Pick<Container, "containerId"> & {
            containerNotes?: Maybe<
              Array<
                Maybe<
                  { __typename: "ContainerNote" } & Pick<
                    ContainerNote,
                    "containerNoteId" | "note" | "dateCommented"
                  > & {
                      user?: Maybe<
                        { __typename?: "AppSuiteUser" } & Pick<
                          AppSuiteUser,
                          "userId" | "userName"
                        >
                      >;
                    }
                >
              >
            >;
            containerMetadata?: Maybe<
              { __typename?: "ContainerMetaData" } & ContainerMetadataFragment
            >;
          }
      >
    >
  >;
} & PlateFragment;

export type ContainerLineageFragment = {
  __typename?: "ContainerLineage";
} & Pick<
  ContainerLineage,
  | "containerId"
  | "hierarchy"
  | "plateBarcode"
  | "rowPos"
  | "colPos"
  | "sample"
  | "tubeBarcode"
  | "dateCreated"
  | "passageNumber"
  | "lotNumber"
  | "isActive"
  | "finalConfluence"
  | "lN2SAMActivity"
  | "comments"
>;

export type SampleFragment = { __typename?: "Sample" } & Pick<
  Sample,
  | "sampleId"
  | "sampleBarcode"
  | "sampleBarcodeAliases"
  | "description"
  | "dateAdded"
  | "registeredByUserId"
>;

export type ContainerTableEntryFragment = { __typename?: "Container" } & Pick<
  Container,
  | "containerId"
  | "barcode"
  | "dateCreated"
  | "colPos"
  | "rowPos"
  | "rowCol"
  | "dateInactivated"
  | "isActive"
> & {
    inactivationReason?: Maybe<
      { __typename?: "LookupInactivationReason" } & Pick<
        LookupInactivationReason,
        "reason"
      >
    >;
    sample?: Maybe<{ __typename?: "Sample" } & SampleFragment>;
    plate?: Maybe<
      { __typename?: "PlatesCreated" } & Pick<
        PlatesCreated,
        "plateCreatedId" | "plateBarcode"
      >
    >;
    lotNumber?: Maybe<
      { __typename?: "LotNumber" } & Pick<
        LotNumber,
        "lotNumberId" | "lotNumber1"
      >
    >;
  };

export type LabwareTypeFragment = { __typename?: "LookupLabwareType" } & Pick<
  LookupLabwareType,
  | "plateLayoutTypeId"
  | "labwareTypeId"
  | "labwareTypeSummary"
  | "labwareTypeName"
  | "labwareTypeCode"
  | "manufacturer"
  | "description"
  | "defaultLabwareVolume"
  | "minLabwareVolume"
  | "maxLabwareVolume"
> & {
    defaultArrayPlateCodeNavigation?: Maybe<
      { __typename?: "LookupArrayPlateCode" } & Pick<
        LookupArrayPlateCode,
        "arrayPlateCode"
      >
    >;
    plateLayoutType?: Maybe<
      { __typename?: "PlateType" } & Pick<PlateType, "plateRows" | "plateCols">
    >;
  };

export type RunFragment = { __typename?: "Run" } & Pick<
  Run,
  | "runId"
  | "runName"
  | "runDateScheduled"
  | "runOwner"
  | "comments"
  | "isActive"
  | "status"
  | "folderParentId"
> & {
    lookupTeamGanttId?: Maybe<
      { __typename?: "LookupTeamGanttId" } & Pick<
        LookupTeamGanttId,
        "teamGanttId" | "id" | "runId" | "dateCreated" | "nodeType"
      >
    >;
    runStages?: Maybe<
      Array<
        Maybe<
          { __typename?: "RunStage" } & Pick<
            RunStage,
            "runId" | "runStageId" | "runStageName"
          >
        >
      >
    >;
  };

export type RunTaskDetailsFragment = { __typename?: "RunTaskDetail" } & Pick<
  RunTaskDetail,
  | "runTaskId"
  | "runTaskDetailsId"
  | "daughterWellNumber"
  | "seedCellCount"
  | "freezeCellCount"
  | "freezeDownRackType"
  | "createMystplate"
  | "destPlateCode"
  | "minCryovial"
  | "maxCryovial"
  | "overflowVials"
  | "imageExportFormat"
  | "imageExportSetting"
  | "worklistTemplateId"
  | "intermediateLabwareTypeId"
>;

export type SimpleRunTaskFragment = { __typename?: "RunTask" } & Pick<
  RunTask,
  | "runTaskId"
  | "runId"
  | "runStageId"
  | "methodId"
  | "runTemplateTaskId"
  | "activityName"
  | "endTimeScheduled"
  | "startTimeScheduled"
  | "endTimeActual"
  | "daysFromRunStart"
  | "systemAssignedTo"
  | "status"
  | "priority"
  | "notes"
  | "manualTaskTypeId"
> & {
    users?: Maybe<
      Array<
        Maybe<{ __typename?: "AppSuiteUser" } & Pick<AppSuiteUser, "userId">>
      >
    >;
    groups?: Maybe<
      Array<
        Maybe<{ __typename?: "AppSuiteGroup" } & Pick<AppSuiteGroup, "groupId">>
      >
    >;
    runTaskDetail?: Maybe<
      { __typename?: "RunTaskDetail" } & RunTaskDetailsFragment
    >;
  };

export type RunTaskFragment = { __typename?: "RunTask" } & Pick<
  RunTask,
  | "runTaskId"
  | "runId"
  | "runStageId"
  | "methodId"
  | "runTemplateTaskId"
  | "activityName"
  | "endTimeScheduled"
  | "startTimeScheduled"
  | "endTimeActual"
  | "startTimeActual"
  | "daysFromRunStart"
  | "systemAssignedTo"
  | "status"
  | "priority"
  | "notes"
  | "manualTaskTypeId"
> & {
    users?: Maybe<
      Array<
        Maybe<{ __typename?: "AppSuiteUser" } & Pick<AppSuiteUser, "userId">>
      >
    >;
    groups?: Maybe<
      Array<
        Maybe<{ __typename?: "AppSuiteGroup" } & Pick<AppSuiteGroup, "groupId">>
      >
    >;
    run?: Maybe<{ __typename?: "Run" } & RunFragment>;
  };

export type ReschedulingTaskFragment = { __typename?: "RunTask" } & Pick<
  RunTask,
  | "runTaskId"
  | "runId"
  | "activityName"
  | "startTimeScheduled"
  | "endTimeScheduled"
  | "startTimeActual"
  | "endTimeActual"
  | "status"
  | "systemAssignedTo"
>;

export type ProductionRunFragment = { __typename?: "ProductionRun" } & Pick<
  ProductionRun,
  "runId"
> & {
    platesCreateds?: Maybe<
      Array<Maybe<{ __typename?: "PlatesCreated" } & PlateCreatedFragment>>
    >;
  };

export type RunInfoFragment = { __typename?: "Run" } & Pick<
  Run,
  "runId" | "productionRunId"
> & {
    productionRun?: Maybe<
      { __typename?: "ProductionRun" } & ProductionRunFragment
    >;
  } & RunFragment;

export type RunTaskMetaDataFragment = { __typename?: "RunTask" } & Pick<
  RunTask,
  "runTaskId"
> & {
    method?: Maybe<{ __typename?: "LookupArrayMethod" } & MethodFragment>;
    manualTaskType?: Maybe<
      { __typename?: "ManualTaskType" } & ManualTaskTypeFragment
    >;
    run?: Maybe<{ __typename?: "Run" } & RunInfoFragment>;
    runTaskDetail?: Maybe<
      { __typename?: "RunTaskDetail" } & RunTaskDetailsFragment
    >;
    methodReservations?: Maybe<
      Array<
        Maybe<
          { __typename?: "MethodReservation" } & Pick<
            MethodReservation,
            "methodReservationId" | "date" | "isCompleted"
          > & {
              worklist?: Maybe<
                { __typename?: "Worklist" } & {
                  worklistContentImagings?: Maybe<
                    Array<
                      Maybe<
                        {
                          __typename?: "WorklistContentImaging";
                        } & ImagingWorklistFragment
                      >
                    >
                  >;
                }
              >;
            }
        >
      >
    >;
  };

export type RunTaskAttachmentFragment = {
  __typename?: "RunTaskAttachment";
} & Pick<
  RunTaskAttachment,
  | "runTaskAttachmentId"
  | "runTaskId"
  | "attachmentName"
  | "attachmentUrl"
  | "dateUploaded"
  | "uploadedBy"
  | "comments"
>;

export type RunTemplateTaskFragment = { __typename?: "RunTemplateTask" } & Pick<
  RunTemplateTask,
  | "runTemplateId"
  | "runTemplateStageId"
  | "runTemplateTaskId"
  | "customTaskName"
  | "systemAssignedTo"
  | "dayScheduled"
  | "runTemplateParentTaskId"
  | "notes"
  | "manualTaskTypeId"
  | "methodId"
> & {
    users?: Maybe<
      Array<
        Maybe<{ __typename?: "AppSuiteUser" } & Pick<AppSuiteUser, "userId">>
      >
    >;
    groups?: Maybe<
      Array<
        Maybe<{ __typename?: "AppSuiteGroup" } & Pick<AppSuiteGroup, "groupId">>
      >
    >;
    runTemplatesRepeatDaysOfWeeks?: Maybe<
      Array<
        Maybe<
          { __typename?: "RunTemplatesRepeatDaysOfWeek" } & Pick<
            RunTemplatesRepeatDaysOfWeek,
            "runTemplateRepeatDayOfWeekId" | "dayOfWeek" | "numberOfWeeks"
          >
        >
      >
    >;
    method?: Maybe<{ __typename?: "LookupArrayMethod" } & MethodFragment>;
    manualTaskType?: Maybe<
      { __typename?: "ManualTaskType" } & ManualTaskTypeFragment
    >;
    runTemplateTaskDetail?: Maybe<
      { __typename?: "RunTemplateTaskDetail" } & Pick<
        RunTemplateTaskDetail,
        | "runTemplateTaskId"
        | "runTemplateTaskDetailsId"
        | "daughterWellNumber"
        | "seedCellCount"
        | "freezeCellCount"
        | "freezeDownRackType"
        | "createMystplate"
        | "destPlateCode"
        | "minCryovial"
        | "maxCryovial"
        | "overflowVials"
        | "imageExportFormat"
        | "imageExportSetting"
        | "worklistTemplateId"
        | "intermediateLabwareTypeId"
      >
    >;
  };

export type RunTemplateStageFragment = {
  __typename?: "RunTemplateStage";
} & Pick<
  RunTemplateStage,
  "runTemplateId" | "runTemplateStageId" | "templateStageName" | "stageSeqNo"
> & {
    runTemplateTreeNodes?: Maybe<
      Array<
        Maybe<
          { __typename?: "RunTemplateTreeNode" } & Pick<
            RunTemplateTreeNode,
            "nodeId" | "parentId" | "dayScheduled"
          > & {
              child?: Maybe<
                { __typename?: "RunTemplate" } & Pick<
                  RunTemplate,
                  | "runTemplateId"
                  | "runTemplateName"
                  | "numberOfSubTemplates"
                  | "numberOfTasks"
                >
              >;
            }
        >
      >
    >;
    runTemplateTasks?: Maybe<
      Array<Maybe<{ __typename?: "RunTemplateTask" } & RunTemplateTaskFragment>>
    >;
  };

export type RunTemplateFragment = { __typename?: "RunTemplate" } & Pick<
  RunTemplate,
  | "runTemplateId"
  | "runTemplateName"
  | "productionRunTypeId"
  | "createdByV"
  | "createdBy"
  | "numberOfSubTemplates"
  | "numberOfTasks"
  | "lastUpdatedBy"
  | "lastTimeUpdated"
  | "folderLocationId"
> & {
    productionRunTypeNavigation?: Maybe<
      { __typename?: "ProductionRunType" } & Pick<
        ProductionRunType,
        "productionRunTypeId" | "typeName" | "runNumberDigits"
      >
    >;
    folderLocation?: Maybe<
      { __typename?: "FolderStructure" } & Pick<
        FolderStructure,
        "folderId" | "folderName"
      >
    >;
    groups?: Maybe<
      Array<
        Maybe<{ __typename?: "AppSuiteGroup" } & Pick<AppSuiteGroup, "groupId">>
      >
    >;
    runTemplateStages?: Maybe<
      Array<
        Maybe<{ __typename?: "RunTemplateStage" } & RunTemplateStageFragment>
      >
    >;
  };

export type TicketFragment = { __typename?: "Ticket" } & Pick<
  Ticket,
  | "ticketId"
  | "title"
  | "priority"
  | "status"
  | "description"
  | "createdByUserId"
  | "dateCreated"
  | "assetId"
  | "dateEncountered"
  | "lastUpdated"
  | "percentComplete"
  | "slackMessageTimestamp"
> & {
    ticketAssignees?: Maybe<
      Array<
        Maybe<
          { __typename?: "TicketAssignee" } & Pick<TicketAssignee, "userId">
        >
      >
    >;
    ticketFollowers?: Maybe<
      Array<
        Maybe<
          { __typename?: "TicketFollower" } & Pick<TicketFollower, "userId">
        >
      >
    >;
    asset?: Maybe<
      { __typename?: "LookupAsset" } & Pick<
        LookupAsset,
        "assetId" | "asset"
      > & {
          assetType?: Maybe<
            { __typename?: "LookupAssetType" } & Pick<
              LookupAssetType,
              "assetTypeId" | "assetType"
            >
          >;
        }
    >;
    ticketType?: Maybe<
      { __typename?: "LookupTicketType" } & Pick<
        LookupTicketType,
        "ticketTypeId" | "ticketType"
      > & {
          userGroup?: Maybe<
            { __typename?: "AppSuiteGroup" } & Pick<
              AppSuiteGroup,
              "groupId" | "groupName"
            >
          >;
        }
    >;
    ticketUpdates?: Maybe<
      Array<
        Maybe<
          { __typename?: "TicketUpdate" } & Pick<
            TicketUpdate,
            "dateUpdated" | "updatedByUserId" | "summary"
          >
        >
      >
    >;
  };

export type TicketAttachmentsFragment = { __typename?: "Ticket" } & Pick<
  Ticket,
  "ticketId"
> & {
    ticketAttachments?: Maybe<
      Array<
        Maybe<
          { __typename?: "TicketAttachment" } & Pick<
            TicketAttachment,
            | "ticketAttachmentId"
            | "attachmentUrl"
            | "attachmentName"
            | "dateUploaded"
            | "uploadedBy"
          >
        >
      >
    >;
  };

export type TicketAndAttachmentsFragment = {
  __typename?: "Ticket";
} & TicketFragment &
  TicketAttachmentsFragment;

export type TubeLabellerDestinationWorklistFragment = {
  __typename?: "TubeLabellerDestinationWorklist";
} & Pick<
  TubeLabellerDestinationWorklist,
  | "dSTRack"
  | "dSTPosition"
  | "sRCRack"
  | "sRCPosition"
  | "dSTRackBarcode"
  | "dSTLinePosition"
  | "factoryBarcode"
  | "field1"
  | "field2"
  | "field3"
  | "field4"
  | "field5"
  | "field6"
  | "field7"
>;

export type UserFragment = { __typename?: "AppSuiteUser" } & Pick<
  AppSuiteUser,
  "userId" | "userName" | "email" | "roles" | "isActive" | "isAdmin"
> & {
    groups?: Maybe<
      Array<
        Maybe<
          { __typename?: "AppSuiteGroup" } & Pick<
            AppSuiteGroup,
            "groupId" | "groupName"
          >
        >
      >
    >;
  };

export type GroupFragment = { __typename?: "AppSuiteGroup" } & Pick<
  AppSuiteGroup,
  "groupId" | "groupName"
> & {
    users?: Maybe<
      Array<
        Maybe<
          { __typename?: "AppSuiteUser" } & Pick<
            AppSuiteUser,
            "userId" | "userName"
          >
        >
      >
    >;
  };

export type UserClaimsFragment = { __typename?: "UserClaims" } & Pick<
  UserClaims,
  "userId" | "userName" | "isAdmin" | "isGroupAccount"
> & {
    setting?: Maybe<
      { __typename?: "UserSetting" } & Pick<
        UserSetting,
        "id" | "userId" | "settingsJson"
      >
    >;
    groupAccount?: Maybe<{ __typename?: "AppSuiteGroup" } & GroupFragment>;
    loggedInGroupMember?: Maybe<
      { __typename?: "LoggedInGroupMember" } & {
        labComputer: { __typename?: "LabComputer" } & Pick<
          LabComputer,
          "id" | "name"
        >;
        user?: Maybe<
          { __typename?: "User" } & Pick<
            User,
            "userId" | "userName" | "isAdmin" | "email"
          >
        >;
      }
    >;
  };

export type WorklistToolTemplateFragment = {
  __typename?: "WorklistToolTemplate";
} & Pick<
  WorklistToolTemplate,
  | "worklistToolTemplateId"
  | "templateName"
  | "methodId"
  | "createdBy"
  | "lastUpdatedBy"
  | "lastUpdateByTime"
> & {
    worklistToolTemplateContents?: Maybe<
      Array<
        Maybe<
          { __typename?: "WorklistToolTemplateContent" } & Pick<
            WorklistToolTemplateContent,
            | "task"
            | "details"
            | "aspirateVol"
            | "dispenseVol"
            | "transferVol"
            | "sourcePlateType"
            | "sourcePlatePosition"
            | "sourceWellId"
            | "destinationPlateType"
            | "destinationPlatePosition"
            | "destinationWellId"
          >
        >
      >
    >;
    worklistToolTemplatePlates?: Maybe<
      Array<
        Maybe<
          { __typename?: "WorklistToolTemplatePlate" } & Pick<
            WorklistToolTemplatePlate,
            | "plateType"
            | "plateIndex"
            | "labwareTypeCode"
            | "numberOfRows"
            | "numberOfColumns"
            | "operatingVol"
            | "resuspensionVol"
            | "startingVol"
            | "preprocess"
            | "topup"
          >
        >
      >
    >;
  };

export type WorklistToolTemplateContentsFragment = {
  __typename?: "WorklistToolTemplateContent";
} & Pick<
  WorklistToolTemplateContent,
  | "task"
  | "details"
  | "aspirateVol"
  | "dispenseVol"
  | "transferVol"
  | "sourcePlateType"
  | "sourcePlatePosition"
  | "sourceWellId"
  | "destinationPlateType"
  | "destinationPlatePosition"
  | "destinationWellId"
>;

export type UpdateDashboardTasksMutationVariables = Exact<{
  dashboardTasks?: Maybe<
    Array<Maybe<UpdateDashboardTaskInput>> | Maybe<UpdateDashboardTaskInput>
  >;
}>;

export type UpdateDashboardTasksMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "updateDashboardTasks"
>;

export type CreateFeedReservationMutationVariables = Exact<{
  plates?: Maybe<SystemOneFeedReservationInfoInput>;
}>;

export type CreateFeedReservationMutation = { __typename?: "Mutation" } & {
  reserveFeedWorklist?: Maybe<
    { __typename?: "SystemOneFeedWorklist" } & Pick<
      SystemOneFeedWorklist,
      "worklistID" | "methodReservationID"
    >
  >;
};

export type ReserveCeligoPersonalTaskMutationVariables = Exact<{
  task?: Maybe<DashboardTaskInput>;
}>;

export type ReserveCeligoPersonalTaskMutation = { __typename?: "Mutation" } & {
  reserveCeligoPersonalTaskMethod?: Maybe<
    { __typename?: "DashboardTask" } & DashboardTaskFragment
  >;
};

export type LogMutationVariables = Exact<{
  log: LogInput;
}>;

export type LogMutation = { __typename?: "Mutation" } & {
  log?: Maybe<{ __typename?: "LogResponse" } & Pick<LogResponse, "success">>;
};

export type CreateRunFolderMutationVariables = Exact<{
  input: CreateRunFolderInput;
}>;

export type CreateRunFolderMutation = { __typename?: "Mutation" } & {
  createRunFolder?: Maybe<
    { __typename?: "CreateRunFolderPayload" } & {
      directoryFolder?: Maybe<
        { __typename?: "DirectoryFolder" } & {
          subFolders?: Maybe<
            Array<{ __typename?: "DirectoryFolder" } & DirectoryFolderFragment>
          >;
        } & DirectoryFolderFragment
      >;
      folderStructure?: Maybe<
        { __typename?: "FolderStructure" } & FolderStructureFragment
      >;
    }
  >;
};

export type DeleteRunFolderMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteRunFolderMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteRunFolder"
>;

export type AddIdfUploadMutationVariables = Exact<{
  input: UploadIdfFormInput;
}>;

export type AddIdfUploadMutation = { __typename?: "Mutation" } & {
  addIdfUpload?: Maybe<
    { __typename?: "UploadIdfFormResponse" } & UploadIdfFormResponseFragment
  >;
};

export type ValidateIdfUploadMutationVariables = Exact<{
  input: UploadIdfFormInput;
}>;

export type ValidateIdfUploadMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "validateIdfUpload"
>;

export type UpdateCollectionSampleTypeMutationVariables = Exact<{
  input: UpdateInputOfUpdateIncomingSampleTypeInputAndCollectionSampleTypeInput;
}>;

export type UpdateCollectionSampleTypeMutation = { __typename?: "Mutation" } & {
  updateIncomingSampleType?: Maybe<
    { __typename?: "Collection" } & IncomingSampleCollectionFragment
  >;
};

export type AddCollectionSampleTypeMutationVariables = Exact<{
  input: CreateIncomingSampleTypeInput;
}>;

export type AddCollectionSampleTypeMutation = { __typename?: "Mutation" } & {
  addIncomingSampleType?: Maybe<
    { __typename?: "Collection" } & IncomingSampleCollectionFragment
  >;
};

export type DeleteCollectionSampleTypeMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteCollectionSampleTypeMutation = { __typename?: "Mutation" } & {
  deleteCollectionSampleType?: Maybe<
    { __typename?: "Collection" } & IncomingSampleCollectionFragment
  >;
};

export type CreateCollectionMutationVariables = Exact<{
  input: CreateIncomingSampleCollectionInput;
}>;

export type CreateCollectionMutation = { __typename?: "Mutation" } & {
  createIncomingSampleCollection?: Maybe<
    { __typename?: "Collection" } & IncomingSampleCollectionFragment
  >;
};

export type DeleteIncomingSampleCollectionMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteIncomingSampleCollectionMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "deleteIncomingSampleCollection">;

export type UpdateIncomingSampleCollectionMutationVariables = Exact<{
  inputs:
    | Array<UpdateInputOfUpdateIncomingSampleCollectionInputAndCollectionInput>
    | UpdateInputOfUpdateIncomingSampleCollectionInputAndCollectionInput;
}>;

export type UpdateIncomingSampleCollectionMutation = {
  __typename?: "Mutation";
} & {
  updateIncomingSampleCollections?: Maybe<
    { __typename?: "Collection" } & IncomingSampleCollectionFragment
  >;
};

export type UpdateCollectionTasksMutationVariables = Exact<{
  inputs: Array<CollectionTaskInput> | CollectionTaskInput;
}>;

export type UpdateCollectionTasksMutation = { __typename?: "Mutation" } & {
  updateCollectionTasks?: Maybe<
    Array<Maybe<{ __typename?: "CollectionTask" } & IncomingSampleTaskFragment>>
  >;
};

export type AddAssetMutationVariables = Exact<{
  input?: Maybe<AssetInput>;
}>;

export type AddAssetMutation = { __typename?: "Mutation" } & {
  addAsset?: Maybe<
    { __typename?: "LookupAsset" } & Pick<
      LookupAsset,
      "assetTypeId" | "assetId" | "asset"
    > & {
        assetType?: Maybe<
          { __typename?: "LookupAssetType" } & Pick<
            LookupAssetType,
            "assetTypeId" | "assetType"
          >
        >;
      }
  >;
};

export type UpdateManualTaskTypeMutationVariables = Exact<{
  inputs?: Maybe<
    | Array<UpdateInputOfUpdateManualTaskInputAndManualTaskTypeInput>
    | UpdateInputOfUpdateManualTaskInputAndManualTaskTypeInput
  >;
}>;

export type UpdateManualTaskTypeMutation = { __typename?: "Mutation" } & {
  updateManualTasks?: Maybe<
    Array<Maybe<{ __typename?: "ManualTaskType" } & ManualTaskTypeFragment>>
  >;
};

export type UpdateMethodMutationVariables = Exact<{
  inputs?: Maybe<
    | Array<UpdateInputOfUpdateMethodInputAndLookupArrayMethodInput>
    | UpdateInputOfUpdateMethodInputAndLookupArrayMethodInput
  >;
}>;

export type UpdateMethodMutation = { __typename?: "Mutation" } & {
  updateMethods?: Maybe<
    Array<Maybe<{ __typename?: "LookupArrayMethod" } & MethodFragment>>
  >;
};

export type UpdateRunTaskTypeDocumentationMutationVariables = Exact<{
  input?: Maybe<UpdateMethodDocumentationInput>;
}>;

export type UpdateRunTaskTypeDocumentationMutation = {
  __typename?: "Mutation";
} & {
  updateRunTaskTypeDocumentation?: Maybe<
    { __typename?: "RunTaskType" } & Pick<
      RunTaskType,
      "id" | "compositeKey" | "type"
    > & {
        manualTaskType?: Maybe<
          { __typename?: "ManualTaskType" } & ManualTaskTypeFragment
        >;
        method?: Maybe<
          Array<Maybe<{ __typename?: "LookupArrayMethod" } & MethodFragment>>
        >;
      }
  >;
};

export type AddPersonalTaskMutationVariables = Exact<{
  model?: Maybe<PersonalTaskInput>;
}>;

export type AddPersonalTaskMutation = { __typename?: "Mutation" } & {
  addPersonalTask?: Maybe<
    { __typename?: "PersonalTask" } & Pick<PersonalTask, "taskId">
  >;
};

export type AddRecurringPersonalTaskMutationVariables = Exact<{
  model?: Maybe<PersonalTaskInput>;
}>;

export type AddRecurringPersonalTaskMutation = { __typename?: "Mutation" } & {
  addRecurringPersonalTask?: Maybe<
    { __typename?: "PersonalTasksRecurringTask" } & Pick<
      PersonalTasksRecurringTask,
      "recurringTaskId"
    >
  >;
};

export type UpdatePersonalTasksMutationVariables = Exact<{
  inputs?: Maybe<Array<Maybe<PersonalTaskInput>> | Maybe<PersonalTaskInput>>;
}>;

export type UpdatePersonalTasksMutation = { __typename?: "Mutation" } & {
  updatePersonalTasks?: Maybe<
    Array<Maybe<{ __typename?: "PersonalTask" } & Pick<PersonalTask, "taskId">>>
  >;
};

export type DeleteRecurringPersonalTaskMutationVariables = Exact<{
  recurringTaskId: Scalars["Int"];
}>;

export type DeleteRecurringPersonalTaskMutation = {
  __typename?: "Mutation";
} & {
  deleteRecurringPersonalTask?: Maybe<
    { __typename?: "PersonalTasksRecurringTask" } & Pick<
      PersonalTasksRecurringTask,
      "recurringTaskId"
    >
  >;
};

export type UpdateRecurringTaskMutationVariables = Exact<{
  recurringTask?: Maybe<PersonalTasksRecurringTaskInput>;
}>;

export type UpdateRecurringTaskMutation = { __typename?: "Mutation" } & {
  updateRecurringTask?: Maybe<
    { __typename?: "PersonalTasksRecurringTask" } & Pick<
      PersonalTasksRecurringTask,
      "recurringTaskId"
    >
  >;
};

export type OverwriteRecurringTasksMutationVariables = Exact<{
  input?: Maybe<OverwriteRecurringTaskInput>;
}>;

export type OverwriteRecurringTasksMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "overwriteRecurringTasks">;

export type CancelRemainingReccuringTasksMutationVariables = Exact<{
  recurringTaskId: Scalars["Int"];
}>;

export type CancelRemainingReccuringTasksMutation = {
  __typename?: "Mutation";
} & {
  cancelRemainingReccuringTasks?: Maybe<
    { __typename?: "PersonalTasksRecurringTask" } & Pick<
      PersonalTasksRecurringTask,
      "recurringTaskId"
    >
  >;
};

export type CreatePersonalTaskTemplateMutationVariables = Exact<{
  templateName?: Maybe<Scalars["String"]>;
}>;

export type CreatePersonalTaskTemplateMutation = { __typename?: "Mutation" } & {
  createPersonalTaskTemplate?: Maybe<
    { __typename?: "PersonalTaskTemplate" } & Pick<
      PersonalTaskTemplate,
      "personalTaskTemplateId"
    >
  >;
};

export type AddPersonalTaskTemplateTaskMutationVariables = Exact<{
  input?: Maybe<PersonalTaskTemplateTaskInput>;
}>;

export type AddPersonalTaskTemplateTaskMutation = {
  __typename?: "Mutation";
} & {
  addPersonalTaskTemplateTask?: Maybe<
    { __typename?: "PersonalTaskTemplate" } & Pick<
      PersonalTaskTemplate,
      "personalTaskTemplateId"
    >
  >;
};

export type StartPersonalTaskTemplateMutationVariables = Exact<{
  input?: Maybe<StartPersonalTaskTemplateInput>;
}>;

export type StartPersonalTaskTemplateMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "startPersonalTaskTemplate">;

export type UpdatePersonalTaskTemplateTaskMutationVariables = Exact<{
  input?: Maybe<PersonalTaskTemplateTaskInput>;
}>;

export type UpdatePersonalTaskTemplateTaskMutation = {
  __typename?: "Mutation";
} & {
  updatePersonalTaskTemplateTask?: Maybe<
    { __typename?: "PersonalTaskTemplateTask" } & Pick<
      PersonalTaskTemplateTask,
      | "personalTaskTemplateId"
      | "personalTaskTemplateTaskId"
      | "personalTaskName"
      | "dayScheduled"
      | "userAssignedTo"
      | "groupAssignedTo"
      | "notes"
      | "priority"
    > & {
        groups?: Maybe<
          Array<
            Maybe<
              { __typename?: "AppSuiteGroup" } & Pick<AppSuiteGroup, "groupId">
            >
          >
        >;
        users?: Maybe<
          Array<
            Maybe<
              { __typename?: "AppSuiteUser" } & Pick<AppSuiteUser, "userId">
            >
          >
        >;
      }
  >;
};

export type CreateRunMutationVariables = Exact<{
  run?: Maybe<CreateRunInput>;
}>;

export type CreateRunMutation = { __typename?: "Mutation" } & {
  createRun?: Maybe<{ __typename?: "Run" } & RunFragment>;
};

export type UpdateRunTasksMutationVariables = Exact<{
  updateInputs?: Maybe<Array<Maybe<RunTaskInput>> | Maybe<RunTaskInput>>;
}>;

export type UpdateRunTasksMutation = { __typename?: "Mutation" } & {
  updateRunTasks?: Maybe<
    Array<
      Maybe<
        { __typename?: "RunTask" } & RunTaskFragment & RunTaskMetaDataFragment
      >
    >
  >;
};

export type ReserveCeligoRunTaskMethodMutationVariables = Exact<{
  input?: Maybe<RunTaskInput>;
}>;

export type ReserveCeligoRunTaskMethodMutation = { __typename?: "Mutation" } & {
  reserveCeligoRunTaskMethod?: Maybe<
    Array<Maybe<{ __typename?: "RunTask" } & RunTaskMetaDataFragment>>
  >;
};

export type CreateRunGanttMutationVariables = Exact<{
  runId: Scalars["Int"];
}>;

export type CreateRunGanttMutation = { __typename?: "Mutation" } & {
  createRunGantt?: Maybe<{ __typename?: "Run" } & RunFragment>;
};

export type DeleteRunActivityMethodReservationMutationVariables = Exact<{
  deleteInput: DeleteInput;
}>;

export type DeleteRunActivityMethodReservationMutation = {
  __typename?: "Mutation";
} & {
  deleteRunActivityMethodReservation?: Maybe<
    Array<Maybe<{ __typename?: "RunTask" } & RunTaskMetaDataFragment>>
  >;
};

export type DeleteTaskMethodReservationMutationVariables = Exact<{
  deleteInput: DeleteInput;
}>;

export type DeleteTaskMethodReservationMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "deleteTaskMethodReservation">;

export type AddRunTaskMutationVariables = Exact<{
  tasks?: Maybe<Array<Maybe<RunTaskInput>> | Maybe<RunTaskInput>>;
}>;

export type AddRunTaskMutation = { __typename?: "Mutation" } & {
  addRunTasks?: Maybe<
    Array<Maybe<{ __typename?: "RunTask" } & RunTaskFragment>>
  >;
};

export type UpdateRunMutationVariables = Exact<{
  input?: Maybe<UpdateInputOfUpdateRunInputAndRunInput>;
}>;

export type UpdateRunMutation = { __typename?: "Mutation" } & {
  updateRun?: Maybe<
    Array<
      Maybe<
        { __typename?: "FolderStructure" } & Pick<
          FolderStructure,
          "folderId" | "folderName" | "folderParentId"
        > & {
            runs?: Maybe<
              Array<
                Maybe<
                  { __typename?: "Run" } & {
                    runTasks?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: "RunTask" } & Pick<
                            RunTask,
                            | "runTaskId"
                            | "daysFromRunStart"
                            | "startTimeScheduled"
                          >
                        >
                      >
                    >;
                  } & RunFragment
                >
              >
            >;
          }
      >
    >
  >;
};

export type DeleteRunMutationVariables = Exact<{
  input?: Maybe<DeleteInput>;
}>;

export type DeleteRunMutation = { __typename?: "Mutation" } & {
  deleteRun?: Maybe<
    { __typename?: "FolderStructure" } & FolderStructureFragment
  >;
};

export type CreateRunTemplateMutationVariables = Exact<{
  template?: Maybe<CreateRunTemplateInput>;
}>;

export type CreateRunTemplateMutation = { __typename?: "Mutation" } & {
  createRunTemplate?: Maybe<
    { __typename?: "RunTemplate" } & RunTemplateFragment
  >;
};

export type AddRunTemplateStagesMutationVariables = Exact<{
  stages?: Maybe<
    | Array<Maybe<CreateRunTemplateStageInput>>
    | Maybe<CreateRunTemplateStageInput>
  >;
}>;

export type AddRunTemplateStagesMutation = { __typename?: "Mutation" } & {
  addRunTemplateStages?: Maybe<
    { __typename?: "RunTemplate" } & RunTemplateFragment
  >;
};

export type AddRunTemplateTasksMutationVariables = Exact<{
  tasks?: Maybe<
    Array<Maybe<RunTemplateTaskInput>> | Maybe<RunTemplateTaskInput>
  >;
}>;

export type AddRunTemplateTasksMutation = { __typename?: "Mutation" } & {
  addRunTemplateTasks?: Maybe<
    { __typename?: "RunTemplateStage" } & RunTemplateStageFragment
  >;
};

export type AddRunTemplateChildMutationVariables = Exact<{
  childTemplate?: Maybe<CreateRunTemplateTreeNodeInput>;
}>;

export type AddRunTemplateChildMutation = { __typename?: "Mutation" } & {
  addChildRunTemplate?: Maybe<
    { __typename?: "RunTemplate" } & RunTemplateFragment
  >;
};

export type UpdateRunTemplateMutationVariables = Exact<{
  updateInput?: Maybe<UpdateInputOfUpdateRunTemplateInputAndRunTemplateInput>;
}>;

export type UpdateRunTemplateMutation = { __typename?: "Mutation" } & {
  updateRunTemplate?: Maybe<
    { __typename?: "RunTemplate" } & RunTemplateFragment
  >;
};

export type UpdateRunTemplateStagesMutationVariables = Exact<{
  updateInputs?: Maybe<
    | Array<
        Maybe<UpdateInputOfUpdateRunTemplateStageInputAndRunTemplateStageInput>
      >
    | Maybe<UpdateInputOfUpdateRunTemplateStageInputAndRunTemplateStageInput>
  >;
}>;

export type UpdateRunTemplateStagesMutation = { __typename?: "Mutation" } & {
  updateRunTemplateStages?: Maybe<
    Array<Maybe<{ __typename?: "RunTemplate" } & RunTemplateFragment>>
  >;
};

export type UpdateRunTemplateTaskScheduleMutationVariables = Exact<{
  runTemplateTaskId: Scalars["Int"];
  schedule:
    | Array<RunTemplatesRepeatDaysOfWeekInput>
    | RunTemplatesRepeatDaysOfWeekInput;
}>;

export type UpdateRunTemplateTaskScheduleMutation = {
  __typename?: "Mutation";
} & {
  updateRunTemplateTaskSchedule?: Maybe<
    { __typename?: "RunTemplateTask" } & RunTemplateTaskFragment
  >;
};

export type UpdateRunTemplateTasksMutationVariables = Exact<{
  inputs?: Maybe<
    Array<Maybe<RunTemplateTaskInput>> | Maybe<RunTemplateTaskInput>
  >;
  cascade?: Maybe<Scalars["Boolean"]>;
}>;

export type UpdateRunTemplateTasksMutation = { __typename?: "Mutation" } & {
  updateRunTemplateTasks?: Maybe<
    Array<
      Maybe<
        { __typename?: "RunTemplateTask" } & {
          runTasks?: Maybe<
            Array<Maybe<{ __typename?: "RunTask" } & SimpleRunTaskFragment>>
          >;
          runTemplate?: Maybe<
            { __typename?: "RunTemplate" } & Pick<
              RunTemplate,
              | "runTemplateId"
              | "runTemplateName"
              | "lastTimeUpdated"
              | "lastUpdatedBy"
            >
          >;
        } & RunTemplateTaskFragment
      >
    >
  >;
};

export type DeleteRunTemplateMutationVariables = Exact<{
  deleteInput?: Maybe<DeleteInput>;
}>;

export type DeleteRunTemplateMutation = { __typename?: "Mutation" } & {
  deleteTemplate?: Maybe<
    Array<Maybe<{ __typename?: "RunTemplate" } & RunTemplateFragment>>
  >;
};

export type DeleteRunTemplateStageMutationVariables = Exact<{
  deleteInput?: Maybe<DeleteInput>;
}>;

export type DeleteRunTemplateStageMutation = { __typename?: "Mutation" } & {
  removeTemplateStage?: Maybe<
    Array<Maybe<{ __typename?: "RunTemplate" } & RunTemplateFragment>>
  >;
};

export type DeleteRunTemplateTreeNodeMutationVariables = Exact<{
  deleteInput?: Maybe<DeleteInput>;
}>;

export type DeleteRunTemplateTreeNodeMutation = { __typename?: "Mutation" } & {
  deleteRunTemplateTreeNode?: Maybe<
    Array<Maybe<{ __typename?: "RunTemplateStage" } & RunTemplateStageFragment>>
  >;
};

export type CreateTaskAttachmentsMutationVariables = Exact<{
  inputs: Array<TaskAttachmentInput> | TaskAttachmentInput;
  taskId: Scalars["Int"];
  taskType: TaskTypeEnum;
}>;

export type CreateTaskAttachmentsMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "createTaskAttachments"
>;

export type DeleteTaskAttachmentMutationVariables = Exact<{
  taskAttachmentId: Scalars["Int"];
  taskType: TaskTypeEnum;
}>;

export type DeleteTaskAttachmentMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteTaskAttachment"
>;

export type DeleteTasksMutationVariables = Exact<{
  taskIDs: Array<Scalars["Int"]> | Scalars["Int"];
  taskType: TaskTypeEnum;
}>;

export type DeleteTasksMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteTasks"
>;

export type AssignTaskMutationVariables = Exact<{
  taskInput: AssignTaskInput;
}>;

export type AssignTaskMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "assignTask"
>;

export type RescheduleTasksMutationVariables = Exact<{
  models: Array<RescheduleTaskInput> | RescheduleTaskInput;
}>;

export type RescheduleTasksMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "rescheduleTasks"
>;

export type SaveFeedGroupsMutationVariables = Exact<{
  inputs: Array<FeedGroupInput> | FeedGroupInput;
}>;

export type SaveFeedGroupsMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "saveFeedGroups"
>;

export type AddPersonalFeedTasksMutationVariables = Exact<{
  inputs: Array<FeedGroupInput> | FeedGroupInput;
}>;

export type AddPersonalFeedTasksMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "addPersonalFeedTasks"
>;

export type DeleteTemplateTaskMutationVariables = Exact<{
  templateId: Scalars["Int"];
  taskType: TaskTypeEnum;
}>;

export type DeleteTemplateTaskMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteTemplateTask"
>;

export type CreateTicketMutationVariables = Exact<{
  input?: Maybe<CreateTicketInput>;
}>;

export type CreateTicketMutation = { __typename?: "Mutation" } & {
  createTicket?: Maybe<{ __typename?: "Ticket" } & TicketFragment>;
};

export type UpdateTicketsMutationVariables = Exact<{
  inputs?: Maybe<
    | Array<Maybe<UpdateInputOfUpdateTicketInputAndTicketInput>>
    | Maybe<UpdateInputOfUpdateTicketInputAndTicketInput>
  >;
}>;

export type UpdateTicketsMutation = { __typename?: "Mutation" } & {
  updateTickets?: Maybe<
    Array<Maybe<{ __typename?: "Ticket" } & TicketFragment>>
  >;
};

export type AddTicketUpdateMutationVariables = Exact<{
  input?: Maybe<CreateTicketUpdateInput>;
}>;

export type AddTicketUpdateMutation = { __typename?: "Mutation" } & {
  addTicketUpdate?: Maybe<{ __typename?: "Ticket" } & TicketFragment>;
};

export type AcceptTicketMutationVariables = Exact<{
  ticketId: Scalars["Int"];
}>;

export type AcceptTicketMutation = { __typename?: "Mutation" } & {
  acceptTicket?: Maybe<{ __typename?: "Ticket" } & TicketFragment>;
};

export type AddTicketAttachmentMutationVariables = Exact<{
  attachments: Array<CreateTicketAttachmentInput> | CreateTicketAttachmentInput;
  ticketId: Scalars["Int"];
}>;

export type AddTicketAttachmentMutation = { __typename?: "Mutation" } & {
  addTicketAttachment?: Maybe<
    { __typename?: "Ticket" } & TicketAndAttachmentsFragment
  >;
};

export type DeleteTicketAttachmentMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteTicketAttachmentMutation = { __typename?: "Mutation" } & {
  deleteTicketAttachment?: Maybe<
    { __typename?: "Ticket" } & TicketAndAttachmentsFragment
  >;
};

export type MergeTicketMutationVariables = Exact<{
  mergeInto: Scalars["Int"];
  ticketToMerge: Scalars["Int"];
}>;

export type MergeTicketMutation = { __typename?: "Mutation" } & {
  mergeTicket?: Maybe<Array<Maybe<{ __typename?: "Ticket" } & TicketFragment>>>;
};

export type EditUserMutationVariables = Exact<{
  inputs?: Maybe<
    | Array<UpdateInputOfUpdateAppSuiteUserInputAndAppSuiteUserInput>
    | UpdateInputOfUpdateAppSuiteUserInputAndAppSuiteUserInput
  >;
}>;

export type EditUserMutation = { __typename?: "Mutation" } & {
  updateUsers?: Maybe<
    Array<Maybe<{ __typename?: "AppSuiteUser" } & UserFragment>>
  >;
};

export type GroupMemberLoginMutationVariables = Exact<{
  computerUser: CurrentComputerUserInput;
}>;

export type GroupMemberLoginMutation = { __typename?: "Mutation" } & {
  groupMemberLogin?: Maybe<
    { __typename?: "GroupLoginState" } & Pick<GroupLoginState, "id"> & {
        user?: Maybe<{ __typename?: "AppSuiteUser" } & UserFragment>;
        labComputer: { __typename?: "LabComputer" } & Pick<
          LabComputer,
          "id" | "name"
        >;
      }
  >;
};

export type SaveUserSettingsMutationVariables = Exact<{
  settingsJson: Scalars["String"];
}>;

export type SaveUserSettingsMutation = { __typename?: "Mutation" } & {
  saveSettings?: Maybe<{ __typename?: "UserClaims" } & UserClaimsFragment>;
};

export type SetLabwareTypeMutationVariables = Exact<{
  plateId: Scalars["Int"];
  labwareTypeId: Scalars["Int"];
}>;

export type SetLabwareTypeMutation = { __typename?: "Mutation" } & {
  setLabwareType?: Maybe<
    { __typename?: "PlatesCreated" } & Pick<
      PlatesCreated,
      "plateCreatedId" | "plateBarcode"
    > & {
        labwareType?: Maybe<
          { __typename?: "LookupLabwareType" } & Pick<
            LookupLabwareType,
            | "labwareTypeId"
            | "labwareTypeName"
            | "labwareTypeCode"
            | "defaultLabwareVolume"
          > & {
              plateLayoutType?: Maybe<
                { __typename?: "PlateType" } & Pick<
                  PlateType,
                  "plateCols" | "plateRows"
                >
              >;
            }
        >;
      }
  >;
};

export type GetPlateBarcodePreviewMutationVariables = Exact<{
  methodId: Scalars["Int"];
  sourcePlateBarcodes?: Maybe<
    Array<Maybe<Scalars["String"]>> | Maybe<Scalars["String"]>
  >;
  destPlateCode?: Maybe<Scalars["String"]>;
}>;

export type GetPlateBarcodePreviewMutation = { __typename?: "Mutation" } & {
  plateBarcodePreview?: Maybe<
    Array<
      Maybe<
        { __typename?: "MethodReservationPlateBarcodePreview" } & Pick<
          MethodReservationPlateBarcodePreview,
          "plateBarcode" | "plateType"
        >
      >
    >
  >;
};

export type GetIntermediateDeadAndDestPlateBarcodesMutationVariables = Exact<{
  sourcePlateBarcode?: Maybe<Scalars["String"]>;
  destPlateCode?: Maybe<Scalars["String"]>;
  hasMultipleSourcePlateTypes: Scalars["Boolean"];
  hasMultipleDestPlateTypes: Scalars["Boolean"];
  has384DeadPlate: Scalars["Boolean"];
}>;

export type GetIntermediateDeadAndDestPlateBarcodesMutation = {
  __typename?: "Mutation";
} & {
  intermediateDeadAndDestPlateBarcodes?: Maybe<
    { __typename?: "MethodReservationPlateBarcodePreview" } & Pick<
      MethodReservationPlateBarcodePreview,
      | "intermediatePlateBarcode1"
      | "intermediatePlateBarcode2"
      | "deadPlateBarcode"
      | "destinationPlateBarcode"
    >
  >;
};

export type ReserveMethodMutationVariables = Exact<{
  methodReservationInput?: Maybe<WorklistToolMethodReservationInput>;
}>;

export type ReserveMethodMutation = { __typename?: "Mutation" } & {
  reserveMethod?: Maybe<
    { __typename?: "MethodReservation" } & Pick<
      MethodReservation,
      "methodReservationId"
    >
  >;
};

export type DeleteExistingMethodReservationMutationVariables = Exact<{
  methodReservationId: Scalars["Int"];
}>;

export type DeleteExistingMethodReservationMutation = {
  __typename?: "Mutation";
} & {
  deleteExistingMethodReservation?: Maybe<
    { __typename?: "MethodReservation" } & Pick<
      MethodReservation,
      "methodReservationId"
    >
  >;
};

export type SaveWorklistToolTemplateMutationVariables = Exact<{
  input?: Maybe<SaveWorklistTemplateInput>;
}>;

export type SaveWorklistToolTemplateMutation = { __typename?: "Mutation" } & {
  saveWorklistToolTemplate?: Maybe<
    { __typename?: "WorklistToolTemplate" } & Pick<
      WorklistToolTemplate,
      "worklistToolTemplateId"
    >
  >;
};

export type OverwriteExistingWorklistTemplateMutationVariables = Exact<{
  input?: Maybe<SaveWorklistTemplateInput>;
}>;

export type OverwriteExistingWorklistTemplateMutation = {
  __typename?: "Mutation";
} & {
  overwriteExistingWorklistTemplate?: Maybe<
    { __typename?: "WorklistToolTemplate" } & Pick<
      WorklistToolTemplate,
      "worklistToolTemplateId"
    >
  >;
};

export type DeleteWorklistToolTemplateMutationVariables = Exact<{
  worklistToolTemplateId: Scalars["Int"];
}>;

export type DeleteWorklistToolTemplateMutation = { __typename?: "Mutation" } & {
  deleteWorklistToolTemplate?: Maybe<
    { __typename?: "WorklistToolTemplate" } & Pick<
      WorklistToolTemplate,
      "worklistToolTemplateId"
    >
  >;
};

export type GetCalendarNodesQueryVariables = Exact<{
  startDate: Scalars["DateTime"];
  endDate: Scalars["DateTime"];
}>;

export type GetCalendarNodesQuery = { __typename?: "Query" } & {
  calendarNodes?: Maybe<
    Array<Maybe<{ __typename?: "CalendarNode" } & CalendarNodeFragment>>
  >;
};

export type GetFeedGroupsQueryVariables = Exact<{
  groupId: Scalars["Int"];
}>;

export type GetFeedGroupsQuery = { __typename?: "Query" } & {
  feedGroups?: Maybe<
    Array<
      Maybe<
        { __typename?: "FeedGroup" } & Pick<
          FeedGroup,
          | "feedGroupId"
          | "groupId"
          | "arraySystemAutomationMethodId"
          | "feedGroupName"
          | "numberOfPlates"
          | "isActive"
          | "startTimeScheduled"
        > & {
            arraySystemAutomationMethod?: Maybe<
              { __typename?: "ArraySystemAutomationMethod" } & Pick<
                ArraySystemAutomationMethod,
                "automationMethodId" | "automationMethodCode" | "arraySystemId"
              > & {
                  automationMethod?: Maybe<
                    { __typename?: "LookupAutomationMethod" } & {
                      lookupArrayMethods?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "LookupArrayMethod" } & Pick<
                              LookupArrayMethod,
                              "methodId"
                            >
                          >
                        >
                      >;
                    }
                  >;
                }
            >;
          }
      >
    >
  >;
};

export type GetGroupPersonalTasksQueryVariables = Exact<{
  groupId: Scalars["Int"];
  today: Scalars["DateTime"];
}>;

export type GetGroupPersonalTasksQuery = { __typename?: "Query" } & {
  groupPersonalTasks?: Maybe<
    Array<
      Maybe<
        { __typename?: "PersonalTask" } & Pick<
          PersonalTask,
          "taskName" | "methodId" | "startTimeScheduled" | "feedGroupId"
        >
      >
    >
  >;
};

export type AppBackgroundQueryVariables = Exact<{ [key: string]: never }>;

export type AppBackgroundQuery = { __typename?: "Query" } & {
  systemsQuery?: Maybe<
    Array<
      Maybe<
        { __typename?: "LookupArraySystem" } & Pick<
          LookupArraySystem,
          "systemId" | "systemName"
        >
      >
    >
  >;
  usersQuery?: Maybe<
    Array<Maybe<{ __typename?: "AppSuiteUser" } & UserFragment>>
  >;
  groupsQuery?: Maybe<
    Array<Maybe<{ __typename?: "AppSuiteGroup" } & GroupFragment>>
  >;
  folders?: Maybe<
    Array<Maybe<{ __typename?: "FolderStructure" } & FolderStructureFragment>>
  >;
  insertableFoldersQuery?: Maybe<
    Array<Maybe<{ __typename?: "FolderStructure" } & FolderStructureFragment>>
  >;
  runTypesQuery?: Maybe<
    Array<
      Maybe<
        { __typename?: "ProductionRunType" } & Pick<
          ProductionRunType,
          "productionRunTypeId" | "typeName"
        >
      >
    >
  >;
  runTaskTypesQuery?: Maybe<
    Array<
      Maybe<
        { __typename?: "RunTaskType" } & Pick<
          RunTaskType,
          "type" | "id" | "name" | "compositeKey" | "automationMethodId"
        >
      >
    >
  >;
};

export type DashboardBackgroundQueriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type DashboardBackgroundQueriesQuery = { __typename?: "Query" } & {
  methods?: Maybe<
    Array<Maybe<{ __typename?: "LookupArrayMethod" } & MethodFragment>>
  >;
};

export type RunTemplateBackgroundQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RunTemplateBackgroundQuery = { __typename?: "Query" } & Pick<
  Query,
  "plateCodes"
> & {
    runTemplatesForCombobox?: Maybe<
      Array<
        Maybe<
          { __typename?: "RunTemplateOption" } & Pick<
            RunTemplateOption,
            "runTemplateId" | "runTemplateName"
          >
        >
      >
    >;
  };

export type CalendarBackgroundQueryVariables = Exact<{ [key: string]: never }>;

export type CalendarBackgroundQuery = { __typename?: "Query" } & {
  runs?: Maybe<Array<Maybe<{ __typename?: "Run" } & RunFragment>>>;
};

export type RunsBackgroundQueryVariables = Exact<{ [key: string]: never }>;

export type RunsBackgroundQuery = { __typename?: "Query" } & {
  runTemplatesForCombobox?: Maybe<
    Array<
      Maybe<
        { __typename?: "RunTemplateOption" } & Pick<
          RunTemplateOption,
          "runTemplateId" | "runTemplateName"
        >
      >
    >
  >;
  experimentSettings?: Maybe<
    Array<
      Maybe<
        { __typename?: "LookupExperimentSetting" } & Pick<
          LookupExperimentSetting,
          | "experimentSettingId"
          | "experimentSettingScanTypeId"
          | "experimentSetting"
          | "plateLayoutTypeId"
        >
      >
    >
  >;
  methods?: Maybe<
    Array<Maybe<{ __typename?: "LookupArrayMethod" } & MethodFragment>>
  >;
};

export type TicketBackgroundQueryVariables = Exact<{ [key: string]: never }>;

export type TicketBackgroundQuery = { __typename?: "Query" } & {
  ticketTypes?: Maybe<
    Array<
      Maybe<
        { __typename?: "LookupTicketType" } & Pick<
          LookupTicketType,
          "ticketTypeId" | "ticketType"
        >
      >
    >
  >;
  ticketAssetTypes?: Maybe<
    Array<
      Maybe<
        { __typename?: "LookupAssetType" } & Pick<
          LookupAssetType,
          "assetType" | "assetTypeId"
        >
      >
    >
  >;
  ticketAssets?: Maybe<
    Array<
      Maybe<
        { __typename?: "LookupAsset" } & Pick<
          LookupAsset,
          "assetTypeId" | "assetId" | "asset"
        > & {
            assetType?: Maybe<
              { __typename?: "LookupAssetType" } & Pick<
                LookupAssetType,
                "assetTypeId" | "assetType"
              >
            >;
          }
      >
    >
  >;
};

export type IncomingSamplesBackgroundQueryVariables = Exact<{
  [key: string]: never;
}>;

export type IncomingSamplesBackgroundQuery = { __typename?: "Query" } & {
  sampleTypes?: Maybe<
    Array<
      Maybe<
        { __typename?: "LookupSampleType" } & Pick<
          LookupSampleType,
          "sampleTypeId" | "sampleTypeString"
        >
      >
    >
  >;
  projects?: Maybe<
    Array<
      Maybe<
        { __typename?: "Project" } & Pick<
          Project,
          "projectId" | "nyscfprefix" | "projectName"
        >
      >
    >
  >;
};

export type GetDashboardTasksQueryVariables = Exact<{
  loggedInUser?: Maybe<Scalars["Int"]>;
  status?: Maybe<Array<TaskStatusEnum> | TaskStatusEnum>;
}>;

export type GetDashboardTasksQuery = { __typename?: "Query" } & {
  getOverdueTasks?: Maybe<
    Array<Maybe<{ __typename?: "DashboardTask" } & DashboardTaskFragment>>
  >;
  getMyTasks?: Maybe<
    Array<Maybe<{ __typename?: "DashboardTask" } & DashboardTaskFragment>>
  >;
  getGroupTasks?: Maybe<
    Array<Maybe<{ __typename?: "DashboardTask" } & DashboardTaskFragment>>
  >;
  getLaterThisWeekTasks?: Maybe<
    Array<Maybe<{ __typename?: "DashboardTask" } & DashboardTaskFragment>>
  >;
};

export type GetTaskHistoryQueryVariables = Exact<{
  where?: Maybe<WebSuiteTaskLogFilterInput>;
}>;

export type GetTaskHistoryQuery = { __typename?: "Query" } & {
  taskHistory?: Maybe<
    Array<
      Maybe<
        { __typename?: "WebSuiteTaskLog" } & Pick<
          WebSuiteTaskLog,
          | "taskParentId"
          | "taskId"
          | "taskType"
          | "actionPerformed"
          | "actionPerformedTime"
          | "actionPerformedBy"
        >
      >
    >
  >;
};

export type GetIncludedFeedPlatesQueryVariables = Exact<{
  dateScheduled: Scalars["DateTime"];
}>;

export type GetIncludedFeedPlatesQuery = { __typename?: "Query" } & {
  includedFeedPlates?: Maybe<
    Array<
      Maybe<
        { __typename?: "SystemOneFeedRuns" } & Pick<
          SystemOneFeedRuns,
          "runName" | "reason"
        > & {
            plates?: Maybe<
              Array<
                Maybe<
                  { __typename?: "SystemOneFeedPlates" } & Pick<
                    SystemOneFeedPlates,
                    "plateID" | "plateBarcode" | "activeWellCount"
                  >
                >
              >
            >;
          }
      >
    >
  >;
};

export type GetExcludedFeedPlatesQueryVariables = Exact<{
  dateScheduled: Scalars["DateTime"];
}>;

export type GetExcludedFeedPlatesQuery = { __typename?: "Query" } & {
  excludedFeedPlates?: Maybe<
    Array<
      Maybe<
        { __typename?: "SystemOneFeedRuns" } & Pick<
          SystemOneFeedRuns,
          "runName" | "reason"
        > & {
            plates?: Maybe<
              Array<
                Maybe<
                  { __typename?: "SystemOneFeedPlates" } & Pick<
                    SystemOneFeedPlates,
                    "plateID" | "plateBarcode" | "activeWellCount"
                  >
                >
              >
            >;
          }
      >
    >
  >;
};

export type GetPlateMapQueryVariables = Exact<{
  plateID: Scalars["Int"];
}>;

export type GetPlateMapQuery = { __typename?: "Query" } & {
  plateMap?: Maybe<
    Array<
      Maybe<
        { __typename?: "PlatesCreated" } & Pick<
          PlatesCreated,
          "plateBarcode"
        > & {
            arrayPlateType?: Maybe<
              { __typename?: "LookupArrayPlateTypeString" } & {
                plateLayoutType?: Maybe<
                  { __typename?: "PlateType" } & Pick<PlateType, "plateName">
                >;
              }
            >;
            containers?: Maybe<
              Array<
                Maybe<
                  { __typename?: "Container" } & Pick<
                    Container,
                    "containerId" | "rowPos" | "colPos" | "isActive"
                  >
                >
              >
            >;
          }
      >
    >
  >;
};

export type GetDashboardTaskQueryVariables = Exact<{
  taskID: Scalars["Int"];
  taskType: TaskTypeEnum;
}>;

export type GetDashboardTaskQuery = { __typename?: "Query" } & {
  dashboardTask?: Maybe<
    { __typename?: "DashboardTask" } & DashboardTaskFragment
  >;
};

export type GetFolderDirectoryQueryVariables = Exact<{ [key: string]: never }>;

export type GetFolderDirectoryQuery = { __typename?: "Query" } & {
  directory?: Maybe<
    Array<Maybe<{ __typename?: "DirectoryFolder" } & DirectoryFragment>>
  >;
};

export type GetInsertableFoldersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetInsertableFoldersQuery = { __typename?: "Query" } & {
  insertableFolders?: Maybe<
    Array<Maybe<{ __typename?: "FolderStructure" } & FolderStructureFragment>>
  >;
};

export type GetFoldersQueryVariables = Exact<{
  where?: Maybe<FolderStructureFilterInput>;
  order?: Maybe<Array<FolderStructureSortInput> | FolderStructureSortInput>;
}>;

export type GetFoldersQuery = { __typename?: "Query" } & {
  folders?: Maybe<
    Array<
      Maybe<
        { __typename?: "FolderStructure" } & Pick<
          FolderStructure,
          "folderId" | "folderName" | "folderParentId"
        > & {
            folderParent?: Maybe<
              { __typename?: "FolderStructure" } & Pick<
                FolderStructure,
                "folderId" | "folderName"
              >
            >;
            runs?: Maybe<
              Array<
                Maybe<
                  { __typename?: "Run" } & Pick<
                    Run,
                    "runId" | "runName" | "runDateScheduled"
                  > & {
                      runStages?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "RunStage" } & Pick<
                              RunStage,
                              "runStageId" | "runStageName"
                            >
                          >
                        >
                      >;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type GetAllFoldersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllFoldersQuery = { __typename?: "Query" } & {
  folders?: Maybe<
    Array<Maybe<{ __typename?: "FolderStructure" } & FolderStructureFragment>>
  >;
};

export type GetFolderByIdQueryVariables = Exact<{
  folderId: Scalars["Int"];
}>;

export type GetFolderByIdQuery = { __typename?: "Query" } & {
  folder?: Maybe<
    { __typename?: "FolderStructure" } & Pick<
      FolderStructure,
      "folderId" | "folderParentId" | "folderName"
    >
  >;
};

export type SearchDirectoryQueryVariables = Exact<{
  search: Scalars["String"];
}>;

export type SearchDirectoryQuery = { __typename?: "Query" } & {
  searchDirectory: Array<
    { __typename?: "DirectoryFolder" } & DirectoryFragment
  >;
};

export type GetIncomingSampleTasksQueryVariables = Exact<{
  where?: Maybe<CollectionTaskFilterInput>;
  order?: Maybe<Array<CollectionTaskSortInput> | CollectionTaskSortInput>;
  after?: Maybe<Scalars["String"]>;
  pageSize?: Maybe<Scalars["Int"]>;
}>;

export type GetIncomingSampleTasksQuery = { __typename?: "Query" } & {
  incomingSampleTasks?: Maybe<
    { __typename?: "IncomingSampleTasksConnection" } & Pick<
      IncomingSampleTasksConnection,
      "totalCount"
    > & {
        edges?: Maybe<
          Array<
            { __typename?: "IncomingSampleTasksEdge" } & {
              node?: Maybe<
                { __typename?: "CollectionTask" } & IncomingSampleTaskFragment
              >;
            }
          >
        >;
        pageInfo: { __typename?: "PageInfo" } & Pick<
          PageInfo,
          "hasNextPage" | "hasPreviousPage" | "endCursor" | "startCursor"
        >;
      }
  >;
};

export type GetIncomingSampleCollectionsQueryVariables = Exact<{
  where?: Maybe<CollectionFilterInput>;
  order?: Maybe<Array<CollectionSortInput> | CollectionSortInput>;
  after?: Maybe<Scalars["String"]>;
  pageSize?: Maybe<Scalars["Int"]>;
}>;

export type GetIncomingSampleCollectionsQuery = { __typename?: "Query" } & {
  incomingSampleCollections?: Maybe<
    { __typename?: "IncomingSampleCollectionsConnection" } & Pick<
      IncomingSampleCollectionsConnection,
      "totalCount"
    > & {
        edges?: Maybe<
          Array<
            { __typename?: "IncomingSampleCollectionsEdge" } & {
              node?: Maybe<
                { __typename?: "Collection" } & IncomingSampleCollectionFragment
              >;
            }
          >
        >;
        pageInfo: { __typename?: "PageInfo" } & Pick<
          PageInfo,
          "hasNextPage" | "hasPreviousPage" | "endCursor" | "startCursor"
        >;
      }
  >;
};

export type GetSourceSampleTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSourceSampleTypesQuery = { __typename?: "Query" } & {
  sampleTypes?: Maybe<
    Array<
      Maybe<
        { __typename?: "LookupSampleType" } & Pick<
          LookupSampleType,
          "sampleTypeId" | "sampleTypeString"
        >
      >
    >
  >;
};

export type GetProjectsQueryVariables = Exact<{ [key: string]: never }>;

export type GetProjectsQuery = { __typename?: "Query" } & {
  projects?: Maybe<
    Array<
      Maybe<
        { __typename?: "Project" } & Pick<
          Project,
          "projectId" | "nyscfprefix" | "projectName"
        >
      >
    >
  >;
};

export type GetIncomingSampleCollectionByIdQueryVariables = Exact<{
  collectionId: Scalars["Int"];
}>;

export type GetIncomingSampleCollectionByIdQuery = { __typename?: "Query" } & {
  incomingSamplesCollection?: Maybe<
    { __typename?: "Collection" } & IncomingSampleCollectionFragment
  >;
};

export type GetIncomingSampleTaskByIdQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type GetIncomingSampleTaskByIdQuery = { __typename?: "Query" } & {
  incomingSamplesTask?: Maybe<
    { __typename?: "CollectionTask" } & IncomingSampleTaskFragment
  >;
};

export type GetIncomingSampleTasksByIdQueryVariables = Exact<{
  taskIds: Array<Scalars["Int"]> | Scalars["Int"];
}>;

export type GetIncomingSampleTasksByIdQuery = { __typename?: "Query" } & {
  incomingSamplesTasksFromCache: Array<
    { __typename?: "CollectionTask" } & IncomingSampleTaskFragment
  >;
};

export type GetSystemsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSystemsQuery = { __typename?: "Query" } & {
  systems?: Maybe<
    Array<
      Maybe<
        { __typename?: "LookupArraySystem" } & Pick<
          LookupArraySystem,
          "systemId" | "systemName"
        >
      >
    >
  >;
};

export type GetLabComputersQueryVariables = Exact<{ [key: string]: never }>;

export type GetLabComputersQuery = { __typename?: "Query" } & {
  labComputers?: Maybe<
    Array<
      Maybe<{ __typename?: "LabComputer" } & Pick<LabComputer, "id" | "name">>
    >
  >;
};

export type GetSystemQueryVariables = Exact<{
  systemId: Scalars["Int"];
}>;

export type GetSystemQuery = { __typename?: "Query" } & {
  system?: Maybe<
    { __typename?: "LookupArraySystem" } & Pick<
      LookupArraySystem,
      "systemId" | "systemName"
    >
  >;
};

export type ManualTaskManagerQueryVariables = Exact<{
  where?: Maybe<ManualTaskTypeFilterInput>;
  order?: Maybe<Array<ManualTaskTypeSortInput> | ManualTaskTypeSortInput>;
}>;

export type ManualTaskManagerQuery = { __typename?: "Query" } & {
  manualTaskTypes?: Maybe<
    Array<Maybe<{ __typename?: "ManualTaskType" } & ManualTaskTypeFragment>>
  >;
};

export type MethodManagerQueryVariables = Exact<{
  where?: Maybe<LookupArrayMethodFilterInput>;
  order?: Maybe<Array<LookupArrayMethodSortInput> | LookupArrayMethodSortInput>;
}>;

export type MethodManagerQuery = { __typename?: "Query" } & {
  methods?: Maybe<
    Array<Maybe<{ __typename?: "LookupArrayMethod" } & MethodFragment>>
  >;
};

export type GetMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMethodsQuery = { __typename?: "Query" } & {
  methods?: Maybe<
    Array<Maybe<{ __typename?: "LookupArrayMethod" } & MethodFragment>>
  >;
};

export type GetManualTaskTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetManualTaskTypesQuery = { __typename?: "Query" } & {
  manualTaskTypes?: Maybe<
    Array<Maybe<{ __typename?: "ManualTaskType" } & ManualTaskTypeFragment>>
  >;
};

export type GetMethodByIdQueryVariables = Exact<{
  methodId: Scalars["Int"];
}>;

export type GetMethodByIdQuery = { __typename?: "Query" } & {
  method?: Maybe<{ __typename?: "LookupArrayMethod" } & MethodFragment>;
};

export type GetManualTaskTypeByIdQueryVariables = Exact<{
  manualTaskTypeId: Scalars["Int"];
}>;

export type GetManualTaskTypeByIdQuery = { __typename?: "Query" } & {
  manualTaskType?: Maybe<
    { __typename?: "ManualTaskType" } & ManualTaskTypeFragment
  >;
};

export type GetReservedMethodsQueryVariables = Exact<{
  runId: Scalars["Int"];
}>;

export type GetReservedMethodsQuery = { __typename?: "Query" } & {
  reservedMethods?: Maybe<
    Array<
      Maybe<
        { __typename?: "MethodReservation" } & Pick<
          MethodReservation,
          "methodId" | "methodReservationId"
        > & {
            methodReservationsPlateBarcodes?: Maybe<
              Array<
                Maybe<
                  { __typename?: "MethodReservationsPlateBarcode" } & Pick<
                    MethodReservationsPlateBarcode,
                    "plateBarcode"
                  >
                >
              >
            >;
          }
      >
    >
  >;
};

export type GetExperimentSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetExperimentSettingsQuery = { __typename?: "Query" } & {
  experimentSettings?: Maybe<
    Array<
      Maybe<
        { __typename?: "LookupExperimentSetting" } & Pick<
          LookupExperimentSetting,
          | "experimentSettingId"
          | "experimentSettingScanTypeId"
          | "experimentSetting"
          | "plateLayoutTypeId"
        >
      >
    >
  >;
};

export type GetActiveMethodReservationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetActiveMethodReservationsQuery = { __typename?: "Query" } & {
  activeReservations?: Maybe<
    Array<
      Maybe<
        { __typename?: "MethodReservation" } & Pick<
          MethodReservation,
          "methodReservationId" | "isCompleted"
        > & {
            methodReservationsPlateBarcodes?: Maybe<
              Array<
                Maybe<
                  { __typename?: "MethodReservationsPlateBarcode" } & Pick<
                    MethodReservationsPlateBarcode,
                    "plateBarcode"
                  >
                >
              >
            >;
            method?: Maybe<
              { __typename?: "LookupArrayMethod" } & MethodFragment
            >;
          }
      >
    >
  >;
};

export type GetMethodReservationByPersonalTaskIdQueryVariables = Exact<{
  personalTaskId: Scalars["Int"];
}>;

export type GetMethodReservationByPersonalTaskIdQuery = {
  __typename?: "Query";
} & {
  methodReservation?: Maybe<
    Array<
      Maybe<
        { __typename?: "MethodReservation" } & Pick<
          MethodReservation,
          "methodReservationId" | "isCompleted"
        > & {
            methodReservationsPlateBarcodes?: Maybe<
              Array<
                Maybe<
                  { __typename?: "MethodReservationsPlateBarcode" } & Pick<
                    MethodReservationsPlateBarcode,
                    "plateBarcode"
                  >
                >
              >
            >;
            worklist?: Maybe<
              { __typename?: "Worklist" } & Pick<Worklist, "worklistId"> & {
                  worklistContentImagings?: Maybe<
                    Array<
                      Maybe<
                        {
                          __typename?: "WorklistContentImaging";
                        } & ImagingWorklistFragment
                      >
                    >
                  >;
                }
            >;
            method?: Maybe<
              { __typename?: "LookupArrayMethod" } & MethodFragment
            >;
          }
      >
    >
  >;
};

export type GetMethodReservationByRunActivityIdQueryVariables = Exact<{
  runActivityId: Scalars["Int"];
}>;

export type GetMethodReservationByRunActivityIdQuery = {
  __typename?: "Query";
} & {
  methodReservation?: Maybe<
    Array<
      Maybe<
        { __typename?: "MethodReservation" } & Pick<
          MethodReservation,
          "methodReservationId" | "isCompleted"
        > & {
            methodReservationsPlateBarcodes?: Maybe<
              Array<
                Maybe<
                  { __typename?: "MethodReservationsPlateBarcode" } & Pick<
                    MethodReservationsPlateBarcode,
                    "plateBarcode"
                  >
                >
              >
            >;
            worklist?: Maybe<
              { __typename?: "Worklist" } & Pick<Worklist, "worklistId"> & {
                  worklistContentImagings?: Maybe<
                    Array<
                      Maybe<
                        {
                          __typename?: "WorklistContentImaging";
                        } & ImagingWorklistFragment
                      >
                    >
                  >;
                }
            >;
            method?: Maybe<
              { __typename?: "LookupArrayMethod" } & MethodFragment
            >;
          }
      >
    >
  >;
};

export type GetMyPersonalTasksQueryVariables = Exact<{
  pageSize?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  where?: Maybe<ManagePersonalTaskFilterInput>;
  order_by?: Maybe<
    Array<ManagePersonalTaskSortInput> | ManagePersonalTaskSortInput
  >;
}>;

export type GetMyPersonalTasksQuery = { __typename?: "Query" } & {
  myPersonalTasks?: Maybe<
    { __typename?: "MyPersonalTasksConnection" } & Pick<
      MyPersonalTasksConnection,
      "totalCount"
    > & {
        edges?: Maybe<
          Array<
            { __typename?: "MyPersonalTasksEdge" } & {
              node?: Maybe<
                {
                  __typename?: "ManagePersonalTask";
                } & ManagePersonalTaskFragment
              >;
            }
          >
        >;
        pageInfo: { __typename?: "PageInfo" } & Pick<
          PageInfo,
          "hasNextPage" | "hasPreviousPage" | "endCursor" | "startCursor"
        >;
      }
  >;
};

export type GetPersonalTaskAttachmentsQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type GetPersonalTaskAttachmentsQuery = { __typename?: "Query" } & {
  personalTaskAttachments?: Maybe<
    Array<
      Maybe<
        {
          __typename?: "PersonalTaskAttachment";
        } & PersonalTaskAttachmentFragment
      >
    >
  >;
};

export type GetPersonalTaskTemplatesQueryVariables = Exact<{
  pageSize?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  where?: Maybe<PersonalTaskTemplateFilterInput>;
  order_by?: Maybe<
    Array<PersonalTaskTemplateSortInput> | PersonalTaskTemplateSortInput
  >;
}>;

export type GetPersonalTaskTemplatesQuery = { __typename?: "Query" } & {
  personalTaskTemplates?: Maybe<
    { __typename?: "PersonalTaskTemplatesConnection" } & Pick<
      PersonalTaskTemplatesConnection,
      "totalCount"
    > & {
        edges?: Maybe<
          Array<
            { __typename?: "PersonalTaskTemplatesEdge" } & {
              node?: Maybe<
                { __typename?: "PersonalTaskTemplate" } & Pick<
                  PersonalTaskTemplate,
                  | "personalTaskTemplateId"
                  | "personalTaskTemplateName"
                  | "createdBy"
                  | "updatedBy"
                  | "updatedByTime"
                > & {
                    personalTaskTemplateTasks?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: "PersonalTaskTemplateTask" } & Pick<
                            PersonalTaskTemplateTask,
                            | "personalTaskTemplateTaskId"
                            | "personalTaskName"
                            | "dayScheduled"
                            | "userAssignedTo"
                            | "groupAssignedTo"
                            | "assignedSystem"
                            | "notes"
                            | "priority"
                          > & {
                              groups?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: "AppSuiteGroup" } & Pick<
                                      AppSuiteGroup,
                                      "groupId"
                                    >
                                  >
                                >
                              >;
                              users?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: "AppSuiteUser" } & Pick<
                                      AppSuiteUser,
                                      "userId"
                                    >
                                  >
                                >
                              >;
                            }
                        >
                      >
                    >;
                  }
              >;
            }
          >
        >;
        pageInfo: { __typename?: "PageInfo" } & Pick<
          PageInfo,
          "hasNextPage" | "hasPreviousPage" | "endCursor" | "startCursor"
        >;
      }
  >;
};

export type GetRepeatingTasksSummaryQueryVariables = Exact<{
  pageSize?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  where?: Maybe<PersonalTasksRecurringTaskFilterInput>;
  order_by?: Maybe<
    | Array<PersonalTasksRecurringTaskSortInput>
    | PersonalTasksRecurringTaskSortInput
  >;
}>;

export type GetRepeatingTasksSummaryQuery = { __typename?: "Query" } & {
  repeatingTasksSummary?: Maybe<
    { __typename?: "RepeatingTasksSummaryConnection" } & Pick<
      RepeatingTasksSummaryConnection,
      "totalCount"
    > & {
        edges?: Maybe<
          Array<
            { __typename?: "RepeatingTasksSummaryEdge" } & {
              node?: Maybe<
                { __typename?: "PersonalTasksRecurringTask" } & Pick<
                  PersonalTasksRecurringTask,
                  | "recurringTaskId"
                  | "taskName"
                  | "repeatingDaysOfWeek"
                  | "endRepeatDate"
                >
              >;
            }
          >
        >;
        pageInfo: { __typename?: "PageInfo" } & Pick<
          PageInfo,
          "hasNextPage" | "hasPreviousPage" | "endCursor" | "startCursor"
        >;
      }
  >;
};

export type GetUnavailablePlatesQueryVariables = Exact<{
  methodId: Scalars["Int"];
}>;

export type GetUnavailablePlatesQuery = { __typename?: "Query" } & Pick<
  Query,
  "unavailablePlates"
>;

export type GetCeligoPlateMetadataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCeligoPlateMetadataQuery = { __typename?: "Query" } & {
  celigoScanPlateMetadata?: Maybe<
    Array<
      Maybe<
        { __typename?: "GroupedPlateMetadata" } & Pick<
          GroupedPlateMetadata,
          "prefix"
        > & {
            plates?: Maybe<
              Array<
                Maybe<
                  { __typename?: "PlateToMetadata" } & {
                    platesCreated?: Maybe<
                      { __typename?: "PlatesCreated" } & PlateCreatedFragment
                    >;
                    metadata?: Maybe<
                      { __typename?: "PlateMetadata" } & Pick<
                        PlateMetadata,
                        | "sourcePlateBarcode"
                        | "analysisSetting"
                        | "platePitch"
                        | "imageExportFormat"
                        | "imageExportSetting"
                        | "experimentSetting"
                      >
                    >;
                  }
                >
              >
            >;
          }
      >
    >
  >;
};

export type GetPlatesQueryVariables = Exact<{
  pageSize?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  where?: Maybe<PlatesCreatedFilterInput>;
  order?: Maybe<Array<PlatesCreatedSortInput> | PlatesCreatedSortInput>;
}>;

export type GetPlatesQuery = { __typename?: "Query" } & {
  plates?: Maybe<
    { __typename?: "PlatesConnection" } & {
      edges?: Maybe<
        Array<
          { __typename?: "PlatesEdge" } & {
            node?: Maybe<{ __typename?: "PlatesCreated" } & PlateFragment>;
          }
        >
      >;
      pageInfo: { __typename?: "PageInfo" } & Pick<
        PageInfo,
        "hasNextPage" | "hasPreviousPage" | "endCursor" | "startCursor"
      >;
    }
  >;
};

export type GetPlateInfoQueryVariables = Exact<{
  plateBarcode?: Maybe<Scalars["String"]>;
}>;

export type GetPlateInfoQuery = { __typename?: "Query" } & {
  plateInfo?: Maybe<{ __typename?: "PlatesCreated" } & PlateInfoFragment>;
};

export type GetTubeBarcodesForAutocompleteQueryVariables = Exact<{
  search: Scalars["String"];
}>;

export type GetTubeBarcodesForAutocompleteQuery = { __typename?: "Query" } & {
  containers?: Maybe<
    { __typename?: "ContainersConnection" } & {
      edges?: Maybe<
        Array<
          { __typename?: "ContainersEdge" } & {
            node?: Maybe<
              { __typename?: "Container" } & Pick<
                Container,
                "containerId" | "barcode"
              >
            >;
          }
        >
      >;
    }
  >;
};

export type GetPlateForAutocompleteQueryVariables = Exact<{
  search: Scalars["String"];
}>;

export type GetPlateForAutocompleteQuery = { __typename?: "Query" } & {
  plates?: Maybe<
    { __typename?: "PlatesConnection" } & {
      edges?: Maybe<
        Array<
          { __typename?: "PlatesEdge" } & {
            node?: Maybe<
              { __typename?: "PlatesCreated" } & Pick<
                PlatesCreated,
                "plateCreatedId" | "plateBarcode"
              >
            >;
          }
        >
      >;
    }
  >;
};

export type GetLotNumbersQueryVariables = Exact<{
  search: Scalars["String"];
}>;

export type GetLotNumbersQuery = { __typename?: "Query" } & {
  lotNumbers?: Maybe<
    { __typename?: "LotNumbersConnection" } & {
      edges?: Maybe<
        Array<
          { __typename?: "LotNumbersEdge" } & {
            node?: Maybe<
              { __typename?: "LotNumber" } & Pick<
                LotNumber,
                "lotNumberId" | "lotNumber1"
              >
            >;
          }
        >
      >;
    }
  >;
};

export type GetContainerLineageQueryVariables = Exact<{
  containerId: Scalars["Int"];
}>;

export type GetContainerLineageQuery = { __typename?: "Query" } & {
  containerLineage?: Maybe<
    Array<Maybe<{ __typename?: "ContainerLineage" } & ContainerLineageFragment>>
  >;
};

export type GetContainersQueryVariables = Exact<{
  pageSize?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  order?: Maybe<Array<ContainerSortInput> | ContainerSortInput>;
  where?: Maybe<ContainerFilterInput>;
}>;

export type GetContainersQuery = { __typename?: "Query" } & {
  containers?: Maybe<
    { __typename?: "ContainersConnection" } & Pick<
      ContainersConnection,
      "totalCount"
    > & {
        edges?: Maybe<
          Array<
            { __typename?: "ContainersEdge" } & {
              node?: Maybe<
                { __typename?: "Container" } & ContainerTableEntryFragment
              >;
            }
          >
        >;
        pageInfo: { __typename?: "PageInfo" } & Pick<
          PageInfo,
          "hasNextPage" | "hasPreviousPage" | "endCursor" | "startCursor"
        >;
      }
  >;
};

export type GetSampleQueryVariables = Exact<{
  sampleId: Scalars["Int"];
}>;

export type GetSampleQuery = { __typename?: "Query" } & {
  sample?: Maybe<{ __typename?: "Sample" } & SampleFragment>;
};

export type GetPlateByIdQueryVariables = Exact<{
  plateBarcode: Scalars["String"];
}>;

export type GetPlateByIdQuery = { __typename?: "Query" } & {
  plate?: Maybe<{ __typename?: "PlatesCreated" } & PlateFragment>;
};

export type GetContainerByIdQueryVariables = Exact<{
  containerId: Scalars["Int"];
}>;

export type GetContainerByIdQuery = { __typename?: "Query" } & {
  container?: Maybe<{ __typename?: "Container" } & ContainerTableEntryFragment>;
};

export type GetCreateRunDefaultsQueryVariables = Exact<{
  runTemplateId: Scalars["Int"];
}>;

export type GetCreateRunDefaultsQuery = { __typename?: "Query" } & {
  createRunDefaults?: Maybe<
    { __typename?: "CreateRunDefaults" } & Pick<
      CreateRunDefaults,
      "runFolder" | "runName"
    >
  >;
};

export type GetActiveRunsQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveRunsQuery = { __typename?: "Query" } & {
  runs?: Maybe<Array<Maybe<{ __typename?: "Run" } & RunFragment>>>;
};

export type GetRunsQueryVariables = Exact<{
  where?: Maybe<RunFilterInput>;
}>;

export type GetRunsQuery = { __typename?: "Query" } & {
  runs?: Maybe<Array<Maybe<{ __typename?: "Run" } & RunFragment>>>;
};

export type GetRunTasksQueryVariables = Exact<{
  key?: Maybe<DirectoryFolderKeyInput>;
  pageSize?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  order?: Maybe<Array<RunTaskSortInput> | RunTaskSortInput>;
  where?: Maybe<RunTaskFilterInput>;
}>;

export type GetRunTasksQuery = { __typename?: "Query" } & {
  runTasks?: Maybe<
    { __typename?: "RunTasksConnection" } & Pick<
      RunTasksConnection,
      "totalCount"
    > & {
        edges?: Maybe<
          Array<
            { __typename?: "RunTasksEdge" } & {
              node?: Maybe<{ __typename?: "RunTask" } & RunTaskFragment>;
            }
          >
        >;
        pageInfo: { __typename?: "PageInfo" } & Pick<
          PageInfo,
          "hasNextPage" | "startCursor" | "endCursor" | "hasPreviousPage"
        >;
      }
  >;
};

export type GetRunTaskAttachmentsQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type GetRunTaskAttachmentsQuery = { __typename?: "Query" } & {
  runTaskAttachments?: Maybe<
    Array<
      Maybe<{ __typename?: "RunTaskAttachment" } & RunTaskAttachmentFragment>
    >
  >;
};

export type GetRunTaskMetaDataQueryVariables = Exact<{
  taskIds?: Maybe<Array<Scalars["Int"]> | Scalars["Int"]>;
}>;

export type GetRunTaskMetaDataQuery = { __typename?: "Query" } & {
  runTaskMetadata?: Maybe<
    Array<
      Maybe<
        { __typename?: "RunTask" } & RunTaskFragment & RunTaskMetaDataFragment
      >
    >
  >;
};

export type GetRunTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRunTypesQuery = { __typename?: "Query" } & {
  runTypes?: Maybe<
    Array<
      Maybe<
        { __typename?: "ProductionRunType" } & Pick<
          ProductionRunType,
          "productionRunTypeId" | "typeName"
        >
      >
    >
  >;
};

export type GetGanttIFrameUrlQueryVariables = Exact<{
  runId: Scalars["Int"];
}>;

export type GetGanttIFrameUrlQuery = { __typename?: "Query" } & Pick<
  Query,
  "ganttIFrameUrl"
>;

export type GetRunAndTasksForRescheduleQueryVariables = Exact<{
  runId: Scalars["Int"];
  baseDateTime: Scalars["DateTime"];
}>;

export type GetRunAndTasksForRescheduleQuery = { __typename?: "Query" } & {
  runs?: Maybe<
    Array<
      Maybe<
        { __typename?: "Run" } & Pick<Run, "runId" | "runName"> & {
            runTasks?: Maybe<
              Array<
                Maybe<{ __typename?: "RunTask" } & ReschedulingTaskFragment>
              >
            >;
          }
      >
    >
  >;
};

export type GetArraySystemAutomationMethodsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetArraySystemAutomationMethodsQuery = { __typename?: "Query" } & {
  arraySystemAutomationMethods?: Maybe<
    Array<
      Maybe<
        { __typename?: "ArraySystemAutomationMethod" } & Pick<
          ArraySystemAutomationMethod,
          | "arraySystemAutomationMethodId"
          | "automationMethodCode"
          | "arraySystemId"
          | "automationMethodId"
          | "isActive"
          | "averageRunTimeSeconds"
        > & {
            arraySystem?: Maybe<
              { __typename?: "LookupArraySystem" } & Pick<
                LookupArraySystem,
                "systemId" | "systemName"
              >
            >;
          }
      >
    >
  >;
};

export type GetRunByIdQueryVariables = Exact<{
  runId: Scalars["Int"];
}>;

export type GetRunByIdQuery = { __typename?: "Query" } & {
  run?: Maybe<{ __typename?: "Run" } & RunFragment>;
};

export type GetRunTaskByIdQueryVariables = Exact<{
  runActivityId: Scalars["Int"];
}>;

export type GetRunTaskByIdQuery = { __typename?: "Query" } & {
  runTask?: Maybe<
    { __typename?: "RunTask" } & RunTaskFragment & RunTaskMetaDataFragment
  >;
};

export type GetRunActivitiesByIdsQueryVariables = Exact<{
  runActivityIds: Array<Scalars["Int"]> | Scalars["Int"];
}>;

export type GetRunActivitiesByIdsQuery = { __typename?: "Query" } & {
  runTasksFromCache: Array<
    { __typename?: "RunTask" } & RunTaskFragment & RunTaskMetaDataFragment
  >;
};

export type GetRunTypeByIdQueryVariables = Exact<{
  productionRunTypeId: Scalars["Int"];
}>;

export type GetRunTypeByIdQuery = { __typename?: "Query" } & {
  productionRunType?: Maybe<
    { __typename?: "ProductionRunType" } & Pick<
      ProductionRunType,
      "productionRunTypeId" | "typeName"
    >
  >;
};

export type GetRunTemplatesQueryVariables = Exact<{
  pageSize?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  order?: Maybe<Array<RunTemplateSortInput> | RunTemplateSortInput>;
  where?: Maybe<RunTemplateFilterInput>;
}>;

export type GetRunTemplatesQuery = { __typename?: "Query" } & {
  runTemplates?: Maybe<
    { __typename?: "RunTemplatesConnection" } & Pick<
      RunTemplatesConnection,
      "totalCount"
    > & {
        edges?: Maybe<
          Array<
            { __typename?: "RunTemplatesEdge" } & {
              node?: Maybe<
                { __typename?: "RunTemplate" } & RunTemplateFragment
              >;
            }
          >
        >;
        pageInfo: { __typename?: "PageInfo" } & Pick<
          PageInfo,
          "hasNextPage" | "hasPreviousPage" | "endCursor" | "startCursor"
        >;
      }
  >;
};

export type GetRunTemplatesForComboboxQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetRunTemplatesForComboboxQuery = { __typename?: "Query" } & {
  runTemplatesForCombobox?: Maybe<
    Array<
      Maybe<
        { __typename?: "RunTemplateOption" } & Pick<
          RunTemplateOption,
          "runTemplateId" | "runTemplateName"
        >
      >
    >
  >;
};

export type GetRunTemplateTasksQueryVariables = Exact<{
  where?: Maybe<RunTemplateTaskFilterInput>;
}>;

export type GetRunTemplateTasksQuery = { __typename?: "Query" } & {
  runTemplateTasks?: Maybe<
    Array<Maybe<{ __typename?: "RunTemplateTask" } & RunTemplateTaskFragment>>
  >;
};

export type GetRunTaskTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRunTaskTypesQuery = { __typename?: "Query" } & {
  runTaskTypes?: Maybe<
    Array<
      Maybe<
        { __typename?: "RunTaskType" } & Pick<
          RunTaskType,
          "type" | "id" | "name" | "compositeKey" | "automationMethodId"
        >
      >
    >
  >;
};

export type GetPlateCodesQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlateCodesQuery = { __typename?: "Query" } & Pick<
  Query,
  "plateCodes"
>;

export type GetRunTemplateByIdQueryVariables = Exact<{
  runTemplateId: Scalars["Int"];
}>;

export type GetRunTemplateByIdQuery = { __typename?: "Query" } & {
  runTemplate?: Maybe<{ __typename?: "RunTemplate" } & RunTemplateFragment>;
};

export type GetRunTemplateStageByIdQueryVariables = Exact<{
  runTemplateStageId: Scalars["Int"];
}>;

export type GetRunTemplateStageByIdQuery = { __typename?: "Query" } & {
  runTemplateStage?: Maybe<
    { __typename?: "RunTemplateStage" } & RunTemplateStageFragment
  >;
};

export type GetRunTemplateTaskByIdQueryVariables = Exact<{
  runTemplateTaskId: Scalars["Int"];
}>;

export type GetRunTemplateTaskByIdQuery = { __typename?: "Query" } & {
  runTemplateTask?: Maybe<
    { __typename?: "RunTemplateTask" } & RunTemplateTaskFragment
  >;
};

export type GetActiveTicketsQueryVariables = Exact<{
  where?: Maybe<TicketFilterInput>;
  order?: Maybe<Array<TicketSortInput> | TicketSortInput>;
}>;

export type GetActiveTicketsQuery = { __typename?: "Query" } & {
  activeTickets?: Maybe<
    Array<Maybe<{ __typename?: "Ticket" } & TicketFragment>>
  >;
};

export type GetTicketsQueryVariables = Exact<{
  pageSize?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  order?: Maybe<Array<TicketSortInput> | TicketSortInput>;
  where?: Maybe<TicketFilterInput>;
}>;

export type GetTicketsQuery = { __typename?: "Query" } & {
  tickets?: Maybe<
    { __typename?: "TicketsConnection" } & Pick<
      TicketsConnection,
      "totalCount"
    > & {
        edges?: Maybe<
          Array<
            { __typename?: "TicketsEdge" } & {
              node?: Maybe<{ __typename?: "Ticket" } & TicketFragment>;
            }
          >
        >;
        pageInfo: { __typename?: "PageInfo" } & Pick<
          PageInfo,
          "hasNextPage" | "startCursor" | "endCursor" | "hasPreviousPage"
        >;
      }
  >;
};

export type GetTicketAttachmentsQueryVariables = Exact<{
  ticketId: Scalars["Int"];
}>;

export type GetTicketAttachmentsQuery = { __typename?: "Query" } & {
  ticketInfo?: Maybe<{ __typename?: "Ticket" } & TicketAttachmentsFragment>;
};

export type GetTicketInfoQueryVariables = Exact<{
  ticketId: Scalars["Int"];
}>;

export type GetTicketInfoQuery = { __typename?: "Query" } & {
  ticketInfo?: Maybe<{ __typename?: "Ticket" } & TicketAndAttachmentsFragment>;
};

export type GetTicketAssetTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTicketAssetTypesQuery = { __typename?: "Query" } & {
  ticketAssetTypes?: Maybe<
    Array<
      Maybe<
        { __typename?: "LookupAssetType" } & Pick<
          LookupAssetType,
          "assetTypeId" | "assetType"
        >
      >
    >
  >;
};

export type GetTicketTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTicketTypesQuery = { __typename?: "Query" } & {
  ticketTypes?: Maybe<
    Array<
      Maybe<
        { __typename?: "LookupTicketType" } & Pick<
          LookupTicketType,
          "ticketTypeId" | "ticketType"
        >
      >
    >
  >;
};

export type GetTicketAssetsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTicketAssetsQuery = { __typename?: "Query" } & {
  ticketAssets?: Maybe<
    Array<
      Maybe<
        { __typename?: "LookupAsset" } & Pick<
          LookupAsset,
          "assetId" | "asset"
        > & {
            assetType?: Maybe<
              { __typename?: "LookupAssetType" } & Pick<
                LookupAssetType,
                "assetTypeId" | "assetType"
              >
            >;
          }
      >
    >
  >;
};

export type GetTicketByIdQueryVariables = Exact<{
  ticketId: Scalars["Int"];
}>;

export type GetTicketByIdQuery = { __typename?: "Query" } & {
  ticket?: Maybe<{ __typename?: "Ticket" } & TicketFragment>;
};

export type GetTubeLabellerRunsQueryVariables = Exact<{
  method?: Maybe<Scalars["String"]>;
}>;

export type GetTubeLabellerRunsQuery = { __typename?: "Query" } & Pick<
  Query,
  "tubeLabellerRuns"
>;

export type GetTubeLabellerRunsByProcessQueryVariables = Exact<{
  process?: Maybe<Scalars["String"]>;
}>;

export type GetTubeLabellerRunsByProcessQuery = { __typename?: "Query" } & {
  tubeLabellerRunsByProcess?: Maybe<
    Array<
      Maybe<
        { __typename?: "ProductionRun" } & Pick<
          ProductionRun,
          "runId" | "runName"
        > & {
            platesCreateds?: Maybe<
              Array<
                Maybe<
                  { __typename?: "PlatesCreated" } & Pick<
                    PlatesCreated,
                    "plateCreatedId" | "plateBarcode"
                  > & {
                      arrayPlateType?: Maybe<
                        { __typename?: "LookupArrayPlateTypeString" } & {
                          arrayPlateCode?: Maybe<
                            { __typename?: "LookupArrayPlateCode" } & Pick<
                              LookupArrayPlateCode,
                              "arrayPlateCode"
                            >
                          >;
                        }
                      >;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type GetPlatesForManualSelectorQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPlatesForManualSelectorQuery = { __typename?: "Query" } & {
  platesForManualSelector?: Maybe<
    Array<
      Maybe<
        { __typename?: "PlatesCreated" } & Pick<
          PlatesCreated,
          "plateCreatedId" | "plateBarcode"
        >
      >
    >
  >;
};

export type GetCellNumberFromNormalizedPassagePlateQueryVariables = Exact<{
  plateBarcode?: Maybe<Scalars["String"]>;
}>;

export type GetCellNumberFromNormalizedPassagePlateQuery = {
  __typename?: "Query";
} & Pick<Query, "cellNumberFromNormalizedPassagePlate">;

export type GetResuspensionVolFromPooling1224TcPlateQueryVariables = Exact<{
  plateBarcode?: Maybe<Scalars["String"]>;
}>;

export type GetResuspensionVolFromPooling1224TcPlateQuery = {
  __typename?: "Query";
} & Pick<Query, "resuspensionVolFromPooling1224TCPlate">;

export type GetActivePooling1224TcPlatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetActivePooling1224TcPlatesQuery = { __typename?: "Query" } & Pick<
  Query,
  "activePooling1224TCPlates"
>;

export type GetTubeLabellerSalivaSamplesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetTubeLabellerSalivaSamplesQuery = { __typename?: "Query" } & {
  tubeLabellerSalivaSamples?: Maybe<
    Array<
      Maybe<
        { __typename?: "Sample" } & Pick<Sample, "sampleId" | "sampleBarcode">
      >
    >
  >;
};

export type GetRunTasksForTubeLabelsQueryVariables = Exact<{
  plateBarcode?: Maybe<Scalars["String"]>;
  showCompleted: Scalars["Boolean"];
}>;

export type GetRunTasksForTubeLabelsQuery = { __typename?: "Query" } & {
  runTasksForTubeLabels?: Maybe<
    Array<
      Maybe<
        { __typename?: "RunTask" } & Pick<
          RunTask,
          "runId" | "runTaskId" | "activityName" | "startTimeScheduled"
        > & {
            runTaskDetail?: Maybe<
              { __typename?: "RunTaskDetail" } & Pick<
                RunTaskDetail,
                | "runTaskId"
                | "runTaskDetailsId"
                | "freezeDownRackType"
                | "createMystplate"
              >
            >;
          }
      >
    >
  >;
};

export type GetFibroblastRackInfoQueryVariables = Exact<{
  input?: Maybe<AddRackInput>;
}>;

export type GetFibroblastRackInfoQuery = { __typename?: "Query" } & {
  fibroblastRackInfo?: Maybe<
    Array<
      Maybe<
        {
          __typename?: "TubeLabellerDestinationWorklist";
        } & TubeLabellerDestinationWorklistFragment
      >
    >
  >;
};

export type GetMonoclonalizationRackInfoQueryVariables = Exact<{
  input?: Maybe<AddRackInput>;
}>;

export type GetMonoclonalizationRackInfoQuery = { __typename?: "Query" } & {
  monoclonalizationRackInfo?: Maybe<
    Array<
      Maybe<
        {
          __typename?: "TubeLabellerDestinationWorklist";
        } & TubeLabellerDestinationWorklistFragment
      >
    >
  >;
};

export type GetBloodProcessingRackInfoQueryVariables = Exact<{
  input?: Maybe<AddRackInput>;
}>;

export type GetBloodProcessingRackInfoQuery = { __typename?: "Query" } & {
  bloodProcessingRackInfo?: Maybe<
    Array<
      Maybe<
        {
          __typename?: "TubeLabellerDestinationWorklist";
        } & TubeLabellerDestinationWorklistFragment
      >
    >
  >;
};

export type GetSalivaRackInfoQueryVariables = Exact<{
  input?: Maybe<AddRackInput>;
}>;

export type GetSalivaRackInfoQuery = { __typename?: "Query" } & {
  salivaRackInfo?: Maybe<
    Array<
      Maybe<
        {
          __typename?: "TubeLabellerDestinationWorklist";
        } & TubeLabellerDestinationWorklistFragment
      >
    >
  >;
};

export type GetConsolidationRackInfoQueryVariables = Exact<{
  input?: Maybe<AddRackInput>;
}>;

export type GetConsolidationRackInfoQuery = { __typename?: "Query" } & {
  consolidationRackInfo?: Maybe<
    Array<
      Maybe<
        {
          __typename?: "TubeLabellerDestinationWorklist";
        } & TubeLabellerDestinationWorklistFragment
      >
    >
  >;
};

export type GetIpscExpansionRackInfoQueryVariables = Exact<{
  input?: Maybe<AddRackInput>;
}>;

export type GetIpscExpansionRackInfoQuery = { __typename?: "Query" } & {
  iPSCExpansionRackInfo?: Maybe<
    Array<
      Maybe<
        {
          __typename?: "TubeLabellerDestinationWorklist";
        } & TubeLabellerDestinationWorklistFragment
      >
    >
  >;
};

export type GetEbCollectionRackInfoQueryVariables = Exact<{
  input?: Maybe<AddRackInput>;
}>;

export type GetEbCollectionRackInfoQuery = { __typename?: "Query" } & {
  eBCollectionRackInfo?: Maybe<
    Array<
      Maybe<
        {
          __typename?: "TubeLabellerDestinationWorklist";
        } & TubeLabellerDestinationWorklistFragment
      >
    >
  >;
};

export type GetDnarnaRackInfoQueryVariables = Exact<{
  input?: Maybe<AddRackInput>;
}>;

export type GetDnarnaRackInfoQuery = { __typename?: "Query" } & {
  dNARNARackInfo?: Maybe<
    Array<
      Maybe<
        {
          __typename?: "TubeLabellerDestinationWorklist";
        } & TubeLabellerDestinationWorklistFragment
      >
    >
  >;
};

export type GetQcRackInfoQueryVariables = Exact<{
  input?: Maybe<AddRackInput>;
}>;

export type GetQcRackInfoQuery = { __typename?: "Query" } & {
  qCRackInfo?: Maybe<
    Array<
      Maybe<
        {
          __typename?: "TubeLabellerDestinationWorklist";
        } & TubeLabellerDestinationWorklistFragment
      >
    >
  >;
};

export type GetPooling1224TcRackInfoQueryVariables = Exact<{
  input?: Maybe<AddRackInput>;
}>;

export type GetPooling1224TcRackInfoQuery = { __typename?: "Query" } & {
  pooling1224TCRackInfo?: Maybe<
    Array<
      Maybe<
        {
          __typename?: "TubeLabellerDestinationWorklist";
        } & TubeLabellerDestinationWorklistFragment
      >
    >
  >;
};

export type GetManualPlateRackInfoQueryVariables = Exact<{
  input?: Maybe<AddRackInput>;
}>;

export type GetManualPlateRackInfoQuery = { __typename?: "Query" } & {
  manualPlateRackInfo?: Maybe<
    Array<
      Maybe<
        {
          __typename?: "TubeLabellerDestinationWorklist";
        } & TubeLabellerDestinationWorklistFragment
      >
    >
  >;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = { __typename?: "Query" } & {
  me?: Maybe<{ __typename?: "UserClaims" } & UserClaimsFragment>;
};

export type GetGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGroupsQuery = { __typename?: "Query" } & {
  appSuiteGroups?: Maybe<
    Array<Maybe<{ __typename?: "AppSuiteGroup" } & GroupFragment>>
  >;
};

export type GetGroupByIdQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetGroupByIdQuery = { __typename?: "Query" } & {
  appSuiteGroups?: Maybe<
    Array<Maybe<{ __typename?: "AppSuiteGroup" } & GroupFragment>>
  >;
};

export type UserManagerQueryVariables = Exact<{
  where?: Maybe<AppSuiteUserFilterInput>;
  order?: Maybe<Array<AppSuiteUserSortInput> | AppSuiteUserSortInput>;
}>;

export type UserManagerQuery = { __typename?: "Query" } & {
  appSuiteUsers?: Maybe<
    Array<Maybe<{ __typename?: "AppSuiteUser" } & UserFragment>>
  >;
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersQuery = { __typename?: "Query" } & {
  appSuiteUsers?: Maybe<
    Array<Maybe<{ __typename?: "AppSuiteUser" } & UserFragment>>
  >;
};

export type GetUserQueryVariables = Exact<{
  userId: Scalars["Int"];
}>;

export type GetUserQuery = { __typename?: "Query" } & {
  user?: Maybe<{ __typename?: "AppSuiteUser" } & UserFragment>;
};

export type GetUsersFromCacheQueryVariables = Exact<{
  userIds: Array<Scalars["Int"]> | Scalars["Int"];
}>;

export type GetUsersFromCacheQuery = { __typename?: "Query" } & {
  usersFromCache: Array<Maybe<{ __typename?: "AppSuiteUser" } & UserFragment>>;
};

export type GetGroupQueryVariables = Exact<{
  groupId: Scalars["Int"];
}>;

export type GetGroupQuery = { __typename?: "Query" } & {
  group?: Maybe<{ __typename?: "AppSuiteGroup" } & GroupFragment>;
};

export type GetGroupsFromCacheQueryVariables = Exact<{
  groupIds?: Maybe<Array<Scalars["Int"]> | Scalars["Int"]>;
}>;

export type GetGroupsFromCacheQuery = { __typename?: "Query" } & {
  groupsFromCache?: Maybe<
    Array<
      Maybe<
        { __typename?: "AppSuiteGroup" } & Pick<
          AppSuiteGroup,
          "groupId" | "groupName"
        >
      >
    >
  >;
};

export type GetActiveAsamsQueryVariables = Exact<{
  where?: Maybe<ArraySystemAutomationMethodFilterInput>;
}>;

export type GetActiveAsamsQuery = { __typename?: "Query" } & {
  activeASAMs?: Maybe<
    Array<
      Maybe<
        { __typename?: "ArraySystemAutomationMethod" } & Pick<
          ArraySystemAutomationMethod,
          "automationMethodCode" | "arraySystemId"
        >
      >
    >
  >;
};

export type GetArrayMethodLabwareTypesQueryVariables = Exact<{
  where?: Maybe<ArraySystemAutomationMethodLabwareTypeFilterInput>;
}>;

export type GetArrayMethodLabwareTypesQuery = { __typename?: "Query" } & {
  arrayMethodLabwareTypes?: Maybe<
    Array<
      Maybe<
        { __typename?: "ArraySystemAutomationMethodLabwareType" } & Pick<
          ArraySystemAutomationMethodLabwareType,
          | "arraySystemAutomationMethodLabwareTypeId"
          | "arraySystemAutomationMethodId"
          | "arrayMethodPlateRoleId"
        > & {
            labwareType?: Maybe<
              { __typename?: "LookupLabwareType" } & LabwareTypeFragment
            >;
          }
      >
    >
  >;
};

export type GetSourcePlatesQueryVariables = Exact<{
  where?: Maybe<PlatesCreatedFilterInput>;
}>;

export type GetSourcePlatesQuery = { __typename?: "Query" } & {
  sourcePlates?: Maybe<
    Array<
      Maybe<
        { __typename?: "PlatesCreated" } & Pick<
          PlatesCreated,
          "plateCreatedId" | "plateBarcode" | "labwareTypeId"
        >
      >
    >
  >;
};

export type GetPlatesInfoQueryVariables = Exact<{
  where?: Maybe<PlatesCreatedFilterInput>;
}>;

export type GetPlatesInfoQuery = { __typename?: "Query" } & {
  platesInfo?: Maybe<
    { __typename?: "PlatesCreated" } & Pick<
      PlatesCreated,
      "plateCreatedId" | "plateBarcode"
    > & {
        arrayPlateType?: Maybe<
          { __typename?: "LookupArrayPlateTypeString" } & Pick<
            LookupArrayPlateTypeString,
            "plateLayoutTypeId"
          >
        >;
        labwareType?: Maybe<
          { __typename?: "LookupLabwareType" } & LabwareTypeFragment
        >;
        containers?: Maybe<
          Array<
            Maybe<
              { __typename?: "Container" } & Pick<
                Container,
                "containerId" | "isActive" | "barcode" | "rowPos" | "colPos"
              > & {
                  sample?: Maybe<
                    { __typename?: "Sample" } & Pick<
                      Sample,
                      "sampleId" | "sampleBarcode"
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type GetLabwareTypesQueryVariables = Exact<{
  where?: Maybe<LookupLabwareTypeFilterInput>;
}>;

export type GetLabwareTypesQuery = { __typename?: "Query" } & {
  labwareTypes?: Maybe<
    Array<Maybe<{ __typename?: "LookupLabwareType" } & LabwareTypeFragment>>
  >;
};

export type GetExistingMethodReservationQueryVariables = Exact<{
  where?: Maybe<MethodReservationFilterInput>;
}>;

export type GetExistingMethodReservationQuery = { __typename?: "Query" } & {
  existingMethodReservation?: Maybe<
    Array<
      Maybe<
        { __typename?: "MethodReservation" } & Pick<
          MethodReservation,
          "methodReservationId"
        >
      >
    >
  >;
};

export type GetExistingDeadPlateQueryVariables = Exact<{
  srcPlateId: Scalars["Int"];
}>;

export type GetExistingDeadPlateQuery = { __typename?: "Query" } & {
  existingDeadPlate?: Maybe<
    { __typename?: "PlatesCreated" } & Pick<
      PlatesCreated,
      "plateCreatedId" | "plateBarcode"
    > & {
        labwareType?: Maybe<
          { __typename?: "LookupLabwareType" } & Pick<
            LookupLabwareType,
            "defaultLabwareVolume" | "labwareTypeCode" | "labwareTypeName"
          > & {
              plateLayoutType?: Maybe<
                { __typename?: "PlateType" } & Pick<
                  PlateType,
                  "plateRows" | "plateCols"
                >
              >;
            }
        >;
      }
  >;
};

export type GetTaskDetailsForWorklistToolQueryVariables = Exact<{
  runTaskId: Scalars["Int"];
}>;

export type GetTaskDetailsForWorklistToolQuery = { __typename?: "Query" } & {
  taskDetailsForWorklistTool?: Maybe<
    { __typename?: "RunTaskDetail" } & Pick<
      RunTaskDetail,
      | "daughterWellNumber"
      | "seedCellCount"
      | "freezeCellCount"
      | "freezeDownRackType"
      | "createMystplate"
      | "destPlateCode"
      | "maxCryovial"
      | "minCryovial"
      | "overflowVials"
      | "imageExportSetting"
      | "imageExportFormat"
      | "worklistTemplateId"
    >
  >;
};

export type GetWorklistToolTemplateQueryVariables = Exact<{
  where?: Maybe<WorklistToolTemplateFilterInput>;
}>;

export type GetWorklistToolTemplateQuery = { __typename?: "Query" } & {
  worklistToolTemplate?: Maybe<
    Array<
      Maybe<
        { __typename?: "WorklistToolTemplate" } & WorklistToolTemplateFragment
      >
    >
  >;
};

export type GetWorklistToolTemplateContentsQueryVariables = Exact<{
  where?: Maybe<WorklistToolTemplateContentFilterInput>;
}>;

export type GetWorklistToolTemplateContentsQuery = { __typename?: "Query" } & {
  worklistToolTemplateContent?: Maybe<
    Array<
      Maybe<
        {
          __typename?: "WorklistToolTemplateContent";
        } & WorklistToolTemplateContentsFragment
      >
    >
  >;
};

export type PlateHasTubeBarcodeScanQueryVariables = Exact<{
  plateId: Scalars["Int"];
}>;

export type PlateHasTubeBarcodeScanQuery = { __typename?: "Query" } & Pick<
  Query,
  "plateHasTubeBarcodeScan"
>;

export type AppSuiteGroupKeySpecifier = (
  | "groupId"
  | "groupName"
  | "slackGroupId"
  | "slackGroupName"
  | "activeForTaskAssignment"
  | "appSuiteUsers"
  | "collectionTasks"
  | "feedGroups"
  | "folderStructures"
  | "lookupAssetTypes"
  | "lookupTicketTypes"
  | "personalTaskTemplateTasks"
  | "collectionTasksNavigation"
  | "personalTaskTemplateTasksNavigation"
  | "personalTasks"
  | "runTasks"
  | "runTemplateTasks"
  | "runTemplates"
  | "users"
  | AppSuiteGroupKeySpecifier
)[];
export type AppSuiteGroupFieldPolicy = {
  groupId?: FieldPolicy<any> | FieldReadFunction<any>;
  groupName?: FieldPolicy<any> | FieldReadFunction<any>;
  slackGroupId?: FieldPolicy<any> | FieldReadFunction<any>;
  slackGroupName?: FieldPolicy<any> | FieldReadFunction<any>;
  activeForTaskAssignment?: FieldPolicy<any> | FieldReadFunction<any>;
  appSuiteUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  feedGroups?: FieldPolicy<any> | FieldReadFunction<any>;
  folderStructures?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupAssetTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupTicketTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTaskTemplateTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionTasksNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTaskTemplateTasksNavigation?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  personalTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplates?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AppSuiteGroupUserKeySpecifier = (
  | "groupUserId"
  | "userId"
  | "groupId"
  | "isGroupLeader"
  | "isDefaultGroup"
  | "group"
  | "user"
  | AppSuiteGroupUserKeySpecifier
)[];
export type AppSuiteGroupUserFieldPolicy = {
  groupUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  groupId?: FieldPolicy<any> | FieldReadFunction<any>;
  isGroupLeader?: FieldPolicy<any> | FieldReadFunction<any>;
  isDefaultGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  group?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AppSuiteUserKeySpecifier = (
  | "userId"
  | "userName"
  | "email"
  | "icon"
  | "initials"
  | "isActive"
  | "isAdmin"
  | "userIcon"
  | "slackUserId"
  | "groupAccountId"
  | "githubId"
  | "groupAccount"
  | "userSetting"
  | "cellLineRegisteredByUsers"
  | "cellLineUpdatedByUsers"
  | "coatedPlateBatches"
  | "collectionTasks"
  | "collections"
  | "containerComments"
  | "containerMarkings"
  | "containerNotes"
  | "donorComments"
  | "folderStructures"
  | "methodReservationCompletedByUsers"
  | "methodReservationUsers"
  | "normalizedPassageTemplates"
  | "personalTaskCompletedByNavigations"
  | "personalTaskTaskOwners"
  | "personalTaskTemplateTasks"
  | "personalTaskTemplates"
  | "plateNotes"
  | "platesCreateds"
  | "productionRuns"
  | "runTasks"
  | "runTemplateCreatedByNavigations"
  | "runTemplateDeletedBies"
  | "runTemplateLastUpdatedByNavigations"
  | "runs"
  | "ticketAssignees"
  | "ticketFollowers"
  | "ticketUpdates"
  | "userToRoles"
  | "worklistToolTemplates"
  | "collectionTasksNavigation"
  | "personalTaskTemplateTasksNavigation"
  | "personalTasks"
  | "runTasksNavigation"
  | "runTemplateTasks"
  | "groups"
  | "roles"
  | AppSuiteUserKeySpecifier
)[];
export type AppSuiteUserFieldPolicy = {
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  userName?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  icon?: FieldPolicy<any> | FieldReadFunction<any>;
  initials?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  isAdmin?: FieldPolicy<any> | FieldReadFunction<any>;
  userIcon?: FieldPolicy<any> | FieldReadFunction<any>;
  slackUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  groupAccountId?: FieldPolicy<any> | FieldReadFunction<any>;
  githubId?: FieldPolicy<any> | FieldReadFunction<any>;
  groupAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  userSetting?: FieldPolicy<any> | FieldReadFunction<any>;
  cellLineRegisteredByUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  cellLineUpdatedByUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  coatedPlateBatches?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  collections?: FieldPolicy<any> | FieldReadFunction<any>;
  containerComments?: FieldPolicy<any> | FieldReadFunction<any>;
  containerMarkings?: FieldPolicy<any> | FieldReadFunction<any>;
  containerNotes?: FieldPolicy<any> | FieldReadFunction<any>;
  donorComments?: FieldPolicy<any> | FieldReadFunction<any>;
  folderStructures?: FieldPolicy<any> | FieldReadFunction<any>;
  methodReservationCompletedByUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  methodReservationUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  normalizedPassageTemplates?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTaskCompletedByNavigations?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  personalTaskTaskOwners?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTaskTemplateTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTaskTemplates?: FieldPolicy<any> | FieldReadFunction<any>;
  plateNotes?: FieldPolicy<any> | FieldReadFunction<any>;
  platesCreateds?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRuns?: FieldPolicy<any> | FieldReadFunction<any>;
  runTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateCreatedByNavigations?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateDeletedBies?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateLastUpdatedByNavigations?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  runs?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketAssignees?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketFollowers?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketUpdates?: FieldPolicy<any> | FieldReadFunction<any>;
  userToRoles?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistToolTemplates?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionTasksNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTaskTemplateTasksNavigation?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  personalTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTasksNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  groups?: FieldPolicy<any> | FieldReadFunction<any>;
  roles?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ArrayMethodLabwareTypeKeySpecifier = (
  | "arrayMethodLabwareTypeId"
  | "arrayMethodId"
  | "labwareTypeId"
  | "methodPlateRoleId"
  | "isDefault"
  | "arrayMethod"
  | "labwareType"
  | "methodPlateRole"
  | ArrayMethodLabwareTypeKeySpecifier
)[];
export type ArrayMethodLabwareTypeFieldPolicy = {
  arrayMethodLabwareTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  labwareTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  methodPlateRoleId?: FieldPolicy<any> | FieldReadFunction<any>;
  isDefault?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  labwareType?: FieldPolicy<any> | FieldReadFunction<any>;
  methodPlateRole?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ArrayMethodPlateKeySpecifier = (
  | "arrayMethodPlateId"
  | "arrayMethodId"
  | "arrayMethodVariationId"
  | "arrayMethodPlateRoleId"
  | "minNumberInvolved"
  | "maxNumberInvolved"
  | "srcArrayMethodPlateRoleId"
  | "printPlate"
  | "arrayPlateCodeId"
  | "arrayPlateCodeOptions"
  | "worklistTask"
  | "worklistColumnId"
  | "minPlateAspirateVol"
  | "maxPlateAspirateVol"
  | "defaultPlateAspirateVol"
  | "minPlateDispenseVol"
  | "maxPlateDispenseVol"
  | "defaultPlateDispenseVol"
  | "minPlateTransferVol"
  | "maxPlateTransferVol"
  | "defaultPlateTransferVol"
  | "arrayMethod"
  | "arrayMethodPlateRole"
  | "arrayPlateCode"
  | "srcArrayMethodPlateRole"
  | "worklistColumn"
  | ArrayMethodPlateKeySpecifier
)[];
export type ArrayMethodPlateFieldPolicy = {
  arrayMethodPlateId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodVariationId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodPlateRoleId?: FieldPolicy<any> | FieldReadFunction<any>;
  minNumberInvolved?: FieldPolicy<any> | FieldReadFunction<any>;
  maxNumberInvolved?: FieldPolicy<any> | FieldReadFunction<any>;
  srcArrayMethodPlateRoleId?: FieldPolicy<any> | FieldReadFunction<any>;
  printPlate?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayPlateCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayPlateCodeOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistTask?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistColumnId?: FieldPolicy<any> | FieldReadFunction<any>;
  minPlateAspirateVol?: FieldPolicy<any> | FieldReadFunction<any>;
  maxPlateAspirateVol?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultPlateAspirateVol?: FieldPolicy<any> | FieldReadFunction<any>;
  minPlateDispenseVol?: FieldPolicy<any> | FieldReadFunction<any>;
  maxPlateDispenseVol?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultPlateDispenseVol?: FieldPolicy<any> | FieldReadFunction<any>;
  minPlateTransferVol?: FieldPolicy<any> | FieldReadFunction<any>;
  maxPlateTransferVol?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultPlateTransferVol?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodPlateRole?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayPlateCode?: FieldPolicy<any> | FieldReadFunction<any>;
  srcArrayMethodPlateRole?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistColumn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ArraySystemAutomationMethodKeySpecifier = (
  | "arraySystemAutomationMethodId"
  | "automationMethodCode"
  | "arraySystemId"
  | "automationMethodId"
  | "isActive"
  | "averageRunTimeSeconds"
  | "arraySystem"
  | "automationMethod"
  | "arraySystemAutomationMethodLabwareTypes"
  | "arraySystemAutomationMethodProcesses"
  | "feedGroups"
  | "lookupAssets"
  | ArraySystemAutomationMethodKeySpecifier
)[];
export type ArraySystemAutomationMethodFieldPolicy = {
  arraySystemAutomationMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  automationMethodCode?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemId?: FieldPolicy<any> | FieldReadFunction<any>;
  automationMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  averageRunTimeSeconds?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystem?: FieldPolicy<any> | FieldReadFunction<any>;
  automationMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethodLabwareTypes?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  arraySystemAutomationMethodProcesses?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  feedGroups?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupAssets?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ArraySystemAutomationMethodLabwareTypeKeySpecifier = (
  | "arraySystemAutomationMethodLabwareTypeId"
  | "arraySystemAutomationMethodId"
  | "labwareTypeId"
  | "arrayMethodPlateRoleId"
  | "isDefault"
  | "arrayMethodVariationId"
  | "arrayMethodPlateRole"
  | "arraySystemAutomationMethod"
  | "labwareType"
  | ArraySystemAutomationMethodLabwareTypeKeySpecifier
)[];
export type ArraySystemAutomationMethodLabwareTypeFieldPolicy = {
  arraySystemAutomationMethodLabwareTypeId?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  arraySystemAutomationMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  labwareTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodPlateRoleId?: FieldPolicy<any> | FieldReadFunction<any>;
  isDefault?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodVariationId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodPlateRole?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  labwareType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ArraySystemAutomationMethodProcessKeySpecifier = (
  | "arraySystemAutomationMethodProcessId"
  | "arraySystemAutomationMethodId"
  | "automationMethodProcessRunTypeId"
  | "usesCytomat"
  | "usesVspin"
  | "usesDecapper"
  | "usesEasyCode"
  | "expectedRunTime"
  | "arraySystemAutomationMethod"
  | "automationMethodProcessRunType"
  | "arraySystemAutomationMethodProcessRuns"
  | "arraySystemAutomationMethodProcessSteps"
  | ArraySystemAutomationMethodProcessKeySpecifier
)[];
export type ArraySystemAutomationMethodProcessFieldPolicy = {
  arraySystemAutomationMethodProcessId?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  arraySystemAutomationMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  automationMethodProcessRunTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  usesCytomat?: FieldPolicy<any> | FieldReadFunction<any>;
  usesVspin?: FieldPolicy<any> | FieldReadFunction<any>;
  usesDecapper?: FieldPolicy<any> | FieldReadFunction<any>;
  usesEasyCode?: FieldPolicy<any> | FieldReadFunction<any>;
  expectedRunTime?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  automationMethodProcessRunType?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethodProcessRuns?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  arraySystemAutomationMethodProcessSteps?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
};
export type ArraySystemAutomationMethodProcessRunKeySpecifier = (
  | "arraySystemAutomationMethodProcessRunId"
  | "arraySystemAutomationMethodProcessId"
  | "startedAt"
  | "lastUpdatedAt"
  | "automationMethodProcessRunStatusId"
  | "lastArraySystemAutomationMethodProcessStepId"
  | "lastPlateBarcode"
  | "isActive"
  | "isSimulated"
  | "countNtrtips"
  | "count1000uLtips"
  | "count300uLtips"
  | "capacityNtrtips"
  | "capacity1000uLtips"
  | "capacity300uLtips"
  | "incubationTime"
  | "vspinTime"
  | "runTime"
  | "worklistId"
  | "parsedRunInstanceId"
  | "parsedSystem"
  | "parsedAutomationMethod"
  | "parsedAutomationMethodCode"
  | "parsedLastStep"
  | "parsedUsesCytomat"
  | "parsedUsesVspin"
  | "parsedUsesDecapper"
  | "parsedUsesEasyCode"
  | "parsedRunTime"
  | "parsedWorklistContents"
  | "arraySystemAutomationMethodProcess"
  | "automationMethodProcessRunStatus"
  | "arraySystemAutomationMethodProcessRunSteps"
  | ArraySystemAutomationMethodProcessRunKeySpecifier
)[];
export type ArraySystemAutomationMethodProcessRunFieldPolicy = {
  arraySystemAutomationMethodProcessRunId?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  arraySystemAutomationMethodProcessId?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  startedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  lastUpdatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  automationMethodProcessRunStatusId?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  lastArraySystemAutomationMethodProcessStepId?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  lastPlateBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  isSimulated?: FieldPolicy<any> | FieldReadFunction<any>;
  countNtrtips?: FieldPolicy<any> | FieldReadFunction<any>;
  count1000uLtips?: FieldPolicy<any> | FieldReadFunction<any>;
  count300uLtips?: FieldPolicy<any> | FieldReadFunction<any>;
  capacityNtrtips?: FieldPolicy<any> | FieldReadFunction<any>;
  capacity1000uLtips?: FieldPolicy<any> | FieldReadFunction<any>;
  capacity300uLtips?: FieldPolicy<any> | FieldReadFunction<any>;
  incubationTime?: FieldPolicy<any> | FieldReadFunction<any>;
  vspinTime?: FieldPolicy<any> | FieldReadFunction<any>;
  runTime?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistId?: FieldPolicy<any> | FieldReadFunction<any>;
  parsedRunInstanceId?: FieldPolicy<any> | FieldReadFunction<any>;
  parsedSystem?: FieldPolicy<any> | FieldReadFunction<any>;
  parsedAutomationMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  parsedAutomationMethodCode?: FieldPolicy<any> | FieldReadFunction<any>;
  parsedLastStep?: FieldPolicy<any> | FieldReadFunction<any>;
  parsedUsesCytomat?: FieldPolicy<any> | FieldReadFunction<any>;
  parsedUsesVspin?: FieldPolicy<any> | FieldReadFunction<any>;
  parsedUsesDecapper?: FieldPolicy<any> | FieldReadFunction<any>;
  parsedUsesEasyCode?: FieldPolicy<any> | FieldReadFunction<any>;
  parsedRunTime?: FieldPolicy<any> | FieldReadFunction<any>;
  parsedWorklistContents?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethodProcess?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  automationMethodProcessRunStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethodProcessRunSteps?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
};
export type ArraySystemAutomationMethodProcessRunStepKeySpecifier = (
  | "arraySystemAutomationMethodProcessRunStepId"
  | "arraySystemAutomationMethodProcessRunId"
  | "arraySystemAutomationMethodProcessStepId"
  | "startedAt"
  | "sourcePlateBarcode"
  | "destinationPlateBarcode"
  | "details"
  | "isActive"
  | "isComplete"
  | "requiredNtrtips"
  | "required1000uLtips"
  | "required300uLtips"
  | "consumptionNtrtips"
  | "consumption1000uLtips"
  | "consumption300uLtips"
  | "runTime"
  | "parsedRunProcessId"
  | "parsedStep"
  | "arraySystemAutomationMethodProcessRun"
  | "arraySystemAutomationMethodProcessStep"
  | ArraySystemAutomationMethodProcessRunStepKeySpecifier
)[];
export type ArraySystemAutomationMethodProcessRunStepFieldPolicy = {
  arraySystemAutomationMethodProcessRunStepId?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  arraySystemAutomationMethodProcessRunId?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  arraySystemAutomationMethodProcessStepId?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  startedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  sourcePlateBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  destinationPlateBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  details?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  isComplete?: FieldPolicy<any> | FieldReadFunction<any>;
  requiredNtrtips?: FieldPolicy<any> | FieldReadFunction<any>;
  required1000uLtips?: FieldPolicy<any> | FieldReadFunction<any>;
  required300uLtips?: FieldPolicy<any> | FieldReadFunction<any>;
  consumptionNtrtips?: FieldPolicy<any> | FieldReadFunction<any>;
  consumption1000uLtips?: FieldPolicy<any> | FieldReadFunction<any>;
  consumption300uLtips?: FieldPolicy<any> | FieldReadFunction<any>;
  runTime?: FieldPolicy<any> | FieldReadFunction<any>;
  parsedRunProcessId?: FieldPolicy<any> | FieldReadFunction<any>;
  parsedStep?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethodProcessRun?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  arraySystemAutomationMethodProcessStep?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
};
export type ArraySystemAutomationMethodProcessStepKeySpecifier = (
  | "arraySystemAutomationMethodProcessStepId"
  | "arraySystemAutomationMethodProcessId"
  | "automationMethodProcessStepTypeId"
  | "worklistDetailFieldId"
  | "processStepPosition"
  | "processStepInnerPosition"
  | "automationMethodProcessStepControlStringId"
  | "isTracked"
  | "usageNtrtips"
  | "usage1000uLtips"
  | "usage300uLtips"
  | "expectedRuntime"
  | "batchSize"
  | "arraySystemAutomationMethodProcess"
  | "arraySystemAutomationMethodProcessRunSteps"
  | ArraySystemAutomationMethodProcessStepKeySpecifier
)[];
export type ArraySystemAutomationMethodProcessStepFieldPolicy = {
  arraySystemAutomationMethodProcessStepId?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  arraySystemAutomationMethodProcessId?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  automationMethodProcessStepTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistDetailFieldId?: FieldPolicy<any> | FieldReadFunction<any>;
  processStepPosition?: FieldPolicy<any> | FieldReadFunction<any>;
  processStepInnerPosition?: FieldPolicy<any> | FieldReadFunction<any>;
  automationMethodProcessStepControlStringId?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  isTracked?: FieldPolicy<any> | FieldReadFunction<any>;
  usageNtrtips?: FieldPolicy<any> | FieldReadFunction<any>;
  usage1000uLtips?: FieldPolicy<any> | FieldReadFunction<any>;
  usage300uLtips?: FieldPolicy<any> | FieldReadFunction<any>;
  expectedRuntime?: FieldPolicy<any> | FieldReadFunction<any>;
  batchSize?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethodProcess?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  arraySystemAutomationMethodProcessRunSteps?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
};
export type CalendarNodeKeySpecifier = (
  | "id"
  | "name"
  | "taskType"
  | "runId"
  | "startTimeScheduled"
  | "endTimeScheduled"
  | "startTimeActual"
  | "endTimeActual"
  | "startTime"
  | "endTime"
  | "status"
  | "priority"
  | "systemAssignedTo"
  | "groups"
  | "users"
  | "methodReservations"
  | "systemAssignedToNavigation"
  | "method"
  | "manualTaskType"
  | "runName"
  | "lastTimeUpdated"
  | "updatedBy"
  | "updatedByName"
  | "inProgress"
  | CalendarNodeKeySpecifier
)[];
export type CalendarNodeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  taskType?: FieldPolicy<any> | FieldReadFunction<any>;
  runId?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeScheduled?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeScheduled?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeActual?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeActual?: FieldPolicy<any> | FieldReadFunction<any>;
  startTime?: FieldPolicy<any> | FieldReadFunction<any>;
  endTime?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  priority?: FieldPolicy<any> | FieldReadFunction<any>;
  systemAssignedTo?: FieldPolicy<any> | FieldReadFunction<any>;
  groups?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
  methodReservations?: FieldPolicy<any> | FieldReadFunction<any>;
  systemAssignedToNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  method?: FieldPolicy<any> | FieldReadFunction<any>;
  manualTaskType?: FieldPolicy<any> | FieldReadFunction<any>;
  runName?: FieldPolicy<any> | FieldReadFunction<any>;
  lastTimeUpdated?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByName?: FieldPolicy<any> | FieldReadFunction<any>;
  inProgress?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CellLineKeySpecifier = (
  | "cellLineId"
  | "sourceCellLine"
  | "donorId"
  | "collectionNumber"
  | "sourceSampleTypeId"
  | "ageAtCollection"
  | "yearCollected"
  | "passageNumberAtThaw"
  | "workflowPriority"
  | "iPsclinesRequired"
  | "requiresMonoclonalization"
  | "requiresMediaProcess"
  | "distributable"
  | "providedAsIs"
  | "vendorId"
  | "comments"
  | "dateRegistered"
  | "registeredByUserId"
  | "dateLastUpdated"
  | "updatedByUserId"
  | "donor"
  | "registeredByUser"
  | "sourceSampleType"
  | "updatedByUser"
  | "samples"
  | CellLineKeySpecifier
)[];
export type CellLineFieldPolicy = {
  cellLineId?: FieldPolicy<any> | FieldReadFunction<any>;
  sourceCellLine?: FieldPolicy<any> | FieldReadFunction<any>;
  donorId?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  sourceSampleTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  ageAtCollection?: FieldPolicy<any> | FieldReadFunction<any>;
  yearCollected?: FieldPolicy<any> | FieldReadFunction<any>;
  passageNumberAtThaw?: FieldPolicy<any> | FieldReadFunction<any>;
  workflowPriority?: FieldPolicy<any> | FieldReadFunction<any>;
  iPsclinesRequired?: FieldPolicy<any> | FieldReadFunction<any>;
  requiresMonoclonalization?: FieldPolicy<any> | FieldReadFunction<any>;
  requiresMediaProcess?: FieldPolicy<any> | FieldReadFunction<any>;
  distributable?: FieldPolicy<any> | FieldReadFunction<any>;
  providedAsIs?: FieldPolicy<any> | FieldReadFunction<any>;
  vendorId?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  dateRegistered?: FieldPolicy<any> | FieldReadFunction<any>;
  registeredByUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  dateLastUpdated?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  donor?: FieldPolicy<any> | FieldReadFunction<any>;
  registeredByUser?: FieldPolicy<any> | FieldReadFunction<any>;
  sourceSampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByUser?: FieldPolicy<any> | FieldReadFunction<any>;
  samples?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClusterKeySpecifier = (
  | "clusterId"
  | "cluster1"
  | "lookupArraySystems"
  | ClusterKeySpecifier
)[];
export type ClusterFieldPolicy = {
  clusterId?: FieldPolicy<any> | FieldReadFunction<any>;
  cluster1?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupArraySystems?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CoatedPlateBatchKeySpecifier = (
  | "batchId"
  | "batchNumber"
  | "batchTypeId"
  | "createdByUserId"
  | "createdDate"
  | "coatingLotNumber"
  | "mediaLotNumber"
  | "fromVendorAliquot"
  | "comments"
  | "mediaTypeId"
  | "artifactsPresent"
  | "numPe96w"
  | "numC96w"
  | "numC6w"
  | "numC12w"
  | "numC24w"
  | "createdByUser"
  | "platesCreateds"
  | CoatedPlateBatchKeySpecifier
)[];
export type CoatedPlateBatchFieldPolicy = {
  batchId?: FieldPolicy<any> | FieldReadFunction<any>;
  batchNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  batchTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdDate?: FieldPolicy<any> | FieldReadFunction<any>;
  coatingLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  mediaLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  fromVendorAliquot?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  mediaTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  artifactsPresent?: FieldPolicy<any> | FieldReadFunction<any>;
  numPe96w?: FieldPolicy<any> | FieldReadFunction<any>;
  numC96w?: FieldPolicy<any> | FieldReadFunction<any>;
  numC6w?: FieldPolicy<any> | FieldReadFunction<any>;
  numC12w?: FieldPolicy<any> | FieldReadFunction<any>;
  numC24w?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByUser?: FieldPolicy<any> | FieldReadFunction<any>;
  platesCreateds?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CollectionKeySpecifier = (
  | "collectionId"
  | "projectId"
  | "dateTimeOfArrival"
  | "dateTimeOfCollection"
  | "createdBy"
  | "createdByNavigation"
  | "project"
  | "collectionSampleTypes"
  | CollectionKeySpecifier
)[];
export type CollectionFieldPolicy = {
  collectionId?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  dateTimeOfArrival?: FieldPolicy<any> | FieldReadFunction<any>;
  dateTimeOfCollection?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionSampleTypes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CollectionSampleTypeKeySpecifier = (
  | "incomingSampleTypeId"
  | "collectionId"
  | "dateToProcess"
  | "notes"
  | "isActive"
  | "sampleTypeId"
  | "collection"
  | "collectionTasks"
  | "sampleTypeText"
  | "sampleType"
  | CollectionSampleTypeKeySpecifier
)[];
export type CollectionSampleTypeFieldPolicy = {
  incomingSampleTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionId?: FieldPolicy<any> | FieldReadFunction<any>;
  dateToProcess?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  collection?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleTypeText?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CollectionTaskKeySpecifier = (
  | "status"
  | "priority"
  | "incomingSampleTaskId"
  | "incomingSampleTypeId"
  | "incomingSampleParentTaskId"
  | "task"
  | "startTimeScheduled"
  | "endTimeScheduled"
  | "startTimeActual"
  | "endTimeActual"
  | "userAssignedTo"
  | "groupAssignedTo"
  | "systemAssignedTo"
  | "methodId"
  | "folderParentId"
  | "displayInsideIncomingSamplesFolder"
  | "updatedBy"
  | "updatedByTime"
  | "completedBy"
  | "completedByTime"
  | "folderParent"
  | "groupAssignedToNavigation"
  | "incomingSampleType"
  | "method"
  | "systemAssignedToNavigation"
  | "userAssignedToNavigation"
  | "groups"
  | "users"
  | CollectionTaskKeySpecifier
)[];
export type CollectionTaskFieldPolicy = {
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  priority?: FieldPolicy<any> | FieldReadFunction<any>;
  incomingSampleTaskId?: FieldPolicy<any> | FieldReadFunction<any>;
  incomingSampleTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  incomingSampleParentTaskId?: FieldPolicy<any> | FieldReadFunction<any>;
  task?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeScheduled?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeScheduled?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeActual?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeActual?: FieldPolicy<any> | FieldReadFunction<any>;
  userAssignedTo?: FieldPolicy<any> | FieldReadFunction<any>;
  groupAssignedTo?: FieldPolicy<any> | FieldReadFunction<any>;
  systemAssignedTo?: FieldPolicy<any> | FieldReadFunction<any>;
  methodId?: FieldPolicy<any> | FieldReadFunction<any>;
  folderParentId?: FieldPolicy<any> | FieldReadFunction<any>;
  displayInsideIncomingSamplesFolder?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByTime?: FieldPolicy<any> | FieldReadFunction<any>;
  completedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  completedByTime?: FieldPolicy<any> | FieldReadFunction<any>;
  folderParent?: FieldPolicy<any> | FieldReadFunction<any>;
  groupAssignedToNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  incomingSampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  method?: FieldPolicy<any> | FieldReadFunction<any>;
  systemAssignedToNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  userAssignedToNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  groups?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContainerKeySpecifier = (
  | "containerId"
  | "sampleId"
  | "plateId"
  | "rowPos"
  | "colPos"
  | "barcode"
  | "dateCreated"
  | "comments"
  | "isActive"
  | "inactivationReasonId"
  | "reasonInactivated"
  | "inactivatedBy"
  | "dateInactivated"
  | "lotNumberId"
  | "passageNumber"
  | "explicitlyDeclaredPassageNumber"
  | "startingCellNumber"
  | "explicitlyDeclaredStartingCellNumber"
  | "dateUploaded"
  | "lastUpdatedByUserId"
  | "dateLastUpdated"
  | "temporaryCreation"
  | "isPicked"
  | "lotNumberIdold"
  | "startingPassageNumber"
  | "rowCol"
  | "colRow"
  | "platePosition"
  | "markingSummary"
  | "contaminatedMarking"
  | "differentiatedMarking"
  | "noCellsMarking"
  | "qcpassFailMarking"
  | "colonyMarking"
  | "clonalityMarking"
  | "conReviewMarking"
  | "sangerSeqMarking"
  | "lastDataPointSummary"
  | "lastDataPointValue"
  | "createdByMappingFileId"
  | "lastLineageCorrectionId"
  | "inactivationReason"
  | "lotNumber"
  | "plate"
  | "sample"
  | "containerComments"
  | "containerMarkings"
  | "containerNotes"
  | "matricesTubeDestContainer"
  | "matricesTubeSrcContainer"
  | "containerMetadata"
  | ContainerKeySpecifier
)[];
export type ContainerFieldPolicy = {
  containerId?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleId?: FieldPolicy<any> | FieldReadFunction<any>;
  plateId?: FieldPolicy<any> | FieldReadFunction<any>;
  rowPos?: FieldPolicy<any> | FieldReadFunction<any>;
  colPos?: FieldPolicy<any> | FieldReadFunction<any>;
  barcode?: FieldPolicy<any> | FieldReadFunction<any>;
  dateCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  inactivationReasonId?: FieldPolicy<any> | FieldReadFunction<any>;
  reasonInactivated?: FieldPolicy<any> | FieldReadFunction<any>;
  inactivatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  dateInactivated?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumberId?: FieldPolicy<any> | FieldReadFunction<any>;
  passageNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  explicitlyDeclaredPassageNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  startingCellNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  explicitlyDeclaredStartingCellNumber?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  dateUploaded?: FieldPolicy<any> | FieldReadFunction<any>;
  lastUpdatedByUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  dateLastUpdated?: FieldPolicy<any> | FieldReadFunction<any>;
  temporaryCreation?: FieldPolicy<any> | FieldReadFunction<any>;
  isPicked?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumberIdold?: FieldPolicy<any> | FieldReadFunction<any>;
  startingPassageNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  rowCol?: FieldPolicy<any> | FieldReadFunction<any>;
  colRow?: FieldPolicy<any> | FieldReadFunction<any>;
  platePosition?: FieldPolicy<any> | FieldReadFunction<any>;
  markingSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  contaminatedMarking?: FieldPolicy<any> | FieldReadFunction<any>;
  differentiatedMarking?: FieldPolicy<any> | FieldReadFunction<any>;
  noCellsMarking?: FieldPolicy<any> | FieldReadFunction<any>;
  qcpassFailMarking?: FieldPolicy<any> | FieldReadFunction<any>;
  colonyMarking?: FieldPolicy<any> | FieldReadFunction<any>;
  clonalityMarking?: FieldPolicy<any> | FieldReadFunction<any>;
  conReviewMarking?: FieldPolicy<any> | FieldReadFunction<any>;
  sangerSeqMarking?: FieldPolicy<any> | FieldReadFunction<any>;
  lastDataPointSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  lastDataPointValue?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByMappingFileId?: FieldPolicy<any> | FieldReadFunction<any>;
  lastLineageCorrectionId?: FieldPolicy<any> | FieldReadFunction<any>;
  inactivationReason?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  plate?: FieldPolicy<any> | FieldReadFunction<any>;
  sample?: FieldPolicy<any> | FieldReadFunction<any>;
  containerComments?: FieldPolicy<any> | FieldReadFunction<any>;
  containerMarkings?: FieldPolicy<any> | FieldReadFunction<any>;
  containerNotes?: FieldPolicy<any> | FieldReadFunction<any>;
  matricesTubeDestContainer?: FieldPolicy<any> | FieldReadFunction<any>;
  matricesTubeSrcContainer?: FieldPolicy<any> | FieldReadFunction<any>;
  containerMetadata?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContainerCommentKeySpecifier = (
  | "containerCommentId"
  | "containerId"
  | "comment"
  | "dateTime"
  | "userId"
  | "container"
  | "user"
  | ContainerCommentKeySpecifier
)[];
export type ContainerCommentFieldPolicy = {
  containerCommentId?: FieldPolicy<any> | FieldReadFunction<any>;
  containerId?: FieldPolicy<any> | FieldReadFunction<any>;
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  dateTime?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  container?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContainerLineageKeySpecifier = (
  | "containerId"
  | "hierarchy"
  | "plateBarcode"
  | "rowPos"
  | "colPos"
  | "sample"
  | "tubeBarcode"
  | "dateCreated"
  | "passageNumber"
  | "lotNumber"
  | "isActive"
  | "finalConfluence"
  | "lN2SAMActivity"
  | "comments"
  | ContainerLineageKeySpecifier
)[];
export type ContainerLineageFieldPolicy = {
  containerId?: FieldPolicy<any> | FieldReadFunction<any>;
  hierarchy?: FieldPolicy<any> | FieldReadFunction<any>;
  plateBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  rowPos?: FieldPolicy<any> | FieldReadFunction<any>;
  colPos?: FieldPolicy<any> | FieldReadFunction<any>;
  sample?: FieldPolicy<any> | FieldReadFunction<any>;
  tubeBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  dateCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  passageNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  finalConfluence?: FieldPolicy<any> | FieldReadFunction<any>;
  lN2SAMActivity?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContainerMarkingKeySpecifier = (
  | "containerMarkingId"
  | "containerId"
  | "dateMarked"
  | "markMethod"
  | "containerMarkingTypeId"
  | "markingAmount"
  | "markedByUserId"
  | "container"
  | "containerMarkingType"
  | "markedByUser"
  | ContainerMarkingKeySpecifier
)[];
export type ContainerMarkingFieldPolicy = {
  containerMarkingId?: FieldPolicy<any> | FieldReadFunction<any>;
  containerId?: FieldPolicy<any> | FieldReadFunction<any>;
  dateMarked?: FieldPolicy<any> | FieldReadFunction<any>;
  markMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  containerMarkingTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  markingAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  markedByUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  container?: FieldPolicy<any> | FieldReadFunction<any>;
  containerMarkingType?: FieldPolicy<any> | FieldReadFunction<any>;
  markedByUser?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContainerMetaDataKeySpecifier = (
  | "plateCreatedId"
  | "containerId"
  | "rowCol"
  | "rowPos"
  | "colPos"
  | "sampleBarcode"
  | "barcode"
  | "isActive"
  | "lotNumber"
  | "passageNumber"
  | "lastScanDate"
  | "lastDataPointSummary"
  | "markingSummary"
  | "nyscfid"
  | "collaboratorId"
  | "sourceCellLine"
  | "runName"
  | "plateBarcodeAlias"
  | "sampleBarcodeAliases"
  | "lastDataPointValue"
  | "externalLotNumber"
  | "spitLotNumber"
  | "preFibroblastLotNumber"
  | "fibroblastLotNumber"
  | "bloodProcessingLotNumber"
  | "reprogrammingLotNumber"
  | "consolidationLotNumber"
  | "monoclonalizationLotNumber"
  | "expansionLotNumber"
  | "geneEditingLotNumber"
  | "dnaextractionLotNumber"
  | "rpelotNumber"
  | "referenceLotNumber"
  | "otherLotNumber"
  | "contaminatedMarking"
  | "differentiatedMarking"
  | "noCellsMarking"
  | "clonalityMarking"
  | "qcpassFailMarking"
  | "colonyMarking"
  | "sangerSeqMarking"
  | "conReviewMarking"
  | ContainerMetaDataKeySpecifier
)[];
export type ContainerMetaDataFieldPolicy = {
  plateCreatedId?: FieldPolicy<any> | FieldReadFunction<any>;
  containerId?: FieldPolicy<any> | FieldReadFunction<any>;
  rowCol?: FieldPolicy<any> | FieldReadFunction<any>;
  rowPos?: FieldPolicy<any> | FieldReadFunction<any>;
  colPos?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  barcode?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  passageNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  lastScanDate?: FieldPolicy<any> | FieldReadFunction<any>;
  lastDataPointSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  markingSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  nyscfid?: FieldPolicy<any> | FieldReadFunction<any>;
  collaboratorId?: FieldPolicy<any> | FieldReadFunction<any>;
  sourceCellLine?: FieldPolicy<any> | FieldReadFunction<any>;
  runName?: FieldPolicy<any> | FieldReadFunction<any>;
  plateBarcodeAlias?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleBarcodeAliases?: FieldPolicy<any> | FieldReadFunction<any>;
  lastDataPointValue?: FieldPolicy<any> | FieldReadFunction<any>;
  externalLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  spitLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  preFibroblastLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  fibroblastLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  bloodProcessingLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  reprogrammingLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  consolidationLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  monoclonalizationLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  expansionLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  geneEditingLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  dnaextractionLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  rpelotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  referenceLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  otherLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  contaminatedMarking?: FieldPolicy<any> | FieldReadFunction<any>;
  differentiatedMarking?: FieldPolicy<any> | FieldReadFunction<any>;
  noCellsMarking?: FieldPolicy<any> | FieldReadFunction<any>;
  clonalityMarking?: FieldPolicy<any> | FieldReadFunction<any>;
  qcpassFailMarking?: FieldPolicy<any> | FieldReadFunction<any>;
  colonyMarking?: FieldPolicy<any> | FieldReadFunction<any>;
  sangerSeqMarking?: FieldPolicy<any> | FieldReadFunction<any>;
  conReviewMarking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContainerNoteKeySpecifier = (
  | "containerNoteId"
  | "containerId"
  | "note"
  | "dateCommented"
  | "userId"
  | "container"
  | "user"
  | ContainerNoteKeySpecifier
)[];
export type ContainerNoteFieldPolicy = {
  containerNoteId?: FieldPolicy<any> | FieldReadFunction<any>;
  containerId?: FieldPolicy<any> | FieldReadFunction<any>;
  note?: FieldPolicy<any> | FieldReadFunction<any>;
  dateCommented?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  container?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContainersConnectionKeySpecifier = (
  | "pageInfo"
  | "edges"
  | "nodes"
  | "totalCount"
  | ContainersConnectionKeySpecifier
)[];
export type ContainersConnectionFieldPolicy = {
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContainersEdgeKeySpecifier = (
  | "cursor"
  | "node"
  | ContainersEdgeKeySpecifier
)[];
export type ContainersEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateRunDefaultsKeySpecifier = (
  | "runName"
  | "runFolder"
  | CreateRunDefaultsKeySpecifier
)[];
export type CreateRunDefaultsFieldPolicy = {
  runName?: FieldPolicy<any> | FieldReadFunction<any>;
  runFolder?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateRunFolderPayloadKeySpecifier = (
  | "directoryFolder"
  | "folderStructure"
  | CreateRunFolderPayloadKeySpecifier
)[];
export type CreateRunFolderPayloadFieldPolicy = {
  directoryFolder?: FieldPolicy<any> | FieldReadFunction<any>;
  folderStructure?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CytomatKeySpecifier = (
  | "cytomatId"
  | "name"
  | "labComputerId"
  | "capacity"
  | "labComputer"
  | "cytomatWarnings"
  | CytomatKeySpecifier
)[];
export type CytomatFieldPolicy = {
  cytomatId?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  labComputerId?: FieldPolicy<any> | FieldReadFunction<any>;
  capacity?: FieldPolicy<any> | FieldReadFunction<any>;
  labComputer?: FieldPolicy<any> | FieldReadFunction<any>;
  cytomatWarnings?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CytomatPositionKeySpecifier = (
  | "cytomatPositionId"
  | "cytomatId"
  | "position"
  | "barcode"
  | "isOccupied"
  | "modifiedOn"
  | "importedOn"
  | "plateId"
  | "plate"
  | CytomatPositionKeySpecifier
)[];
export type CytomatPositionFieldPolicy = {
  cytomatPositionId?: FieldPolicy<any> | FieldReadFunction<any>;
  cytomatId?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  barcode?: FieldPolicy<any> | FieldReadFunction<any>;
  isOccupied?: FieldPolicy<any> | FieldReadFunction<any>;
  modifiedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  importedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  plateId?: FieldPolicy<any> | FieldReadFunction<any>;
  plate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CytomatWarningKeySpecifier = (
  | "cytomatWarningId"
  | "cytomatId"
  | "entity"
  | "dateWarned"
  | "cytomat"
  | CytomatWarningKeySpecifier
)[];
export type CytomatWarningFieldPolicy = {
  cytomatWarningId?: FieldPolicy<any> | FieldReadFunction<any>;
  cytomatId?: FieldPolicy<any> | FieldReadFunction<any>;
  entity?: FieldPolicy<any> | FieldReadFunction<any>;
  dateWarned?: FieldPolicy<any> | FieldReadFunction<any>;
  cytomat?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DashboardTaskKeySpecifier = (
  | "taskOwnerId"
  | "taskOwnerName"
  | "taskId"
  | "taskName"
  | "taskType"
  | "startTime"
  | "endTime"
  | "priority"
  | "status"
  | "assignedUsers"
  | "assignedGroups"
  | "systemId"
  | "notes"
  | "methodId"
  | "folderId"
  | "startTimeScheduled"
  | "endTimeScheduled"
  | "startTimeActual"
  | "endTimeActual"
  | "taskAttachment"
  | "methodReservationPlateMetadata"
  | "project"
  | "sampleType"
  | "taskSettings"
  | "startDate"
  | DashboardTaskKeySpecifier
)[];
export type DashboardTaskFieldPolicy = {
  taskOwnerId?: FieldPolicy<any> | FieldReadFunction<any>;
  taskOwnerName?: FieldPolicy<any> | FieldReadFunction<any>;
  taskId?: FieldPolicy<any> | FieldReadFunction<any>;
  taskName?: FieldPolicy<any> | FieldReadFunction<any>;
  taskType?: FieldPolicy<any> | FieldReadFunction<any>;
  startTime?: FieldPolicy<any> | FieldReadFunction<any>;
  endTime?: FieldPolicy<any> | FieldReadFunction<any>;
  priority?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  assignedUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  assignedGroups?: FieldPolicy<any> | FieldReadFunction<any>;
  systemId?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  methodId?: FieldPolicy<any> | FieldReadFunction<any>;
  folderId?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeScheduled?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeScheduled?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeActual?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeActual?: FieldPolicy<any> | FieldReadFunction<any>;
  taskAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
  methodReservationPlateMetadata?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  taskSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  startDate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DirectoryFolderKeySpecifier = (
  | "name"
  | "parentType"
  | "parentId"
  | "subFolders"
  | "id"
  | "type"
  | DirectoryFolderKeySpecifier
)[];
export type DirectoryFolderFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  parentType?: FieldPolicy<any> | FieldReadFunction<any>;
  parentId?: FieldPolicy<any> | FieldReadFunction<any>;
  subFolders?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DonorKeySpecifier = (
  | "donorId"
  | "nyscfid"
  | "collaboratorId"
  | "nyscfidalias"
  | "collaboratorIdalias"
  | "nyscfidalias2"
  | "sex"
  | "yearOfBirth"
  | "description"
  | "studySampleType"
  | "redcapNyscfidalias"
  | "recontact"
  | "noRecontactDetails"
  | "flagged"
  | "flaggedDetails"
  | "subjectOverviewComplete"
  | "education"
  | "educationOther"
  | "handedness"
  | "adopted"
  | "twinOrMultiple"
  | "twinType"
  | "multipleDetails"
  | "raceAmericanIndianOrAlaskaNative"
  | "raceAsianOrPacificIslander"
  | "raceBlack"
  | "raceHispanic"
  | "raceWhite"
  | "raceOther"
  | "otherRace"
  | "raceUnknown"
  | "ancestryAdmixedAmerican"
  | "ancestryAfrican"
  | "ancestryEastAsian"
  | "ancestryEuropean"
  | "ancestrySouthAsian"
  | "dateAncestryEstimated"
  | "ashkenazi"
  | "ashkenaziGrandparents"
  | "demographicsComments"
  | "demographicsComplete"
  | "studyHistoryNyuPdr"
  | "studyHistoryNyuControl"
  | "studyHistory10001"
  | "studyHistoryEmbryo"
  | "studyHistoryPgp"
  | "studyHistoryOther"
  | "otherStudy"
  | "statusId"
  | "mostRecentPlateCreated"
  | "mostRecentSampleTypeId"
  | "mostRecentSampleBarcode"
  | "affectedMedicalConditionId"
  | "clonesPassedQc"
  | "dateAdded"
  | "yearRegistered"
  | "registeredBy"
  | "dateLastUpdated"
  | "updatedBy"
  | "studyInformationComplete"
  | "paternalGrandFather"
  | "paternalGrandFatherOther"
  | "paternalGrandMother"
  | "paternalGrandMotherOther"
  | "maternalGrandFather"
  | "maternalGrandFatherOther"
  | "maternalGrandMother"
  | "maternalGrandMotherOther"
  | "reportedEthnicity"
  | "isTwin"
  | "isAdopted"
  | "ageAtDeath"
  | "apoemutationTypes"
  | "amyloidBuildUp"
  | "neuriticPlaqueAverageScaledNeocortex"
  | "braakscore"
  | "cellLines"
  | "donorComments"
  | "donorMedicalConditions"
  | "projectDonors"
  | DonorKeySpecifier
)[];
export type DonorFieldPolicy = {
  donorId?: FieldPolicy<any> | FieldReadFunction<any>;
  nyscfid?: FieldPolicy<any> | FieldReadFunction<any>;
  collaboratorId?: FieldPolicy<any> | FieldReadFunction<any>;
  nyscfidalias?: FieldPolicy<any> | FieldReadFunction<any>;
  collaboratorIdalias?: FieldPolicy<any> | FieldReadFunction<any>;
  nyscfidalias2?: FieldPolicy<any> | FieldReadFunction<any>;
  sex?: FieldPolicy<any> | FieldReadFunction<any>;
  yearOfBirth?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  studySampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  redcapNyscfidalias?: FieldPolicy<any> | FieldReadFunction<any>;
  recontact?: FieldPolicy<any> | FieldReadFunction<any>;
  noRecontactDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  flagged?: FieldPolicy<any> | FieldReadFunction<any>;
  flaggedDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  subjectOverviewComplete?: FieldPolicy<any> | FieldReadFunction<any>;
  education?: FieldPolicy<any> | FieldReadFunction<any>;
  educationOther?: FieldPolicy<any> | FieldReadFunction<any>;
  handedness?: FieldPolicy<any> | FieldReadFunction<any>;
  adopted?: FieldPolicy<any> | FieldReadFunction<any>;
  twinOrMultiple?: FieldPolicy<any> | FieldReadFunction<any>;
  twinType?: FieldPolicy<any> | FieldReadFunction<any>;
  multipleDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  raceAmericanIndianOrAlaskaNative?: FieldPolicy<any> | FieldReadFunction<any>;
  raceAsianOrPacificIslander?: FieldPolicy<any> | FieldReadFunction<any>;
  raceBlack?: FieldPolicy<any> | FieldReadFunction<any>;
  raceHispanic?: FieldPolicy<any> | FieldReadFunction<any>;
  raceWhite?: FieldPolicy<any> | FieldReadFunction<any>;
  raceOther?: FieldPolicy<any> | FieldReadFunction<any>;
  otherRace?: FieldPolicy<any> | FieldReadFunction<any>;
  raceUnknown?: FieldPolicy<any> | FieldReadFunction<any>;
  ancestryAdmixedAmerican?: FieldPolicy<any> | FieldReadFunction<any>;
  ancestryAfrican?: FieldPolicy<any> | FieldReadFunction<any>;
  ancestryEastAsian?: FieldPolicy<any> | FieldReadFunction<any>;
  ancestryEuropean?: FieldPolicy<any> | FieldReadFunction<any>;
  ancestrySouthAsian?: FieldPolicy<any> | FieldReadFunction<any>;
  dateAncestryEstimated?: FieldPolicy<any> | FieldReadFunction<any>;
  ashkenazi?: FieldPolicy<any> | FieldReadFunction<any>;
  ashkenaziGrandparents?: FieldPolicy<any> | FieldReadFunction<any>;
  demographicsComments?: FieldPolicy<any> | FieldReadFunction<any>;
  demographicsComplete?: FieldPolicy<any> | FieldReadFunction<any>;
  studyHistoryNyuPdr?: FieldPolicy<any> | FieldReadFunction<any>;
  studyHistoryNyuControl?: FieldPolicy<any> | FieldReadFunction<any>;
  studyHistory10001?: FieldPolicy<any> | FieldReadFunction<any>;
  studyHistoryEmbryo?: FieldPolicy<any> | FieldReadFunction<any>;
  studyHistoryPgp?: FieldPolicy<any> | FieldReadFunction<any>;
  studyHistoryOther?: FieldPolicy<any> | FieldReadFunction<any>;
  otherStudy?: FieldPolicy<any> | FieldReadFunction<any>;
  statusId?: FieldPolicy<any> | FieldReadFunction<any>;
  mostRecentPlateCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  mostRecentSampleTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  mostRecentSampleBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  affectedMedicalConditionId?: FieldPolicy<any> | FieldReadFunction<any>;
  clonesPassedQc?: FieldPolicy<any> | FieldReadFunction<any>;
  dateAdded?: FieldPolicy<any> | FieldReadFunction<any>;
  yearRegistered?: FieldPolicy<any> | FieldReadFunction<any>;
  registeredBy?: FieldPolicy<any> | FieldReadFunction<any>;
  dateLastUpdated?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  studyInformationComplete?: FieldPolicy<any> | FieldReadFunction<any>;
  paternalGrandFather?: FieldPolicy<any> | FieldReadFunction<any>;
  paternalGrandFatherOther?: FieldPolicy<any> | FieldReadFunction<any>;
  paternalGrandMother?: FieldPolicy<any> | FieldReadFunction<any>;
  paternalGrandMotherOther?: FieldPolicy<any> | FieldReadFunction<any>;
  maternalGrandFather?: FieldPolicy<any> | FieldReadFunction<any>;
  maternalGrandFatherOther?: FieldPolicy<any> | FieldReadFunction<any>;
  maternalGrandMother?: FieldPolicy<any> | FieldReadFunction<any>;
  maternalGrandMotherOther?: FieldPolicy<any> | FieldReadFunction<any>;
  reportedEthnicity?: FieldPolicy<any> | FieldReadFunction<any>;
  isTwin?: FieldPolicy<any> | FieldReadFunction<any>;
  isAdopted?: FieldPolicy<any> | FieldReadFunction<any>;
  ageAtDeath?: FieldPolicy<any> | FieldReadFunction<any>;
  apoemutationTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  amyloidBuildUp?: FieldPolicy<any> | FieldReadFunction<any>;
  neuriticPlaqueAverageScaledNeocortex?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  braakscore?: FieldPolicy<any> | FieldReadFunction<any>;
  cellLines?: FieldPolicy<any> | FieldReadFunction<any>;
  donorComments?: FieldPolicy<any> | FieldReadFunction<any>;
  donorMedicalConditions?: FieldPolicy<any> | FieldReadFunction<any>;
  projectDonors?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DonorCommentKeySpecifier = (
  | "donorCommentId"
  | "donorId"
  | "comment"
  | "dateTime"
  | "userId"
  | "donor"
  | "user"
  | DonorCommentKeySpecifier
)[];
export type DonorCommentFieldPolicy = {
  donorCommentId?: FieldPolicy<any> | FieldReadFunction<any>;
  donorId?: FieldPolicy<any> | FieldReadFunction<any>;
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  dateTime?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  donor?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DonorMedicalConditionKeySpecifier = (
  | "donorMedicalConditionId"
  | "donorId"
  | "medicalConditionId"
  | "ageOfOnset"
  | "comments"
  | "importedFromRedcap"
  | "donor"
  | "medicalCondition"
  | DonorMedicalConditionKeySpecifier
)[];
export type DonorMedicalConditionFieldPolicy = {
  donorMedicalConditionId?: FieldPolicy<any> | FieldReadFunction<any>;
  donorId?: FieldPolicy<any> | FieldReadFunction<any>;
  medicalConditionId?: FieldPolicy<any> | FieldReadFunction<any>;
  ageOfOnset?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  importedFromRedcap?: FieldPolicy<any> | FieldReadFunction<any>;
  donor?: FieldPolicy<any> | FieldReadFunction<any>;
  medicalCondition?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FeedGroupKeySpecifier = (
  | "feedGroupId"
  | "groupId"
  | "arraySystemAutomationMethodId"
  | "feedGroupName"
  | "isActive"
  | "numberOfPlates"
  | "startTimeScheduled"
  | "arraySystemAutomationMethod"
  | "group"
  | "personalTasks"
  | FeedGroupKeySpecifier
)[];
export type FeedGroupFieldPolicy = {
  feedGroupId?: FieldPolicy<any> | FieldReadFunction<any>;
  groupId?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  feedGroupName?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  numberOfPlates?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeScheduled?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  group?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTasks?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FolderStructureKeySpecifier = (
  | "folderId"
  | "folderParentId"
  | "folderName"
  | "groupId"
  | "isInsertable"
  | "folderParent"
  | "group"
  | "lookupTeamGanttId"
  | "collectionTasks"
  | "inverseFolderParent"
  | "runTemplates"
  | "runs"
  | FolderStructureKeySpecifier
)[];
export type FolderStructureFieldPolicy = {
  folderId?: FieldPolicy<any> | FieldReadFunction<any>;
  folderParentId?: FieldPolicy<any> | FieldReadFunction<any>;
  folderName?: FieldPolicy<any> | FieldReadFunction<any>;
  groupId?: FieldPolicy<any> | FieldReadFunction<any>;
  isInsertable?: FieldPolicy<any> | FieldReadFunction<any>;
  folderParent?: FieldPolicy<any> | FieldReadFunction<any>;
  group?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupTeamGanttId?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  inverseFolderParent?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplates?: FieldPolicy<any> | FieldReadFunction<any>;
  runs?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GithubProjectKeySpecifier = (
  | "id"
  | "githubId"
  | "name"
  | "repositoryId"
  | "lookupAssetTypes"
  | GithubProjectKeySpecifier
)[];
export type GithubProjectFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  githubId?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  repositoryId?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupAssetTypes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GroupLoginStateKeySpecifier = (
  | "id"
  | "labComputer"
  | "user"
  | GroupLoginStateKeySpecifier
)[];
export type GroupLoginStateFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  labComputer?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GroupedPlateMetadataKeySpecifier = (
  | "prefix"
  | "plates"
  | GroupedPlateMetadataKeySpecifier
)[];
export type GroupedPlateMetadataFieldPolicy = {
  prefix?: FieldPolicy<any> | FieldReadFunction<any>;
  plates?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IncomingSampleCollectionsConnectionKeySpecifier = (
  | "pageInfo"
  | "edges"
  | "nodes"
  | "totalCount"
  | IncomingSampleCollectionsConnectionKeySpecifier
)[];
export type IncomingSampleCollectionsConnectionFieldPolicy = {
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IncomingSampleCollectionsEdgeKeySpecifier = (
  | "cursor"
  | "node"
  | IncomingSampleCollectionsEdgeKeySpecifier
)[];
export type IncomingSampleCollectionsEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IncomingSampleTasksConnectionKeySpecifier = (
  | "pageInfo"
  | "edges"
  | "nodes"
  | "totalCount"
  | IncomingSampleTasksConnectionKeySpecifier
)[];
export type IncomingSampleTasksConnectionFieldPolicy = {
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IncomingSampleTasksEdgeKeySpecifier = (
  | "cursor"
  | "node"
  | IncomingSampleTasksEdgeKeySpecifier
)[];
export type IncomingSampleTasksEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LabComputerKeySpecifier = (
  | "id"
  | "name"
  | "slackChannelId"
  | "cytomats"
  | LabComputerKeySpecifier
)[];
export type LabComputerFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  slackChannelId?: FieldPolicy<any> | FieldReadFunction<any>;
  cytomats?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LogResponseKeySpecifier = ("success" | LogResponseKeySpecifier)[];
export type LogResponseFieldPolicy = {
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LoggedInGroupMemberKeySpecifier = (
  | "id"
  | "labComputer"
  | "user"
  | LoggedInGroupMemberKeySpecifier
)[];
export type LoggedInGroupMemberFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  labComputer?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupArrayMethodKeySpecifier = (
  | "methodId"
  | "methodName"
  | "methodTypeId"
  | "arrayMethodGroupId"
  | "automationMethodId"
  | "isActive"
  | "isManual"
  | "worklistCherryPickModeId"
  | "platesRequired"
  | "platesCreated"
  | "incrementHundred"
  | "useNewPrefix"
  | "intPlatesCreated"
  | "deadPlatesCreated"
  | "maxSourcePlatesAllowed"
  | "destArrayPlateCodeId"
  | "destinationPlateType"
  | "newPrefix"
  | "createsMystPlate"
  | "auxilliaryPlatesCreated"
  | "auxilliaryPlateType"
  | "auxArrayPlateCodeId"
  | "batchGenerate"
  | "picklistDriven"
  | "customDispenseVolumes"
  | "cherryPick"
  | "methodShorthand"
  | "optionToSkipSamples"
  | "maps1to1ForEachSrcPlate"
  | "promptForPartialMethod"
  | "destPlateSampleOverflow"
  | "productionProcessOrder"
  | "pairedMethodId"
  | "printSrcPlate"
  | "mappingGroupId"
  | "passageTemplateId"
  | "worklistSourcePlateTask"
  | "worklistCustomTask"
  | "worklistCustomTaskDetails"
  | "worklistCustomTaskPosition"
  | "worklistDestPlateTask"
  | "worklistAuxilliaryPlateTask"
  | "worklistFirstIntPlateTask"
  | "worklistSecondIntPlateTask"
  | "worklistCherryPickTask"
  | "worklistCherryPickColumn"
  | "worklistIncludesRowNumber"
  | "worklistRowNumberKeyword"
  | "durationInMinutes"
  | "documentationUrl"
  | "arrayMethodGroup"
  | "automationMethod"
  | "auxArrayPlateCode"
  | "destArrayPlateCode"
  | "destinationPlateTypeNavigation"
  | "methodType"
  | "pairedMethod"
  | "worklistCherryPickMode"
  | "arrayMethodLabwareTypes"
  | "arrayMethodPlates"
  | "collectionTasks"
  | "inversePairedMethod"
  | "lookupAssets"
  | "methodReservations"
  | "methodSourcePlateFilters"
  | "personalTasks"
  | "runTasks"
  | "runTemplateTasks"
  | "worklistToolTemplates"
  | "worklists"
  | LookupArrayMethodKeySpecifier
)[];
export type LookupArrayMethodFieldPolicy = {
  methodId?: FieldPolicy<any> | FieldReadFunction<any>;
  methodName?: FieldPolicy<any> | FieldReadFunction<any>;
  methodTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodGroupId?: FieldPolicy<any> | FieldReadFunction<any>;
  automationMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  isManual?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistCherryPickModeId?: FieldPolicy<any> | FieldReadFunction<any>;
  platesRequired?: FieldPolicy<any> | FieldReadFunction<any>;
  platesCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  incrementHundred?: FieldPolicy<any> | FieldReadFunction<any>;
  useNewPrefix?: FieldPolicy<any> | FieldReadFunction<any>;
  intPlatesCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  deadPlatesCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  maxSourcePlatesAllowed?: FieldPolicy<any> | FieldReadFunction<any>;
  destArrayPlateCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  destinationPlateType?: FieldPolicy<any> | FieldReadFunction<any>;
  newPrefix?: FieldPolicy<any> | FieldReadFunction<any>;
  createsMystPlate?: FieldPolicy<any> | FieldReadFunction<any>;
  auxilliaryPlatesCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  auxilliaryPlateType?: FieldPolicy<any> | FieldReadFunction<any>;
  auxArrayPlateCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  batchGenerate?: FieldPolicy<any> | FieldReadFunction<any>;
  picklistDriven?: FieldPolicy<any> | FieldReadFunction<any>;
  customDispenseVolumes?: FieldPolicy<any> | FieldReadFunction<any>;
  cherryPick?: FieldPolicy<any> | FieldReadFunction<any>;
  methodShorthand?: FieldPolicy<any> | FieldReadFunction<any>;
  optionToSkipSamples?: FieldPolicy<any> | FieldReadFunction<any>;
  maps1to1ForEachSrcPlate?: FieldPolicy<any> | FieldReadFunction<any>;
  promptForPartialMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  destPlateSampleOverflow?: FieldPolicy<any> | FieldReadFunction<any>;
  productionProcessOrder?: FieldPolicy<any> | FieldReadFunction<any>;
  pairedMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  printSrcPlate?: FieldPolicy<any> | FieldReadFunction<any>;
  mappingGroupId?: FieldPolicy<any> | FieldReadFunction<any>;
  passageTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistSourcePlateTask?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistCustomTask?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistCustomTaskDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistCustomTaskPosition?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistDestPlateTask?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistAuxilliaryPlateTask?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistFirstIntPlateTask?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistSecondIntPlateTask?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistCherryPickTask?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistCherryPickColumn?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistIncludesRowNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistRowNumberKeyword?: FieldPolicy<any> | FieldReadFunction<any>;
  durationInMinutes?: FieldPolicy<any> | FieldReadFunction<any>;
  documentationUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  automationMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  auxArrayPlateCode?: FieldPolicy<any> | FieldReadFunction<any>;
  destArrayPlateCode?: FieldPolicy<any> | FieldReadFunction<any>;
  destinationPlateTypeNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  methodType?: FieldPolicy<any> | FieldReadFunction<any>;
  pairedMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistCherryPickMode?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodLabwareTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodPlates?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  inversePairedMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupAssets?: FieldPolicy<any> | FieldReadFunction<any>;
  methodReservations?: FieldPolicy<any> | FieldReadFunction<any>;
  methodSourcePlateFilters?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistToolTemplates?: FieldPolicy<any> | FieldReadFunction<any>;
  worklists?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupArrayMethodGroupKeySpecifier = (
  | "arrayMethodGroupId"
  | "arrayMethodGroup"
  | "arrayMethodTypeId"
  | "arrayMethodType"
  | "lookupArrayMethods"
  | LookupArrayMethodGroupKeySpecifier
)[];
export type LookupArrayMethodGroupFieldPolicy = {
  arrayMethodGroupId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodType?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupArrayMethods?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupArrayMethodPlateRoleKeySpecifier = (
  | "arrayMethodPlateRoleId"
  | "arrayMethodPlateRole"
  | "methodOrder"
  | "defaultArrayPlateCodeId"
  | "defaultWorklistTask"
  | "validForIntSourcePlateCode"
  | "validForIntDestPlateCode"
  | "defaultWorklistColumnId"
  | "isDestination"
  | "defaultArrayPlateCode"
  | "defaultWorklistColumn"
  | "arrayMethodLabwareTypes"
  | "arrayMethodPlateArrayMethodPlateRoles"
  | "arrayMethodPlateSrcArrayMethodPlateRoles"
  | "arraySystemAutomationMethodLabwareTypes"
  | "methodReservationsPlateBarcodes"
  | LookupArrayMethodPlateRoleKeySpecifier
)[];
export type LookupArrayMethodPlateRoleFieldPolicy = {
  arrayMethodPlateRoleId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodPlateRole?: FieldPolicy<any> | FieldReadFunction<any>;
  methodOrder?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultArrayPlateCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultWorklistTask?: FieldPolicy<any> | FieldReadFunction<any>;
  validForIntSourcePlateCode?: FieldPolicy<any> | FieldReadFunction<any>;
  validForIntDestPlateCode?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultWorklistColumnId?: FieldPolicy<any> | FieldReadFunction<any>;
  isDestination?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultArrayPlateCode?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultWorklistColumn?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodLabwareTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodPlateArrayMethodPlateRoles?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  arrayMethodPlateSrcArrayMethodPlateRoles?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  arraySystemAutomationMethodLabwareTypes?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  methodReservationsPlateBarcodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupArrayMethodTypeKeySpecifier = (
  | "methodTypeId"
  | "methodType"
  | "runSpecific"
  | "inactivatesSourcePlates"
  | "excludeSourcePlatesReservedForInactivation"
  | "lookupArrayMethodGroups"
  | "lookupArrayMethods"
  | LookupArrayMethodTypeKeySpecifier
)[];
export type LookupArrayMethodTypeFieldPolicy = {
  methodTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  methodType?: FieldPolicy<any> | FieldReadFunction<any>;
  runSpecific?: FieldPolicy<any> | FieldReadFunction<any>;
  inactivatesSourcePlates?: FieldPolicy<any> | FieldReadFunction<any>;
  excludeSourcePlatesReservedForInactivation?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  lookupArrayMethodGroups?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupArrayMethods?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupArrayPlateCodeKeySpecifier = (
  | "arrayPlateCodeId"
  | "arrayPlateCode"
  | "isActive"
  | "arrayMethodPlates"
  | "lookupArrayMethodAuxArrayPlateCodes"
  | "lookupArrayMethodDestArrayPlateCodes"
  | "lookupArrayMethodPlateRoles"
  | "lookupArrayPlateTypeStrings"
  | "lookupLabwareTypes"
  | LookupArrayPlateCodeKeySpecifier
)[];
export type LookupArrayPlateCodeFieldPolicy = {
  arrayPlateCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayPlateCode?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodPlates?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupArrayMethodAuxArrayPlateCodes?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  lookupArrayMethodDestArrayPlateCodes?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  lookupArrayMethodPlateRoles?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupArrayPlateTypeStrings?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupLabwareTypes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupArrayPlateTypeStringKeySpecifier = (
  | "plateTypeId"
  | "arrayPlateType"
  | "productionRunTypeId"
  | "arrayPlateCodeId"
  | "defaultLabwareTypeId"
  | "defaultCeligoExperimentSettingsId"
  | "plateLayoutTypeId"
  | "arrayPlateRoleId"
  | "newSampleTypeId"
  | "lotNumberTypeId"
  | "lotNumberFormatId"
  | "plateDescription"
  | "passageByDayLimit"
  | "dataAbsenceAutoInactivationLimit"
  | "isMatrixRack"
  | "isActive"
  | "runTemplateId"
  | "arrayPlateCode"
  | "defaultCeligoExperimentSettings"
  | "defaultLabwareType"
  | "lotNumberFormat"
  | "lotNumberType"
  | "newSampleType"
  | "plateLayoutType"
  | "productionRunType"
  | "runTemplate"
  | "lookupArrayMethods"
  | "platesCreateds"
  | "productionRunTypeFinalPlateTypes"
  | "productionRunTypeSourcePlateTypes"
  | LookupArrayPlateTypeStringKeySpecifier
)[];
export type LookupArrayPlateTypeStringFieldPolicy = {
  plateTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayPlateType?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRunTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayPlateCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultLabwareTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultCeligoExperimentSettingsId?: FieldPolicy<any> | FieldReadFunction<any>;
  plateLayoutTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayPlateRoleId?: FieldPolicy<any> | FieldReadFunction<any>;
  newSampleTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumberTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumberFormatId?: FieldPolicy<any> | FieldReadFunction<any>;
  plateDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  passageByDayLimit?: FieldPolicy<any> | FieldReadFunction<any>;
  dataAbsenceAutoInactivationLimit?: FieldPolicy<any> | FieldReadFunction<any>;
  isMatrixRack?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayPlateCode?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultCeligoExperimentSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultLabwareType?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumberFormat?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumberType?: FieldPolicy<any> | FieldReadFunction<any>;
  newSampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  plateLayoutType?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRunType?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupArrayMethods?: FieldPolicy<any> | FieldReadFunction<any>;
  platesCreateds?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRunTypeFinalPlateTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRunTypeSourcePlateTypes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupArraySystemKeySpecifier = (
  | "systemId"
  | "systemName"
  | "serialNumber"
  | "systemNumber"
  | "slackChannelId"
  | "systemShorthand"
  | "clusterId"
  | "systemTypeId"
  | "cluster"
  | "systemType"
  | "arraySystemAutomationMethods"
  | "collectionTasks"
  | "methodReservations"
  | "personalTasks"
  | "runTasks"
  | "runTemplateTasks"
  | "worklists"
  | LookupArraySystemKeySpecifier
)[];
export type LookupArraySystemFieldPolicy = {
  systemId?: FieldPolicy<any> | FieldReadFunction<any>;
  systemName?: FieldPolicy<any> | FieldReadFunction<any>;
  serialNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  systemNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  slackChannelId?: FieldPolicy<any> | FieldReadFunction<any>;
  systemShorthand?: FieldPolicy<any> | FieldReadFunction<any>;
  clusterId?: FieldPolicy<any> | FieldReadFunction<any>;
  systemTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  cluster?: FieldPolicy<any> | FieldReadFunction<any>;
  systemType?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethods?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  methodReservations?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  worklists?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupAssetKeySpecifier = (
  | "assetId"
  | "asset"
  | "assetTypeId"
  | "serialNumber"
  | "manufacturer"
  | "vendor"
  | "contractId"
  | "designName"
  | "documentationUrl"
  | "arrayMethodId"
  | "arraySystemAutomationMethodId"
  | "isActive"
  | "arrayMethod"
  | "arraySystemAutomationMethod"
  | "assetType"
  | "tickets"
  | LookupAssetKeySpecifier
)[];
export type LookupAssetFieldPolicy = {
  assetId?: FieldPolicy<any> | FieldReadFunction<any>;
  asset?: FieldPolicy<any> | FieldReadFunction<any>;
  assetTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  serialNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  manufacturer?: FieldPolicy<any> | FieldReadFunction<any>;
  vendor?: FieldPolicy<any> | FieldReadFunction<any>;
  contractId?: FieldPolicy<any> | FieldReadFunction<any>;
  designName?: FieldPolicy<any> | FieldReadFunction<any>;
  documentationUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  assetType?: FieldPolicy<any> | FieldReadFunction<any>;
  tickets?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupAssetTypeKeySpecifier = (
  | "assetTypeId"
  | "assetType"
  | "userGroupId"
  | "githubProjectId"
  | "githubProject"
  | "userGroup"
  | "lookupAssets"
  | LookupAssetTypeKeySpecifier
)[];
export type LookupAssetTypeFieldPolicy = {
  assetTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  assetType?: FieldPolicy<any> | FieldReadFunction<any>;
  userGroupId?: FieldPolicy<any> | FieldReadFunction<any>;
  githubProjectId?: FieldPolicy<any> | FieldReadFunction<any>;
  githubProject?: FieldPolicy<any> | FieldReadFunction<any>;
  userGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupAssets?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupAutomationMethodKeySpecifier = (
  | "automationMethodId"
  | "automationMethod"
  | "arraySystemAutomationMethods"
  | "lookupArrayMethods"
  | "manualTaskTypes"
  | LookupAutomationMethodKeySpecifier
)[];
export type LookupAutomationMethodFieldPolicy = {
  automationMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  automationMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethods?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupArrayMethods?: FieldPolicy<any> | FieldReadFunction<any>;
  manualTaskTypes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupAutomationMethodProcessRunStatusKeySpecifier = (
  | "automationMethodProcessRunStatusId"
  | "processRunStatus"
  | "arraySystemAutomationMethodProcessRuns"
  | LookupAutomationMethodProcessRunStatusKeySpecifier
)[];
export type LookupAutomationMethodProcessRunStatusFieldPolicy = {
  automationMethodProcessRunStatusId?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  processRunStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethodProcessRuns?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
};
export type LookupAutomationMethodProcessRunTypeKeySpecifier = (
  | "automationMethodProcessRunTypeId"
  | "processRunType"
  | "arraySystemAutomationMethodProcesses"
  | LookupAutomationMethodProcessRunTypeKeySpecifier
)[];
export type LookupAutomationMethodProcessRunTypeFieldPolicy = {
  automationMethodProcessRunTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  processRunType?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethodProcesses?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
};
export type LookupContainerMarkingTypeKeySpecifier = (
  | "containerMarkingTypeId"
  | "conditionGroupId"
  | "conditionType"
  | "conditionAbbreviation"
  | "allowedPlatePatterns"
  | "plateMapBackColor"
  | "backColorPriority"
  | "autoMarkQcfail"
  | "inactivationReasonId"
  | "limitPropagationToCurrentRun"
  | "conditionOld"
  | "qcpassFailFlagId"
  | "inactivationReason"
  | "containerMarkings"
  | LookupContainerMarkingTypeKeySpecifier
)[];
export type LookupContainerMarkingTypeFieldPolicy = {
  containerMarkingTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  conditionGroupId?: FieldPolicy<any> | FieldReadFunction<any>;
  conditionType?: FieldPolicy<any> | FieldReadFunction<any>;
  conditionAbbreviation?: FieldPolicy<any> | FieldReadFunction<any>;
  allowedPlatePatterns?: FieldPolicy<any> | FieldReadFunction<any>;
  plateMapBackColor?: FieldPolicy<any> | FieldReadFunction<any>;
  backColorPriority?: FieldPolicy<any> | FieldReadFunction<any>;
  autoMarkQcfail?: FieldPolicy<any> | FieldReadFunction<any>;
  inactivationReasonId?: FieldPolicy<any> | FieldReadFunction<any>;
  limitPropagationToCurrentRun?: FieldPolicy<any> | FieldReadFunction<any>;
  conditionOld?: FieldPolicy<any> | FieldReadFunction<any>;
  qcpassFailFlagId?: FieldPolicy<any> | FieldReadFunction<any>;
  inactivationReason?: FieldPolicy<any> | FieldReadFunction<any>;
  containerMarkings?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupExperimentSettingKeySpecifier = (
  | "experimentSettingId"
  | "experimentSetting"
  | "experimentSettingScanTypeId"
  | "plateLayoutTypeId"
  | "focusWellRowPos"
  | "focusWellColPos"
  | "plateLayoutType"
  | "lookupArrayPlateTypeStrings"
  | "lookupLabwareTypes"
  | "platesCreateds"
  | LookupExperimentSettingKeySpecifier
)[];
export type LookupExperimentSettingFieldPolicy = {
  experimentSettingId?: FieldPolicy<any> | FieldReadFunction<any>;
  experimentSetting?: FieldPolicy<any> | FieldReadFunction<any>;
  experimentSettingScanTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  plateLayoutTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  focusWellRowPos?: FieldPolicy<any> | FieldReadFunction<any>;
  focusWellColPos?: FieldPolicy<any> | FieldReadFunction<any>;
  plateLayoutType?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupArrayPlateTypeStrings?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupLabwareTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  platesCreateds?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupInactivationReasonKeySpecifier = (
  | "inactivationReasonId"
  | "reason"
  | "specificReason"
  | "activeForRun"
  | "activeForPlate"
  | "activeForContainer"
  | "activeForBloodProcessing"
  | "containerMarkingTypeId"
  | "containers"
  | "lookupContainerMarkingTypes"
  | LookupInactivationReasonKeySpecifier
)[];
export type LookupInactivationReasonFieldPolicy = {
  inactivationReasonId?: FieldPolicy<any> | FieldReadFunction<any>;
  reason?: FieldPolicy<any> | FieldReadFunction<any>;
  specificReason?: FieldPolicy<any> | FieldReadFunction<any>;
  activeForRun?: FieldPolicy<any> | FieldReadFunction<any>;
  activeForPlate?: FieldPolicy<any> | FieldReadFunction<any>;
  activeForContainer?: FieldPolicy<any> | FieldReadFunction<any>;
  activeForBloodProcessing?: FieldPolicy<any> | FieldReadFunction<any>;
  containerMarkingTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  containers?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupContainerMarkingTypes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupLabwareTypeKeySpecifier = (
  | "labwareTypeId"
  | "labwareTypeName"
  | "manufacturer"
  | "description"
  | "plateLayoutTypeId"
  | "isActive"
  | "labwareTypeCode"
  | "celigoScanName"
  | "defaultCeligoExperimentSettingId"
  | "footprint"
  | "platePitch"
  | "defaultLabwareVolume"
  | "minLabwareVolume"
  | "maxLabwareVolume"
  | "defaultAspirateVolume"
  | "minAspirateVolume"
  | "maxAspirateVolume"
  | "defaultDispenseVolume"
  | "minDispenseVolume"
  | "maxDispenseVolume"
  | "maxStampResuspensionVolume"
  | "defaultArrayPlateCode"
  | "defaultArrayPlateCodeId"
  | "labwareTypeSummary"
  | "defaultArrayPlateCodeNavigation"
  | "defaultCeligoExperimentSetting"
  | "plateLayoutType"
  | "arrayMethodLabwareTypes"
  | "arraySystemAutomationMethodLabwareTypes"
  | "lookupArrayPlateTypeStrings"
  | "methodReservationsPlateBarcodes"
  | "platesCreateds"
  | "runTaskDetails"
  | "runTemplateTaskDetails"
  | LookupLabwareTypeKeySpecifier
)[];
export type LookupLabwareTypeFieldPolicy = {
  labwareTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  labwareTypeName?: FieldPolicy<any> | FieldReadFunction<any>;
  manufacturer?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  plateLayoutTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  labwareTypeCode?: FieldPolicy<any> | FieldReadFunction<any>;
  celigoScanName?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultCeligoExperimentSettingId?: FieldPolicy<any> | FieldReadFunction<any>;
  footprint?: FieldPolicy<any> | FieldReadFunction<any>;
  platePitch?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultLabwareVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  minLabwareVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  maxLabwareVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultAspirateVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  minAspirateVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  maxAspirateVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultDispenseVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  minDispenseVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDispenseVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  maxStampResuspensionVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultArrayPlateCode?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultArrayPlateCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  labwareTypeSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultArrayPlateCodeNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultCeligoExperimentSetting?: FieldPolicy<any> | FieldReadFunction<any>;
  plateLayoutType?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodLabwareTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethodLabwareTypes?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  lookupArrayPlateTypeStrings?: FieldPolicy<any> | FieldReadFunction<any>;
  methodReservationsPlateBarcodes?: FieldPolicy<any> | FieldReadFunction<any>;
  platesCreateds?: FieldPolicy<any> | FieldReadFunction<any>;
  runTaskDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTaskDetails?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupLotNumberTypeKeySpecifier = (
  | "lotNumberTypeId"
  | "lotNumberTypeName"
  | "description"
  | "lotNumberFormat"
  | "lookupArrayPlateTypeStrings"
  | "lotNumbers"
  | "productionRunTypes"
  | LookupLotNumberTypeKeySpecifier
)[];
export type LookupLotNumberTypeFieldPolicy = {
  lotNumberTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumberTypeName?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumberFormat?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupArrayPlateTypeStrings?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumbers?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRunTypes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupMedicalConditionKeySpecifier = (
  | "medicalConditionId"
  | "medicalCondition"
  | "definition"
  | "medicalConditionTypeId"
  | "healthyControlTypeId"
  | "doid"
  | "importedFromRedcap"
  | "donorMedicalConditions"
  | LookupMedicalConditionKeySpecifier
)[];
export type LookupMedicalConditionFieldPolicy = {
  medicalConditionId?: FieldPolicy<any> | FieldReadFunction<any>;
  medicalCondition?: FieldPolicy<any> | FieldReadFunction<any>;
  definition?: FieldPolicy<any> | FieldReadFunction<any>;
  medicalConditionTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  healthyControlTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  doid?: FieldPolicy<any> | FieldReadFunction<any>;
  importedFromRedcap?: FieldPolicy<any> | FieldReadFunction<any>;
  donorMedicalConditions?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupRunActivityTypeKeySpecifier = (
  | "runActivityTypeId"
  | "runActivityType"
  | "lookupTemplateTasks"
  | "runTasks"
  | LookupRunActivityTypeKeySpecifier
)[];
export type LookupRunActivityTypeFieldPolicy = {
  runActivityTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  runActivityType?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupTemplateTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTasks?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupSampleCollectionTypeKeySpecifier = (
  | "sampleCollectionTypeId"
  | "sampleCollectionType"
  | "redcapCollectionTypeCheckedVariable"
  | "sampleTypeId"
  | "sampleType"
  | LookupSampleCollectionTypeKeySpecifier
)[];
export type LookupSampleCollectionTypeFieldPolicy = {
  sampleCollectionTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleCollectionType?: FieldPolicy<any> | FieldReadFunction<any>;
  redcapCollectionTypeCheckedVariable?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  sampleTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupSampleTypeKeySpecifier = (
  | "sampleTypeId"
  | "sampleTypeString"
  | "sampleTypeAbbreviation"
  | "isSourceSampleType"
  | "isDerivedSampleType"
  | "isStemCellSampleType"
  | "isCrisprsampleType"
  | "isMonoclonalSampleType"
  | "reprogrammingTier"
  | "lowStockAlertLimit"
  | "monoclonalSampleTypeId"
  | "polyclonalSampleTypeId"
  | "appendSampleTypeCode"
  | "appendRun"
  | "monoclonalSampleType"
  | "polyclonalSampleType"
  | "cellLines"
  | "collectionSampleTypes"
  | "inverseMonoclonalSampleType"
  | "inversePolyclonalSampleType"
  | "lookupArrayPlateTypeStrings"
  | "lookupSampleCollectionTypes"
  | "productionRunTypes"
  | "sampleTypeCollectionTasks"
  | "samples"
  | LookupSampleTypeKeySpecifier
)[];
export type LookupSampleTypeFieldPolicy = {
  sampleTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleTypeString?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleTypeAbbreviation?: FieldPolicy<any> | FieldReadFunction<any>;
  isSourceSampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  isDerivedSampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  isStemCellSampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  isCrisprsampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  isMonoclonalSampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  reprogrammingTier?: FieldPolicy<any> | FieldReadFunction<any>;
  lowStockAlertLimit?: FieldPolicy<any> | FieldReadFunction<any>;
  monoclonalSampleTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  polyclonalSampleTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  appendSampleTypeCode?: FieldPolicy<any> | FieldReadFunction<any>;
  appendRun?: FieldPolicy<any> | FieldReadFunction<any>;
  monoclonalSampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  polyclonalSampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  cellLines?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionSampleTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  inverseMonoclonalSampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  inversePolyclonalSampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupArrayPlateTypeStrings?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupSampleCollectionTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRunTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleTypeCollectionTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  samples?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupTeamGanttIdKeySpecifier = (
  | "nodeType"
  | "id"
  | "folderId"
  | "taskId"
  | "teamGanttId"
  | "dateCreated"
  | "runId"
  | "folder"
  | "run"
  | "task"
  | LookupTeamGanttIdKeySpecifier
)[];
export type LookupTeamGanttIdFieldPolicy = {
  nodeType?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  folderId?: FieldPolicy<any> | FieldReadFunction<any>;
  taskId?: FieldPolicy<any> | FieldReadFunction<any>;
  teamGanttId?: FieldPolicy<any> | FieldReadFunction<any>;
  dateCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  runId?: FieldPolicy<any> | FieldReadFunction<any>;
  folder?: FieldPolicy<any> | FieldReadFunction<any>;
  run?: FieldPolicy<any> | FieldReadFunction<any>;
  task?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupTemplateTaskKeySpecifier = (
  | "templateTaskId"
  | "methodId"
  | "methodName"
  | "sourcePlateFilter"
  | "runActivityTypeId"
  | "averageRunTime"
  | "runActivityType"
  | LookupTemplateTaskKeySpecifier
)[];
export type LookupTemplateTaskFieldPolicy = {
  templateTaskId?: FieldPolicy<any> | FieldReadFunction<any>;
  methodId?: FieldPolicy<any> | FieldReadFunction<any>;
  methodName?: FieldPolicy<any> | FieldReadFunction<any>;
  sourcePlateFilter?: FieldPolicy<any> | FieldReadFunction<any>;
  runActivityTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  averageRunTime?: FieldPolicy<any> | FieldReadFunction<any>;
  runActivityType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LookupTicketTypeKeySpecifier = (
  | "ticketTypeId"
  | "ticketType"
  | "userGroupId"
  | "userGroup"
  | "tickets"
  | LookupTicketTypeKeySpecifier
)[];
export type LookupTicketTypeFieldPolicy = {
  ticketTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketType?: FieldPolicy<any> | FieldReadFunction<any>;
  userGroupId?: FieldPolicy<any> | FieldReadFunction<any>;
  userGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  tickets?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LotNumberKeySpecifier = (
  | "lotNumberId"
  | "lotNumber1"
  | "lotNumberTypeId"
  | "fibroblastLotNumber"
  | "spitLotNumber"
  | "bloodProcessingLotNumber"
  | "reprogrammingLotNumber"
  | "consolidationLotNumber"
  | "monoclonalizationLotNumber"
  | "expansionLotNumber"
  | "geneEditingLotNumber"
  | "dnaextractionLotNumber"
  | "externalLotNumber"
  | "otherLotNumber"
  | "preFibroblastLotNumber"
  | "referenceLotNumber"
  | "rpelotNumber"
  | "lotNumberType"
  | "containers"
  | "matricesTube"
  | LotNumberKeySpecifier
)[];
export type LotNumberFieldPolicy = {
  lotNumberId?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumber1?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumberTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  fibroblastLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  spitLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  bloodProcessingLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  reprogrammingLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  consolidationLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  monoclonalizationLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  expansionLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  geneEditingLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  dnaextractionLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  externalLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  otherLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  preFibroblastLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  referenceLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  rpelotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumberType?: FieldPolicy<any> | FieldReadFunction<any>;
  containers?: FieldPolicy<any> | FieldReadFunction<any>;
  matricesTube?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LotNumberFormatKeySpecifier = (
  | "lotNumberFormatId"
  | "lotNumberFormat1"
  | "includeRow"
  | "includeColumn"
  | "includePlateSeries"
  | "alphabeticalRow"
  | "example"
  | "lookupArrayPlateTypeStrings"
  | "productionRunTypes"
  | LotNumberFormatKeySpecifier
)[];
export type LotNumberFormatFieldPolicy = {
  lotNumberFormatId?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumberFormat1?: FieldPolicy<any> | FieldReadFunction<any>;
  includeRow?: FieldPolicy<any> | FieldReadFunction<any>;
  includeColumn?: FieldPolicy<any> | FieldReadFunction<any>;
  includePlateSeries?: FieldPolicy<any> | FieldReadFunction<any>;
  alphabeticalRow?: FieldPolicy<any> | FieldReadFunction<any>;
  example?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupArrayPlateTypeStrings?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRunTypes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LotNumbersConnectionKeySpecifier = (
  | "pageInfo"
  | "edges"
  | "nodes"
  | LotNumbersConnectionKeySpecifier
)[];
export type LotNumbersConnectionFieldPolicy = {
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LotNumbersEdgeKeySpecifier = (
  | "cursor"
  | "node"
  | LotNumbersEdgeKeySpecifier
)[];
export type LotNumbersEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ManagePersonalTaskKeySpecifier = (
  | "taskId"
  | "taskName"
  | "assignedUsers"
  | "assignedGroups"
  | "systemAssignedTo"
  | "startTimeScheduled"
  | "endTimeScheduled"
  | "priority"
  | "status"
  | "notes"
  | "taskOwnerId"
  | ManagePersonalTaskKeySpecifier
)[];
export type ManagePersonalTaskFieldPolicy = {
  taskId?: FieldPolicy<any> | FieldReadFunction<any>;
  taskName?: FieldPolicy<any> | FieldReadFunction<any>;
  assignedUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  assignedGroups?: FieldPolicy<any> | FieldReadFunction<any>;
  systemAssignedTo?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeScheduled?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeScheduled?: FieldPolicy<any> | FieldReadFunction<any>;
  priority?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  taskOwnerId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ManualTaskTypeKeySpecifier = (
  | "id"
  | "name"
  | "documentationUrl"
  | "durationInMinutes"
  | "sourcePlateFilter"
  | "automationMethodId"
  | "automationMethod"
  | "personalTasks"
  | "runTasks"
  | "runTemplateTasks"
  | ManualTaskTypeKeySpecifier
)[];
export type ManualTaskTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  documentationUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  durationInMinutes?: FieldPolicy<any> | FieldReadFunction<any>;
  sourcePlateFilter?: FieldPolicy<any> | FieldReadFunction<any>;
  automationMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  automationMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTasks?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MatrixTubeKeySpecifier = (
  | "matrixTubeId"
  | "sampleId"
  | "tubeBarcode"
  | "stockTypeId"
  | "groupingId"
  | "comment"
  | "isActive"
  | "dateCreated"
  | "createdBy"
  | "dateIdentified"
  | "identifiedBy"
  | "offSite"
  | "shipped"
  | "reserved"
  | "lotNumberId"
  | "preFibroblastLotNumber"
  | "fibroblastLotNumber"
  | "spitLotNumber"
  | "bloodProcessingLotNumber"
  | "reprogrammingLotNumber"
  | "consolidationLotNumber"
  | "monoclonalizationLotNumber"
  | "geneEditingLotNumber"
  | "expansionLotNumber"
  | "dnaextractionLotNumber"
  | "externalLotNumber"
  | "otherLotNumber"
  | "srcContainerId"
  | "destContainerId"
  | "latestTransactionDate"
  | "cellCount"
  | "passageNumber"
  | "lotNumberIdold"
  | "labeledAsAlias"
  | "passageNumberOld"
  | "destContainer"
  | "lotNumber"
  | "sample"
  | "srcContainer"
  | MatrixTubeKeySpecifier
)[];
export type MatrixTubeFieldPolicy = {
  matrixTubeId?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleId?: FieldPolicy<any> | FieldReadFunction<any>;
  tubeBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  stockTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  groupingId?: FieldPolicy<any> | FieldReadFunction<any>;
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  dateCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  dateIdentified?: FieldPolicy<any> | FieldReadFunction<any>;
  identifiedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  offSite?: FieldPolicy<any> | FieldReadFunction<any>;
  shipped?: FieldPolicy<any> | FieldReadFunction<any>;
  reserved?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumberId?: FieldPolicy<any> | FieldReadFunction<any>;
  preFibroblastLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  fibroblastLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  spitLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  bloodProcessingLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  reprogrammingLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  consolidationLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  monoclonalizationLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  geneEditingLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  expansionLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  dnaextractionLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  externalLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  otherLotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  srcContainerId?: FieldPolicy<any> | FieldReadFunction<any>;
  destContainerId?: FieldPolicy<any> | FieldReadFunction<any>;
  latestTransactionDate?: FieldPolicy<any> | FieldReadFunction<any>;
  cellCount?: FieldPolicy<any> | FieldReadFunction<any>;
  passageNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumberIdold?: FieldPolicy<any> | FieldReadFunction<any>;
  labeledAsAlias?: FieldPolicy<any> | FieldReadFunction<any>;
  passageNumberOld?: FieldPolicy<any> | FieldReadFunction<any>;
  destContainer?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  sample?: FieldPolicy<any> | FieldReadFunction<any>;
  srcContainer?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MethodReservationKeySpecifier = (
  | "methodReservationId"
  | "arraySystemAutomationMethodId"
  | "userId"
  | "methodId"
  | "systemId"
  | "sysNum"
  | "date"
  | "picklistId"
  | "isCompleted"
  | "methodVariationId"
  | "dateCreated"
  | "generatedFromAppSuite"
  | "runActivityId"
  | "dateCompleted"
  | "completedByUserId"
  | "personalTaskId"
  | "cherryPickIncludeInactiveWells"
  | "completedByUser"
  | "method"
  | "personalTask"
  | "runActivity"
  | "system"
  | "user"
  | "worklist"
  | "methodReservationsPlateBarcodes"
  | MethodReservationKeySpecifier
)[];
export type MethodReservationFieldPolicy = {
  methodReservationId?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  methodId?: FieldPolicy<any> | FieldReadFunction<any>;
  systemId?: FieldPolicy<any> | FieldReadFunction<any>;
  sysNum?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  picklistId?: FieldPolicy<any> | FieldReadFunction<any>;
  isCompleted?: FieldPolicy<any> | FieldReadFunction<any>;
  methodVariationId?: FieldPolicy<any> | FieldReadFunction<any>;
  dateCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  generatedFromAppSuite?: FieldPolicy<any> | FieldReadFunction<any>;
  runActivityId?: FieldPolicy<any> | FieldReadFunction<any>;
  dateCompleted?: FieldPolicy<any> | FieldReadFunction<any>;
  completedByUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTaskId?: FieldPolicy<any> | FieldReadFunction<any>;
  cherryPickIncludeInactiveWells?: FieldPolicy<any> | FieldReadFunction<any>;
  completedByUser?: FieldPolicy<any> | FieldReadFunction<any>;
  method?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTask?: FieldPolicy<any> | FieldReadFunction<any>;
  runActivity?: FieldPolicy<any> | FieldReadFunction<any>;
  system?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  worklist?: FieldPolicy<any> | FieldReadFunction<any>;
  methodReservationsPlateBarcodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MethodReservationPlateBarcodePreviewKeySpecifier = (
  | "intermediatePlateBarcode1"
  | "intermediatePlateBarcode2"
  | "deadPlateBarcode"
  | "destinationPlateBarcode"
  | "iD"
  | "plateBarcode"
  | "plateType"
  | MethodReservationPlateBarcodePreviewKeySpecifier
)[];
export type MethodReservationPlateBarcodePreviewFieldPolicy = {
  intermediatePlateBarcode1?: FieldPolicy<any> | FieldReadFunction<any>;
  intermediatePlateBarcode2?: FieldPolicy<any> | FieldReadFunction<any>;
  deadPlateBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  destinationPlateBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  iD?: FieldPolicy<any> | FieldReadFunction<any>;
  plateBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  plateType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MethodReservationsPlateBarcodeKeySpecifier = (
  | "plateReservationId"
  | "methodReservationId"
  | "plateId"
  | "plateBarcode"
  | "plateBarcodeAlias"
  | "arrayMethodPlateRoleId"
  | "labwareTypeId"
  | "aspirateVol"
  | "dispenseVol"
  | "labwareType"
  | "plateType"
  | "details"
  | "isCompleted"
  | "arrayMethodPlateRole"
  | "labwareTypeNavigation"
  | "methodReservation"
  | MethodReservationsPlateBarcodeKeySpecifier
)[];
export type MethodReservationsPlateBarcodeFieldPolicy = {
  plateReservationId?: FieldPolicy<any> | FieldReadFunction<any>;
  methodReservationId?: FieldPolicy<any> | FieldReadFunction<any>;
  plateId?: FieldPolicy<any> | FieldReadFunction<any>;
  plateBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  plateBarcodeAlias?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodPlateRoleId?: FieldPolicy<any> | FieldReadFunction<any>;
  labwareTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  aspirateVol?: FieldPolicy<any> | FieldReadFunction<any>;
  dispenseVol?: FieldPolicy<any> | FieldReadFunction<any>;
  labwareType?: FieldPolicy<any> | FieldReadFunction<any>;
  plateType?: FieldPolicy<any> | FieldReadFunction<any>;
  details?: FieldPolicy<any> | FieldReadFunction<any>;
  isCompleted?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodPlateRole?: FieldPolicy<any> | FieldReadFunction<any>;
  labwareTypeNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  methodReservation?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MethodSourcePlateFilterKeySpecifier = (
  | "methodSourcePlateFilterId"
  | "methodId"
  | "arrayMethodVariationId"
  | "sourcePlateFilter"
  | "maxDaysSincePlated"
  | "maxDaysSinceScanned"
  | "activePlatesOnly"
  | "method"
  | MethodSourcePlateFilterKeySpecifier
)[];
export type MethodSourcePlateFilterFieldPolicy = {
  methodSourcePlateFilterId?: FieldPolicy<any> | FieldReadFunction<any>;
  methodId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodVariationId?: FieldPolicy<any> | FieldReadFunction<any>;
  sourcePlateFilter?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDaysSincePlated?: FieldPolicy<any> | FieldReadFunction<any>;
  maxDaysSinceScanned?: FieldPolicy<any> | FieldReadFunction<any>;
  activePlatesOnly?: FieldPolicy<any> | FieldReadFunction<any>;
  method?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutationKeySpecifier = (
  | "reserveFeedWorklist"
  | "deleteTemplateTask"
  | "assignTask"
  | "createTaskAttachments"
  | "deleteTaskAttachment"
  | "deleteTasks"
  | "rescheduleTasks"
  | "addPersonalTask"
  | "addRecurringPersonalTask"
  | "startPersonalTaskTemplate"
  | "createPersonalTaskTemplate"
  | "addPersonalTaskTemplateTask"
  | "addRunTemplateTasks"
  | "updatePersonalTaskTemplateTask"
  | "updateRunTemplateTasks"
  | "overwriteRecurringTasks"
  | "updatePersonalTasks"
  | "deleteRecurringPersonalTask"
  | "updateRecurringTask"
  | "cancelRemainingReccuringTasks"
  | "updateDashboardTasks"
  | "updateCollectionTasks"
  | "createCollectionTask"
  | "addRunTasks"
  | "updateRunTasks"
  | "saveFeedGroups"
  | "addPersonalFeedTasks"
  | "createTicket"
  | "mergeTicket"
  | "updateTickets"
  | "acceptTicket"
  | "addTicketUpdate"
  | "addTicketAttachment"
  | "deleteTicketAttachment"
  | "createRunTemplate"
  | "addChildRunTemplate"
  | "addRunTemplateStages"
  | "updateRunTemplate"
  | "updateRunTemplateStages"
  | "updateRunTemplateTaskSchedule"
  | "deleteTemplate"
  | "removeTemplateStage"
  | "deleteRunTemplateTreeNode"
  | "intermediateDeadAndDestPlateBarcodes"
  | "setLabwareType"
  | "plateBarcodePreview"
  | "reserveMethod"
  | "deleteExistingMethodReservation"
  | "saveWorklistToolTemplate"
  | "overwriteExistingWorklistTemplate"
  | "deleteWorklistToolTemplate"
  | "updateUsers"
  | "groupMemberLogin"
  | "saveSettings"
  | "deleteRunActivityMethodReservation"
  | "deleteTaskMethodReservation"
  | "reserveCeligoRunTaskMethod"
  | "reserveCeligoPersonalTaskMethod"
  | "log"
  | "updateIncomingSampleCollections"
  | "createIncomingSampleCollection"
  | "addIdfUpload"
  | "validateIdfUpload"
  | "deleteIncomingSampleCollection"
  | "updateIncomingSampleType"
  | "addIncomingSampleType"
  | "deleteCollectionSampleType"
  | "addAsset"
  | "updateMethods"
  | "updateManualTasks"
  | "updateRunTaskTypeDocumentation"
  | "createRunFolder"
  | "deleteRunFolder"
  | "createRun"
  | "updateRun"
  | "deleteRun"
  | "createRunGantt"
  | MutationKeySpecifier
)[];
export type MutationFieldPolicy = {
  reserveFeedWorklist?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTemplateTask?: FieldPolicy<any> | FieldReadFunction<any>;
  assignTask?: FieldPolicy<any> | FieldReadFunction<any>;
  createTaskAttachments?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTaskAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  rescheduleTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  addPersonalTask?: FieldPolicy<any> | FieldReadFunction<any>;
  addRecurringPersonalTask?: FieldPolicy<any> | FieldReadFunction<any>;
  startPersonalTaskTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  createPersonalTaskTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  addPersonalTaskTemplateTask?: FieldPolicy<any> | FieldReadFunction<any>;
  addRunTemplateTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePersonalTaskTemplateTask?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRunTemplateTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  overwriteRecurringTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePersonalTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRecurringPersonalTask?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRecurringTask?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelRemainingReccuringTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  updateDashboardTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  updateCollectionTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  createCollectionTask?: FieldPolicy<any> | FieldReadFunction<any>;
  addRunTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRunTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  saveFeedGroups?: FieldPolicy<any> | FieldReadFunction<any>;
  addPersonalFeedTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  createTicket?: FieldPolicy<any> | FieldReadFunction<any>;
  mergeTicket?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTickets?: FieldPolicy<any> | FieldReadFunction<any>;
  acceptTicket?: FieldPolicy<any> | FieldReadFunction<any>;
  addTicketUpdate?: FieldPolicy<any> | FieldReadFunction<any>;
  addTicketAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTicketAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
  createRunTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  addChildRunTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  addRunTemplateStages?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRunTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRunTemplateStages?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRunTemplateTaskSchedule?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  removeTemplateStage?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRunTemplateTreeNode?: FieldPolicy<any> | FieldReadFunction<any>;
  intermediateDeadAndDestPlateBarcodes?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  setLabwareType?: FieldPolicy<any> | FieldReadFunction<any>;
  plateBarcodePreview?: FieldPolicy<any> | FieldReadFunction<any>;
  reserveMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteExistingMethodReservation?: FieldPolicy<any> | FieldReadFunction<any>;
  saveWorklistToolTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  overwriteExistingWorklistTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteWorklistToolTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  groupMemberLogin?: FieldPolicy<any> | FieldReadFunction<any>;
  saveSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRunActivityMethodReservation?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  deleteTaskMethodReservation?: FieldPolicy<any> | FieldReadFunction<any>;
  reserveCeligoRunTaskMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  reserveCeligoPersonalTaskMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  log?: FieldPolicy<any> | FieldReadFunction<any>;
  updateIncomingSampleCollections?: FieldPolicy<any> | FieldReadFunction<any>;
  createIncomingSampleCollection?: FieldPolicy<any> | FieldReadFunction<any>;
  addIdfUpload?: FieldPolicy<any> | FieldReadFunction<any>;
  validateIdfUpload?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteIncomingSampleCollection?: FieldPolicy<any> | FieldReadFunction<any>;
  updateIncomingSampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  addIncomingSampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteCollectionSampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  addAsset?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMethods?: FieldPolicy<any> | FieldReadFunction<any>;
  updateManualTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRunTaskTypeDocumentation?: FieldPolicy<any> | FieldReadFunction<any>;
  createRunFolder?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRunFolder?: FieldPolicy<any> | FieldReadFunction<any>;
  createRun?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRun?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteRun?: FieldPolicy<any> | FieldReadFunction<any>;
  createRunGantt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MyPersonalTasksConnectionKeySpecifier = (
  | "pageInfo"
  | "edges"
  | "nodes"
  | "totalCount"
  | MyPersonalTasksConnectionKeySpecifier
)[];
export type MyPersonalTasksConnectionFieldPolicy = {
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MyPersonalTasksEdgeKeySpecifier = (
  | "cursor"
  | "node"
  | MyPersonalTasksEdgeKeySpecifier
)[];
export type MyPersonalTasksEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NormalizedPassageTemplateKeySpecifier = (
  | "normalizedPassageTemplateId"
  | "templateName"
  | "createdBy"
  | "updatedBy"
  | "updatedByTime"
  | "createdByNavigation"
  | "normalizedPassageTemplateDetails"
  | "normalizedPassageTemplateMethodSettings"
  | "normalizedPassageTemplateStampDetails"
  | "normalizedPassageTemplatesStampTopLefts"
  | NormalizedPassageTemplateKeySpecifier
)[];
export type NormalizedPassageTemplateFieldPolicy = {
  normalizedPassageTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  templateName?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByTime?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  normalizedPassageTemplateDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  normalizedPassageTemplateMethodSettings?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  normalizedPassageTemplateStampDetails?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  normalizedPassageTemplatesStampTopLefts?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
};
export type NormalizedPassageTemplateDetailKeySpecifier = (
  | "normalizedPassageTemplateDetailsId"
  | "normalizedPassageTemplateId"
  | "task"
  | "sourcePlateId"
  | "sourcePlateWellPosition"
  | "destinationPlateId"
  | "destinationPlateWellPosition"
  | "cellNumber"
  | "sourcePlateType"
  | "destinationPlateType"
  | "normalizedPassageTemplate"
  | NormalizedPassageTemplateDetailKeySpecifier
)[];
export type NormalizedPassageTemplateDetailFieldPolicy = {
  normalizedPassageTemplateDetailsId?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  normalizedPassageTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  task?: FieldPolicy<any> | FieldReadFunction<any>;
  sourcePlateId?: FieldPolicy<any> | FieldReadFunction<any>;
  sourcePlateWellPosition?: FieldPolicy<any> | FieldReadFunction<any>;
  destinationPlateId?: FieldPolicy<any> | FieldReadFunction<any>;
  destinationPlateWellPosition?: FieldPolicy<any> | FieldReadFunction<any>;
  cellNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  sourcePlateType?: FieldPolicy<any> | FieldReadFunction<any>;
  destinationPlateType?: FieldPolicy<any> | FieldReadFunction<any>;
  normalizedPassageTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NormalizedPassageTemplateMethodSettingKeySpecifier = (
  | "normalizedPassageTemplateMethodSettingsId"
  | "normalizedPassageTemplateId"
  | "selectedSystem"
  | "isStamp96"
  | "cellType"
  | "dissociationTime"
  | "spinParameters"
  | "spinTime"
  | "spinAcceleration"
  | "spinDeceleration"
  | "pelletResuspensionVolume"
  | "dissociationWashReagent"
  | "reFeedWells"
  | "reFeedWellsReagent"
  | "dissociation"
  | "washBeforeDissociation"
  | "washAfterDissociation"
  | "preprocessPlate"
  | "stampColumns"
  | "stampRows"
  | "stampVolume"
  | "normalizedPassageTemplate"
  | NormalizedPassageTemplateMethodSettingKeySpecifier
)[];
export type NormalizedPassageTemplateMethodSettingFieldPolicy = {
  normalizedPassageTemplateMethodSettingsId?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  normalizedPassageTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  selectedSystem?: FieldPolicy<any> | FieldReadFunction<any>;
  isStamp96?: FieldPolicy<any> | FieldReadFunction<any>;
  cellType?: FieldPolicy<any> | FieldReadFunction<any>;
  dissociationTime?: FieldPolicy<any> | FieldReadFunction<any>;
  spinParameters?: FieldPolicy<any> | FieldReadFunction<any>;
  spinTime?: FieldPolicy<any> | FieldReadFunction<any>;
  spinAcceleration?: FieldPolicy<any> | FieldReadFunction<any>;
  spinDeceleration?: FieldPolicy<any> | FieldReadFunction<any>;
  pelletResuspensionVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  dissociationWashReagent?: FieldPolicy<any> | FieldReadFunction<any>;
  reFeedWells?: FieldPolicy<any> | FieldReadFunction<any>;
  reFeedWellsReagent?: FieldPolicy<any> | FieldReadFunction<any>;
  dissociation?: FieldPolicy<any> | FieldReadFunction<any>;
  washBeforeDissociation?: FieldPolicy<any> | FieldReadFunction<any>;
  washAfterDissociation?: FieldPolicy<any> | FieldReadFunction<any>;
  preprocessPlate?: FieldPolicy<any> | FieldReadFunction<any>;
  stampColumns?: FieldPolicy<any> | FieldReadFunction<any>;
  stampRows?: FieldPolicy<any> | FieldReadFunction<any>;
  stampVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  normalizedPassageTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NormalizedPassageTemplateStampDetailKeySpecifier = (
  | "normalizedPassageTemplateStampId"
  | "normalizedPassageTemplateId"
  | "sourcePlateLocation"
  | "sourceWellId"
  | "normalizedPassageTemplate"
  | NormalizedPassageTemplateStampDetailKeySpecifier
)[];
export type NormalizedPassageTemplateStampDetailFieldPolicy = {
  normalizedPassageTemplateStampId?: FieldPolicy<any> | FieldReadFunction<any>;
  normalizedPassageTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  sourcePlateLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  sourceWellId?: FieldPolicy<any> | FieldReadFunction<any>;
  normalizedPassageTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NormalizedPassageTemplatesStampTopLeftKeySpecifier = (
  | "stampTopLeftId"
  | "normalizedPassageTemplateId"
  | "stampTopLeftPlateLocation"
  | "stampTopLeftWellLocation"
  | "normalizedPassageTemplate"
  | NormalizedPassageTemplatesStampTopLeftKeySpecifier
)[];
export type NormalizedPassageTemplatesStampTopLeftFieldPolicy = {
  stampTopLeftId?: FieldPolicy<any> | FieldReadFunction<any>;
  normalizedPassageTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  stampTopLeftPlateLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  stampTopLeftWellLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  normalizedPassageTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PageInfoKeySpecifier = (
  | "hasNextPage"
  | "hasPreviousPage"
  | "startCursor"
  | "endCursor"
  | PageInfoKeySpecifier
)[];
export type PageInfoFieldPolicy = {
  hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>;
  hasPreviousPage?: FieldPolicy<any> | FieldReadFunction<any>;
  startCursor?: FieldPolicy<any> | FieldReadFunction<any>;
  endCursor?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PersonalTaskKeySpecifier = (
  | "taskType"
  | "taskId"
  | "taskName"
  | "methodId"
  | "taskOwnerId"
  | "systemAssignedTo"
  | "notes"
  | "updatedBy"
  | "updateActionDetails"
  | "lastTimeUpdated"
  | "completedBy"
  | "completedByTime"
  | "recurringTaskId"
  | "manualTaskTypeId"
  | "startTimeScheduled"
  | "endTimeScheduled"
  | "startTimeActual"
  | "endTimeActual"
  | "feedGroupId"
  | "completedByNavigation"
  | "feedGroup"
  | "manualTaskType"
  | "method"
  | "recurringTask"
  | "systemAssignedToNavigation"
  | "taskOwner"
  | "methodReservations"
  | "personalTaskAttachments"
  | "groups"
  | "users"
  | "priority"
  | "status"
  | PersonalTaskKeySpecifier
)[];
export type PersonalTaskFieldPolicy = {
  taskType?: FieldPolicy<any> | FieldReadFunction<any>;
  taskId?: FieldPolicy<any> | FieldReadFunction<any>;
  taskName?: FieldPolicy<any> | FieldReadFunction<any>;
  methodId?: FieldPolicy<any> | FieldReadFunction<any>;
  taskOwnerId?: FieldPolicy<any> | FieldReadFunction<any>;
  systemAssignedTo?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updateActionDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  lastTimeUpdated?: FieldPolicy<any> | FieldReadFunction<any>;
  completedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  completedByTime?: FieldPolicy<any> | FieldReadFunction<any>;
  recurringTaskId?: FieldPolicy<any> | FieldReadFunction<any>;
  manualTaskTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeScheduled?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeScheduled?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeActual?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeActual?: FieldPolicy<any> | FieldReadFunction<any>;
  feedGroupId?: FieldPolicy<any> | FieldReadFunction<any>;
  completedByNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  feedGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  manualTaskType?: FieldPolicy<any> | FieldReadFunction<any>;
  method?: FieldPolicy<any> | FieldReadFunction<any>;
  recurringTask?: FieldPolicy<any> | FieldReadFunction<any>;
  systemAssignedToNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  taskOwner?: FieldPolicy<any> | FieldReadFunction<any>;
  methodReservations?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTaskAttachments?: FieldPolicy<any> | FieldReadFunction<any>;
  groups?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
  priority?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PersonalTaskAttachmentKeySpecifier = (
  | "personalTaskAttachmentId"
  | "personalTaskId"
  | "attachmentName"
  | "dateUploaded"
  | "uploadedBy"
  | "comments"
  | "personalTask"
  | "attachmentUrl"
  | PersonalTaskAttachmentKeySpecifier
)[];
export type PersonalTaskAttachmentFieldPolicy = {
  personalTaskAttachmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTaskId?: FieldPolicy<any> | FieldReadFunction<any>;
  attachmentName?: FieldPolicy<any> | FieldReadFunction<any>;
  dateUploaded?: FieldPolicy<any> | FieldReadFunction<any>;
  uploadedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTask?: FieldPolicy<any> | FieldReadFunction<any>;
  attachmentUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PersonalTaskTemplateKeySpecifier = (
  | "personalTaskTemplateId"
  | "personalTaskTemplateName"
  | "createdBy"
  | "updatedBy"
  | "updatedByTime"
  | "createdByNavigation"
  | "personalTaskTemplateTasks"
  | PersonalTaskTemplateKeySpecifier
)[];
export type PersonalTaskTemplateFieldPolicy = {
  personalTaskTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTaskTemplateName?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByTime?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTaskTemplateTasks?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PersonalTaskTemplateTaskKeySpecifier = (
  | "priority"
  | "personalTaskTemplateTaskId"
  | "personalTaskTemplateId"
  | "personalTaskName"
  | "dayScheduled"
  | "userAssignedTo"
  | "groupAssignedTo"
  | "notes"
  | "assignedSystem"
  | "groupAssignedToNavigation"
  | "personalTaskTemplate"
  | "userAssignedToNavigation"
  | "groups"
  | "users"
  | PersonalTaskTemplateTaskKeySpecifier
)[];
export type PersonalTaskTemplateTaskFieldPolicy = {
  priority?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTaskTemplateTaskId?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTaskTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTaskName?: FieldPolicy<any> | FieldReadFunction<any>;
  dayScheduled?: FieldPolicy<any> | FieldReadFunction<any>;
  userAssignedTo?: FieldPolicy<any> | FieldReadFunction<any>;
  groupAssignedTo?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  assignedSystem?: FieldPolicy<any> | FieldReadFunction<any>;
  groupAssignedToNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTaskTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  userAssignedToNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  groups?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PersonalTaskTemplatesConnectionKeySpecifier = (
  | "pageInfo"
  | "edges"
  | "nodes"
  | "totalCount"
  | PersonalTaskTemplatesConnectionKeySpecifier
)[];
export type PersonalTaskTemplatesConnectionFieldPolicy = {
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PersonalTaskTemplatesEdgeKeySpecifier = (
  | "cursor"
  | "node"
  | PersonalTaskTemplatesEdgeKeySpecifier
)[];
export type PersonalTaskTemplatesEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PersonalTasksRecurringTaskKeySpecifier = (
  | "recurringTaskId"
  | "taskName"
  | "repeatingDaysOfWeek"
  | "endRepeatDate"
  | "personalTasks"
  | PersonalTasksRecurringTaskKeySpecifier
)[];
export type PersonalTasksRecurringTaskFieldPolicy = {
  recurringTaskId?: FieldPolicy<any> | FieldReadFunction<any>;
  taskName?: FieldPolicy<any> | FieldReadFunction<any>;
  repeatingDaysOfWeek?: FieldPolicy<any> | FieldReadFunction<any>;
  endRepeatDate?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTasks?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlateMetadataKeySpecifier = (
  | "sourcePlateBarcode"
  | "experimentSetting"
  | "analysisSetting"
  | "platePitch"
  | "imageExportSetting"
  | "imageExportFormat"
  | PlateMetadataKeySpecifier
)[];
export type PlateMetadataFieldPolicy = {
  sourcePlateBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  experimentSetting?: FieldPolicy<any> | FieldReadFunction<any>;
  analysisSetting?: FieldPolicy<any> | FieldReadFunction<any>;
  platePitch?: FieldPolicy<any> | FieldReadFunction<any>;
  imageExportSetting?: FieldPolicy<any> | FieldReadFunction<any>;
  imageExportFormat?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlateNoteKeySpecifier = (
  | "plateNoteId"
  | "plateId"
  | "note"
  | "dateCommented"
  | "userId"
  | "plate"
  | "user"
  | PlateNoteKeySpecifier
)[];
export type PlateNoteFieldPolicy = {
  plateNoteId?: FieldPolicy<any> | FieldReadFunction<any>;
  plateId?: FieldPolicy<any> | FieldReadFunction<any>;
  note?: FieldPolicy<any> | FieldReadFunction<any>;
  dateCommented?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  plate?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlateToMetadataKeySpecifier = (
  | "platesCreated"
  | "metadata"
  | PlateToMetadataKeySpecifier
)[];
export type PlateToMetadataFieldPolicy = {
  platesCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  metadata?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlateTransferKeySpecifier = (
  | "plateTransferId"
  | "srcPlateId"
  | "destPlateId"
  | "dateTime"
  | "isVisible"
  | "destPlate"
  | "srcPlate"
  | PlateTransferKeySpecifier
)[];
export type PlateTransferFieldPolicy = {
  plateTransferId?: FieldPolicy<any> | FieldReadFunction<any>;
  srcPlateId?: FieldPolicy<any> | FieldReadFunction<any>;
  destPlateId?: FieldPolicy<any> | FieldReadFunction<any>;
  dateTime?: FieldPolicy<any> | FieldReadFunction<any>;
  isVisible?: FieldPolicy<any> | FieldReadFunction<any>;
  destPlate?: FieldPolicy<any> | FieldReadFunction<any>;
  srcPlate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlateTypeKeySpecifier = (
  | "plateTypeId"
  | "plateName"
  | "plateDescription"
  | "manufacturer"
  | "catalogNumber"
  | "plateRows"
  | "plateCols"
  | "lookupArrayPlateTypeStrings"
  | "lookupExperimentSettings"
  | "lookupLabwareTypes"
  | PlateTypeKeySpecifier
)[];
export type PlateTypeFieldPolicy = {
  plateTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  plateName?: FieldPolicy<any> | FieldReadFunction<any>;
  plateDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  manufacturer?: FieldPolicy<any> | FieldReadFunction<any>;
  catalogNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  plateRows?: FieldPolicy<any> | FieldReadFunction<any>;
  plateCols?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupArrayPlateTypeStrings?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupExperimentSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupLabwareTypes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlatesConnectionKeySpecifier = (
  | "pageInfo"
  | "edges"
  | "nodes"
  | PlatesConnectionKeySpecifier
)[];
export type PlatesConnectionFieldPolicy = {
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlatesCreatedKeySpecifier = (
  | "method"
  | "plateCreatedId"
  | "plateBarcode"
  | "dateCreated"
  | "createdByUserId"
  | "dateUploaded"
  | "systemSerialNumber"
  | "systemMethodName"
  | "plateBarcodeAlias"
  | "isActive"
  | "dateInactivate"
  | "inactivatedBy"
  | "inactivationReasonId"
  | "reasonInactivated"
  | "comments"
  | "productionRunId"
  | "arrayPlateTypeId"
  | "reasonNoData"
  | "reasonNoTubeBarcodes"
  | "reasonNoPassage"
  | "isIntermediate"
  | "isDead"
  | "labwareTypeId"
  | "temporaryCreation"
  | "coatedPlateBatchId"
  | "celigoExperimentSettingsId"
  | "lastScanDate"
  | "isReserved"
  | "arrayPlateType"
  | "celigoExperimentSettings"
  | "coatedPlateBatch"
  | "createdByUser"
  | "labwareType"
  | "productionRun"
  | "containers"
  | "cytomatPositions"
  | "plateNotes"
  | "plateTransferDestPlates"
  | "plateTransferSrcPlates"
  | "runs"
  | "tubeBarcodeFiles"
  | PlatesCreatedKeySpecifier
)[];
export type PlatesCreatedFieldPolicy = {
  method?: FieldPolicy<any> | FieldReadFunction<any>;
  plateCreatedId?: FieldPolicy<any> | FieldReadFunction<any>;
  plateBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  dateCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  dateUploaded?: FieldPolicy<any> | FieldReadFunction<any>;
  systemSerialNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  systemMethodName?: FieldPolicy<any> | FieldReadFunction<any>;
  plateBarcodeAlias?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  dateInactivate?: FieldPolicy<any> | FieldReadFunction<any>;
  inactivatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  inactivationReasonId?: FieldPolicy<any> | FieldReadFunction<any>;
  reasonInactivated?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRunId?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayPlateTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  reasonNoData?: FieldPolicy<any> | FieldReadFunction<any>;
  reasonNoTubeBarcodes?: FieldPolicy<any> | FieldReadFunction<any>;
  reasonNoPassage?: FieldPolicy<any> | FieldReadFunction<any>;
  isIntermediate?: FieldPolicy<any> | FieldReadFunction<any>;
  isDead?: FieldPolicy<any> | FieldReadFunction<any>;
  labwareTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  temporaryCreation?: FieldPolicy<any> | FieldReadFunction<any>;
  coatedPlateBatchId?: FieldPolicy<any> | FieldReadFunction<any>;
  celigoExperimentSettingsId?: FieldPolicy<any> | FieldReadFunction<any>;
  lastScanDate?: FieldPolicy<any> | FieldReadFunction<any>;
  isReserved?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayPlateType?: FieldPolicy<any> | FieldReadFunction<any>;
  celigoExperimentSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  coatedPlateBatch?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByUser?: FieldPolicy<any> | FieldReadFunction<any>;
  labwareType?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRun?: FieldPolicy<any> | FieldReadFunction<any>;
  containers?: FieldPolicy<any> | FieldReadFunction<any>;
  cytomatPositions?: FieldPolicy<any> | FieldReadFunction<any>;
  plateNotes?: FieldPolicy<any> | FieldReadFunction<any>;
  plateTransferDestPlates?: FieldPolicy<any> | FieldReadFunction<any>;
  plateTransferSrcPlates?: FieldPolicy<any> | FieldReadFunction<any>;
  runs?: FieldPolicy<any> | FieldReadFunction<any>;
  tubeBarcodeFiles?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlatesEdgeKeySpecifier = (
  | "cursor"
  | "node"
  | PlatesEdgeKeySpecifier
)[];
export type PlatesEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProductionRunKeySpecifier = (
  | "runId"
  | "runName"
  | "runNameAlias"
  | "aliasComment"
  | "runTypeId"
  | "runTemplateId"
  | "startDate"
  | "endDate"
  | "runStatusId"
  | "isActive"
  | "inactivationReasonId"
  | "inactivationComments"
  | "dateInactivated"
  | "inactivatedBy"
  | "isQcactive"
  | "isExperimental"
  | "isReference"
  | "endDateFbreproTube"
  | "createdByUserId"
  | "createdByUser"
  | "runType"
  | "platesCreateds"
  | "runs"
  | ProductionRunKeySpecifier
)[];
export type ProductionRunFieldPolicy = {
  runId?: FieldPolicy<any> | FieldReadFunction<any>;
  runName?: FieldPolicy<any> | FieldReadFunction<any>;
  runNameAlias?: FieldPolicy<any> | FieldReadFunction<any>;
  aliasComment?: FieldPolicy<any> | FieldReadFunction<any>;
  runTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  startDate?: FieldPolicy<any> | FieldReadFunction<any>;
  endDate?: FieldPolicy<any> | FieldReadFunction<any>;
  runStatusId?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  inactivationReasonId?: FieldPolicy<any> | FieldReadFunction<any>;
  inactivationComments?: FieldPolicy<any> | FieldReadFunction<any>;
  dateInactivated?: FieldPolicy<any> | FieldReadFunction<any>;
  inactivatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  isQcactive?: FieldPolicy<any> | FieldReadFunction<any>;
  isExperimental?: FieldPolicy<any> | FieldReadFunction<any>;
  isReference?: FieldPolicy<any> | FieldReadFunction<any>;
  endDateFbreproTube?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByUser?: FieldPolicy<any> | FieldReadFunction<any>;
  runType?: FieldPolicy<any> | FieldReadFunction<any>;
  platesCreateds?: FieldPolicy<any> | FieldReadFunction<any>;
  runs?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProductionRunTypeKeySpecifier = (
  | "productionRunTypeId"
  | "typeName"
  | "runCode"
  | "runNumberDigits"
  | "isActive"
  | "lotNumberTypeId"
  | "lotNumberFormatId"
  | "sourcePlateTypeId"
  | "finalPlateTypeId"
  | "actionId"
  | "isRandD"
  | "isGeneEditing"
  | "isReprogramming"
  | "isConsolidation"
  | "slackChannelId"
  | "isSourceRun"
  | "pivotPosition"
  | "expectedCompletionTime"
  | "newSampleTypeId"
  | "sampleTypeChangeTriggersOnAnyPassageWithinRun"
  | "sampleTypeChangeTriggersOnRunTypeChange"
  | "sampleTypeChangeUsesSingleCloneMode"
  | "runTemplateId"
  | "finalPlateType"
  | "lotNumberFormat"
  | "lotNumberType"
  | "newSampleType"
  | "runTemplate"
  | "sourcePlateType"
  | "lookupArrayPlateTypeStrings"
  | "productionRuns"
  | "runTemplates"
  | ProductionRunTypeKeySpecifier
)[];
export type ProductionRunTypeFieldPolicy = {
  productionRunTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  typeName?: FieldPolicy<any> | FieldReadFunction<any>;
  runCode?: FieldPolicy<any> | FieldReadFunction<any>;
  runNumberDigits?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumberTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumberFormatId?: FieldPolicy<any> | FieldReadFunction<any>;
  sourcePlateTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  finalPlateTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  actionId?: FieldPolicy<any> | FieldReadFunction<any>;
  isRandD?: FieldPolicy<any> | FieldReadFunction<any>;
  isGeneEditing?: FieldPolicy<any> | FieldReadFunction<any>;
  isReprogramming?: FieldPolicy<any> | FieldReadFunction<any>;
  isConsolidation?: FieldPolicy<any> | FieldReadFunction<any>;
  slackChannelId?: FieldPolicy<any> | FieldReadFunction<any>;
  isSourceRun?: FieldPolicy<any> | FieldReadFunction<any>;
  pivotPosition?: FieldPolicy<any> | FieldReadFunction<any>;
  expectedCompletionTime?: FieldPolicy<any> | FieldReadFunction<any>;
  newSampleTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleTypeChangeTriggersOnAnyPassageWithinRun?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  sampleTypeChangeTriggersOnRunTypeChange?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  sampleTypeChangeUsesSingleCloneMode?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  runTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  finalPlateType?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumberFormat?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumberType?: FieldPolicy<any> | FieldReadFunction<any>;
  newSampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  sourcePlateType?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupArrayPlateTypeStrings?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRuns?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplates?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectKeySpecifier = (
  | "projectId"
  | "projectName"
  | "nyscfprefix"
  | "dateCreated"
  | "createdBy"
  | "administrator"
  | "isActive"
  | "providedAsIsCoAtext"
  | "collections"
  | "projectDonors"
  | ProjectKeySpecifier
)[];
export type ProjectFieldPolicy = {
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  projectName?: FieldPolicy<any> | FieldReadFunction<any>;
  nyscfprefix?: FieldPolicy<any> | FieldReadFunction<any>;
  dateCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  administrator?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  providedAsIsCoAtext?: FieldPolicy<any> | FieldReadFunction<any>;
  collections?: FieldPolicy<any> | FieldReadFunction<any>;
  projectDonors?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectDonorKeySpecifier = (
  | "projectDonorId"
  | "projectId"
  | "donorId"
  | "associatedBy"
  | "donor"
  | "project"
  | ProjectDonorKeySpecifier
)[];
export type ProjectDonorFieldPolicy = {
  projectDonorId?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  donorId?: FieldPolicy<any> | FieldReadFunction<any>;
  associatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  donor?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QueryKeySpecifier = (
  | "activeASAMs"
  | "activePooling1224TCPlates"
  | "activeReservations"
  | "activeTickets"
  | "allUserAndGroupTasks"
  | "appSuiteGroups"
  | "appSuiteUsers"
  | "arrayMethodLabwareTypes"
  | "arraySystemAutomationMethods"
  | "bloodProcessingRackInfo"
  | "calendarNodes"
  | "celigoScanPlateMetadata"
  | "cellNumberFromNormalizedPassagePlate"
  | "consolidationRackInfo"
  | "container"
  | "containerLineage"
  | "containers"
  | "createRunDefaults"
  | "dNARNARackInfo"
  | "dashboardTask"
  | "directory"
  | "eBCollectionRackInfo"
  | "excludedFeedPlates"
  | "existingDeadPlate"
  | "existingMethodReservation"
  | "experimentSettings"
  | "feedGroups"
  | "fibroblastRackInfo"
  | "folder"
  | "folders"
  | "ganttIFrameUrl"
  | "group"
  | "groupPersonalTasks"
  | "groupsFromCache"
  | "iPSCExpansionRackInfo"
  | "includedFeedPlates"
  | "incomingSampleCollections"
  | "incomingSampleTasks"
  | "incomingSamplesCollection"
  | "incomingSamplesTask"
  | "incomingSamplesTasksFromCache"
  | "insertableFolders"
  | "labComputers"
  | "labwareTypes"
  | "lotNumbers"
  | "manualPlateRackInfo"
  | "manualTaskType"
  | "manualTaskTypes"
  | "me"
  | "method"
  | "methodReservation"
  | "methods"
  | "monoclonalizationRackInfo"
  | "myPersonalTasks"
  | "personalTaskAttachments"
  | "personalTaskTemplates"
  | "plate"
  | "plateCodes"
  | "plateHasTubeBarcodeScan"
  | "plateInfo"
  | "plateMap"
  | "plates"
  | "platesForManualSelector"
  | "platesInfo"
  | "pooling1224TCRackInfo"
  | "productionRunType"
  | "productionRuns"
  | "projects"
  | "qCRackInfo"
  | "repeatingTasksSummary"
  | "reservedMethods"
  | "resuspensionVolFromPooling1224TCPlate"
  | "run"
  | "runStages"
  | "runTask"
  | "runTaskAttachments"
  | "runTaskMetadata"
  | "runTaskTypes"
  | "runTasks"
  | "runTasksForTubeLabels"
  | "runTasksFromCache"
  | "runTemplate"
  | "runTemplateStage"
  | "runTemplateTask"
  | "runTemplateTasks"
  | "runTemplates"
  | "runTemplatesForCombobox"
  | "runTypes"
  | "runs"
  | "salivaRackInfo"
  | "sample"
  | "sampleTypes"
  | "searchDirectory"
  | "serverGroupState"
  | "sourcePlates"
  | "system"
  | "systems"
  | "taskDetailsForWorklistTool"
  | "taskHistory"
  | "ticket"
  | "ticketAssetTypes"
  | "ticketAssets"
  | "ticketInfo"
  | "ticketTypes"
  | "tickets"
  | "tubeLabellerRuns"
  | "tubeLabellerRunsByProcess"
  | "tubeLabellerSalivaSamples"
  | "unavailablePlates"
  | "user"
  | "usersFromCache"
  | "worklist"
  | "worklistToolTemplate"
  | "worklistToolTemplateContent"
  | QueryKeySpecifier
)[];
export type QueryFieldPolicy = {
  activeASAMs?: FieldPolicy<any> | FieldReadFunction<any>;
  activePooling1224TCPlates?: FieldPolicy<any> | FieldReadFunction<any>;
  activeReservations?: FieldPolicy<any> | FieldReadFunction<any>;
  activeTickets?: FieldPolicy<any> | FieldReadFunction<any>;
  allUserAndGroupTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  appSuiteGroups?: FieldPolicy<any> | FieldReadFunction<any>;
  appSuiteUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodLabwareTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  arraySystemAutomationMethods?: FieldPolicy<any> | FieldReadFunction<any>;
  bloodProcessingRackInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  calendarNodes?: FieldPolicy<any> | FieldReadFunction<any>;
  celigoScanPlateMetadata?: FieldPolicy<any> | FieldReadFunction<any>;
  cellNumberFromNormalizedPassagePlate?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  consolidationRackInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  container?: FieldPolicy<any> | FieldReadFunction<any>;
  containerLineage?: FieldPolicy<any> | FieldReadFunction<any>;
  containers?: FieldPolicy<any> | FieldReadFunction<any>;
  createRunDefaults?: FieldPolicy<any> | FieldReadFunction<any>;
  dNARNARackInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  dashboardTask?: FieldPolicy<any> | FieldReadFunction<any>;
  directory?: FieldPolicy<any> | FieldReadFunction<any>;
  eBCollectionRackInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  excludedFeedPlates?: FieldPolicy<any> | FieldReadFunction<any>;
  existingDeadPlate?: FieldPolicy<any> | FieldReadFunction<any>;
  existingMethodReservation?: FieldPolicy<any> | FieldReadFunction<any>;
  experimentSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  feedGroups?: FieldPolicy<any> | FieldReadFunction<any>;
  fibroblastRackInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  folder?: FieldPolicy<any> | FieldReadFunction<any>;
  folders?: FieldPolicy<any> | FieldReadFunction<any>;
  ganttIFrameUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  group?: FieldPolicy<any> | FieldReadFunction<any>;
  groupPersonalTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  groupsFromCache?: FieldPolicy<any> | FieldReadFunction<any>;
  iPSCExpansionRackInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  includedFeedPlates?: FieldPolicy<any> | FieldReadFunction<any>;
  incomingSampleCollections?: FieldPolicy<any> | FieldReadFunction<any>;
  incomingSampleTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  incomingSamplesCollection?: FieldPolicy<any> | FieldReadFunction<any>;
  incomingSamplesTask?: FieldPolicy<any> | FieldReadFunction<any>;
  incomingSamplesTasksFromCache?: FieldPolicy<any> | FieldReadFunction<any>;
  insertableFolders?: FieldPolicy<any> | FieldReadFunction<any>;
  labComputers?: FieldPolicy<any> | FieldReadFunction<any>;
  labwareTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumbers?: FieldPolicy<any> | FieldReadFunction<any>;
  manualPlateRackInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  manualTaskType?: FieldPolicy<any> | FieldReadFunction<any>;
  manualTaskTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  me?: FieldPolicy<any> | FieldReadFunction<any>;
  method?: FieldPolicy<any> | FieldReadFunction<any>;
  methodReservation?: FieldPolicy<any> | FieldReadFunction<any>;
  methods?: FieldPolicy<any> | FieldReadFunction<any>;
  monoclonalizationRackInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  myPersonalTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTaskAttachments?: FieldPolicy<any> | FieldReadFunction<any>;
  personalTaskTemplates?: FieldPolicy<any> | FieldReadFunction<any>;
  plate?: FieldPolicy<any> | FieldReadFunction<any>;
  plateCodes?: FieldPolicy<any> | FieldReadFunction<any>;
  plateHasTubeBarcodeScan?: FieldPolicy<any> | FieldReadFunction<any>;
  plateInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  plateMap?: FieldPolicy<any> | FieldReadFunction<any>;
  plates?: FieldPolicy<any> | FieldReadFunction<any>;
  platesForManualSelector?: FieldPolicy<any> | FieldReadFunction<any>;
  platesInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  pooling1224TCRackInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRunType?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRuns?: FieldPolicy<any> | FieldReadFunction<any>;
  projects?: FieldPolicy<any> | FieldReadFunction<any>;
  qCRackInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  repeatingTasksSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  reservedMethods?: FieldPolicy<any> | FieldReadFunction<any>;
  resuspensionVolFromPooling1224TCPlate?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  run?: FieldPolicy<any> | FieldReadFunction<any>;
  runStages?: FieldPolicy<any> | FieldReadFunction<any>;
  runTask?: FieldPolicy<any> | FieldReadFunction<any>;
  runTaskAttachments?: FieldPolicy<any> | FieldReadFunction<any>;
  runTaskMetadata?: FieldPolicy<any> | FieldReadFunction<any>;
  runTaskTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  runTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTasksForTubeLabels?: FieldPolicy<any> | FieldReadFunction<any>;
  runTasksFromCache?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateStage?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTask?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplates?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplatesForCombobox?: FieldPolicy<any> | FieldReadFunction<any>;
  runTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  runs?: FieldPolicy<any> | FieldReadFunction<any>;
  salivaRackInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  sample?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  searchDirectory?: FieldPolicy<any> | FieldReadFunction<any>;
  serverGroupState?: FieldPolicy<any> | FieldReadFunction<any>;
  sourcePlates?: FieldPolicy<any> | FieldReadFunction<any>;
  system?: FieldPolicy<any> | FieldReadFunction<any>;
  systems?: FieldPolicy<any> | FieldReadFunction<any>;
  taskDetailsForWorklistTool?: FieldPolicy<any> | FieldReadFunction<any>;
  taskHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  ticket?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketAssetTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketAssets?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  tickets?: FieldPolicy<any> | FieldReadFunction<any>;
  tubeLabellerRuns?: FieldPolicy<any> | FieldReadFunction<any>;
  tubeLabellerRunsByProcess?: FieldPolicy<any> | FieldReadFunction<any>;
  tubeLabellerSalivaSamples?: FieldPolicy<any> | FieldReadFunction<any>;
  unavailablePlates?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  usersFromCache?: FieldPolicy<any> | FieldReadFunction<any>;
  worklist?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistToolTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistToolTemplateContent?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RepeatingTasksSummaryConnectionKeySpecifier = (
  | "pageInfo"
  | "edges"
  | "nodes"
  | "totalCount"
  | RepeatingTasksSummaryConnectionKeySpecifier
)[];
export type RepeatingTasksSummaryConnectionFieldPolicy = {
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RepeatingTasksSummaryEdgeKeySpecifier = (
  | "cursor"
  | "node"
  | RepeatingTasksSummaryEdgeKeySpecifier
)[];
export type RepeatingTasksSummaryEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunKeySpecifier = (
  | "status"
  | "runId"
  | "folderParentId"
  | "runTemplateId"
  | "runDateScheduled"
  | "runTypeId"
  | "runName"
  | "comments"
  | "isActive"
  | "runProgress"
  | "runOwner"
  | "productionRunId"
  | "plateCreatedId"
  | "folderParent"
  | "plateCreated"
  | "runOwnerNavigation"
  | "lookupTeamGanttId"
  | "runStages"
  | "runTasks"
  | "productionRun"
  | RunKeySpecifier
)[];
export type RunFieldPolicy = {
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  runId?: FieldPolicy<any> | FieldReadFunction<any>;
  folderParentId?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  runDateScheduled?: FieldPolicy<any> | FieldReadFunction<any>;
  runTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  runName?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  runProgress?: FieldPolicy<any> | FieldReadFunction<any>;
  runOwner?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRunId?: FieldPolicy<any> | FieldReadFunction<any>;
  plateCreatedId?: FieldPolicy<any> | FieldReadFunction<any>;
  folderParent?: FieldPolicy<any> | FieldReadFunction<any>;
  plateCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  runOwnerNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupTeamGanttId?: FieldPolicy<any> | FieldReadFunction<any>;
  runStages?: FieldPolicy<any> | FieldReadFunction<any>;
  runTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRun?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunStageKeySpecifier = (
  | "runStageId"
  | "runId"
  | "runStageSeqNo"
  | "runStageName"
  | "run"
  | "runTasks"
  | RunStageKeySpecifier
)[];
export type RunStageFieldPolicy = {
  runStageId?: FieldPolicy<any> | FieldReadFunction<any>;
  runId?: FieldPolicy<any> | FieldReadFunction<any>;
  runStageSeqNo?: FieldPolicy<any> | FieldReadFunction<any>;
  runStageName?: FieldPolicy<any> | FieldReadFunction<any>;
  run?: FieldPolicy<any> | FieldReadFunction<any>;
  runTasks?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunStagesConnectionKeySpecifier = (
  | "pageInfo"
  | "edges"
  | "nodes"
  | RunStagesConnectionKeySpecifier
)[];
export type RunStagesConnectionFieldPolicy = {
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunStagesEdgeKeySpecifier = (
  | "cursor"
  | "node"
  | RunStagesEdgeKeySpecifier
)[];
export type RunStagesEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunTaskKeySpecifier = (
  | "taskType"
  | "status"
  | "priority"
  | "methodReservationPlateMetadata"
  | "folderId"
  | "runTaskId"
  | "runId"
  | "runStageId"
  | "parentSubTaskId"
  | "methodId"
  | "runTemplateTaskId"
  | "seqNo"
  | "activityName"
  | "startTimeScheduled"
  | "endTimeScheduled"
  | "startTimeActual"
  | "endTimeActual"
  | "systemAssignedTo"
  | "notes"
  | "runActivityTypeId"
  | "sourcePlateFilter"
  | "updateActionDetails"
  | "lastTimeUpdated"
  | "updatedBy"
  | "completedBy"
  | "completedByTime"
  | "manualTaskTypeId"
  | "completedByNavigation"
  | "manualTaskType"
  | "method"
  | "run"
  | "runActivityType"
  | "runNavigation"
  | "runTemplateTask"
  | "systemAssignedToNavigation"
  | "lookupTeamGanttId"
  | "runTaskDetail"
  | "methodReservations"
  | "runTaskAttachments"
  | "groups"
  | "users"
  | "daysFromRunStart"
  | RunTaskKeySpecifier
)[];
export type RunTaskFieldPolicy = {
  taskType?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  priority?: FieldPolicy<any> | FieldReadFunction<any>;
  methodReservationPlateMetadata?: FieldPolicy<any> | FieldReadFunction<any>;
  folderId?: FieldPolicy<any> | FieldReadFunction<any>;
  runTaskId?: FieldPolicy<any> | FieldReadFunction<any>;
  runId?: FieldPolicy<any> | FieldReadFunction<any>;
  runStageId?: FieldPolicy<any> | FieldReadFunction<any>;
  parentSubTaskId?: FieldPolicy<any> | FieldReadFunction<any>;
  methodId?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTaskId?: FieldPolicy<any> | FieldReadFunction<any>;
  seqNo?: FieldPolicy<any> | FieldReadFunction<any>;
  activityName?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeScheduled?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeScheduled?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeActual?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeActual?: FieldPolicy<any> | FieldReadFunction<any>;
  systemAssignedTo?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  runActivityTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  sourcePlateFilter?: FieldPolicy<any> | FieldReadFunction<any>;
  updateActionDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  lastTimeUpdated?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  completedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  completedByTime?: FieldPolicy<any> | FieldReadFunction<any>;
  manualTaskTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  completedByNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  manualTaskType?: FieldPolicy<any> | FieldReadFunction<any>;
  method?: FieldPolicy<any> | FieldReadFunction<any>;
  run?: FieldPolicy<any> | FieldReadFunction<any>;
  runActivityType?: FieldPolicy<any> | FieldReadFunction<any>;
  runNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTask?: FieldPolicy<any> | FieldReadFunction<any>;
  systemAssignedToNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupTeamGanttId?: FieldPolicy<any> | FieldReadFunction<any>;
  runTaskDetail?: FieldPolicy<any> | FieldReadFunction<any>;
  methodReservations?: FieldPolicy<any> | FieldReadFunction<any>;
  runTaskAttachments?: FieldPolicy<any> | FieldReadFunction<any>;
  groups?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
  daysFromRunStart?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunTaskAttachmentKeySpecifier = (
  | "runTaskAttachmentId"
  | "runTaskId"
  | "attachmentName"
  | "dateUploaded"
  | "uploadedBy"
  | "comments"
  | "runTask"
  | "attachmentUrl"
  | RunTaskAttachmentKeySpecifier
)[];
export type RunTaskAttachmentFieldPolicy = {
  runTaskAttachmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  runTaskId?: FieldPolicy<any> | FieldReadFunction<any>;
  attachmentName?: FieldPolicy<any> | FieldReadFunction<any>;
  dateUploaded?: FieldPolicy<any> | FieldReadFunction<any>;
  uploadedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  runTask?: FieldPolicy<any> | FieldReadFunction<any>;
  attachmentUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunTaskDetailKeySpecifier = (
  | "imageExportFormat"
  | "runTaskDetailsId"
  | "runTaskId"
  | "daughterWellNumber"
  | "seedCellCount"
  | "freezeCellCount"
  | "freezeDownRackType"
  | "createMystplate"
  | "destPlateCode"
  | "maxCryovial"
  | "minCryovial"
  | "overflowVials"
  | "imageExportSetting"
  | "worklistTemplateId"
  | "intermediateLabwareTypeId"
  | "intermediateLabwareType"
  | "runTask"
  | RunTaskDetailKeySpecifier
)[];
export type RunTaskDetailFieldPolicy = {
  imageExportFormat?: FieldPolicy<any> | FieldReadFunction<any>;
  runTaskDetailsId?: FieldPolicy<any> | FieldReadFunction<any>;
  runTaskId?: FieldPolicy<any> | FieldReadFunction<any>;
  daughterWellNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  seedCellCount?: FieldPolicy<any> | FieldReadFunction<any>;
  freezeCellCount?: FieldPolicy<any> | FieldReadFunction<any>;
  freezeDownRackType?: FieldPolicy<any> | FieldReadFunction<any>;
  createMystplate?: FieldPolicy<any> | FieldReadFunction<any>;
  destPlateCode?: FieldPolicy<any> | FieldReadFunction<any>;
  maxCryovial?: FieldPolicy<any> | FieldReadFunction<any>;
  minCryovial?: FieldPolicy<any> | FieldReadFunction<any>;
  overflowVials?: FieldPolicy<any> | FieldReadFunction<any>;
  imageExportSetting?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  intermediateLabwareTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  intermediateLabwareType?: FieldPolicy<any> | FieldReadFunction<any>;
  runTask?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunTaskTypeKeySpecifier = (
  | "type"
  | "id"
  | "name"
  | "documentationUrl"
  | "automationMethodId"
  | "compositeKey"
  | "manualTaskType"
  | "method"
  | RunTaskTypeKeySpecifier
)[];
export type RunTaskTypeFieldPolicy = {
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  documentationUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  automationMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  compositeKey?: FieldPolicy<any> | FieldReadFunction<any>;
  manualTaskType?: FieldPolicy<any> | FieldReadFunction<any>;
  method?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunTasksConnectionKeySpecifier = (
  | "pageInfo"
  | "edges"
  | "nodes"
  | "totalCount"
  | RunTasksConnectionKeySpecifier
)[];
export type RunTasksConnectionFieldPolicy = {
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunTasksEdgeKeySpecifier = (
  | "cursor"
  | "node"
  | RunTasksEdgeKeySpecifier
)[];
export type RunTasksEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunTemplateKeySpecifier = (
  | "runTemplateId"
  | "runTemplateName"
  | "productionRunType"
  | "createdBy"
  | "folderLocationId"
  | "productionRunTypeId"
  | "createdByV"
  | "lastUpdatedBy"
  | "lastTimeUpdated"
  | "createdByNavigation"
  | "folderLocation"
  | "lastUpdatedByNavigation"
  | "productionRunTypeNavigation"
  | "lookupArrayPlateTypeStrings"
  | "productionRunTypes"
  | "runTemplateStages"
  | "runTemplateTasks"
  | "runTemplateTreeNodeChildren"
  | "runTemplateTreeNodeParents"
  | "groups"
  | "numberOfSubTemplates"
  | "numberOfTasks"
  | RunTemplateKeySpecifier
)[];
export type RunTemplateFieldPolicy = {
  runTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateName?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRunType?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  folderLocationId?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRunTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByV?: FieldPolicy<any> | FieldReadFunction<any>;
  lastUpdatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  lastTimeUpdated?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  folderLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  lastUpdatedByNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRunTypeNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupArrayPlateTypeStrings?: FieldPolicy<any> | FieldReadFunction<any>;
  productionRunTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateStages?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTreeNodeChildren?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTreeNodeParents?: FieldPolicy<any> | FieldReadFunction<any>;
  groups?: FieldPolicy<any> | FieldReadFunction<any>;
  numberOfSubTemplates?: FieldPolicy<any> | FieldReadFunction<any>;
  numberOfTasks?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunTemplateOptionKeySpecifier = (
  | "runTemplateId"
  | "runTemplateName"
  | RunTemplateOptionKeySpecifier
)[];
export type RunTemplateOptionFieldPolicy = {
  runTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunTemplateStageKeySpecifier = (
  | "runTemplateStageId"
  | "runTemplateId"
  | "stageSeqNo"
  | "templateStageName"
  | "runTemplate"
  | "runTemplateTasks"
  | "runTemplateTreeNodes"
  | RunTemplateStageKeySpecifier
)[];
export type RunTemplateStageFieldPolicy = {
  runTemplateStageId?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  stageSeqNo?: FieldPolicy<any> | FieldReadFunction<any>;
  templateStageName?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTreeNodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunTemplateTaskKeySpecifier = (
  | "runTemplateTaskId"
  | "runTemplateParentTaskId"
  | "templateTaskSeqNo"
  | "runTemplateStageId"
  | "runTemplateId"
  | "customTaskName"
  | "dayScheduled"
  | "systemAssignedTo"
  | "notes"
  | "methodId"
  | "manualTaskTypeId"
  | "manualTaskType"
  | "method"
  | "runTemplate"
  | "runTemplateStage"
  | "systemAssignedToNavigation"
  | "runTemplateTaskDetail"
  | "runTasks"
  | "runTemplatesRepeatDaysOfWeeks"
  | "groups"
  | "users"
  | RunTemplateTaskKeySpecifier
)[];
export type RunTemplateTaskFieldPolicy = {
  runTemplateTaskId?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateParentTaskId?: FieldPolicy<any> | FieldReadFunction<any>;
  templateTaskSeqNo?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateStageId?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  customTaskName?: FieldPolicy<any> | FieldReadFunction<any>;
  dayScheduled?: FieldPolicy<any> | FieldReadFunction<any>;
  systemAssignedTo?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  methodId?: FieldPolicy<any> | FieldReadFunction<any>;
  manualTaskTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  manualTaskType?: FieldPolicy<any> | FieldReadFunction<any>;
  method?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateStage?: FieldPolicy<any> | FieldReadFunction<any>;
  systemAssignedToNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTaskDetail?: FieldPolicy<any> | FieldReadFunction<any>;
  runTasks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplatesRepeatDaysOfWeeks?: FieldPolicy<any> | FieldReadFunction<any>;
  groups?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunTemplateTaskDetailKeySpecifier = (
  | "imageExportFormat"
  | "runTemplateTaskDetailsId"
  | "runTemplateTaskId"
  | "daughterWellNumber"
  | "seedCellCount"
  | "freezeCellCount"
  | "freezeDownRackType"
  | "createMystplate"
  | "destPlateCode"
  | "maxCryovial"
  | "minCryovial"
  | "overflowVials"
  | "imageExportSetting"
  | "worklistTemplateId"
  | "intermediateLabwareTypeId"
  | "intermediateLabwareType"
  | "runTemplateTask"
  | RunTemplateTaskDetailKeySpecifier
)[];
export type RunTemplateTaskDetailFieldPolicy = {
  imageExportFormat?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTaskDetailsId?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTaskId?: FieldPolicy<any> | FieldReadFunction<any>;
  daughterWellNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  seedCellCount?: FieldPolicy<any> | FieldReadFunction<any>;
  freezeCellCount?: FieldPolicy<any> | FieldReadFunction<any>;
  freezeDownRackType?: FieldPolicy<any> | FieldReadFunction<any>;
  createMystplate?: FieldPolicy<any> | FieldReadFunction<any>;
  destPlateCode?: FieldPolicy<any> | FieldReadFunction<any>;
  maxCryovial?: FieldPolicy<any> | FieldReadFunction<any>;
  minCryovial?: FieldPolicy<any> | FieldReadFunction<any>;
  overflowVials?: FieldPolicy<any> | FieldReadFunction<any>;
  imageExportSetting?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  intermediateLabwareTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  intermediateLabwareType?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTask?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunTemplateTreeNodeKeySpecifier = (
  | "nodeId"
  | "parentId"
  | "childId"
  | "child"
  | "parent"
  | "stage"
  | "stageId"
  | "dayScheduled"
  | RunTemplateTreeNodeKeySpecifier
)[];
export type RunTemplateTreeNodeFieldPolicy = {
  nodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  parentId?: FieldPolicy<any> | FieldReadFunction<any>;
  childId?: FieldPolicy<any> | FieldReadFunction<any>;
  child?: FieldPolicy<any> | FieldReadFunction<any>;
  parent?: FieldPolicy<any> | FieldReadFunction<any>;
  stage?: FieldPolicy<any> | FieldReadFunction<any>;
  stageId?: FieldPolicy<any> | FieldReadFunction<any>;
  dayScheduled?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunTemplatesConnectionKeySpecifier = (
  | "pageInfo"
  | "edges"
  | "nodes"
  | "totalCount"
  | RunTemplatesConnectionKeySpecifier
)[];
export type RunTemplatesConnectionFieldPolicy = {
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunTemplatesEdgeKeySpecifier = (
  | "cursor"
  | "node"
  | RunTemplatesEdgeKeySpecifier
)[];
export type RunTemplatesEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RunTemplatesRepeatDaysOfWeekKeySpecifier = (
  | "dayOfWeek"
  | "runTemplateRepeatDayOfWeekId"
  | "runTemplateTaskId"
  | "numberOfWeeks"
  | "runTemplateTask"
  | RunTemplatesRepeatDaysOfWeekKeySpecifier
)[];
export type RunTemplatesRepeatDaysOfWeekFieldPolicy = {
  dayOfWeek?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateRepeatDayOfWeekId?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTaskId?: FieldPolicy<any> | FieldReadFunction<any>;
  numberOfWeeks?: FieldPolicy<any> | FieldReadFunction<any>;
  runTemplateTask?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SampleKeySpecifier = (
  | "sampleId"
  | "sampleBarcode"
  | "sampleBarcodeAliasOld"
  | "sampleBarcodeAliases"
  | "description"
  | "cellLineId"
  | "sampleTypeId"
  | "dateAdded"
  | "registeredBy"
  | "registeredByUserId"
  | "isActive"
  | "isVisible"
  | "passageNumberAtThaw"
  | "collectionTubeBarcode"
  | "collectionContainerType"
  | "collectionCells"
  | "mLcollectionVolume"
  | "mLplasmaVolume"
  | "mLprocessingVolume"
  | "receivedAt"
  | "processingStartedAt"
  | "processingEndedAt"
  | "isGenotyped"
  | "isKaryotyped"
  | "mycoplasmaTestDate"
  | "mycoplasmaResults"
  | "parentSampleId"
  | "secondParentSampleId"
  | "dateLastUpdated"
  | "updatedBy"
  | "temporaryCreation"
  | "createdByMappingFileId"
  | "collaboratorSampleId"
  | "idftraceId"
  | "cellLine"
  | "sampleType"
  | "containers"
  | "matricesTube"
  | "tubeBarcodes"
  | "plateBarcodes"
  | "lotNumbers"
  | SampleKeySpecifier
)[];
export type SampleFieldPolicy = {
  sampleId?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleBarcodeAliasOld?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleBarcodeAliases?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  cellLineId?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  dateAdded?: FieldPolicy<any> | FieldReadFunction<any>;
  registeredBy?: FieldPolicy<any> | FieldReadFunction<any>;
  registeredByUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  isVisible?: FieldPolicy<any> | FieldReadFunction<any>;
  passageNumberAtThaw?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionTubeBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionContainerType?: FieldPolicy<any> | FieldReadFunction<any>;
  collectionCells?: FieldPolicy<any> | FieldReadFunction<any>;
  mLcollectionVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  mLplasmaVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  mLprocessingVolume?: FieldPolicy<any> | FieldReadFunction<any>;
  receivedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  processingStartedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  processingEndedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  isGenotyped?: FieldPolicy<any> | FieldReadFunction<any>;
  isKaryotyped?: FieldPolicy<any> | FieldReadFunction<any>;
  mycoplasmaTestDate?: FieldPolicy<any> | FieldReadFunction<any>;
  mycoplasmaResults?: FieldPolicy<any> | FieldReadFunction<any>;
  parentSampleId?: FieldPolicy<any> | FieldReadFunction<any>;
  secondParentSampleId?: FieldPolicy<any> | FieldReadFunction<any>;
  dateLastUpdated?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  temporaryCreation?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByMappingFileId?: FieldPolicy<any> | FieldReadFunction<any>;
  collaboratorSampleId?: FieldPolicy<any> | FieldReadFunction<any>;
  idftraceId?: FieldPolicy<any> | FieldReadFunction<any>;
  cellLine?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleType?: FieldPolicy<any> | FieldReadFunction<any>;
  containers?: FieldPolicy<any> | FieldReadFunction<any>;
  matricesTube?: FieldPolicy<any> | FieldReadFunction<any>;
  tubeBarcodes?: FieldPolicy<any> | FieldReadFunction<any>;
  plateBarcodes?: FieldPolicy<any> | FieldReadFunction<any>;
  lotNumbers?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SampleTypeCollectionTaskKeySpecifier = (
  | "id"
  | "sampleTypeId"
  | "taskName"
  | "seqNo"
  | "day"
  | "sampleType"
  | SampleTypeCollectionTaskKeySpecifier
)[];
export type SampleTypeCollectionTaskFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  taskName?: FieldPolicy<any> | FieldReadFunction<any>;
  seqNo?: FieldPolicy<any> | FieldReadFunction<any>;
  day?: FieldPolicy<any> | FieldReadFunction<any>;
  sampleType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SystemOneFeedPlatesKeySpecifier = (
  | "plateID"
  | "plateBarcode"
  | "activeWellCount"
  | SystemOneFeedPlatesKeySpecifier
)[];
export type SystemOneFeedPlatesFieldPolicy = {
  plateID?: FieldPolicy<any> | FieldReadFunction<any>;
  plateBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  activeWellCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SystemOneFeedRunsKeySpecifier = (
  | "runName"
  | "plates"
  | "reason"
  | SystemOneFeedRunsKeySpecifier
)[];
export type SystemOneFeedRunsFieldPolicy = {
  runName?: FieldPolicy<any> | FieldReadFunction<any>;
  plates?: FieldPolicy<any> | FieldReadFunction<any>;
  reason?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SystemOneFeedWorklistKeySpecifier = (
  | "methodReservationID"
  | "worklistID"
  | SystemOneFeedWorklistKeySpecifier
)[];
export type SystemOneFeedWorklistFieldPolicy = {
  methodReservationID?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistID?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SystemTypeKeySpecifier = (
  | "systemTypeId"
  | "systemType1"
  | "lookupArraySystems"
  | SystemTypeKeySpecifier
)[];
export type SystemTypeFieldPolicy = {
  systemTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  systemType1?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupArraySystems?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TaskAttachmentKeySpecifier = (
  | "taskAttachmentId"
  | "taskId"
  | "attachmentName"
  | "attachmentFile"
  | "dateUploaded"
  | "uploadedBy"
  | "comments"
  | "task"
  | TaskAttachmentKeySpecifier
)[];
export type TaskAttachmentFieldPolicy = {
  taskAttachmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  taskId?: FieldPolicy<any> | FieldReadFunction<any>;
  attachmentName?: FieldPolicy<any> | FieldReadFunction<any>;
  attachmentFile?: FieldPolicy<any> | FieldReadFunction<any>;
  dateUploaded?: FieldPolicy<any> | FieldReadFunction<any>;
  uploadedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  task?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TaskTypeKeySpecifier = (
  | "taskTypeId"
  | "taskType1"
  | "webSuiteTaskLogs"
  | TaskTypeKeySpecifier
)[];
export type TaskTypeFieldPolicy = {
  taskTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  taskType1?: FieldPolicy<any> | FieldReadFunction<any>;
  webSuiteTaskLogs?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketKeySpecifier = (
  | "status"
  | "lastUpdated"
  | "ticketId"
  | "ticketHeader"
  | "title"
  | "dateCreated"
  | "createdByUserId"
  | "description"
  | "assetId"
  | "dateEncountered"
  | "percentComplete"
  | "requestedCompletionDate"
  | "estimatedCompletionDays"
  | "actualCompletionDays"
  | "estimatedCompletionDate"
  | "isActive"
  | "ticketTypeId"
  | "mergedIntoTicketId"
  | "exceptionId"
  | "computerName"
  | "slackMessageTimestamp"
  | "githubId"
  | "asset"
  | "ticketType"
  | "ticketAssignees"
  | "ticketAttachments"
  | "ticketFollowers"
  | "ticketUpdates"
  | "priority"
  | TicketKeySpecifier
)[];
export type TicketFieldPolicy = {
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  lastUpdated?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketId?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketHeader?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  dateCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  assetId?: FieldPolicy<any> | FieldReadFunction<any>;
  dateEncountered?: FieldPolicy<any> | FieldReadFunction<any>;
  percentComplete?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedCompletionDate?: FieldPolicy<any> | FieldReadFunction<any>;
  estimatedCompletionDays?: FieldPolicy<any> | FieldReadFunction<any>;
  actualCompletionDays?: FieldPolicy<any> | FieldReadFunction<any>;
  estimatedCompletionDate?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  mergedIntoTicketId?: FieldPolicy<any> | FieldReadFunction<any>;
  exceptionId?: FieldPolicy<any> | FieldReadFunction<any>;
  computerName?: FieldPolicy<any> | FieldReadFunction<any>;
  slackMessageTimestamp?: FieldPolicy<any> | FieldReadFunction<any>;
  githubId?: FieldPolicy<any> | FieldReadFunction<any>;
  asset?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketType?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketAssignees?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketAttachments?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketFollowers?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketUpdates?: FieldPolicy<any> | FieldReadFunction<any>;
  priority?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketAssigneeKeySpecifier = (
  | "ticketAssigneeId"
  | "ticketId"
  | "userId"
  | "dateAssigned"
  | "ticketAccepted"
  | "dateAccepted"
  | "ticket"
  | "user"
  | TicketAssigneeKeySpecifier
)[];
export type TicketAssigneeFieldPolicy = {
  ticketAssigneeId?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketId?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  dateAssigned?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketAccepted?: FieldPolicy<any> | FieldReadFunction<any>;
  dateAccepted?: FieldPolicy<any> | FieldReadFunction<any>;
  ticket?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketAttachmentKeySpecifier = (
  | "ticketAttachmentId"
  | "ticketId"
  | "attachmentName"
  | "dateUploaded"
  | "uploadedBy"
  | "comments"
  | "slackAttachmentUrl"
  | "slackPrivateUrl"
  | "ticket"
  | "attachmentUrl"
  | TicketAttachmentKeySpecifier
)[];
export type TicketAttachmentFieldPolicy = {
  ticketAttachmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketId?: FieldPolicy<any> | FieldReadFunction<any>;
  attachmentName?: FieldPolicy<any> | FieldReadFunction<any>;
  dateUploaded?: FieldPolicy<any> | FieldReadFunction<any>;
  uploadedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  slackAttachmentUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  slackPrivateUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  ticket?: FieldPolicy<any> | FieldReadFunction<any>;
  attachmentUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketFollowerKeySpecifier = (
  | "ticketFollowerId"
  | "ticketId"
  | "userId"
  | "ticket"
  | "user"
  | TicketFollowerKeySpecifier
)[];
export type TicketFollowerFieldPolicy = {
  ticketFollowerId?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketId?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  ticket?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketUpdateKeySpecifier = (
  | "status"
  | "ticketUpdateId"
  | "ticketId"
  | "summary"
  | "dateUpdated"
  | "updatedByUserId"
  | "percentComplete"
  | "ticket"
  | "updatedByUser"
  | TicketUpdateKeySpecifier
)[];
export type TicketUpdateFieldPolicy = {
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketUpdateId?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketId?: FieldPolicy<any> | FieldReadFunction<any>;
  summary?: FieldPolicy<any> | FieldReadFunction<any>;
  dateUpdated?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  percentComplete?: FieldPolicy<any> | FieldReadFunction<any>;
  ticket?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByUser?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsConnectionKeySpecifier = (
  | "pageInfo"
  | "edges"
  | "nodes"
  | "totalCount"
  | TicketsConnectionKeySpecifier
)[];
export type TicketsConnectionFieldPolicy = {
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsEdgeKeySpecifier = (
  | "cursor"
  | "node"
  | TicketsEdgeKeySpecifier
)[];
export type TicketsEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TubeBarcodeFileKeySpecifier = (
  | "tubeBarcodeFileId"
  | "fileName"
  | "plateId"
  | "dateCreated"
  | "dateUploaded"
  | "computerName"
  | "isActive"
  | "fileUploaded"
  | "plate"
  | TubeBarcodeFileKeySpecifier
)[];
export type TubeBarcodeFileFieldPolicy = {
  tubeBarcodeFileId?: FieldPolicy<any> | FieldReadFunction<any>;
  fileName?: FieldPolicy<any> | FieldReadFunction<any>;
  plateId?: FieldPolicy<any> | FieldReadFunction<any>;
  dateCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  dateUploaded?: FieldPolicy<any> | FieldReadFunction<any>;
  computerName?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  fileUploaded?: FieldPolicy<any> | FieldReadFunction<any>;
  plate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TubeLabellerDestinationWorklistKeySpecifier = (
  | "dSTRack"
  | "dSTPosition"
  | "sRCRack"
  | "sRCPosition"
  | "dSTRackBarcode"
  | "dSTLinePosition"
  | "factoryBarcode"
  | "field1"
  | "field2"
  | "field3"
  | "field4"
  | "field5"
  | "field6"
  | "field7"
  | TubeLabellerDestinationWorklistKeySpecifier
)[];
export type TubeLabellerDestinationWorklistFieldPolicy = {
  dSTRack?: FieldPolicy<any> | FieldReadFunction<any>;
  dSTPosition?: FieldPolicy<any> | FieldReadFunction<any>;
  sRCRack?: FieldPolicy<any> | FieldReadFunction<any>;
  sRCPosition?: FieldPolicy<any> | FieldReadFunction<any>;
  dSTRackBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  dSTLinePosition?: FieldPolicy<any> | FieldReadFunction<any>;
  factoryBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  field1?: FieldPolicy<any> | FieldReadFunction<any>;
  field2?: FieldPolicy<any> | FieldReadFunction<any>;
  field3?: FieldPolicy<any> | FieldReadFunction<any>;
  field4?: FieldPolicy<any> | FieldReadFunction<any>;
  field5?: FieldPolicy<any> | FieldReadFunction<any>;
  field6?: FieldPolicy<any> | FieldReadFunction<any>;
  field7?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UploadIdfFormResponseKeySpecifier = (
  | "success"
  | "traceID"
  | "message"
  | "errors"
  | UploadIdfFormResponseKeySpecifier
)[];
export type UploadIdfFormResponseFieldPolicy = {
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  traceID?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  errors?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserKeySpecifier = (
  | "userName"
  | "userId"
  | "email"
  | "isAdmin"
  | UserKeySpecifier
)[];
export type UserFieldPolicy = {
  userName?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  isAdmin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserClaimsKeySpecifier = (
  | "isGroupAccount"
  | "loggedInGroupMember"
  | "groupAccount"
  | "setting"
  | "userName"
  | "userId"
  | "email"
  | "isAdmin"
  | UserClaimsKeySpecifier
)[];
export type UserClaimsFieldPolicy = {
  isGroupAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  loggedInGroupMember?: FieldPolicy<any> | FieldReadFunction<any>;
  groupAccount?: FieldPolicy<any> | FieldReadFunction<any>;
  setting?: FieldPolicy<any> | FieldReadFunction<any>;
  userName?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  isAdmin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserSettingKeySpecifier = (
  | "id"
  | "userId"
  | "settingsJson"
  | "user"
  | UserSettingKeySpecifier
)[];
export type UserSettingFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  settingsJson?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserToRoleKeySpecifier = (
  | "userId"
  | "roleId"
  | "user"
  | UserToRoleKeySpecifier
)[];
export type UserToRoleFieldPolicy = {
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  roleId?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WebSuiteTaskLogKeySpecifier = (
  | "taskLogId"
  | "taskParentId"
  | "taskId"
  | "taskType"
  | "actionPerformed"
  | "actionPerformedTime"
  | "actionPerformedBy"
  | "taskTypeId"
  | "taskTypeNavigation"
  | WebSuiteTaskLogKeySpecifier
)[];
export type WebSuiteTaskLogFieldPolicy = {
  taskLogId?: FieldPolicy<any> | FieldReadFunction<any>;
  taskParentId?: FieldPolicy<any> | FieldReadFunction<any>;
  taskId?: FieldPolicy<any> | FieldReadFunction<any>;
  taskType?: FieldPolicy<any> | FieldReadFunction<any>;
  actionPerformed?: FieldPolicy<any> | FieldReadFunction<any>;
  actionPerformedTime?: FieldPolicy<any> | FieldReadFunction<any>;
  actionPerformedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  taskTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  taskTypeNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WorklistKeySpecifier = (
  | "worklistId"
  | "systemId"
  | "methodId"
  | "passageTemplateId"
  | "dateCreated"
  | "createdBy"
  | "methodReservationId"
  | "fileName"
  | "method"
  | "methodReservation"
  | "system"
  | "worklistContentImagings"
  | "worklistContents"
  | WorklistKeySpecifier
)[];
export type WorklistFieldPolicy = {
  worklistId?: FieldPolicy<any> | FieldReadFunction<any>;
  systemId?: FieldPolicy<any> | FieldReadFunction<any>;
  methodId?: FieldPolicy<any> | FieldReadFunction<any>;
  passageTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  dateCreated?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  methodReservationId?: FieldPolicy<any> | FieldReadFunction<any>;
  fileName?: FieldPolicy<any> | FieldReadFunction<any>;
  method?: FieldPolicy<any> | FieldReadFunction<any>;
  methodReservation?: FieldPolicy<any> | FieldReadFunction<any>;
  system?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistContentImagings?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistContents?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WorklistCherryPickModeKeySpecifier = (
  | "worklistCherryPickModeId"
  | "taskKeyword"
  | "detailExpression"
  | "populateSourcePlate"
  | "populateSourceWell"
  | "populateDestinationPlate"
  | "populateDestinationWell"
  | "sortWellsColumnWise"
  | "lookupArrayMethods"
  | WorklistCherryPickModeKeySpecifier
)[];
export type WorklistCherryPickModeFieldPolicy = {
  worklistCherryPickModeId?: FieldPolicy<any> | FieldReadFunction<any>;
  taskKeyword?: FieldPolicy<any> | FieldReadFunction<any>;
  detailExpression?: FieldPolicy<any> | FieldReadFunction<any>;
  populateSourcePlate?: FieldPolicy<any> | FieldReadFunction<any>;
  populateSourceWell?: FieldPolicy<any> | FieldReadFunction<any>;
  populateDestinationPlate?: FieldPolicy<any> | FieldReadFunction<any>;
  populateDestinationWell?: FieldPolicy<any> | FieldReadFunction<any>;
  sortWellsColumnWise?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupArrayMethods?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WorklistColumnKeySpecifier = (
  | "worklistColumnId"
  | "worklistColumn1"
  | "arrayMethodPlates"
  | "lookupArrayMethodPlateRoles"
  | WorklistColumnKeySpecifier
)[];
export type WorklistColumnFieldPolicy = {
  worklistColumnId?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistColumn1?: FieldPolicy<any> | FieldReadFunction<any>;
  arrayMethodPlates?: FieldPolicy<any> | FieldReadFunction<any>;
  lookupArrayMethodPlateRoles?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WorklistContentKeySpecifier = (
  | "worklistContentId"
  | "worklistId"
  | "task"
  | "details"
  | "aspirateVol"
  | "dispenseVol"
  | "transferVol"
  | "sourcePlateType"
  | "sourcePlateBarcode"
  | "sourceWellId"
  | "destinationPlateType"
  | "destinationPlateBarcode"
  | "destinationWellId"
  | "rowNumber"
  | "worklist"
  | WorklistContentKeySpecifier
)[];
export type WorklistContentFieldPolicy = {
  worklistContentId?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistId?: FieldPolicy<any> | FieldReadFunction<any>;
  task?: FieldPolicy<any> | FieldReadFunction<any>;
  details?: FieldPolicy<any> | FieldReadFunction<any>;
  aspirateVol?: FieldPolicy<any> | FieldReadFunction<any>;
  dispenseVol?: FieldPolicy<any> | FieldReadFunction<any>;
  transferVol?: FieldPolicy<any> | FieldReadFunction<any>;
  sourcePlateType?: FieldPolicy<any> | FieldReadFunction<any>;
  sourcePlateBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  sourceWellId?: FieldPolicy<any> | FieldReadFunction<any>;
  destinationPlateType?: FieldPolicy<any> | FieldReadFunction<any>;
  destinationPlateBarcode?: FieldPolicy<any> | FieldReadFunction<any>;
  destinationWellId?: FieldPolicy<any> | FieldReadFunction<any>;
  rowNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  worklist?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WorklistContentImagingKeySpecifier = (
  | "imageExportFormat"
  | "worklistContentImagingId"
  | "worklistId"
  | "barcode"
  | "plateType"
  | "activeWells"
  | "platePitch"
  | "experimentSetting"
  | "analysisSetting"
  | "imageExportSetting"
  | "worklist"
  | WorklistContentImagingKeySpecifier
)[];
export type WorklistContentImagingFieldPolicy = {
  imageExportFormat?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistContentImagingId?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistId?: FieldPolicy<any> | FieldReadFunction<any>;
  barcode?: FieldPolicy<any> | FieldReadFunction<any>;
  plateType?: FieldPolicy<any> | FieldReadFunction<any>;
  activeWells?: FieldPolicy<any> | FieldReadFunction<any>;
  platePitch?: FieldPolicy<any> | FieldReadFunction<any>;
  experimentSetting?: FieldPolicy<any> | FieldReadFunction<any>;
  analysisSetting?: FieldPolicy<any> | FieldReadFunction<any>;
  imageExportSetting?: FieldPolicy<any> | FieldReadFunction<any>;
  worklist?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WorklistToolTemplateKeySpecifier = (
  | "worklistToolTemplateId"
  | "methodId"
  | "templateName"
  | "createdBy"
  | "lastUpdatedBy"
  | "lastUpdateByTime"
  | "createdByNavigation"
  | "method"
  | "worklistToolTemplateContents"
  | "worklistToolTemplatePlates"
  | WorklistToolTemplateKeySpecifier
)[];
export type WorklistToolTemplateFieldPolicy = {
  worklistToolTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  methodId?: FieldPolicy<any> | FieldReadFunction<any>;
  templateName?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  lastUpdatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  lastUpdateByTime?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByNavigation?: FieldPolicy<any> | FieldReadFunction<any>;
  method?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistToolTemplateContents?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistToolTemplatePlates?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WorklistToolTemplateContentKeySpecifier = (
  | "worklistToolTemplateContentsId"
  | "worklistToolTemplateId"
  | "task"
  | "details"
  | "aspirateVol"
  | "dispenseVol"
  | "transferVol"
  | "sourcePlateType"
  | "sourcePlatePosition"
  | "sourceWellId"
  | "destinationPlateType"
  | "destinationPlatePosition"
  | "destinationWellId"
  | "worklistToolTemplate"
  | WorklistToolTemplateContentKeySpecifier
)[];
export type WorklistToolTemplateContentFieldPolicy = {
  worklistToolTemplateContentsId?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistToolTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  task?: FieldPolicy<any> | FieldReadFunction<any>;
  details?: FieldPolicy<any> | FieldReadFunction<any>;
  aspirateVol?: FieldPolicy<any> | FieldReadFunction<any>;
  dispenseVol?: FieldPolicy<any> | FieldReadFunction<any>;
  transferVol?: FieldPolicy<any> | FieldReadFunction<any>;
  sourcePlateType?: FieldPolicy<any> | FieldReadFunction<any>;
  sourcePlatePosition?: FieldPolicy<any> | FieldReadFunction<any>;
  sourceWellId?: FieldPolicy<any> | FieldReadFunction<any>;
  destinationPlateType?: FieldPolicy<any> | FieldReadFunction<any>;
  destinationPlatePosition?: FieldPolicy<any> | FieldReadFunction<any>;
  destinationWellId?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistToolTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WorklistToolTemplatePlateKeySpecifier = (
  | "worklistToolTemplatePlateId"
  | "worklistToolTemplateId"
  | "plateType"
  | "plateIndex"
  | "labwareTypeCode"
  | "numberOfRows"
  | "numberOfColumns"
  | "operatingVol"
  | "resuspensionVol"
  | "startingVol"
  | "preprocess"
  | "topup"
  | "worklistToolTemplate"
  | WorklistToolTemplatePlateKeySpecifier
)[];
export type WorklistToolTemplatePlateFieldPolicy = {
  worklistToolTemplatePlateId?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistToolTemplateId?: FieldPolicy<any> | FieldReadFunction<any>;
  plateType?: FieldPolicy<any> | FieldReadFunction<any>;
  plateIndex?: FieldPolicy<any> | FieldReadFunction<any>;
  labwareTypeCode?: FieldPolicy<any> | FieldReadFunction<any>;
  numberOfRows?: FieldPolicy<any> | FieldReadFunction<any>;
  numberOfColumns?: FieldPolicy<any> | FieldReadFunction<any>;
  operatingVol?: FieldPolicy<any> | FieldReadFunction<any>;
  resuspensionVol?: FieldPolicy<any> | FieldReadFunction<any>;
  startingVol?: FieldPolicy<any> | FieldReadFunction<any>;
  preprocess?: FieldPolicy<any> | FieldReadFunction<any>;
  topup?: FieldPolicy<any> | FieldReadFunction<any>;
  worklistToolTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TypedTypePolicies = TypePolicies & {
  AppSuiteGroup?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | AppSuiteGroupKeySpecifier
      | (() => undefined | AppSuiteGroupKeySpecifier);
    fields?: AppSuiteGroupFieldPolicy;
  };
  AppSuiteGroupUser?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | AppSuiteGroupUserKeySpecifier
      | (() => undefined | AppSuiteGroupUserKeySpecifier);
    fields?: AppSuiteGroupUserFieldPolicy;
  };
  AppSuiteUser?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | AppSuiteUserKeySpecifier
      | (() => undefined | AppSuiteUserKeySpecifier);
    fields?: AppSuiteUserFieldPolicy;
  };
  ArrayMethodLabwareType?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ArrayMethodLabwareTypeKeySpecifier
      | (() => undefined | ArrayMethodLabwareTypeKeySpecifier);
    fields?: ArrayMethodLabwareTypeFieldPolicy;
  };
  ArrayMethodPlate?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ArrayMethodPlateKeySpecifier
      | (() => undefined | ArrayMethodPlateKeySpecifier);
    fields?: ArrayMethodPlateFieldPolicy;
  };
  ArraySystemAutomationMethod?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ArraySystemAutomationMethodKeySpecifier
      | (() => undefined | ArraySystemAutomationMethodKeySpecifier);
    fields?: ArraySystemAutomationMethodFieldPolicy;
  };
  ArraySystemAutomationMethodLabwareType?: Omit<
    TypePolicy,
    "fields" | "keyFields"
  > & {
    keyFields?:
      | false
      | ArraySystemAutomationMethodLabwareTypeKeySpecifier
      | (() => undefined | ArraySystemAutomationMethodLabwareTypeKeySpecifier);
    fields?: ArraySystemAutomationMethodLabwareTypeFieldPolicy;
  };
  ArraySystemAutomationMethodProcess?: Omit<
    TypePolicy,
    "fields" | "keyFields"
  > & {
    keyFields?:
      | false
      | ArraySystemAutomationMethodProcessKeySpecifier
      | (() => undefined | ArraySystemAutomationMethodProcessKeySpecifier);
    fields?: ArraySystemAutomationMethodProcessFieldPolicy;
  };
  ArraySystemAutomationMethodProcessRun?: Omit<
    TypePolicy,
    "fields" | "keyFields"
  > & {
    keyFields?:
      | false
      | ArraySystemAutomationMethodProcessRunKeySpecifier
      | (() => undefined | ArraySystemAutomationMethodProcessRunKeySpecifier);
    fields?: ArraySystemAutomationMethodProcessRunFieldPolicy;
  };
  ArraySystemAutomationMethodProcessRunStep?: Omit<
    TypePolicy,
    "fields" | "keyFields"
  > & {
    keyFields?:
      | false
      | ArraySystemAutomationMethodProcessRunStepKeySpecifier
      | (() =>
          | undefined
          | ArraySystemAutomationMethodProcessRunStepKeySpecifier);
    fields?: ArraySystemAutomationMethodProcessRunStepFieldPolicy;
  };
  ArraySystemAutomationMethodProcessStep?: Omit<
    TypePolicy,
    "fields" | "keyFields"
  > & {
    keyFields?:
      | false
      | ArraySystemAutomationMethodProcessStepKeySpecifier
      | (() => undefined | ArraySystemAutomationMethodProcessStepKeySpecifier);
    fields?: ArraySystemAutomationMethodProcessStepFieldPolicy;
  };
  CalendarNode?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | CalendarNodeKeySpecifier
      | (() => undefined | CalendarNodeKeySpecifier);
    fields?: CalendarNodeFieldPolicy;
  };
  CellLine?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | CellLineKeySpecifier
      | (() => undefined | CellLineKeySpecifier);
    fields?: CellLineFieldPolicy;
  };
  Cluster?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ClusterKeySpecifier
      | (() => undefined | ClusterKeySpecifier);
    fields?: ClusterFieldPolicy;
  };
  CoatedPlateBatch?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | CoatedPlateBatchKeySpecifier
      | (() => undefined | CoatedPlateBatchKeySpecifier);
    fields?: CoatedPlateBatchFieldPolicy;
  };
  Collection?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | CollectionKeySpecifier
      | (() => undefined | CollectionKeySpecifier);
    fields?: CollectionFieldPolicy;
  };
  CollectionSampleType?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | CollectionSampleTypeKeySpecifier
      | (() => undefined | CollectionSampleTypeKeySpecifier);
    fields?: CollectionSampleTypeFieldPolicy;
  };
  CollectionTask?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | CollectionTaskKeySpecifier
      | (() => undefined | CollectionTaskKeySpecifier);
    fields?: CollectionTaskFieldPolicy;
  };
  Container?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ContainerKeySpecifier
      | (() => undefined | ContainerKeySpecifier);
    fields?: ContainerFieldPolicy;
  };
  ContainerComment?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ContainerCommentKeySpecifier
      | (() => undefined | ContainerCommentKeySpecifier);
    fields?: ContainerCommentFieldPolicy;
  };
  ContainerLineage?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ContainerLineageKeySpecifier
      | (() => undefined | ContainerLineageKeySpecifier);
    fields?: ContainerLineageFieldPolicy;
  };
  ContainerMarking?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ContainerMarkingKeySpecifier
      | (() => undefined | ContainerMarkingKeySpecifier);
    fields?: ContainerMarkingFieldPolicy;
  };
  ContainerMetaData?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ContainerMetaDataKeySpecifier
      | (() => undefined | ContainerMetaDataKeySpecifier);
    fields?: ContainerMetaDataFieldPolicy;
  };
  ContainerNote?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ContainerNoteKeySpecifier
      | (() => undefined | ContainerNoteKeySpecifier);
    fields?: ContainerNoteFieldPolicy;
  };
  ContainersConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ContainersConnectionKeySpecifier
      | (() => undefined | ContainersConnectionKeySpecifier);
    fields?: ContainersConnectionFieldPolicy;
  };
  ContainersEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ContainersEdgeKeySpecifier
      | (() => undefined | ContainersEdgeKeySpecifier);
    fields?: ContainersEdgeFieldPolicy;
  };
  CreateRunDefaults?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | CreateRunDefaultsKeySpecifier
      | (() => undefined | CreateRunDefaultsKeySpecifier);
    fields?: CreateRunDefaultsFieldPolicy;
  };
  CreateRunFolderPayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | CreateRunFolderPayloadKeySpecifier
      | (() => undefined | CreateRunFolderPayloadKeySpecifier);
    fields?: CreateRunFolderPayloadFieldPolicy;
  };
  Cytomat?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | CytomatKeySpecifier
      | (() => undefined | CytomatKeySpecifier);
    fields?: CytomatFieldPolicy;
  };
  CytomatPosition?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | CytomatPositionKeySpecifier
      | (() => undefined | CytomatPositionKeySpecifier);
    fields?: CytomatPositionFieldPolicy;
  };
  CytomatWarning?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | CytomatWarningKeySpecifier
      | (() => undefined | CytomatWarningKeySpecifier);
    fields?: CytomatWarningFieldPolicy;
  };
  DashboardTask?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | DashboardTaskKeySpecifier
      | (() => undefined | DashboardTaskKeySpecifier);
    fields?: DashboardTaskFieldPolicy;
  };
  DirectoryFolder?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | DirectoryFolderKeySpecifier
      | (() => undefined | DirectoryFolderKeySpecifier);
    fields?: DirectoryFolderFieldPolicy;
  };
  Donor?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | DonorKeySpecifier
      | (() => undefined | DonorKeySpecifier);
    fields?: DonorFieldPolicy;
  };
  DonorComment?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | DonorCommentKeySpecifier
      | (() => undefined | DonorCommentKeySpecifier);
    fields?: DonorCommentFieldPolicy;
  };
  DonorMedicalCondition?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | DonorMedicalConditionKeySpecifier
      | (() => undefined | DonorMedicalConditionKeySpecifier);
    fields?: DonorMedicalConditionFieldPolicy;
  };
  FeedGroup?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | FeedGroupKeySpecifier
      | (() => undefined | FeedGroupKeySpecifier);
    fields?: FeedGroupFieldPolicy;
  };
  FolderStructure?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | FolderStructureKeySpecifier
      | (() => undefined | FolderStructureKeySpecifier);
    fields?: FolderStructureFieldPolicy;
  };
  GithubProject?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | GithubProjectKeySpecifier
      | (() => undefined | GithubProjectKeySpecifier);
    fields?: GithubProjectFieldPolicy;
  };
  GroupLoginState?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | GroupLoginStateKeySpecifier
      | (() => undefined | GroupLoginStateKeySpecifier);
    fields?: GroupLoginStateFieldPolicy;
  };
  GroupedPlateMetadata?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | GroupedPlateMetadataKeySpecifier
      | (() => undefined | GroupedPlateMetadataKeySpecifier);
    fields?: GroupedPlateMetadataFieldPolicy;
  };
  IncomingSampleCollectionsConnection?: Omit<
    TypePolicy,
    "fields" | "keyFields"
  > & {
    keyFields?:
      | false
      | IncomingSampleCollectionsConnectionKeySpecifier
      | (() => undefined | IncomingSampleCollectionsConnectionKeySpecifier);
    fields?: IncomingSampleCollectionsConnectionFieldPolicy;
  };
  IncomingSampleCollectionsEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | IncomingSampleCollectionsEdgeKeySpecifier
      | (() => undefined | IncomingSampleCollectionsEdgeKeySpecifier);
    fields?: IncomingSampleCollectionsEdgeFieldPolicy;
  };
  IncomingSampleTasksConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | IncomingSampleTasksConnectionKeySpecifier
      | (() => undefined | IncomingSampleTasksConnectionKeySpecifier);
    fields?: IncomingSampleTasksConnectionFieldPolicy;
  };
  IncomingSampleTasksEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | IncomingSampleTasksEdgeKeySpecifier
      | (() => undefined | IncomingSampleTasksEdgeKeySpecifier);
    fields?: IncomingSampleTasksEdgeFieldPolicy;
  };
  LabComputer?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LabComputerKeySpecifier
      | (() => undefined | LabComputerKeySpecifier);
    fields?: LabComputerFieldPolicy;
  };
  LogResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LogResponseKeySpecifier
      | (() => undefined | LogResponseKeySpecifier);
    fields?: LogResponseFieldPolicy;
  };
  LoggedInGroupMember?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LoggedInGroupMemberKeySpecifier
      | (() => undefined | LoggedInGroupMemberKeySpecifier);
    fields?: LoggedInGroupMemberFieldPolicy;
  };
  LookupArrayMethod?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupArrayMethodKeySpecifier
      | (() => undefined | LookupArrayMethodKeySpecifier);
    fields?: LookupArrayMethodFieldPolicy;
  };
  LookupArrayMethodGroup?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupArrayMethodGroupKeySpecifier
      | (() => undefined | LookupArrayMethodGroupKeySpecifier);
    fields?: LookupArrayMethodGroupFieldPolicy;
  };
  LookupArrayMethodPlateRole?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupArrayMethodPlateRoleKeySpecifier
      | (() => undefined | LookupArrayMethodPlateRoleKeySpecifier);
    fields?: LookupArrayMethodPlateRoleFieldPolicy;
  };
  LookupArrayMethodType?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupArrayMethodTypeKeySpecifier
      | (() => undefined | LookupArrayMethodTypeKeySpecifier);
    fields?: LookupArrayMethodTypeFieldPolicy;
  };
  LookupArrayPlateCode?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupArrayPlateCodeKeySpecifier
      | (() => undefined | LookupArrayPlateCodeKeySpecifier);
    fields?: LookupArrayPlateCodeFieldPolicy;
  };
  LookupArrayPlateTypeString?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupArrayPlateTypeStringKeySpecifier
      | (() => undefined | LookupArrayPlateTypeStringKeySpecifier);
    fields?: LookupArrayPlateTypeStringFieldPolicy;
  };
  LookupArraySystem?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupArraySystemKeySpecifier
      | (() => undefined | LookupArraySystemKeySpecifier);
    fields?: LookupArraySystemFieldPolicy;
  };
  LookupAsset?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupAssetKeySpecifier
      | (() => undefined | LookupAssetKeySpecifier);
    fields?: LookupAssetFieldPolicy;
  };
  LookupAssetType?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupAssetTypeKeySpecifier
      | (() => undefined | LookupAssetTypeKeySpecifier);
    fields?: LookupAssetTypeFieldPolicy;
  };
  LookupAutomationMethod?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupAutomationMethodKeySpecifier
      | (() => undefined | LookupAutomationMethodKeySpecifier);
    fields?: LookupAutomationMethodFieldPolicy;
  };
  LookupAutomationMethodProcessRunStatus?: Omit<
    TypePolicy,
    "fields" | "keyFields"
  > & {
    keyFields?:
      | false
      | LookupAutomationMethodProcessRunStatusKeySpecifier
      | (() => undefined | LookupAutomationMethodProcessRunStatusKeySpecifier);
    fields?: LookupAutomationMethodProcessRunStatusFieldPolicy;
  };
  LookupAutomationMethodProcessRunType?: Omit<
    TypePolicy,
    "fields" | "keyFields"
  > & {
    keyFields?:
      | false
      | LookupAutomationMethodProcessRunTypeKeySpecifier
      | (() => undefined | LookupAutomationMethodProcessRunTypeKeySpecifier);
    fields?: LookupAutomationMethodProcessRunTypeFieldPolicy;
  };
  LookupContainerMarkingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupContainerMarkingTypeKeySpecifier
      | (() => undefined | LookupContainerMarkingTypeKeySpecifier);
    fields?: LookupContainerMarkingTypeFieldPolicy;
  };
  LookupExperimentSetting?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupExperimentSettingKeySpecifier
      | (() => undefined | LookupExperimentSettingKeySpecifier);
    fields?: LookupExperimentSettingFieldPolicy;
  };
  LookupInactivationReason?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupInactivationReasonKeySpecifier
      | (() => undefined | LookupInactivationReasonKeySpecifier);
    fields?: LookupInactivationReasonFieldPolicy;
  };
  LookupLabwareType?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupLabwareTypeKeySpecifier
      | (() => undefined | LookupLabwareTypeKeySpecifier);
    fields?: LookupLabwareTypeFieldPolicy;
  };
  LookupLotNumberType?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupLotNumberTypeKeySpecifier
      | (() => undefined | LookupLotNumberTypeKeySpecifier);
    fields?: LookupLotNumberTypeFieldPolicy;
  };
  LookupMedicalCondition?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupMedicalConditionKeySpecifier
      | (() => undefined | LookupMedicalConditionKeySpecifier);
    fields?: LookupMedicalConditionFieldPolicy;
  };
  LookupRunActivityType?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupRunActivityTypeKeySpecifier
      | (() => undefined | LookupRunActivityTypeKeySpecifier);
    fields?: LookupRunActivityTypeFieldPolicy;
  };
  LookupSampleCollectionType?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupSampleCollectionTypeKeySpecifier
      | (() => undefined | LookupSampleCollectionTypeKeySpecifier);
    fields?: LookupSampleCollectionTypeFieldPolicy;
  };
  LookupSampleType?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupSampleTypeKeySpecifier
      | (() => undefined | LookupSampleTypeKeySpecifier);
    fields?: LookupSampleTypeFieldPolicy;
  };
  LookupTeamGanttId?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupTeamGanttIdKeySpecifier
      | (() => undefined | LookupTeamGanttIdKeySpecifier);
    fields?: LookupTeamGanttIdFieldPolicy;
  };
  LookupTemplateTask?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupTemplateTaskKeySpecifier
      | (() => undefined | LookupTemplateTaskKeySpecifier);
    fields?: LookupTemplateTaskFieldPolicy;
  };
  LookupTicketType?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LookupTicketTypeKeySpecifier
      | (() => undefined | LookupTicketTypeKeySpecifier);
    fields?: LookupTicketTypeFieldPolicy;
  };
  LotNumber?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LotNumberKeySpecifier
      | (() => undefined | LotNumberKeySpecifier);
    fields?: LotNumberFieldPolicy;
  };
  LotNumberFormat?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LotNumberFormatKeySpecifier
      | (() => undefined | LotNumberFormatKeySpecifier);
    fields?: LotNumberFormatFieldPolicy;
  };
  LotNumbersConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LotNumbersConnectionKeySpecifier
      | (() => undefined | LotNumbersConnectionKeySpecifier);
    fields?: LotNumbersConnectionFieldPolicy;
  };
  LotNumbersEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LotNumbersEdgeKeySpecifier
      | (() => undefined | LotNumbersEdgeKeySpecifier);
    fields?: LotNumbersEdgeFieldPolicy;
  };
  ManagePersonalTask?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ManagePersonalTaskKeySpecifier
      | (() => undefined | ManagePersonalTaskKeySpecifier);
    fields?: ManagePersonalTaskFieldPolicy;
  };
  ManualTaskType?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ManualTaskTypeKeySpecifier
      | (() => undefined | ManualTaskTypeKeySpecifier);
    fields?: ManualTaskTypeFieldPolicy;
  };
  MatrixTube?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | MatrixTubeKeySpecifier
      | (() => undefined | MatrixTubeKeySpecifier);
    fields?: MatrixTubeFieldPolicy;
  };
  MethodReservation?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | MethodReservationKeySpecifier
      | (() => undefined | MethodReservationKeySpecifier);
    fields?: MethodReservationFieldPolicy;
  };
  MethodReservationPlateBarcodePreview?: Omit<
    TypePolicy,
    "fields" | "keyFields"
  > & {
    keyFields?:
      | false
      | MethodReservationPlateBarcodePreviewKeySpecifier
      | (() => undefined | MethodReservationPlateBarcodePreviewKeySpecifier);
    fields?: MethodReservationPlateBarcodePreviewFieldPolicy;
  };
  MethodReservationsPlateBarcode?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | MethodReservationsPlateBarcodeKeySpecifier
      | (() => undefined | MethodReservationsPlateBarcodeKeySpecifier);
    fields?: MethodReservationsPlateBarcodeFieldPolicy;
  };
  MethodSourcePlateFilter?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | MethodSourcePlateFilterKeySpecifier
      | (() => undefined | MethodSourcePlateFilterKeySpecifier);
    fields?: MethodSourcePlateFilterFieldPolicy;
  };
  Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | MutationKeySpecifier
      | (() => undefined | MutationKeySpecifier);
    fields?: MutationFieldPolicy;
  };
  MyPersonalTasksConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | MyPersonalTasksConnectionKeySpecifier
      | (() => undefined | MyPersonalTasksConnectionKeySpecifier);
    fields?: MyPersonalTasksConnectionFieldPolicy;
  };
  MyPersonalTasksEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | MyPersonalTasksEdgeKeySpecifier
      | (() => undefined | MyPersonalTasksEdgeKeySpecifier);
    fields?: MyPersonalTasksEdgeFieldPolicy;
  };
  NormalizedPassageTemplate?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | NormalizedPassageTemplateKeySpecifier
      | (() => undefined | NormalizedPassageTemplateKeySpecifier);
    fields?: NormalizedPassageTemplateFieldPolicy;
  };
  NormalizedPassageTemplateDetail?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | NormalizedPassageTemplateDetailKeySpecifier
      | (() => undefined | NormalizedPassageTemplateDetailKeySpecifier);
    fields?: NormalizedPassageTemplateDetailFieldPolicy;
  };
  NormalizedPassageTemplateMethodSetting?: Omit<
    TypePolicy,
    "fields" | "keyFields"
  > & {
    keyFields?:
      | false
      | NormalizedPassageTemplateMethodSettingKeySpecifier
      | (() => undefined | NormalizedPassageTemplateMethodSettingKeySpecifier);
    fields?: NormalizedPassageTemplateMethodSettingFieldPolicy;
  };
  NormalizedPassageTemplateStampDetail?: Omit<
    TypePolicy,
    "fields" | "keyFields"
  > & {
    keyFields?:
      | false
      | NormalizedPassageTemplateStampDetailKeySpecifier
      | (() => undefined | NormalizedPassageTemplateStampDetailKeySpecifier);
    fields?: NormalizedPassageTemplateStampDetailFieldPolicy;
  };
  NormalizedPassageTemplatesStampTopLeft?: Omit<
    TypePolicy,
    "fields" | "keyFields"
  > & {
    keyFields?:
      | false
      | NormalizedPassageTemplatesStampTopLeftKeySpecifier
      | (() => undefined | NormalizedPassageTemplatesStampTopLeftKeySpecifier);
    fields?: NormalizedPassageTemplatesStampTopLeftFieldPolicy;
  };
  PageInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PageInfoKeySpecifier
      | (() => undefined | PageInfoKeySpecifier);
    fields?: PageInfoFieldPolicy;
  };
  PersonalTask?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PersonalTaskKeySpecifier
      | (() => undefined | PersonalTaskKeySpecifier);
    fields?: PersonalTaskFieldPolicy;
  };
  PersonalTaskAttachment?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PersonalTaskAttachmentKeySpecifier
      | (() => undefined | PersonalTaskAttachmentKeySpecifier);
    fields?: PersonalTaskAttachmentFieldPolicy;
  };
  PersonalTaskTemplate?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PersonalTaskTemplateKeySpecifier
      | (() => undefined | PersonalTaskTemplateKeySpecifier);
    fields?: PersonalTaskTemplateFieldPolicy;
  };
  PersonalTaskTemplateTask?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PersonalTaskTemplateTaskKeySpecifier
      | (() => undefined | PersonalTaskTemplateTaskKeySpecifier);
    fields?: PersonalTaskTemplateTaskFieldPolicy;
  };
  PersonalTaskTemplatesConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PersonalTaskTemplatesConnectionKeySpecifier
      | (() => undefined | PersonalTaskTemplatesConnectionKeySpecifier);
    fields?: PersonalTaskTemplatesConnectionFieldPolicy;
  };
  PersonalTaskTemplatesEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PersonalTaskTemplatesEdgeKeySpecifier
      | (() => undefined | PersonalTaskTemplatesEdgeKeySpecifier);
    fields?: PersonalTaskTemplatesEdgeFieldPolicy;
  };
  PersonalTasksRecurringTask?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PersonalTasksRecurringTaskKeySpecifier
      | (() => undefined | PersonalTasksRecurringTaskKeySpecifier);
    fields?: PersonalTasksRecurringTaskFieldPolicy;
  };
  PlateMetadata?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PlateMetadataKeySpecifier
      | (() => undefined | PlateMetadataKeySpecifier);
    fields?: PlateMetadataFieldPolicy;
  };
  PlateNote?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PlateNoteKeySpecifier
      | (() => undefined | PlateNoteKeySpecifier);
    fields?: PlateNoteFieldPolicy;
  };
  PlateToMetadata?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PlateToMetadataKeySpecifier
      | (() => undefined | PlateToMetadataKeySpecifier);
    fields?: PlateToMetadataFieldPolicy;
  };
  PlateTransfer?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PlateTransferKeySpecifier
      | (() => undefined | PlateTransferKeySpecifier);
    fields?: PlateTransferFieldPolicy;
  };
  PlateType?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PlateTypeKeySpecifier
      | (() => undefined | PlateTypeKeySpecifier);
    fields?: PlateTypeFieldPolicy;
  };
  PlatesConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PlatesConnectionKeySpecifier
      | (() => undefined | PlatesConnectionKeySpecifier);
    fields?: PlatesConnectionFieldPolicy;
  };
  PlatesCreated?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PlatesCreatedKeySpecifier
      | (() => undefined | PlatesCreatedKeySpecifier);
    fields?: PlatesCreatedFieldPolicy;
  };
  PlatesEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PlatesEdgeKeySpecifier
      | (() => undefined | PlatesEdgeKeySpecifier);
    fields?: PlatesEdgeFieldPolicy;
  };
  ProductionRun?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ProductionRunKeySpecifier
      | (() => undefined | ProductionRunKeySpecifier);
    fields?: ProductionRunFieldPolicy;
  };
  ProductionRunType?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ProductionRunTypeKeySpecifier
      | (() => undefined | ProductionRunTypeKeySpecifier);
    fields?: ProductionRunTypeFieldPolicy;
  };
  Project?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ProjectKeySpecifier
      | (() => undefined | ProjectKeySpecifier);
    fields?: ProjectFieldPolicy;
  };
  ProjectDonor?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ProjectDonorKeySpecifier
      | (() => undefined | ProjectDonorKeySpecifier);
    fields?: ProjectDonorFieldPolicy;
  };
  Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | QueryKeySpecifier
      | (() => undefined | QueryKeySpecifier);
    fields?: QueryFieldPolicy;
  };
  RepeatingTasksSummaryConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RepeatingTasksSummaryConnectionKeySpecifier
      | (() => undefined | RepeatingTasksSummaryConnectionKeySpecifier);
    fields?: RepeatingTasksSummaryConnectionFieldPolicy;
  };
  RepeatingTasksSummaryEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RepeatingTasksSummaryEdgeKeySpecifier
      | (() => undefined | RepeatingTasksSummaryEdgeKeySpecifier);
    fields?: RepeatingTasksSummaryEdgeFieldPolicy;
  };
  Run?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | RunKeySpecifier | (() => undefined | RunKeySpecifier);
    fields?: RunFieldPolicy;
  };
  RunStage?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunStageKeySpecifier
      | (() => undefined | RunStageKeySpecifier);
    fields?: RunStageFieldPolicy;
  };
  RunStagesConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunStagesConnectionKeySpecifier
      | (() => undefined | RunStagesConnectionKeySpecifier);
    fields?: RunStagesConnectionFieldPolicy;
  };
  RunStagesEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunStagesEdgeKeySpecifier
      | (() => undefined | RunStagesEdgeKeySpecifier);
    fields?: RunStagesEdgeFieldPolicy;
  };
  RunTask?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunTaskKeySpecifier
      | (() => undefined | RunTaskKeySpecifier);
    fields?: RunTaskFieldPolicy;
  };
  RunTaskAttachment?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunTaskAttachmentKeySpecifier
      | (() => undefined | RunTaskAttachmentKeySpecifier);
    fields?: RunTaskAttachmentFieldPolicy;
  };
  RunTaskDetail?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunTaskDetailKeySpecifier
      | (() => undefined | RunTaskDetailKeySpecifier);
    fields?: RunTaskDetailFieldPolicy;
  };
  RunTaskType?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunTaskTypeKeySpecifier
      | (() => undefined | RunTaskTypeKeySpecifier);
    fields?: RunTaskTypeFieldPolicy;
  };
  RunTasksConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunTasksConnectionKeySpecifier
      | (() => undefined | RunTasksConnectionKeySpecifier);
    fields?: RunTasksConnectionFieldPolicy;
  };
  RunTasksEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunTasksEdgeKeySpecifier
      | (() => undefined | RunTasksEdgeKeySpecifier);
    fields?: RunTasksEdgeFieldPolicy;
  };
  RunTemplate?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunTemplateKeySpecifier
      | (() => undefined | RunTemplateKeySpecifier);
    fields?: RunTemplateFieldPolicy;
  };
  RunTemplateOption?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunTemplateOptionKeySpecifier
      | (() => undefined | RunTemplateOptionKeySpecifier);
    fields?: RunTemplateOptionFieldPolicy;
  };
  RunTemplateStage?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunTemplateStageKeySpecifier
      | (() => undefined | RunTemplateStageKeySpecifier);
    fields?: RunTemplateStageFieldPolicy;
  };
  RunTemplateTask?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunTemplateTaskKeySpecifier
      | (() => undefined | RunTemplateTaskKeySpecifier);
    fields?: RunTemplateTaskFieldPolicy;
  };
  RunTemplateTaskDetail?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunTemplateTaskDetailKeySpecifier
      | (() => undefined | RunTemplateTaskDetailKeySpecifier);
    fields?: RunTemplateTaskDetailFieldPolicy;
  };
  RunTemplateTreeNode?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunTemplateTreeNodeKeySpecifier
      | (() => undefined | RunTemplateTreeNodeKeySpecifier);
    fields?: RunTemplateTreeNodeFieldPolicy;
  };
  RunTemplatesConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunTemplatesConnectionKeySpecifier
      | (() => undefined | RunTemplatesConnectionKeySpecifier);
    fields?: RunTemplatesConnectionFieldPolicy;
  };
  RunTemplatesEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunTemplatesEdgeKeySpecifier
      | (() => undefined | RunTemplatesEdgeKeySpecifier);
    fields?: RunTemplatesEdgeFieldPolicy;
  };
  RunTemplatesRepeatDaysOfWeek?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RunTemplatesRepeatDaysOfWeekKeySpecifier
      | (() => undefined | RunTemplatesRepeatDaysOfWeekKeySpecifier);
    fields?: RunTemplatesRepeatDaysOfWeekFieldPolicy;
  };
  Sample?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | SampleKeySpecifier
      | (() => undefined | SampleKeySpecifier);
    fields?: SampleFieldPolicy;
  };
  SampleTypeCollectionTask?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | SampleTypeCollectionTaskKeySpecifier
      | (() => undefined | SampleTypeCollectionTaskKeySpecifier);
    fields?: SampleTypeCollectionTaskFieldPolicy;
  };
  SystemOneFeedPlates?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | SystemOneFeedPlatesKeySpecifier
      | (() => undefined | SystemOneFeedPlatesKeySpecifier);
    fields?: SystemOneFeedPlatesFieldPolicy;
  };
  SystemOneFeedRuns?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | SystemOneFeedRunsKeySpecifier
      | (() => undefined | SystemOneFeedRunsKeySpecifier);
    fields?: SystemOneFeedRunsFieldPolicy;
  };
  SystemOneFeedWorklist?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | SystemOneFeedWorklistKeySpecifier
      | (() => undefined | SystemOneFeedWorklistKeySpecifier);
    fields?: SystemOneFeedWorklistFieldPolicy;
  };
  SystemType?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | SystemTypeKeySpecifier
      | (() => undefined | SystemTypeKeySpecifier);
    fields?: SystemTypeFieldPolicy;
  };
  TaskAttachment?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | TaskAttachmentKeySpecifier
      | (() => undefined | TaskAttachmentKeySpecifier);
    fields?: TaskAttachmentFieldPolicy;
  };
  TaskType?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | TaskTypeKeySpecifier
      | (() => undefined | TaskTypeKeySpecifier);
    fields?: TaskTypeFieldPolicy;
  };
  Ticket?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | TicketKeySpecifier
      | (() => undefined | TicketKeySpecifier);
    fields?: TicketFieldPolicy;
  };
  TicketAssignee?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | TicketAssigneeKeySpecifier
      | (() => undefined | TicketAssigneeKeySpecifier);
    fields?: TicketAssigneeFieldPolicy;
  };
  TicketAttachment?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | TicketAttachmentKeySpecifier
      | (() => undefined | TicketAttachmentKeySpecifier);
    fields?: TicketAttachmentFieldPolicy;
  };
  TicketFollower?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | TicketFollowerKeySpecifier
      | (() => undefined | TicketFollowerKeySpecifier);
    fields?: TicketFollowerFieldPolicy;
  };
  TicketUpdate?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | TicketUpdateKeySpecifier
      | (() => undefined | TicketUpdateKeySpecifier);
    fields?: TicketUpdateFieldPolicy;
  };
  TicketsConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | TicketsConnectionKeySpecifier
      | (() => undefined | TicketsConnectionKeySpecifier);
    fields?: TicketsConnectionFieldPolicy;
  };
  TicketsEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | TicketsEdgeKeySpecifier
      | (() => undefined | TicketsEdgeKeySpecifier);
    fields?: TicketsEdgeFieldPolicy;
  };
  TubeBarcodeFile?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | TubeBarcodeFileKeySpecifier
      | (() => undefined | TubeBarcodeFileKeySpecifier);
    fields?: TubeBarcodeFileFieldPolicy;
  };
  TubeLabellerDestinationWorklist?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | TubeLabellerDestinationWorklistKeySpecifier
      | (() => undefined | TubeLabellerDestinationWorklistKeySpecifier);
    fields?: TubeLabellerDestinationWorklistFieldPolicy;
  };
  UploadIdfFormResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | UploadIdfFormResponseKeySpecifier
      | (() => undefined | UploadIdfFormResponseKeySpecifier);
    fields?: UploadIdfFormResponseFieldPolicy;
  };
  User?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier);
    fields?: UserFieldPolicy;
  };
  UserClaims?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | UserClaimsKeySpecifier
      | (() => undefined | UserClaimsKeySpecifier);
    fields?: UserClaimsFieldPolicy;
  };
  UserSetting?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | UserSettingKeySpecifier
      | (() => undefined | UserSettingKeySpecifier);
    fields?: UserSettingFieldPolicy;
  };
  UserToRole?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | UserToRoleKeySpecifier
      | (() => undefined | UserToRoleKeySpecifier);
    fields?: UserToRoleFieldPolicy;
  };
  WebSuiteTaskLog?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | WebSuiteTaskLogKeySpecifier
      | (() => undefined | WebSuiteTaskLogKeySpecifier);
    fields?: WebSuiteTaskLogFieldPolicy;
  };
  Worklist?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | WorklistKeySpecifier
      | (() => undefined | WorklistKeySpecifier);
    fields?: WorklistFieldPolicy;
  };
  WorklistCherryPickMode?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | WorklistCherryPickModeKeySpecifier
      | (() => undefined | WorklistCherryPickModeKeySpecifier);
    fields?: WorklistCherryPickModeFieldPolicy;
  };
  WorklistColumn?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | WorklistColumnKeySpecifier
      | (() => undefined | WorklistColumnKeySpecifier);
    fields?: WorklistColumnFieldPolicy;
  };
  WorklistContent?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | WorklistContentKeySpecifier
      | (() => undefined | WorklistContentKeySpecifier);
    fields?: WorklistContentFieldPolicy;
  };
  WorklistContentImaging?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | WorklistContentImagingKeySpecifier
      | (() => undefined | WorklistContentImagingKeySpecifier);
    fields?: WorklistContentImagingFieldPolicy;
  };
  WorklistToolTemplate?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | WorklistToolTemplateKeySpecifier
      | (() => undefined | WorklistToolTemplateKeySpecifier);
    fields?: WorklistToolTemplateFieldPolicy;
  };
  WorklistToolTemplateContent?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | WorklistToolTemplateContentKeySpecifier
      | (() => undefined | WorklistToolTemplateContentKeySpecifier);
    fields?: WorklistToolTemplateContentFieldPolicy;
  };
  WorklistToolTemplatePlate?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | WorklistToolTemplatePlateKeySpecifier
      | (() => undefined | WorklistToolTemplatePlateKeySpecifier);
    fields?: WorklistToolTemplatePlateFieldPolicy;
  };
};
export const CalendarNodeFragmentDoc = gql`
  fragment CalendarNode on CalendarNode {
    id
    name
    taskType
    startTimeScheduled
    endTimeScheduled
    startTime
    endTime
    status
    priority
    systemAssignedTo
    runId
    runName
    method {
      methodId
      methodName
    }
    manualTaskType {
      id
      name
    }
    systemAssignedToNavigation {
      systemId
      systemName
      clusterId
    }
    users {
      userId
      userName
    }
    methodReservations {
      methodReservationsPlateBarcodes {
        plateBarcode
      }
    }
    groups {
      groupId
      groupName
    }
    lastTimeUpdated
    updatedByName
  }
`;
export const DashboardTaskFragmentDoc = gql`
  fragment DashboardTask on DashboardTask {
    taskOwnerId
    taskOwnerName
    taskId
    taskName
    taskType
    startDate
    startTime
    endTime
    priority
    status
    assignedUsers
    assignedGroups
    systemId
    folderId
    notes
    methodId
    startTimeScheduled
    endTimeScheduled
    startTimeActual
    endTimeActual
    project
    sampleType
  }
`;
export const ManagePersonalTaskFragmentDoc = gql`
  fragment ManagePersonalTask on ManagePersonalTask {
    taskOwnerId
    taskId
    taskName
    startTimeScheduled
    endTimeScheduled
    priority
    status
    assignedUsers
    assignedGroups
    systemAssignedTo
    notes
  }
`;
export const RunFragmentDoc = gql`
  fragment Run on Run {
    runId
    runName
    runDateScheduled
    runOwner
    comments
    isActive
    status
    folderParentId
    lookupTeamGanttId {
      teamGanttId
      id
      runId
      dateCreated
      nodeType
    }
    runStages {
      runId
      runStageId
      runStageName
    }
  }
`;
export const FolderStructureFragmentDoc = gql`
  fragment FolderStructure on FolderStructure {
    folderId
    folderName
    folderParentId
    isInsertable
    folderParent {
      folderId
      folderName
    }
    lookupTeamGanttId {
      folderId
      nodeType
      teamGanttId
    }
    runs {
      runId
      ...Run
      runStages {
        runStageId
        runStageName
      }
    }
  }
  ${RunFragmentDoc}
`;
export const DirectoryFolderFragmentDoc = gql`
  fragment DirectoryFolder on DirectoryFolder {
    id
    parentId
    parentType
    name
    type
  }
`;
export const DirectoryFragmentDoc = gql`
  fragment Directory on DirectoryFolder {
    ...DirectoryFolder
    subFolders {
      ...DirectoryFolder
      subFolders {
        ...DirectoryFolder
        subFolders {
          ...DirectoryFolder
          subFolders {
            ...DirectoryFolder
          }
        }
      }
    }
  }
  ${DirectoryFolderFragmentDoc}
`;
export const IncomingSampleTaskTableEntryFragmentDoc = gql`
  fragment IncomingSampleTaskTableEntry on CollectionTask {
    incomingSampleType {
      sampleType {
        sampleTypeId
        sampleTypeString
      }
      collection {
        collectionId
        project {
          projectId
          projectName
        }
      }
    }
    incomingSampleTaskId
    task
    startTimeScheduled
    status
    groupAssignedTo
    userAssignedTo
    systemAssignedTo
  }
`;
export const IncomingSampleTaskFragmentDoc = gql`
  fragment IncomingSampleTask on CollectionTask {
    ...IncomingSampleTaskTableEntry
    completedBy
    completedByTime
    endTimeActual
    endTimeScheduled
    startTimeActual
  }
  ${IncomingSampleTaskTableEntryFragmentDoc}
`;
export const IncomingSampleTypeFragmentDoc = gql`
  fragment IncomingSampleType on CollectionSampleType {
    incomingSampleTypeId
    sampleTypeId
    sampleType {
      sampleTypeId
      sampleTypeString
    }
    dateToProcess
    isActive
    notes
  }
`;
export const IncomingSampleCollectionFragmentDoc = gql`
  fragment IncomingSampleCollection on Collection {
    collectionId
    collectionSampleTypes {
      ...IncomingSampleType
    }
    createdBy
    projectId
    project {
      projectId
      projectName
      nyscfprefix
    }
    dateTimeOfArrival
    dateTimeOfCollection
    dateTimeOfArrival
  }
  ${IncomingSampleTypeFragmentDoc}
`;
export const UploadIdfFormResponseFragmentDoc = gql`
  fragment UploadIdfFormResponse on UploadIdfFormResponse {
    success
    traceID
    message
    errors
  }
`;
export const PersonalTaskAttachmentFragmentDoc = gql`
  fragment PersonalTaskAttachment on PersonalTaskAttachment {
    personalTaskAttachmentId
    personalTaskId
    attachmentName
    attachmentUrl
    dateUploaded
    uploadedBy
    comments
  }
`;
export const PlateFragmentDoc = gql`
  fragment Plate on PlatesCreated {
    plateCreatedId
    plateBarcode
    plateBarcodeAlias
    comments
    dateCreated
    createdByUserId
    isDead
    isIntermediate
    isActive
    lastScanDate
    plateNotes {
      plateNoteId
      __typename
      user {
        userId
        userName
      }
      note
      dateCommented
    }
    productionRun {
      runName
      runId
    }
    arrayPlateType {
      plateLayoutTypeId
      plateLayoutType {
        plateTypeId
      }
      defaultLabwareType {
        plateLayoutTypeId
      }
    }
  }
`;
export const ContainerMetadataFragmentDoc = gql`
  fragment ContainerMetadata on ContainerMetaData {
    rowCol
    rowPos
    colPos
    barcode
    isActive
    passageNumber
    lastDataPointSummary
    lastDataPointValue
    markingSummary
    contaminatedMarking
    differentiatedMarking
    noCellsMarking
    clonalityMarking
    qcpassFailMarking
    colonyMarking
    sangerSeqMarking
    conReviewMarking
    fibroblastLotNumber
    bloodProcessingLotNumber
    consolidationLotNumber
    reprogrammingLotNumber
    spitLotNumber
    monoclonalizationLotNumber
    expansionLotNumber
    geneEditingLotNumber
    dnaextractionLotNumber
    externalLotNumber
    otherLotNumber
    collaboratorId
    nyscfid
    sourceCellLine
    lotNumber
    sampleBarcode
    sampleBarcodeAliases
  }
`;
export const PlateInfoFragmentDoc = gql`
  fragment PlateInfo on PlatesCreated {
    ...Plate
    containers {
      containerId
      containerNotes {
        containerNoteId
        __typename
        user {
          userId
          userName
        }
        note
        dateCommented
      }
      containerMetadata {
        ...ContainerMetadata
      }
    }
  }
  ${PlateFragmentDoc}
  ${ContainerMetadataFragmentDoc}
`;
export const ContainerLineageFragmentDoc = gql`
  fragment ContainerLineage on ContainerLineage {
    containerId
    hierarchy
    plateBarcode
    rowPos
    colPos
    sample
    tubeBarcode
    dateCreated
    passageNumber
    lotNumber
    isActive
    finalConfluence
    lN2SAMActivity
    comments
  }
`;
export const SampleFragmentDoc = gql`
  fragment Sample on Sample {
    sampleId
    sampleBarcode
    sampleBarcodeAliases
    description
    dateAdded
    registeredByUserId
  }
`;
export const ContainerTableEntryFragmentDoc = gql`
  fragment ContainerTableEntry on Container {
    containerId
    barcode
    dateCreated
    colPos
    rowPos
    rowCol
    dateInactivated
    isActive
    inactivationReason {
      reason
    }
    sample {
      ...Sample
    }
    plate {
      plateCreatedId
      plateBarcode
    }
    lotNumber {
      lotNumberId
      lotNumber1
    }
  }
  ${SampleFragmentDoc}
`;
export const LabwareTypeFragmentDoc = gql`
  fragment LabwareType on LookupLabwareType {
    plateLayoutTypeId
    labwareTypeId
    labwareTypeSummary
    labwareTypeName
    labwareTypeCode
    manufacturer
    description
    defaultLabwareVolume
    minLabwareVolume
    maxLabwareVolume
    defaultArrayPlateCodeNavigation {
      arrayPlateCode
    }
    plateLayoutType {
      plateRows
      plateCols
    }
  }
`;
export const RunTaskDetailsFragmentDoc = gql`
  fragment RunTaskDetails on RunTaskDetail {
    runTaskId
    runTaskDetailsId
    daughterWellNumber
    seedCellCount
    freezeCellCount
    freezeDownRackType
    createMystplate
    destPlateCode
    minCryovial
    maxCryovial
    overflowVials
    imageExportFormat
    imageExportSetting
    worklistTemplateId
    intermediateLabwareTypeId
  }
`;
export const SimpleRunTaskFragmentDoc = gql`
  fragment SimpleRunTask on RunTask {
    runTaskId
    runId
    runStageId
    methodId
    runTemplateTaskId
    activityName
    endTimeScheduled
    startTimeScheduled
    endTimeActual
    daysFromRunStart
    users {
      userId
    }
    groups {
      groupId
    }
    systemAssignedTo
    status
    priority
    notes
    manualTaskTypeId
    runTaskDetail {
      ...RunTaskDetails
    }
  }
  ${RunTaskDetailsFragmentDoc}
`;
export const RunTaskFragmentDoc = gql`
  fragment RunTask on RunTask {
    runTaskId
    runId
    runStageId
    methodId
    runTemplateTaskId
    activityName
    endTimeScheduled
    startTimeScheduled
    endTimeActual
    startTimeActual
    daysFromRunStart
    users {
      userId
    }
    groups {
      groupId
    }
    systemAssignedTo
    status
    priority
    notes
    manualTaskTypeId
    run {
      ...Run
    }
  }
  ${RunFragmentDoc}
`;
export const ReschedulingTaskFragmentDoc = gql`
  fragment ReschedulingTask on RunTask {
    runTaskId
    runId
    activityName
    startTimeScheduled
    endTimeScheduled
    startTimeActual
    endTimeActual
    status
    systemAssignedTo
  }
`;
export const MethodFragmentDoc = gql`
  fragment Method on LookupArrayMethod {
    methodId
    methodName
    documentationUrl
    isActive
    methodType {
      methodType
      methodTypeId
      runSpecific
      inactivatesSourcePlates
      excludeSourcePlatesReservedForInactivation
    }
  }
`;
export const ManualTaskTypeFragmentDoc = gql`
  fragment ManualTaskType on ManualTaskType {
    id
    name
    documentationUrl
  }
`;
export const PlateCreatedFragmentDoc = gql`
  fragment PlateCreated on PlatesCreated {
    plateCreatedId
    plateBarcode
    isActive
    labwareType {
      plateLayoutTypeId
      platePitch
    }
    arrayPlateType {
      defaultLabwareType {
        plateLayoutTypeId
        platePitch
      }
    }
    containers {
      containerId
      sample {
        sampleId
        sampleBarcode
      }
    }
  }
`;
export const ProductionRunFragmentDoc = gql`
  fragment ProductionRun on ProductionRun {
    runId
    platesCreateds {
      ...PlateCreated
    }
  }
  ${PlateCreatedFragmentDoc}
`;
export const RunInfoFragmentDoc = gql`
  fragment RunInfo on Run {
    runId
    ...Run
    productionRunId
    productionRun {
      ...ProductionRun
    }
  }
  ${RunFragmentDoc}
  ${ProductionRunFragmentDoc}
`;
export const ImagingWorklistFragmentDoc = gql`
  fragment ImagingWorklist on WorklistContentImaging {
    barcode
    platePitch
    experimentSetting
    analysisSetting
    imageExportFormat
    imageExportSetting
  }
`;
export const RunTaskMetaDataFragmentDoc = gql`
  fragment RunTaskMetaData on RunTask {
    runTaskId
    method {
      ...Method
    }
    manualTaskType {
      ...ManualTaskType
    }
    run {
      ...RunInfo
    }
    runTaskDetail {
      ...RunTaskDetails
    }
    methodReservations {
      methodReservationId
      date
      isCompleted
      worklist {
        worklistContentImagings {
          ...ImagingWorklist
        }
      }
    }
  }
  ${MethodFragmentDoc}
  ${ManualTaskTypeFragmentDoc}
  ${RunInfoFragmentDoc}
  ${RunTaskDetailsFragmentDoc}
  ${ImagingWorklistFragmentDoc}
`;
export const RunTaskAttachmentFragmentDoc = gql`
  fragment RunTaskAttachment on RunTaskAttachment {
    runTaskAttachmentId
    runTaskId
    attachmentName
    attachmentUrl
    dateUploaded
    uploadedBy
    comments
  }
`;
export const RunTemplateTaskFragmentDoc = gql`
  fragment RunTemplateTask on RunTemplateTask {
    runTemplateId
    runTemplateStageId
    runTemplateTaskId
    customTaskName
    users {
      userId
    }
    groups {
      groupId
    }
    systemAssignedTo
    dayScheduled
    runTemplateParentTaskId
    notes
    runTemplatesRepeatDaysOfWeeks {
      runTemplateRepeatDayOfWeekId
      dayOfWeek
      numberOfWeeks
    }
    method {
      ...Method
    }
    manualTaskType {
      ...ManualTaskType
    }
    manualTaskTypeId
    methodId
    runTemplateTaskDetail {
      runTemplateTaskId
      runTemplateTaskDetailsId
      daughterWellNumber
      seedCellCount
      freezeCellCount
      freezeDownRackType
      createMystplate
      destPlateCode
      minCryovial
      maxCryovial
      overflowVials
      imageExportFormat
      imageExportSetting
      worklistTemplateId
      intermediateLabwareTypeId
    }
  }
  ${MethodFragmentDoc}
  ${ManualTaskTypeFragmentDoc}
`;
export const RunTemplateStageFragmentDoc = gql`
  fragment RunTemplateStage on RunTemplateStage {
    runTemplateId
    runTemplateStageId
    templateStageName
    stageSeqNo
    runTemplateTreeNodes {
      nodeId
      parentId
      dayScheduled
      child {
        runTemplateId
        runTemplateName
        numberOfSubTemplates
        numberOfTasks
      }
    }
    runTemplateTasks {
      ...RunTemplateTask
    }
  }
  ${RunTemplateTaskFragmentDoc}
`;
export const RunTemplateFragmentDoc = gql`
  fragment RunTemplate on RunTemplate {
    runTemplateId
    runTemplateName
    productionRunTypeId
    createdByV
    createdBy
    numberOfSubTemplates
    numberOfTasks
    lastUpdatedBy
    lastTimeUpdated
    productionRunTypeNavigation {
      productionRunTypeId
      typeName
      runNumberDigits
    }
    folderLocationId
    folderLocation {
      folderId
      folderName
    }
    groups {
      groupId
    }
    runTemplateStages {
      ...RunTemplateStage
    }
  }
  ${RunTemplateStageFragmentDoc}
`;
export const TicketFragmentDoc = gql`
  fragment Ticket on Ticket {
    ticketId
    title
    priority
    status
    description
    createdByUserId
    dateCreated
    assetId
    dateEncountered
    lastUpdated
    ticketAssignees {
      userId
    }
    ticketFollowers {
      userId
    }
    asset {
      assetId
      asset
      assetType {
        assetTypeId
        assetType
      }
    }
    ticketType {
      ticketTypeId
      ticketType
      userGroup {
        groupId
        groupName
      }
    }
    percentComplete
    slackMessageTimestamp
    ticketUpdates {
      dateUpdated
      updatedByUserId
      summary
    }
  }
`;
export const TicketAttachmentsFragmentDoc = gql`
  fragment TicketAttachments on Ticket {
    ticketId
    ticketAttachments {
      ticketAttachmentId
      attachmentUrl
      attachmentName
      dateUploaded
      uploadedBy
    }
  }
`;
export const TicketAndAttachmentsFragmentDoc = gql`
  fragment TicketAndAttachments on Ticket {
    ...Ticket
    ...TicketAttachments
  }
  ${TicketFragmentDoc}
  ${TicketAttachmentsFragmentDoc}
`;
export const TubeLabellerDestinationWorklistFragmentDoc = gql`
  fragment TubeLabellerDestinationWorklist on TubeLabellerDestinationWorklist {
    dSTRack
    dSTPosition
    sRCRack
    sRCPosition
    dSTRackBarcode
    dSTLinePosition
    factoryBarcode
    field1
    field2
    field3
    field4
    field5
    field6
    field7
  }
`;
export const UserFragmentDoc = gql`
  fragment User on AppSuiteUser {
    userId
    userName
    email
    roles
    isActive
    isAdmin
    groups {
      groupId
      groupName
    }
  }
`;
export const GroupFragmentDoc = gql`
  fragment Group on AppSuiteGroup {
    groupId
    groupName
    users {
      userId
      userName
    }
  }
`;
export const UserClaimsFragmentDoc = gql`
  fragment UserClaims on UserClaims {
    userId
    userName
    isAdmin
    isGroupAccount
    setting {
      id
      userId
      settingsJson
    }
    groupAccount {
      ...Group
    }
    loggedInGroupMember {
      labComputer {
        id
        name
      }
      user {
        userId
        userName
        isAdmin
        email
      }
    }
  }
  ${GroupFragmentDoc}
`;
export const WorklistToolTemplateFragmentDoc = gql`
  fragment WorklistToolTemplate on WorklistToolTemplate {
    worklistToolTemplateId
    templateName
    methodId
    createdBy
    lastUpdatedBy
    lastUpdateByTime
    worklistToolTemplateContents {
      task
      details
      aspirateVol
      dispenseVol
      transferVol
      sourcePlateType
      sourcePlatePosition
      sourceWellId
      destinationPlateType
      destinationPlatePosition
      destinationWellId
    }
    worklistToolTemplatePlates {
      plateType
      plateIndex
      labwareTypeCode
      numberOfRows
      numberOfColumns
      operatingVol
      resuspensionVol
      startingVol
      preprocess
      topup
    }
  }
`;
export const WorklistToolTemplateContentsFragmentDoc = gql`
  fragment WorklistToolTemplateContents on WorklistToolTemplateContent {
    task
    details
    aspirateVol
    dispenseVol
    transferVol
    sourcePlateType
    sourcePlatePosition
    sourceWellId
    destinationPlateType
    destinationPlatePosition
    destinationWellId
  }
`;
export const UpdateDashboardTasksDocument = gql`
  mutation updateDashboardTasks($dashboardTasks: [UpdateDashboardTaskInput]) {
    updateDashboardTasks(dashboardTasks: $dashboardTasks)
  }
`;
export type UpdateDashboardTasksMutationFn = Apollo.MutationFunction<
  UpdateDashboardTasksMutation,
  UpdateDashboardTasksMutationVariables
>;

/**
 * __useUpdateDashboardTasksMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardTasksMutation, { data, loading, error }] = useUpdateDashboardTasksMutation({
 *   variables: {
 *      dashboardTasks: // value for 'dashboardTasks'
 *   },
 * });
 */
export function useUpdateDashboardTasksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDashboardTasksMutation,
    UpdateDashboardTasksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDashboardTasksMutation,
    UpdateDashboardTasksMutationVariables
  >(UpdateDashboardTasksDocument, options);
}
export type UpdateDashboardTasksMutationHookResult = ReturnType<
  typeof useUpdateDashboardTasksMutation
>;
export type UpdateDashboardTasksMutationResult =
  Apollo.MutationResult<UpdateDashboardTasksMutation>;
export type UpdateDashboardTasksMutationOptions = Apollo.BaseMutationOptions<
  UpdateDashboardTasksMutation,
  UpdateDashboardTasksMutationVariables
>;
export const CreateFeedReservationDocument = gql`
  mutation createFeedReservation($plates: SystemOneFeedReservationInfoInput) {
    reserveFeedWorklist(plates: $plates) {
      worklistID
      methodReservationID
    }
  }
`;
export type CreateFeedReservationMutationFn = Apollo.MutationFunction<
  CreateFeedReservationMutation,
  CreateFeedReservationMutationVariables
>;

/**
 * __useCreateFeedReservationMutation__
 *
 * To run a mutation, you first call `useCreateFeedReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedReservationMutation, { data, loading, error }] = useCreateFeedReservationMutation({
 *   variables: {
 *      plates: // value for 'plates'
 *   },
 * });
 */
export function useCreateFeedReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFeedReservationMutation,
    CreateFeedReservationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFeedReservationMutation,
    CreateFeedReservationMutationVariables
  >(CreateFeedReservationDocument, options);
}
export type CreateFeedReservationMutationHookResult = ReturnType<
  typeof useCreateFeedReservationMutation
>;
export type CreateFeedReservationMutationResult =
  Apollo.MutationResult<CreateFeedReservationMutation>;
export type CreateFeedReservationMutationOptions = Apollo.BaseMutationOptions<
  CreateFeedReservationMutation,
  CreateFeedReservationMutationVariables
>;
export const ReserveCeligoPersonalTaskDocument = gql`
  mutation ReserveCeligoPersonalTask($task: DashboardTaskInput) {
    reserveCeligoPersonalTaskMethod(task: $task) {
      ...DashboardTask
    }
  }
  ${DashboardTaskFragmentDoc}
`;
export type ReserveCeligoPersonalTaskMutationFn = Apollo.MutationFunction<
  ReserveCeligoPersonalTaskMutation,
  ReserveCeligoPersonalTaskMutationVariables
>;

/**
 * __useReserveCeligoPersonalTaskMutation__
 *
 * To run a mutation, you first call `useReserveCeligoPersonalTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReserveCeligoPersonalTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reserveCeligoPersonalTaskMutation, { data, loading, error }] = useReserveCeligoPersonalTaskMutation({
 *   variables: {
 *      task: // value for 'task'
 *   },
 * });
 */
export function useReserveCeligoPersonalTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReserveCeligoPersonalTaskMutation,
    ReserveCeligoPersonalTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReserveCeligoPersonalTaskMutation,
    ReserveCeligoPersonalTaskMutationVariables
  >(ReserveCeligoPersonalTaskDocument, options);
}
export type ReserveCeligoPersonalTaskMutationHookResult = ReturnType<
  typeof useReserveCeligoPersonalTaskMutation
>;
export type ReserveCeligoPersonalTaskMutationResult =
  Apollo.MutationResult<ReserveCeligoPersonalTaskMutation>;
export type ReserveCeligoPersonalTaskMutationOptions =
  Apollo.BaseMutationOptions<
    ReserveCeligoPersonalTaskMutation,
    ReserveCeligoPersonalTaskMutationVariables
  >;
export const LogDocument = gql`
  mutation Log($log: LogInput!) {
    log(log: $log) {
      success
    }
  }
`;
export type LogMutationFn = Apollo.MutationFunction<
  LogMutation,
  LogMutationVariables
>;

/**
 * __useLogMutation__
 *
 * To run a mutation, you first call `useLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logMutation, { data, loading, error }] = useLogMutation({
 *   variables: {
 *      log: // value for 'log'
 *   },
 * });
 */
export function useLogMutation(
  baseOptions?: Apollo.MutationHookOptions<LogMutation, LogMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogMutation, LogMutationVariables>(
    LogDocument,
    options
  );
}
export type LogMutationHookResult = ReturnType<typeof useLogMutation>;
export type LogMutationResult = Apollo.MutationResult<LogMutation>;
export type LogMutationOptions = Apollo.BaseMutationOptions<
  LogMutation,
  LogMutationVariables
>;
export const CreateRunFolderDocument = gql`
  mutation CreateRunFolder($input: CreateRunFolderInput!) {
    createRunFolder(input: $input) {
      directoryFolder {
        ...DirectoryFolder
        subFolders {
          ...DirectoryFolder
        }
      }
      folderStructure {
        ...FolderStructure
      }
    }
  }
  ${DirectoryFolderFragmentDoc}
  ${FolderStructureFragmentDoc}
`;
export type CreateRunFolderMutationFn = Apollo.MutationFunction<
  CreateRunFolderMutation,
  CreateRunFolderMutationVariables
>;

/**
 * __useCreateRunFolderMutation__
 *
 * To run a mutation, you first call `useCreateRunFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRunFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRunFolderMutation, { data, loading, error }] = useCreateRunFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRunFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRunFolderMutation,
    CreateRunFolderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRunFolderMutation,
    CreateRunFolderMutationVariables
  >(CreateRunFolderDocument, options);
}
export type CreateRunFolderMutationHookResult = ReturnType<
  typeof useCreateRunFolderMutation
>;
export type CreateRunFolderMutationResult =
  Apollo.MutationResult<CreateRunFolderMutation>;
export type CreateRunFolderMutationOptions = Apollo.BaseMutationOptions<
  CreateRunFolderMutation,
  CreateRunFolderMutationVariables
>;
export const DeleteRunFolderDocument = gql`
  mutation DeleteRunFolder($input: DeleteInput!) {
    deleteRunFolder(input: $input)
  }
`;
export type DeleteRunFolderMutationFn = Apollo.MutationFunction<
  DeleteRunFolderMutation,
  DeleteRunFolderMutationVariables
>;

/**
 * __useDeleteRunFolderMutation__
 *
 * To run a mutation, you first call `useDeleteRunFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRunFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRunFolderMutation, { data, loading, error }] = useDeleteRunFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRunFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRunFolderMutation,
    DeleteRunFolderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRunFolderMutation,
    DeleteRunFolderMutationVariables
  >(DeleteRunFolderDocument, options);
}
export type DeleteRunFolderMutationHookResult = ReturnType<
  typeof useDeleteRunFolderMutation
>;
export type DeleteRunFolderMutationResult =
  Apollo.MutationResult<DeleteRunFolderMutation>;
export type DeleteRunFolderMutationOptions = Apollo.BaseMutationOptions<
  DeleteRunFolderMutation,
  DeleteRunFolderMutationVariables
>;
export const AddIdfUploadDocument = gql`
  mutation AddIdfUpload($input: UploadIdfFormInput!) {
    addIdfUpload(uploadIdfFormInput: $input) {
      ...UploadIdfFormResponse
    }
  }
  ${UploadIdfFormResponseFragmentDoc}
`;
export type AddIdfUploadMutationFn = Apollo.MutationFunction<
  AddIdfUploadMutation,
  AddIdfUploadMutationVariables
>;

/**
 * __useAddIdfUploadMutation__
 *
 * To run a mutation, you first call `useAddIdfUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIdfUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIdfUploadMutation, { data, loading, error }] = useAddIdfUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddIdfUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddIdfUploadMutation,
    AddIdfUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddIdfUploadMutation,
    AddIdfUploadMutationVariables
  >(AddIdfUploadDocument, options);
}
export type AddIdfUploadMutationHookResult = ReturnType<
  typeof useAddIdfUploadMutation
>;
export type AddIdfUploadMutationResult =
  Apollo.MutationResult<AddIdfUploadMutation>;
export type AddIdfUploadMutationOptions = Apollo.BaseMutationOptions<
  AddIdfUploadMutation,
  AddIdfUploadMutationVariables
>;
export const ValidateIdfUploadDocument = gql`
  mutation ValidateIdfUpload($input: UploadIdfFormInput!) {
    validateIdfUpload(uploadIdfFormInput: $input)
  }
`;
export type ValidateIdfUploadMutationFn = Apollo.MutationFunction<
  ValidateIdfUploadMutation,
  ValidateIdfUploadMutationVariables
>;

/**
 * __useValidateIdfUploadMutation__
 *
 * To run a mutation, you first call `useValidateIdfUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateIdfUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateIdfUploadMutation, { data, loading, error }] = useValidateIdfUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateIdfUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateIdfUploadMutation,
    ValidateIdfUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ValidateIdfUploadMutation,
    ValidateIdfUploadMutationVariables
  >(ValidateIdfUploadDocument, options);
}
export type ValidateIdfUploadMutationHookResult = ReturnType<
  typeof useValidateIdfUploadMutation
>;
export type ValidateIdfUploadMutationResult =
  Apollo.MutationResult<ValidateIdfUploadMutation>;
export type ValidateIdfUploadMutationOptions = Apollo.BaseMutationOptions<
  ValidateIdfUploadMutation,
  ValidateIdfUploadMutationVariables
>;
export const UpdateCollectionSampleTypeDocument = gql`
  mutation UpdateCollectionSampleType(
    $input: UpdateInputOfUpdateIncomingSampleTypeInputAndCollectionSampleTypeInput!
  ) {
    updateIncomingSampleType(input: $input) {
      ...IncomingSampleCollection
    }
  }
  ${IncomingSampleCollectionFragmentDoc}
`;
export type UpdateCollectionSampleTypeMutationFn = Apollo.MutationFunction<
  UpdateCollectionSampleTypeMutation,
  UpdateCollectionSampleTypeMutationVariables
>;

/**
 * __useUpdateCollectionSampleTypeMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionSampleTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionSampleTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionSampleTypeMutation, { data, loading, error }] = useUpdateCollectionSampleTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollectionSampleTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCollectionSampleTypeMutation,
    UpdateCollectionSampleTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCollectionSampleTypeMutation,
    UpdateCollectionSampleTypeMutationVariables
  >(UpdateCollectionSampleTypeDocument, options);
}
export type UpdateCollectionSampleTypeMutationHookResult = ReturnType<
  typeof useUpdateCollectionSampleTypeMutation
>;
export type UpdateCollectionSampleTypeMutationResult =
  Apollo.MutationResult<UpdateCollectionSampleTypeMutation>;
export type UpdateCollectionSampleTypeMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCollectionSampleTypeMutation,
    UpdateCollectionSampleTypeMutationVariables
  >;
export const AddCollectionSampleTypeDocument = gql`
  mutation AddCollectionSampleType($input: CreateIncomingSampleTypeInput!) {
    addIncomingSampleType(input: $input) {
      ...IncomingSampleCollection
    }
  }
  ${IncomingSampleCollectionFragmentDoc}
`;
export type AddCollectionSampleTypeMutationFn = Apollo.MutationFunction<
  AddCollectionSampleTypeMutation,
  AddCollectionSampleTypeMutationVariables
>;

/**
 * __useAddCollectionSampleTypeMutation__
 *
 * To run a mutation, you first call `useAddCollectionSampleTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCollectionSampleTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCollectionSampleTypeMutation, { data, loading, error }] = useAddCollectionSampleTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCollectionSampleTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCollectionSampleTypeMutation,
    AddCollectionSampleTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCollectionSampleTypeMutation,
    AddCollectionSampleTypeMutationVariables
  >(AddCollectionSampleTypeDocument, options);
}
export type AddCollectionSampleTypeMutationHookResult = ReturnType<
  typeof useAddCollectionSampleTypeMutation
>;
export type AddCollectionSampleTypeMutationResult =
  Apollo.MutationResult<AddCollectionSampleTypeMutation>;
export type AddCollectionSampleTypeMutationOptions = Apollo.BaseMutationOptions<
  AddCollectionSampleTypeMutation,
  AddCollectionSampleTypeMutationVariables
>;
export const DeleteCollectionSampleTypeDocument = gql`
  mutation DeleteCollectionSampleType($input: DeleteInput!) {
    deleteCollectionSampleType(input: $input) {
      ...IncomingSampleCollection
    }
  }
  ${IncomingSampleCollectionFragmentDoc}
`;
export type DeleteCollectionSampleTypeMutationFn = Apollo.MutationFunction<
  DeleteCollectionSampleTypeMutation,
  DeleteCollectionSampleTypeMutationVariables
>;

/**
 * __useDeleteCollectionSampleTypeMutation__
 *
 * To run a mutation, you first call `useDeleteCollectionSampleTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollectionSampleTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollectionSampleTypeMutation, { data, loading, error }] = useDeleteCollectionSampleTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCollectionSampleTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCollectionSampleTypeMutation,
    DeleteCollectionSampleTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCollectionSampleTypeMutation,
    DeleteCollectionSampleTypeMutationVariables
  >(DeleteCollectionSampleTypeDocument, options);
}
export type DeleteCollectionSampleTypeMutationHookResult = ReturnType<
  typeof useDeleteCollectionSampleTypeMutation
>;
export type DeleteCollectionSampleTypeMutationResult =
  Apollo.MutationResult<DeleteCollectionSampleTypeMutation>;
export type DeleteCollectionSampleTypeMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteCollectionSampleTypeMutation,
    DeleteCollectionSampleTypeMutationVariables
  >;
export const CreateCollectionDocument = gql`
  mutation CreateCollection($input: CreateIncomingSampleCollectionInput!) {
    createIncomingSampleCollection(input: $input) {
      ...IncomingSampleCollection
    }
  }
  ${IncomingSampleCollectionFragmentDoc}
`;
export type CreateCollectionMutationFn = Apollo.MutationFunction<
  CreateCollectionMutation,
  CreateCollectionMutationVariables
>;

/**
 * __useCreateCollectionMutation__
 *
 * To run a mutation, you first call `useCreateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionMutation, { data, loading, error }] = useCreateCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCollectionMutation,
    CreateCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCollectionMutation,
    CreateCollectionMutationVariables
  >(CreateCollectionDocument, options);
}
export type CreateCollectionMutationHookResult = ReturnType<
  typeof useCreateCollectionMutation
>;
export type CreateCollectionMutationResult =
  Apollo.MutationResult<CreateCollectionMutation>;
export type CreateCollectionMutationOptions = Apollo.BaseMutationOptions<
  CreateCollectionMutation,
  CreateCollectionMutationVariables
>;
export const DeleteIncomingSampleCollectionDocument = gql`
  mutation DeleteIncomingSampleCollection($input: DeleteInput!) {
    deleteIncomingSampleCollection(input: $input)
  }
`;
export type DeleteIncomingSampleCollectionMutationFn = Apollo.MutationFunction<
  DeleteIncomingSampleCollectionMutation,
  DeleteIncomingSampleCollectionMutationVariables
>;

/**
 * __useDeleteIncomingSampleCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteIncomingSampleCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIncomingSampleCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIncomingSampleCollectionMutation, { data, loading, error }] = useDeleteIncomingSampleCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteIncomingSampleCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteIncomingSampleCollectionMutation,
    DeleteIncomingSampleCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteIncomingSampleCollectionMutation,
    DeleteIncomingSampleCollectionMutationVariables
  >(DeleteIncomingSampleCollectionDocument, options);
}
export type DeleteIncomingSampleCollectionMutationHookResult = ReturnType<
  typeof useDeleteIncomingSampleCollectionMutation
>;
export type DeleteIncomingSampleCollectionMutationResult =
  Apollo.MutationResult<DeleteIncomingSampleCollectionMutation>;
export type DeleteIncomingSampleCollectionMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteIncomingSampleCollectionMutation,
    DeleteIncomingSampleCollectionMutationVariables
  >;
export const UpdateIncomingSampleCollectionDocument = gql`
  mutation UpdateIncomingSampleCollection(
    $inputs: [UpdateInputOfUpdateIncomingSampleCollectionInputAndCollectionInput!]!
  ) {
    updateIncomingSampleCollections(inputs: $inputs) {
      ...IncomingSampleCollection
    }
  }
  ${IncomingSampleCollectionFragmentDoc}
`;
export type UpdateIncomingSampleCollectionMutationFn = Apollo.MutationFunction<
  UpdateIncomingSampleCollectionMutation,
  UpdateIncomingSampleCollectionMutationVariables
>;

/**
 * __useUpdateIncomingSampleCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateIncomingSampleCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIncomingSampleCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIncomingSampleCollectionMutation, { data, loading, error }] = useUpdateIncomingSampleCollectionMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateIncomingSampleCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIncomingSampleCollectionMutation,
    UpdateIncomingSampleCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateIncomingSampleCollectionMutation,
    UpdateIncomingSampleCollectionMutationVariables
  >(UpdateIncomingSampleCollectionDocument, options);
}
export type UpdateIncomingSampleCollectionMutationHookResult = ReturnType<
  typeof useUpdateIncomingSampleCollectionMutation
>;
export type UpdateIncomingSampleCollectionMutationResult =
  Apollo.MutationResult<UpdateIncomingSampleCollectionMutation>;
export type UpdateIncomingSampleCollectionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateIncomingSampleCollectionMutation,
    UpdateIncomingSampleCollectionMutationVariables
  >;
export const UpdateCollectionTasksDocument = gql`
  mutation UpdateCollectionTasks($inputs: [CollectionTaskInput!]!) {
    updateCollectionTasks(inputs: $inputs) {
      ...IncomingSampleTask
    }
  }
  ${IncomingSampleTaskFragmentDoc}
`;
export type UpdateCollectionTasksMutationFn = Apollo.MutationFunction<
  UpdateCollectionTasksMutation,
  UpdateCollectionTasksMutationVariables
>;

/**
 * __useUpdateCollectionTasksMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionTasksMutation, { data, loading, error }] = useUpdateCollectionTasksMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateCollectionTasksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCollectionTasksMutation,
    UpdateCollectionTasksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCollectionTasksMutation,
    UpdateCollectionTasksMutationVariables
  >(UpdateCollectionTasksDocument, options);
}
export type UpdateCollectionTasksMutationHookResult = ReturnType<
  typeof useUpdateCollectionTasksMutation
>;
export type UpdateCollectionTasksMutationResult =
  Apollo.MutationResult<UpdateCollectionTasksMutation>;
export type UpdateCollectionTasksMutationOptions = Apollo.BaseMutationOptions<
  UpdateCollectionTasksMutation,
  UpdateCollectionTasksMutationVariables
>;
export const AddAssetDocument = gql`
  mutation AddAsset($input: AssetInput) {
    addAsset(input: $input) {
      assetTypeId
      assetType {
        assetTypeId
        assetType
      }
      assetId
      asset
    }
  }
`;
export type AddAssetMutationFn = Apollo.MutationFunction<
  AddAssetMutation,
  AddAssetMutationVariables
>;

/**
 * __useAddAssetMutation__
 *
 * To run a mutation, you first call `useAddAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssetMutation, { data, loading, error }] = useAddAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAssetMutation,
    AddAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAssetMutation, AddAssetMutationVariables>(
    AddAssetDocument,
    options
  );
}
export type AddAssetMutationHookResult = ReturnType<typeof useAddAssetMutation>;
export type AddAssetMutationResult = Apollo.MutationResult<AddAssetMutation>;
export type AddAssetMutationOptions = Apollo.BaseMutationOptions<
  AddAssetMutation,
  AddAssetMutationVariables
>;
export const UpdateManualTaskTypeDocument = gql`
  mutation UpdateManualTaskType(
    $inputs: [UpdateInputOfUpdateManualTaskInputAndManualTaskTypeInput!]
  ) {
    updateManualTasks(inputs: $inputs) {
      ...ManualTaskType
    }
  }
  ${ManualTaskTypeFragmentDoc}
`;
export type UpdateManualTaskTypeMutationFn = Apollo.MutationFunction<
  UpdateManualTaskTypeMutation,
  UpdateManualTaskTypeMutationVariables
>;

/**
 * __useUpdateManualTaskTypeMutation__
 *
 * To run a mutation, you first call `useUpdateManualTaskTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManualTaskTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManualTaskTypeMutation, { data, loading, error }] = useUpdateManualTaskTypeMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateManualTaskTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateManualTaskTypeMutation,
    UpdateManualTaskTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateManualTaskTypeMutation,
    UpdateManualTaskTypeMutationVariables
  >(UpdateManualTaskTypeDocument, options);
}
export type UpdateManualTaskTypeMutationHookResult = ReturnType<
  typeof useUpdateManualTaskTypeMutation
>;
export type UpdateManualTaskTypeMutationResult =
  Apollo.MutationResult<UpdateManualTaskTypeMutation>;
export type UpdateManualTaskTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateManualTaskTypeMutation,
  UpdateManualTaskTypeMutationVariables
>;
export const UpdateMethodDocument = gql`
  mutation UpdateMethod(
    $inputs: [UpdateInputOfUpdateMethodInputAndLookupArrayMethodInput!]
  ) {
    updateMethods(inputs: $inputs) {
      ...Method
    }
  }
  ${MethodFragmentDoc}
`;
export type UpdateMethodMutationFn = Apollo.MutationFunction<
  UpdateMethodMutation,
  UpdateMethodMutationVariables
>;

/**
 * __useUpdateMethodMutation__
 *
 * To run a mutation, you first call `useUpdateMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMethodMutation, { data, loading, error }] = useUpdateMethodMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMethodMutation,
    UpdateMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMethodMutation,
    UpdateMethodMutationVariables
  >(UpdateMethodDocument, options);
}
export type UpdateMethodMutationHookResult = ReturnType<
  typeof useUpdateMethodMutation
>;
export type UpdateMethodMutationResult =
  Apollo.MutationResult<UpdateMethodMutation>;
export type UpdateMethodMutationOptions = Apollo.BaseMutationOptions<
  UpdateMethodMutation,
  UpdateMethodMutationVariables
>;
export const UpdateRunTaskTypeDocumentationDocument = gql`
  mutation UpdateRunTaskTypeDocumentation(
    $input: UpdateMethodDocumentationInput
  ) {
    updateRunTaskTypeDocumentation(input: $input) {
      id
      compositeKey
      type
      manualTaskType {
        ...ManualTaskType
      }
      method {
        ...Method
      }
    }
  }
  ${ManualTaskTypeFragmentDoc}
  ${MethodFragmentDoc}
`;
export type UpdateRunTaskTypeDocumentationMutationFn = Apollo.MutationFunction<
  UpdateRunTaskTypeDocumentationMutation,
  UpdateRunTaskTypeDocumentationMutationVariables
>;

/**
 * __useUpdateRunTaskTypeDocumentationMutation__
 *
 * To run a mutation, you first call `useUpdateRunTaskTypeDocumentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRunTaskTypeDocumentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRunTaskTypeDocumentationMutation, { data, loading, error }] = useUpdateRunTaskTypeDocumentationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRunTaskTypeDocumentationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRunTaskTypeDocumentationMutation,
    UpdateRunTaskTypeDocumentationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRunTaskTypeDocumentationMutation,
    UpdateRunTaskTypeDocumentationMutationVariables
  >(UpdateRunTaskTypeDocumentationDocument, options);
}
export type UpdateRunTaskTypeDocumentationMutationHookResult = ReturnType<
  typeof useUpdateRunTaskTypeDocumentationMutation
>;
export type UpdateRunTaskTypeDocumentationMutationResult =
  Apollo.MutationResult<UpdateRunTaskTypeDocumentationMutation>;
export type UpdateRunTaskTypeDocumentationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateRunTaskTypeDocumentationMutation,
    UpdateRunTaskTypeDocumentationMutationVariables
  >;
export const AddPersonalTaskDocument = gql`
  mutation addPersonalTask($model: PersonalTaskInput) {
    addPersonalTask(input: $model) {
      taskId
    }
  }
`;
export type AddPersonalTaskMutationFn = Apollo.MutationFunction<
  AddPersonalTaskMutation,
  AddPersonalTaskMutationVariables
>;

/**
 * __useAddPersonalTaskMutation__
 *
 * To run a mutation, you first call `useAddPersonalTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPersonalTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPersonalTaskMutation, { data, loading, error }] = useAddPersonalTaskMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useAddPersonalTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPersonalTaskMutation,
    AddPersonalTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPersonalTaskMutation,
    AddPersonalTaskMutationVariables
  >(AddPersonalTaskDocument, options);
}
export type AddPersonalTaskMutationHookResult = ReturnType<
  typeof useAddPersonalTaskMutation
>;
export type AddPersonalTaskMutationResult =
  Apollo.MutationResult<AddPersonalTaskMutation>;
export type AddPersonalTaskMutationOptions = Apollo.BaseMutationOptions<
  AddPersonalTaskMutation,
  AddPersonalTaskMutationVariables
>;
export const AddRecurringPersonalTaskDocument = gql`
  mutation addRecurringPersonalTask($model: PersonalTaskInput) {
    addRecurringPersonalTask(input: $model) {
      recurringTaskId
    }
  }
`;
export type AddRecurringPersonalTaskMutationFn = Apollo.MutationFunction<
  AddRecurringPersonalTaskMutation,
  AddRecurringPersonalTaskMutationVariables
>;

/**
 * __useAddRecurringPersonalTaskMutation__
 *
 * To run a mutation, you first call `useAddRecurringPersonalTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRecurringPersonalTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRecurringPersonalTaskMutation, { data, loading, error }] = useAddRecurringPersonalTaskMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useAddRecurringPersonalTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRecurringPersonalTaskMutation,
    AddRecurringPersonalTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddRecurringPersonalTaskMutation,
    AddRecurringPersonalTaskMutationVariables
  >(AddRecurringPersonalTaskDocument, options);
}
export type AddRecurringPersonalTaskMutationHookResult = ReturnType<
  typeof useAddRecurringPersonalTaskMutation
>;
export type AddRecurringPersonalTaskMutationResult =
  Apollo.MutationResult<AddRecurringPersonalTaskMutation>;
export type AddRecurringPersonalTaskMutationOptions =
  Apollo.BaseMutationOptions<
    AddRecurringPersonalTaskMutation,
    AddRecurringPersonalTaskMutationVariables
  >;
export const UpdatePersonalTasksDocument = gql`
  mutation updatePersonalTasks($inputs: [PersonalTaskInput]) {
    updatePersonalTasks(inputs: $inputs) {
      taskId
    }
  }
`;
export type UpdatePersonalTasksMutationFn = Apollo.MutationFunction<
  UpdatePersonalTasksMutation,
  UpdatePersonalTasksMutationVariables
>;

/**
 * __useUpdatePersonalTasksMutation__
 *
 * To run a mutation, you first call `useUpdatePersonalTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonalTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonalTasksMutation, { data, loading, error }] = useUpdatePersonalTasksMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdatePersonalTasksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePersonalTasksMutation,
    UpdatePersonalTasksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePersonalTasksMutation,
    UpdatePersonalTasksMutationVariables
  >(UpdatePersonalTasksDocument, options);
}
export type UpdatePersonalTasksMutationHookResult = ReturnType<
  typeof useUpdatePersonalTasksMutation
>;
export type UpdatePersonalTasksMutationResult =
  Apollo.MutationResult<UpdatePersonalTasksMutation>;
export type UpdatePersonalTasksMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonalTasksMutation,
  UpdatePersonalTasksMutationVariables
>;
export const DeleteRecurringPersonalTaskDocument = gql`
  mutation deleteRecurringPersonalTask($recurringTaskId: Int!) {
    deleteRecurringPersonalTask(recurringTaskId: $recurringTaskId) {
      recurringTaskId
    }
  }
`;
export type DeleteRecurringPersonalTaskMutationFn = Apollo.MutationFunction<
  DeleteRecurringPersonalTaskMutation,
  DeleteRecurringPersonalTaskMutationVariables
>;

/**
 * __useDeleteRecurringPersonalTaskMutation__
 *
 * To run a mutation, you first call `useDeleteRecurringPersonalTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecurringPersonalTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecurringPersonalTaskMutation, { data, loading, error }] = useDeleteRecurringPersonalTaskMutation({
 *   variables: {
 *      recurringTaskId: // value for 'recurringTaskId'
 *   },
 * });
 */
export function useDeleteRecurringPersonalTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRecurringPersonalTaskMutation,
    DeleteRecurringPersonalTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRecurringPersonalTaskMutation,
    DeleteRecurringPersonalTaskMutationVariables
  >(DeleteRecurringPersonalTaskDocument, options);
}
export type DeleteRecurringPersonalTaskMutationHookResult = ReturnType<
  typeof useDeleteRecurringPersonalTaskMutation
>;
export type DeleteRecurringPersonalTaskMutationResult =
  Apollo.MutationResult<DeleteRecurringPersonalTaskMutation>;
export type DeleteRecurringPersonalTaskMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteRecurringPersonalTaskMutation,
    DeleteRecurringPersonalTaskMutationVariables
  >;
export const UpdateRecurringTaskDocument = gql`
  mutation updateRecurringTask(
    $recurringTask: PersonalTasksRecurringTaskInput
  ) {
    updateRecurringTask(recurringTask: $recurringTask) {
      recurringTaskId
    }
  }
`;
export type UpdateRecurringTaskMutationFn = Apollo.MutationFunction<
  UpdateRecurringTaskMutation,
  UpdateRecurringTaskMutationVariables
>;

/**
 * __useUpdateRecurringTaskMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringTaskMutation, { data, loading, error }] = useUpdateRecurringTaskMutation({
 *   variables: {
 *      recurringTask: // value for 'recurringTask'
 *   },
 * });
 */
export function useUpdateRecurringTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecurringTaskMutation,
    UpdateRecurringTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRecurringTaskMutation,
    UpdateRecurringTaskMutationVariables
  >(UpdateRecurringTaskDocument, options);
}
export type UpdateRecurringTaskMutationHookResult = ReturnType<
  typeof useUpdateRecurringTaskMutation
>;
export type UpdateRecurringTaskMutationResult =
  Apollo.MutationResult<UpdateRecurringTaskMutation>;
export type UpdateRecurringTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecurringTaskMutation,
  UpdateRecurringTaskMutationVariables
>;
export const OverwriteRecurringTasksDocument = gql`
  mutation overwriteRecurringTasks($input: OverwriteRecurringTaskInput) {
    overwriteRecurringTasks(input: $input)
  }
`;
export type OverwriteRecurringTasksMutationFn = Apollo.MutationFunction<
  OverwriteRecurringTasksMutation,
  OverwriteRecurringTasksMutationVariables
>;

/**
 * __useOverwriteRecurringTasksMutation__
 *
 * To run a mutation, you first call `useOverwriteRecurringTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOverwriteRecurringTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overwriteRecurringTasksMutation, { data, loading, error }] = useOverwriteRecurringTasksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOverwriteRecurringTasksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OverwriteRecurringTasksMutation,
    OverwriteRecurringTasksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OverwriteRecurringTasksMutation,
    OverwriteRecurringTasksMutationVariables
  >(OverwriteRecurringTasksDocument, options);
}
export type OverwriteRecurringTasksMutationHookResult = ReturnType<
  typeof useOverwriteRecurringTasksMutation
>;
export type OverwriteRecurringTasksMutationResult =
  Apollo.MutationResult<OverwriteRecurringTasksMutation>;
export type OverwriteRecurringTasksMutationOptions = Apollo.BaseMutationOptions<
  OverwriteRecurringTasksMutation,
  OverwriteRecurringTasksMutationVariables
>;
export const CancelRemainingReccuringTasksDocument = gql`
  mutation cancelRemainingReccuringTasks($recurringTaskId: Int!) {
    cancelRemainingReccuringTasks(recurringTaskId: $recurringTaskId) {
      recurringTaskId
    }
  }
`;
export type CancelRemainingReccuringTasksMutationFn = Apollo.MutationFunction<
  CancelRemainingReccuringTasksMutation,
  CancelRemainingReccuringTasksMutationVariables
>;

/**
 * __useCancelRemainingReccuringTasksMutation__
 *
 * To run a mutation, you first call `useCancelRemainingReccuringTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRemainingReccuringTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRemainingReccuringTasksMutation, { data, loading, error }] = useCancelRemainingReccuringTasksMutation({
 *   variables: {
 *      recurringTaskId: // value for 'recurringTaskId'
 *   },
 * });
 */
export function useCancelRemainingReccuringTasksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelRemainingReccuringTasksMutation,
    CancelRemainingReccuringTasksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelRemainingReccuringTasksMutation,
    CancelRemainingReccuringTasksMutationVariables
  >(CancelRemainingReccuringTasksDocument, options);
}
export type CancelRemainingReccuringTasksMutationHookResult = ReturnType<
  typeof useCancelRemainingReccuringTasksMutation
>;
export type CancelRemainingReccuringTasksMutationResult =
  Apollo.MutationResult<CancelRemainingReccuringTasksMutation>;
export type CancelRemainingReccuringTasksMutationOptions =
  Apollo.BaseMutationOptions<
    CancelRemainingReccuringTasksMutation,
    CancelRemainingReccuringTasksMutationVariables
  >;
export const CreatePersonalTaskTemplateDocument = gql`
  mutation createPersonalTaskTemplate($templateName: String) {
    createPersonalTaskTemplate(templateName: $templateName) {
      personalTaskTemplateId
    }
  }
`;
export type CreatePersonalTaskTemplateMutationFn = Apollo.MutationFunction<
  CreatePersonalTaskTemplateMutation,
  CreatePersonalTaskTemplateMutationVariables
>;

/**
 * __useCreatePersonalTaskTemplateMutation__
 *
 * To run a mutation, you first call `useCreatePersonalTaskTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonalTaskTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonalTaskTemplateMutation, { data, loading, error }] = useCreatePersonalTaskTemplateMutation({
 *   variables: {
 *      templateName: // value for 'templateName'
 *   },
 * });
 */
export function useCreatePersonalTaskTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePersonalTaskTemplateMutation,
    CreatePersonalTaskTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePersonalTaskTemplateMutation,
    CreatePersonalTaskTemplateMutationVariables
  >(CreatePersonalTaskTemplateDocument, options);
}
export type CreatePersonalTaskTemplateMutationHookResult = ReturnType<
  typeof useCreatePersonalTaskTemplateMutation
>;
export type CreatePersonalTaskTemplateMutationResult =
  Apollo.MutationResult<CreatePersonalTaskTemplateMutation>;
export type CreatePersonalTaskTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    CreatePersonalTaskTemplateMutation,
    CreatePersonalTaskTemplateMutationVariables
  >;
export const AddPersonalTaskTemplateTaskDocument = gql`
  mutation addPersonalTaskTemplateTask($input: PersonalTaskTemplateTaskInput) {
    addPersonalTaskTemplateTask(input: $input) {
      personalTaskTemplateId
    }
  }
`;
export type AddPersonalTaskTemplateTaskMutationFn = Apollo.MutationFunction<
  AddPersonalTaskTemplateTaskMutation,
  AddPersonalTaskTemplateTaskMutationVariables
>;

/**
 * __useAddPersonalTaskTemplateTaskMutation__
 *
 * To run a mutation, you first call `useAddPersonalTaskTemplateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPersonalTaskTemplateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPersonalTaskTemplateTaskMutation, { data, loading, error }] = useAddPersonalTaskTemplateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPersonalTaskTemplateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPersonalTaskTemplateTaskMutation,
    AddPersonalTaskTemplateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPersonalTaskTemplateTaskMutation,
    AddPersonalTaskTemplateTaskMutationVariables
  >(AddPersonalTaskTemplateTaskDocument, options);
}
export type AddPersonalTaskTemplateTaskMutationHookResult = ReturnType<
  typeof useAddPersonalTaskTemplateTaskMutation
>;
export type AddPersonalTaskTemplateTaskMutationResult =
  Apollo.MutationResult<AddPersonalTaskTemplateTaskMutation>;
export type AddPersonalTaskTemplateTaskMutationOptions =
  Apollo.BaseMutationOptions<
    AddPersonalTaskTemplateTaskMutation,
    AddPersonalTaskTemplateTaskMutationVariables
  >;
export const StartPersonalTaskTemplateDocument = gql`
  mutation startPersonalTaskTemplate($input: StartPersonalTaskTemplateInput) {
    startPersonalTaskTemplate(input: $input)
  }
`;
export type StartPersonalTaskTemplateMutationFn = Apollo.MutationFunction<
  StartPersonalTaskTemplateMutation,
  StartPersonalTaskTemplateMutationVariables
>;

/**
 * __useStartPersonalTaskTemplateMutation__
 *
 * To run a mutation, you first call `useStartPersonalTaskTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPersonalTaskTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPersonalTaskTemplateMutation, { data, loading, error }] = useStartPersonalTaskTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartPersonalTaskTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartPersonalTaskTemplateMutation,
    StartPersonalTaskTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartPersonalTaskTemplateMutation,
    StartPersonalTaskTemplateMutationVariables
  >(StartPersonalTaskTemplateDocument, options);
}
export type StartPersonalTaskTemplateMutationHookResult = ReturnType<
  typeof useStartPersonalTaskTemplateMutation
>;
export type StartPersonalTaskTemplateMutationResult =
  Apollo.MutationResult<StartPersonalTaskTemplateMutation>;
export type StartPersonalTaskTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    StartPersonalTaskTemplateMutation,
    StartPersonalTaskTemplateMutationVariables
  >;
export const UpdatePersonalTaskTemplateTaskDocument = gql`
  mutation updatePersonalTaskTemplateTask(
    $input: PersonalTaskTemplateTaskInput
  ) {
    updatePersonalTaskTemplateTask(input: $input) {
      personalTaskTemplateId
      personalTaskTemplateTaskId
      personalTaskName
      dayScheduled
      userAssignedTo
      groupAssignedTo
      notes
      priority
      groups {
        groupId
      }
      users {
        userId
      }
    }
  }
`;
export type UpdatePersonalTaskTemplateTaskMutationFn = Apollo.MutationFunction<
  UpdatePersonalTaskTemplateTaskMutation,
  UpdatePersonalTaskTemplateTaskMutationVariables
>;

/**
 * __useUpdatePersonalTaskTemplateTaskMutation__
 *
 * To run a mutation, you first call `useUpdatePersonalTaskTemplateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonalTaskTemplateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonalTaskTemplateTaskMutation, { data, loading, error }] = useUpdatePersonalTaskTemplateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePersonalTaskTemplateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePersonalTaskTemplateTaskMutation,
    UpdatePersonalTaskTemplateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePersonalTaskTemplateTaskMutation,
    UpdatePersonalTaskTemplateTaskMutationVariables
  >(UpdatePersonalTaskTemplateTaskDocument, options);
}
export type UpdatePersonalTaskTemplateTaskMutationHookResult = ReturnType<
  typeof useUpdatePersonalTaskTemplateTaskMutation
>;
export type UpdatePersonalTaskTemplateTaskMutationResult =
  Apollo.MutationResult<UpdatePersonalTaskTemplateTaskMutation>;
export type UpdatePersonalTaskTemplateTaskMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePersonalTaskTemplateTaskMutation,
    UpdatePersonalTaskTemplateTaskMutationVariables
  >;
export const CreateRunDocument = gql`
  mutation createRun($run: CreateRunInput) {
    createRun(runInput: $run) {
      ...Run
    }
  }
  ${RunFragmentDoc}
`;
export type CreateRunMutationFn = Apollo.MutationFunction<
  CreateRunMutation,
  CreateRunMutationVariables
>;

/**
 * __useCreateRunMutation__
 *
 * To run a mutation, you first call `useCreateRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRunMutation, { data, loading, error }] = useCreateRunMutation({
 *   variables: {
 *      run: // value for 'run'
 *   },
 * });
 */
export function useCreateRunMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRunMutation,
    CreateRunMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRunMutation, CreateRunMutationVariables>(
    CreateRunDocument,
    options
  );
}
export type CreateRunMutationHookResult = ReturnType<
  typeof useCreateRunMutation
>;
export type CreateRunMutationResult = Apollo.MutationResult<CreateRunMutation>;
export type CreateRunMutationOptions = Apollo.BaseMutationOptions<
  CreateRunMutation,
  CreateRunMutationVariables
>;
export const UpdateRunTasksDocument = gql`
  mutation updateRunTasks($updateInputs: [RunTaskInput]) {
    updateRunTasks(updateInputs: $updateInputs) {
      ...RunTask
      ...RunTaskMetaData
    }
  }
  ${RunTaskFragmentDoc}
  ${RunTaskMetaDataFragmentDoc}
`;
export type UpdateRunTasksMutationFn = Apollo.MutationFunction<
  UpdateRunTasksMutation,
  UpdateRunTasksMutationVariables
>;

/**
 * __useUpdateRunTasksMutation__
 *
 * To run a mutation, you first call `useUpdateRunTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRunTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRunTasksMutation, { data, loading, error }] = useUpdateRunTasksMutation({
 *   variables: {
 *      updateInputs: // value for 'updateInputs'
 *   },
 * });
 */
export function useUpdateRunTasksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRunTasksMutation,
    UpdateRunTasksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRunTasksMutation,
    UpdateRunTasksMutationVariables
  >(UpdateRunTasksDocument, options);
}
export type UpdateRunTasksMutationHookResult = ReturnType<
  typeof useUpdateRunTasksMutation
>;
export type UpdateRunTasksMutationResult =
  Apollo.MutationResult<UpdateRunTasksMutation>;
export type UpdateRunTasksMutationOptions = Apollo.BaseMutationOptions<
  UpdateRunTasksMutation,
  UpdateRunTasksMutationVariables
>;
export const ReserveCeligoRunTaskMethodDocument = gql`
  mutation ReserveCeligoRunTaskMethod($input: RunTaskInput) {
    reserveCeligoRunTaskMethod(input: $input) {
      ...RunTaskMetaData
    }
  }
  ${RunTaskMetaDataFragmentDoc}
`;
export type ReserveCeligoRunTaskMethodMutationFn = Apollo.MutationFunction<
  ReserveCeligoRunTaskMethodMutation,
  ReserveCeligoRunTaskMethodMutationVariables
>;

/**
 * __useReserveCeligoRunTaskMethodMutation__
 *
 * To run a mutation, you first call `useReserveCeligoRunTaskMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReserveCeligoRunTaskMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reserveCeligoRunTaskMethodMutation, { data, loading, error }] = useReserveCeligoRunTaskMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReserveCeligoRunTaskMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReserveCeligoRunTaskMethodMutation,
    ReserveCeligoRunTaskMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReserveCeligoRunTaskMethodMutation,
    ReserveCeligoRunTaskMethodMutationVariables
  >(ReserveCeligoRunTaskMethodDocument, options);
}
export type ReserveCeligoRunTaskMethodMutationHookResult = ReturnType<
  typeof useReserveCeligoRunTaskMethodMutation
>;
export type ReserveCeligoRunTaskMethodMutationResult =
  Apollo.MutationResult<ReserveCeligoRunTaskMethodMutation>;
export type ReserveCeligoRunTaskMethodMutationOptions =
  Apollo.BaseMutationOptions<
    ReserveCeligoRunTaskMethodMutation,
    ReserveCeligoRunTaskMethodMutationVariables
  >;
export const CreateRunGanttDocument = gql`
  mutation CreateRunGantt($runId: Int!) {
    createRunGantt(runId: $runId) {
      ...Run
    }
  }
  ${RunFragmentDoc}
`;
export type CreateRunGanttMutationFn = Apollo.MutationFunction<
  CreateRunGanttMutation,
  CreateRunGanttMutationVariables
>;

/**
 * __useCreateRunGanttMutation__
 *
 * To run a mutation, you first call `useCreateRunGanttMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRunGanttMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRunGanttMutation, { data, loading, error }] = useCreateRunGanttMutation({
 *   variables: {
 *      runId: // value for 'runId'
 *   },
 * });
 */
export function useCreateRunGanttMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRunGanttMutation,
    CreateRunGanttMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRunGanttMutation,
    CreateRunGanttMutationVariables
  >(CreateRunGanttDocument, options);
}
export type CreateRunGanttMutationHookResult = ReturnType<
  typeof useCreateRunGanttMutation
>;
export type CreateRunGanttMutationResult =
  Apollo.MutationResult<CreateRunGanttMutation>;
export type CreateRunGanttMutationOptions = Apollo.BaseMutationOptions<
  CreateRunGanttMutation,
  CreateRunGanttMutationVariables
>;
export const DeleteRunActivityMethodReservationDocument = gql`
  mutation deleteRunActivityMethodReservation($deleteInput: DeleteInput!) {
    deleteRunActivityMethodReservation(deleteInput: $deleteInput) {
      ...RunTaskMetaData
    }
  }
  ${RunTaskMetaDataFragmentDoc}
`;
export type DeleteRunActivityMethodReservationMutationFn =
  Apollo.MutationFunction<
    DeleteRunActivityMethodReservationMutation,
    DeleteRunActivityMethodReservationMutationVariables
  >;

/**
 * __useDeleteRunActivityMethodReservationMutation__
 *
 * To run a mutation, you first call `useDeleteRunActivityMethodReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRunActivityMethodReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRunActivityMethodReservationMutation, { data, loading, error }] = useDeleteRunActivityMethodReservationMutation({
 *   variables: {
 *      deleteInput: // value for 'deleteInput'
 *   },
 * });
 */
export function useDeleteRunActivityMethodReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRunActivityMethodReservationMutation,
    DeleteRunActivityMethodReservationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRunActivityMethodReservationMutation,
    DeleteRunActivityMethodReservationMutationVariables
  >(DeleteRunActivityMethodReservationDocument, options);
}
export type DeleteRunActivityMethodReservationMutationHookResult = ReturnType<
  typeof useDeleteRunActivityMethodReservationMutation
>;
export type DeleteRunActivityMethodReservationMutationResult =
  Apollo.MutationResult<DeleteRunActivityMethodReservationMutation>;
export type DeleteRunActivityMethodReservationMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteRunActivityMethodReservationMutation,
    DeleteRunActivityMethodReservationMutationVariables
  >;
export const DeleteTaskMethodReservationDocument = gql`
  mutation deleteTaskMethodReservation($deleteInput: DeleteInput!) {
    deleteTaskMethodReservation(deleteInput: $deleteInput)
  }
`;
export type DeleteTaskMethodReservationMutationFn = Apollo.MutationFunction<
  DeleteTaskMethodReservationMutation,
  DeleteTaskMethodReservationMutationVariables
>;

/**
 * __useDeleteTaskMethodReservationMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMethodReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMethodReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMethodReservationMutation, { data, loading, error }] = useDeleteTaskMethodReservationMutation({
 *   variables: {
 *      deleteInput: // value for 'deleteInput'
 *   },
 * });
 */
export function useDeleteTaskMethodReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskMethodReservationMutation,
    DeleteTaskMethodReservationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTaskMethodReservationMutation,
    DeleteTaskMethodReservationMutationVariables
  >(DeleteTaskMethodReservationDocument, options);
}
export type DeleteTaskMethodReservationMutationHookResult = ReturnType<
  typeof useDeleteTaskMethodReservationMutation
>;
export type DeleteTaskMethodReservationMutationResult =
  Apollo.MutationResult<DeleteTaskMethodReservationMutation>;
export type DeleteTaskMethodReservationMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteTaskMethodReservationMutation,
    DeleteTaskMethodReservationMutationVariables
  >;
export const AddRunTaskDocument = gql`
  mutation addRunTask($tasks: [RunTaskInput]) {
    addRunTasks(tasks: $tasks) {
      ...RunTask
    }
  }
  ${RunTaskFragmentDoc}
`;
export type AddRunTaskMutationFn = Apollo.MutationFunction<
  AddRunTaskMutation,
  AddRunTaskMutationVariables
>;

/**
 * __useAddRunTaskMutation__
 *
 * To run a mutation, you first call `useAddRunTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRunTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRunTaskMutation, { data, loading, error }] = useAddRunTaskMutation({
 *   variables: {
 *      tasks: // value for 'tasks'
 *   },
 * });
 */
export function useAddRunTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRunTaskMutation,
    AddRunTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddRunTaskMutation, AddRunTaskMutationVariables>(
    AddRunTaskDocument,
    options
  );
}
export type AddRunTaskMutationHookResult = ReturnType<
  typeof useAddRunTaskMutation
>;
export type AddRunTaskMutationResult =
  Apollo.MutationResult<AddRunTaskMutation>;
export type AddRunTaskMutationOptions = Apollo.BaseMutationOptions<
  AddRunTaskMutation,
  AddRunTaskMutationVariables
>;
export const UpdateRunDocument = gql`
  mutation UpdateRun($input: UpdateInputOfUpdateRunInputAndRunInput) {
    updateRun(input: $input) {
      folderId
      folderName
      folderParentId
      runs {
        ...Run
        runTasks {
          runTaskId
          daysFromRunStart
          startTimeScheduled
        }
      }
    }
  }
  ${RunFragmentDoc}
`;
export type UpdateRunMutationFn = Apollo.MutationFunction<
  UpdateRunMutation,
  UpdateRunMutationVariables
>;

/**
 * __useUpdateRunMutation__
 *
 * To run a mutation, you first call `useUpdateRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRunMutation, { data, loading, error }] = useUpdateRunMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRunMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRunMutation,
    UpdateRunMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRunMutation, UpdateRunMutationVariables>(
    UpdateRunDocument,
    options
  );
}
export type UpdateRunMutationHookResult = ReturnType<
  typeof useUpdateRunMutation
>;
export type UpdateRunMutationResult = Apollo.MutationResult<UpdateRunMutation>;
export type UpdateRunMutationOptions = Apollo.BaseMutationOptions<
  UpdateRunMutation,
  UpdateRunMutationVariables
>;
export const DeleteRunDocument = gql`
  mutation DeleteRun($input: DeleteInput) {
    deleteRun(input: $input) {
      ...FolderStructure
    }
  }
  ${FolderStructureFragmentDoc}
`;
export type DeleteRunMutationFn = Apollo.MutationFunction<
  DeleteRunMutation,
  DeleteRunMutationVariables
>;

/**
 * __useDeleteRunMutation__
 *
 * To run a mutation, you first call `useDeleteRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRunMutation, { data, loading, error }] = useDeleteRunMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRunMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRunMutation,
    DeleteRunMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRunMutation, DeleteRunMutationVariables>(
    DeleteRunDocument,
    options
  );
}
export type DeleteRunMutationHookResult = ReturnType<
  typeof useDeleteRunMutation
>;
export type DeleteRunMutationResult = Apollo.MutationResult<DeleteRunMutation>;
export type DeleteRunMutationOptions = Apollo.BaseMutationOptions<
  DeleteRunMutation,
  DeleteRunMutationVariables
>;
export const CreateRunTemplateDocument = gql`
  mutation CreateRunTemplate($template: CreateRunTemplateInput) {
    createRunTemplate(createRunTemplate: $template) {
      ...RunTemplate
    }
  }
  ${RunTemplateFragmentDoc}
`;
export type CreateRunTemplateMutationFn = Apollo.MutationFunction<
  CreateRunTemplateMutation,
  CreateRunTemplateMutationVariables
>;

/**
 * __useCreateRunTemplateMutation__
 *
 * To run a mutation, you first call `useCreateRunTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRunTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRunTemplateMutation, { data, loading, error }] = useCreateRunTemplateMutation({
 *   variables: {
 *      template: // value for 'template'
 *   },
 * });
 */
export function useCreateRunTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRunTemplateMutation,
    CreateRunTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRunTemplateMutation,
    CreateRunTemplateMutationVariables
  >(CreateRunTemplateDocument, options);
}
export type CreateRunTemplateMutationHookResult = ReturnType<
  typeof useCreateRunTemplateMutation
>;
export type CreateRunTemplateMutationResult =
  Apollo.MutationResult<CreateRunTemplateMutation>;
export type CreateRunTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateRunTemplateMutation,
  CreateRunTemplateMutationVariables
>;
export const AddRunTemplateStagesDocument = gql`
  mutation AddRunTemplateStages($stages: [CreateRunTemplateStageInput]) {
    addRunTemplateStages(stages: $stages) {
      ...RunTemplate
    }
  }
  ${RunTemplateFragmentDoc}
`;
export type AddRunTemplateStagesMutationFn = Apollo.MutationFunction<
  AddRunTemplateStagesMutation,
  AddRunTemplateStagesMutationVariables
>;

/**
 * __useAddRunTemplateStagesMutation__
 *
 * To run a mutation, you first call `useAddRunTemplateStagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRunTemplateStagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRunTemplateStagesMutation, { data, loading, error }] = useAddRunTemplateStagesMutation({
 *   variables: {
 *      stages: // value for 'stages'
 *   },
 * });
 */
export function useAddRunTemplateStagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRunTemplateStagesMutation,
    AddRunTemplateStagesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddRunTemplateStagesMutation,
    AddRunTemplateStagesMutationVariables
  >(AddRunTemplateStagesDocument, options);
}
export type AddRunTemplateStagesMutationHookResult = ReturnType<
  typeof useAddRunTemplateStagesMutation
>;
export type AddRunTemplateStagesMutationResult =
  Apollo.MutationResult<AddRunTemplateStagesMutation>;
export type AddRunTemplateStagesMutationOptions = Apollo.BaseMutationOptions<
  AddRunTemplateStagesMutation,
  AddRunTemplateStagesMutationVariables
>;
export const AddRunTemplateTasksDocument = gql`
  mutation AddRunTemplateTasks($tasks: [RunTemplateTaskInput]) {
    addRunTemplateTasks(tasks: $tasks) {
      ...RunTemplateStage
    }
  }
  ${RunTemplateStageFragmentDoc}
`;
export type AddRunTemplateTasksMutationFn = Apollo.MutationFunction<
  AddRunTemplateTasksMutation,
  AddRunTemplateTasksMutationVariables
>;

/**
 * __useAddRunTemplateTasksMutation__
 *
 * To run a mutation, you first call `useAddRunTemplateTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRunTemplateTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRunTemplateTasksMutation, { data, loading, error }] = useAddRunTemplateTasksMutation({
 *   variables: {
 *      tasks: // value for 'tasks'
 *   },
 * });
 */
export function useAddRunTemplateTasksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRunTemplateTasksMutation,
    AddRunTemplateTasksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddRunTemplateTasksMutation,
    AddRunTemplateTasksMutationVariables
  >(AddRunTemplateTasksDocument, options);
}
export type AddRunTemplateTasksMutationHookResult = ReturnType<
  typeof useAddRunTemplateTasksMutation
>;
export type AddRunTemplateTasksMutationResult =
  Apollo.MutationResult<AddRunTemplateTasksMutation>;
export type AddRunTemplateTasksMutationOptions = Apollo.BaseMutationOptions<
  AddRunTemplateTasksMutation,
  AddRunTemplateTasksMutationVariables
>;
export const AddRunTemplateChildDocument = gql`
  mutation AddRunTemplateChild($childTemplate: CreateRunTemplateTreeNodeInput) {
    addChildRunTemplate(input: $childTemplate) {
      ...RunTemplate
    }
  }
  ${RunTemplateFragmentDoc}
`;
export type AddRunTemplateChildMutationFn = Apollo.MutationFunction<
  AddRunTemplateChildMutation,
  AddRunTemplateChildMutationVariables
>;

/**
 * __useAddRunTemplateChildMutation__
 *
 * To run a mutation, you first call `useAddRunTemplateChildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRunTemplateChildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRunTemplateChildMutation, { data, loading, error }] = useAddRunTemplateChildMutation({
 *   variables: {
 *      childTemplate: // value for 'childTemplate'
 *   },
 * });
 */
export function useAddRunTemplateChildMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRunTemplateChildMutation,
    AddRunTemplateChildMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddRunTemplateChildMutation,
    AddRunTemplateChildMutationVariables
  >(AddRunTemplateChildDocument, options);
}
export type AddRunTemplateChildMutationHookResult = ReturnType<
  typeof useAddRunTemplateChildMutation
>;
export type AddRunTemplateChildMutationResult =
  Apollo.MutationResult<AddRunTemplateChildMutation>;
export type AddRunTemplateChildMutationOptions = Apollo.BaseMutationOptions<
  AddRunTemplateChildMutation,
  AddRunTemplateChildMutationVariables
>;
export const UpdateRunTemplateDocument = gql`
  mutation UpdateRunTemplate(
    $updateInput: UpdateInputOfUpdateRunTemplateInputAndRunTemplateInput
  ) {
    updateRunTemplate(updateInput: $updateInput) {
      ...RunTemplate
    }
  }
  ${RunTemplateFragmentDoc}
`;
export type UpdateRunTemplateMutationFn = Apollo.MutationFunction<
  UpdateRunTemplateMutation,
  UpdateRunTemplateMutationVariables
>;

/**
 * __useUpdateRunTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateRunTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRunTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRunTemplateMutation, { data, loading, error }] = useUpdateRunTemplateMutation({
 *   variables: {
 *      updateInput: // value for 'updateInput'
 *   },
 * });
 */
export function useUpdateRunTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRunTemplateMutation,
    UpdateRunTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRunTemplateMutation,
    UpdateRunTemplateMutationVariables
  >(UpdateRunTemplateDocument, options);
}
export type UpdateRunTemplateMutationHookResult = ReturnType<
  typeof useUpdateRunTemplateMutation
>;
export type UpdateRunTemplateMutationResult =
  Apollo.MutationResult<UpdateRunTemplateMutation>;
export type UpdateRunTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateRunTemplateMutation,
  UpdateRunTemplateMutationVariables
>;
export const UpdateRunTemplateStagesDocument = gql`
  mutation UpdateRunTemplateStages(
    $updateInputs: [UpdateInputOfUpdateRunTemplateStageInputAndRunTemplateStageInput]
  ) {
    updateRunTemplateStages(updateInputs: $updateInputs) {
      ...RunTemplate
    }
  }
  ${RunTemplateFragmentDoc}
`;
export type UpdateRunTemplateStagesMutationFn = Apollo.MutationFunction<
  UpdateRunTemplateStagesMutation,
  UpdateRunTemplateStagesMutationVariables
>;

/**
 * __useUpdateRunTemplateStagesMutation__
 *
 * To run a mutation, you first call `useUpdateRunTemplateStagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRunTemplateStagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRunTemplateStagesMutation, { data, loading, error }] = useUpdateRunTemplateStagesMutation({
 *   variables: {
 *      updateInputs: // value for 'updateInputs'
 *   },
 * });
 */
export function useUpdateRunTemplateStagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRunTemplateStagesMutation,
    UpdateRunTemplateStagesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRunTemplateStagesMutation,
    UpdateRunTemplateStagesMutationVariables
  >(UpdateRunTemplateStagesDocument, options);
}
export type UpdateRunTemplateStagesMutationHookResult = ReturnType<
  typeof useUpdateRunTemplateStagesMutation
>;
export type UpdateRunTemplateStagesMutationResult =
  Apollo.MutationResult<UpdateRunTemplateStagesMutation>;
export type UpdateRunTemplateStagesMutationOptions = Apollo.BaseMutationOptions<
  UpdateRunTemplateStagesMutation,
  UpdateRunTemplateStagesMutationVariables
>;
export const UpdateRunTemplateTaskScheduleDocument = gql`
  mutation UpdateRunTemplateTaskSchedule(
    $runTemplateTaskId: Int!
    $schedule: [RunTemplatesRepeatDaysOfWeekInput!]!
  ) {
    updateRunTemplateTaskSchedule(
      runTemplateTaskId: $runTemplateTaskId
      schedule: $schedule
    ) {
      ...RunTemplateTask
    }
  }
  ${RunTemplateTaskFragmentDoc}
`;
export type UpdateRunTemplateTaskScheduleMutationFn = Apollo.MutationFunction<
  UpdateRunTemplateTaskScheduleMutation,
  UpdateRunTemplateTaskScheduleMutationVariables
>;

/**
 * __useUpdateRunTemplateTaskScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateRunTemplateTaskScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRunTemplateTaskScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRunTemplateTaskScheduleMutation, { data, loading, error }] = useUpdateRunTemplateTaskScheduleMutation({
 *   variables: {
 *      runTemplateTaskId: // value for 'runTemplateTaskId'
 *      schedule: // value for 'schedule'
 *   },
 * });
 */
export function useUpdateRunTemplateTaskScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRunTemplateTaskScheduleMutation,
    UpdateRunTemplateTaskScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRunTemplateTaskScheduleMutation,
    UpdateRunTemplateTaskScheduleMutationVariables
  >(UpdateRunTemplateTaskScheduleDocument, options);
}
export type UpdateRunTemplateTaskScheduleMutationHookResult = ReturnType<
  typeof useUpdateRunTemplateTaskScheduleMutation
>;
export type UpdateRunTemplateTaskScheduleMutationResult =
  Apollo.MutationResult<UpdateRunTemplateTaskScheduleMutation>;
export type UpdateRunTemplateTaskScheduleMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateRunTemplateTaskScheduleMutation,
    UpdateRunTemplateTaskScheduleMutationVariables
  >;
export const UpdateRunTemplateTasksDocument = gql`
  mutation UpdateRunTemplateTasks(
    $inputs: [RunTemplateTaskInput]
    $cascade: Boolean
  ) {
    updateRunTemplateTasks(inputs: $inputs, cascadeChanges: $cascade) {
      ...RunTemplateTask
      runTasks(where: { status: { nin: [CANCELLED, COMPLETED] } }) {
        ...SimpleRunTask
      }
      runTemplate {
        runTemplateId
        runTemplateName
        lastTimeUpdated
        lastUpdatedBy
      }
    }
  }
  ${RunTemplateTaskFragmentDoc}
  ${SimpleRunTaskFragmentDoc}
`;
export type UpdateRunTemplateTasksMutationFn = Apollo.MutationFunction<
  UpdateRunTemplateTasksMutation,
  UpdateRunTemplateTasksMutationVariables
>;

/**
 * __useUpdateRunTemplateTasksMutation__
 *
 * To run a mutation, you first call `useUpdateRunTemplateTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRunTemplateTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRunTemplateTasksMutation, { data, loading, error }] = useUpdateRunTemplateTasksMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *      cascade: // value for 'cascade'
 *   },
 * });
 */
export function useUpdateRunTemplateTasksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRunTemplateTasksMutation,
    UpdateRunTemplateTasksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRunTemplateTasksMutation,
    UpdateRunTemplateTasksMutationVariables
  >(UpdateRunTemplateTasksDocument, options);
}
export type UpdateRunTemplateTasksMutationHookResult = ReturnType<
  typeof useUpdateRunTemplateTasksMutation
>;
export type UpdateRunTemplateTasksMutationResult =
  Apollo.MutationResult<UpdateRunTemplateTasksMutation>;
export type UpdateRunTemplateTasksMutationOptions = Apollo.BaseMutationOptions<
  UpdateRunTemplateTasksMutation,
  UpdateRunTemplateTasksMutationVariables
>;
export const DeleteRunTemplateDocument = gql`
  mutation DeleteRunTemplate($deleteInput: DeleteInput) {
    deleteTemplate(deleteInput: $deleteInput) {
      ...RunTemplate
    }
  }
  ${RunTemplateFragmentDoc}
`;
export type DeleteRunTemplateMutationFn = Apollo.MutationFunction<
  DeleteRunTemplateMutation,
  DeleteRunTemplateMutationVariables
>;

/**
 * __useDeleteRunTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteRunTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRunTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRunTemplateMutation, { data, loading, error }] = useDeleteRunTemplateMutation({
 *   variables: {
 *      deleteInput: // value for 'deleteInput'
 *   },
 * });
 */
export function useDeleteRunTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRunTemplateMutation,
    DeleteRunTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRunTemplateMutation,
    DeleteRunTemplateMutationVariables
  >(DeleteRunTemplateDocument, options);
}
export type DeleteRunTemplateMutationHookResult = ReturnType<
  typeof useDeleteRunTemplateMutation
>;
export type DeleteRunTemplateMutationResult =
  Apollo.MutationResult<DeleteRunTemplateMutation>;
export type DeleteRunTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteRunTemplateMutation,
  DeleteRunTemplateMutationVariables
>;
export const DeleteRunTemplateStageDocument = gql`
  mutation DeleteRunTemplateStage($deleteInput: DeleteInput) {
    removeTemplateStage(deleteInput: $deleteInput) {
      ...RunTemplate
    }
  }
  ${RunTemplateFragmentDoc}
`;
export type DeleteRunTemplateStageMutationFn = Apollo.MutationFunction<
  DeleteRunTemplateStageMutation,
  DeleteRunTemplateStageMutationVariables
>;

/**
 * __useDeleteRunTemplateStageMutation__
 *
 * To run a mutation, you first call `useDeleteRunTemplateStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRunTemplateStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRunTemplateStageMutation, { data, loading, error }] = useDeleteRunTemplateStageMutation({
 *   variables: {
 *      deleteInput: // value for 'deleteInput'
 *   },
 * });
 */
export function useDeleteRunTemplateStageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRunTemplateStageMutation,
    DeleteRunTemplateStageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRunTemplateStageMutation,
    DeleteRunTemplateStageMutationVariables
  >(DeleteRunTemplateStageDocument, options);
}
export type DeleteRunTemplateStageMutationHookResult = ReturnType<
  typeof useDeleteRunTemplateStageMutation
>;
export type DeleteRunTemplateStageMutationResult =
  Apollo.MutationResult<DeleteRunTemplateStageMutation>;
export type DeleteRunTemplateStageMutationOptions = Apollo.BaseMutationOptions<
  DeleteRunTemplateStageMutation,
  DeleteRunTemplateStageMutationVariables
>;
export const DeleteRunTemplateTreeNodeDocument = gql`
  mutation DeleteRunTemplateTreeNode($deleteInput: DeleteInput) {
    deleteRunTemplateTreeNode(deleteInput: $deleteInput) {
      ...RunTemplateStage
    }
  }
  ${RunTemplateStageFragmentDoc}
`;
export type DeleteRunTemplateTreeNodeMutationFn = Apollo.MutationFunction<
  DeleteRunTemplateTreeNodeMutation,
  DeleteRunTemplateTreeNodeMutationVariables
>;

/**
 * __useDeleteRunTemplateTreeNodeMutation__
 *
 * To run a mutation, you first call `useDeleteRunTemplateTreeNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRunTemplateTreeNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRunTemplateTreeNodeMutation, { data, loading, error }] = useDeleteRunTemplateTreeNodeMutation({
 *   variables: {
 *      deleteInput: // value for 'deleteInput'
 *   },
 * });
 */
export function useDeleteRunTemplateTreeNodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRunTemplateTreeNodeMutation,
    DeleteRunTemplateTreeNodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRunTemplateTreeNodeMutation,
    DeleteRunTemplateTreeNodeMutationVariables
  >(DeleteRunTemplateTreeNodeDocument, options);
}
export type DeleteRunTemplateTreeNodeMutationHookResult = ReturnType<
  typeof useDeleteRunTemplateTreeNodeMutation
>;
export type DeleteRunTemplateTreeNodeMutationResult =
  Apollo.MutationResult<DeleteRunTemplateTreeNodeMutation>;
export type DeleteRunTemplateTreeNodeMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteRunTemplateTreeNodeMutation,
    DeleteRunTemplateTreeNodeMutationVariables
  >;
export const CreateTaskAttachmentsDocument = gql`
  mutation CreateTaskAttachments(
    $inputs: [TaskAttachmentInput!]!
    $taskId: Int!
    $taskType: TaskTypeEnum!
  ) {
    createTaskAttachments(inputs: $inputs, taskId: $taskId, taskType: $taskType)
  }
`;
export type CreateTaskAttachmentsMutationFn = Apollo.MutationFunction<
  CreateTaskAttachmentsMutation,
  CreateTaskAttachmentsMutationVariables
>;

/**
 * __useCreateTaskAttachmentsMutation__
 *
 * To run a mutation, you first call `useCreateTaskAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskAttachmentsMutation, { data, loading, error }] = useCreateTaskAttachmentsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *      taskId: // value for 'taskId'
 *      taskType: // value for 'taskType'
 *   },
 * });
 */
export function useCreateTaskAttachmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTaskAttachmentsMutation,
    CreateTaskAttachmentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTaskAttachmentsMutation,
    CreateTaskAttachmentsMutationVariables
  >(CreateTaskAttachmentsDocument, options);
}
export type CreateTaskAttachmentsMutationHookResult = ReturnType<
  typeof useCreateTaskAttachmentsMutation
>;
export type CreateTaskAttachmentsMutationResult =
  Apollo.MutationResult<CreateTaskAttachmentsMutation>;
export type CreateTaskAttachmentsMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskAttachmentsMutation,
  CreateTaskAttachmentsMutationVariables
>;
export const DeleteTaskAttachmentDocument = gql`
  mutation DeleteTaskAttachment(
    $taskAttachmentId: Int!
    $taskType: TaskTypeEnum!
  ) {
    deleteTaskAttachment(
      taskAttachmentId: $taskAttachmentId
      taskType: $taskType
    )
  }
`;
export type DeleteTaskAttachmentMutationFn = Apollo.MutationFunction<
  DeleteTaskAttachmentMutation,
  DeleteTaskAttachmentMutationVariables
>;

/**
 * __useDeleteTaskAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteTaskAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskAttachmentMutation, { data, loading, error }] = useDeleteTaskAttachmentMutation({
 *   variables: {
 *      taskAttachmentId: // value for 'taskAttachmentId'
 *      taskType: // value for 'taskType'
 *   },
 * });
 */
export function useDeleteTaskAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskAttachmentMutation,
    DeleteTaskAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTaskAttachmentMutation,
    DeleteTaskAttachmentMutationVariables
  >(DeleteTaskAttachmentDocument, options);
}
export type DeleteTaskAttachmentMutationHookResult = ReturnType<
  typeof useDeleteTaskAttachmentMutation
>;
export type DeleteTaskAttachmentMutationResult =
  Apollo.MutationResult<DeleteTaskAttachmentMutation>;
export type DeleteTaskAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskAttachmentMutation,
  DeleteTaskAttachmentMutationVariables
>;
export const DeleteTasksDocument = gql`
  mutation DeleteTasks($taskIDs: [Int!]!, $taskType: TaskTypeEnum!) {
    deleteTasks(taskIDs: $taskIDs, taskType: $taskType)
  }
`;
export type DeleteTasksMutationFn = Apollo.MutationFunction<
  DeleteTasksMutation,
  DeleteTasksMutationVariables
>;

/**
 * __useDeleteTasksMutation__
 *
 * To run a mutation, you first call `useDeleteTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTasksMutation, { data, loading, error }] = useDeleteTasksMutation({
 *   variables: {
 *      taskIDs: // value for 'taskIDs'
 *      taskType: // value for 'taskType'
 *   },
 * });
 */
export function useDeleteTasksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTasksMutation,
    DeleteTasksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTasksMutation, DeleteTasksMutationVariables>(
    DeleteTasksDocument,
    options
  );
}
export type DeleteTasksMutationHookResult = ReturnType<
  typeof useDeleteTasksMutation
>;
export type DeleteTasksMutationResult =
  Apollo.MutationResult<DeleteTasksMutation>;
export type DeleteTasksMutationOptions = Apollo.BaseMutationOptions<
  DeleteTasksMutation,
  DeleteTasksMutationVariables
>;
export const AssignTaskDocument = gql`
  mutation AssignTask($taskInput: AssignTaskInput!) {
    assignTask(taskInput: $taskInput)
  }
`;
export type AssignTaskMutationFn = Apollo.MutationFunction<
  AssignTaskMutation,
  AssignTaskMutationVariables
>;

/**
 * __useAssignTaskMutation__
 *
 * To run a mutation, you first call `useAssignTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTaskMutation, { data, loading, error }] = useAssignTaskMutation({
 *   variables: {
 *      taskInput: // value for 'taskInput'
 *   },
 * });
 */
export function useAssignTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignTaskMutation,
    AssignTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignTaskMutation, AssignTaskMutationVariables>(
    AssignTaskDocument,
    options
  );
}
export type AssignTaskMutationHookResult = ReturnType<
  typeof useAssignTaskMutation
>;
export type AssignTaskMutationResult =
  Apollo.MutationResult<AssignTaskMutation>;
export type AssignTaskMutationOptions = Apollo.BaseMutationOptions<
  AssignTaskMutation,
  AssignTaskMutationVariables
>;
export const RescheduleTasksDocument = gql`
  mutation RescheduleTasks($models: [RescheduleTaskInput!]!) {
    rescheduleTasks(models: $models)
  }
`;
export type RescheduleTasksMutationFn = Apollo.MutationFunction<
  RescheduleTasksMutation,
  RescheduleTasksMutationVariables
>;

/**
 * __useRescheduleTasksMutation__
 *
 * To run a mutation, you first call `useRescheduleTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleTasksMutation, { data, loading, error }] = useRescheduleTasksMutation({
 *   variables: {
 *      models: // value for 'models'
 *   },
 * });
 */
export function useRescheduleTasksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescheduleTasksMutation,
    RescheduleTasksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RescheduleTasksMutation,
    RescheduleTasksMutationVariables
  >(RescheduleTasksDocument, options);
}
export type RescheduleTasksMutationHookResult = ReturnType<
  typeof useRescheduleTasksMutation
>;
export type RescheduleTasksMutationResult =
  Apollo.MutationResult<RescheduleTasksMutation>;
export type RescheduleTasksMutationOptions = Apollo.BaseMutationOptions<
  RescheduleTasksMutation,
  RescheduleTasksMutationVariables
>;
export const SaveFeedGroupsDocument = gql`
  mutation SaveFeedGroups($inputs: [FeedGroupInput!]!) {
    saveFeedGroups(inputs: $inputs)
  }
`;
export type SaveFeedGroupsMutationFn = Apollo.MutationFunction<
  SaveFeedGroupsMutation,
  SaveFeedGroupsMutationVariables
>;

/**
 * __useSaveFeedGroupsMutation__
 *
 * To run a mutation, you first call `useSaveFeedGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFeedGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFeedGroupsMutation, { data, loading, error }] = useSaveFeedGroupsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useSaveFeedGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveFeedGroupsMutation,
    SaveFeedGroupsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveFeedGroupsMutation,
    SaveFeedGroupsMutationVariables
  >(SaveFeedGroupsDocument, options);
}
export type SaveFeedGroupsMutationHookResult = ReturnType<
  typeof useSaveFeedGroupsMutation
>;
export type SaveFeedGroupsMutationResult =
  Apollo.MutationResult<SaveFeedGroupsMutation>;
export type SaveFeedGroupsMutationOptions = Apollo.BaseMutationOptions<
  SaveFeedGroupsMutation,
  SaveFeedGroupsMutationVariables
>;
export const AddPersonalFeedTasksDocument = gql`
  mutation AddPersonalFeedTasks($inputs: [FeedGroupInput!]!) {
    addPersonalFeedTasks(inputs: $inputs)
  }
`;
export type AddPersonalFeedTasksMutationFn = Apollo.MutationFunction<
  AddPersonalFeedTasksMutation,
  AddPersonalFeedTasksMutationVariables
>;

/**
 * __useAddPersonalFeedTasksMutation__
 *
 * To run a mutation, you first call `useAddPersonalFeedTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPersonalFeedTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPersonalFeedTasksMutation, { data, loading, error }] = useAddPersonalFeedTasksMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useAddPersonalFeedTasksMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPersonalFeedTasksMutation,
    AddPersonalFeedTasksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPersonalFeedTasksMutation,
    AddPersonalFeedTasksMutationVariables
  >(AddPersonalFeedTasksDocument, options);
}
export type AddPersonalFeedTasksMutationHookResult = ReturnType<
  typeof useAddPersonalFeedTasksMutation
>;
export type AddPersonalFeedTasksMutationResult =
  Apollo.MutationResult<AddPersonalFeedTasksMutation>;
export type AddPersonalFeedTasksMutationOptions = Apollo.BaseMutationOptions<
  AddPersonalFeedTasksMutation,
  AddPersonalFeedTasksMutationVariables
>;
export const DeleteTemplateTaskDocument = gql`
  mutation DeleteTemplateTask($templateId: Int!, $taskType: TaskTypeEnum!) {
    deleteTemplateTask(templateId: $templateId, taskType: $taskType)
  }
`;
export type DeleteTemplateTaskMutationFn = Apollo.MutationFunction<
  DeleteTemplateTaskMutation,
  DeleteTemplateTaskMutationVariables
>;

/**
 * __useDeleteTemplateTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateTaskMutation, { data, loading, error }] = useDeleteTemplateTaskMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      taskType: // value for 'taskType'
 *   },
 * });
 */
export function useDeleteTemplateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTemplateTaskMutation,
    DeleteTemplateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTemplateTaskMutation,
    DeleteTemplateTaskMutationVariables
  >(DeleteTemplateTaskDocument, options);
}
export type DeleteTemplateTaskMutationHookResult = ReturnType<
  typeof useDeleteTemplateTaskMutation
>;
export type DeleteTemplateTaskMutationResult =
  Apollo.MutationResult<DeleteTemplateTaskMutation>;
export type DeleteTemplateTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteTemplateTaskMutation,
  DeleteTemplateTaskMutationVariables
>;
export const CreateTicketDocument = gql`
  mutation CreateTicket($input: CreateTicketInput) {
    createTicket(input: $input) {
      ...Ticket
    }
  }
  ${TicketFragmentDoc}
`;
export type CreateTicketMutationFn = Apollo.MutationFunction<
  CreateTicketMutation,
  CreateTicketMutationVariables
>;

/**
 * __useCreateTicketMutation__
 *
 * To run a mutation, you first call `useCreateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketMutation, { data, loading, error }] = useCreateTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTicketMutation,
    CreateTicketMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTicketMutation,
    CreateTicketMutationVariables
  >(CreateTicketDocument, options);
}
export type CreateTicketMutationHookResult = ReturnType<
  typeof useCreateTicketMutation
>;
export type CreateTicketMutationResult =
  Apollo.MutationResult<CreateTicketMutation>;
export type CreateTicketMutationOptions = Apollo.BaseMutationOptions<
  CreateTicketMutation,
  CreateTicketMutationVariables
>;
export const UpdateTicketsDocument = gql`
  mutation UpdateTickets(
    $inputs: [UpdateInputOfUpdateTicketInputAndTicketInput]
  ) {
    updateTickets(inputs: $inputs) {
      ...Ticket
    }
  }
  ${TicketFragmentDoc}
`;
export type UpdateTicketsMutationFn = Apollo.MutationFunction<
  UpdateTicketsMutation,
  UpdateTicketsMutationVariables
>;

/**
 * __useUpdateTicketsMutation__
 *
 * To run a mutation, you first call `useUpdateTicketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketsMutation, { data, loading, error }] = useUpdateTicketsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateTicketsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTicketsMutation,
    UpdateTicketsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTicketsMutation,
    UpdateTicketsMutationVariables
  >(UpdateTicketsDocument, options);
}
export type UpdateTicketsMutationHookResult = ReturnType<
  typeof useUpdateTicketsMutation
>;
export type UpdateTicketsMutationResult =
  Apollo.MutationResult<UpdateTicketsMutation>;
export type UpdateTicketsMutationOptions = Apollo.BaseMutationOptions<
  UpdateTicketsMutation,
  UpdateTicketsMutationVariables
>;
export const AddTicketUpdateDocument = gql`
  mutation AddTicketUpdate($input: CreateTicketUpdateInput) {
    addTicketUpdate(input: $input) {
      ...Ticket
    }
  }
  ${TicketFragmentDoc}
`;
export type AddTicketUpdateMutationFn = Apollo.MutationFunction<
  AddTicketUpdateMutation,
  AddTicketUpdateMutationVariables
>;

/**
 * __useAddTicketUpdateMutation__
 *
 * To run a mutation, you first call `useAddTicketUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTicketUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTicketUpdateMutation, { data, loading, error }] = useAddTicketUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTicketUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTicketUpdateMutation,
    AddTicketUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTicketUpdateMutation,
    AddTicketUpdateMutationVariables
  >(AddTicketUpdateDocument, options);
}
export type AddTicketUpdateMutationHookResult = ReturnType<
  typeof useAddTicketUpdateMutation
>;
export type AddTicketUpdateMutationResult =
  Apollo.MutationResult<AddTicketUpdateMutation>;
export type AddTicketUpdateMutationOptions = Apollo.BaseMutationOptions<
  AddTicketUpdateMutation,
  AddTicketUpdateMutationVariables
>;
export const AcceptTicketDocument = gql`
  mutation AcceptTicket($ticketId: Int!) {
    acceptTicket(ticketId: $ticketId) {
      ...Ticket
    }
  }
  ${TicketFragmentDoc}
`;
export type AcceptTicketMutationFn = Apollo.MutationFunction<
  AcceptTicketMutation,
  AcceptTicketMutationVariables
>;

/**
 * __useAcceptTicketMutation__
 *
 * To run a mutation, you first call `useAcceptTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTicketMutation, { data, loading, error }] = useAcceptTicketMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useAcceptTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptTicketMutation,
    AcceptTicketMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptTicketMutation,
    AcceptTicketMutationVariables
  >(AcceptTicketDocument, options);
}
export type AcceptTicketMutationHookResult = ReturnType<
  typeof useAcceptTicketMutation
>;
export type AcceptTicketMutationResult =
  Apollo.MutationResult<AcceptTicketMutation>;
export type AcceptTicketMutationOptions = Apollo.BaseMutationOptions<
  AcceptTicketMutation,
  AcceptTicketMutationVariables
>;
export const AddTicketAttachmentDocument = gql`
  mutation AddTicketAttachment(
    $attachments: [CreateTicketAttachmentInput!]!
    $ticketId: Int!
  ) {
    addTicketAttachment(inputs: $attachments, ticketId: $ticketId) {
      ...TicketAndAttachments
    }
  }
  ${TicketAndAttachmentsFragmentDoc}
`;
export type AddTicketAttachmentMutationFn = Apollo.MutationFunction<
  AddTicketAttachmentMutation,
  AddTicketAttachmentMutationVariables
>;

/**
 * __useAddTicketAttachmentMutation__
 *
 * To run a mutation, you first call `useAddTicketAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTicketAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTicketAttachmentMutation, { data, loading, error }] = useAddTicketAttachmentMutation({
 *   variables: {
 *      attachments: // value for 'attachments'
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useAddTicketAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTicketAttachmentMutation,
    AddTicketAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTicketAttachmentMutation,
    AddTicketAttachmentMutationVariables
  >(AddTicketAttachmentDocument, options);
}
export type AddTicketAttachmentMutationHookResult = ReturnType<
  typeof useAddTicketAttachmentMutation
>;
export type AddTicketAttachmentMutationResult =
  Apollo.MutationResult<AddTicketAttachmentMutation>;
export type AddTicketAttachmentMutationOptions = Apollo.BaseMutationOptions<
  AddTicketAttachmentMutation,
  AddTicketAttachmentMutationVariables
>;
export const DeleteTicketAttachmentDocument = gql`
  mutation DeleteTicketAttachment($input: DeleteInput!) {
    deleteTicketAttachment(input: $input) {
      ...TicketAndAttachments
    }
  }
  ${TicketAndAttachmentsFragmentDoc}
`;
export type DeleteTicketAttachmentMutationFn = Apollo.MutationFunction<
  DeleteTicketAttachmentMutation,
  DeleteTicketAttachmentMutationVariables
>;

/**
 * __useDeleteTicketAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteTicketAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTicketAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTicketAttachmentMutation, { data, loading, error }] = useDeleteTicketAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTicketAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTicketAttachmentMutation,
    DeleteTicketAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTicketAttachmentMutation,
    DeleteTicketAttachmentMutationVariables
  >(DeleteTicketAttachmentDocument, options);
}
export type DeleteTicketAttachmentMutationHookResult = ReturnType<
  typeof useDeleteTicketAttachmentMutation
>;
export type DeleteTicketAttachmentMutationResult =
  Apollo.MutationResult<DeleteTicketAttachmentMutation>;
export type DeleteTicketAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteTicketAttachmentMutation,
  DeleteTicketAttachmentMutationVariables
>;
export const MergeTicketDocument = gql`
  mutation MergeTicket($mergeInto: Int!, $ticketToMerge: Int!) {
    mergeTicket(mergeInto: $mergeInto, ticketToMerge: $ticketToMerge) {
      ...Ticket
    }
  }
  ${TicketFragmentDoc}
`;
export type MergeTicketMutationFn = Apollo.MutationFunction<
  MergeTicketMutation,
  MergeTicketMutationVariables
>;

/**
 * __useMergeTicketMutation__
 *
 * To run a mutation, you first call `useMergeTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeTicketMutation, { data, loading, error }] = useMergeTicketMutation({
 *   variables: {
 *      mergeInto: // value for 'mergeInto'
 *      ticketToMerge: // value for 'ticketToMerge'
 *   },
 * });
 */
export function useMergeTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MergeTicketMutation,
    MergeTicketMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MergeTicketMutation, MergeTicketMutationVariables>(
    MergeTicketDocument,
    options
  );
}
export type MergeTicketMutationHookResult = ReturnType<
  typeof useMergeTicketMutation
>;
export type MergeTicketMutationResult =
  Apollo.MutationResult<MergeTicketMutation>;
export type MergeTicketMutationOptions = Apollo.BaseMutationOptions<
  MergeTicketMutation,
  MergeTicketMutationVariables
>;
export const EditUserDocument = gql`
  mutation EditUser(
    $inputs: [UpdateInputOfUpdateAppSuiteUserInputAndAppSuiteUserInput!]
  ) {
    updateUsers(inputs: $inputs) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type EditUserMutationFn = Apollo.MutationFunction<
  EditUserMutation,
  EditUserMutationVariables
>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useEditUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditUserMutation,
    EditUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(
    EditUserDocument,
    options
  );
}
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<
  EditUserMutation,
  EditUserMutationVariables
>;
export const GroupMemberLoginDocument = gql`
  mutation GroupMemberLogin($computerUser: CurrentComputerUserInput!) {
    groupMemberLogin(currentComputerUser: $computerUser) {
      id
      user {
        ...User
      }
      labComputer {
        id
        name
      }
    }
  }
  ${UserFragmentDoc}
`;
export type GroupMemberLoginMutationFn = Apollo.MutationFunction<
  GroupMemberLoginMutation,
  GroupMemberLoginMutationVariables
>;

/**
 * __useGroupMemberLoginMutation__
 *
 * To run a mutation, you first call `useGroupMemberLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupMemberLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupMemberLoginMutation, { data, loading, error }] = useGroupMemberLoginMutation({
 *   variables: {
 *      computerUser: // value for 'computerUser'
 *   },
 * });
 */
export function useGroupMemberLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupMemberLoginMutation,
    GroupMemberLoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GroupMemberLoginMutation,
    GroupMemberLoginMutationVariables
  >(GroupMemberLoginDocument, options);
}
export type GroupMemberLoginMutationHookResult = ReturnType<
  typeof useGroupMemberLoginMutation
>;
export type GroupMemberLoginMutationResult =
  Apollo.MutationResult<GroupMemberLoginMutation>;
export type GroupMemberLoginMutationOptions = Apollo.BaseMutationOptions<
  GroupMemberLoginMutation,
  GroupMemberLoginMutationVariables
>;
export const SaveUserSettingsDocument = gql`
  mutation SaveUserSettings($settingsJson: String!) {
    saveSettings(settingsJson: $settingsJson) {
      ...UserClaims
    }
  }
  ${UserClaimsFragmentDoc}
`;
export type SaveUserSettingsMutationFn = Apollo.MutationFunction<
  SaveUserSettingsMutation,
  SaveUserSettingsMutationVariables
>;

/**
 * __useSaveUserSettingsMutation__
 *
 * To run a mutation, you first call `useSaveUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserSettingsMutation, { data, loading, error }] = useSaveUserSettingsMutation({
 *   variables: {
 *      settingsJson: // value for 'settingsJson'
 *   },
 * });
 */
export function useSaveUserSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveUserSettingsMutation,
    SaveUserSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveUserSettingsMutation,
    SaveUserSettingsMutationVariables
  >(SaveUserSettingsDocument, options);
}
export type SaveUserSettingsMutationHookResult = ReturnType<
  typeof useSaveUserSettingsMutation
>;
export type SaveUserSettingsMutationResult =
  Apollo.MutationResult<SaveUserSettingsMutation>;
export type SaveUserSettingsMutationOptions = Apollo.BaseMutationOptions<
  SaveUserSettingsMutation,
  SaveUserSettingsMutationVariables
>;
export const SetLabwareTypeDocument = gql`
  mutation setLabwareType($plateId: Int!, $labwareTypeId: Int!) {
    setLabwareType(plateId: $plateId, labwareTypeId: $labwareTypeId) {
      plateCreatedId
      plateBarcode
      labwareType {
        labwareTypeId
        labwareTypeName
        labwareTypeCode
        defaultLabwareVolume
        plateLayoutType {
          plateCols
          plateRows
        }
      }
    }
  }
`;
export type SetLabwareTypeMutationFn = Apollo.MutationFunction<
  SetLabwareTypeMutation,
  SetLabwareTypeMutationVariables
>;

/**
 * __useSetLabwareTypeMutation__
 *
 * To run a mutation, you first call `useSetLabwareTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLabwareTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLabwareTypeMutation, { data, loading, error }] = useSetLabwareTypeMutation({
 *   variables: {
 *      plateId: // value for 'plateId'
 *      labwareTypeId: // value for 'labwareTypeId'
 *   },
 * });
 */
export function useSetLabwareTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetLabwareTypeMutation,
    SetLabwareTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetLabwareTypeMutation,
    SetLabwareTypeMutationVariables
  >(SetLabwareTypeDocument, options);
}
export type SetLabwareTypeMutationHookResult = ReturnType<
  typeof useSetLabwareTypeMutation
>;
export type SetLabwareTypeMutationResult =
  Apollo.MutationResult<SetLabwareTypeMutation>;
export type SetLabwareTypeMutationOptions = Apollo.BaseMutationOptions<
  SetLabwareTypeMutation,
  SetLabwareTypeMutationVariables
>;
export const GetPlateBarcodePreviewDocument = gql`
  mutation getPlateBarcodePreview(
    $methodId: Int!
    $sourcePlateBarcodes: [String]
    $destPlateCode: String
  ) {
    plateBarcodePreview(
      methodId: $methodId
      sourcePlateBarcodes: $sourcePlateBarcodes
      destPlateCode: $destPlateCode
    ) {
      plateBarcode
      plateType
    }
  }
`;
export type GetPlateBarcodePreviewMutationFn = Apollo.MutationFunction<
  GetPlateBarcodePreviewMutation,
  GetPlateBarcodePreviewMutationVariables
>;

/**
 * __useGetPlateBarcodePreviewMutation__
 *
 * To run a mutation, you first call `useGetPlateBarcodePreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPlateBarcodePreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPlateBarcodePreviewMutation, { data, loading, error }] = useGetPlateBarcodePreviewMutation({
 *   variables: {
 *      methodId: // value for 'methodId'
 *      sourcePlateBarcodes: // value for 'sourcePlateBarcodes'
 *      destPlateCode: // value for 'destPlateCode'
 *   },
 * });
 */
export function useGetPlateBarcodePreviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetPlateBarcodePreviewMutation,
    GetPlateBarcodePreviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetPlateBarcodePreviewMutation,
    GetPlateBarcodePreviewMutationVariables
  >(GetPlateBarcodePreviewDocument, options);
}
export type GetPlateBarcodePreviewMutationHookResult = ReturnType<
  typeof useGetPlateBarcodePreviewMutation
>;
export type GetPlateBarcodePreviewMutationResult =
  Apollo.MutationResult<GetPlateBarcodePreviewMutation>;
export type GetPlateBarcodePreviewMutationOptions = Apollo.BaseMutationOptions<
  GetPlateBarcodePreviewMutation,
  GetPlateBarcodePreviewMutationVariables
>;
export const GetIntermediateDeadAndDestPlateBarcodesDocument = gql`
  mutation getIntermediateDeadAndDestPlateBarcodes(
    $sourcePlateBarcode: String
    $destPlateCode: String
    $hasMultipleSourcePlateTypes: Boolean!
    $hasMultipleDestPlateTypes: Boolean!
    $has384DeadPlate: Boolean!
  ) {
    intermediateDeadAndDestPlateBarcodes(
      sourcePlateBarcode: $sourcePlateBarcode
      destPlateCode: $destPlateCode
      hasMultipleSourcePlateTypes: $hasMultipleSourcePlateTypes
      hasMultipleDestPlateTypes: $hasMultipleDestPlateTypes
      has384DeadPlate: $has384DeadPlate
    ) {
      intermediatePlateBarcode1
      intermediatePlateBarcode2
      deadPlateBarcode
      destinationPlateBarcode
    }
  }
`;
export type GetIntermediateDeadAndDestPlateBarcodesMutationFn =
  Apollo.MutationFunction<
    GetIntermediateDeadAndDestPlateBarcodesMutation,
    GetIntermediateDeadAndDestPlateBarcodesMutationVariables
  >;

/**
 * __useGetIntermediateDeadAndDestPlateBarcodesMutation__
 *
 * To run a mutation, you first call `useGetIntermediateDeadAndDestPlateBarcodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetIntermediateDeadAndDestPlateBarcodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getIntermediateDeadAndDestPlateBarcodesMutation, { data, loading, error }] = useGetIntermediateDeadAndDestPlateBarcodesMutation({
 *   variables: {
 *      sourcePlateBarcode: // value for 'sourcePlateBarcode'
 *      destPlateCode: // value for 'destPlateCode'
 *      hasMultipleSourcePlateTypes: // value for 'hasMultipleSourcePlateTypes'
 *      hasMultipleDestPlateTypes: // value for 'hasMultipleDestPlateTypes'
 *      has384DeadPlate: // value for 'has384DeadPlate'
 *   },
 * });
 */
export function useGetIntermediateDeadAndDestPlateBarcodesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetIntermediateDeadAndDestPlateBarcodesMutation,
    GetIntermediateDeadAndDestPlateBarcodesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetIntermediateDeadAndDestPlateBarcodesMutation,
    GetIntermediateDeadAndDestPlateBarcodesMutationVariables
  >(GetIntermediateDeadAndDestPlateBarcodesDocument, options);
}
export type GetIntermediateDeadAndDestPlateBarcodesMutationHookResult =
  ReturnType<typeof useGetIntermediateDeadAndDestPlateBarcodesMutation>;
export type GetIntermediateDeadAndDestPlateBarcodesMutationResult =
  Apollo.MutationResult<GetIntermediateDeadAndDestPlateBarcodesMutation>;
export type GetIntermediateDeadAndDestPlateBarcodesMutationOptions =
  Apollo.BaseMutationOptions<
    GetIntermediateDeadAndDestPlateBarcodesMutation,
    GetIntermediateDeadAndDestPlateBarcodesMutationVariables
  >;
export const ReserveMethodDocument = gql`
  mutation reserveMethod(
    $methodReservationInput: WorklistToolMethodReservationInput
  ) {
    reserveMethod(input: $methodReservationInput) {
      methodReservationId
    }
  }
`;
export type ReserveMethodMutationFn = Apollo.MutationFunction<
  ReserveMethodMutation,
  ReserveMethodMutationVariables
>;

/**
 * __useReserveMethodMutation__
 *
 * To run a mutation, you first call `useReserveMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReserveMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reserveMethodMutation, { data, loading, error }] = useReserveMethodMutation({
 *   variables: {
 *      methodReservationInput: // value for 'methodReservationInput'
 *   },
 * });
 */
export function useReserveMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReserveMethodMutation,
    ReserveMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReserveMethodMutation,
    ReserveMethodMutationVariables
  >(ReserveMethodDocument, options);
}
export type ReserveMethodMutationHookResult = ReturnType<
  typeof useReserveMethodMutation
>;
export type ReserveMethodMutationResult =
  Apollo.MutationResult<ReserveMethodMutation>;
export type ReserveMethodMutationOptions = Apollo.BaseMutationOptions<
  ReserveMethodMutation,
  ReserveMethodMutationVariables
>;
export const DeleteExistingMethodReservationDocument = gql`
  mutation deleteExistingMethodReservation($methodReservationId: Int!) {
    deleteExistingMethodReservation(methodReservationId: $methodReservationId) {
      methodReservationId
    }
  }
`;
export type DeleteExistingMethodReservationMutationFn = Apollo.MutationFunction<
  DeleteExistingMethodReservationMutation,
  DeleteExistingMethodReservationMutationVariables
>;

/**
 * __useDeleteExistingMethodReservationMutation__
 *
 * To run a mutation, you first call `useDeleteExistingMethodReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExistingMethodReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExistingMethodReservationMutation, { data, loading, error }] = useDeleteExistingMethodReservationMutation({
 *   variables: {
 *      methodReservationId: // value for 'methodReservationId'
 *   },
 * });
 */
export function useDeleteExistingMethodReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExistingMethodReservationMutation,
    DeleteExistingMethodReservationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteExistingMethodReservationMutation,
    DeleteExistingMethodReservationMutationVariables
  >(DeleteExistingMethodReservationDocument, options);
}
export type DeleteExistingMethodReservationMutationHookResult = ReturnType<
  typeof useDeleteExistingMethodReservationMutation
>;
export type DeleteExistingMethodReservationMutationResult =
  Apollo.MutationResult<DeleteExistingMethodReservationMutation>;
export type DeleteExistingMethodReservationMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteExistingMethodReservationMutation,
    DeleteExistingMethodReservationMutationVariables
  >;
export const SaveWorklistToolTemplateDocument = gql`
  mutation saveWorklistToolTemplate($input: SaveWorklistTemplateInput) {
    saveWorklistToolTemplate(input: $input) {
      worklistToolTemplateId
    }
  }
`;
export type SaveWorklistToolTemplateMutationFn = Apollo.MutationFunction<
  SaveWorklistToolTemplateMutation,
  SaveWorklistToolTemplateMutationVariables
>;

/**
 * __useSaveWorklistToolTemplateMutation__
 *
 * To run a mutation, you first call `useSaveWorklistToolTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWorklistToolTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWorklistToolTemplateMutation, { data, loading, error }] = useSaveWorklistToolTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveWorklistToolTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveWorklistToolTemplateMutation,
    SaveWorklistToolTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveWorklistToolTemplateMutation,
    SaveWorklistToolTemplateMutationVariables
  >(SaveWorklistToolTemplateDocument, options);
}
export type SaveWorklistToolTemplateMutationHookResult = ReturnType<
  typeof useSaveWorklistToolTemplateMutation
>;
export type SaveWorklistToolTemplateMutationResult =
  Apollo.MutationResult<SaveWorklistToolTemplateMutation>;
export type SaveWorklistToolTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    SaveWorklistToolTemplateMutation,
    SaveWorklistToolTemplateMutationVariables
  >;
export const OverwriteExistingWorklistTemplateDocument = gql`
  mutation overwriteExistingWorklistTemplate(
    $input: SaveWorklistTemplateInput
  ) {
    overwriteExistingWorklistTemplate(input: $input) {
      worklistToolTemplateId
    }
  }
`;
export type OverwriteExistingWorklistTemplateMutationFn =
  Apollo.MutationFunction<
    OverwriteExistingWorklistTemplateMutation,
    OverwriteExistingWorklistTemplateMutationVariables
  >;

/**
 * __useOverwriteExistingWorklistTemplateMutation__
 *
 * To run a mutation, you first call `useOverwriteExistingWorklistTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOverwriteExistingWorklistTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overwriteExistingWorklistTemplateMutation, { data, loading, error }] = useOverwriteExistingWorklistTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOverwriteExistingWorklistTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OverwriteExistingWorklistTemplateMutation,
    OverwriteExistingWorklistTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OverwriteExistingWorklistTemplateMutation,
    OverwriteExistingWorklistTemplateMutationVariables
  >(OverwriteExistingWorklistTemplateDocument, options);
}
export type OverwriteExistingWorklistTemplateMutationHookResult = ReturnType<
  typeof useOverwriteExistingWorklistTemplateMutation
>;
export type OverwriteExistingWorklistTemplateMutationResult =
  Apollo.MutationResult<OverwriteExistingWorklistTemplateMutation>;
export type OverwriteExistingWorklistTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    OverwriteExistingWorklistTemplateMutation,
    OverwriteExistingWorklistTemplateMutationVariables
  >;
export const DeleteWorklistToolTemplateDocument = gql`
  mutation deleteWorklistToolTemplate($worklistToolTemplateId: Int!) {
    deleteWorklistToolTemplate(
      worklistToolTemplateId: $worklistToolTemplateId
    ) {
      worklistToolTemplateId
    }
  }
`;
export type DeleteWorklistToolTemplateMutationFn = Apollo.MutationFunction<
  DeleteWorklistToolTemplateMutation,
  DeleteWorklistToolTemplateMutationVariables
>;

/**
 * __useDeleteWorklistToolTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteWorklistToolTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorklistToolTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorklistToolTemplateMutation, { data, loading, error }] = useDeleteWorklistToolTemplateMutation({
 *   variables: {
 *      worklistToolTemplateId: // value for 'worklistToolTemplateId'
 *   },
 * });
 */
export function useDeleteWorklistToolTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWorklistToolTemplateMutation,
    DeleteWorklistToolTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteWorklistToolTemplateMutation,
    DeleteWorklistToolTemplateMutationVariables
  >(DeleteWorklistToolTemplateDocument, options);
}
export type DeleteWorklistToolTemplateMutationHookResult = ReturnType<
  typeof useDeleteWorklistToolTemplateMutation
>;
export type DeleteWorklistToolTemplateMutationResult =
  Apollo.MutationResult<DeleteWorklistToolTemplateMutation>;
export type DeleteWorklistToolTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteWorklistToolTemplateMutation,
    DeleteWorklistToolTemplateMutationVariables
  >;
export const GetCalendarNodesDocument = gql`
  query getCalendarNodes($startDate: DateTime!, $endDate: DateTime!) {
    calendarNodes(
      startDate: $startDate
      endDate: $endDate
      where: { startTimeScheduled: { gte: $startDate, lte: $endDate } }
    ) {
      ...CalendarNode
    }
  }
  ${CalendarNodeFragmentDoc}
`;

/**
 * __useGetCalendarNodesQuery__
 *
 * To run a query within a React component, call `useGetCalendarNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarNodesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetCalendarNodesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCalendarNodesQuery,
    GetCalendarNodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCalendarNodesQuery, GetCalendarNodesQueryVariables>(
    GetCalendarNodesDocument,
    options
  );
}
export function useGetCalendarNodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCalendarNodesQuery,
    GetCalendarNodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCalendarNodesQuery,
    GetCalendarNodesQueryVariables
  >(GetCalendarNodesDocument, options);
}
export type GetCalendarNodesQueryHookResult = ReturnType<
  typeof useGetCalendarNodesQuery
>;
export type GetCalendarNodesLazyQueryHookResult = ReturnType<
  typeof useGetCalendarNodesLazyQuery
>;
export type GetCalendarNodesQueryResult = Apollo.QueryResult<
  GetCalendarNodesQuery,
  GetCalendarNodesQueryVariables
>;
export const GetFeedGroupsDocument = gql`
  query getFeedGroups($groupId: Int!) {
    feedGroups(groupId: $groupId) {
      feedGroupId
      groupId
      arraySystemAutomationMethodId
      feedGroupName
      numberOfPlates
      isActive
      startTimeScheduled
      arraySystemAutomationMethod {
        automationMethodId
        automationMethodCode
        arraySystemId
        automationMethod {
          lookupArrayMethods {
            methodId
          }
        }
      }
    }
  }
`;

/**
 * __useGetFeedGroupsQuery__
 *
 * To run a query within a React component, call `useGetFeedGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedGroupsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetFeedGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFeedGroupsQuery,
    GetFeedGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFeedGroupsQuery, GetFeedGroupsQueryVariables>(
    GetFeedGroupsDocument,
    options
  );
}
export function useGetFeedGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeedGroupsQuery,
    GetFeedGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFeedGroupsQuery, GetFeedGroupsQueryVariables>(
    GetFeedGroupsDocument,
    options
  );
}
export type GetFeedGroupsQueryHookResult = ReturnType<
  typeof useGetFeedGroupsQuery
>;
export type GetFeedGroupsLazyQueryHookResult = ReturnType<
  typeof useGetFeedGroupsLazyQuery
>;
export type GetFeedGroupsQueryResult = Apollo.QueryResult<
  GetFeedGroupsQuery,
  GetFeedGroupsQueryVariables
>;
export const GetGroupPersonalTasksDocument = gql`
  query getGroupPersonalTasks($groupId: Int!, $today: DateTime!) {
    groupPersonalTasks(groupId: $groupId, today: $today) {
      taskName
      methodId
      startTimeScheduled
      feedGroupId
    }
  }
`;

/**
 * __useGetGroupPersonalTasksQuery__
 *
 * To run a query within a React component, call `useGetGroupPersonalTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupPersonalTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupPersonalTasksQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      today: // value for 'today'
 *   },
 * });
 */
export function useGetGroupPersonalTasksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGroupPersonalTasksQuery,
    GetGroupPersonalTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGroupPersonalTasksQuery,
    GetGroupPersonalTasksQueryVariables
  >(GetGroupPersonalTasksDocument, options);
}
export function useGetGroupPersonalTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupPersonalTasksQuery,
    GetGroupPersonalTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGroupPersonalTasksQuery,
    GetGroupPersonalTasksQueryVariables
  >(GetGroupPersonalTasksDocument, options);
}
export type GetGroupPersonalTasksQueryHookResult = ReturnType<
  typeof useGetGroupPersonalTasksQuery
>;
export type GetGroupPersonalTasksLazyQueryHookResult = ReturnType<
  typeof useGetGroupPersonalTasksLazyQuery
>;
export type GetGroupPersonalTasksQueryResult = Apollo.QueryResult<
  GetGroupPersonalTasksQuery,
  GetGroupPersonalTasksQueryVariables
>;
export const AppBackgroundDocument = gql`
  query AppBackground {
    systemsQuery: systems {
      systemId
      systemName
    }
    usersQuery: appSuiteUsers(where: { isActive: { eq: true } }) {
      ...User
    }
    groupsQuery: appSuiteGroups {
      ...Group
    }
    folders {
      ...FolderStructure
    }
    insertableFoldersQuery: insertableFolders {
      ...FolderStructure
    }
    runTypesQuery: runTypes {
      productionRunTypeId
      typeName
    }
    runTaskTypesQuery: runTaskTypes {
      type
      id
      name
      compositeKey
      automationMethodId
    }
  }
  ${UserFragmentDoc}
  ${GroupFragmentDoc}
  ${FolderStructureFragmentDoc}
`;

/**
 * __useAppBackgroundQuery__
 *
 * To run a query within a React component, call `useAppBackgroundQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppBackgroundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppBackgroundQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppBackgroundQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AppBackgroundQuery,
    AppBackgroundQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppBackgroundQuery, AppBackgroundQueryVariables>(
    AppBackgroundDocument,
    options
  );
}
export function useAppBackgroundLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppBackgroundQuery,
    AppBackgroundQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppBackgroundQuery, AppBackgroundQueryVariables>(
    AppBackgroundDocument,
    options
  );
}
export type AppBackgroundQueryHookResult = ReturnType<
  typeof useAppBackgroundQuery
>;
export type AppBackgroundLazyQueryHookResult = ReturnType<
  typeof useAppBackgroundLazyQuery
>;
export type AppBackgroundQueryResult = Apollo.QueryResult<
  AppBackgroundQuery,
  AppBackgroundQueryVariables
>;
export const DashboardBackgroundQueriesDocument = gql`
  query DashboardBackgroundQueries {
    methods {
      ...Method
    }
  }
  ${MethodFragmentDoc}
`;

/**
 * __useDashboardBackgroundQueriesQuery__
 *
 * To run a query within a React component, call `useDashboardBackgroundQueriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardBackgroundQueriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardBackgroundQueriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardBackgroundQueriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DashboardBackgroundQueriesQuery,
    DashboardBackgroundQueriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardBackgroundQueriesQuery,
    DashboardBackgroundQueriesQueryVariables
  >(DashboardBackgroundQueriesDocument, options);
}
export function useDashboardBackgroundQueriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardBackgroundQueriesQuery,
    DashboardBackgroundQueriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardBackgroundQueriesQuery,
    DashboardBackgroundQueriesQueryVariables
  >(DashboardBackgroundQueriesDocument, options);
}
export type DashboardBackgroundQueriesQueryHookResult = ReturnType<
  typeof useDashboardBackgroundQueriesQuery
>;
export type DashboardBackgroundQueriesLazyQueryHookResult = ReturnType<
  typeof useDashboardBackgroundQueriesLazyQuery
>;
export type DashboardBackgroundQueriesQueryResult = Apollo.QueryResult<
  DashboardBackgroundQueriesQuery,
  DashboardBackgroundQueriesQueryVariables
>;
export const RunTemplateBackgroundDocument = gql`
  query RunTemplateBackground {
    plateCodes
    runTemplatesForCombobox(order: { runTemplateId: DESC }) {
      runTemplateId
      runTemplateName
    }
  }
`;

/**
 * __useRunTemplateBackgroundQuery__
 *
 * To run a query within a React component, call `useRunTemplateBackgroundQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunTemplateBackgroundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunTemplateBackgroundQuery({
 *   variables: {
 *   },
 * });
 */
export function useRunTemplateBackgroundQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RunTemplateBackgroundQuery,
    RunTemplateBackgroundQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RunTemplateBackgroundQuery,
    RunTemplateBackgroundQueryVariables
  >(RunTemplateBackgroundDocument, options);
}
export function useRunTemplateBackgroundLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RunTemplateBackgroundQuery,
    RunTemplateBackgroundQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RunTemplateBackgroundQuery,
    RunTemplateBackgroundQueryVariables
  >(RunTemplateBackgroundDocument, options);
}
export type RunTemplateBackgroundQueryHookResult = ReturnType<
  typeof useRunTemplateBackgroundQuery
>;
export type RunTemplateBackgroundLazyQueryHookResult = ReturnType<
  typeof useRunTemplateBackgroundLazyQuery
>;
export type RunTemplateBackgroundQueryResult = Apollo.QueryResult<
  RunTemplateBackgroundQuery,
  RunTemplateBackgroundQueryVariables
>;
export const CalendarBackgroundDocument = gql`
  query CalendarBackground {
    runs(
      where: { status: { in: [IN_PROGRESS, SCHEDULED] } }
      order: [{ runName: ASC }]
    ) {
      ...Run
    }
  }
  ${RunFragmentDoc}
`;

/**
 * __useCalendarBackgroundQuery__
 *
 * To run a query within a React component, call `useCalendarBackgroundQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarBackgroundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarBackgroundQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalendarBackgroundQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CalendarBackgroundQuery,
    CalendarBackgroundQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CalendarBackgroundQuery,
    CalendarBackgroundQueryVariables
  >(CalendarBackgroundDocument, options);
}
export function useCalendarBackgroundLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalendarBackgroundQuery,
    CalendarBackgroundQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CalendarBackgroundQuery,
    CalendarBackgroundQueryVariables
  >(CalendarBackgroundDocument, options);
}
export type CalendarBackgroundQueryHookResult = ReturnType<
  typeof useCalendarBackgroundQuery
>;
export type CalendarBackgroundLazyQueryHookResult = ReturnType<
  typeof useCalendarBackgroundLazyQuery
>;
export type CalendarBackgroundQueryResult = Apollo.QueryResult<
  CalendarBackgroundQuery,
  CalendarBackgroundQueryVariables
>;
export const RunsBackgroundDocument = gql`
  query RunsBackground {
    runTemplatesForCombobox(order: { runTemplateId: DESC }) {
      runTemplateId
      runTemplateName
    }
    experimentSettings {
      experimentSettingId
      experimentSettingScanTypeId
      experimentSetting
      plateLayoutTypeId
    }
    methods {
      ...Method
    }
  }
  ${MethodFragmentDoc}
`;

/**
 * __useRunsBackgroundQuery__
 *
 * To run a query within a React component, call `useRunsBackgroundQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunsBackgroundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunsBackgroundQuery({
 *   variables: {
 *   },
 * });
 */
export function useRunsBackgroundQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RunsBackgroundQuery,
    RunsBackgroundQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RunsBackgroundQuery, RunsBackgroundQueryVariables>(
    RunsBackgroundDocument,
    options
  );
}
export function useRunsBackgroundLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RunsBackgroundQuery,
    RunsBackgroundQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RunsBackgroundQuery, RunsBackgroundQueryVariables>(
    RunsBackgroundDocument,
    options
  );
}
export type RunsBackgroundQueryHookResult = ReturnType<
  typeof useRunsBackgroundQuery
>;
export type RunsBackgroundLazyQueryHookResult = ReturnType<
  typeof useRunsBackgroundLazyQuery
>;
export type RunsBackgroundQueryResult = Apollo.QueryResult<
  RunsBackgroundQuery,
  RunsBackgroundQueryVariables
>;
export const TicketBackgroundDocument = gql`
  query TicketBackground {
    ticketTypes {
      ticketTypeId
      ticketType
    }
    ticketAssetTypes {
      assetType
      assetTypeId
    }
    ticketAssets {
      assetTypeId
      assetType {
        assetTypeId
        assetType
      }
      assetId
      asset
    }
  }
`;

/**
 * __useTicketBackgroundQuery__
 *
 * To run a query within a React component, call `useTicketBackgroundQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketBackgroundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketBackgroundQuery({
 *   variables: {
 *   },
 * });
 */
export function useTicketBackgroundQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TicketBackgroundQuery,
    TicketBackgroundQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketBackgroundQuery, TicketBackgroundQueryVariables>(
    TicketBackgroundDocument,
    options
  );
}
export function useTicketBackgroundLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TicketBackgroundQuery,
    TicketBackgroundQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TicketBackgroundQuery,
    TicketBackgroundQueryVariables
  >(TicketBackgroundDocument, options);
}
export type TicketBackgroundQueryHookResult = ReturnType<
  typeof useTicketBackgroundQuery
>;
export type TicketBackgroundLazyQueryHookResult = ReturnType<
  typeof useTicketBackgroundLazyQuery
>;
export type TicketBackgroundQueryResult = Apollo.QueryResult<
  TicketBackgroundQuery,
  TicketBackgroundQueryVariables
>;
export const IncomingSamplesBackgroundDocument = gql`
  query IncomingSamplesBackground {
    sampleTypes(where: { isSourceSampleType: { eq: true } }) {
      sampleTypeId
      sampleTypeString
    }
    projects(where: { isActive: { eq: true } }) {
      projectId
      nyscfprefix
      projectName
    }
  }
`;

/**
 * __useIncomingSamplesBackgroundQuery__
 *
 * To run a query within a React component, call `useIncomingSamplesBackgroundQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncomingSamplesBackgroundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncomingSamplesBackgroundQuery({
 *   variables: {
 *   },
 * });
 */
export function useIncomingSamplesBackgroundQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IncomingSamplesBackgroundQuery,
    IncomingSamplesBackgroundQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IncomingSamplesBackgroundQuery,
    IncomingSamplesBackgroundQueryVariables
  >(IncomingSamplesBackgroundDocument, options);
}
export function useIncomingSamplesBackgroundLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IncomingSamplesBackgroundQuery,
    IncomingSamplesBackgroundQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IncomingSamplesBackgroundQuery,
    IncomingSamplesBackgroundQueryVariables
  >(IncomingSamplesBackgroundDocument, options);
}
export type IncomingSamplesBackgroundQueryHookResult = ReturnType<
  typeof useIncomingSamplesBackgroundQuery
>;
export type IncomingSamplesBackgroundLazyQueryHookResult = ReturnType<
  typeof useIncomingSamplesBackgroundLazyQuery
>;
export type IncomingSamplesBackgroundQueryResult = Apollo.QueryResult<
  IncomingSamplesBackgroundQuery,
  IncomingSamplesBackgroundQueryVariables
>;
export const GetDashboardTasksDocument = gql`
  query getDashboardTasks($loggedInUser: Int, $status: [TaskStatusEnum!]) {
    getOverdueTasks: allUserAndGroupTasks(
      where: { and: [{ status: { in: [OVERDUE] } }] }
    ) {
      ...DashboardTask
    }
    getMyTasks: allUserAndGroupTasks(
      where: {
        or: [
          {
            and: [
              { startTime: { gte: "T04:01:00.000" } }
              { startTime: { lt: "T03:59:00.000" } }
              { assignedUsers: { some: { eq: $loggedInUser } } }
              { status: { in: $status } }
            ]
          }
          {
            and: [
              { status: { in: [IN_PROGRESS] } }
              { assignedUsers: { some: { eq: $loggedInUser } } }
            ]
          }
        ]
      }
    ) {
      ...DashboardTask
    }
    getGroupTasks: allUserAndGroupTasks(
      where: {
        or: [
          {
            and: [
              { startTime: { gte: "T04:01:00.00" } }
              { startTime: { lt: "T03:59:00.000" } }
              { assignedUsers: { none: { eq: $loggedInUser } } }
              { status: { in: $status } }
            ]
          }
          {
            and: [
              { status: { in: [IN_PROGRESS] } }
              { assignedUsers: { none: { eq: $loggedInUser } } }
            ]
          }
        ]
      }
    ) {
      ...DashboardTask
    }
    getLaterThisWeekTasks: allUserAndGroupTasks(
      where: {
        and: [
          { startTime: { gt: "T04:01:00.000" } }
          { startTime: { lt: "" } }
          { status: { in: $status } }
        ]
      }
      order: { startTime: ASC }
    ) {
      ...DashboardTask
    }
  }
  ${DashboardTaskFragmentDoc}
`;

/**
 * __useGetDashboardTasksQuery__
 *
 * To run a query within a React component, call `useGetDashboardTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardTasksQuery({
 *   variables: {
 *      loggedInUser: // value for 'loggedInUser'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetDashboardTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardTasksQuery,
    GetDashboardTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardTasksQuery,
    GetDashboardTasksQueryVariables
  >(GetDashboardTasksDocument, options);
}
export function useGetDashboardTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardTasksQuery,
    GetDashboardTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardTasksQuery,
    GetDashboardTasksQueryVariables
  >(GetDashboardTasksDocument, options);
}
export type GetDashboardTasksQueryHookResult = ReturnType<
  typeof useGetDashboardTasksQuery
>;
export type GetDashboardTasksLazyQueryHookResult = ReturnType<
  typeof useGetDashboardTasksLazyQuery
>;
export type GetDashboardTasksQueryResult = Apollo.QueryResult<
  GetDashboardTasksQuery,
  GetDashboardTasksQueryVariables
>;
export const GetTaskHistoryDocument = gql`
  query getTaskHistory($where: WebSuiteTaskLogFilterInput) {
    taskHistory(where: $where) {
      taskParentId
      taskId
      taskType
      actionPerformed
      actionPerformedTime
      actionPerformedBy
    }
  }
`;

/**
 * __useGetTaskHistoryQuery__
 *
 * To run a query within a React component, call `useGetTaskHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskHistoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTaskHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTaskHistoryQuery,
    GetTaskHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTaskHistoryQuery, GetTaskHistoryQueryVariables>(
    GetTaskHistoryDocument,
    options
  );
}
export function useGetTaskHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaskHistoryQuery,
    GetTaskHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTaskHistoryQuery, GetTaskHistoryQueryVariables>(
    GetTaskHistoryDocument,
    options
  );
}
export type GetTaskHistoryQueryHookResult = ReturnType<
  typeof useGetTaskHistoryQuery
>;
export type GetTaskHistoryLazyQueryHookResult = ReturnType<
  typeof useGetTaskHistoryLazyQuery
>;
export type GetTaskHistoryQueryResult = Apollo.QueryResult<
  GetTaskHistoryQuery,
  GetTaskHistoryQueryVariables
>;
export const GetIncludedFeedPlatesDocument = gql`
  query getIncludedFeedPlates($dateScheduled: DateTime!) {
    includedFeedPlates(dateScheduled: $dateScheduled) {
      runName
      reason
      plates {
        plateID
        plateBarcode
        activeWellCount
      }
    }
  }
`;

/**
 * __useGetIncludedFeedPlatesQuery__
 *
 * To run a query within a React component, call `useGetIncludedFeedPlatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncludedFeedPlatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncludedFeedPlatesQuery({
 *   variables: {
 *      dateScheduled: // value for 'dateScheduled'
 *   },
 * });
 */
export function useGetIncludedFeedPlatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIncludedFeedPlatesQuery,
    GetIncludedFeedPlatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIncludedFeedPlatesQuery,
    GetIncludedFeedPlatesQueryVariables
  >(GetIncludedFeedPlatesDocument, options);
}
export function useGetIncludedFeedPlatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIncludedFeedPlatesQuery,
    GetIncludedFeedPlatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIncludedFeedPlatesQuery,
    GetIncludedFeedPlatesQueryVariables
  >(GetIncludedFeedPlatesDocument, options);
}
export type GetIncludedFeedPlatesQueryHookResult = ReturnType<
  typeof useGetIncludedFeedPlatesQuery
>;
export type GetIncludedFeedPlatesLazyQueryHookResult = ReturnType<
  typeof useGetIncludedFeedPlatesLazyQuery
>;
export type GetIncludedFeedPlatesQueryResult = Apollo.QueryResult<
  GetIncludedFeedPlatesQuery,
  GetIncludedFeedPlatesQueryVariables
>;
export const GetExcludedFeedPlatesDocument = gql`
  query getExcludedFeedPlates($dateScheduled: DateTime!) {
    excludedFeedPlates(dateScheduled: $dateScheduled) {
      runName
      reason
      plates {
        plateID
        plateBarcode
        activeWellCount
      }
    }
  }
`;

/**
 * __useGetExcludedFeedPlatesQuery__
 *
 * To run a query within a React component, call `useGetExcludedFeedPlatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExcludedFeedPlatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExcludedFeedPlatesQuery({
 *   variables: {
 *      dateScheduled: // value for 'dateScheduled'
 *   },
 * });
 */
export function useGetExcludedFeedPlatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExcludedFeedPlatesQuery,
    GetExcludedFeedPlatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetExcludedFeedPlatesQuery,
    GetExcludedFeedPlatesQueryVariables
  >(GetExcludedFeedPlatesDocument, options);
}
export function useGetExcludedFeedPlatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExcludedFeedPlatesQuery,
    GetExcludedFeedPlatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExcludedFeedPlatesQuery,
    GetExcludedFeedPlatesQueryVariables
  >(GetExcludedFeedPlatesDocument, options);
}
export type GetExcludedFeedPlatesQueryHookResult = ReturnType<
  typeof useGetExcludedFeedPlatesQuery
>;
export type GetExcludedFeedPlatesLazyQueryHookResult = ReturnType<
  typeof useGetExcludedFeedPlatesLazyQuery
>;
export type GetExcludedFeedPlatesQueryResult = Apollo.QueryResult<
  GetExcludedFeedPlatesQuery,
  GetExcludedFeedPlatesQueryVariables
>;
export const GetPlateMapDocument = gql`
  query getPlateMap($plateID: Int!) {
    plateMap(plateID: $plateID) {
      plateBarcode
      arrayPlateType {
        plateLayoutType {
          plateName
        }
      }
      containers {
        containerId
        rowPos
        colPos
        isActive
      }
    }
  }
`;

/**
 * __useGetPlateMapQuery__
 *
 * To run a query within a React component, call `useGetPlateMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlateMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlateMapQuery({
 *   variables: {
 *      plateID: // value for 'plateID'
 *   },
 * });
 */
export function useGetPlateMapQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlateMapQuery,
    GetPlateMapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlateMapQuery, GetPlateMapQueryVariables>(
    GetPlateMapDocument,
    options
  );
}
export function useGetPlateMapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlateMapQuery,
    GetPlateMapQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlateMapQuery, GetPlateMapQueryVariables>(
    GetPlateMapDocument,
    options
  );
}
export type GetPlateMapQueryHookResult = ReturnType<typeof useGetPlateMapQuery>;
export type GetPlateMapLazyQueryHookResult = ReturnType<
  typeof useGetPlateMapLazyQuery
>;
export type GetPlateMapQueryResult = Apollo.QueryResult<
  GetPlateMapQuery,
  GetPlateMapQueryVariables
>;
export const GetDashboardTaskDocument = gql`
  query getDashboardTask($taskID: Int!, $taskType: TaskTypeEnum!) {
    dashboardTask(taskID: $taskID, taskType: $taskType) {
      ...DashboardTask
    }
  }
  ${DashboardTaskFragmentDoc}
`;

/**
 * __useGetDashboardTaskQuery__
 *
 * To run a query within a React component, call `useGetDashboardTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardTaskQuery({
 *   variables: {
 *      taskID: // value for 'taskID'
 *      taskType: // value for 'taskType'
 *   },
 * });
 */
export function useGetDashboardTaskQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDashboardTaskQuery,
    GetDashboardTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDashboardTaskQuery, GetDashboardTaskQueryVariables>(
    GetDashboardTaskDocument,
    options
  );
}
export function useGetDashboardTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardTaskQuery,
    GetDashboardTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardTaskQuery,
    GetDashboardTaskQueryVariables
  >(GetDashboardTaskDocument, options);
}
export type GetDashboardTaskQueryHookResult = ReturnType<
  typeof useGetDashboardTaskQuery
>;
export type GetDashboardTaskLazyQueryHookResult = ReturnType<
  typeof useGetDashboardTaskLazyQuery
>;
export type GetDashboardTaskQueryResult = Apollo.QueryResult<
  GetDashboardTaskQuery,
  GetDashboardTaskQueryVariables
>;
export const GetFolderDirectoryDocument = gql`
  query getFolderDirectory {
    directory {
      ...Directory
    }
  }
  ${DirectoryFragmentDoc}
`;

/**
 * __useGetFolderDirectoryQuery__
 *
 * To run a query within a React component, call `useGetFolderDirectoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFolderDirectoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFolderDirectoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFolderDirectoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFolderDirectoryQuery,
    GetFolderDirectoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFolderDirectoryQuery,
    GetFolderDirectoryQueryVariables
  >(GetFolderDirectoryDocument, options);
}
export function useGetFolderDirectoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFolderDirectoryQuery,
    GetFolderDirectoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFolderDirectoryQuery,
    GetFolderDirectoryQueryVariables
  >(GetFolderDirectoryDocument, options);
}
export type GetFolderDirectoryQueryHookResult = ReturnType<
  typeof useGetFolderDirectoryQuery
>;
export type GetFolderDirectoryLazyQueryHookResult = ReturnType<
  typeof useGetFolderDirectoryLazyQuery
>;
export type GetFolderDirectoryQueryResult = Apollo.QueryResult<
  GetFolderDirectoryQuery,
  GetFolderDirectoryQueryVariables
>;
export const GetInsertableFoldersDocument = gql`
  query getInsertableFolders {
    insertableFolders {
      ...FolderStructure
    }
  }
  ${FolderStructureFragmentDoc}
`;

/**
 * __useGetInsertableFoldersQuery__
 *
 * To run a query within a React component, call `useGetInsertableFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInsertableFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsertableFoldersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInsertableFoldersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInsertableFoldersQuery,
    GetInsertableFoldersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInsertableFoldersQuery,
    GetInsertableFoldersQueryVariables
  >(GetInsertableFoldersDocument, options);
}
export function useGetInsertableFoldersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInsertableFoldersQuery,
    GetInsertableFoldersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInsertableFoldersQuery,
    GetInsertableFoldersQueryVariables
  >(GetInsertableFoldersDocument, options);
}
export type GetInsertableFoldersQueryHookResult = ReturnType<
  typeof useGetInsertableFoldersQuery
>;
export type GetInsertableFoldersLazyQueryHookResult = ReturnType<
  typeof useGetInsertableFoldersLazyQuery
>;
export type GetInsertableFoldersQueryResult = Apollo.QueryResult<
  GetInsertableFoldersQuery,
  GetInsertableFoldersQueryVariables
>;
export const GetFoldersDocument = gql`
  query getFolders(
    $where: FolderStructureFilterInput
    $order: [FolderStructureSortInput!]
  ) {
    folders(where: $where, order: $order) {
      folderId
      folderName
      folderParentId
      folderParent {
        folderId
        folderName
      }
      runs {
        runId
        runName
        runDateScheduled
        runStages {
          runStageId
          runStageName
        }
      }
    }
  }
`;

/**
 * __useGetFoldersQuery__
 *
 * To run a query within a React component, call `useGetFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFoldersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetFoldersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFoldersQuery,
    GetFoldersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFoldersQuery, GetFoldersQueryVariables>(
    GetFoldersDocument,
    options
  );
}
export function useGetFoldersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFoldersQuery,
    GetFoldersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFoldersQuery, GetFoldersQueryVariables>(
    GetFoldersDocument,
    options
  );
}
export type GetFoldersQueryHookResult = ReturnType<typeof useGetFoldersQuery>;
export type GetFoldersLazyQueryHookResult = ReturnType<
  typeof useGetFoldersLazyQuery
>;
export type GetFoldersQueryResult = Apollo.QueryResult<
  GetFoldersQuery,
  GetFoldersQueryVariables
>;
export const GetAllFoldersDocument = gql`
  query GetAllFolders {
    folders {
      ...FolderStructure
    }
  }
  ${FolderStructureFragmentDoc}
`;

/**
 * __useGetAllFoldersQuery__
 *
 * To run a query within a React component, call `useGetAllFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFoldersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllFoldersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllFoldersQuery,
    GetAllFoldersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllFoldersQuery, GetAllFoldersQueryVariables>(
    GetAllFoldersDocument,
    options
  );
}
export function useGetAllFoldersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllFoldersQuery,
    GetAllFoldersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllFoldersQuery, GetAllFoldersQueryVariables>(
    GetAllFoldersDocument,
    options
  );
}
export type GetAllFoldersQueryHookResult = ReturnType<
  typeof useGetAllFoldersQuery
>;
export type GetAllFoldersLazyQueryHookResult = ReturnType<
  typeof useGetAllFoldersLazyQuery
>;
export type GetAllFoldersQueryResult = Apollo.QueryResult<
  GetAllFoldersQuery,
  GetAllFoldersQueryVariables
>;
export const GetFolderByIdDocument = gql`
  query GetFolderById($folderId: Int!) {
    folder(id: $folderId, typeName: "", keyField: "folderId") @client {
      folderId
      folderParentId
      folderName
    }
  }
`;

/**
 * __useGetFolderByIdQuery__
 *
 * To run a query within a React component, call `useGetFolderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFolderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFolderByIdQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useGetFolderByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFolderByIdQuery,
    GetFolderByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFolderByIdQuery, GetFolderByIdQueryVariables>(
    GetFolderByIdDocument,
    options
  );
}
export function useGetFolderByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFolderByIdQuery,
    GetFolderByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFolderByIdQuery, GetFolderByIdQueryVariables>(
    GetFolderByIdDocument,
    options
  );
}
export type GetFolderByIdQueryHookResult = ReturnType<
  typeof useGetFolderByIdQuery
>;
export type GetFolderByIdLazyQueryHookResult = ReturnType<
  typeof useGetFolderByIdLazyQuery
>;
export type GetFolderByIdQueryResult = Apollo.QueryResult<
  GetFolderByIdQuery,
  GetFolderByIdQueryVariables
>;
export const SearchDirectoryDocument = gql`
  query SearchDirectory($search: String!) {
    searchDirectory(search: $search) @client {
      ...Directory
    }
  }
  ${DirectoryFragmentDoc}
`;

/**
 * __useSearchDirectoryQuery__
 *
 * To run a query within a React component, call `useSearchDirectoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDirectoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDirectoryQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchDirectoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchDirectoryQuery,
    SearchDirectoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchDirectoryQuery, SearchDirectoryQueryVariables>(
    SearchDirectoryDocument,
    options
  );
}
export function useSearchDirectoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchDirectoryQuery,
    SearchDirectoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchDirectoryQuery,
    SearchDirectoryQueryVariables
  >(SearchDirectoryDocument, options);
}
export type SearchDirectoryQueryHookResult = ReturnType<
  typeof useSearchDirectoryQuery
>;
export type SearchDirectoryLazyQueryHookResult = ReturnType<
  typeof useSearchDirectoryLazyQuery
>;
export type SearchDirectoryQueryResult = Apollo.QueryResult<
  SearchDirectoryQuery,
  SearchDirectoryQueryVariables
>;
export const GetIncomingSampleTasksDocument = gql`
  query GetIncomingSampleTasks(
    $where: CollectionTaskFilterInput
    $order: [CollectionTaskSortInput!]
    $after: String
    $pageSize: Int
  ) {
    incomingSampleTasks(
      where: $where
      order: $order
      first: $pageSize
      after: $after
    ) {
      edges {
        node {
          ...IncomingSampleTask
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
  ${IncomingSampleTaskFragmentDoc}
`;

/**
 * __useGetIncomingSampleTasksQuery__
 *
 * To run a query within a React component, call `useGetIncomingSampleTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncomingSampleTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncomingSampleTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      after: // value for 'after'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetIncomingSampleTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetIncomingSampleTasksQuery,
    GetIncomingSampleTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIncomingSampleTasksQuery,
    GetIncomingSampleTasksQueryVariables
  >(GetIncomingSampleTasksDocument, options);
}
export function useGetIncomingSampleTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIncomingSampleTasksQuery,
    GetIncomingSampleTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIncomingSampleTasksQuery,
    GetIncomingSampleTasksQueryVariables
  >(GetIncomingSampleTasksDocument, options);
}
export type GetIncomingSampleTasksQueryHookResult = ReturnType<
  typeof useGetIncomingSampleTasksQuery
>;
export type GetIncomingSampleTasksLazyQueryHookResult = ReturnType<
  typeof useGetIncomingSampleTasksLazyQuery
>;
export type GetIncomingSampleTasksQueryResult = Apollo.QueryResult<
  GetIncomingSampleTasksQuery,
  GetIncomingSampleTasksQueryVariables
>;
export const GetIncomingSampleCollectionsDocument = gql`
  query GetIncomingSampleCollections(
    $where: CollectionFilterInput
    $order: [CollectionSortInput!]
    $after: String
    $pageSize: Int
  ) {
    incomingSampleCollections(
      where: $where
      order: $order
      first: $pageSize
      after: $after
    ) {
      edges {
        node {
          ...IncomingSampleCollection
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
  ${IncomingSampleCollectionFragmentDoc}
`;

/**
 * __useGetIncomingSampleCollectionsQuery__
 *
 * To run a query within a React component, call `useGetIncomingSampleCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncomingSampleCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncomingSampleCollectionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      after: // value for 'after'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetIncomingSampleCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetIncomingSampleCollectionsQuery,
    GetIncomingSampleCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIncomingSampleCollectionsQuery,
    GetIncomingSampleCollectionsQueryVariables
  >(GetIncomingSampleCollectionsDocument, options);
}
export function useGetIncomingSampleCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIncomingSampleCollectionsQuery,
    GetIncomingSampleCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIncomingSampleCollectionsQuery,
    GetIncomingSampleCollectionsQueryVariables
  >(GetIncomingSampleCollectionsDocument, options);
}
export type GetIncomingSampleCollectionsQueryHookResult = ReturnType<
  typeof useGetIncomingSampleCollectionsQuery
>;
export type GetIncomingSampleCollectionsLazyQueryHookResult = ReturnType<
  typeof useGetIncomingSampleCollectionsLazyQuery
>;
export type GetIncomingSampleCollectionsQueryResult = Apollo.QueryResult<
  GetIncomingSampleCollectionsQuery,
  GetIncomingSampleCollectionsQueryVariables
>;
export const GetSourceSampleTypesDocument = gql`
  query GetSourceSampleTypes {
    sampleTypes(where: { isSourceSampleType: { eq: true } }) {
      sampleTypeId
      sampleTypeString
    }
  }
`;

/**
 * __useGetSourceSampleTypesQuery__
 *
 * To run a query within a React component, call `useGetSourceSampleTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSourceSampleTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSourceSampleTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSourceSampleTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSourceSampleTypesQuery,
    GetSourceSampleTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSourceSampleTypesQuery,
    GetSourceSampleTypesQueryVariables
  >(GetSourceSampleTypesDocument, options);
}
export function useGetSourceSampleTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSourceSampleTypesQuery,
    GetSourceSampleTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSourceSampleTypesQuery,
    GetSourceSampleTypesQueryVariables
  >(GetSourceSampleTypesDocument, options);
}
export type GetSourceSampleTypesQueryHookResult = ReturnType<
  typeof useGetSourceSampleTypesQuery
>;
export type GetSourceSampleTypesLazyQueryHookResult = ReturnType<
  typeof useGetSourceSampleTypesLazyQuery
>;
export type GetSourceSampleTypesQueryResult = Apollo.QueryResult<
  GetSourceSampleTypesQuery,
  GetSourceSampleTypesQueryVariables
>;
export const GetProjectsDocument = gql`
  query GetProjects {
    projects(where: { isActive: { eq: true } }) {
      projectId
      nyscfprefix
      projectName
    }
  }
`;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectsQuery,
    GetProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
    options
  );
}
export function useGetProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsQuery,
    GetProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
    options
  );
}
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<
  typeof useGetProjectsLazyQuery
>;
export type GetProjectsQueryResult = Apollo.QueryResult<
  GetProjectsQuery,
  GetProjectsQueryVariables
>;
export const GetIncomingSampleCollectionByIdDocument = gql`
  query GetIncomingSampleCollectionById($collectionId: Int!) {
    incomingSamplesCollection(
      id: $collectionId
      typeName: ""
      keyField: "collectionId"
    ) @client {
      ...IncomingSampleCollection
    }
  }
  ${IncomingSampleCollectionFragmentDoc}
`;

/**
 * __useGetIncomingSampleCollectionByIdQuery__
 *
 * To run a query within a React component, call `useGetIncomingSampleCollectionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncomingSampleCollectionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncomingSampleCollectionByIdQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useGetIncomingSampleCollectionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIncomingSampleCollectionByIdQuery,
    GetIncomingSampleCollectionByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIncomingSampleCollectionByIdQuery,
    GetIncomingSampleCollectionByIdQueryVariables
  >(GetIncomingSampleCollectionByIdDocument, options);
}
export function useGetIncomingSampleCollectionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIncomingSampleCollectionByIdQuery,
    GetIncomingSampleCollectionByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIncomingSampleCollectionByIdQuery,
    GetIncomingSampleCollectionByIdQueryVariables
  >(GetIncomingSampleCollectionByIdDocument, options);
}
export type GetIncomingSampleCollectionByIdQueryHookResult = ReturnType<
  typeof useGetIncomingSampleCollectionByIdQuery
>;
export type GetIncomingSampleCollectionByIdLazyQueryHookResult = ReturnType<
  typeof useGetIncomingSampleCollectionByIdLazyQuery
>;
export type GetIncomingSampleCollectionByIdQueryResult = Apollo.QueryResult<
  GetIncomingSampleCollectionByIdQuery,
  GetIncomingSampleCollectionByIdQueryVariables
>;
export const GetIncomingSampleTaskByIdDocument = gql`
  query GetIncomingSampleTaskById($taskId: Int!) {
    incomingSamplesTask(
      id: $taskId
      typeName: ""
      keyField: "incomingSampleTaskId"
    ) @client {
      ...IncomingSampleTask
    }
  }
  ${IncomingSampleTaskFragmentDoc}
`;

/**
 * __useGetIncomingSampleTaskByIdQuery__
 *
 * To run a query within a React component, call `useGetIncomingSampleTaskByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncomingSampleTaskByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncomingSampleTaskByIdQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetIncomingSampleTaskByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIncomingSampleTaskByIdQuery,
    GetIncomingSampleTaskByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIncomingSampleTaskByIdQuery,
    GetIncomingSampleTaskByIdQueryVariables
  >(GetIncomingSampleTaskByIdDocument, options);
}
export function useGetIncomingSampleTaskByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIncomingSampleTaskByIdQuery,
    GetIncomingSampleTaskByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIncomingSampleTaskByIdQuery,
    GetIncomingSampleTaskByIdQueryVariables
  >(GetIncomingSampleTaskByIdDocument, options);
}
export type GetIncomingSampleTaskByIdQueryHookResult = ReturnType<
  typeof useGetIncomingSampleTaskByIdQuery
>;
export type GetIncomingSampleTaskByIdLazyQueryHookResult = ReturnType<
  typeof useGetIncomingSampleTaskByIdLazyQuery
>;
export type GetIncomingSampleTaskByIdQueryResult = Apollo.QueryResult<
  GetIncomingSampleTaskByIdQuery,
  GetIncomingSampleTaskByIdQueryVariables
>;
export const GetIncomingSampleTasksByIdDocument = gql`
  query GetIncomingSampleTasksById($taskIds: [Int!]!) {
    incomingSamplesTasksFromCache(
      ids: $taskIds
      typeName: ""
      keyField: "incomingSampleTaskId"
    ) @client {
      ...IncomingSampleTask
    }
  }
  ${IncomingSampleTaskFragmentDoc}
`;

/**
 * __useGetIncomingSampleTasksByIdQuery__
 *
 * To run a query within a React component, call `useGetIncomingSampleTasksByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncomingSampleTasksByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncomingSampleTasksByIdQuery({
 *   variables: {
 *      taskIds: // value for 'taskIds'
 *   },
 * });
 */
export function useGetIncomingSampleTasksByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIncomingSampleTasksByIdQuery,
    GetIncomingSampleTasksByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIncomingSampleTasksByIdQuery,
    GetIncomingSampleTasksByIdQueryVariables
  >(GetIncomingSampleTasksByIdDocument, options);
}
export function useGetIncomingSampleTasksByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIncomingSampleTasksByIdQuery,
    GetIncomingSampleTasksByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIncomingSampleTasksByIdQuery,
    GetIncomingSampleTasksByIdQueryVariables
  >(GetIncomingSampleTasksByIdDocument, options);
}
export type GetIncomingSampleTasksByIdQueryHookResult = ReturnType<
  typeof useGetIncomingSampleTasksByIdQuery
>;
export type GetIncomingSampleTasksByIdLazyQueryHookResult = ReturnType<
  typeof useGetIncomingSampleTasksByIdLazyQuery
>;
export type GetIncomingSampleTasksByIdQueryResult = Apollo.QueryResult<
  GetIncomingSampleTasksByIdQuery,
  GetIncomingSampleTasksByIdQueryVariables
>;
export const GetSystemsDocument = gql`
  query getSystems {
    systems {
      systemId
      systemName
    }
  }
`;

/**
 * __useGetSystemsQuery__
 *
 * To run a query within a React component, call `useGetSystemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSystemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSystemsQuery,
    GetSystemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSystemsQuery, GetSystemsQueryVariables>(
    GetSystemsDocument,
    options
  );
}
export function useGetSystemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSystemsQuery,
    GetSystemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSystemsQuery, GetSystemsQueryVariables>(
    GetSystemsDocument,
    options
  );
}
export type GetSystemsQueryHookResult = ReturnType<typeof useGetSystemsQuery>;
export type GetSystemsLazyQueryHookResult = ReturnType<
  typeof useGetSystemsLazyQuery
>;
export type GetSystemsQueryResult = Apollo.QueryResult<
  GetSystemsQuery,
  GetSystemsQueryVariables
>;
export const GetLabComputersDocument = gql`
  query getLabComputers {
    labComputers {
      id
      name
    }
  }
`;

/**
 * __useGetLabComputersQuery__
 *
 * To run a query within a React component, call `useGetLabComputersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabComputersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabComputersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLabComputersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLabComputersQuery,
    GetLabComputersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLabComputersQuery, GetLabComputersQueryVariables>(
    GetLabComputersDocument,
    options
  );
}
export function useGetLabComputersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLabComputersQuery,
    GetLabComputersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLabComputersQuery,
    GetLabComputersQueryVariables
  >(GetLabComputersDocument, options);
}
export type GetLabComputersQueryHookResult = ReturnType<
  typeof useGetLabComputersQuery
>;
export type GetLabComputersLazyQueryHookResult = ReturnType<
  typeof useGetLabComputersLazyQuery
>;
export type GetLabComputersQueryResult = Apollo.QueryResult<
  GetLabComputersQuery,
  GetLabComputersQueryVariables
>;
export const GetSystemDocument = gql`
  query getSystem($systemId: Int!) {
    system(id: $systemId, typeName: "", keyField: "systemId") @client {
      systemId
      systemName
    }
  }
`;

/**
 * __useGetSystemQuery__
 *
 * To run a query within a React component, call `useGetSystemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemQuery({
 *   variables: {
 *      systemId: // value for 'systemId'
 *   },
 * });
 */
export function useGetSystemQuery(
  baseOptions: Apollo.QueryHookOptions<GetSystemQuery, GetSystemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSystemQuery, GetSystemQueryVariables>(
    GetSystemDocument,
    options
  );
}
export function useGetSystemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSystemQuery,
    GetSystemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSystemQuery, GetSystemQueryVariables>(
    GetSystemDocument,
    options
  );
}
export type GetSystemQueryHookResult = ReturnType<typeof useGetSystemQuery>;
export type GetSystemLazyQueryHookResult = ReturnType<
  typeof useGetSystemLazyQuery
>;
export type GetSystemQueryResult = Apollo.QueryResult<
  GetSystemQuery,
  GetSystemQueryVariables
>;
export const ManualTaskManagerDocument = gql`
  query ManualTaskManager(
    $where: ManualTaskTypeFilterInput
    $order: [ManualTaskTypeSortInput!]
  ) {
    manualTaskTypes(where: $where, order: $order) {
      ...ManualTaskType
    }
  }
  ${ManualTaskTypeFragmentDoc}
`;

/**
 * __useManualTaskManagerQuery__
 *
 * To run a query within a React component, call `useManualTaskManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useManualTaskManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManualTaskManagerQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useManualTaskManagerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ManualTaskManagerQuery,
    ManualTaskManagerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ManualTaskManagerQuery,
    ManualTaskManagerQueryVariables
  >(ManualTaskManagerDocument, options);
}
export function useManualTaskManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManualTaskManagerQuery,
    ManualTaskManagerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ManualTaskManagerQuery,
    ManualTaskManagerQueryVariables
  >(ManualTaskManagerDocument, options);
}
export type ManualTaskManagerQueryHookResult = ReturnType<
  typeof useManualTaskManagerQuery
>;
export type ManualTaskManagerLazyQueryHookResult = ReturnType<
  typeof useManualTaskManagerLazyQuery
>;
export type ManualTaskManagerQueryResult = Apollo.QueryResult<
  ManualTaskManagerQuery,
  ManualTaskManagerQueryVariables
>;
export const MethodManagerDocument = gql`
  query MethodManager(
    $where: LookupArrayMethodFilterInput
    $order: [LookupArrayMethodSortInput!]
  ) {
    methods(where: $where, order: $order) {
      ...Method
    }
  }
  ${MethodFragmentDoc}
`;

/**
 * __useMethodManagerQuery__
 *
 * To run a query within a React component, call `useMethodManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMethodManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMethodManagerQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMethodManagerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MethodManagerQuery,
    MethodManagerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MethodManagerQuery, MethodManagerQueryVariables>(
    MethodManagerDocument,
    options
  );
}
export function useMethodManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MethodManagerQuery,
    MethodManagerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MethodManagerQuery, MethodManagerQueryVariables>(
    MethodManagerDocument,
    options
  );
}
export type MethodManagerQueryHookResult = ReturnType<
  typeof useMethodManagerQuery
>;
export type MethodManagerLazyQueryHookResult = ReturnType<
  typeof useMethodManagerLazyQuery
>;
export type MethodManagerQueryResult = Apollo.QueryResult<
  MethodManagerQuery,
  MethodManagerQueryVariables
>;
export const GetMethodsDocument = gql`
  query getMethods {
    methods(where: { isActive: { eq: true } }) {
      ...Method
    }
  }
  ${MethodFragmentDoc}
`;

/**
 * __useGetMethodsQuery__
 *
 * To run a query within a React component, call `useGetMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMethodsQuery,
    GetMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMethodsQuery, GetMethodsQueryVariables>(
    GetMethodsDocument,
    options
  );
}
export function useGetMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMethodsQuery,
    GetMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMethodsQuery, GetMethodsQueryVariables>(
    GetMethodsDocument,
    options
  );
}
export type GetMethodsQueryHookResult = ReturnType<typeof useGetMethodsQuery>;
export type GetMethodsLazyQueryHookResult = ReturnType<
  typeof useGetMethodsLazyQuery
>;
export type GetMethodsQueryResult = Apollo.QueryResult<
  GetMethodsQuery,
  GetMethodsQueryVariables
>;
export const GetManualTaskTypesDocument = gql`
  query getManualTaskTypes {
    manualTaskTypes {
      ...ManualTaskType
    }
  }
  ${ManualTaskTypeFragmentDoc}
`;

/**
 * __useGetManualTaskTypesQuery__
 *
 * To run a query within a React component, call `useGetManualTaskTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManualTaskTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManualTaskTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManualTaskTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManualTaskTypesQuery,
    GetManualTaskTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManualTaskTypesQuery,
    GetManualTaskTypesQueryVariables
  >(GetManualTaskTypesDocument, options);
}
export function useGetManualTaskTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManualTaskTypesQuery,
    GetManualTaskTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManualTaskTypesQuery,
    GetManualTaskTypesQueryVariables
  >(GetManualTaskTypesDocument, options);
}
export type GetManualTaskTypesQueryHookResult = ReturnType<
  typeof useGetManualTaskTypesQuery
>;
export type GetManualTaskTypesLazyQueryHookResult = ReturnType<
  typeof useGetManualTaskTypesLazyQuery
>;
export type GetManualTaskTypesQueryResult = Apollo.QueryResult<
  GetManualTaskTypesQuery,
  GetManualTaskTypesQueryVariables
>;
export const GetMethodByIdDocument = gql`
  query GetMethodById($methodId: Int!) {
    method(id: $methodId, typeName: "", keyField: "methodId") @client {
      ...Method
    }
  }
  ${MethodFragmentDoc}
`;

/**
 * __useGetMethodByIdQuery__
 *
 * To run a query within a React component, call `useGetMethodByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMethodByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMethodByIdQuery({
 *   variables: {
 *      methodId: // value for 'methodId'
 *   },
 * });
 */
export function useGetMethodByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMethodByIdQuery,
    GetMethodByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMethodByIdQuery, GetMethodByIdQueryVariables>(
    GetMethodByIdDocument,
    options
  );
}
export function useGetMethodByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMethodByIdQuery,
    GetMethodByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMethodByIdQuery, GetMethodByIdQueryVariables>(
    GetMethodByIdDocument,
    options
  );
}
export type GetMethodByIdQueryHookResult = ReturnType<
  typeof useGetMethodByIdQuery
>;
export type GetMethodByIdLazyQueryHookResult = ReturnType<
  typeof useGetMethodByIdLazyQuery
>;
export type GetMethodByIdQueryResult = Apollo.QueryResult<
  GetMethodByIdQuery,
  GetMethodByIdQueryVariables
>;
export const GetManualTaskTypeByIdDocument = gql`
  query GetManualTaskTypeById($manualTaskTypeId: Int!) {
    manualTaskType(id: $manualTaskTypeId, typeName: "", keyField: "id")
      @client {
      ...ManualTaskType
    }
  }
  ${ManualTaskTypeFragmentDoc}
`;

/**
 * __useGetManualTaskTypeByIdQuery__
 *
 * To run a query within a React component, call `useGetManualTaskTypeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManualTaskTypeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManualTaskTypeByIdQuery({
 *   variables: {
 *      manualTaskTypeId: // value for 'manualTaskTypeId'
 *   },
 * });
 */
export function useGetManualTaskTypeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManualTaskTypeByIdQuery,
    GetManualTaskTypeByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManualTaskTypeByIdQuery,
    GetManualTaskTypeByIdQueryVariables
  >(GetManualTaskTypeByIdDocument, options);
}
export function useGetManualTaskTypeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManualTaskTypeByIdQuery,
    GetManualTaskTypeByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManualTaskTypeByIdQuery,
    GetManualTaskTypeByIdQueryVariables
  >(GetManualTaskTypeByIdDocument, options);
}
export type GetManualTaskTypeByIdQueryHookResult = ReturnType<
  typeof useGetManualTaskTypeByIdQuery
>;
export type GetManualTaskTypeByIdLazyQueryHookResult = ReturnType<
  typeof useGetManualTaskTypeByIdLazyQuery
>;
export type GetManualTaskTypeByIdQueryResult = Apollo.QueryResult<
  GetManualTaskTypeByIdQuery,
  GetManualTaskTypeByIdQueryVariables
>;
export const GetReservedMethodsDocument = gql`
  query GetReservedMethods($runId: Int!) {
    reservedMethods(runId: $runId) {
      methodId
      methodReservationId
      methodReservationsPlateBarcodes {
        plateBarcode
      }
    }
  }
`;

/**
 * __useGetReservedMethodsQuery__
 *
 * To run a query within a React component, call `useGetReservedMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservedMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservedMethodsQuery({
 *   variables: {
 *      runId: // value for 'runId'
 *   },
 * });
 */
export function useGetReservedMethodsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReservedMethodsQuery,
    GetReservedMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetReservedMethodsQuery,
    GetReservedMethodsQueryVariables
  >(GetReservedMethodsDocument, options);
}
export function useGetReservedMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReservedMethodsQuery,
    GetReservedMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetReservedMethodsQuery,
    GetReservedMethodsQueryVariables
  >(GetReservedMethodsDocument, options);
}
export type GetReservedMethodsQueryHookResult = ReturnType<
  typeof useGetReservedMethodsQuery
>;
export type GetReservedMethodsLazyQueryHookResult = ReturnType<
  typeof useGetReservedMethodsLazyQuery
>;
export type GetReservedMethodsQueryResult = Apollo.QueryResult<
  GetReservedMethodsQuery,
  GetReservedMethodsQueryVariables
>;
export const GetExperimentSettingsDocument = gql`
  query GetExperimentSettings {
    experimentSettings {
      experimentSettingId
      experimentSettingScanTypeId
      experimentSetting
      plateLayoutTypeId
    }
  }
`;

/**
 * __useGetExperimentSettingsQuery__
 *
 * To run a query within a React component, call `useGetExperimentSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExperimentSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExperimentSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExperimentSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetExperimentSettingsQuery,
    GetExperimentSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetExperimentSettingsQuery,
    GetExperimentSettingsQueryVariables
  >(GetExperimentSettingsDocument, options);
}
export function useGetExperimentSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExperimentSettingsQuery,
    GetExperimentSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExperimentSettingsQuery,
    GetExperimentSettingsQueryVariables
  >(GetExperimentSettingsDocument, options);
}
export type GetExperimentSettingsQueryHookResult = ReturnType<
  typeof useGetExperimentSettingsQuery
>;
export type GetExperimentSettingsLazyQueryHookResult = ReturnType<
  typeof useGetExperimentSettingsLazyQuery
>;
export type GetExperimentSettingsQueryResult = Apollo.QueryResult<
  GetExperimentSettingsQuery,
  GetExperimentSettingsQueryVariables
>;
export const GetActiveMethodReservationsDocument = gql`
  query GetActiveMethodReservations {
    activeReservations {
      methodReservationId
      isCompleted
      methodReservationsPlateBarcodes {
        plateBarcode
      }
      method {
        ...Method
      }
    }
  }
  ${MethodFragmentDoc}
`;

/**
 * __useGetActiveMethodReservationsQuery__
 *
 * To run a query within a React component, call `useGetActiveMethodReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveMethodReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveMethodReservationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveMethodReservationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActiveMethodReservationsQuery,
    GetActiveMethodReservationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveMethodReservationsQuery,
    GetActiveMethodReservationsQueryVariables
  >(GetActiveMethodReservationsDocument, options);
}
export function useGetActiveMethodReservationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveMethodReservationsQuery,
    GetActiveMethodReservationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveMethodReservationsQuery,
    GetActiveMethodReservationsQueryVariables
  >(GetActiveMethodReservationsDocument, options);
}
export type GetActiveMethodReservationsQueryHookResult = ReturnType<
  typeof useGetActiveMethodReservationsQuery
>;
export type GetActiveMethodReservationsLazyQueryHookResult = ReturnType<
  typeof useGetActiveMethodReservationsLazyQuery
>;
export type GetActiveMethodReservationsQueryResult = Apollo.QueryResult<
  GetActiveMethodReservationsQuery,
  GetActiveMethodReservationsQueryVariables
>;
export const GetMethodReservationByPersonalTaskIdDocument = gql`
  query getMethodReservationByPersonalTaskId($personalTaskId: Int!) {
    methodReservation(where: { personalTaskId: { eq: $personalTaskId } }) {
      methodReservationId
      isCompleted
      methodReservationsPlateBarcodes {
        plateBarcode
      }
      worklist {
        worklistId
        worklistContentImagings {
          ...ImagingWorklist
        }
      }
      method {
        ...Method
      }
    }
  }
  ${ImagingWorklistFragmentDoc}
  ${MethodFragmentDoc}
`;

/**
 * __useGetMethodReservationByPersonalTaskIdQuery__
 *
 * To run a query within a React component, call `useGetMethodReservationByPersonalTaskIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMethodReservationByPersonalTaskIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMethodReservationByPersonalTaskIdQuery({
 *   variables: {
 *      personalTaskId: // value for 'personalTaskId'
 *   },
 * });
 */
export function useGetMethodReservationByPersonalTaskIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMethodReservationByPersonalTaskIdQuery,
    GetMethodReservationByPersonalTaskIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMethodReservationByPersonalTaskIdQuery,
    GetMethodReservationByPersonalTaskIdQueryVariables
  >(GetMethodReservationByPersonalTaskIdDocument, options);
}
export function useGetMethodReservationByPersonalTaskIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMethodReservationByPersonalTaskIdQuery,
    GetMethodReservationByPersonalTaskIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMethodReservationByPersonalTaskIdQuery,
    GetMethodReservationByPersonalTaskIdQueryVariables
  >(GetMethodReservationByPersonalTaskIdDocument, options);
}
export type GetMethodReservationByPersonalTaskIdQueryHookResult = ReturnType<
  typeof useGetMethodReservationByPersonalTaskIdQuery
>;
export type GetMethodReservationByPersonalTaskIdLazyQueryHookResult =
  ReturnType<typeof useGetMethodReservationByPersonalTaskIdLazyQuery>;
export type GetMethodReservationByPersonalTaskIdQueryResult =
  Apollo.QueryResult<
    GetMethodReservationByPersonalTaskIdQuery,
    GetMethodReservationByPersonalTaskIdQueryVariables
  >;
export const GetMethodReservationByRunActivityIdDocument = gql`
  query getMethodReservationByRunActivityId($runActivityId: Int!) {
    methodReservation(where: { runActivityId: { eq: $runActivityId } }) {
      methodReservationId
      isCompleted
      methodReservationsPlateBarcodes {
        plateBarcode
      }
      worklist {
        worklistId
        worklistContentImagings {
          ...ImagingWorklist
        }
      }
      method {
        ...Method
      }
    }
  }
  ${ImagingWorklistFragmentDoc}
  ${MethodFragmentDoc}
`;

/**
 * __useGetMethodReservationByRunActivityIdQuery__
 *
 * To run a query within a React component, call `useGetMethodReservationByRunActivityIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMethodReservationByRunActivityIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMethodReservationByRunActivityIdQuery({
 *   variables: {
 *      runActivityId: // value for 'runActivityId'
 *   },
 * });
 */
export function useGetMethodReservationByRunActivityIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMethodReservationByRunActivityIdQuery,
    GetMethodReservationByRunActivityIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMethodReservationByRunActivityIdQuery,
    GetMethodReservationByRunActivityIdQueryVariables
  >(GetMethodReservationByRunActivityIdDocument, options);
}
export function useGetMethodReservationByRunActivityIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMethodReservationByRunActivityIdQuery,
    GetMethodReservationByRunActivityIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMethodReservationByRunActivityIdQuery,
    GetMethodReservationByRunActivityIdQueryVariables
  >(GetMethodReservationByRunActivityIdDocument, options);
}
export type GetMethodReservationByRunActivityIdQueryHookResult = ReturnType<
  typeof useGetMethodReservationByRunActivityIdQuery
>;
export type GetMethodReservationByRunActivityIdLazyQueryHookResult = ReturnType<
  typeof useGetMethodReservationByRunActivityIdLazyQuery
>;
export type GetMethodReservationByRunActivityIdQueryResult = Apollo.QueryResult<
  GetMethodReservationByRunActivityIdQuery,
  GetMethodReservationByRunActivityIdQueryVariables
>;
export const GetMyPersonalTasksDocument = gql`
  query getMyPersonalTasks(
    $pageSize: Int
    $after: String
    $where: ManagePersonalTaskFilterInput
    $order_by: [ManagePersonalTaskSortInput!]
  ) {
    myPersonalTasks(
      first: $pageSize
      after: $after
      where: $where
      order: $order_by
    ) {
      edges {
        node {
          ...ManagePersonalTask
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      totalCount
    }
  }
  ${ManagePersonalTaskFragmentDoc}
`;

/**
 * __useGetMyPersonalTasksQuery__
 *
 * To run a query within a React component, call `useGetMyPersonalTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPersonalTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPersonalTasksQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetMyPersonalTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyPersonalTasksQuery,
    GetMyPersonalTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyPersonalTasksQuery,
    GetMyPersonalTasksQueryVariables
  >(GetMyPersonalTasksDocument, options);
}
export function useGetMyPersonalTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyPersonalTasksQuery,
    GetMyPersonalTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyPersonalTasksQuery,
    GetMyPersonalTasksQueryVariables
  >(GetMyPersonalTasksDocument, options);
}
export type GetMyPersonalTasksQueryHookResult = ReturnType<
  typeof useGetMyPersonalTasksQuery
>;
export type GetMyPersonalTasksLazyQueryHookResult = ReturnType<
  typeof useGetMyPersonalTasksLazyQuery
>;
export type GetMyPersonalTasksQueryResult = Apollo.QueryResult<
  GetMyPersonalTasksQuery,
  GetMyPersonalTasksQueryVariables
>;
export const GetPersonalTaskAttachmentsDocument = gql`
  query getPersonalTaskAttachments($taskId: Int!) {
    personalTaskAttachments(taskId: $taskId) {
      ...PersonalTaskAttachment
    }
  }
  ${PersonalTaskAttachmentFragmentDoc}
`;

/**
 * __useGetPersonalTaskAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetPersonalTaskAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalTaskAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalTaskAttachmentsQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetPersonalTaskAttachmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPersonalTaskAttachmentsQuery,
    GetPersonalTaskAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPersonalTaskAttachmentsQuery,
    GetPersonalTaskAttachmentsQueryVariables
  >(GetPersonalTaskAttachmentsDocument, options);
}
export function useGetPersonalTaskAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonalTaskAttachmentsQuery,
    GetPersonalTaskAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPersonalTaskAttachmentsQuery,
    GetPersonalTaskAttachmentsQueryVariables
  >(GetPersonalTaskAttachmentsDocument, options);
}
export type GetPersonalTaskAttachmentsQueryHookResult = ReturnType<
  typeof useGetPersonalTaskAttachmentsQuery
>;
export type GetPersonalTaskAttachmentsLazyQueryHookResult = ReturnType<
  typeof useGetPersonalTaskAttachmentsLazyQuery
>;
export type GetPersonalTaskAttachmentsQueryResult = Apollo.QueryResult<
  GetPersonalTaskAttachmentsQuery,
  GetPersonalTaskAttachmentsQueryVariables
>;
export const GetPersonalTaskTemplatesDocument = gql`
  query getPersonalTaskTemplates(
    $pageSize: Int
    $after: String
    $where: PersonalTaskTemplateFilterInput
    $order_by: [PersonalTaskTemplateSortInput!]
  ) {
    personalTaskTemplates(
      first: $pageSize
      after: $after
      where: $where
      order: $order_by
    ) {
      edges {
        node {
          personalTaskTemplateId
          personalTaskTemplateName
          createdBy
          updatedBy
          updatedByTime
          personalTaskTemplateTasks {
            personalTaskTemplateTaskId
            personalTaskName
            dayScheduled
            userAssignedTo
            groupAssignedTo
            assignedSystem
            notes
            priority
            groups {
              groupId
            }
            users {
              userId
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetPersonalTaskTemplatesQuery__
 *
 * To run a query within a React component, call `useGetPersonalTaskTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalTaskTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalTaskTemplatesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetPersonalTaskTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPersonalTaskTemplatesQuery,
    GetPersonalTaskTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPersonalTaskTemplatesQuery,
    GetPersonalTaskTemplatesQueryVariables
  >(GetPersonalTaskTemplatesDocument, options);
}
export function useGetPersonalTaskTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonalTaskTemplatesQuery,
    GetPersonalTaskTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPersonalTaskTemplatesQuery,
    GetPersonalTaskTemplatesQueryVariables
  >(GetPersonalTaskTemplatesDocument, options);
}
export type GetPersonalTaskTemplatesQueryHookResult = ReturnType<
  typeof useGetPersonalTaskTemplatesQuery
>;
export type GetPersonalTaskTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetPersonalTaskTemplatesLazyQuery
>;
export type GetPersonalTaskTemplatesQueryResult = Apollo.QueryResult<
  GetPersonalTaskTemplatesQuery,
  GetPersonalTaskTemplatesQueryVariables
>;
export const GetRepeatingTasksSummaryDocument = gql`
  query getRepeatingTasksSummary(
    $pageSize: Int
    $after: String
    $where: PersonalTasksRecurringTaskFilterInput
    $order_by: [PersonalTasksRecurringTaskSortInput!]
  ) {
    repeatingTasksSummary(
      first: $pageSize
      after: $after
      where: $where
      order: $order_by
    ) {
      edges {
        node {
          recurringTaskId
          taskName
          repeatingDaysOfWeek
          endRepeatDate
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetRepeatingTasksSummaryQuery__
 *
 * To run a query within a React component, call `useGetRepeatingTasksSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepeatingTasksSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepeatingTasksSummaryQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetRepeatingTasksSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRepeatingTasksSummaryQuery,
    GetRepeatingTasksSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRepeatingTasksSummaryQuery,
    GetRepeatingTasksSummaryQueryVariables
  >(GetRepeatingTasksSummaryDocument, options);
}
export function useGetRepeatingTasksSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRepeatingTasksSummaryQuery,
    GetRepeatingTasksSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRepeatingTasksSummaryQuery,
    GetRepeatingTasksSummaryQueryVariables
  >(GetRepeatingTasksSummaryDocument, options);
}
export type GetRepeatingTasksSummaryQueryHookResult = ReturnType<
  typeof useGetRepeatingTasksSummaryQuery
>;
export type GetRepeatingTasksSummaryLazyQueryHookResult = ReturnType<
  typeof useGetRepeatingTasksSummaryLazyQuery
>;
export type GetRepeatingTasksSummaryQueryResult = Apollo.QueryResult<
  GetRepeatingTasksSummaryQuery,
  GetRepeatingTasksSummaryQueryVariables
>;
export const GetUnavailablePlatesDocument = gql`
  query getUnavailablePlates($methodId: Int!) {
    unavailablePlates(methodId: $methodId)
  }
`;

/**
 * __useGetUnavailablePlatesQuery__
 *
 * To run a query within a React component, call `useGetUnavailablePlatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnavailablePlatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnavailablePlatesQuery({
 *   variables: {
 *      methodId: // value for 'methodId'
 *   },
 * });
 */
export function useGetUnavailablePlatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUnavailablePlatesQuery,
    GetUnavailablePlatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUnavailablePlatesQuery,
    GetUnavailablePlatesQueryVariables
  >(GetUnavailablePlatesDocument, options);
}
export function useGetUnavailablePlatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnavailablePlatesQuery,
    GetUnavailablePlatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUnavailablePlatesQuery,
    GetUnavailablePlatesQueryVariables
  >(GetUnavailablePlatesDocument, options);
}
export type GetUnavailablePlatesQueryHookResult = ReturnType<
  typeof useGetUnavailablePlatesQuery
>;
export type GetUnavailablePlatesLazyQueryHookResult = ReturnType<
  typeof useGetUnavailablePlatesLazyQuery
>;
export type GetUnavailablePlatesQueryResult = Apollo.QueryResult<
  GetUnavailablePlatesQuery,
  GetUnavailablePlatesQueryVariables
>;
export const GetCeligoPlateMetadataDocument = gql`
  query getCeligoPlateMetadata {
    celigoScanPlateMetadata {
      prefix
      plates {
        platesCreated {
          ...PlateCreated
        }
        metadata {
          sourcePlateBarcode
          analysisSetting
          platePitch
          imageExportFormat
          imageExportSetting
          experimentSetting
        }
      }
    }
  }
  ${PlateCreatedFragmentDoc}
`;

/**
 * __useGetCeligoPlateMetadataQuery__
 *
 * To run a query within a React component, call `useGetCeligoPlateMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCeligoPlateMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCeligoPlateMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCeligoPlateMetadataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCeligoPlateMetadataQuery,
    GetCeligoPlateMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCeligoPlateMetadataQuery,
    GetCeligoPlateMetadataQueryVariables
  >(GetCeligoPlateMetadataDocument, options);
}
export function useGetCeligoPlateMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCeligoPlateMetadataQuery,
    GetCeligoPlateMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCeligoPlateMetadataQuery,
    GetCeligoPlateMetadataQueryVariables
  >(GetCeligoPlateMetadataDocument, options);
}
export type GetCeligoPlateMetadataQueryHookResult = ReturnType<
  typeof useGetCeligoPlateMetadataQuery
>;
export type GetCeligoPlateMetadataLazyQueryHookResult = ReturnType<
  typeof useGetCeligoPlateMetadataLazyQuery
>;
export type GetCeligoPlateMetadataQueryResult = Apollo.QueryResult<
  GetCeligoPlateMetadataQuery,
  GetCeligoPlateMetadataQueryVariables
>;
export const GetPlatesDocument = gql`
  query getPlates(
    $pageSize: Int
    $after: String
    $where: PlatesCreatedFilterInput
    $order: [PlatesCreatedSortInput!]
  ) {
    plates(first: $pageSize, after: $after, where: $where, order: $order) {
      edges {
        node {
          ...Plate
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
  ${PlateFragmentDoc}
`;

/**
 * __useGetPlatesQuery__
 *
 * To run a query within a React component, call `useGetPlatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetPlatesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPlatesQuery, GetPlatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlatesQuery, GetPlatesQueryVariables>(
    GetPlatesDocument,
    options
  );
}
export function useGetPlatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlatesQuery,
    GetPlatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlatesQuery, GetPlatesQueryVariables>(
    GetPlatesDocument,
    options
  );
}
export type GetPlatesQueryHookResult = ReturnType<typeof useGetPlatesQuery>;
export type GetPlatesLazyQueryHookResult = ReturnType<
  typeof useGetPlatesLazyQuery
>;
export type GetPlatesQueryResult = Apollo.QueryResult<
  GetPlatesQuery,
  GetPlatesQueryVariables
>;
export const GetPlateInfoDocument = gql`
  query getPlateInfo($plateBarcode: String) {
    plateInfo(plateBarcode: $plateBarcode) {
      ...PlateInfo
    }
  }
  ${PlateInfoFragmentDoc}
`;

/**
 * __useGetPlateInfoQuery__
 *
 * To run a query within a React component, call `useGetPlateInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlateInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlateInfoQuery({
 *   variables: {
 *      plateBarcode: // value for 'plateBarcode'
 *   },
 * });
 */
export function useGetPlateInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPlateInfoQuery,
    GetPlateInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlateInfoQuery, GetPlateInfoQueryVariables>(
    GetPlateInfoDocument,
    options
  );
}
export function useGetPlateInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlateInfoQuery,
    GetPlateInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlateInfoQuery, GetPlateInfoQueryVariables>(
    GetPlateInfoDocument,
    options
  );
}
export type GetPlateInfoQueryHookResult = ReturnType<
  typeof useGetPlateInfoQuery
>;
export type GetPlateInfoLazyQueryHookResult = ReturnType<
  typeof useGetPlateInfoLazyQuery
>;
export type GetPlateInfoQueryResult = Apollo.QueryResult<
  GetPlateInfoQuery,
  GetPlateInfoQueryVariables
>;
export const GetTubeBarcodesForAutocompleteDocument = gql`
  query GetTubeBarcodesForAutocomplete($search: String!) {
    containers(
      where: {
        and: [{ barcode: { neq: null } }, { barcode: { contains: $search } }]
      }
      order: { containerId: DESC }
    ) {
      edges {
        node {
          containerId
          barcode
        }
      }
    }
  }
`;

/**
 * __useGetTubeBarcodesForAutocompleteQuery__
 *
 * To run a query within a React component, call `useGetTubeBarcodesForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTubeBarcodesForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTubeBarcodesForAutocompleteQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetTubeBarcodesForAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTubeBarcodesForAutocompleteQuery,
    GetTubeBarcodesForAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTubeBarcodesForAutocompleteQuery,
    GetTubeBarcodesForAutocompleteQueryVariables
  >(GetTubeBarcodesForAutocompleteDocument, options);
}
export function useGetTubeBarcodesForAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTubeBarcodesForAutocompleteQuery,
    GetTubeBarcodesForAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTubeBarcodesForAutocompleteQuery,
    GetTubeBarcodesForAutocompleteQueryVariables
  >(GetTubeBarcodesForAutocompleteDocument, options);
}
export type GetTubeBarcodesForAutocompleteQueryHookResult = ReturnType<
  typeof useGetTubeBarcodesForAutocompleteQuery
>;
export type GetTubeBarcodesForAutocompleteLazyQueryHookResult = ReturnType<
  typeof useGetTubeBarcodesForAutocompleteLazyQuery
>;
export type GetTubeBarcodesForAutocompleteQueryResult = Apollo.QueryResult<
  GetTubeBarcodesForAutocompleteQuery,
  GetTubeBarcodesForAutocompleteQueryVariables
>;
export const GetPlateForAutocompleteDocument = gql`
  query GetPlateForAutocomplete($search: String!) {
    plates(
      where: { plateBarcode: { contains: $search } }
      order: { plateCreatedId: DESC }
    ) {
      edges {
        node {
          plateCreatedId
          plateBarcode
        }
      }
    }
  }
`;

/**
 * __useGetPlateForAutocompleteQuery__
 *
 * To run a query within a React component, call `useGetPlateForAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlateForAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlateForAutocompleteQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetPlateForAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlateForAutocompleteQuery,
    GetPlateForAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPlateForAutocompleteQuery,
    GetPlateForAutocompleteQueryVariables
  >(GetPlateForAutocompleteDocument, options);
}
export function useGetPlateForAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlateForAutocompleteQuery,
    GetPlateForAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlateForAutocompleteQuery,
    GetPlateForAutocompleteQueryVariables
  >(GetPlateForAutocompleteDocument, options);
}
export type GetPlateForAutocompleteQueryHookResult = ReturnType<
  typeof useGetPlateForAutocompleteQuery
>;
export type GetPlateForAutocompleteLazyQueryHookResult = ReturnType<
  typeof useGetPlateForAutocompleteLazyQuery
>;
export type GetPlateForAutocompleteQueryResult = Apollo.QueryResult<
  GetPlateForAutocompleteQuery,
  GetPlateForAutocompleteQueryVariables
>;
export const GetLotNumbersDocument = gql`
  query GetLotNumbers($search: String!) {
    lotNumbers(
      where: { lotNumber1: { contains: $search } }
      order: { lotNumberId: DESC }
    ) {
      edges {
        node {
          lotNumberId
          lotNumber1
        }
      }
    }
  }
`;

/**
 * __useGetLotNumbersQuery__
 *
 * To run a query within a React component, call `useGetLotNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLotNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLotNumbersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetLotNumbersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLotNumbersQuery,
    GetLotNumbersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLotNumbersQuery, GetLotNumbersQueryVariables>(
    GetLotNumbersDocument,
    options
  );
}
export function useGetLotNumbersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLotNumbersQuery,
    GetLotNumbersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLotNumbersQuery, GetLotNumbersQueryVariables>(
    GetLotNumbersDocument,
    options
  );
}
export type GetLotNumbersQueryHookResult = ReturnType<
  typeof useGetLotNumbersQuery
>;
export type GetLotNumbersLazyQueryHookResult = ReturnType<
  typeof useGetLotNumbersLazyQuery
>;
export type GetLotNumbersQueryResult = Apollo.QueryResult<
  GetLotNumbersQuery,
  GetLotNumbersQueryVariables
>;
export const GetContainerLineageDocument = gql`
  query getContainerLineage($containerId: Int!) {
    containerLineage(containerId: $containerId) {
      ...ContainerLineage
    }
  }
  ${ContainerLineageFragmentDoc}
`;

/**
 * __useGetContainerLineageQuery__
 *
 * To run a query within a React component, call `useGetContainerLineageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContainerLineageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContainerLineageQuery({
 *   variables: {
 *      containerId: // value for 'containerId'
 *   },
 * });
 */
export function useGetContainerLineageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContainerLineageQuery,
    GetContainerLineageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContainerLineageQuery,
    GetContainerLineageQueryVariables
  >(GetContainerLineageDocument, options);
}
export function useGetContainerLineageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContainerLineageQuery,
    GetContainerLineageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContainerLineageQuery,
    GetContainerLineageQueryVariables
  >(GetContainerLineageDocument, options);
}
export type GetContainerLineageQueryHookResult = ReturnType<
  typeof useGetContainerLineageQuery
>;
export type GetContainerLineageLazyQueryHookResult = ReturnType<
  typeof useGetContainerLineageLazyQuery
>;
export type GetContainerLineageQueryResult = Apollo.QueryResult<
  GetContainerLineageQuery,
  GetContainerLineageQueryVariables
>;
export const GetContainersDocument = gql`
  query GetContainers(
    $pageSize: Int
    $after: String
    $order: [ContainerSortInput!]
    $where: ContainerFilterInput
  ) {
    containers(first: $pageSize, after: $after, order: $order, where: $where) {
      edges {
        node {
          ...ContainerTableEntry
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
  ${ContainerTableEntryFragmentDoc}
`;

/**
 * __useGetContainersQuery__
 *
 * To run a query within a React component, call `useGetContainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContainersQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      after: // value for 'after'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetContainersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetContainersQuery,
    GetContainersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContainersQuery, GetContainersQueryVariables>(
    GetContainersDocument,
    options
  );
}
export function useGetContainersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContainersQuery,
    GetContainersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContainersQuery, GetContainersQueryVariables>(
    GetContainersDocument,
    options
  );
}
export type GetContainersQueryHookResult = ReturnType<
  typeof useGetContainersQuery
>;
export type GetContainersLazyQueryHookResult = ReturnType<
  typeof useGetContainersLazyQuery
>;
export type GetContainersQueryResult = Apollo.QueryResult<
  GetContainersQuery,
  GetContainersQueryVariables
>;
export const GetSampleDocument = gql`
  query getSample($sampleId: Int!) {
    sample(id: $sampleId, typeName: "", keyField: "sampleId") @client {
      ...Sample
    }
  }
  ${SampleFragmentDoc}
`;

/**
 * __useGetSampleQuery__
 *
 * To run a query within a React component, call `useGetSampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSampleQuery({
 *   variables: {
 *      sampleId: // value for 'sampleId'
 *   },
 * });
 */
export function useGetSampleQuery(
  baseOptions: Apollo.QueryHookOptions<GetSampleQuery, GetSampleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSampleQuery, GetSampleQueryVariables>(
    GetSampleDocument,
    options
  );
}
export function useGetSampleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSampleQuery,
    GetSampleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSampleQuery, GetSampleQueryVariables>(
    GetSampleDocument,
    options
  );
}
export type GetSampleQueryHookResult = ReturnType<typeof useGetSampleQuery>;
export type GetSampleLazyQueryHookResult = ReturnType<
  typeof useGetSampleLazyQuery
>;
export type GetSampleQueryResult = Apollo.QueryResult<
  GetSampleQuery,
  GetSampleQueryVariables
>;
export const GetPlateByIdDocument = gql`
  query GetPlateById($plateBarcode: String!) {
    plate(id: $plateBarcode, typeName: "", keyField: "plateBarcode") @client {
      ...Plate
    }
  }
  ${PlateFragmentDoc}
`;

/**
 * __useGetPlateByIdQuery__
 *
 * To run a query within a React component, call `useGetPlateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlateByIdQuery({
 *   variables: {
 *      plateBarcode: // value for 'plateBarcode'
 *   },
 * });
 */
export function useGetPlateByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlateByIdQuery,
    GetPlateByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlateByIdQuery, GetPlateByIdQueryVariables>(
    GetPlateByIdDocument,
    options
  );
}
export function useGetPlateByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlateByIdQuery,
    GetPlateByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlateByIdQuery, GetPlateByIdQueryVariables>(
    GetPlateByIdDocument,
    options
  );
}
export type GetPlateByIdQueryHookResult = ReturnType<
  typeof useGetPlateByIdQuery
>;
export type GetPlateByIdLazyQueryHookResult = ReturnType<
  typeof useGetPlateByIdLazyQuery
>;
export type GetPlateByIdQueryResult = Apollo.QueryResult<
  GetPlateByIdQuery,
  GetPlateByIdQueryVariables
>;
export const GetContainerByIdDocument = gql`
  query GetContainerById($containerId: Int!) {
    container(id: $containerId, typeName: "", keyField: "containerId") @client {
      ...ContainerTableEntry
    }
  }
  ${ContainerTableEntryFragmentDoc}
`;

/**
 * __useGetContainerByIdQuery__
 *
 * To run a query within a React component, call `useGetContainerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContainerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContainerByIdQuery({
 *   variables: {
 *      containerId: // value for 'containerId'
 *   },
 * });
 */
export function useGetContainerByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContainerByIdQuery,
    GetContainerByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContainerByIdQuery, GetContainerByIdQueryVariables>(
    GetContainerByIdDocument,
    options
  );
}
export function useGetContainerByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContainerByIdQuery,
    GetContainerByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContainerByIdQuery,
    GetContainerByIdQueryVariables
  >(GetContainerByIdDocument, options);
}
export type GetContainerByIdQueryHookResult = ReturnType<
  typeof useGetContainerByIdQuery
>;
export type GetContainerByIdLazyQueryHookResult = ReturnType<
  typeof useGetContainerByIdLazyQuery
>;
export type GetContainerByIdQueryResult = Apollo.QueryResult<
  GetContainerByIdQuery,
  GetContainerByIdQueryVariables
>;
export const GetCreateRunDefaultsDocument = gql`
  query getCreateRunDefaults($runTemplateId: Int!) {
    createRunDefaults(runTemplateId: $runTemplateId) {
      runFolder
      runName
    }
  }
`;

/**
 * __useGetCreateRunDefaultsQuery__
 *
 * To run a query within a React component, call `useGetCreateRunDefaultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreateRunDefaultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreateRunDefaultsQuery({
 *   variables: {
 *      runTemplateId: // value for 'runTemplateId'
 *   },
 * });
 */
export function useGetCreateRunDefaultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCreateRunDefaultsQuery,
    GetCreateRunDefaultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCreateRunDefaultsQuery,
    GetCreateRunDefaultsQueryVariables
  >(GetCreateRunDefaultsDocument, options);
}
export function useGetCreateRunDefaultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreateRunDefaultsQuery,
    GetCreateRunDefaultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCreateRunDefaultsQuery,
    GetCreateRunDefaultsQueryVariables
  >(GetCreateRunDefaultsDocument, options);
}
export type GetCreateRunDefaultsQueryHookResult = ReturnType<
  typeof useGetCreateRunDefaultsQuery
>;
export type GetCreateRunDefaultsLazyQueryHookResult = ReturnType<
  typeof useGetCreateRunDefaultsLazyQuery
>;
export type GetCreateRunDefaultsQueryResult = Apollo.QueryResult<
  GetCreateRunDefaultsQuery,
  GetCreateRunDefaultsQueryVariables
>;
export const GetActiveRunsDocument = gql`
  query GetActiveRuns {
    runs(
      where: { status: { in: [IN_PROGRESS, SCHEDULED] } }
      order: [{ runName: ASC }]
    ) {
      ...Run
    }
  }
  ${RunFragmentDoc}
`;

/**
 * __useGetActiveRunsQuery__
 *
 * To run a query within a React component, call `useGetActiveRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveRunsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveRunsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActiveRunsQuery,
    GetActiveRunsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActiveRunsQuery, GetActiveRunsQueryVariables>(
    GetActiveRunsDocument,
    options
  );
}
export function useGetActiveRunsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveRunsQuery,
    GetActiveRunsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActiveRunsQuery, GetActiveRunsQueryVariables>(
    GetActiveRunsDocument,
    options
  );
}
export type GetActiveRunsQueryHookResult = ReturnType<
  typeof useGetActiveRunsQuery
>;
export type GetActiveRunsLazyQueryHookResult = ReturnType<
  typeof useGetActiveRunsLazyQuery
>;
export type GetActiveRunsQueryResult = Apollo.QueryResult<
  GetActiveRunsQuery,
  GetActiveRunsQueryVariables
>;
export const GetRunsDocument = gql`
  query getRuns($where: RunFilterInput) {
    runs(where: $where) {
      ...Run
    }
  }
  ${RunFragmentDoc}
`;

/**
 * __useGetRunsQuery__
 *
 * To run a query within a React component, call `useGetRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetRunsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRunsQuery, GetRunsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRunsQuery, GetRunsQueryVariables>(
    GetRunsDocument,
    options
  );
}
export function useGetRunsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRunsQuery, GetRunsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRunsQuery, GetRunsQueryVariables>(
    GetRunsDocument,
    options
  );
}
export type GetRunsQueryHookResult = ReturnType<typeof useGetRunsQuery>;
export type GetRunsLazyQueryHookResult = ReturnType<typeof useGetRunsLazyQuery>;
export type GetRunsQueryResult = Apollo.QueryResult<
  GetRunsQuery,
  GetRunsQueryVariables
>;
export const GetRunTasksDocument = gql`
  query getRunTasks(
    $key: DirectoryFolderKeyInput
    $pageSize: Int
    $after: String
    $order: [RunTaskSortInput!]
    $where: RunTaskFilterInput
  ) {
    runTasks(
      key: $key
      first: $pageSize
      after: $after
      order: $order
      where: $where
    ) {
      edges {
        node {
          ...RunTask
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      totalCount
    }
  }
  ${RunTaskFragmentDoc}
`;

/**
 * __useGetRunTasksQuery__
 *
 * To run a query within a React component, call `useGetRunTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunTasksQuery({
 *   variables: {
 *      key: // value for 'key'
 *      pageSize: // value for 'pageSize'
 *      after: // value for 'after'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetRunTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRunTasksQuery,
    GetRunTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRunTasksQuery, GetRunTasksQueryVariables>(
    GetRunTasksDocument,
    options
  );
}
export function useGetRunTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRunTasksQuery,
    GetRunTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRunTasksQuery, GetRunTasksQueryVariables>(
    GetRunTasksDocument,
    options
  );
}
export type GetRunTasksQueryHookResult = ReturnType<typeof useGetRunTasksQuery>;
export type GetRunTasksLazyQueryHookResult = ReturnType<
  typeof useGetRunTasksLazyQuery
>;
export type GetRunTasksQueryResult = Apollo.QueryResult<
  GetRunTasksQuery,
  GetRunTasksQueryVariables
>;
export const GetRunTaskAttachmentsDocument = gql`
  query getRunTaskAttachments($taskId: Int!) {
    runTaskAttachments(taskId: $taskId) {
      ...RunTaskAttachment
    }
  }
  ${RunTaskAttachmentFragmentDoc}
`;

/**
 * __useGetRunTaskAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetRunTaskAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunTaskAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunTaskAttachmentsQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetRunTaskAttachmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRunTaskAttachmentsQuery,
    GetRunTaskAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRunTaskAttachmentsQuery,
    GetRunTaskAttachmentsQueryVariables
  >(GetRunTaskAttachmentsDocument, options);
}
export function useGetRunTaskAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRunTaskAttachmentsQuery,
    GetRunTaskAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRunTaskAttachmentsQuery,
    GetRunTaskAttachmentsQueryVariables
  >(GetRunTaskAttachmentsDocument, options);
}
export type GetRunTaskAttachmentsQueryHookResult = ReturnType<
  typeof useGetRunTaskAttachmentsQuery
>;
export type GetRunTaskAttachmentsLazyQueryHookResult = ReturnType<
  typeof useGetRunTaskAttachmentsLazyQuery
>;
export type GetRunTaskAttachmentsQueryResult = Apollo.QueryResult<
  GetRunTaskAttachmentsQuery,
  GetRunTaskAttachmentsQueryVariables
>;
export const GetRunTaskMetaDataDocument = gql`
  query getRunTaskMetaData($taskIds: [Int!]) {
    runTaskMetadata(runTaskIds: $taskIds) {
      ...RunTask
      ...RunTaskMetaData
    }
  }
  ${RunTaskFragmentDoc}
  ${RunTaskMetaDataFragmentDoc}
`;

/**
 * __useGetRunTaskMetaDataQuery__
 *
 * To run a query within a React component, call `useGetRunTaskMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunTaskMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunTaskMetaDataQuery({
 *   variables: {
 *      taskIds: // value for 'taskIds'
 *   },
 * });
 */
export function useGetRunTaskMetaDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRunTaskMetaDataQuery,
    GetRunTaskMetaDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRunTaskMetaDataQuery,
    GetRunTaskMetaDataQueryVariables
  >(GetRunTaskMetaDataDocument, options);
}
export function useGetRunTaskMetaDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRunTaskMetaDataQuery,
    GetRunTaskMetaDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRunTaskMetaDataQuery,
    GetRunTaskMetaDataQueryVariables
  >(GetRunTaskMetaDataDocument, options);
}
export type GetRunTaskMetaDataQueryHookResult = ReturnType<
  typeof useGetRunTaskMetaDataQuery
>;
export type GetRunTaskMetaDataLazyQueryHookResult = ReturnType<
  typeof useGetRunTaskMetaDataLazyQuery
>;
export type GetRunTaskMetaDataQueryResult = Apollo.QueryResult<
  GetRunTaskMetaDataQuery,
  GetRunTaskMetaDataQueryVariables
>;
export const GetRunTypesDocument = gql`
  query getRunTypes {
    runTypes {
      productionRunTypeId
      typeName
    }
  }
`;

/**
 * __useGetRunTypesQuery__
 *
 * To run a query within a React component, call `useGetRunTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRunTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRunTypesQuery,
    GetRunTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRunTypesQuery, GetRunTypesQueryVariables>(
    GetRunTypesDocument,
    options
  );
}
export function useGetRunTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRunTypesQuery,
    GetRunTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRunTypesQuery, GetRunTypesQueryVariables>(
    GetRunTypesDocument,
    options
  );
}
export type GetRunTypesQueryHookResult = ReturnType<typeof useGetRunTypesQuery>;
export type GetRunTypesLazyQueryHookResult = ReturnType<
  typeof useGetRunTypesLazyQuery
>;
export type GetRunTypesQueryResult = Apollo.QueryResult<
  GetRunTypesQuery,
  GetRunTypesQueryVariables
>;
export const GetGanttIFrameUrlDocument = gql`
  query getGanttIFrameUrl($runId: Int!) {
    ganttIFrameUrl(runId: $runId)
  }
`;

/**
 * __useGetGanttIFrameUrlQuery__
 *
 * To run a query within a React component, call `useGetGanttIFrameUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGanttIFrameUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGanttIFrameUrlQuery({
 *   variables: {
 *      runId: // value for 'runId'
 *   },
 * });
 */
export function useGetGanttIFrameUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGanttIFrameUrlQuery,
    GetGanttIFrameUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGanttIFrameUrlQuery,
    GetGanttIFrameUrlQueryVariables
  >(GetGanttIFrameUrlDocument, options);
}
export function useGetGanttIFrameUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGanttIFrameUrlQuery,
    GetGanttIFrameUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGanttIFrameUrlQuery,
    GetGanttIFrameUrlQueryVariables
  >(GetGanttIFrameUrlDocument, options);
}
export type GetGanttIFrameUrlQueryHookResult = ReturnType<
  typeof useGetGanttIFrameUrlQuery
>;
export type GetGanttIFrameUrlLazyQueryHookResult = ReturnType<
  typeof useGetGanttIFrameUrlLazyQuery
>;
export type GetGanttIFrameUrlQueryResult = Apollo.QueryResult<
  GetGanttIFrameUrlQuery,
  GetGanttIFrameUrlQueryVariables
>;
export const GetRunAndTasksForRescheduleDocument = gql`
  query getRunAndTasksForReschedule($runId: Int!, $baseDateTime: DateTime!) {
    runs(where: { runId: { eq: $runId } }) {
      runId
      runName
      runTasks(
        where: {
          and: [
            { status: { in: [SCHEDULED, IN_PROGRESS, OVERDUE] } }
            { startTimeScheduled: { gte: $baseDateTime } }
          ]
        }
        order: [{ startTimeScheduled: ASC }]
      ) {
        ...ReschedulingTask
      }
    }
  }
  ${ReschedulingTaskFragmentDoc}
`;

/**
 * __useGetRunAndTasksForRescheduleQuery__
 *
 * To run a query within a React component, call `useGetRunAndTasksForRescheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunAndTasksForRescheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunAndTasksForRescheduleQuery({
 *   variables: {
 *      runId: // value for 'runId'
 *      baseDateTime: // value for 'baseDateTime'
 *   },
 * });
 */
export function useGetRunAndTasksForRescheduleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRunAndTasksForRescheduleQuery,
    GetRunAndTasksForRescheduleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRunAndTasksForRescheduleQuery,
    GetRunAndTasksForRescheduleQueryVariables
  >(GetRunAndTasksForRescheduleDocument, options);
}
export function useGetRunAndTasksForRescheduleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRunAndTasksForRescheduleQuery,
    GetRunAndTasksForRescheduleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRunAndTasksForRescheduleQuery,
    GetRunAndTasksForRescheduleQueryVariables
  >(GetRunAndTasksForRescheduleDocument, options);
}
export type GetRunAndTasksForRescheduleQueryHookResult = ReturnType<
  typeof useGetRunAndTasksForRescheduleQuery
>;
export type GetRunAndTasksForRescheduleLazyQueryHookResult = ReturnType<
  typeof useGetRunAndTasksForRescheduleLazyQuery
>;
export type GetRunAndTasksForRescheduleQueryResult = Apollo.QueryResult<
  GetRunAndTasksForRescheduleQuery,
  GetRunAndTasksForRescheduleQueryVariables
>;
export const GetArraySystemAutomationMethodsDocument = gql`
  query getArraySystemAutomationMethods {
    arraySystemAutomationMethods {
      arraySystemAutomationMethodId
      automationMethodCode
      arraySystemId
      automationMethodId
      isActive
      averageRunTimeSeconds
      arraySystem {
        systemId
        systemName
      }
    }
  }
`;

/**
 * __useGetArraySystemAutomationMethodsQuery__
 *
 * To run a query within a React component, call `useGetArraySystemAutomationMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArraySystemAutomationMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArraySystemAutomationMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetArraySystemAutomationMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetArraySystemAutomationMethodsQuery,
    GetArraySystemAutomationMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetArraySystemAutomationMethodsQuery,
    GetArraySystemAutomationMethodsQueryVariables
  >(GetArraySystemAutomationMethodsDocument, options);
}
export function useGetArraySystemAutomationMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArraySystemAutomationMethodsQuery,
    GetArraySystemAutomationMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetArraySystemAutomationMethodsQuery,
    GetArraySystemAutomationMethodsQueryVariables
  >(GetArraySystemAutomationMethodsDocument, options);
}
export type GetArraySystemAutomationMethodsQueryHookResult = ReturnType<
  typeof useGetArraySystemAutomationMethodsQuery
>;
export type GetArraySystemAutomationMethodsLazyQueryHookResult = ReturnType<
  typeof useGetArraySystemAutomationMethodsLazyQuery
>;
export type GetArraySystemAutomationMethodsQueryResult = Apollo.QueryResult<
  GetArraySystemAutomationMethodsQuery,
  GetArraySystemAutomationMethodsQueryVariables
>;
export const GetRunByIdDocument = gql`
  query getRunById($runId: Int!) {
    run(id: $runId, typeName: "", keyField: "runId") @client {
      ...Run
    }
  }
  ${RunFragmentDoc}
`;

/**
 * __useGetRunByIdQuery__
 *
 * To run a query within a React component, call `useGetRunByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunByIdQuery({
 *   variables: {
 *      runId: // value for 'runId'
 *   },
 * });
 */
export function useGetRunByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRunByIdQuery,
    GetRunByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRunByIdQuery, GetRunByIdQueryVariables>(
    GetRunByIdDocument,
    options
  );
}
export function useGetRunByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRunByIdQuery,
    GetRunByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRunByIdQuery, GetRunByIdQueryVariables>(
    GetRunByIdDocument,
    options
  );
}
export type GetRunByIdQueryHookResult = ReturnType<typeof useGetRunByIdQuery>;
export type GetRunByIdLazyQueryHookResult = ReturnType<
  typeof useGetRunByIdLazyQuery
>;
export type GetRunByIdQueryResult = Apollo.QueryResult<
  GetRunByIdQuery,
  GetRunByIdQueryVariables
>;
export const GetRunTaskByIdDocument = gql`
  query getRunTaskById($runActivityId: Int!) {
    runTask(id: $runActivityId, typeName: "", keyField: "runTaskId") @client {
      ...RunTask
      ...RunTaskMetaData
    }
  }
  ${RunTaskFragmentDoc}
  ${RunTaskMetaDataFragmentDoc}
`;

/**
 * __useGetRunTaskByIdQuery__
 *
 * To run a query within a React component, call `useGetRunTaskByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunTaskByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunTaskByIdQuery({
 *   variables: {
 *      runActivityId: // value for 'runActivityId'
 *   },
 * });
 */
export function useGetRunTaskByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRunTaskByIdQuery,
    GetRunTaskByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRunTaskByIdQuery, GetRunTaskByIdQueryVariables>(
    GetRunTaskByIdDocument,
    options
  );
}
export function useGetRunTaskByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRunTaskByIdQuery,
    GetRunTaskByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRunTaskByIdQuery, GetRunTaskByIdQueryVariables>(
    GetRunTaskByIdDocument,
    options
  );
}
export type GetRunTaskByIdQueryHookResult = ReturnType<
  typeof useGetRunTaskByIdQuery
>;
export type GetRunTaskByIdLazyQueryHookResult = ReturnType<
  typeof useGetRunTaskByIdLazyQuery
>;
export type GetRunTaskByIdQueryResult = Apollo.QueryResult<
  GetRunTaskByIdQuery,
  GetRunTaskByIdQueryVariables
>;
export const GetRunActivitiesByIdsDocument = gql`
  query getRunActivitiesByIds($runActivityIds: [Int!]!) {
    runTasksFromCache(
      ids: $runActivityIds
      typeName: ""
      keyField: "runTaskId"
    ) @client {
      ...RunTask
      ...RunTaskMetaData
    }
  }
  ${RunTaskFragmentDoc}
  ${RunTaskMetaDataFragmentDoc}
`;

/**
 * __useGetRunActivitiesByIdsQuery__
 *
 * To run a query within a React component, call `useGetRunActivitiesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunActivitiesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunActivitiesByIdsQuery({
 *   variables: {
 *      runActivityIds: // value for 'runActivityIds'
 *   },
 * });
 */
export function useGetRunActivitiesByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRunActivitiesByIdsQuery,
    GetRunActivitiesByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRunActivitiesByIdsQuery,
    GetRunActivitiesByIdsQueryVariables
  >(GetRunActivitiesByIdsDocument, options);
}
export function useGetRunActivitiesByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRunActivitiesByIdsQuery,
    GetRunActivitiesByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRunActivitiesByIdsQuery,
    GetRunActivitiesByIdsQueryVariables
  >(GetRunActivitiesByIdsDocument, options);
}
export type GetRunActivitiesByIdsQueryHookResult = ReturnType<
  typeof useGetRunActivitiesByIdsQuery
>;
export type GetRunActivitiesByIdsLazyQueryHookResult = ReturnType<
  typeof useGetRunActivitiesByIdsLazyQuery
>;
export type GetRunActivitiesByIdsQueryResult = Apollo.QueryResult<
  GetRunActivitiesByIdsQuery,
  GetRunActivitiesByIdsQueryVariables
>;
export const GetRunTypeByIdDocument = gql`
  query GetRunTypeById($productionRunTypeId: Int!) {
    productionRunType(
      id: $productionRunTypeId
      typeName: ""
      keyField: "productionRunTypeId"
    ) @client {
      productionRunTypeId
      typeName
    }
  }
`;

/**
 * __useGetRunTypeByIdQuery__
 *
 * To run a query within a React component, call `useGetRunTypeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunTypeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunTypeByIdQuery({
 *   variables: {
 *      productionRunTypeId: // value for 'productionRunTypeId'
 *   },
 * });
 */
export function useGetRunTypeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRunTypeByIdQuery,
    GetRunTypeByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRunTypeByIdQuery, GetRunTypeByIdQueryVariables>(
    GetRunTypeByIdDocument,
    options
  );
}
export function useGetRunTypeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRunTypeByIdQuery,
    GetRunTypeByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRunTypeByIdQuery, GetRunTypeByIdQueryVariables>(
    GetRunTypeByIdDocument,
    options
  );
}
export type GetRunTypeByIdQueryHookResult = ReturnType<
  typeof useGetRunTypeByIdQuery
>;
export type GetRunTypeByIdLazyQueryHookResult = ReturnType<
  typeof useGetRunTypeByIdLazyQuery
>;
export type GetRunTypeByIdQueryResult = Apollo.QueryResult<
  GetRunTypeByIdQuery,
  GetRunTypeByIdQueryVariables
>;
export const GetRunTemplatesDocument = gql`
  query GetRunTemplates(
    $pageSize: Int
    $after: String
    $order: [RunTemplateSortInput!]
    $where: RunTemplateFilterInput
  ) {
    runTemplates(
      first: $pageSize
      after: $after
      order: $order
      where: $where
    ) {
      edges {
        node {
          ...RunTemplate
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
  ${RunTemplateFragmentDoc}
`;

/**
 * __useGetRunTemplatesQuery__
 *
 * To run a query within a React component, call `useGetRunTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunTemplatesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      after: // value for 'after'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetRunTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRunTemplatesQuery,
    GetRunTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRunTemplatesQuery, GetRunTemplatesQueryVariables>(
    GetRunTemplatesDocument,
    options
  );
}
export function useGetRunTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRunTemplatesQuery,
    GetRunTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRunTemplatesQuery,
    GetRunTemplatesQueryVariables
  >(GetRunTemplatesDocument, options);
}
export type GetRunTemplatesQueryHookResult = ReturnType<
  typeof useGetRunTemplatesQuery
>;
export type GetRunTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetRunTemplatesLazyQuery
>;
export type GetRunTemplatesQueryResult = Apollo.QueryResult<
  GetRunTemplatesQuery,
  GetRunTemplatesQueryVariables
>;
export const GetRunTemplatesForComboboxDocument = gql`
  query GetRunTemplatesForCombobox {
    runTemplatesForCombobox(order: { runTemplateId: DESC }) {
      runTemplateId
      runTemplateName
    }
  }
`;

/**
 * __useGetRunTemplatesForComboboxQuery__
 *
 * To run a query within a React component, call `useGetRunTemplatesForComboboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunTemplatesForComboboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunTemplatesForComboboxQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRunTemplatesForComboboxQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRunTemplatesForComboboxQuery,
    GetRunTemplatesForComboboxQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRunTemplatesForComboboxQuery,
    GetRunTemplatesForComboboxQueryVariables
  >(GetRunTemplatesForComboboxDocument, options);
}
export function useGetRunTemplatesForComboboxLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRunTemplatesForComboboxQuery,
    GetRunTemplatesForComboboxQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRunTemplatesForComboboxQuery,
    GetRunTemplatesForComboboxQueryVariables
  >(GetRunTemplatesForComboboxDocument, options);
}
export type GetRunTemplatesForComboboxQueryHookResult = ReturnType<
  typeof useGetRunTemplatesForComboboxQuery
>;
export type GetRunTemplatesForComboboxLazyQueryHookResult = ReturnType<
  typeof useGetRunTemplatesForComboboxLazyQuery
>;
export type GetRunTemplatesForComboboxQueryResult = Apollo.QueryResult<
  GetRunTemplatesForComboboxQuery,
  GetRunTemplatesForComboboxQueryVariables
>;
export const GetRunTemplateTasksDocument = gql`
  query GetRunTemplateTasks($where: RunTemplateTaskFilterInput) {
    runTemplateTasks(where: $where) {
      ...RunTemplateTask
    }
  }
  ${RunTemplateTaskFragmentDoc}
`;

/**
 * __useGetRunTemplateTasksQuery__
 *
 * To run a query within a React component, call `useGetRunTemplateTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunTemplateTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunTemplateTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetRunTemplateTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRunTemplateTasksQuery,
    GetRunTemplateTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRunTemplateTasksQuery,
    GetRunTemplateTasksQueryVariables
  >(GetRunTemplateTasksDocument, options);
}
export function useGetRunTemplateTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRunTemplateTasksQuery,
    GetRunTemplateTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRunTemplateTasksQuery,
    GetRunTemplateTasksQueryVariables
  >(GetRunTemplateTasksDocument, options);
}
export type GetRunTemplateTasksQueryHookResult = ReturnType<
  typeof useGetRunTemplateTasksQuery
>;
export type GetRunTemplateTasksLazyQueryHookResult = ReturnType<
  typeof useGetRunTemplateTasksLazyQuery
>;
export type GetRunTemplateTasksQueryResult = Apollo.QueryResult<
  GetRunTemplateTasksQuery,
  GetRunTemplateTasksQueryVariables
>;
export const GetRunTaskTypesDocument = gql`
  query GetRunTaskTypes {
    runTaskTypes(order: { name: ASC }) {
      type
      id
      name
      compositeKey
      automationMethodId
    }
  }
`;

/**
 * __useGetRunTaskTypesQuery__
 *
 * To run a query within a React component, call `useGetRunTaskTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunTaskTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunTaskTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRunTaskTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRunTaskTypesQuery,
    GetRunTaskTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRunTaskTypesQuery, GetRunTaskTypesQueryVariables>(
    GetRunTaskTypesDocument,
    options
  );
}
export function useGetRunTaskTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRunTaskTypesQuery,
    GetRunTaskTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRunTaskTypesQuery,
    GetRunTaskTypesQueryVariables
  >(GetRunTaskTypesDocument, options);
}
export type GetRunTaskTypesQueryHookResult = ReturnType<
  typeof useGetRunTaskTypesQuery
>;
export type GetRunTaskTypesLazyQueryHookResult = ReturnType<
  typeof useGetRunTaskTypesLazyQuery
>;
export type GetRunTaskTypesQueryResult = Apollo.QueryResult<
  GetRunTaskTypesQuery,
  GetRunTaskTypesQueryVariables
>;
export const GetPlateCodesDocument = gql`
  query GetPlateCodes {
    plateCodes
  }
`;

/**
 * __useGetPlateCodesQuery__
 *
 * To run a query within a React component, call `useGetPlateCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlateCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlateCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlateCodesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPlateCodesQuery,
    GetPlateCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlateCodesQuery, GetPlateCodesQueryVariables>(
    GetPlateCodesDocument,
    options
  );
}
export function useGetPlateCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlateCodesQuery,
    GetPlateCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlateCodesQuery, GetPlateCodesQueryVariables>(
    GetPlateCodesDocument,
    options
  );
}
export type GetPlateCodesQueryHookResult = ReturnType<
  typeof useGetPlateCodesQuery
>;
export type GetPlateCodesLazyQueryHookResult = ReturnType<
  typeof useGetPlateCodesLazyQuery
>;
export type GetPlateCodesQueryResult = Apollo.QueryResult<
  GetPlateCodesQuery,
  GetPlateCodesQueryVariables
>;
export const GetRunTemplateByIdDocument = gql`
  query getRunTemplateById($runTemplateId: Int!) {
    runTemplate(id: $runTemplateId, typeName: "", keyField: "runTemplateId")
      @client {
      ...RunTemplate
    }
  }
  ${RunTemplateFragmentDoc}
`;

/**
 * __useGetRunTemplateByIdQuery__
 *
 * To run a query within a React component, call `useGetRunTemplateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunTemplateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunTemplateByIdQuery({
 *   variables: {
 *      runTemplateId: // value for 'runTemplateId'
 *   },
 * });
 */
export function useGetRunTemplateByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRunTemplateByIdQuery,
    GetRunTemplateByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRunTemplateByIdQuery,
    GetRunTemplateByIdQueryVariables
  >(GetRunTemplateByIdDocument, options);
}
export function useGetRunTemplateByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRunTemplateByIdQuery,
    GetRunTemplateByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRunTemplateByIdQuery,
    GetRunTemplateByIdQueryVariables
  >(GetRunTemplateByIdDocument, options);
}
export type GetRunTemplateByIdQueryHookResult = ReturnType<
  typeof useGetRunTemplateByIdQuery
>;
export type GetRunTemplateByIdLazyQueryHookResult = ReturnType<
  typeof useGetRunTemplateByIdLazyQuery
>;
export type GetRunTemplateByIdQueryResult = Apollo.QueryResult<
  GetRunTemplateByIdQuery,
  GetRunTemplateByIdQueryVariables
>;
export const GetRunTemplateStageByIdDocument = gql`
  query getRunTemplateStageById($runTemplateStageId: Int!) {
    runTemplateStage(
      id: $runTemplateStageId
      typeName: ""
      keyField: "runTemplateStageId"
    ) @client {
      ...RunTemplateStage
    }
  }
  ${RunTemplateStageFragmentDoc}
`;

/**
 * __useGetRunTemplateStageByIdQuery__
 *
 * To run a query within a React component, call `useGetRunTemplateStageByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunTemplateStageByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunTemplateStageByIdQuery({
 *   variables: {
 *      runTemplateStageId: // value for 'runTemplateStageId'
 *   },
 * });
 */
export function useGetRunTemplateStageByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRunTemplateStageByIdQuery,
    GetRunTemplateStageByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRunTemplateStageByIdQuery,
    GetRunTemplateStageByIdQueryVariables
  >(GetRunTemplateStageByIdDocument, options);
}
export function useGetRunTemplateStageByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRunTemplateStageByIdQuery,
    GetRunTemplateStageByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRunTemplateStageByIdQuery,
    GetRunTemplateStageByIdQueryVariables
  >(GetRunTemplateStageByIdDocument, options);
}
export type GetRunTemplateStageByIdQueryHookResult = ReturnType<
  typeof useGetRunTemplateStageByIdQuery
>;
export type GetRunTemplateStageByIdLazyQueryHookResult = ReturnType<
  typeof useGetRunTemplateStageByIdLazyQuery
>;
export type GetRunTemplateStageByIdQueryResult = Apollo.QueryResult<
  GetRunTemplateStageByIdQuery,
  GetRunTemplateStageByIdQueryVariables
>;
export const GetRunTemplateTaskByIdDocument = gql`
  query getRunTemplateTaskById($runTemplateTaskId: Int!) {
    runTemplateTask(
      id: $runTemplateTaskId
      typeName: ""
      keyField: "runTemplateTaskId"
    ) @client {
      ...RunTemplateTask
    }
  }
  ${RunTemplateTaskFragmentDoc}
`;

/**
 * __useGetRunTemplateTaskByIdQuery__
 *
 * To run a query within a React component, call `useGetRunTemplateTaskByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunTemplateTaskByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunTemplateTaskByIdQuery({
 *   variables: {
 *      runTemplateTaskId: // value for 'runTemplateTaskId'
 *   },
 * });
 */
export function useGetRunTemplateTaskByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRunTemplateTaskByIdQuery,
    GetRunTemplateTaskByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRunTemplateTaskByIdQuery,
    GetRunTemplateTaskByIdQueryVariables
  >(GetRunTemplateTaskByIdDocument, options);
}
export function useGetRunTemplateTaskByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRunTemplateTaskByIdQuery,
    GetRunTemplateTaskByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRunTemplateTaskByIdQuery,
    GetRunTemplateTaskByIdQueryVariables
  >(GetRunTemplateTaskByIdDocument, options);
}
export type GetRunTemplateTaskByIdQueryHookResult = ReturnType<
  typeof useGetRunTemplateTaskByIdQuery
>;
export type GetRunTemplateTaskByIdLazyQueryHookResult = ReturnType<
  typeof useGetRunTemplateTaskByIdLazyQuery
>;
export type GetRunTemplateTaskByIdQueryResult = Apollo.QueryResult<
  GetRunTemplateTaskByIdQuery,
  GetRunTemplateTaskByIdQueryVariables
>;
export const GetActiveTicketsDocument = gql`
  query GetActiveTickets(
    $where: TicketFilterInput
    $order: [TicketSortInput!]
  ) {
    activeTickets(where: $where, order: $order) {
      ...Ticket
    }
  }
  ${TicketFragmentDoc}
`;

/**
 * __useGetActiveTicketsQuery__
 *
 * To run a query within a React component, call `useGetActiveTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveTicketsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetActiveTicketsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActiveTicketsQuery,
    GetActiveTicketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActiveTicketsQuery, GetActiveTicketsQueryVariables>(
    GetActiveTicketsDocument,
    options
  );
}
export function useGetActiveTicketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveTicketsQuery,
    GetActiveTicketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveTicketsQuery,
    GetActiveTicketsQueryVariables
  >(GetActiveTicketsDocument, options);
}
export type GetActiveTicketsQueryHookResult = ReturnType<
  typeof useGetActiveTicketsQuery
>;
export type GetActiveTicketsLazyQueryHookResult = ReturnType<
  typeof useGetActiveTicketsLazyQuery
>;
export type GetActiveTicketsQueryResult = Apollo.QueryResult<
  GetActiveTicketsQuery,
  GetActiveTicketsQueryVariables
>;
export const GetTicketsDocument = gql`
  query GetTickets(
    $pageSize: Int
    $after: String
    $order: [TicketSortInput!]
    $where: TicketFilterInput
  ) {
    tickets(first: $pageSize, order: $order, after: $after, where: $where) {
      edges {
        node {
          ...Ticket
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      totalCount
    }
  }
  ${TicketFragmentDoc}
`;

/**
 * __useGetTicketsQuery__
 *
 * To run a query within a React component, call `useGetTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      after: // value for 'after'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTicketsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTicketsQuery,
    GetTicketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTicketsQuery, GetTicketsQueryVariables>(
    GetTicketsDocument,
    options
  );
}
export function useGetTicketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTicketsQuery,
    GetTicketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTicketsQuery, GetTicketsQueryVariables>(
    GetTicketsDocument,
    options
  );
}
export type GetTicketsQueryHookResult = ReturnType<typeof useGetTicketsQuery>;
export type GetTicketsLazyQueryHookResult = ReturnType<
  typeof useGetTicketsLazyQuery
>;
export type GetTicketsQueryResult = Apollo.QueryResult<
  GetTicketsQuery,
  GetTicketsQueryVariables
>;
export const GetTicketAttachmentsDocument = gql`
  query GetTicketAttachments($ticketId: Int!) {
    ticketInfo(id: $ticketId) {
      ...TicketAttachments
    }
  }
  ${TicketAttachmentsFragmentDoc}
`;

/**
 * __useGetTicketAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetTicketAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketAttachmentsQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useGetTicketAttachmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTicketAttachmentsQuery,
    GetTicketAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTicketAttachmentsQuery,
    GetTicketAttachmentsQueryVariables
  >(GetTicketAttachmentsDocument, options);
}
export function useGetTicketAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTicketAttachmentsQuery,
    GetTicketAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTicketAttachmentsQuery,
    GetTicketAttachmentsQueryVariables
  >(GetTicketAttachmentsDocument, options);
}
export type GetTicketAttachmentsQueryHookResult = ReturnType<
  typeof useGetTicketAttachmentsQuery
>;
export type GetTicketAttachmentsLazyQueryHookResult = ReturnType<
  typeof useGetTicketAttachmentsLazyQuery
>;
export type GetTicketAttachmentsQueryResult = Apollo.QueryResult<
  GetTicketAttachmentsQuery,
  GetTicketAttachmentsQueryVariables
>;
export const GetTicketInfoDocument = gql`
  query GetTicketInfo($ticketId: Int!) {
    ticketInfo(id: $ticketId) {
      ...TicketAndAttachments
    }
  }
  ${TicketAndAttachmentsFragmentDoc}
`;

/**
 * __useGetTicketInfoQuery__
 *
 * To run a query within a React component, call `useGetTicketInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketInfoQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useGetTicketInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTicketInfoQuery,
    GetTicketInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTicketInfoQuery, GetTicketInfoQueryVariables>(
    GetTicketInfoDocument,
    options
  );
}
export function useGetTicketInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTicketInfoQuery,
    GetTicketInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTicketInfoQuery, GetTicketInfoQueryVariables>(
    GetTicketInfoDocument,
    options
  );
}
export type GetTicketInfoQueryHookResult = ReturnType<
  typeof useGetTicketInfoQuery
>;
export type GetTicketInfoLazyQueryHookResult = ReturnType<
  typeof useGetTicketInfoLazyQuery
>;
export type GetTicketInfoQueryResult = Apollo.QueryResult<
  GetTicketInfoQuery,
  GetTicketInfoQueryVariables
>;
export const GetTicketAssetTypesDocument = gql`
  query GetTicketAssetTypes {
    ticketAssetTypes {
      assetTypeId
      assetType
    }
  }
`;

/**
 * __useGetTicketAssetTypesQuery__
 *
 * To run a query within a React component, call `useGetTicketAssetTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketAssetTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketAssetTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTicketAssetTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTicketAssetTypesQuery,
    GetTicketAssetTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTicketAssetTypesQuery,
    GetTicketAssetTypesQueryVariables
  >(GetTicketAssetTypesDocument, options);
}
export function useGetTicketAssetTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTicketAssetTypesQuery,
    GetTicketAssetTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTicketAssetTypesQuery,
    GetTicketAssetTypesQueryVariables
  >(GetTicketAssetTypesDocument, options);
}
export type GetTicketAssetTypesQueryHookResult = ReturnType<
  typeof useGetTicketAssetTypesQuery
>;
export type GetTicketAssetTypesLazyQueryHookResult = ReturnType<
  typeof useGetTicketAssetTypesLazyQuery
>;
export type GetTicketAssetTypesQueryResult = Apollo.QueryResult<
  GetTicketAssetTypesQuery,
  GetTicketAssetTypesQueryVariables
>;
export const GetTicketTypesDocument = gql`
  query GetTicketTypes {
    ticketTypes {
      ticketTypeId
      ticketType
    }
  }
`;

/**
 * __useGetTicketTypesQuery__
 *
 * To run a query within a React component, call `useGetTicketTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTicketTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTicketTypesQuery,
    GetTicketTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTicketTypesQuery, GetTicketTypesQueryVariables>(
    GetTicketTypesDocument,
    options
  );
}
export function useGetTicketTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTicketTypesQuery,
    GetTicketTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTicketTypesQuery, GetTicketTypesQueryVariables>(
    GetTicketTypesDocument,
    options
  );
}
export type GetTicketTypesQueryHookResult = ReturnType<
  typeof useGetTicketTypesQuery
>;
export type GetTicketTypesLazyQueryHookResult = ReturnType<
  typeof useGetTicketTypesLazyQuery
>;
export type GetTicketTypesQueryResult = Apollo.QueryResult<
  GetTicketTypesQuery,
  GetTicketTypesQueryVariables
>;
export const GetTicketAssetsDocument = gql`
  query GetTicketAssets {
    ticketAssets(order: { asset: ASC }) {
      assetId
      asset
      assetType {
        assetTypeId
        assetType
      }
    }
  }
`;

/**
 * __useGetTicketAssetsQuery__
 *
 * To run a query within a React component, call `useGetTicketAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketAssetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTicketAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTicketAssetsQuery,
    GetTicketAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTicketAssetsQuery, GetTicketAssetsQueryVariables>(
    GetTicketAssetsDocument,
    options
  );
}
export function useGetTicketAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTicketAssetsQuery,
    GetTicketAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTicketAssetsQuery,
    GetTicketAssetsQueryVariables
  >(GetTicketAssetsDocument, options);
}
export type GetTicketAssetsQueryHookResult = ReturnType<
  typeof useGetTicketAssetsQuery
>;
export type GetTicketAssetsLazyQueryHookResult = ReturnType<
  typeof useGetTicketAssetsLazyQuery
>;
export type GetTicketAssetsQueryResult = Apollo.QueryResult<
  GetTicketAssetsQuery,
  GetTicketAssetsQueryVariables
>;
export const GetTicketByIdDocument = gql`
  query GetTicketById($ticketId: Int!) {
    ticket(id: $ticketId, typeName: "", keyField: "ticketId") @client {
      ...Ticket
    }
  }
  ${TicketFragmentDoc}
`;

/**
 * __useGetTicketByIdQuery__
 *
 * To run a query within a React component, call `useGetTicketByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketByIdQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useGetTicketByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTicketByIdQuery,
    GetTicketByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTicketByIdQuery, GetTicketByIdQueryVariables>(
    GetTicketByIdDocument,
    options
  );
}
export function useGetTicketByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTicketByIdQuery,
    GetTicketByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTicketByIdQuery, GetTicketByIdQueryVariables>(
    GetTicketByIdDocument,
    options
  );
}
export type GetTicketByIdQueryHookResult = ReturnType<
  typeof useGetTicketByIdQuery
>;
export type GetTicketByIdLazyQueryHookResult = ReturnType<
  typeof useGetTicketByIdLazyQuery
>;
export type GetTicketByIdQueryResult = Apollo.QueryResult<
  GetTicketByIdQuery,
  GetTicketByIdQueryVariables
>;
export const GetTubeLabellerRunsDocument = gql`
  query getTubeLabellerRuns($method: String) {
    tubeLabellerRuns(method: $method)
  }
`;

/**
 * __useGetTubeLabellerRunsQuery__
 *
 * To run a query within a React component, call `useGetTubeLabellerRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTubeLabellerRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTubeLabellerRunsQuery({
 *   variables: {
 *      method: // value for 'method'
 *   },
 * });
 */
export function useGetTubeLabellerRunsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTubeLabellerRunsQuery,
    GetTubeLabellerRunsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTubeLabellerRunsQuery,
    GetTubeLabellerRunsQueryVariables
  >(GetTubeLabellerRunsDocument, options);
}
export function useGetTubeLabellerRunsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTubeLabellerRunsQuery,
    GetTubeLabellerRunsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTubeLabellerRunsQuery,
    GetTubeLabellerRunsQueryVariables
  >(GetTubeLabellerRunsDocument, options);
}
export type GetTubeLabellerRunsQueryHookResult = ReturnType<
  typeof useGetTubeLabellerRunsQuery
>;
export type GetTubeLabellerRunsLazyQueryHookResult = ReturnType<
  typeof useGetTubeLabellerRunsLazyQuery
>;
export type GetTubeLabellerRunsQueryResult = Apollo.QueryResult<
  GetTubeLabellerRunsQuery,
  GetTubeLabellerRunsQueryVariables
>;
export const GetTubeLabellerRunsByProcessDocument = gql`
  query getTubeLabellerRunsByProcess($process: String) {
    tubeLabellerRunsByProcess(process: $process) {
      runId
      runName
      platesCreateds {
        plateCreatedId
        plateBarcode
        arrayPlateType {
          arrayPlateCode {
            arrayPlateCode
          }
        }
      }
    }
  }
`;

/**
 * __useGetTubeLabellerRunsByProcessQuery__
 *
 * To run a query within a React component, call `useGetTubeLabellerRunsByProcessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTubeLabellerRunsByProcessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTubeLabellerRunsByProcessQuery({
 *   variables: {
 *      process: // value for 'process'
 *   },
 * });
 */
export function useGetTubeLabellerRunsByProcessQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTubeLabellerRunsByProcessQuery,
    GetTubeLabellerRunsByProcessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTubeLabellerRunsByProcessQuery,
    GetTubeLabellerRunsByProcessQueryVariables
  >(GetTubeLabellerRunsByProcessDocument, options);
}
export function useGetTubeLabellerRunsByProcessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTubeLabellerRunsByProcessQuery,
    GetTubeLabellerRunsByProcessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTubeLabellerRunsByProcessQuery,
    GetTubeLabellerRunsByProcessQueryVariables
  >(GetTubeLabellerRunsByProcessDocument, options);
}
export type GetTubeLabellerRunsByProcessQueryHookResult = ReturnType<
  typeof useGetTubeLabellerRunsByProcessQuery
>;
export type GetTubeLabellerRunsByProcessLazyQueryHookResult = ReturnType<
  typeof useGetTubeLabellerRunsByProcessLazyQuery
>;
export type GetTubeLabellerRunsByProcessQueryResult = Apollo.QueryResult<
  GetTubeLabellerRunsByProcessQuery,
  GetTubeLabellerRunsByProcessQueryVariables
>;
export const GetPlatesForManualSelectorDocument = gql`
  query getPlatesForManualSelector {
    platesForManualSelector {
      plateCreatedId
      plateBarcode
    }
  }
`;

/**
 * __useGetPlatesForManualSelectorQuery__
 *
 * To run a query within a React component, call `useGetPlatesForManualSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatesForManualSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatesForManualSelectorQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlatesForManualSelectorQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPlatesForManualSelectorQuery,
    GetPlatesForManualSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPlatesForManualSelectorQuery,
    GetPlatesForManualSelectorQueryVariables
  >(GetPlatesForManualSelectorDocument, options);
}
export function useGetPlatesForManualSelectorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlatesForManualSelectorQuery,
    GetPlatesForManualSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlatesForManualSelectorQuery,
    GetPlatesForManualSelectorQueryVariables
  >(GetPlatesForManualSelectorDocument, options);
}
export type GetPlatesForManualSelectorQueryHookResult = ReturnType<
  typeof useGetPlatesForManualSelectorQuery
>;
export type GetPlatesForManualSelectorLazyQueryHookResult = ReturnType<
  typeof useGetPlatesForManualSelectorLazyQuery
>;
export type GetPlatesForManualSelectorQueryResult = Apollo.QueryResult<
  GetPlatesForManualSelectorQuery,
  GetPlatesForManualSelectorQueryVariables
>;
export const GetCellNumberFromNormalizedPassagePlateDocument = gql`
  query getCellNumberFromNormalizedPassagePlate($plateBarcode: String) {
    cellNumberFromNormalizedPassagePlate(plateBarcode: $plateBarcode)
  }
`;

/**
 * __useGetCellNumberFromNormalizedPassagePlateQuery__
 *
 * To run a query within a React component, call `useGetCellNumberFromNormalizedPassagePlateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCellNumberFromNormalizedPassagePlateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCellNumberFromNormalizedPassagePlateQuery({
 *   variables: {
 *      plateBarcode: // value for 'plateBarcode'
 *   },
 * });
 */
export function useGetCellNumberFromNormalizedPassagePlateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCellNumberFromNormalizedPassagePlateQuery,
    GetCellNumberFromNormalizedPassagePlateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCellNumberFromNormalizedPassagePlateQuery,
    GetCellNumberFromNormalizedPassagePlateQueryVariables
  >(GetCellNumberFromNormalizedPassagePlateDocument, options);
}
export function useGetCellNumberFromNormalizedPassagePlateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCellNumberFromNormalizedPassagePlateQuery,
    GetCellNumberFromNormalizedPassagePlateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCellNumberFromNormalizedPassagePlateQuery,
    GetCellNumberFromNormalizedPassagePlateQueryVariables
  >(GetCellNumberFromNormalizedPassagePlateDocument, options);
}
export type GetCellNumberFromNormalizedPassagePlateQueryHookResult = ReturnType<
  typeof useGetCellNumberFromNormalizedPassagePlateQuery
>;
export type GetCellNumberFromNormalizedPassagePlateLazyQueryHookResult =
  ReturnType<typeof useGetCellNumberFromNormalizedPassagePlateLazyQuery>;
export type GetCellNumberFromNormalizedPassagePlateQueryResult =
  Apollo.QueryResult<
    GetCellNumberFromNormalizedPassagePlateQuery,
    GetCellNumberFromNormalizedPassagePlateQueryVariables
  >;
export const GetResuspensionVolFromPooling1224TcPlateDocument = gql`
  query getResuspensionVolFromPooling1224TCPlate($plateBarcode: String) {
    resuspensionVolFromPooling1224TCPlate(plateBarcode: $plateBarcode)
  }
`;

/**
 * __useGetResuspensionVolFromPooling1224TcPlateQuery__
 *
 * To run a query within a React component, call `useGetResuspensionVolFromPooling1224TcPlateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResuspensionVolFromPooling1224TcPlateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResuspensionVolFromPooling1224TcPlateQuery({
 *   variables: {
 *      plateBarcode: // value for 'plateBarcode'
 *   },
 * });
 */
export function useGetResuspensionVolFromPooling1224TcPlateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetResuspensionVolFromPooling1224TcPlateQuery,
    GetResuspensionVolFromPooling1224TcPlateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetResuspensionVolFromPooling1224TcPlateQuery,
    GetResuspensionVolFromPooling1224TcPlateQueryVariables
  >(GetResuspensionVolFromPooling1224TcPlateDocument, options);
}
export function useGetResuspensionVolFromPooling1224TcPlateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetResuspensionVolFromPooling1224TcPlateQuery,
    GetResuspensionVolFromPooling1224TcPlateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetResuspensionVolFromPooling1224TcPlateQuery,
    GetResuspensionVolFromPooling1224TcPlateQueryVariables
  >(GetResuspensionVolFromPooling1224TcPlateDocument, options);
}
export type GetResuspensionVolFromPooling1224TcPlateQueryHookResult =
  ReturnType<typeof useGetResuspensionVolFromPooling1224TcPlateQuery>;
export type GetResuspensionVolFromPooling1224TcPlateLazyQueryHookResult =
  ReturnType<typeof useGetResuspensionVolFromPooling1224TcPlateLazyQuery>;
export type GetResuspensionVolFromPooling1224TcPlateQueryResult =
  Apollo.QueryResult<
    GetResuspensionVolFromPooling1224TcPlateQuery,
    GetResuspensionVolFromPooling1224TcPlateQueryVariables
  >;
export const GetActivePooling1224TcPlatesDocument = gql`
  query getActivePooling1224TCPlates {
    activePooling1224TCPlates
  }
`;

/**
 * __useGetActivePooling1224TcPlatesQuery__
 *
 * To run a query within a React component, call `useGetActivePooling1224TcPlatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivePooling1224TcPlatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivePooling1224TcPlatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActivePooling1224TcPlatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActivePooling1224TcPlatesQuery,
    GetActivePooling1224TcPlatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActivePooling1224TcPlatesQuery,
    GetActivePooling1224TcPlatesQueryVariables
  >(GetActivePooling1224TcPlatesDocument, options);
}
export function useGetActivePooling1224TcPlatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivePooling1224TcPlatesQuery,
    GetActivePooling1224TcPlatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivePooling1224TcPlatesQuery,
    GetActivePooling1224TcPlatesQueryVariables
  >(GetActivePooling1224TcPlatesDocument, options);
}
export type GetActivePooling1224TcPlatesQueryHookResult = ReturnType<
  typeof useGetActivePooling1224TcPlatesQuery
>;
export type GetActivePooling1224TcPlatesLazyQueryHookResult = ReturnType<
  typeof useGetActivePooling1224TcPlatesLazyQuery
>;
export type GetActivePooling1224TcPlatesQueryResult = Apollo.QueryResult<
  GetActivePooling1224TcPlatesQuery,
  GetActivePooling1224TcPlatesQueryVariables
>;
export const GetTubeLabellerSalivaSamplesDocument = gql`
  query getTubeLabellerSalivaSamples {
    tubeLabellerSalivaSamples {
      sampleId
      sampleBarcode
    }
  }
`;

/**
 * __useGetTubeLabellerSalivaSamplesQuery__
 *
 * To run a query within a React component, call `useGetTubeLabellerSalivaSamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTubeLabellerSalivaSamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTubeLabellerSalivaSamplesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTubeLabellerSalivaSamplesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTubeLabellerSalivaSamplesQuery,
    GetTubeLabellerSalivaSamplesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTubeLabellerSalivaSamplesQuery,
    GetTubeLabellerSalivaSamplesQueryVariables
  >(GetTubeLabellerSalivaSamplesDocument, options);
}
export function useGetTubeLabellerSalivaSamplesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTubeLabellerSalivaSamplesQuery,
    GetTubeLabellerSalivaSamplesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTubeLabellerSalivaSamplesQuery,
    GetTubeLabellerSalivaSamplesQueryVariables
  >(GetTubeLabellerSalivaSamplesDocument, options);
}
export type GetTubeLabellerSalivaSamplesQueryHookResult = ReturnType<
  typeof useGetTubeLabellerSalivaSamplesQuery
>;
export type GetTubeLabellerSalivaSamplesLazyQueryHookResult = ReturnType<
  typeof useGetTubeLabellerSalivaSamplesLazyQuery
>;
export type GetTubeLabellerSalivaSamplesQueryResult = Apollo.QueryResult<
  GetTubeLabellerSalivaSamplesQuery,
  GetTubeLabellerSalivaSamplesQueryVariables
>;
export const GetRunTasksForTubeLabelsDocument = gql`
  query getRunTasksForTubeLabels(
    $plateBarcode: String
    $showCompleted: Boolean!
  ) {
    runTasksForTubeLabels(
      plateBarcode: $plateBarcode
      showCompleted: $showCompleted
      order: [{ startTimeScheduled: ASC }]
    ) {
      runId
      runTaskId
      activityName
      startTimeScheduled
      runTaskDetail {
        runTaskId
        runTaskDetailsId
        freezeDownRackType
        createMystplate
      }
    }
  }
`;

/**
 * __useGetRunTasksForTubeLabelsQuery__
 *
 * To run a query within a React component, call `useGetRunTasksForTubeLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunTasksForTubeLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunTasksForTubeLabelsQuery({
 *   variables: {
 *      plateBarcode: // value for 'plateBarcode'
 *      showCompleted: // value for 'showCompleted'
 *   },
 * });
 */
export function useGetRunTasksForTubeLabelsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRunTasksForTubeLabelsQuery,
    GetRunTasksForTubeLabelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRunTasksForTubeLabelsQuery,
    GetRunTasksForTubeLabelsQueryVariables
  >(GetRunTasksForTubeLabelsDocument, options);
}
export function useGetRunTasksForTubeLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRunTasksForTubeLabelsQuery,
    GetRunTasksForTubeLabelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRunTasksForTubeLabelsQuery,
    GetRunTasksForTubeLabelsQueryVariables
  >(GetRunTasksForTubeLabelsDocument, options);
}
export type GetRunTasksForTubeLabelsQueryHookResult = ReturnType<
  typeof useGetRunTasksForTubeLabelsQuery
>;
export type GetRunTasksForTubeLabelsLazyQueryHookResult = ReturnType<
  typeof useGetRunTasksForTubeLabelsLazyQuery
>;
export type GetRunTasksForTubeLabelsQueryResult = Apollo.QueryResult<
  GetRunTasksForTubeLabelsQuery,
  GetRunTasksForTubeLabelsQueryVariables
>;
export const GetFibroblastRackInfoDocument = gql`
  query getFibroblastRackInfo($input: AddRackInput) {
    fibroblastRackInfo(input: $input) {
      ...TubeLabellerDestinationWorklist
    }
  }
  ${TubeLabellerDestinationWorklistFragmentDoc}
`;

/**
 * __useGetFibroblastRackInfoQuery__
 *
 * To run a query within a React component, call `useGetFibroblastRackInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFibroblastRackInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFibroblastRackInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFibroblastRackInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFibroblastRackInfoQuery,
    GetFibroblastRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFibroblastRackInfoQuery,
    GetFibroblastRackInfoQueryVariables
  >(GetFibroblastRackInfoDocument, options);
}
export function useGetFibroblastRackInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFibroblastRackInfoQuery,
    GetFibroblastRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFibroblastRackInfoQuery,
    GetFibroblastRackInfoQueryVariables
  >(GetFibroblastRackInfoDocument, options);
}
export type GetFibroblastRackInfoQueryHookResult = ReturnType<
  typeof useGetFibroblastRackInfoQuery
>;
export type GetFibroblastRackInfoLazyQueryHookResult = ReturnType<
  typeof useGetFibroblastRackInfoLazyQuery
>;
export type GetFibroblastRackInfoQueryResult = Apollo.QueryResult<
  GetFibroblastRackInfoQuery,
  GetFibroblastRackInfoQueryVariables
>;
export const GetMonoclonalizationRackInfoDocument = gql`
  query getMonoclonalizationRackInfo($input: AddRackInput) {
    monoclonalizationRackInfo(input: $input) {
      ...TubeLabellerDestinationWorklist
    }
  }
  ${TubeLabellerDestinationWorklistFragmentDoc}
`;

/**
 * __useGetMonoclonalizationRackInfoQuery__
 *
 * To run a query within a React component, call `useGetMonoclonalizationRackInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonoclonalizationRackInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonoclonalizationRackInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMonoclonalizationRackInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMonoclonalizationRackInfoQuery,
    GetMonoclonalizationRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMonoclonalizationRackInfoQuery,
    GetMonoclonalizationRackInfoQueryVariables
  >(GetMonoclonalizationRackInfoDocument, options);
}
export function useGetMonoclonalizationRackInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMonoclonalizationRackInfoQuery,
    GetMonoclonalizationRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMonoclonalizationRackInfoQuery,
    GetMonoclonalizationRackInfoQueryVariables
  >(GetMonoclonalizationRackInfoDocument, options);
}
export type GetMonoclonalizationRackInfoQueryHookResult = ReturnType<
  typeof useGetMonoclonalizationRackInfoQuery
>;
export type GetMonoclonalizationRackInfoLazyQueryHookResult = ReturnType<
  typeof useGetMonoclonalizationRackInfoLazyQuery
>;
export type GetMonoclonalizationRackInfoQueryResult = Apollo.QueryResult<
  GetMonoclonalizationRackInfoQuery,
  GetMonoclonalizationRackInfoQueryVariables
>;
export const GetBloodProcessingRackInfoDocument = gql`
  query getBloodProcessingRackInfo($input: AddRackInput) {
    bloodProcessingRackInfo(input: $input) {
      ...TubeLabellerDestinationWorklist
    }
  }
  ${TubeLabellerDestinationWorklistFragmentDoc}
`;

/**
 * __useGetBloodProcessingRackInfoQuery__
 *
 * To run a query within a React component, call `useGetBloodProcessingRackInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBloodProcessingRackInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBloodProcessingRackInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBloodProcessingRackInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBloodProcessingRackInfoQuery,
    GetBloodProcessingRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBloodProcessingRackInfoQuery,
    GetBloodProcessingRackInfoQueryVariables
  >(GetBloodProcessingRackInfoDocument, options);
}
export function useGetBloodProcessingRackInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBloodProcessingRackInfoQuery,
    GetBloodProcessingRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBloodProcessingRackInfoQuery,
    GetBloodProcessingRackInfoQueryVariables
  >(GetBloodProcessingRackInfoDocument, options);
}
export type GetBloodProcessingRackInfoQueryHookResult = ReturnType<
  typeof useGetBloodProcessingRackInfoQuery
>;
export type GetBloodProcessingRackInfoLazyQueryHookResult = ReturnType<
  typeof useGetBloodProcessingRackInfoLazyQuery
>;
export type GetBloodProcessingRackInfoQueryResult = Apollo.QueryResult<
  GetBloodProcessingRackInfoQuery,
  GetBloodProcessingRackInfoQueryVariables
>;
export const GetSalivaRackInfoDocument = gql`
  query getSalivaRackInfo($input: AddRackInput) {
    salivaRackInfo(input: $input) {
      ...TubeLabellerDestinationWorklist
    }
  }
  ${TubeLabellerDestinationWorklistFragmentDoc}
`;

/**
 * __useGetSalivaRackInfoQuery__
 *
 * To run a query within a React component, call `useGetSalivaRackInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalivaRackInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalivaRackInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSalivaRackInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSalivaRackInfoQuery,
    GetSalivaRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSalivaRackInfoQuery,
    GetSalivaRackInfoQueryVariables
  >(GetSalivaRackInfoDocument, options);
}
export function useGetSalivaRackInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalivaRackInfoQuery,
    GetSalivaRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSalivaRackInfoQuery,
    GetSalivaRackInfoQueryVariables
  >(GetSalivaRackInfoDocument, options);
}
export type GetSalivaRackInfoQueryHookResult = ReturnType<
  typeof useGetSalivaRackInfoQuery
>;
export type GetSalivaRackInfoLazyQueryHookResult = ReturnType<
  typeof useGetSalivaRackInfoLazyQuery
>;
export type GetSalivaRackInfoQueryResult = Apollo.QueryResult<
  GetSalivaRackInfoQuery,
  GetSalivaRackInfoQueryVariables
>;
export const GetConsolidationRackInfoDocument = gql`
  query getConsolidationRackInfo($input: AddRackInput) {
    consolidationRackInfo(input: $input) {
      ...TubeLabellerDestinationWorklist
    }
  }
  ${TubeLabellerDestinationWorklistFragmentDoc}
`;

/**
 * __useGetConsolidationRackInfoQuery__
 *
 * To run a query within a React component, call `useGetConsolidationRackInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsolidationRackInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsolidationRackInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetConsolidationRackInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetConsolidationRackInfoQuery,
    GetConsolidationRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetConsolidationRackInfoQuery,
    GetConsolidationRackInfoQueryVariables
  >(GetConsolidationRackInfoDocument, options);
}
export function useGetConsolidationRackInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConsolidationRackInfoQuery,
    GetConsolidationRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConsolidationRackInfoQuery,
    GetConsolidationRackInfoQueryVariables
  >(GetConsolidationRackInfoDocument, options);
}
export type GetConsolidationRackInfoQueryHookResult = ReturnType<
  typeof useGetConsolidationRackInfoQuery
>;
export type GetConsolidationRackInfoLazyQueryHookResult = ReturnType<
  typeof useGetConsolidationRackInfoLazyQuery
>;
export type GetConsolidationRackInfoQueryResult = Apollo.QueryResult<
  GetConsolidationRackInfoQuery,
  GetConsolidationRackInfoQueryVariables
>;
export const GetIpscExpansionRackInfoDocument = gql`
  query getIPSCExpansionRackInfo($input: AddRackInput) {
    iPSCExpansionRackInfo(input: $input) {
      ...TubeLabellerDestinationWorklist
    }
  }
  ${TubeLabellerDestinationWorklistFragmentDoc}
`;

/**
 * __useGetIpscExpansionRackInfoQuery__
 *
 * To run a query within a React component, call `useGetIpscExpansionRackInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIpscExpansionRackInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIpscExpansionRackInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetIpscExpansionRackInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetIpscExpansionRackInfoQuery,
    GetIpscExpansionRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIpscExpansionRackInfoQuery,
    GetIpscExpansionRackInfoQueryVariables
  >(GetIpscExpansionRackInfoDocument, options);
}
export function useGetIpscExpansionRackInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIpscExpansionRackInfoQuery,
    GetIpscExpansionRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIpscExpansionRackInfoQuery,
    GetIpscExpansionRackInfoQueryVariables
  >(GetIpscExpansionRackInfoDocument, options);
}
export type GetIpscExpansionRackInfoQueryHookResult = ReturnType<
  typeof useGetIpscExpansionRackInfoQuery
>;
export type GetIpscExpansionRackInfoLazyQueryHookResult = ReturnType<
  typeof useGetIpscExpansionRackInfoLazyQuery
>;
export type GetIpscExpansionRackInfoQueryResult = Apollo.QueryResult<
  GetIpscExpansionRackInfoQuery,
  GetIpscExpansionRackInfoQueryVariables
>;
export const GetEbCollectionRackInfoDocument = gql`
  query getEBCollectionRackInfo($input: AddRackInput) {
    eBCollectionRackInfo(input: $input) {
      ...TubeLabellerDestinationWorklist
    }
  }
  ${TubeLabellerDestinationWorklistFragmentDoc}
`;

/**
 * __useGetEbCollectionRackInfoQuery__
 *
 * To run a query within a React component, call `useGetEbCollectionRackInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEbCollectionRackInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEbCollectionRackInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEbCollectionRackInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEbCollectionRackInfoQuery,
    GetEbCollectionRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEbCollectionRackInfoQuery,
    GetEbCollectionRackInfoQueryVariables
  >(GetEbCollectionRackInfoDocument, options);
}
export function useGetEbCollectionRackInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEbCollectionRackInfoQuery,
    GetEbCollectionRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEbCollectionRackInfoQuery,
    GetEbCollectionRackInfoQueryVariables
  >(GetEbCollectionRackInfoDocument, options);
}
export type GetEbCollectionRackInfoQueryHookResult = ReturnType<
  typeof useGetEbCollectionRackInfoQuery
>;
export type GetEbCollectionRackInfoLazyQueryHookResult = ReturnType<
  typeof useGetEbCollectionRackInfoLazyQuery
>;
export type GetEbCollectionRackInfoQueryResult = Apollo.QueryResult<
  GetEbCollectionRackInfoQuery,
  GetEbCollectionRackInfoQueryVariables
>;
export const GetDnarnaRackInfoDocument = gql`
  query getDNARNARackInfo($input: AddRackInput) {
    dNARNARackInfo(input: $input) {
      ...TubeLabellerDestinationWorklist
    }
  }
  ${TubeLabellerDestinationWorklistFragmentDoc}
`;

/**
 * __useGetDnarnaRackInfoQuery__
 *
 * To run a query within a React component, call `useGetDnarnaRackInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDnarnaRackInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDnarnaRackInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDnarnaRackInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDnarnaRackInfoQuery,
    GetDnarnaRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDnarnaRackInfoQuery,
    GetDnarnaRackInfoQueryVariables
  >(GetDnarnaRackInfoDocument, options);
}
export function useGetDnarnaRackInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDnarnaRackInfoQuery,
    GetDnarnaRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDnarnaRackInfoQuery,
    GetDnarnaRackInfoQueryVariables
  >(GetDnarnaRackInfoDocument, options);
}
export type GetDnarnaRackInfoQueryHookResult = ReturnType<
  typeof useGetDnarnaRackInfoQuery
>;
export type GetDnarnaRackInfoLazyQueryHookResult = ReturnType<
  typeof useGetDnarnaRackInfoLazyQuery
>;
export type GetDnarnaRackInfoQueryResult = Apollo.QueryResult<
  GetDnarnaRackInfoQuery,
  GetDnarnaRackInfoQueryVariables
>;
export const GetQcRackInfoDocument = gql`
  query getQCRackInfo($input: AddRackInput) {
    qCRackInfo(input: $input) {
      ...TubeLabellerDestinationWorklist
    }
  }
  ${TubeLabellerDestinationWorklistFragmentDoc}
`;

/**
 * __useGetQcRackInfoQuery__
 *
 * To run a query within a React component, call `useGetQcRackInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQcRackInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQcRackInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetQcRackInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetQcRackInfoQuery,
    GetQcRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQcRackInfoQuery, GetQcRackInfoQueryVariables>(
    GetQcRackInfoDocument,
    options
  );
}
export function useGetQcRackInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQcRackInfoQuery,
    GetQcRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetQcRackInfoQuery, GetQcRackInfoQueryVariables>(
    GetQcRackInfoDocument,
    options
  );
}
export type GetQcRackInfoQueryHookResult = ReturnType<
  typeof useGetQcRackInfoQuery
>;
export type GetQcRackInfoLazyQueryHookResult = ReturnType<
  typeof useGetQcRackInfoLazyQuery
>;
export type GetQcRackInfoQueryResult = Apollo.QueryResult<
  GetQcRackInfoQuery,
  GetQcRackInfoQueryVariables
>;
export const GetPooling1224TcRackInfoDocument = gql`
  query getPooling1224TCRackInfo($input: AddRackInput) {
    pooling1224TCRackInfo(input: $input) {
      ...TubeLabellerDestinationWorklist
    }
  }
  ${TubeLabellerDestinationWorklistFragmentDoc}
`;

/**
 * __useGetPooling1224TcRackInfoQuery__
 *
 * To run a query within a React component, call `useGetPooling1224TcRackInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPooling1224TcRackInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPooling1224TcRackInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPooling1224TcRackInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPooling1224TcRackInfoQuery,
    GetPooling1224TcRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPooling1224TcRackInfoQuery,
    GetPooling1224TcRackInfoQueryVariables
  >(GetPooling1224TcRackInfoDocument, options);
}
export function useGetPooling1224TcRackInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPooling1224TcRackInfoQuery,
    GetPooling1224TcRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPooling1224TcRackInfoQuery,
    GetPooling1224TcRackInfoQueryVariables
  >(GetPooling1224TcRackInfoDocument, options);
}
export type GetPooling1224TcRackInfoQueryHookResult = ReturnType<
  typeof useGetPooling1224TcRackInfoQuery
>;
export type GetPooling1224TcRackInfoLazyQueryHookResult = ReturnType<
  typeof useGetPooling1224TcRackInfoLazyQuery
>;
export type GetPooling1224TcRackInfoQueryResult = Apollo.QueryResult<
  GetPooling1224TcRackInfoQuery,
  GetPooling1224TcRackInfoQueryVariables
>;
export const GetManualPlateRackInfoDocument = gql`
  query getManualPlateRackInfo($input: AddRackInput) {
    manualPlateRackInfo(input: $input) {
      ...TubeLabellerDestinationWorklist
    }
  }
  ${TubeLabellerDestinationWorklistFragmentDoc}
`;

/**
 * __useGetManualPlateRackInfoQuery__
 *
 * To run a query within a React component, call `useGetManualPlateRackInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManualPlateRackInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManualPlateRackInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetManualPlateRackInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManualPlateRackInfoQuery,
    GetManualPlateRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManualPlateRackInfoQuery,
    GetManualPlateRackInfoQueryVariables
  >(GetManualPlateRackInfoDocument, options);
}
export function useGetManualPlateRackInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManualPlateRackInfoQuery,
    GetManualPlateRackInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManualPlateRackInfoQuery,
    GetManualPlateRackInfoQueryVariables
  >(GetManualPlateRackInfoDocument, options);
}
export type GetManualPlateRackInfoQueryHookResult = ReturnType<
  typeof useGetManualPlateRackInfoQuery
>;
export type GetManualPlateRackInfoLazyQueryHookResult = ReturnType<
  typeof useGetManualPlateRackInfoLazyQuery
>;
export type GetManualPlateRackInfoQueryResult = Apollo.QueryResult<
  GetManualPlateRackInfoQuery,
  GetManualPlateRackInfoQueryVariables
>;
export const GetMeDocument = gql`
  query GetMe {
    me {
      ...UserClaims
    }
  }
  ${UserClaimsFragmentDoc}
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options
  );
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options
  );
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<
  GetMeQuery,
  GetMeQueryVariables
>;
export const GetGroupsDocument = gql`
  query getGroups {
    appSuiteGroups(order: { groupName: ASC }) {
      ...Group
    }
  }
  ${GroupFragmentDoc}
`;

/**
 * __useGetGroupsQuery__
 *
 * To run a query within a React component, call `useGetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGroupsQuery, GetGroupsQueryVariables>(
    GetGroupsDocument,
    options
  );
}
export function useGetGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupsQuery,
    GetGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGroupsQuery, GetGroupsQueryVariables>(
    GetGroupsDocument,
    options
  );
}
export type GetGroupsQueryHookResult = ReturnType<typeof useGetGroupsQuery>;
export type GetGroupsLazyQueryHookResult = ReturnType<
  typeof useGetGroupsLazyQuery
>;
export type GetGroupsQueryResult = Apollo.QueryResult<
  GetGroupsQuery,
  GetGroupsQueryVariables
>;
export const GetGroupByIdDocument = gql`
  query getGroupById($id: Int!) {
    appSuiteGroups(where: { groupId: { eq: $id } }) {
      ...Group
    }
  }
  ${GroupFragmentDoc}
`;

/**
 * __useGetGroupByIdQuery__
 *
 * To run a query within a React component, call `useGetGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGroupByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGroupByIdQuery,
    GetGroupByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGroupByIdQuery, GetGroupByIdQueryVariables>(
    GetGroupByIdDocument,
    options
  );
}
export function useGetGroupByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupByIdQuery,
    GetGroupByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGroupByIdQuery, GetGroupByIdQueryVariables>(
    GetGroupByIdDocument,
    options
  );
}
export type GetGroupByIdQueryHookResult = ReturnType<
  typeof useGetGroupByIdQuery
>;
export type GetGroupByIdLazyQueryHookResult = ReturnType<
  typeof useGetGroupByIdLazyQuery
>;
export type GetGroupByIdQueryResult = Apollo.QueryResult<
  GetGroupByIdQuery,
  GetGroupByIdQueryVariables
>;
export const UserManagerDocument = gql`
  query UserManager(
    $where: AppSuiteUserFilterInput
    $order: [AppSuiteUserSortInput!]
  ) {
    appSuiteUsers(where: $where, order: $order) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useUserManagerQuery__
 *
 * To run a query within a React component, call `useUserManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserManagerQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUserManagerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserManagerQuery,
    UserManagerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserManagerQuery, UserManagerQueryVariables>(
    UserManagerDocument,
    options
  );
}
export function useUserManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserManagerQuery,
    UserManagerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserManagerQuery, UserManagerQueryVariables>(
    UserManagerDocument,
    options
  );
}
export type UserManagerQueryHookResult = ReturnType<typeof useUserManagerQuery>;
export type UserManagerLazyQueryHookResult = ReturnType<
  typeof useUserManagerLazyQuery
>;
export type UserManagerQueryResult = Apollo.QueryResult<
  UserManagerQuery,
  UserManagerQueryVariables
>;
export const GetUsersDocument = gql`
  query getUsers {
    appSuiteUsers(
      order: { userName: ASC }
      where: { isActive: { eq: true }, userId: { neq: 70 } }
    ) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<
  typeof useGetUsersLazyQuery
>;
export type GetUsersQueryResult = Apollo.QueryResult<
  GetUsersQuery,
  GetUsersQueryVariables
>;
export const GetUserDocument = gql`
  query getUser($userId: Int!) {
    user(id: $userId, typeName: "", keyField: "userId") @client {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const GetUsersFromCacheDocument = gql`
  query getUsersFromCache($userIds: [Int!]!) {
    usersFromCache(ids: $userIds, typeName: "", keyField: "userId") @client {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useGetUsersFromCacheQuery__
 *
 * To run a query within a React component, call `useGetUsersFromCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersFromCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersFromCacheQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useGetUsersFromCacheQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsersFromCacheQuery,
    GetUsersFromCacheQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersFromCacheQuery,
    GetUsersFromCacheQueryVariables
  >(GetUsersFromCacheDocument, options);
}
export function useGetUsersFromCacheLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersFromCacheQuery,
    GetUsersFromCacheQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersFromCacheQuery,
    GetUsersFromCacheQueryVariables
  >(GetUsersFromCacheDocument, options);
}
export type GetUsersFromCacheQueryHookResult = ReturnType<
  typeof useGetUsersFromCacheQuery
>;
export type GetUsersFromCacheLazyQueryHookResult = ReturnType<
  typeof useGetUsersFromCacheLazyQuery
>;
export type GetUsersFromCacheQueryResult = Apollo.QueryResult<
  GetUsersFromCacheQuery,
  GetUsersFromCacheQueryVariables
>;
export const GetGroupDocument = gql`
  query getGroup($groupId: Int!) {
    group(id: $groupId, typeName: "", keyField: "groupId") @client {
      ...Group
    }
  }
  ${GroupFragmentDoc}
`;

/**
 * __useGetGroupQuery__
 *
 * To run a query within a React component, call `useGetGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetGroupQuery(
  baseOptions: Apollo.QueryHookOptions<GetGroupQuery, GetGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGroupQuery, GetGroupQueryVariables>(
    GetGroupDocument,
    options
  );
}
export function useGetGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupQuery,
    GetGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGroupQuery, GetGroupQueryVariables>(
    GetGroupDocument,
    options
  );
}
export type GetGroupQueryHookResult = ReturnType<typeof useGetGroupQuery>;
export type GetGroupLazyQueryHookResult = ReturnType<
  typeof useGetGroupLazyQuery
>;
export type GetGroupQueryResult = Apollo.QueryResult<
  GetGroupQuery,
  GetGroupQueryVariables
>;
export const GetGroupsFromCacheDocument = gql`
  query getGroupsFromCache($groupIds: [Int!]) {
    groupsFromCache(ids: $groupIds, typeName: "", keyField: "groupId") @client {
      groupId
      groupName
    }
  }
`;

/**
 * __useGetGroupsFromCacheQuery__
 *
 * To run a query within a React component, call `useGetGroupsFromCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsFromCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsFromCacheQuery({
 *   variables: {
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useGetGroupsFromCacheQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGroupsFromCacheQuery,
    GetGroupsFromCacheQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGroupsFromCacheQuery,
    GetGroupsFromCacheQueryVariables
  >(GetGroupsFromCacheDocument, options);
}
export function useGetGroupsFromCacheLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupsFromCacheQuery,
    GetGroupsFromCacheQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGroupsFromCacheQuery,
    GetGroupsFromCacheQueryVariables
  >(GetGroupsFromCacheDocument, options);
}
export type GetGroupsFromCacheQueryHookResult = ReturnType<
  typeof useGetGroupsFromCacheQuery
>;
export type GetGroupsFromCacheLazyQueryHookResult = ReturnType<
  typeof useGetGroupsFromCacheLazyQuery
>;
export type GetGroupsFromCacheQueryResult = Apollo.QueryResult<
  GetGroupsFromCacheQuery,
  GetGroupsFromCacheQueryVariables
>;
export const GetActiveAsamsDocument = gql`
  query getActiveAsams($where: ArraySystemAutomationMethodFilterInput) {
    activeASAMs(where: $where) {
      automationMethodCode
      arraySystemId
    }
  }
`;

/**
 * __useGetActiveAsamsQuery__
 *
 * To run a query within a React component, call `useGetActiveAsamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveAsamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveAsamsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetActiveAsamsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActiveAsamsQuery,
    GetActiveAsamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActiveAsamsQuery, GetActiveAsamsQueryVariables>(
    GetActiveAsamsDocument,
    options
  );
}
export function useGetActiveAsamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveAsamsQuery,
    GetActiveAsamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActiveAsamsQuery, GetActiveAsamsQueryVariables>(
    GetActiveAsamsDocument,
    options
  );
}
export type GetActiveAsamsQueryHookResult = ReturnType<
  typeof useGetActiveAsamsQuery
>;
export type GetActiveAsamsLazyQueryHookResult = ReturnType<
  typeof useGetActiveAsamsLazyQuery
>;
export type GetActiveAsamsQueryResult = Apollo.QueryResult<
  GetActiveAsamsQuery,
  GetActiveAsamsQueryVariables
>;
export const GetArrayMethodLabwareTypesDocument = gql`
  query getArrayMethodLabwareTypes(
    $where: ArraySystemAutomationMethodLabwareTypeFilterInput
  ) {
    arrayMethodLabwareTypes(where: $where) {
      arraySystemAutomationMethodLabwareTypeId
      arraySystemAutomationMethodId
      arrayMethodPlateRoleId
      labwareType {
        ...LabwareType
      }
    }
  }
  ${LabwareTypeFragmentDoc}
`;

/**
 * __useGetArrayMethodLabwareTypesQuery__
 *
 * To run a query within a React component, call `useGetArrayMethodLabwareTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArrayMethodLabwareTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArrayMethodLabwareTypesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetArrayMethodLabwareTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetArrayMethodLabwareTypesQuery,
    GetArrayMethodLabwareTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetArrayMethodLabwareTypesQuery,
    GetArrayMethodLabwareTypesQueryVariables
  >(GetArrayMethodLabwareTypesDocument, options);
}
export function useGetArrayMethodLabwareTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArrayMethodLabwareTypesQuery,
    GetArrayMethodLabwareTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetArrayMethodLabwareTypesQuery,
    GetArrayMethodLabwareTypesQueryVariables
  >(GetArrayMethodLabwareTypesDocument, options);
}
export type GetArrayMethodLabwareTypesQueryHookResult = ReturnType<
  typeof useGetArrayMethodLabwareTypesQuery
>;
export type GetArrayMethodLabwareTypesLazyQueryHookResult = ReturnType<
  typeof useGetArrayMethodLabwareTypesLazyQuery
>;
export type GetArrayMethodLabwareTypesQueryResult = Apollo.QueryResult<
  GetArrayMethodLabwareTypesQuery,
  GetArrayMethodLabwareTypesQueryVariables
>;
export const GetSourcePlatesDocument = gql`
  query getSourcePlates($where: PlatesCreatedFilterInput) {
    sourcePlates(where: $where) {
      plateCreatedId
      plateBarcode
      labwareTypeId
    }
  }
`;

/**
 * __useGetSourcePlatesQuery__
 *
 * To run a query within a React component, call `useGetSourcePlatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSourcePlatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSourcePlatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSourcePlatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSourcePlatesQuery,
    GetSourcePlatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSourcePlatesQuery, GetSourcePlatesQueryVariables>(
    GetSourcePlatesDocument,
    options
  );
}
export function useGetSourcePlatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSourcePlatesQuery,
    GetSourcePlatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSourcePlatesQuery,
    GetSourcePlatesQueryVariables
  >(GetSourcePlatesDocument, options);
}
export type GetSourcePlatesQueryHookResult = ReturnType<
  typeof useGetSourcePlatesQuery
>;
export type GetSourcePlatesLazyQueryHookResult = ReturnType<
  typeof useGetSourcePlatesLazyQuery
>;
export type GetSourcePlatesQueryResult = Apollo.QueryResult<
  GetSourcePlatesQuery,
  GetSourcePlatesQueryVariables
>;
export const GetPlatesInfoDocument = gql`
  query getPlatesInfo($where: PlatesCreatedFilterInput) {
    platesInfo(where: $where) {
      plateCreatedId
      plateBarcode
      arrayPlateType {
        plateLayoutTypeId
      }
      labwareType {
        ...LabwareType
      }
      containers {
        containerId
        isActive
        barcode
        rowPos
        colPos
        sample {
          sampleId
          sampleBarcode
        }
      }
    }
  }
  ${LabwareTypeFragmentDoc}
`;

/**
 * __useGetPlatesInfoQuery__
 *
 * To run a query within a React component, call `useGetPlatesInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatesInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatesInfoQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPlatesInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPlatesInfoQuery,
    GetPlatesInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlatesInfoQuery, GetPlatesInfoQueryVariables>(
    GetPlatesInfoDocument,
    options
  );
}
export function useGetPlatesInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlatesInfoQuery,
    GetPlatesInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlatesInfoQuery, GetPlatesInfoQueryVariables>(
    GetPlatesInfoDocument,
    options
  );
}
export type GetPlatesInfoQueryHookResult = ReturnType<
  typeof useGetPlatesInfoQuery
>;
export type GetPlatesInfoLazyQueryHookResult = ReturnType<
  typeof useGetPlatesInfoLazyQuery
>;
export type GetPlatesInfoQueryResult = Apollo.QueryResult<
  GetPlatesInfoQuery,
  GetPlatesInfoQueryVariables
>;
export const GetLabwareTypesDocument = gql`
  query getLabwareTypes($where: LookupLabwareTypeFilterInput) {
    labwareTypes(where: $where) {
      ...LabwareType
    }
  }
  ${LabwareTypeFragmentDoc}
`;

/**
 * __useGetLabwareTypesQuery__
 *
 * To run a query within a React component, call `useGetLabwareTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabwareTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabwareTypesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLabwareTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLabwareTypesQuery,
    GetLabwareTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLabwareTypesQuery, GetLabwareTypesQueryVariables>(
    GetLabwareTypesDocument,
    options
  );
}
export function useGetLabwareTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLabwareTypesQuery,
    GetLabwareTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLabwareTypesQuery,
    GetLabwareTypesQueryVariables
  >(GetLabwareTypesDocument, options);
}
export type GetLabwareTypesQueryHookResult = ReturnType<
  typeof useGetLabwareTypesQuery
>;
export type GetLabwareTypesLazyQueryHookResult = ReturnType<
  typeof useGetLabwareTypesLazyQuery
>;
export type GetLabwareTypesQueryResult = Apollo.QueryResult<
  GetLabwareTypesQuery,
  GetLabwareTypesQueryVariables
>;
export const GetExistingMethodReservationDocument = gql`
  query getExistingMethodReservation($where: MethodReservationFilterInput) {
    existingMethodReservation(where: $where) {
      methodReservationId
    }
  }
`;

/**
 * __useGetExistingMethodReservationQuery__
 *
 * To run a query within a React component, call `useGetExistingMethodReservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExistingMethodReservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExistingMethodReservationQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetExistingMethodReservationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetExistingMethodReservationQuery,
    GetExistingMethodReservationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetExistingMethodReservationQuery,
    GetExistingMethodReservationQueryVariables
  >(GetExistingMethodReservationDocument, options);
}
export function useGetExistingMethodReservationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExistingMethodReservationQuery,
    GetExistingMethodReservationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExistingMethodReservationQuery,
    GetExistingMethodReservationQueryVariables
  >(GetExistingMethodReservationDocument, options);
}
export type GetExistingMethodReservationQueryHookResult = ReturnType<
  typeof useGetExistingMethodReservationQuery
>;
export type GetExistingMethodReservationLazyQueryHookResult = ReturnType<
  typeof useGetExistingMethodReservationLazyQuery
>;
export type GetExistingMethodReservationQueryResult = Apollo.QueryResult<
  GetExistingMethodReservationQuery,
  GetExistingMethodReservationQueryVariables
>;
export const GetExistingDeadPlateDocument = gql`
  query getExistingDeadPlate($srcPlateId: Int!) {
    existingDeadPlate(srcPlateId: $srcPlateId) {
      plateCreatedId
      plateBarcode
      labwareType {
        defaultLabwareVolume
        labwareTypeCode
        labwareTypeName
        plateLayoutType {
          plateRows
          plateCols
        }
      }
    }
  }
`;

/**
 * __useGetExistingDeadPlateQuery__
 *
 * To run a query within a React component, call `useGetExistingDeadPlateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExistingDeadPlateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExistingDeadPlateQuery({
 *   variables: {
 *      srcPlateId: // value for 'srcPlateId'
 *   },
 * });
 */
export function useGetExistingDeadPlateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExistingDeadPlateQuery,
    GetExistingDeadPlateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetExistingDeadPlateQuery,
    GetExistingDeadPlateQueryVariables
  >(GetExistingDeadPlateDocument, options);
}
export function useGetExistingDeadPlateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExistingDeadPlateQuery,
    GetExistingDeadPlateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExistingDeadPlateQuery,
    GetExistingDeadPlateQueryVariables
  >(GetExistingDeadPlateDocument, options);
}
export type GetExistingDeadPlateQueryHookResult = ReturnType<
  typeof useGetExistingDeadPlateQuery
>;
export type GetExistingDeadPlateLazyQueryHookResult = ReturnType<
  typeof useGetExistingDeadPlateLazyQuery
>;
export type GetExistingDeadPlateQueryResult = Apollo.QueryResult<
  GetExistingDeadPlateQuery,
  GetExistingDeadPlateQueryVariables
>;
export const GetTaskDetailsForWorklistToolDocument = gql`
  query getTaskDetailsForWorklistTool($runTaskId: Int!) {
    taskDetailsForWorklistTool(runTaskId: $runTaskId) {
      daughterWellNumber
      seedCellCount
      freezeCellCount
      freezeDownRackType
      createMystplate
      destPlateCode
      maxCryovial
      minCryovial
      overflowVials
      imageExportSetting
      imageExportFormat
      worklistTemplateId
    }
  }
`;

/**
 * __useGetTaskDetailsForWorklistToolQuery__
 *
 * To run a query within a React component, call `useGetTaskDetailsForWorklistToolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskDetailsForWorklistToolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskDetailsForWorklistToolQuery({
 *   variables: {
 *      runTaskId: // value for 'runTaskId'
 *   },
 * });
 */
export function useGetTaskDetailsForWorklistToolQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTaskDetailsForWorklistToolQuery,
    GetTaskDetailsForWorklistToolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTaskDetailsForWorklistToolQuery,
    GetTaskDetailsForWorklistToolQueryVariables
  >(GetTaskDetailsForWorklistToolDocument, options);
}
export function useGetTaskDetailsForWorklistToolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaskDetailsForWorklistToolQuery,
    GetTaskDetailsForWorklistToolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTaskDetailsForWorklistToolQuery,
    GetTaskDetailsForWorklistToolQueryVariables
  >(GetTaskDetailsForWorklistToolDocument, options);
}
export type GetTaskDetailsForWorklistToolQueryHookResult = ReturnType<
  typeof useGetTaskDetailsForWorklistToolQuery
>;
export type GetTaskDetailsForWorklistToolLazyQueryHookResult = ReturnType<
  typeof useGetTaskDetailsForWorklistToolLazyQuery
>;
export type GetTaskDetailsForWorklistToolQueryResult = Apollo.QueryResult<
  GetTaskDetailsForWorklistToolQuery,
  GetTaskDetailsForWorklistToolQueryVariables
>;
export const GetWorklistToolTemplateDocument = gql`
  query getWorklistToolTemplate($where: WorklistToolTemplateFilterInput) {
    worklistToolTemplate(where: $where) {
      ...WorklistToolTemplate
    }
  }
  ${WorklistToolTemplateFragmentDoc}
`;

/**
 * __useGetWorklistToolTemplateQuery__
 *
 * To run a query within a React component, call `useGetWorklistToolTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorklistToolTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorklistToolTemplateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetWorklistToolTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorklistToolTemplateQuery,
    GetWorklistToolTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWorklistToolTemplateQuery,
    GetWorklistToolTemplateQueryVariables
  >(GetWorklistToolTemplateDocument, options);
}
export function useGetWorklistToolTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorklistToolTemplateQuery,
    GetWorklistToolTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorklistToolTemplateQuery,
    GetWorklistToolTemplateQueryVariables
  >(GetWorklistToolTemplateDocument, options);
}
export type GetWorklistToolTemplateQueryHookResult = ReturnType<
  typeof useGetWorklistToolTemplateQuery
>;
export type GetWorklistToolTemplateLazyQueryHookResult = ReturnType<
  typeof useGetWorklistToolTemplateLazyQuery
>;
export type GetWorklistToolTemplateQueryResult = Apollo.QueryResult<
  GetWorklistToolTemplateQuery,
  GetWorklistToolTemplateQueryVariables
>;
export const GetWorklistToolTemplateContentsDocument = gql`
  query getWorklistToolTemplateContents(
    $where: WorklistToolTemplateContentFilterInput
  ) {
    worklistToolTemplateContent(where: $where) {
      ...WorklistToolTemplateContents
    }
  }
  ${WorklistToolTemplateContentsFragmentDoc}
`;

/**
 * __useGetWorklistToolTemplateContentsQuery__
 *
 * To run a query within a React component, call `useGetWorklistToolTemplateContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorklistToolTemplateContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorklistToolTemplateContentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetWorklistToolTemplateContentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorklistToolTemplateContentsQuery,
    GetWorklistToolTemplateContentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWorklistToolTemplateContentsQuery,
    GetWorklistToolTemplateContentsQueryVariables
  >(GetWorklistToolTemplateContentsDocument, options);
}
export function useGetWorklistToolTemplateContentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorklistToolTemplateContentsQuery,
    GetWorklistToolTemplateContentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorklistToolTemplateContentsQuery,
    GetWorklistToolTemplateContentsQueryVariables
  >(GetWorklistToolTemplateContentsDocument, options);
}
export type GetWorklistToolTemplateContentsQueryHookResult = ReturnType<
  typeof useGetWorklistToolTemplateContentsQuery
>;
export type GetWorklistToolTemplateContentsLazyQueryHookResult = ReturnType<
  typeof useGetWorklistToolTemplateContentsLazyQuery
>;
export type GetWorklistToolTemplateContentsQueryResult = Apollo.QueryResult<
  GetWorklistToolTemplateContentsQuery,
  GetWorklistToolTemplateContentsQueryVariables
>;
export const PlateHasTubeBarcodeScanDocument = gql`
  query PlateHasTubeBarcodeScan($plateId: Int!) {
    plateHasTubeBarcodeScan(plateId: $plateId)
  }
`;

/**
 * __usePlateHasTubeBarcodeScanQuery__
 *
 * To run a query within a React component, call `usePlateHasTubeBarcodeScanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlateHasTubeBarcodeScanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlateHasTubeBarcodeScanQuery({
 *   variables: {
 *      plateId: // value for 'plateId'
 *   },
 * });
 */
export function usePlateHasTubeBarcodeScanQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlateHasTubeBarcodeScanQuery,
    PlateHasTubeBarcodeScanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlateHasTubeBarcodeScanQuery,
    PlateHasTubeBarcodeScanQueryVariables
  >(PlateHasTubeBarcodeScanDocument, options);
}
export function usePlateHasTubeBarcodeScanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlateHasTubeBarcodeScanQuery,
    PlateHasTubeBarcodeScanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlateHasTubeBarcodeScanQuery,
    PlateHasTubeBarcodeScanQueryVariables
  >(PlateHasTubeBarcodeScanDocument, options);
}
export type PlateHasTubeBarcodeScanQueryHookResult = ReturnType<
  typeof usePlateHasTubeBarcodeScanQuery
>;
export type PlateHasTubeBarcodeScanLazyQueryHookResult = ReturnType<
  typeof usePlateHasTubeBarcodeScanLazyQuery
>;
export type PlateHasTubeBarcodeScanQueryResult = Apollo.QueryResult<
  PlateHasTubeBarcodeScanQuery,
  PlateHasTubeBarcodeScanQueryVariables
>;
export const OperationNames = {
  Query: {
    getCalendarNodes: "getCalendarNodes",
    getFeedGroups: "getFeedGroups",
    getGroupPersonalTasks: "getGroupPersonalTasks",
    AppBackground: "AppBackground",
    DashboardBackgroundQueries: "DashboardBackgroundQueries",
    RunTemplateBackground: "RunTemplateBackground",
    CalendarBackground: "CalendarBackground",
    RunsBackground: "RunsBackground",
    TicketBackground: "TicketBackground",
    IncomingSamplesBackground: "IncomingSamplesBackground",
    getDashboardTasks: "getDashboardTasks",
    getTaskHistory: "getTaskHistory",
    getIncludedFeedPlates: "getIncludedFeedPlates",
    getExcludedFeedPlates: "getExcludedFeedPlates",
    getPlateMap: "getPlateMap",
    getDashboardTask: "getDashboardTask",
    getFolderDirectory: "getFolderDirectory",
    getInsertableFolders: "getInsertableFolders",
    getFolders: "getFolders",
    GetAllFolders: "GetAllFolders",
    GetFolderById: "GetFolderById",
    SearchDirectory: "SearchDirectory",
    GetIncomingSampleTasks: "GetIncomingSampleTasks",
    GetIncomingSampleCollections: "GetIncomingSampleCollections",
    GetSourceSampleTypes: "GetSourceSampleTypes",
    GetProjects: "GetProjects",
    GetIncomingSampleCollectionById: "GetIncomingSampleCollectionById",
    GetIncomingSampleTaskById: "GetIncomingSampleTaskById",
    GetIncomingSampleTasksById: "GetIncomingSampleTasksById",
    getSystems: "getSystems",
    getLabComputers: "getLabComputers",
    getSystem: "getSystem",
    ManualTaskManager: "ManualTaskManager",
    MethodManager: "MethodManager",
    getMethods: "getMethods",
    getManualTaskTypes: "getManualTaskTypes",
    GetMethodById: "GetMethodById",
    GetManualTaskTypeById: "GetManualTaskTypeById",
    GetReservedMethods: "GetReservedMethods",
    GetExperimentSettings: "GetExperimentSettings",
    GetActiveMethodReservations: "GetActiveMethodReservations",
    getMethodReservationByPersonalTaskId:
      "getMethodReservationByPersonalTaskId",
    getMethodReservationByRunActivityId: "getMethodReservationByRunActivityId",
    getMyPersonalTasks: "getMyPersonalTasks",
    getPersonalTaskAttachments: "getPersonalTaskAttachments",
    getPersonalTaskTemplates: "getPersonalTaskTemplates",
    getRepeatingTasksSummary: "getRepeatingTasksSummary",
    getUnavailablePlates: "getUnavailablePlates",
    getCeligoPlateMetadata: "getCeligoPlateMetadata",
    getPlates: "getPlates",
    getPlateInfo: "getPlateInfo",
    GetTubeBarcodesForAutocomplete: "GetTubeBarcodesForAutocomplete",
    GetPlateForAutocomplete: "GetPlateForAutocomplete",
    GetLotNumbers: "GetLotNumbers",
    getContainerLineage: "getContainerLineage",
    GetContainers: "GetContainers",
    getSample: "getSample",
    GetPlateById: "GetPlateById",
    GetContainerById: "GetContainerById",
    getCreateRunDefaults: "getCreateRunDefaults",
    GetActiveRuns: "GetActiveRuns",
    getRuns: "getRuns",
    getRunTasks: "getRunTasks",
    getRunTaskAttachments: "getRunTaskAttachments",
    getRunTaskMetaData: "getRunTaskMetaData",
    getRunTypes: "getRunTypes",
    getGanttIFrameUrl: "getGanttIFrameUrl",
    getRunAndTasksForReschedule: "getRunAndTasksForReschedule",
    getArraySystemAutomationMethods: "getArraySystemAutomationMethods",
    getRunById: "getRunById",
    getRunTaskById: "getRunTaskById",
    getRunActivitiesByIds: "getRunActivitiesByIds",
    GetRunTypeById: "GetRunTypeById",
    GetRunTemplates: "GetRunTemplates",
    GetRunTemplatesForCombobox: "GetRunTemplatesForCombobox",
    GetRunTemplateTasks: "GetRunTemplateTasks",
    GetRunTaskTypes: "GetRunTaskTypes",
    GetPlateCodes: "GetPlateCodes",
    getRunTemplateById: "getRunTemplateById",
    getRunTemplateStageById: "getRunTemplateStageById",
    getRunTemplateTaskById: "getRunTemplateTaskById",
    GetActiveTickets: "GetActiveTickets",
    GetTickets: "GetTickets",
    GetTicketAttachments: "GetTicketAttachments",
    GetTicketInfo: "GetTicketInfo",
    GetTicketAssetTypes: "GetTicketAssetTypes",
    GetTicketTypes: "GetTicketTypes",
    GetTicketAssets: "GetTicketAssets",
    GetTicketById: "GetTicketById",
    getTubeLabellerRuns: "getTubeLabellerRuns",
    getTubeLabellerRunsByProcess: "getTubeLabellerRunsByProcess",
    getPlatesForManualSelector: "getPlatesForManualSelector",
    getCellNumberFromNormalizedPassagePlate:
      "getCellNumberFromNormalizedPassagePlate",
    getResuspensionVolFromPooling1224TCPlate:
      "getResuspensionVolFromPooling1224TCPlate",
    getActivePooling1224TCPlates: "getActivePooling1224TCPlates",
    getTubeLabellerSalivaSamples: "getTubeLabellerSalivaSamples",
    getRunTasksForTubeLabels: "getRunTasksForTubeLabels",
    getFibroblastRackInfo: "getFibroblastRackInfo",
    getMonoclonalizationRackInfo: "getMonoclonalizationRackInfo",
    getBloodProcessingRackInfo: "getBloodProcessingRackInfo",
    getSalivaRackInfo: "getSalivaRackInfo",
    getConsolidationRackInfo: "getConsolidationRackInfo",
    getIPSCExpansionRackInfo: "getIPSCExpansionRackInfo",
    getEBCollectionRackInfo: "getEBCollectionRackInfo",
    getDNARNARackInfo: "getDNARNARackInfo",
    getQCRackInfo: "getQCRackInfo",
    getPooling1224TCRackInfo: "getPooling1224TCRackInfo",
    getManualPlateRackInfo: "getManualPlateRackInfo",
    GetMe: "GetMe",
    getGroups: "getGroups",
    getGroupById: "getGroupById",
    UserManager: "UserManager",
    getUsers: "getUsers",
    getUser: "getUser",
    getUsersFromCache: "getUsersFromCache",
    getGroup: "getGroup",
    getGroupsFromCache: "getGroupsFromCache",
    getActiveAsams: "getActiveAsams",
    getArrayMethodLabwareTypes: "getArrayMethodLabwareTypes",
    getSourcePlates: "getSourcePlates",
    getPlatesInfo: "getPlatesInfo",
    getLabwareTypes: "getLabwareTypes",
    getExistingMethodReservation: "getExistingMethodReservation",
    getExistingDeadPlate: "getExistingDeadPlate",
    getTaskDetailsForWorklistTool: "getTaskDetailsForWorklistTool",
    getWorklistToolTemplate: "getWorklistToolTemplate",
    getWorklistToolTemplateContents: "getWorklistToolTemplateContents",
    PlateHasTubeBarcodeScan: "PlateHasTubeBarcodeScan",
  },
  Mutation: {
    updateDashboardTasks: "updateDashboardTasks",
    createFeedReservation: "createFeedReservation",
    ReserveCeligoPersonalTask: "ReserveCeligoPersonalTask",
    Log: "Log",
    CreateRunFolder: "CreateRunFolder",
    DeleteRunFolder: "DeleteRunFolder",
    AddIdfUpload: "AddIdfUpload",
    ValidateIdfUpload: "ValidateIdfUpload",
    UpdateCollectionSampleType: "UpdateCollectionSampleType",
    AddCollectionSampleType: "AddCollectionSampleType",
    DeleteCollectionSampleType: "DeleteCollectionSampleType",
    CreateCollection: "CreateCollection",
    DeleteIncomingSampleCollection: "DeleteIncomingSampleCollection",
    UpdateIncomingSampleCollection: "UpdateIncomingSampleCollection",
    UpdateCollectionTasks: "UpdateCollectionTasks",
    AddAsset: "AddAsset",
    UpdateManualTaskType: "UpdateManualTaskType",
    UpdateMethod: "UpdateMethod",
    UpdateRunTaskTypeDocumentation: "UpdateRunTaskTypeDocumentation",
    addPersonalTask: "addPersonalTask",
    addRecurringPersonalTask: "addRecurringPersonalTask",
    updatePersonalTasks: "updatePersonalTasks",
    deleteRecurringPersonalTask: "deleteRecurringPersonalTask",
    updateRecurringTask: "updateRecurringTask",
    overwriteRecurringTasks: "overwriteRecurringTasks",
    cancelRemainingReccuringTasks: "cancelRemainingReccuringTasks",
    createPersonalTaskTemplate: "createPersonalTaskTemplate",
    addPersonalTaskTemplateTask: "addPersonalTaskTemplateTask",
    startPersonalTaskTemplate: "startPersonalTaskTemplate",
    updatePersonalTaskTemplateTask: "updatePersonalTaskTemplateTask",
    createRun: "createRun",
    updateRunTasks: "updateRunTasks",
    ReserveCeligoRunTaskMethod: "ReserveCeligoRunTaskMethod",
    CreateRunGantt: "CreateRunGantt",
    deleteRunActivityMethodReservation: "deleteRunActivityMethodReservation",
    deleteTaskMethodReservation: "deleteTaskMethodReservation",
    addRunTask: "addRunTask",
    UpdateRun: "UpdateRun",
    DeleteRun: "DeleteRun",
    CreateRunTemplate: "CreateRunTemplate",
    AddRunTemplateStages: "AddRunTemplateStages",
    AddRunTemplateTasks: "AddRunTemplateTasks",
    AddRunTemplateChild: "AddRunTemplateChild",
    UpdateRunTemplate: "UpdateRunTemplate",
    UpdateRunTemplateStages: "UpdateRunTemplateStages",
    UpdateRunTemplateTaskSchedule: "UpdateRunTemplateTaskSchedule",
    UpdateRunTemplateTasks: "UpdateRunTemplateTasks",
    DeleteRunTemplate: "DeleteRunTemplate",
    DeleteRunTemplateStage: "DeleteRunTemplateStage",
    DeleteRunTemplateTreeNode: "DeleteRunTemplateTreeNode",
    CreateTaskAttachments: "CreateTaskAttachments",
    DeleteTaskAttachment: "DeleteTaskAttachment",
    DeleteTasks: "DeleteTasks",
    AssignTask: "AssignTask",
    RescheduleTasks: "RescheduleTasks",
    SaveFeedGroups: "SaveFeedGroups",
    AddPersonalFeedTasks: "AddPersonalFeedTasks",
    DeleteTemplateTask: "DeleteTemplateTask",
    CreateTicket: "CreateTicket",
    UpdateTickets: "UpdateTickets",
    AddTicketUpdate: "AddTicketUpdate",
    AcceptTicket: "AcceptTicket",
    AddTicketAttachment: "AddTicketAttachment",
    DeleteTicketAttachment: "DeleteTicketAttachment",
    MergeTicket: "MergeTicket",
    EditUser: "EditUser",
    GroupMemberLogin: "GroupMemberLogin",
    SaveUserSettings: "SaveUserSettings",
    setLabwareType: "setLabwareType",
    getPlateBarcodePreview: "getPlateBarcodePreview",
    getIntermediateDeadAndDestPlateBarcodes:
      "getIntermediateDeadAndDestPlateBarcodes",
    reserveMethod: "reserveMethod",
    deleteExistingMethodReservation: "deleteExistingMethodReservation",
    saveWorklistToolTemplate: "saveWorklistToolTemplate",
    overwriteExistingWorklistTemplate: "overwriteExistingWorklistTemplate",
    deleteWorklistToolTemplate: "deleteWorklistToolTemplate",
  },
  Fragment: {
    CalendarNode: "CalendarNode",
    DashboardTask: "DashboardTask",
    ManagePersonalTask: "ManagePersonalTask",
    FolderStructure: "FolderStructure",
    DirectoryFolder: "DirectoryFolder",
    Directory: "Directory",
    IncomingSampleType: "IncomingSampleType",
    IncomingSampleTaskTableEntry: "IncomingSampleTaskTableEntry",
    IncomingSampleTask: "IncomingSampleTask",
    IncomingSampleCollection: "IncomingSampleCollection",
    UploadIdfFormResponse: "UploadIdfFormResponse",
    ManualTaskType: "ManualTaskType",
    Method: "Method",
    ImagingWorklist: "ImagingWorklist",
    PersonalTaskAttachment: "PersonalTaskAttachment",
    PlateCreated: "PlateCreated",
    Plate: "Plate",
    ContainerMetadata: "ContainerMetadata",
    PlateInfo: "PlateInfo",
    ContainerLineage: "ContainerLineage",
    Sample: "Sample",
    ContainerTableEntry: "ContainerTableEntry",
    LabwareType: "LabwareType",
    Run: "Run",
    RunTaskDetails: "RunTaskDetails",
    SimpleRunTask: "SimpleRunTask",
    RunTask: "RunTask",
    ReschedulingTask: "ReschedulingTask",
    ProductionRun: "ProductionRun",
    RunInfo: "RunInfo",
    RunTaskMetaData: "RunTaskMetaData",
    RunTaskAttachment: "RunTaskAttachment",
    RunTemplateTask: "RunTemplateTask",
    RunTemplateStage: "RunTemplateStage",
    RunTemplate: "RunTemplate",
    Ticket: "Ticket",
    TicketAttachments: "TicketAttachments",
    TicketAndAttachments: "TicketAndAttachments",
    TubeLabellerDestinationWorklist: "TubeLabellerDestinationWorklist",
    User: "User",
    Group: "Group",
    UserClaims: "UserClaims",
    WorklistToolTemplate: "WorklistToolTemplate",
    WorklistToolTemplateContents: "WorklistToolTemplateContents",
  },
};
