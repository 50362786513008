import { Rule } from "@mui/icons-material";
import GetAppIcon from "@mui/icons-material/GetApp";
import UploadFile from "@mui/icons-material/UploadFile";
import { Button, Divider, FormControl, FormHelperText } from "@mui/material";
import { BackdropOpen } from "App";
import { FormikProvider, useFormik } from "formik";
import {
  AppSuiteUser,
  useAddIdfUploadMutation,
  useValidateIdfUploadMutation,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { GetUrl } from "helpers/get-url";
import { useEffect, useState } from "react";
import { DropZone } from "shared-components/DropZone";
import {
  ProjectsField,
  UserField,
} from "shared-components/FormFields/FormFields";
import styled from "styled-components/macro";
import IDFResultsModal from "../ModelsAndDialogs/IDFResultsModal";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.08);
`;

const StyledControl = styled(FormControl)`
  width: 400px;
  margin-top: 20px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;
  justify-content: center;
  align-items: ${({ justifyContent }: { justifyContent?: string }) =>
    justifyContent ?? ""};
  flex-wrap: wrap;
`;

const IDFFormScreen = () => {
  const [validateIdfUploadMutation, { loading: validatingIDFForm }] =
    useValidateIdfUploadMutation();
  const [addIdfUploadMutation, { loading: uploadingIDFForm }] =
    useAddIdfUploadMutation();
  const [idfResultsModalState, setIdfResultsModalState] = useState<{
    show: boolean;
    title: string;
    message: Maybe<string>;
    traceId: Maybe<string>;
    errors: Maybe<Maybe<string>[]>;
  }>({
    show: false,
    title: "",
    message: undefined,
    traceId: undefined,
    errors: undefined,
  });

  useEffect(() => {
    BackdropOpen({
      open: validatingIDFForm || uploadingIDFForm,
      header: validatingIDFForm ? "Validating IDF Form" : "Uploading IDF Form",
      subText: "Do not reload page",
      size: 60,
    });
  }, [validatingIDFForm, uploadingIDFForm]);

  const backendUrl = GetUrl();
  const handleDownload = () => {
    const url = `${backendUrl}/api/download/idfform`;
    window.location.href = url;
  };

  const formik = useFormik({
    initialValues: {
      projectId: 0,
      projectCode: "",
      userName: "",
      userId: 0,
      attachment: [] as File[],
    },
    onSubmit: async () => {
      await handleSubmit();
    },
  });

  const values = formik.values;
  const setFieldValue = formik.setFieldValue;
  const errors = formik.errors;
  const touched = formik.touched;

  const isProjectFieldValid = () => {
    if (values.projectCode && values.projectCode !== "") return true;

    setIdfResultsModalState({
      ...idfResultsModalState,
      show: true,
      title: "Couldn't Upload",
      message: "Please select a project",
    });
    return false;
  };

  const isUserFieldValid = () => {
    if (
      values.userName &&
      values.userName !== undefined &&
      values.userName !== ""
    )
      return true;

    setIdfResultsModalState({
      ...idfResultsModalState,
      show: true,
      title: "Couldn't Upload",
      message: "Please select a user",
    });
    return false;
  };

  const isFileSelectionValid = () => {
    if (values.attachment[0]) return true;

    setIdfResultsModalState({
      ...idfResultsModalState,
      show: true,
      title: "Couldn't Validate",
      message: "There was no file to validate",
    });
    return false;
  };

  const isFileSizeValid = () => {
    const twentyMB = 1024 * 1024 * 20;

    if (values.attachment[0].size <= twentyMB) return true;

    setIdfResultsModalState({
      ...idfResultsModalState,
      show: true,
      title: "Couldn't Upload",
      message: `${values.attachment[0].name} is too large (20MB max)`,
    });
    return false;
  };

  const formFieldsAreValid = () => {
    if (
      isProjectFieldValid() &&
      isUserFieldValid() &&
      isFileSelectionValid() &&
      isFileSizeValid()
    )
      return true;
    return false;
  };

  const handleValidateFile = async () => {
    if (!formFieldsAreValid()) return;

    const input = {
      name: values.attachment[0].name,
      file: values.attachment[0],
      projectCode: values.projectCode,
      registeredBy: values.userName || "",
      registeredById: values.userId || -1,
    };

    try {
      const responseValidate = await validateIdfUploadMutation({
        variables: {
          input: input,
        },
      });
      const validateIdfUpload =
        responseValidate.data?.validateIdfUpload ?? false;

      if (validateIdfUpload) {
        setIdfResultsModalState({
          ...idfResultsModalState,
          show: true,
          title: "Validation Results",
          errors: validateIdfUpload,
        });
      } else {
        setIdfResultsModalState({
          ...idfResultsModalState,
          show: true,
          title: "Validation Results",
          message: "Unknown error occurred",
        });
      }
    } catch (err) {
      setIdfResultsModalState({
        ...idfResultsModalState,
        show: true,
        title: "Error",
        message: "Error occurred during validation. Please try again.",
      });
    }
  };

  const handleSubmit = async () => {
    if (!formFieldsAreValid()) return;

    try {
      const input = {
        name: values.attachment[0].name,
        file: values.attachment[0],
        projectCode: values.projectCode,
        registeredBy: values.userName,
        registeredById: values.userId,
      };
      const response = await addIdfUploadMutation({
        variables: {
          input: input,
        },
      });
      const idfUpload = response.data?.addIdfUpload ?? false;
      if (idfUpload) {
        setIdfResultsModalState({
          ...idfResultsModalState,
          show: true,
          title: "Upload Results",
          traceId: idfUpload.traceID,
          message: idfUpload.message,
          errors: idfUpload.errors,
        });
      }
    } catch (err) {
      setIdfResultsModalState({
        ...idfResultsModalState,
        show: true,
        title: "Error",
        message: "Error occurred during upload. Please try again",
      });
    }
    formik.setValues({ ...formik.initialValues });
  };

  const setAttachmentsField = (attachments: File[]) =>
    formik.setFieldValue("attachment", attachments);

  return (
    <Layout>
      <Section justifyContent="start">
        <StyledControl>
          <Button
            variant="contained"
            color="primary"
            startIcon={<GetAppIcon />}
            onClick={handleDownload}
          >
            Download Latest IdfForm Template
          </Button>
        </StyledControl>
      </Section>

      <Divider />
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Section justifyContent="start">
            <StyledControl>
              <ProjectsField
                id={"projects-combobox"}
                value={values.projectId}
                onChange={(event, newValue) => {
                  if (newValue && !Array.isArray(newValue)) {
                    setFieldValue("projectId", newValue.projectId);
                    setFieldValue("projectCode", newValue.nyscfprefix);
                  }
                }}
                variant="outlined"
                label={"Select Project"}
                helperText={
                  errors.projectId && touched.projectId && errors.projectId
                }
                error={
                  !!(errors.projectId && touched.projectId && errors.projectId)
                }
              />
              <FormHelperText>
                Select a project from the dropdown
              </FormHelperText>
            </StyledControl>

            <StyledControl>
              <UserField
                id="user-combobox"
                value={values.userId}
                filter={(val) => {
                  return val?.groups?.some(
                    (group) =>
                      group?.groupName === "Project Management" ||
                      group?.groupName === "Software"
                  );
                }}
                onChange={(event, newValue) => {
                  const selectedUser = newValue as AppSuiteUser;
                  setFieldValue("userName", selectedUser?.userName);
                  setFieldValue("userId", selectedUser?.userId);
                }}
                variant="outlined"
                label="Select User"
              />
              <FormHelperText>Select a user from the dropdown</FormHelperText>
            </StyledControl>

            <StyledControl>
              <DropZone
                attachments={values.attachment.slice(0, 1)}
                setAttachmentsFormik={setAttachmentsField}
                fileTypes=".xlsx"
              />
            </StyledControl>

            <StyledControl>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Rule />}
                onClick={handleValidateFile}
              >
                Validate IdfForm
              </Button>
            </StyledControl>

            <StyledControl>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<UploadFile />}
              >
                Validate & Upload IdfForm
              </Button>
            </StyledControl>
          </Section>
        </form>
      </FormikProvider>

      <IDFResultsModal
        isOpen={idfResultsModalState.show}
        setModalState={setIdfResultsModalState}
        title={idfResultsModalState.title}
        message={idfResultsModalState.message}
        traceId={idfResultsModalState.traceId}
        errors={idfResultsModalState.errors}
      />
    </Layout>
  );
};

export default IDFFormScreen;
